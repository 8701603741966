<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-icon left>history</v-icon>
                Transaction History
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search Transactions"
                    single-line
                    hide-details
                ></v-text-field>
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :search="search"
                    :items-per-page="5"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    class="elevation-1"
                >
                    <template v-slot:item.amount="{ item }">
                        <v-chip :color="item.amount > 0 ? 'green' : 'red'" dark>
                            {{ item.amount }}
                        </v-chip>
                    </template>
                    <template v-slot:no-data>
                        <v-alert :value="true" color="error" icon="warning">
                            No transactions found
                        </v-alert>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                search: '',
                sortBy: 'date',
                sortDesc: false,
                headers: [
                    { text: 'Date', value: 'date', align: 'start' },
                    { text: 'From Account', value: 'fromAccount' },
                    { text: 'To Account', value: 'toAccount' },
                    { text: 'Amount', value: 'amount' },
                    { text: 'Notes', value: 'notes' },
                ],
                transactions: [
                    {
                        date: '2024-05-01',
                        fromAccount: 'Account 1',
                        toAccount: 'Account 2',
                        amount: 200,
                        notes: 'Monthly premium payment',
                    },
                    {
                        date: '2024-05-03',
                        fromAccount: 'Account 3',
                        toAccount: 'Account 1',
                        amount: -150,
                        notes: 'Claim reimbursement',
                    },
                    {
                        date: '2024-05-10',
                        fromAccount: 'Account 2',
                        toAccount: 'Account 4',
                        amount: 500,
                        notes: 'Property sale deposit',
                    },
                    {
                        date: '2024-05-15',
                        fromAccount: 'Account 4',
                        toAccount: 'Account 3',
                        amount: -75,
                        notes: 'Service fee',
                    },
                    {
                        date: '2024-05-20',
                        fromAccount: 'Account 2',
                        toAccount: 'Account 1',
                        amount: 300,
                        notes: 'Commission payment',
                    },
                    {
                        date: '2024-05-25',
                        fromAccount: 'Account 1',
                        toAccount: 'Account 4',
                        amount: -100,
                        notes: 'Miscellaneous expense',
                    },
                ],
            };
        },
    };
</script>

<style scoped>
    .v-card-title {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .v-card-title v-icon {
        margin-right: 10px;
    }
</style>
