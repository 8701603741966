<template>
    <div class="pt-16">

        <v-card max-width="800px" class="mx-auto rounded-form" outlined>

            <v-card-title class="layout justify-center" style="font-size: smaller">
                Please verify the appearance of the house and the photos provided.
            </v-card-title>

            <v-card v-if="images" max-width="550px" class="mx-auto" elevation="20" outlined
                    shaped>
                <v-img :src="images" contain max-height="500px" v-slot: placeholder="placeholder"></v-img>
                <v-card-title class="layout justify-center" style="font-size: smaller">
                    Report Photo
                </v-card-title>
            </v-card>
            <v-card v-if="images2" max-width="550px" class="mx-auto" elevation="10" outlined
                    shaped>
                <v-img :src="images2" contain max-height="500px"></v-img>
                <v-card-title class="layout justify-center" style="font-size: smaller">
                    Photo Taken On-Site
                </v-card-title>
            </v-card>

            <!-- ... rest of your code ... -->

            <template v-if="dogs.state == 'Underworking'">
                <v-card-title class="layout justify-center">
                    If the task is completed, please select the appropriate option.
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="orange" class="m-2" @click="NotFinded" :loading="busy"> House Not Found
                    </v-btn>
                    <v-btn depressed color="success" class="m-2" @click="Finded" :loading="busy">Enter Information</v-btn>
                </v-card-actions>
            </template>

            <template v-if="dogs.state == 'Finished'">
                <template v-if="this.$store.state.users.access_level< 3">
                    <v-card-title class="layout justify-center">
                        Based on the location report sent by the technician and also the public message, please verify the accuracy of the information.
                    </v-card-title>
                    <LocationForComparison></LocationForComparison>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="orange" class="m-2" @click="ReportMistake" :loading="busy">Report Error
                        </v-btn>
                        <v-btn depressed color="success" class="m-2" @click="LocationVerified" :loading="busy">Verify Location
                        </v-btn>
                    </v-card-actions>
                </template>
            </template>

        </v-card>
    </div>
</template>


<script>

    import vueFilePond from 'vue-filepond';
    import LocationForComparison from "../views/PublicReport/LocationForComparison";

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond,
            LocationForComparison,
        },
        props: [
            'locationss',
            'userss',
        ],
        data() {
            return {
                video: {},
                canvas: {},
                captures: [],
                snackbar: false,
                text: 'Hello, I\'m a snackbar',
            }
        },
        data: function () {
            return {
                //myFiles: ['index.html'],
                dogs: [],
                images: '',
                images2: '',
                users: '',
                selected_user: '',
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'assessment': '', 'id': ''
                    },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                gettingLocation: '',
                errorStr: '',
                locationLatLong: '',

            }
        },
        //
        created() {
            this.fetchData();//users
            // this.getMessageInfo();
            // this.form.catched_lat = this.locationss.Lat;
            // this.form.catched_lon = this.locationss.Long;
            // this.locateAsync();
            // if(navigator.geolocation){
            //     navigator.geolocation.getCurrentPosition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // };
        },

        mounted: function () {


        },

        methods: {
            // async locateAsync() {
            //     this.locationLatLong = await this.getLocation();
            //     this.form.catched_lat = this.locationLatLong.coords.latitude;
            //     this.form.catched_lon = this.locationLatLong.coords.longitude;
            // },

            // async getLocation() {
            //     return new Promise((resolve, reject) => {
            //         if(!("geolocation" in navigator)) {
            //             reject(new Error('Geolocation is not available.'));
            //         }
            //         navigator.geolocation.getCurrentPosition(resolve, reject);
            //
            //     });
            // },

            fetchData() {
                this.busy = true;
                axios.get('/homedata/' + this.$route.params.id)
                    .then(response => {
                        console.log(response.data);
                        this.dogs = response.data[0].data;
                        this.images = this.dogs.public_fig;
                        this.images2 = '/storage/images/dogs/' + this.dogs.first_fig;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;
                })
            },

            // getMessageInfo(){
            //     this.busy = true;
            //     axios.get('/report/index/' +  this.$route.params.id)
            //         .then(response =>{
            //             //console.log(response);
            //             this.dogs = response.data;
            //             // this.images= require('../../../../public/storage/images/publics/'+this.dogs.fig);
            //             this.images= '/storage/images/publics/'+this.dogs.fig;
            //             //this.images=require( '../../../../storage/app/public/images/publics/Public_100420200728185f7979927beb2.jpg');
            //         })
            // },

            addNewTask() {
                this.busy = true;
                this.form.name = this.selected_user.id;
                this.form.state = "Unread";
                this.form.id = this.$route.params.id;
                this.form.level = this.selected_user.access_level;

                axios.post('../report/update', this.form)
                    .then(response => {
                        console.log(this.responses);
                        // this.$router.push({name:'home'});
                        this.$router.push({name: 'customerMessages', params: {locationss: this.locationss}});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        alert('error');
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            assignTask() {
                this.busy = true;
                this.form.name = this.selected_user.id;
                this.form.state = "Inprogress";
                this.form.id = this.$route.params.id;
                this.form.level = this.selected_user.access_level;
                //this.FindLevel1();
                axios.post('../report/update', this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        this.$router.push({name: 'customerMessages'});
                        //alert('پیغام شما ثبت شد')
                        //this.$router.push(toString(response.data[0].data.id));
                        // flash('success message ...');
                        // axios.get('../report');
                    })
                    .catch(errors => {
                        console.log(this.form);
                        alert(errors);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            Finded() {
                this.form.assessment = 1;
                this.form.id = this.dogs.id;
                axios.put('../homedatas', this.form)
                    .then(response => {
                        console.log(response);
                        this.$router.push({name: 'profiles'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            NotFinded() {
                this.form.assessment = 0;
                this.form.id = this.dogs.id;
                axios.put('../homedatas', this.form)
                    .then(response => {
                        console.log(this.responses);
                        this.$router.push({name: 'newprofiles'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            ReportMistake() {

                this.busy = true;
                this.form.state = "Finished";
                this.form.id = this.$route.params.id;
                this.form.level = 6;//Report A mistake
                axios.post('../report/update', this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.responses);
                        this.$router.push({name: 'customerMessages'});
                        //alert('پیغام شما ثبت شد')
                        //this.$router.push(toString(response.data[0].data.id));
                        // flash('success message ...');
                        // axios.get('../report');
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            LocationVerified() {
                this.busy = true;
                this.form.state = "Finished";
                this.form.id = this.$route.params.id;
                // this.form.level=4;
                this.form.level = 7;//Verify
                axios.post('../report/update', this.form)
                    .then(response => {
                        //this.responses = response.data[0].data.id;
                        console.log(response);
                        this.$router.push({name: 'customerMessages'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>


<!--<template>-->
<!--<div class="pt-16">-->
<!--<v-card max-width="480px" class="mx-auto rounded-form" outlined >-->
<!--    <v-card-title class="text-uppercase">-->
<!--        مشخصات سگ را وارد نمایید.-->
<!--    </v-card-title>-->
<!--    <v-img :src="require('../../../assets/iamges/g8013.png')" contain max-height="120px">-->
<!--    </v-img>-->

<!--    <v-card-text>-->
<!--        <v-text-field  label="ای دی کاربر" :error-messages="errors" placeholder="لطفا سن خود را وارد نمایید." v-model="form.id "></v-text-field>-->
<!--    </v-card-text>-->
<!--    <v-card-actions>-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn depressed color="success" class="m-1" @click="addNewDog" :loading="busy">اضافه کردن </v-btn>-->
<!--    </v-card-actions>-->
<!--</v-card>-->

<!--</div>-->
<!--</template>-->

<!--<script>-->

<!--    export default {-->
<!--        name: "EditPublicMessage",-->
<!--        components: {-->
<!--        },-->
<!--        data() {-->
<!--            return {-->
<!--                form:-->
<!--                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'-->
<!--                        'id':'',-->
<!--                    },-->
<!--            }-->
<!--        },-->
<!--        data:function () {-->
<!--            return{-->
<!--                //myFiles: ['index.html'],-->
<!--                errors: null,-->
<!--                responses:null,-->
<!--                busy:false,-->
<!--            }-->
<!--        },-->
<!--        mounted: {-->

<!--        },-->
<!--        computed: {-->
<!--        },-->
<!--        created() {-->
<!--            this.fetchData();-->
<!--            this.getMessageInfo();-->
<!--        },-->
<!--        methods:{-->
<!--            getMessageInfo(){-->

<!--            },-->
<!--            fetchData(){-->
<!--                this.busy = true;-->

<!--                axios.get('/report/index')-->
<!--                    .then(response =>{-->
<!--                        console.log(response);-->
<!--                        this.dogs = response.data;-->
<!--                    })-->
<!--                    .catch(errors =>{-->
<!--                        alert('unable to fetch');-->
<!--                    }).finally(()=>{-->
<!--                    this.busy = false;-->

<!--                })-->
<!--            },-->
<!--            addNewDog() {-->
<!--                this.busy=true;-->
<!--                axios.post('../dogdata', this.form)-->
<!--                    .then( response => {-->
<!--                        this.responses = response.data[0].data.id;-->
<!--                        //this.$router.push(toString(response.data[0].data.id));-->
<!--                        this.$router.push({name:'profiles'});-->
<!--                        })-->
<!--                    .catch(errors =>{-->
<!--                        console.log(this.form);-->
<!--                        // this.errors = errors.response.data.errors;-->
<!--                        console.log(this.errors);-->
<!--                    }).finally(()=>{-->
<!--                    this.busy=false;-->
<!--                });-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->

<!--    #camera {-->
<!--        text-align: center;-->
<!--        color: #2c3e50;-->
<!--    }-->
<!--    #video {-->
<!--        background-color: #000000;-->
<!--    }-->
<!--    #canvas {-->
<!--        display: none;-->
<!--    }-->
<!--    li {-->
<!--        display: inline;-->
<!--        padding: 5px;-->
<!--    }-->
<!--.rounded-form{-->
<!--    border-radius: 28px !important;-->

<!--}-->
<!--</style>-->
