<template>
    <v-app>
        <v-container fluid class="pa-6">
            <v-row>
                <v-col cols="12">
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="responsive-title">My Wallets</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="openNewAccountDialog">Create New Account</v-btn>
                    </v-toolbar>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="text-center">
                    <v-alert type="info" border="left" colored-border elevation="2">
                        Kindly set up a minimum of one account for each currency you utilize with our service. These
                        funds will be dedicated to covering your service fees.
                    </v-alert>
                    <v-alert type="success" border="left" colored-border elevation="2" class="my-4">
                        Gift: Charge 25 EUR and get 50 EUR credit. Claiming free credit: 25 EUR
                    </v-alert>
                </v-col>
            </v-row>

            <v-row class="mx-auto" style="max-width: 1200px;">
                <v-col cols="12" md="4" v-for="wallet in wallets" :key="wallet.id" class="d-flex align-stretch">
                    <v-card :color="wallet.color" class="rounded-lg elevation-2 mx-auto my-2" max-width="350px">
                        <v-card-title>
                            <v-img :src="wallet.icon" contain width="24" height="24" class="mr-2"></v-img>
                            {{ wallet.name }}
                            <v-spacer></v-spacer>
                            <v-btn icon>
                                <v-icon>mdi-help-circle</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="font-weight-medium mb-2">{{ wallet.number }}</div>
                            <div class="mb-1">Balance: <strong>{{ wallet.balance }} {{ wallet.currency }}</strong></div>
                            <div>Locked: <strong>{{ wallet.locked }} {{ wallet.currency }}</strong></div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text>Transfer</v-btn>
                            <v-btn text>Deposit</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">New Account</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form">
                            <v-select
                                v-model="newAccount.currency"
                                :items="currencies"
                                label="Currency unit"
                                required
                            ></v-select>
                            <v-text-field
                                v-model="newAccount.name"
                                label="Account name"
                                required
                            ></v-text-field>
                            <div>New account creation cost: Free for the first account</div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeNewAccountDialog">Close</v-btn>
                        <v-btn color="primary" @click="createAccount">Create Account</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                newAccount: {
                    currency: '',
                    name: ''
                },
                wallets: [
                    {
                        id: 1,
                        name: 'Insurmatics',
                        icon: 'path/to/insurmatics_icon.png',
                        number: '5442-4536-5865-6502',
                        balance: '1000',
                        locked: '200',
                        currency: 'EUR',
                        color: 'purple lighten-5'
                    },
                    {
                        id: 2,
                        name: 'Company J',
                        icon: 'path/to/company_j_icon.png',
                        number: '8344-6368-1907-5326',
                        balance: '2000',
                        locked: '300',
                        currency: 'USD',
                        color: 'blue lighten-5'
                    },
                    {
                        id: 3,
                        name: 'Local Bank',
                        icon: 'path/to/local_bank_icon.png',
                        number: '6459-5041-7405-5907',
                        balance: '3000',
                        locked: '400',
                        currency: 'IRR',
                        color: 'green lighten-5'
                    }
                ],
                currencies: ['EUR', 'USD', 'IRR']
            };
        },
        methods: {
            openNewAccountDialog() {
                this.dialog = true;
            },
            closeNewAccountDialog() {
                this.dialog = false;
            },
            createAccount() {
                // Add logic to create account
                this.dialog = false;
            }
        }
    };
</script>

<style scoped>
    .v-card {
        border-radius: 20px;
        overflow: hidden;
    }

    .v-card-title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding-bottom: 8px;
    }

    .v-card-text {
        font-size: 1.1rem;
        padding: 16px;
    }

    .v-card-actions {
        justify-content: space-between;
        padding: 8px 16px;
    }

    .v-btn {
        text-transform: none !important;
    }

    .v-toolbar-title {
        font-size: 24px;
        font-weight: bold;
    }

    .mx-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .responsive-title {
        font-size: 20px; /* default font size */
    }

    @media (max-width: 400px) {
        .responsive-title {
            font-size: 14px; /* reduced font size for screens smaller than 400px */
        }
    }
</style>
