var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"#f2c4d3","timeout":5000,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticStyle:{"color":"#333333"}},[_vm._v("\n                    The messages updated in "+_vm._s(new Date())+" are as follows.\n                ")])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-toolbar',{attrs:{"dense":"","color":"gray"}},[_c('v-row',{staticClass:"mx-0"},[_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowAll()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("\n                                home\n                            ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("User messages")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowUnread()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("\n                                email\n                            ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Sent by users")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowInprogress()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#EC407A"}},[_vm._v("\n                                assignment_ind\n                            ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Wait for response ")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowUnderworking()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#F57C00"}},[_vm._v("\n                                assignment\n                            ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("response from insurer")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowFinished()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#827717"}},[_vm._v("\n                                assignment_turned_in\n                            ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Confirmation")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowVerified()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("\n                                verified_user\n                            ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Closed")])])],1)],1)],1),_vm._v(" "),_c('v-card',[_c('v-card-title',[_vm._v("\n            "+_vm._s(_vm.messageType)+"\n            "),_c('v-spacer')],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.messages,"search":_vm.search,"loading":_vm.busy,"items-per-page":20},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v("\n                    "+_vm._s(item.issue_tracking)+"\n                ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColor(item.status)}},[_vm._v("\n                    "+_vm._s(_vm.getIconMessage(item.status))+"\n                ")]),_vm._v(" "),_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v("\n                    "+_vm._s(_vm.getTextState(item.status))+"\n                ")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-circular',{attrs:{"size":45,"value":_vm.getProgressValue(item.status)}},[_vm._v("\n                    "+_vm._s(_vm.getProgressValue(item.status))+"%\n                ")])]}},{key:"item.topic",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getTopicMessage(item.type))+"\n            ")]}},{key:"item.lastupdate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.getLastUpdateTime(item.issue_tracking))+"\n            ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{name:'showmessage', params: {message: item.message }},"icon":""}},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("edit")])],1)]}}])})],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"red white--text"},[_vm._v("\n                Are you sure you want to delete?\n            ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n                Once deleted, it is no longer possible to return the information. If you are sure, press the delete\n                button, otherwise cancel.\n            ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error"},on:{"&click":function($event){return _vm.ShowRemoveDiolog($event)}}},[_vm._v("\n                    Remove\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                    Cancel\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }