<template>
    <div>

<!--        @click:row="item=>$router.push({name:'profile',params:{profile_id:item.id}})"-->
        <v-data-table
            show-expand
            :headers="headers"
            :items="dogs"
            :items-per-page="10"

            :loading="busy"
            :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'items per page',


      }"
        >
            <template #item.remove="{item}">
                <v-btn color="red"
                       rounded
                       small>delete
                </v-btn>
            </template>

            <template #item.assign="{item}">
                <v-btn color="blue"
                       rounded
                       small>assign
                </v-btn>
            </template>
            <template #item.edit="{item}">
                <v-btn color="green"
                       rounded
                       @click="updateRemoveID(item.id)"
                       small>edit
                </v-btn>
            </template>
            <template #item.user="{item}">
                <v-chip
                    :color="getColor(item.verified)"
                    dark
                >
                    {{getTextState(item.verified)}}
                </v-chip>
            </template>
            <template #expanded-item="{header, item}">
                <!--                <v-flex md6>-->
                <!--                </v-flex>-->
                <td :colspan="headers.length">
                    <v-spacer></v-spacer>
                    <v-container fluid>
                        <v-img :src="'/storage/images/vet/'+ item.fig" contain max-height="400px">
                        </v-img>
                        <v-layout row wrap align="center" justify="center">
                            <v-layout row wrap align="center" justify="center">
                                <v-flex sm4 xs12 justify-center>
                                    <v-text-field
                                        v-model="item.number_of_nezam"
                                        label="شماره نظام"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                                <v-flex sm4 xs12 justify-center align-self-center>
                                    <v-text-field
                                        v-model="item.age"
                                        label="سن"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                                <v-flex sm4 xs12 justify-center align-self-center>
                                    <v-text-field
                                        v-model="item.degree"
                                        label="سطح تحصیلات"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
<!--                            <v-flex sm4 xs12 justify-center>-->
<!--                                <v-avatar width="300px" height="100px">-->
<!--                                    &lt;!&ndash;                                    //                    this.images= '/storage/images/publics/'+this.dogs.fig;&ndash;&gt;-->
<!--                                    <v-img :src="'/storage/images/vet/'+ item.fig" contain max-height="400px">-->
<!--                                    </v-img>-->
<!--                                </v-avatar>-->
<!--                            </v-flex>-->
                            <v-flex sm6 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.number"
                                    label="شماره تماس"
                                    readonly
                                    filled
                                    rounded
                                    dense

                                ></v-text-field>
                            </v-flex>
                            <v-flex sm6 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.comments"
                                    label="توضیحات"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>


                    </v-container>
                </td>

            </template>

<!--            <template v-slot:item.track="{ item }">-->
<!--                <v-btn @click.prevent="ShowLocation(item.id)" icon  ><v-icon>gps_not_fixed</v-icon></v-btn>-->
<!--            </template>-->

<!--            <template v-slot:item.edit="{ item }">-->
<!--                <v-btn icon @click.stop><v-icon>edit</v-icon></v-btn>-->
<!--            </template>-->


        </v-data-table>


    </div>
</template>

<script>

    export default {
        name: "OwnersIndex",


        created() {

        },
        mounted(){
            this.fetchData();
        },



        data:function () {
            return {
                busy:false,
                dogs: [],

                headers: [
                    { text: 'ID', value: 'id' },

                    {
                        text: 'name',
                        align: 'start',
                        value: 'name',
                    },
                    { text: 'Surname', value: 'family' },
                    { text: 'Sex', value: 'sex' },

                    {text: 'Building type', value: 'user', sortable: false},
                    {text: 'confirm', value: 'edit', sortable: false},
                    {text: 'assign building', value: 'assign', sortable: false},
                    {text: 'delete', value: 'remove', sortable: false},
                    // { text: 'Dog ID', value: 'family' },
                ],


            }
        },
        methods: {
            updateRemoveID(id){
                axios.put('../vet/'+id,{'verified':1})
                    .then(response =>{
                        console.log(response);
                        this.$router.go(0);
                    })
                    .catch(errors =>{
                        //alert('unable to fetch');
                    }).finally(()=>{
                    this.busy = false;

                })
            },
            getTextState (state) {
                if (state=="0") return 'تایید نشده'
                else return 'تایید شده';
            },
            getColor (state) {
                if (state=="0") return 'orange'
                else return 'green';
            },
            fetchData(){
                this.busy = true;

                axios.get('/vet')
                    .then(response =>{
                        this.dogs = response.data;
                    })
                    .catch(errors =>{
                        // alert('unable to fetch');
                    }).finally(()=>{
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../doglocation/'+id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },


        }
    }

</script>

<style scoped>

</style>
