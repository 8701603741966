<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M418.133,0H93.867C70.34,0,51.2,19.14,51.2,42.667V179.2c0,4.71,3.823,8.533,8.533,8.533h358.4
				c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H68.267v-128c0-14.114,11.486-25.6,25.6-25.6h324.267
				c14.114,0,25.6,11.486,25.6,25.6V435.2c0,14.114-11.486,25.6-25.6,25.6c-4.71,0-8.533,3.823-8.533,8.533v25.6H102.4v-17.067H384
				c4.71,0,8.533-3.823,8.533-8.533S388.71,460.8,384,460.8H93.867c-14.114,0-25.6-11.486-25.6-25.6V213.333
				c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533V435.2c0,20.608,14.686,37.837,34.133,41.805v26.462
				c0,4.71,3.823,8.533,8.533,8.533h324.267c4.71,0,8.533-3.823,8.533-8.533v-26.462c19.447-3.959,34.133-21.197,34.133-41.805
				V42.667C460.8,19.14,441.66,0,418.133,0z"/>
            <path d="M85.333,93.867V128c0,14.114,11.486,25.6,25.6,25.6s25.6-11.486,25.6-25.6V93.867c0-14.114-11.486-25.6-25.6-25.6
				S85.333,79.753,85.333,93.867z M119.467,93.867V128c0,4.702-3.831,8.533-8.533,8.533c-4.702,0-8.533-3.831-8.533-8.533V93.867
				c0-4.702,3.831-8.533,8.533-8.533C115.635,85.333,119.467,89.165,119.467,93.867z"/>
            <path d="M418.133,119.467c4.71,0,8.533-3.823,8.533-8.533V76.8c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533
				v34.133C409.6,115.644,413.423,119.467,418.133,119.467z"/>
            <path d="M418.133,59.733c4.71,0,8.533-3.823,8.533-8.533c0-9.412-7.654-17.067-17.067-17.067c-4.71,0-8.533,3.814-8.533,8.533
				c0,4.71,3.823,8.525,8.533,8.525V51.2C409.6,55.91,413.423,59.733,418.133,59.733z"/>
            <path d="M136.533,315.733V230.4c0-14.114-11.486-25.6-25.6-25.6s-25.6,11.486-25.6,25.6v85.333c0,14.114,11.486,25.6,25.6,25.6
				S136.533,329.847,136.533,315.733z M102.4,315.733V230.4c0-4.702,3.831-8.533,8.533-8.533c4.702,0,8.533,3.831,8.533,8.533
				v85.333c0,4.702-3.831,8.533-8.533,8.533C106.231,324.267,102.4,320.435,102.4,315.733z"/>
		</g>
	</g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
</svg>
</template>

<script>
    export default {
        name: "Appliance"
    }
</script>

<style scoped>

</style>
