<template>
    <div class="test">
        <v-card class="mx-auto rounded-form" max-width="90%" min-width="400px" outlined>

            <v-container
                class="grey lighten-5 mx-auto" align="center"
                fluid
            >
                <v-layout align-center row wrap class="grey lighten-5">

                    <v-flex>
                        <v-row
                            class="mx-auto "
                            no-gutters
                            align="center" justify="space-around">
                            <v-col
                                cols="12"
                                sm="8"
                                lg="8">
                                <v-card
                                    flat
                                    color="#f9f9f9"
                                    min-height="200px"
                                >

                                    <v-row
                                        class="mx-auto"
                                        no-gutters
                                        align="center" justify="space-around">
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            lg="4"
                                        >
                                            <v-card
                                                flat
                                                color="#f9f9f9"
                                                min-height="200px"
                                            >
                                                <v-card
                                                    class="ma-auto my-10 rounded-xl justify-center justify-content-center"
                                                    style="background: linear-gradient(#f9f8f3, #f9f8f3)"
                                                    width="80%"
                                                    height="160px">
                                                    <v-flex>
                                                        <v-avatar
                                                            color="#f1f0ebff"
                                                            size="28px"
                                                            class=" mx-auto">
                                                            <v-icon
                                                                color="#616161"
                                                                size="20px"
                                                                class="mx-auto justify-content-center"
                                                            >
                                                                visibility
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-flex>


                                                    <div class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                        views
                                                    </div>
                                                    <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                        500
                                                    </v-card-title>
                                                    <div class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                        per day
                                                    </div>


                                                </v-card>
                                            </v-card>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            lg="4">
                                            <v-card
                                                flat
                                                color="#f9f9f9"
                                                min-height="200px"
                                            >
                                                <v-card
                                                    class="ma-auto my-10 rounded-xl justify-center justify-content-center"
                                                    style="background: linear-gradient(#f9f8f3, #f9f8f3)"
                                                    width="80%"
                                                    height="160px">
                                                    <v-flex>
                                                        <v-avatar
                                                            color="#f1f0ebff"
                                                            size="28px"
                                                            class=" mx-auto">
                                                            <v-icon
                                                                color="#616161"
                                                                size="20px"
                                                                class="mx-auto justify-content-center"
                                                            >
                                                                explore
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-flex>


                                                    <div class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                        visit
                                                    </div>
                                                    <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                        2000
                                                    </v-card-title>
                                                    <div class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                        per minute
                                                    </div>
                                                </v-card>
                                            </v-card>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            lg="4">
                                            <v-card
                                                flat
                                                color="#f9f9f9"
                                                min-height="200px"
                                            >
                                                <v-card
                                                    class="ma-auto my-10 rounded-xl justify-center justify-content-center"
                                                    style="background: linear-gradient(#f9f8f3, #f9f8f3)"
                                                    width="80%"
                                                    height="160px">
                                                    <v-flex>
                                                        <v-avatar
                                                            color="#f1f0ebff"
                                                            size="28px"
                                                            class=" mx-auto">
                                                            <v-icon
                                                                color="#616161"
                                                                size="20px"
                                                                class="mx-auto justify-content-center"
                                                            >
                                                                store
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-flex>
                                                    <div class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                        orders
                                                    </div>
                                                    <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                        5100
                                                    </v-card-title>
                                                    <div class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                        per day
                                                    </div>
                                                </v-card>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row
                                        class="mx-auto "
                                        no-gutters
                                        align="center" justify="space-around">
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            lg="6">
                                            <v-card
                                                flat
                                                color="#f9f9f9"
                                                min-height="200px"
                                            >
                                                <v-card
                                                    class="ma-auto my-10 rounded-xl"
                                                    style="background: #ffeee2"
                                                    width="80%"
                                                    height="150px">
                                                    <v-row>
                                                        <v-col
                                                            cols="7">
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                                Sales
                                                            </div>
                                                            <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                                2000
                                                            </v-card-title>
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                                Total sales today
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="5">
                                                            <v-progress-circular
                                                                :rotate="360"
                                                                :size="100"
                                                                :width="15"
                                                                value="70"
                                                                color="orange"
                                                            >
                                                                55%
                                                            </v-progress-circular>


                                                        </v-col>
                                                    </v-row>

                                                </v-card>
                                            </v-card>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            lg="6">
                                            <v-card
                                                flat
                                                color="#f9f9f9"
                                                min-height="200px"
                                            >
                                                <v-card

                                                    class="ma-auto my-10 rounded-xl"
                                                    style="background: #eefcef"
                                                    width="80%"
                                                    height="150px">
                                                    <v-row>
                                                        <v-col
                                                            cols="7">
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                                Profit
                                                            </div>
                                                            <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                                $150
                                                            </v-card-title>
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                                per dau ratio
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="5">
                                                            <v-progress-circular
                                                                :rotate="360"
                                                                :size="100"
                                                                :width="15"
                                                                value="30"
                                                                color="green"
                                                            >
                                                                30%
                                                            </v-progress-circular>
                                                        </v-col>
                                                    </v-row>

                                                </v-card>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row
                                        class="mx-auto "
                                        no-gutters
                                        align="center" justify="space-around">
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            lg="6">
                                            <v-card

                                                flat
                                                color="#f9f9f9"
                                                min-height="200px"
                                            >
                                                <v-card
                                                    rounded="rounded-lg"

                                                    class="ma-auto my-10 rounded-xl"
                                                    style="background: #e6f5fa"
                                                    width="80%"
                                                    height="150px">
                                                    <v-row>
                                                        <v-col
                                                            cols="7">
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                                Orders
                                                            </div>
                                                            <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                                1000
                                                            </v-card-title>
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                                total order today
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="5">
                                                            <v-progress-circular
                                                                :rotate="360"
                                                                :size="100"
                                                                :width="15"
                                                                value="80"
                                                                color="blue"
                                                            >
                                                                80%
                                                            </v-progress-circular>


                                                        </v-col>
                                                    </v-row>

                                                </v-card>
                                            </v-card>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            lg="6">
                                            <v-card
                                                shaped
                                                color="#f9f9f9"
                                                flat
                                                min-height="200px"
                                            >
                                                <v-card
                                                    shaped
                                                    class="ma-auto my-10"
                                                    style="background: #f4f5f9"
                                                    width="80%"
                                                    height="150px">
                                                    <v-row>
                                                        <v-col
                                                            cols="7">
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7  mb-0 text-center">
                                                                Visits
                                                            </div>
                                                            <v-card-title class="text-h5 mb-0 justify-center mx-auto">
                                                                400
                                                            </v-card-title>
                                                            <div
                                                                class="v-card-subtitle-dark-section text-h7 mb-0 text-center">
                                                                total visit today
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            cols="5">
                                                            <v-progress-circular
                                                                :rotate="360"
                                                                :size="100"
                                                                :width="15"
                                                                value="70"
                                                                color="purple"
                                                            >
                                                                70%
                                                            </v-progress-circular>


                                                        </v-col>
                                                    </v-row>

                                                </v-card>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <!--                        Black part-->
                            <v-col
                                cols="12"
                                sm="4"
                                lg="4"
                            >
                                <v-card
                                    shaped
                                    width="95%"
                                    style="background: linear-gradient(#7B5765FF , #CFA1BBFF )"
                                    min-height="700px"
                                    max-height="700px"
                                    elevation="24"
                                >
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5 white--text">
                                                Sales Revenue
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-card-text>
                                        <v-row align="center" dense>

                                            <v-col cols="4">
                                                <v-flex class="mx-auto justify-center">
                                                    <v-avatar
                                                        color="white"
                                                        size="50px">
                                                        <v-icon
                                                            color="#616161"
                                                            size="40px"
                                                            class="mx-auto justify-content-center"
                                                        >
                                                            shopping_cart
                                                        </v-icon>
                                                    </v-avatar>

                                                </v-flex>
                                            </v-col>

                                            <v-col
                                                class="text-h4 white--text"
                                                cols="8"
                                            >

                                                <v-card-title class="mx-auto text-h5">
                                                    230k
                                                </v-card-title>
                                                <v-card-text class="v-card-subtitle-dark-section text-h7">
                                                    sales
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-flex class="mx-auto justify-center">
                                                    <v-avatar
                                                        color="white"
                                                        size="50px">
                                                        <v-icon
                                                            color="#616161"
                                                            size="40px"
                                                            class="mx-auto justify-content-center"
                                                        >
                                                            account_circle
                                                        </v-icon>
                                                    </v-avatar>
                                                </v-flex>
                                            </v-col>

                                            <v-col
                                                class="text-h4 white--text"
                                                cols="8"
                                            >

                                                <v-card-title class="mx-auto text-h5">
                                                    300
                                                </v-card-title>
                                                <v-card-text class="v-card-subtitle-dark-section text-h7">
                                                    customers
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-flex class="mx-auto justify-center">
                                                    <v-avatar
                                                        color="white"
                                                        size="50px">
                                                        <v-icon
                                                            color="#616161"
                                                            size="40px"
                                                            class="mx-auto justify-content-center"
                                                        >
                                                            money
                                                        </v-icon>
                                                    </v-avatar>
                                                </v-flex>
                                            </v-col>
                                            <v-col
                                                class="text-h4 white--text"
                                                cols="8"
                                            >
                                                <v-card-title class="mx-auto text-h5">
                                                    $100k
                                                </v-card-title>
                                                <v-card-text class="v-card-subtitle-dark-section text-h7">
                                                    revenue
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-text>
                                        <v-sheet color="rgba(0, 0, 0, .12)">
                                            <v-sparkline
                                                :value="value"
                                                color="rgba(255, 255, 255, .7)"
                                                height="100"
                                                padding="24"
                                                stroke-linecap="round"
                                                smooth
                                            >
                                                <template v-slot:label="item">
                                                    ${{ item.value }}
                                                </template>
                                            </v-sparkline>
                                        </v-sheet>
                                    </v-card-text>

                                    <v-card-text>
                                        <div class="text-h5 font-weight-thin white--text">
                                            Sales Last 24h
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    // import AllUserLocation from "../views/User/AllUserLocation";

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import LiveTracking from "../../../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    import moment from 'jalali-moment/jalali-moment';
    import TechnicianLocationRep from "../../../views/Dog/TechnicianLocationRep";

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            LiveTracking,
            TechnicianLocationRep,
            VueMoment,
            apexchart: VueApexCharts,
            moment
        },
        props: [
            'user',
            'building'
        ],
        data: () => ({
            valueOfChart: [
                423,
                446,
                675,
                510,
                590,
                610,
                760,
            ],
            riskSignals: [],
            headerSignal: [
                {text: 'Date', value: 'date'},
                {text: 'Time', value: 'time'},
                {text: 'Type', value: 'status'},
                // { text: 'حذف', value: 'remove',  sortable: false,},
            ],
            dateStartCaptured: '',
            dateEndCaptured: '',
            alertStartEndCaptured: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            menu3: false,
            row: 'daily',
            last_login_error: '',
            markerCoordinates2: [],
            moments: '',
            users: [],
            selected_user: '',
            errors: '',
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            count: '',
            time: '',
            imagess: [],
            locations: [],
            times: [],
            showImage: false,
            dateTommorow: '',
            datetoGregorian: '',
            dialogVocation: false,


            //Table

            //
            alignments: [
                'start',
                'center',
                'end',

            ],
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1
            //
            //pi chart
            seriesPi: [],
            chartOptionsPi: {
                labels: ['سگ نر، بالغ', 'سگ ماده بالغ', 'سگ نر، توله', 'سگ ماده توله'],
                chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },

            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                    toolbar: false,
                    zoom: {
                        enabled: true
                    },
                },

                xaxis: {
                    categories: ['واکسن 1', 'واکسن 2', 'واکسن 3', 'عقیم '],
                    position: 'top'
                }
            },
            //
            seriesChart3: [{
                name: 'Number of risk',
                data: [{
                    x: '1 Jan.',
                    y: null
                },
                    {
                        x: '2 Jan.',
                        y: 0
                    },
                    {
                        x: '3 Jan.',
                        y: 0
                    },
                    {
                        x: '4 Jan.',
                        y: 0
                    },
                    {
                        x: '5 Jan.',
                        y: null
                    },
                    {
                        x: '6 Jan.',
                        y: 0
                    },
                    {
                        x: '7 Jan.',
                        y: 0
                    },
                    {
                        x: '8 Jan.',
                        y: 0
                    },
                    {
                        x: '9 Jan.',
                        y: 0
                    },
                    {
                        x: '10 Jan.',
                        y: 0
                    },
                    {
                        x: '11 Jan.',
                        y: 0
                    },
                    {
                        x: '12 Jan.',
                        y: null
                    },
                    {
                        x: '13 Jan.',
                        y: null
                    },
                    {
                        x: '14 Jan.',
                        y: 0
                    },
                ],
            }],
            chartOptionsChart3: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: false,
                    shared: false
                },
                theme: {
                    palette: 'palette1'
                },
                xaxis: {
                    type: 'category',
                }
            },
            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }],
            search: '',
            calories: '',
            appliance: [
                {
                    name: 'Refrigerator',
                    brand: 'Sumsung',
                    model: 6.0,
                    date_of_build: 1397,
                    price: 60000000,
                },
                {
                    name: 'vacuum cleaner',
                    brand: 'Sumsung',
                    model: 6.0,
                    date_of_build: 1398,
                    price: 15000000,
                },
                {
                    name: 'TV',
                    brand: 'LG',
                    model: '30inch',
                    date_of_build: 1395,
                    price: 20000000,
                },
            ],

        }),

        created() {
            // console.log(this.$refs.funnel);
            this.moments = moment('2021-01-11 17:01:26', 'YYYY-M-D HH:mm:ss').add(3, 'hours', 30, 'minutes').locale('fa').format('YYYY/M/D HH:mm:ss');
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.fetchData();
            this.loaded = false;
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            this.access_level = this.getAccessLevel();
        },
        methods: {
            updateSeriesLine() {
                this.$refs.realtimeChart.updateSeries([{
                    data: this.seriesChart3[0].data,
                }], false, true);
            },
            fetchRiskSignalInterval() {
                if ((this.dateStartCaptured) && (this.dateEndCaptured)) {
                    if (moment(this.dateStartCaptured).isAfter(this.dateEndCaptured)) {
                        this.alertStartEndCaptured = true;
                    } else {

                        axios.get('../hardwares/getBuildingAlarm/' +
                            this.building.id + '/' + this.dateStartCaptured
                            + '/' + this.dateEndCaptured)
                            .then(response => {
                                console.log(response);
                                // this.dogs = response.data[0].data;
                                this.seriesChart3[0].data = [];
                                // this.test = {x: this.seriesChart3[0].data[0].x, y:this.seriesChart3[0].data[0].y};
                                // this.
                                let startDate = moment(this.dateStartCaptured).format("YYYY-MM-DD");
                                // this.seriesChart3[0].data[0].x = startDate;
                                let dateAxis = this.dateStartCaptured;
                                for (let index = 0; index < response.data.length; ++index) {
                                    this.seriesChart3[0].data[index] = {x: startDate, y: response.data[index]};
                                    dateAxis = moment(dateAxis).add(1, 'days');
                                    startDate = moment(dateAxis).format("YYYY-MM-DD");
                                }
                                // let startDate = moment(this.dateStartCaptured).locale('fa').format('dddd,jDo jMMMM  jYYYY');
                                let endDate = moment(this.dateEndCaptured).format("YYYY-MM-DD");
                                this.seriesChart3[0].data[response.data.length - 1].x = endDate;
                                this.updateSeriesLine();
                            });
                    }
                }
            },
            showCaptureByDate() {
                if ((this.dateStartCaptured) && (this.dateEndCaptured)) {
                    if (moment(this.dateStartCaptured).isAfter(this.dateEndCaptured)) {
                        this.alertStartEndCaptured = true;
                        this.showChart3 = false;
                    } else {
                        axios.post('../homedatas/num/car', {
                            'dateStart': this.dateStartCaptured,
                            'dateEnd': this.dateEndCaptured,
                            'car': this.selected_car
                        })
                            .then(response => {
                                // this.dogs = response.data[0].data;
                                this.seriesChart3[0].data = [];
                                // this.test = {x: this.seriesChart3[0].data[0].x, y:this.seriesChart3[0].data[0].y};
                                // this.
                                let startDate = moment(this.dateStartCaptured).locale('fa').format('dddd,jDo jMMMM');
                                let dateAxis = this.dateStartCaptured;
                                for (let index = 0; index < response.data.length; ++index) {
                                    this.seriesChart3[0].data[index] = {x: startDate, y: response.data[index]};
                                    dateAxis = moment(dateAxis).add(1, 'days');
                                    startDate = moment(dateAxis).locale('fa').format('jYYYY/jM/jD');
                                }
                                // let startDate = moment(this.dateStartCaptured).locale('fa').format('dddd,jDo jMMMM  jYYYY');
                                let endDate = moment(this.dateEndCaptured).locale('fa').format('jYYYY/jM/jD');
                                this.seriesChart3[0].data[0].x = startDate;
                                this.seriesChart3[0].data[response.data.length - 1].x = endDate;
                                this.chartOptionsChart3.chart.toolbar.export.csv = this.selected_car;
                                this.chartOptionsChart3.chart.toolbar.export.png = this.selected_car;
                                this.chartOptionsChart3.chart.toolbar.export.svg = this.selected_car;
                                this.updateSeriesLine();
                                this.showChart3 = true;

                            });
                    }
                }
            },
            filterOnlyCapsText(value, search, item) {
                return value != null &&
                    search != null &&
                    typeof value === 'string' &&
                    value.toString().toLocaleUpperCase().indexOf(search) !== -1
            },
            fetchData() {
                this.busy = true;
                axios.get('/users/technicion')
                    .then(response => {
                        console.log(response.data);
                        this.users = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch1');
                    }).finally(() => {
                    this.busy = false;
                })
            },
            fetchRiskSignal() {
                axios.get('../hardwares/getBuildingAlarm/' + this.building.id + '/' + this.date)
                    .then(response => {
                        // this.dogs = response.data[0].data;
                        console.log(response);
                        this.riskSignals = response.data;
                        this.count = this.riskSignals.length;
                        console.log(this.riskSignals);
                        // let index;
                        // this.count = response.data.length;
                        // this.seriesPi = [0, 0, 0, 0];
                        // this.locations = [];
                        // this.markerCoordinates2 = [];
                        // this.imagess = [];
                        // for (index = 0; index < response.data.length; ++index) {
                        //     this.imagess[index] = '/storage/images/dogs/' + response.data[index].first_fig;
                        //     this.locations[index] = [response.data[index].catched_lon, response.data[index].catched_lat];
                        //     this.times[index] = moment(response.data[index].created_at).locale('fa').format('YYYY/M/D HH:mm:ss');
                        //     if ((response.data[index].age == "توله") && (response.data[index].sex == "نر")) {
                        //         this.seriesPi[2]++;
                        //     } else if ((response.data[index].age == "توله") && (response.data[index].sex == "ماده")) {
                        //         this.seriesPi[3]++;
                        //     } else if ((response.data[index].age == "بالغ") && (response.data[index].sex == "نر")) {
                        //         this.seriesPi[0]++;
                        //     } else if ((response.data[index].age == "بالغ") && (response.data[index].sex == "ماده")) {
                        //         this.seriesPi[1]++;
                        //     }
                        // }
                        this.showImage = true;
                        // // this.count = response.length;
                        // // '/storage/images/dogs/' +
                        // // this.imagess = response;
                        // let selected_id;
                        // if (this.selected_user == "") {
                        //     // selected_id = this.$store.state.users.id;
                        //     this.last_login_error = this.selected_user.last_login_error;
                        // } else {
                        //     selected_id = this.selected_user.id;
                        //     this.last_login_error = 0;
                        // }
                        // this.last_login_error = this.users[selected_id].last_login_error;
                    })
                    .catch(errors => {
                        alert('Please select the date');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            increase() {
                this.height += 10
            },

            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                // axios.put('../user/'+ this.$store.state.users.id , this.form)
                //     .then(response => {
                //         this.$router.push({name: 'dashboard'});
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         // this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },

            getAccessLevel() {
                // if (this.$store.state.users.access_level == "1") return 'مدیریت'
                // else if (this.$store.state.users.access_level == "2") return 'کارشناس'
                // else if (this.$store.state.users.access_level == "3") return 'دکتر آراد کوه'
                // else if (this.$store.state.users.access_level == "4") return 'کارشناس مسئول'
                // else if (this.$store.state.users.access_level == "5") return 'تکنسین'
                // else return 'کاربر تایید نشده'
            },
        },
        computed: {
            headers() {
                return [
                    {
                        text: 'Appliance',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'brand',
                        value: 'brand',
                        filter: value => {
                            if (!this.calories) return true

                            return value < parseInt(this.calories)
                        },
                    },
                    {text: 'model', value: 'model'},
                    {text: 'Production Year', value: 'date_of_build'},
                    {text: 'Price', value: 'price'},
                ]
            },
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .v-card-subtitle-dark-section {
        text-align: justify;
        color: #BDBDBD;
    }

    /*.test {*/
    /*    font-family: IRANSans !important;*/
    /*    color: #3490dc;*/
    /*}*/

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
