var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.dogs,"items-per-page":10,"loading":_vm.busy,"footer-props":{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'items per page',


      }},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","rounded":"","small":""}},[_vm._v("delete\n                ")])]}},{key:"item.assign",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","rounded":"","small":""}},[_vm._v("assign\n                ")])]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","rounded":"","small":""},on:{"click":function($event){return _vm.updateRemoveID(item.id)}}},[_vm._v("edit\n                ")])]}},{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.verified),"dark":""}},[_vm._v("\n                    "+_vm._s(_vm.getTextState(item.verified))+"\n                ")])]}},{key:"expanded-item",fn:function(ref){
      var header = ref.header;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-spacer'),_vm._v(" "),_c('v-container',{attrs:{"fluid":""}},[_c('v-img',{attrs:{"src":'/storage/images/vet/'+ item.fig,"contain":"","max-height":"400px"}}),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":"","align":"center","justify":"center"}},[_c('v-layout',{attrs:{"row":"","wrap":"","align":"center","justify":"center"}},[_c('v-flex',{attrs:{"sm4":"","xs12":"","justify-center":""}},[_c('v-text-field',{attrs:{"label":"شماره نظام","readonly":"","filled":"","rounded":"","dense":""},model:{value:(item.number_of_nezam),callback:function ($$v) {_vm.$set(item, "number_of_nezam", $$v)},expression:"item.number_of_nezam"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"sm4":"","xs12":"","justify-center":"","align-self-center":""}},[_c('v-text-field',{attrs:{"label":"سن","readonly":"","filled":"","rounded":"","dense":""},model:{value:(item.age),callback:function ($$v) {_vm.$set(item, "age", $$v)},expression:"item.age"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"sm4":"","xs12":"","justify-center":"","align-self-center":""}},[_c('v-text-field',{attrs:{"label":"سطح تحصیلات","readonly":"","filled":"","rounded":"","dense":""},model:{value:(item.degree),callback:function ($$v) {_vm.$set(item, "degree", $$v)},expression:"item.degree"}})],1)],1),_vm._v(" "),_c('v-flex',{attrs:{"sm6":"","xs12":"","justify-center":"","align-self-center":""}},[_c('v-text-field',{attrs:{"label":"شماره تماس","readonly":"","filled":"","rounded":"","dense":""},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"sm6":"","xs12":"","justify-center":"","align-self-center":""}},[_c('v-text-field',{attrs:{"label":"توضیحات","readonly":"","filled":"","rounded":"","dense":""},model:{value:(item.comments),callback:function ($$v) {_vm.$set(item, "comments", $$v)},expression:"item.comments"}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }