<template>
    <div style="width: 97%; height: 200px;">
        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <MglNavigationControl position="top-right"/>
            <MglGeolocateControl position="top-right"/>
            <MglMarker :coordinates.sync="markerCoordinates2"
                       color="green">
                <MglPopup>
                    <VCard>
                        <div>من تکنسین هستم</div>
                    </VCard>
                </MglPopup>
            </MglMarker>
            <MglMarker
                :coordinates.sync="markerCoordinates"
                color="red"
            />
        </MglMap>
    </div>
</template>

<script>
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";

    //import MapboxDirections from "@mapbox/mapbox-gl-directions";


    export default {
        name: "LocationForComparison",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        props: [
            'user'
        ],
        computed: {
            coordinates: function () {
                return this.markerCoordinates;
            }

        },
        data() {
            return {
                users: '',
                usersinfos: '',
                colors: "green",
                map: '',

                centers: [25.295682, 54.706806],
                markerCoordinates: '',
                markerCoordinates1: [[52.420296, 35.732379], [53.420296, 35.732379]],
                markerCoordinates2: [53.420296, 35.732379],
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
            };
        },
        created() {
            this.fetchData();
            this.getNewData();
            this.map = mapir;
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/alluserlocation')
                    .then(response => {
                        this.users = response.data[0].data;
                        // this.usersinfos = this.users[1].LatLong;
                        console.log(this.users[1]);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            getNewData() {
                this.busy = true;
                axios.get('/report/index/' + this.$route.params.id)
                    .then(response => {
                        this.usersinfos = response.data;
                        //this.usersinfos = this.users[1].LatLong;
                        this.markerCoordinates = [Number(this.usersinfos.Long), Number(this.usersinfos.Lat)];
                        this.markerCoordinates2 = [Number(JSON.parse(this.usersinfos.id4).Lon), Number(JSON.parse(this.usersinfos.id4).Lat)];
                        // this.map.flyTo({
                        //     center:[52.420296, 35.732379],
                        //     essential: true });
                        console.log(this.users);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
        }


    }
</script>

<style scoped>
    .map-view {
        height: calc(100vh - 48px);
    }
</style>


