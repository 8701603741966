<template>
    <div class="privacy-policy">
        <h1>Privacy Policy for the Users</h1>

        <section v-for="section in sections" :key="section.title">
            <h2 >{{ section.title }}</h2>
            <p v-for="paragraph in section.content" :key="paragraph">{{ paragraph }}</p>
        </section>

        <footer>
            <p>If you have questions about our privacy policy or the data we hold about you, please contact us at:</p>
            <p>Email: <a href="mailto:info@hubbcast.com">info@hubbcast.com</a></p>
            <p>Phone: +37066978994</p>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicy',
        data() {
            return {
                sections: [
                    {
                        title: 'Introduction',
                        content: [
                            'At Hubbcast, we are committed to protecting and respecting your privacy. This policy explains how we use any personal information we collect from you when you use our landing page.'
                        ]
                    },
                    {
                        title: 'What information we collect about you',
                        content: [
                            'We collect information about you when you voluntarily fill out our forms on our pages. This may include your name, company, role, email address, and any other relevant information you provide.'
                        ]
                    },
                    {
                        title: 'How will we use the information',
                        content: [
                            'The information you provide will be used to:',
                            'Respond to your inquiries and feedback.',
                            'Communicate with you about our products, services, and partnership opportunities.',
                            'Conduct market research and analysis.',
                            'We will not share your information with third parties without your explicit consent, unless required by law.'
                        ]
                    },
                    {
                        title: 'Storage & Security',
                        content: [
                            'Your data will be stored on secure servers. We have implemented necessary security measures to ensure your personal data is not lost, accessed, used, altered, disclosed, or accidentally destroyed without authorization.'
                        ]
                    },
                    {
                        title: 'Access to your information and correction',
                        content: [
                            'You have the right to request a copy of the information we hold about you. If you would like a copy, please email info@hubbcast.com. We aim to ensure that your personal information is accurate and up-to-date. You may ask us to correct or remove information you think is inaccurate.'
                        ]
                    },
                    {
                        title: 'Cookies',
                        content: [
                            'Our pages uses cookies to ensure the best user experience. By using our pages, you agree to our use of cookies.'
                        ],

                    },
                    {
                        title: 'Changes to our privacy policy',
                        content: [
                            'We keep our privacy policy under regular review, and we will place any updates on this page. This privacy policy was last updated on September 18, 2023.'
                        ],
                    },
                ]
            }
        }
    }
</script>

<style scoped>
    .privacy-policy {
        padding: 20px;
        max-width: 1000px;
        margin: 0 auto;
        font-family: Arial, sans-serif;
    }

    h1 {
        color: #333;
        font-size: 35px;
        margin-bottom: 60px;
        margin-top: 30px;
        font-weight: bolder;
        text-align: center;
    }

    h2 {
        color: #444;
        font-size: 20px;
        margin-top: 60px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    p {
        margin-bottom: 15px;
        line-height: 1.5;
    }

    footer {
        margin-top: 40px;
        border-top: 1px solid #ddd;
        padding-top: 20px;
    }

    a {
        color: #007BFF;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
</style>
