import { render, staticRenderFns } from "./ProfilesNotApproved.vue?vue&type=template&id=2d836038&scoped=true&"
import script from "./ProfilesNotApproved.vue?vue&type=script&lang=js&"
export * from "./ProfilesNotApproved.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d836038",
  null
  
)

export default component.exports