<template>
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m256 32c-141.4 0-256 93.1-256 208 0 49.6 21.4 95 57 130.7-12.5 50.4-54.3 95.3-54.8 95.8-2.2 2.3-2.8 5.7-1.5 8.7s4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208s-114.6-208-256-208zm-128 240c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/>
    </svg>
</template>

<script>
    export default {
        name: "smsLCIcon"
    }
</script>

<style scoped>

</style>
