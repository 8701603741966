import Vue from 'vue'
import VueRouter from 'vue-router'
import ExampleComponent from "./components/ExampleComponent";
import Logout from "././Customer/Actions/LogoutCustomer";
import RegisterVet from "./views/PublicReport/PublicApp/RegisterVet";
import Dashboard from "./Customer/Dashboard/Dashboard";
import HomeProfile from "./Customer/Home Statistic/HomeProfile";
import BuyInsurance from "./Customer/Request/BuyInsurance";
import ServiceRequest from "./Customer/Request/ServiceRequest";
import DamageEstRequest from "./Customer/Request/DamageEstRequest";
import Ticketing from "./Customer/Request/Ticketing";
import AddNewSensor from "./Customer/Statictics/AddNewSensor";
import AddNewAppliance from "./Customer/Statictics/AddNewAppliance";
import DetailReport from "./Customer/Statictics/DetailReport";
import Users from "./Customer/Users/Users";
import AddUser from "./Customer/Users/AddUser";
import ProfileCustomer from "./Customer/Profile/ProfileCustomer";
import LogoutCustomer from "./Customer/Actions/LogoutCustomer";
import SupportRequest from "./Customer/Request/SupportRequest";
import ShowMessage from "./Customer/Request/ShowMessage";
import ModuleReport from "./Customer/Statictics/ModuleReport";
import Login from "./Customer/Auth/Login";
import Register from "./Customer/Auth/Register";
import NotFound from "./Customer/Auth/NotFound";
import propertyManagement from "./Customer/Dashboard/propertyManagement";
import homeownerData from "./Customer/Dashboard/homeownerData";
import homeDataPanel from "./Customer/Dashboard/HomeData/homeDataPanel";

Vue.use(VueRouter);
const hostname = window.location.hostname;

const baseUrl = ['hubbcast.com', 'localhost'].includes(hostname) || hostname.endsWith('.hubbcast.com')
    ? '/customer'
    : '/';

const routePath = baseUrl === '/customer' ? '/:business' : '';
const router = new VueRouter({
    mode: 'history',
    base: baseUrl,

    routes: [
        {
            path: `${routePath}/login`,
            name: 'login',
            component: Login,
            props: true,
        },
        {
            path: `${routePath}/register`,
            name: 'register',
            component: Register,
            props: true,

        },
        {
            path: `${routePath}/adduser`, name: 'adduser',
            component: AddUser,
            props: true,
        },
        {
            path: `${routePath}/users`, name: 'users',
            component: Users,
            props: true,
        },
        {
            path: `${routePath}/detailreport`, name: 'detailreport',
            component: DetailReport,
            props: true,
        },
        {
            path: `${routePath}/moduledata`, name: 'moduledata',
            component: ModuleReport,
            props: true,
        },
        {
            path: `${routePath}/addsensor`, name: 'addsensor',
            component: AddNewSensor,
            props: true,
        },
        {
            path: `${routePath}/addappliance`, name: 'addappliance',
            component: AddNewAppliance,
            props: true,
        },
        {
            path: `${routePath}/ticketing`, name: 'ticketing',
            component: Ticketing,
            props: true,
        },
        {
            path: `${routePath}/support`, name: 'support',
            component: SupportRequest,
            props: true,
        },
        {
            path: `${routePath}/damage`, name: 'damage',
            component: DamageEstRequest,
            props: true,
        },
        {
            path: `${routePath}/service`, name: 'service',
            component: ServiceRequest,
            props: true,
        },
        {
            path: `${routePath}/buy`, name: 'buy',
            component: BuyInsurance,
            props: true,
        },

        // {
        //     path: `${routePath}/`, name: 'home',
        //     props: true,
        //     component: Dashboard
        // },
        {
            path: `${routePath}/dashboard`,
            name: 'dashboard',
            component: Dashboard,
            props: true,
            redirect: { name: 'propertyManagement' }, // Redirects to default child route
            children: [
                // {
                //     path: '/',
                //     name: 'propertyManagement',
                //     component: propertyManagement
                // },
                {
                    path: 'propertyManagement',
                    name: 'propertyManagement',
                    component: propertyManagement
                },
                {
                    path: 'homeownerData',
                    name: 'homeownerData',
                    component: homeownerData
                },
                {
                    path: 'homeownerData/:id',
                    name: 'homeDataPanel',
                    component: homeDataPanel,
                    props: true
                },
                {
                    path: 'homeprofile',
                    name: 'homeprofile',
                    component: HomeProfile,
                    props: true
                },
            ]
        },
        {
            path: `${routePath}/`,
            component: Dashboard,
            props: true,
            children: [
                {
                    path: '',
                    name: 'home',
                    component: propertyManagement
                },
            ]
        },
        // {
        //     path: `${routePath}/dashboard`, name: 'dashboard',
        //     component: Dashboard,
        //     props: true,
        // },
        {
            path: `${routePath}/logout`, name: 'logout',
            props: true,
            component: LogoutCustomer
        },
        {
            path: `${routePath}/showmessage`, name: 'showmessage',
            component: ShowMessage,
            props: true,
        },
        {
            path: `${routePath}/customerprofile`, name: 'customerprofile',
            props: true,
            component: ProfileCustomer
        },
        {
            path: `${routePath}/*`, name: 'NotFound',
            component: NotFound,
            props: true,
        },
    ],

});

router.beforeEach((to, from, next) => {
    console.log('routerCustomer');
    const isAuthenticated = localStorage.getItem('isCustomerAuthenticated');
    const business = to.params.business;
    const supportedDomains = ['hubbcast.com', 'localhost'];
    const isCustomDomain = !supportedDomains.includes(window.location.hostname) && !window.location.hostname.endsWith('.hubbcast.com');

    if (to.name !== 'login' && to.name !== 'register' ) {
        if (!isAuthenticated) {
            if (isCustomDomain) {
                console.log('yes, I am here, custom domain');
                next({ name: 'login' });
            } else {
                console.log('yes, I am here, hubbcast domain');
                next({ name: 'login', params: { business: business } });
            }
        } else {
            console.log('yes, I am here, other route inside application');
            next();
        }
    } else {

        if (isAuthenticated) {
            if (isCustomDomain) {
                console.log('yes, I am here, redirect to dashboard from custom domain');
                next({ name: 'propertyManagement' });
            } else {
                console.log('yes, I am here, redirect to dashboard from hubbcast');
                next({ name: 'propertyManagement', params: { business: business } });
            }
        } else {
            console.log('yes, I am here,checking login and register directly');
            next();
        }
    }


});


export default router;

// if (!isAuthenticated && to.name !== 'login' && to.name !== 'register') {
//     if (isCustomDomain) {
//         next({ name: 'login' });
//     } else {
//         next({ name: 'login', params: { business: business } });
//     }
//     return;
// }
//
// if (isAuthenticated && (to.name === 'login' || to.name === 'register')) {
//     if (isCustomDomain) {
//         next({ name: 'dashboard' });
//     } else {
//         next({ name: 'dashboard', params: { business: business } });
//     }
//     return;
// }
// // If the business parameter does not start with '@', redirect to a 404 page.
// if (!isCustomDomain && business && !business.startsWith('@')) {
//     next({ name: 'login', params: { business: business } });
//     return;
// }
//
//
// next();
