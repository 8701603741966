<template>
    <div class="card-devices" >
        <div class="header">
            <h1> Test </h1>
        </div>
        <div df-name class="body <style>
      body {
        background-color: #1c87c9;
      }
    </style>" >
            <span @click="Clicked"> Name :</span>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            Clicked() {
                alert("hello-drawflow");
            }
        }
    }
</script>
