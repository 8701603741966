<template>
    <v-app v-if="!$store.state.isCustomerAuthenticated">
        <template v-if="$route.name == 'login' || $route.name == 'NotFound'">
            <Login @customerInfo="handleLogin"></Login>
        </template>
        <template v-else-if="$route.name == 'register'">
            <Register></Register>
        </template>
        <template v-else>
            <NotFound></NotFound>
        </template>
    </v-app>
    <v-app id="inspire" v-else>
        <v-navigation-drawer
            v-if="isHeaderLarge"
            app
            dense
            permanent
            absolute
            v-model="drawer"
            :mini-variant.sync="mini"
            left
        >
            <v-list-item class="px-2 mx-auto close-button-item">

<!--                <v-list-item-avatar>-->
<!--                    <v-img-->
<!--                        contain-->
<!--                        width="90%"-->
<!--                        :src="isLink(customer.pic) ? customer.pic : '/storage/images/customer/' + customer.pic"-->
<!--                    ></v-img>-->
<!--                </v-list-item-avatar>-->

<!--                <v-list-item-title :style="fontSizeStyle">{{customer.first_name + ' ' + customer.surname}}-->
<!--                </v-list-item-title>-->
                <v-btn icon @click.stop="toggleMini">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense>

                <v-list-item two-lifne>
                    <v-list-item-avatar tile>
                        <img src="../../assets/icons/home.png">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title> 3</v-list-item-title>
                        <v-list-item-subtitle>Online homes</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list-item
                flat="true"
                dense
                :append="true"
                class="mt-4 "
                link
                :to="{name:'propertyManagement'}"
            >
                <v-list-item-action>
                    <v-icon color="grey darken-1">dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-title class="list-title-style text--darken-1">Dashboard</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>mdi-message-text-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="list-title-style text--darken-2"> Requests</v-list-item-title>
                        </template>

                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'buy'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">add_shopping_cart</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Purchase, renew and upgrade policies
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'service'}"

                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">build</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Building services</v-list-item-title>
                        </v-list-item>


                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'damage'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">notification_important</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Insurance claim</v-list-item-title>
                        </v-list-item>


                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'support'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">contact_support</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Support and Consultancy
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'ticketing'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">mail_outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Inbox</v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>
                <v-divider></v-divider>

                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>mdi-home-analytics</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="list-title-style text--darken-2">Home Statistics
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'detailreport'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">assessment</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Sensor info
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'addsensor'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.sensor</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add a new module
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'addappliance'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.custom</v-icon>
                                <!--                                <v-icon color="grey darken-1">euro_symbol</v-icon>-->
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add new appliances</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-divider></v-divider>

                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="list-title-style text--darken-2">User panel</v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'users'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">account_box</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">User information</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'adduser'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">person_add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add user</v-list-item-title>
                        </v-list-item>
                        <!--                // User Tracking-->
                    </v-list-group>
                </v-list>
                <v-divider></v-divider>


                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon color="grey darken-1">mdi-file-document</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="list-title-style ">User area</v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'customerprofile'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">settings</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">User profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'logout'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">exit_to_app</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Exit</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-list>

        </v-navigation-drawer>
        <v-app-bar
            v-if="isHeaderLarge "
            dark
            app
            clipped-right
            color="transparent"
            style="background-image: linear-gradient(135deg, #7B5765FF  0%, #7B5765FF  100%);"
        >
            <v-spacer></v-spacer>
            <v-toolbar-title class="mr-1 align-center">
                {{businessData.name}}
            </v-toolbar-title>
            <!--            <v-list-item-avatar class="mx-4" tile style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); width: 80px; height: 80px;">-->
            <!--                <v-img-->
            <!--                    contain-->
            <!--                    :src="'/storage/images/businesses/'+ businessData.logo"-->
            <!--                    style="width: 100%; height: 100%;"-->
            <!--                >-->
            <!--                </v-img>-->
            <!--            </v-list-item-avatar>-->
            <v-list-item-avatar class="mx-4" tile style="width: 80px; height: 80px;">
                <v-img
                    contain
                    :src="'/storage/images/businesses/'+ businessData.logo"
                    style="width: 100%; height: 100%;"
                >
                </v-img>
            </v-list-item-avatar>
            <!--            -->


        </v-app-bar>

        <v-navigation-drawer
            v-if="isHeaderSmall || isHeaderMedium"
            temporary
            v-model="drawer"
            app
            left
        >
            <v-list dense>

                <v-list-item two-lifne>
                    <v-list-item-avatar tile>
                        <img src="../../assets/icons/home.png">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title> 3</v-list-item-title>
                        <v-list-item-subtitle>Online homes</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <v-list-item
                flat="true"
                dense
                :append="true"
                class="mt-4"
                link
                :to="{name:'propertyManagement'}"
            >
                <v-list-item-action>
                    <v-icon color="grey darken-1">dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">Dashboard</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title class="grey--text text--darken-2"> Requests</v-list-item-title>
                        </template>

                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4"
                            link
                            :to="{name:'buy'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">add_shopping_cart</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Purchase, renew and upgrade policies
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4"
                            link
                            :to="{name:'service'}"

                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">build</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Building services</v-list-item-title>
                        </v-list-item>


                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4"
                            link
                            :to="{name:'damage'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">notification_important</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Insurance claim</v-list-item-title>
                        </v-list-item>


                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4"
                            link
                            :to="{name:'support'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">contact_support</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Support and Consultancy
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            flat="true"
                            dense
                            class="mt-4"
                            link
                            :to="{name:'ticketing'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">mail_outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Inbox</v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>
                <v-divider></v-divider>

                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title class="grey--text text--darken-2">Smart home statistics
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'detailreport'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">assessment</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Sensor info
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'addsensor'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.sensor</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add a new module
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'addappliance'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.custom</v-icon>
                                <!--                                <v-icon color="grey darken-1">euro_symbol</v-icon>-->
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add new appliances</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-divider></v-divider>

                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title class="grey--text text--darken-2">User panel</v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'users'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">account_box</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">User information</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'adduser'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">person_add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add user</v-list-item-title>
                        </v-list-item>
                        <!--                // User Tracking-->
                    </v-list-group>
                </v-list>
                <v-divider></v-divider>


                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title class="grey--text text--darken-2">User area</v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'customerprofile'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">settings</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">User profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'logout'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">exit_to_app</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Exit</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-list>

        </v-navigation-drawer>


        <v-app-bar
            v-if="isHeaderSmall || isHeaderMedium"
            dark
            app
            color="transparent"
            style="background-image: linear-gradient(135deg, #7B5765FF  0%, #7B5765FF  100%);"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-toolbar-title class="mr-1 align-center">
                {{businessData.name}}
            </v-toolbar-title>
            <!--            <v-list-item-avatar class="mx-4" tile style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); width: 80px; height: 80px;">-->
            <!--                <v-img-->
            <!--                    contain-->
            <!--                    :src="'/storage/images/businesses/'+ businessData.logo"-->
            <!--                    style="width: 100%; height: 100%;"-->
            <!--                >-->
            <!--                </v-img>-->
            <!--            </v-list-item-avatar>-->
            <v-list-item-avatar class="mx-4" tile style="width: 80px; height: 80px;">
                <v-img
                    contain
                    :src="'/storage/images/businesses/'+ businessData.logo"
                    style="width: 100%; height: 100%;"
                >
                </v-img>
            </v-list-item-avatar>
            <!--            -->


        </v-app-bar>

        <v-main class="mb-15">
            <router-view></router-view>
        </v-main>

        <v-bottom-navigation
            fixed
            value="true"
            color="teal"
            grow
        >
            <v-btn :to="{name:'ticketing'}">
                <span>Inbox</span>
                <v-icon>mail_outline</v-icon>
            </v-btn>

            <v-btn :to="{name:'dashboard'}">
                <span>Dashboard</span>

                <v-icon>dashboard</v-icon>
            </v-btn>


            <v-btn :to="{name:'logout'}">
                <span>Exit</span>

                <v-icon>exit_to_app</v-icon>
            </v-btn>

        </v-bottom-navigation>

    </v-app>


</template>

<script>

    import vueFilePond, {setOptions} from 'vue-filepond';

    let currentDate = Date.now()
    // setOptions({
    //     fileRenameFunction: (file) => {
    //         return currentDate + `${file.extension}`;
    //     }
    // })
    // setOptions({
    //     chunkUploads: true,
    //     chunkForce: true,
    //     server: {
    //         url: "/uploads",
    //         headers: {
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         process: {
    //             method: 'POST',
    //         },
    //     }
    // });
    import Login from "./Auth/Login";
    import Register from "./Auth/Register";
    import NotFound from "./Auth/NotFound";
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize
        from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    import PublicMessage from "./Request/ServiceRequest";
    import RegisterSupporters from "./Request/BuyInsurance";
    import RegisterVet from "./Request/DamageEstRequest";


    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {

        created() {
            // console.log(this.businessData);
            this.$store.commit("updateBusiness", this.businessData);
            if (localStorage.getItem('isCustomerAuthenticated')) {
                console.log(' isCustomerAuthenticated is true')
                this.$store.commit('setCustomerAuthenticated', true);
                this.customerCookie = this.getCookie('customerInfo');
                this.customer = this.$store.state.customers;
                if (this.getCookie('customerInfo')) {
                    this.customer = this.getCookie('customerInfo');
                    this.$store.commit('updateCustomer', this.customer);
                }
            }
            this.$store.commit("updateCustomer", this.customer);
            console.log('created customer app');
            // console.log(this.businessData);
        },
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond,
            PublicMessage,
            RegisterSupporters,
            RegisterVet,
            Login,
            Register,
            NotFound

        },
        props: [
            'citizen',
            'businessData'
            // 'locationLatLong',
        ],
        data() {
            return {
                mini: false,
                customerCookie: '',
                customer: [],
                selectComponent: 1,
                video: {},
                canvas: {},
                captures: [],
                snackbar: false,
                text: 'Hello, I\'m a snackbar',
                sampleFig: '',
                picID2: '',


            }
        },
        data: function () {
            return {
                mini: false,
                drawer: true,
                selectComponent: 1,

                //myFiles: ['index.html'],
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '', 'comments': '', 'cell_number': '', 'fig': '', 'catched_lon': '', 'catched_lat': ''
                    },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                sampleFig: '',
                picID2: ''

            }
        },

        mounted: function () {
            // console.log('mounted');
            // console.log(this.businessData);
            // this.$store.commit("updateBusiness", this.businessData);
            // if (localStorage.getItem('isCustomerAuthenticated')) {
            //     this.$store.commit('setCustomerAuthenticated', true);
            //     this.customerCookie = this.getCookie('customerInfo');
            //     this.customer = this.$store.state.customers;
            //     if (this.getCookie('customerInfo')) {
            //         this.customer = this.getCookie('customerInfo');
            //         this.$store.commit('updateCustomer', this.customer);
            //     }
            // }

            // this.Bol = true;
            // if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // }
            // ;
        },
        computed: {
            // handleProcessFile: function(error, file) {
            //     this.form.fig = file.serverId;
            //     this.picID2 = file;
            //     alert(file.serverId);
            //     // this.$store.commit("updatePublicFig", file.serverId);
            //
            //     // this.setFigValue;
            //     // let response = JSON.parse(file.serverId);
            //     // this.$emit("response", response);
            //     // this.setFigValue(file.serverId);
            //     // console.log(this.form.fig);
            // },
            picID: function (file) {
                this.picID2 = file;
            },
            fontSizeStyle() {
                const firstNameLength = this.user && this.user.first_name ? this.user.first_name.length : 0;
                const surnameLength = this.user && this.user.surname ? this.user.surname.length : 0;
                const length = firstNameLength + surnameLength;
                let fontSize;

                if (length <= 10) {
                    fontSize = '16px';  // Use a larger font size for shorter names
                } else if (length <= 20) {
                    fontSize = '14px';  // Use a medium font size for medium-length names
                } else {
                    fontSize = '12px';  // Use a smaller font size for longer names
                }

                return {fontSize};
            },
            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true;
                    case 'sm':
                        return false;
                    case 'md':
                        return false;
                    case 'lg':
                        return false;
                    case 'xl':
                        return false;
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods: {
            toggleMini() {
                this.mini = !this.mini;
                // this.drawer = !this.drawer;
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            handleLogin(customerInfo) {
                this.customer = customerInfo;
            },
            updateSetOption: function () {
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            getCurrentLocation: function () {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                    })
                }
            },

            callback(msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function (error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            addNewDog() {
                this.updateSetOption();
                this.busy = true;
                this.getCurrentLocation();
                if (this.form.catched_lon === '') {
                    this.snackbar = true;
                    this.text = 'برای ثبت موقعیت مکانی، لطفا مکانیاب خود را فعال نمایید.';
                    this.busy = false;
                } else {
                    axios.post('../report/message', this.form)
                        .then(response => {
                            console.log(response);
                            this.snackbar = true;
                            this.text = 'ممنون از همکاری شما. گزارش کار به محضر شما خواهد رسید.';
                            // this.text = this.form.fig;
                        })
                        .catch(errors => {
                            this.text = errors.response.data.errors;

                            //'name':'','comments':'', 'cellnumber':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                            this.snackbar = true;
                            // this.text = 'لطفا فیلدها را کامل پرکنید';                            this.text = 'لطفا فیلدها را کامل پرکنید';

                            //this.text = this.form.fig;
                            // console.log(this.form);
                            // this.errors = errors.response.data.errors;
                            console.log(errors);
                        }).finally(() => {
                        this.busy = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/

    .list-item-with-padding {
        padding-left: 35px !important; /* Add left padding for list items */
    }

    .list-title-style {
        font-size: 4rem; /* Increase font size */
        font-weight: bold; /* Make it bold */
        padding-left: 0; /* Remove left padding */
    }

    .close-button-item {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }

</style>
