<template>
    <div class="terms-of-use">
        <h1>Terms of Use for Hubbcast</h1>

        <section v-for="section in sections" :key="section.title">
            <h2>{{ section.title }}</h2>
            <p v-for="paragraph in section.content" :key="paragraph">{{ paragraph }}</p>
        </section>

        <footer>
            <p>For questions related to these Terms, please contact us at:</p>
            <p>Email: <a href="mailto:info@hubbcast.com">info@hubbcast.com</a></p>
            <p>Phone: +37066978994</p>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'TermsOfUse',
        data() {
            return {
                sections: [
                    {
                        title: 'Introduction',
                        content: [
                            'Welcome to Hubbcast. By accessing or using our website and services, you agree to adhere to these Terms of Use ("Terms"). If you disagree with any part of these Terms, please refrain from using our services.'
                        ]
                    },
                    {
                        title: 'Registration and User Information',
                        content: [
                            'When registering on our site or filling out forms, you may be asked to provide specific personal information. You agree to provide accurate, current, and complete information and to update this information as necessary.'
                        ]
                    },
                    {
                        title: 'Use of Our Services',
                        content: [
                            'You are granted a non-exclusive, limited, and revocable license to use our services in compliance with these Terms. Any unauthorized use of our platform or services is strictly prohibited.'
                        ]
                    },
                    {
                        title: 'Data Protection and Privacy',
                        content: [
                            'Your privacy is paramount to us. All personal data provided will be treated in accordance with our Privacy Policy. Please review it for more information on how we handle your personal data.'
                        ]
                    },
                    {
                        title: 'Limitation of Liability',
                        content: [
                            'Hubbcast and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, arising from the use of its services or any content therein.'
                        ]
                    },
                    {
                        title: 'Governing Law',
                        content: [
                            'These Terms shall be governed by and construed in accordance with the laws of the Republic of Lithuania. Any disputes arising from or relating to these Terms will be subject to the jurisdiction of the courts located within Lithuania.'
                        ]
                    },

                    {
                        title: 'Changes to Terms of Use',
                        content: [
                            'Hubbcast reserves the right to modify or replace these Terms at any time. Updated versions will appear on our website, and the date of the latest revision will be posted. Please check back frequently to ensure you are familiar with the current Terms.'
                        ]
                    },
                    // ... Add more sections as needed
                ]
            }
        }
    }
</script>

<style scoped>
    .terms-of-use {
        padding: 20px;
        max-width: 1000px;
        margin: 0 auto;
        font-family: Arial, sans-serif;
    }

    h1 {
        color: #333;
        font-size: 35px;
        margin-bottom: 60px;
        margin-top: 30px;
        font-weight: bolder;
        text-align: center;
    }

    h2 {
        color: #444;
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    p {
        margin-bottom: 15px;
        line-height: 1.5;
    }

    footer {
        margin-top: 40px;
        border-top: 1px solid #ddd;
        padding-top: 20px;
    }

    a {
        color: #007BFF;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
</style>
