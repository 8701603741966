import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "./Panel/Dashboard/Dashboard";
import NewApplication from "./Panel/NewApplication/NewApplication";
import MailBox from "./Panel/MailBox/MailBox";
import UserSetting from "./Panel/UserSetting/UserSetting";
import Wallet from "./Panel/Wallet/Wallet";
import BecomePartner from "./Panel/BecomePartner/BecomePartner";
import InsurerDashboard from "./Panel/Dashboard/InsurerProfile/InsurerDashboard";
import Login from "./Panel/Auth/Login";
import Register from "./Panel/Auth/Register";
import NotFound from "./Panel/Auth/NotFound";
import Logout from "./Panel/Auth/Logout";
import InsurerOverview from "./Panel/Dashboard/InsurerProfile/InsurerProfile"
import LowCodePlatform from "./Panel/Dashboard/InsurerProfile/InsurerLCPlatform"
import SoftwareMarketplace from "./Panel/Dashboard/InsurerProfile/ServiceMarketplace"
import HardwareMarketplace from "./Panel/Dashboard/InsurerProfile/HardwareMarketplace"
import SettingBusiness from "./Panel/Dashboard/InsurerProfile/Setting"
import MyWallets from "./Panel/Wallet/Components/MyWallets"
import TransactionHistory from "./Panel/Wallet/Components/TransactionHistory";
import TransferFunds from "./Panel/Wallet/Components/TransferFunds";
import Reports from "./Panel/Wallet/Components/Reports";
import SupportFAQ from "./Panel/Wallet/Components/SupportFAQ";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '/panel',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/register',
            name: 'register',
            component: Register,

        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
        },
        {
            path: '/',
            name: 'home',   // Change this name to 'home' or something else unique
            component: Dashboard,
        },
        // {
        //     path: '/insurerProfile/:businessUniqueAddress',
        //     name: 'insurerProfile',
        //     component: InsurerDashboard,
        //     props: true
        // },
        {
            path: `/insurerProfile/:businessUniqueAddress/`,
            component: InsurerDashboard,
            props: true,
            children: [
                {
                    path: '',
                    name: 'InsurerOverview',
                    component: InsurerOverview
                },
            ]
        },
        {
            path: `/insurerProfile/:businessUniqueAddress`,
            name: 'insurerProfile',
            component: InsurerDashboard,
            props: true,
            redirect: {name: 'InsurerOverview'}, // Redirects to default child route
            children: [
                {
                    path: 'InsurerOverview',
                    name: 'InsurerOverview',
                    component: InsurerOverview
                },
                {
                    path: 'LowCodePlatform',
                    name: 'LowCodePlatform',
                    component: LowCodePlatform
                },
                {
                    path: 'SoftwareMarketplace',
                    name: 'SoftwareMarketplace',
                    component: SoftwareMarketplace
                },
                {
                    path: 'HardwareMarketplace',
                    name: 'HardwareMarketplace',
                    component: HardwareMarketplace
                },
                {
                    path: 'Setting',
                    name: 'SettingBusiness',
                    component: SettingBusiness
                },
            ]
        },
        {
            path: '/newApp',
            name: 'newApp',
            component: NewApplication,
        },
        {
            path: '/mailbox',
            name: 'mailbox',
            component: MailBox,
        },
        {
            path: '/setting',
            name: 'setting',
            component: UserSetting,
        },
        {
            path: `/wallet/`,
            component: Wallet,
            props: true,
            children: [
                {
                    path: '',
                    name: 'MyWallets',
                    component: MyWallets
                },
            ]
        },
        {
            path: `/wallet`,
            name: 'wallet',
            component: Wallet,
            props: true,
            redirect: {name: 'MyWallets'}, // Redirects to default child route
            children: [
                {
                    path: 'MyWallets',
                    name: 'MyWallets',
                    component: MyWallets
                },
                {
                    path: 'TransactionHistory',
                    name: 'TransactionHistory',
                    component: TransactionHistory
                },
                {
                    path: 'TransferFunds',
                    name: 'TransferFunds',
                    component: TransferFunds
                },
                {
                    path: 'Reports',
                    name: 'Reports',
                    component: Reports
                },
                {
                    path: 'Support',
                    name: 'SupportFAQ',
                    component: SupportFAQ
                },
            ]
        },
        {
            path: '/becomePartner',
            name: 'becomePartner',
            component: BecomePartner,
        },
        {
            path: '/logout', name: 'logout',
            component: Logout
        },
        {
            path: '/*', name: 'NotFound',
            component: NotFound
        },
    ],

})
router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('isDeveloperAuthenticated');
    console.log(isAuthenticated);
    if (to.name !== 'login' && to.name !== 'register') {
        if (!isAuthenticated) {
            next({name: 'login'});
        } else {
            next();
        }
    } else {
        if (isAuthenticated && to.name !== 'dashboard') {
            next({name: 'dashboard'});
        } else {
            next();
        }
    }
});


export default router;
