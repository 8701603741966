<template>
    <div class="test">
        <v-card class="mx-auto my-6 rounded-form" width="90%" max-width="1200" outlined elevation="10">
            <v-card-title primary-title class="justify-lg-start">Available applications</v-card-title>
            <v-divider class="mx-4 my-3"></v-divider>
            <v-container class="grey lighten-5 mb-6 mx-auto" align="center">
                <v-row align="center" justify="space-around">
                    <v-col v-if="!businesses.length && !busy" lg="4" md="6" cols="12" class="mx-auto">
                        <v-card class="mx-auto">
                            <v-card-text>No registered application</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="businesses.length && !busy" lg="4" md="6" cols="12" v-for="(business, index) in businesses" :key="index">
                        <v-card class="my-3 mx-auto" width="98%" rounded="10" color="#cfa1bbff">
                            <v-row no-gutters align="center" justify="space-around">
                                <v-col cols="12">
                                    <v-card class="my-3 mx-auto" width="100%" rounded="10" color="#cfa1bbff">
                                        <v-row justify="space-around">
                                            <v-avatar class="profile" color="indigo lighten-5" size="164">
                                                <v-img contain width="90%" :src="'/storage/images/businesses/'+ business.logo"></v-img>
                                            </v-avatar>
                                        </v-row>
                                        <v-row class="my-2" justify="space-around" dense>
                                            <v-btn depressed color="#b2829dff" class="white--text" @click="$router.push({name: 'insurerProfile', params:{business: business, businessUniqueAddress: '@' + business.unique_address}})">
                                                More info!
                                            </v-btn>
                                        </v-row>
                                        <v-divider class="mx-2 my-3"></v-divider>
                                        <business-details :business="business"></business-details>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import VueMoment from 'vue-jalali-moment';
    import BusinessDetails from './BusinessDetails.vue'; // import your reusable component

    export default {
        components: {
            VueMoment,
            apexchart: VueApexCharts,
            BusinessDetails
        },
        props: ['user'],
        data: () => ({
            businesses: [],
            busy: false
        }),
        created() {
            this.busy = true;
        },
        async mounted() {
            try {
                const response = await axios.get('/developerBusinesses');
                this.businesses = response.data;
            } catch (errors) {
                console.error('Error fetching data:', errors.response.data.errors);
            } finally {
                this.busy = false;
            }
        },
        methods: {
            formatUrl(url) {
                if (!/^https?:\/\//i.test(url)) {
                    url = 'http://' + url;
                }
                return url;
            }
        }
    }
</script>

<style scoped>
    .text-no-wrap {
        white-space: nowrap;
    }

    .text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    .v-btn {
        text-transform: capitalize
    }

    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    >>> .text-field {
        border-radius: 20px !important;
    }

    >>> .text-field2 {
        font-size: 12px;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
