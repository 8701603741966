<template>

    <div class="test">
        <v-snackbar v-model="snackbarSetHoliday" color="#f2c4d3" :timeout="3000" centered>
            <span style="color: #333333;">
                Successfully registered.
            </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>

        <v-container fluid>
            <v-text class="text-h5 ml-10 text-bold black--text mb-25 ">
                Account
            </v-text>
            <v-card
                color="grey lighten-5"
                class="mx-auto mt-15 rounded-form mb-15"
                width="80%"
                elevation="10"
            >
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-text-field
                            :value="$store.state.developers.first_name"
                            class="mx-auto"
                            dense
                            outlined
                            rounded
                            color="purple darken-2"
                            label="First name"
                            required

                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-text-field
                            :value="$store.state.developers.surname"
                            class="mx-auto"
                            dense
                            outlined
                            rounded
                            color="blue darken-2"
                            label="Last name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-select
                            v-model="sex"
                            class="mx-auto"
                            dense
                            :items="['Male', 'Female', 'Non-Binary', 'Prefer not to say']"
                            color="pink"
                            label="Sex"
                            required
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-slider
                            value="35"
                            dense
                            color="orange"
                            label="Age"
                            hint="Be honest"
                            min="1"
                            max="100"
                            thumb-label
                        ></v-slider>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-text-field
                            :value="$store.state.developers.email"
                            class="mx-auto"
                            dense
                            outlined
                            rounded
                            color="purple darken-2"
                            label="Email Address"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-text-field
                            v-model="cellphoneNumber"
                            class="mx-auto"
                            dense
                            outlined
                            rounded
                            clearable
                            color="blue darken-2"
                            label="Cell phone number"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="8"
                        class="mx-auto"
                    >
                        <div id="filepondBuy">
                            <file-pond
                                accepted-file-types="image/jpeg, image/png"
                                allow-multiple="false"
                                class-name="my-pond"
                                label-idle="Please upload your Photo... "
                                name="test"
                                ref="pond"
                                server="../buildings/upload"
                                v-on:processfile="handleProcessFile">
                            </file-pond>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <v-text-field
                            v-model="address"
                            class="mx-auto"
                            outlined
                            rounded
                            clearable
                            color="blue darken-2"
                            label="Address"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                        <v-textarea
                            v-model="aboutMe"
                            class="mx-auto"
                            dense
                            outlined
                            rounded
                            clearable
                            color="blue darken-2"
                            label="About me"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="text-capitalize"
                    >
                        Restore
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-capitalize"
                        color="success"
                        type="submit"
                    >
                        Save
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-container>


    </div>
</template>

<script>

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import VueMoment from 'vue-jalali-moment';

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'user',
        ],
        data: () => ({
            firstName:'John',
            lasttName:'Leider',
            emailAddress:'aghababaie@insurmatics.com',
            cellphoneNumber:'+37066978994',
            address:'Lithunia',
            sex:'Male',
            aboutMe:'I am entrepreneur and broker',

            show1: true,
            show2: true,
            password: '',
            rePassword: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'At least 8 characters',
            },
            snackbarSetHoliday: false,
            dialogVocation: false,
            dialogVocation2: false,
            dialogAllVocation: false,
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            dateTommorow: '',
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1

            //pi chart
            seriesPi: [14, 23, 21, 17],
            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        // data() {
        //     return {
        //         labels: ['Impressions', 'Add To Cart', 'Buy'],
        //         subLabels: ['Direct', 'Social Media', 'Ads'],
        //         values: [
        //             // with the given Labels and SubLabels here's what the values represent:
        //             //
        //             // Direct, Social, Ads
        //             //    |      |     |
        //             //    v      v     v
        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
        //             [3000, 1700, 1000], // Segments of "Add To Cart"
        //             [600,  200,  130]   // Segments of "Buy"
        //         ],
        //         colors: [
        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        //         ],
        //         direction: 'horizontal',
        //         gradientDirection: 'horizontal',
        //         height: 300,
        //         width: 800
        //     };
        // },
        created() {
            // console.log(this.$refs.funnel);
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;
        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
        },
        methods: {
            handleProcessFile(){},
            increase() {
                this.height += 10
            },
            EditPassword: function () {
                this.dialogVocation = false;
                axios.put('../user/' + this.$store.state.users.id, {'password': this.password})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                axios.put('../user/' + this.$store.state.users.id, {'tomorrow_vacation': num})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            EditAllVacation: function (num) {
                this.dialogAllVocation = false;

                axios.put('../users/all', {'holiday': num})
                    .then(response => {
                        this.snackbarSetHoliday = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'Management'
                else if (this.$store.state.users.access_level == "2") return 'Operator'
                else if (this.$store.state.users.access_level == "3") return 'Installation expert'
                else if (this.$store.state.users.access_level == "4") return 'Damage expert'
                else if (this.$store.state.users.access_level == "5") return 'Support specialist'
                else if (this.$store.state.users.access_level == "7") return 'Responsible technician'
                else if (this.$store.state.users.access_level == "8") return 'Security and inspection'
                else return 'Unapproved user'
            },
        },
        computed: {
            passwordConfirmationRule() {
                return () => (this.password === this.rePassword) || 'The selected password and its repetition are not the same'
            },
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }

    /deep/ .v-text-field {
        width: 90%;
    }

    .v-select {
        width: 90%;
        align-content: center;
    }
</style>
