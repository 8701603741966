<template>

    <div style="width: 100%; height: 100%;">
        <template>
            <v-card-text >
                <div>View the user's movement report by selecting a date from the calendar below. </div>
                <v-date-picker
                    v-model="date"
                    format="YYYY-MM-DD"
                    display-format="dddd jDD jMMMM jYYYY"></v-date-picker>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
            <v-card-actions>

                <v-btn
                    color="deep-purple lighten-2"
                    @click="getLocationByDate()"
                >
                    نمایش
                </v-btn>
            </v-card-actions>
        </template>
        <template v-if="mapload">
            <MglMap :accessToken="mapirToken"
                    :center.sync="coordinates"
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    zoom="9">
                <MglGeojsonLayer
                    sourceId="mySource"
                    :source="geoJsonSource"
                    layerId="myLayer"
                    :layer="geoJsonlayer"
                />
                <MglMarker :coordinates.sync="this.markerCoordinates2[0]" :color="colors">
                    <MglPopup>
                        <VCard>
                            <div>  Hello, the last time I was online was {{time}} minutes ago. </div>
                        </VCard>
                    </MglPopup>
                </MglMarker>
            </MglMap>
        </template>


<!--        <mapir :apiKey="mapirToken" :center="centers">-->
<!--&lt;!&ndash;            <mapMarker&ndash;&gt;-->
<!--&lt;!&ndash;                :coordinates.sync="usersinfos"&ndash;&gt;-->
<!--&lt;!&ndash;                color="Red"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--            <mapMarker v-for="userlocation in userslocation"-->
<!--                       :coordinates.sync="userlocation.LatLong"-->
<!--                       :color="colors">-->
<!--                <mapPopup>-->
<!--                    <VCard>-->
<!--                        <div>Hello, I'm Mohammad, last update {{userlocation.Time}} min ago</div>-->
<!--                    </VCard>-->
<!--                </mapPopup>-->
<!--            </mapMarker>-->
<!--        </mapir>-->
    </div>
</template>

<script>
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";

    import VueMoment from 'vue-jalali-moment';
    import moment from 'jalali-moment/jalali-moment';

    export default {
        name: "UserLocation",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
            VueMoment,
            moment
        },
        data() {
            // var markerCoordinates1= [[52.420296, 35.732379],[53.420296, 35.732379]];

            return {
                mapload:true,
                date:'',
                coordinates:[51.373155 , 35.688368],
                 markerCoordinates2:[],
                time:'',
                zoom:10,
                userslocation:'',
                time:'',
                usersinfos:'',
                colors: "green",
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
                geoJsonlayer: {
                    id: "myLayer",
                    type: "line",
                    source: "mySource",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    paint: {
                        "line-color": "#088",
                        "line-width": 4
                    }
                }
            };
        },
        computed:{
            // coordinates(){
            //     return this.userslocation[0]["LatLong"];
            // },

            geoJsonSource() {

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                     coordinates:this.markerCoordinates2
                                }
                            }
                        ]
                    }
                };
            }
        },
        created() {
            this.fetchData();

        },
        methods: {
            getLocationByDate(){
                this.mapload = false;
                axios.post('../usertrack/date' ,{'date': this.date, 'user': this.$route.params.id})
                    .then(response =>{
                        this.mapload = true;
                        this.userslocation = response.data;
                        var i;
                        this.time = this.userslocation[0]["Time"];
                        for (i = 0; i < this.userslocation.length; i++) {
                            this.markerCoordinates2[i] = this.userslocation[i]['LatLong'];
                        }
                        // this.geoJsonSource.data.features.coordinates = this.markerCoordinates2;
                    })
                    .catch(errors =>{
                        // alert('unable to fetch');
                    }).finally(()=>{
                    this.busy = false;
                })
            },
            fetchData(){
                this.busy = true;
                axios.get('/usertrack/' + this.$route.params.id)
                    .then(response =>{
                        this.userslocation = response.data;
                        var i;
                        this.time = this.userslocation[0]["Time"];
                         for (i = 0; i < this.userslocation.length; i++) {
                             this.markerCoordinates2[i] = this.userslocation[i]['LatLong'];
                         }
                    })
                    .catch(errors =>{
                        // alert('unable to fetch');
                    }).finally(()=>{
                    this.busy = false;
                })
            },
        }
    }
</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>

<!--<template>-->

<!--    <div>-->
<!--        <div class="label-top" v-html="label"></div>-->
<!--        <div class="uploader-container mx-auto" :class="{dense:dense,dark:dark,disabled:disabled,'has-value':true}">-->
<!--            <v-slide-y-transition>-->
<!--                <v-avatar-->
<!--                    v-if="last_image"-->
<!--                    class="m-2 prev-img"-->
<!--                    size="76"-->
<!--                    :style="clearable?'top: 50%;':''"-->
<!--                >-->
<!--                    <img :src="image_url">-->
<!--                </v-avatar>-->
<!--            </v-slide-y-transition>-->

<!--            <file-pond-->
<!--                v-if="!clearable || !last_image"-->
<!--                ref="pond"-->
<!--                class="mx-auto pointer-pointer fadeIn"-->
<!--                :name="paramName"-->
<!--                style="min-height: 84px"-->

<!--                :label-idle="-->
<!--        (placeholderImage-->
<!--          ? <img src='${placeholderImage}' class='placeholder'>-->
<!--          : '') +-->
<!--          `<p class='text-dark m-0'><i class='fas fa-plus text-success ml-2'></i> ${$t(-->
<!--            'global.image_uploader.label'-->
<!--          )}  </p>`-->
<!--      "-->
<!--                :allow-multiple="allowMultiple"-->
<!--                :accepted-file-types="acceptedFileTypes"-->
<!--                :server="server_credential"-->
<!--                :files="logo"-->
<!--                :maxFileSize="maxFileSize"-->
<!--                :max-files="maxFiles"-->
<!--                check-validity="true"-->
<!--                @processfile="handleProcessFile"-->
<!--                @error="handleFilePondError"-->


<!--                allowImageResize="true"-->
<!--                imageResizeUpscale="false"-->
<!--                imageResizeMode="contain"-->
<!--                :imageResizeTargetWidth="imageResizeTargetWidth"-->
<!--            />-->

<!--            <p class="small file-size-limit">-->
<!--                {{ $t("global.image_uploader.max_size", { size: maxFileSize }) }}-->
<!--            </p>-->

<!--            <v-btn fab depressed small v-if="clearable && last_image" @click.stop="()=>{last_image=null;$emit('onClear')}" class="absolute-top-end m-2 z2"-->
<!--            ><v-icon>close</v-icon></v-btn-->
<!--            >-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    export default {-->
<!--        name: "ImageUploader",-->
<!--        props: {-->
<!--            label: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            server: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->

<!--            maxFileSize: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "1MB"-->
<!--            },-->

<!--            image: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            placeholderImage: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            paramName: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "photo"-->
<!--            },-->

<!--            maxFiles: {-->
<!--                required: false,-->
<!--                type: Number,-->
<!--                default: 1-->
<!--            },-->

<!--            allowMultiple: {-->
<!--                required: false,-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            clearable: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dense: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dark: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            disabled: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            imageResizeTargetWidth:{-->
<!--                default:1600-->
<!--            },-->


<!--            noSvg: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--        },-->

<!--        data: () => ({-->
<!--            logo: null,-->
<!--            version: "",-->
<!--            last_image:null-->
<!--        }),-->

<!--        computed: {-->

<!--            acceptedFileTypes(){-->
<!--                return this.noSvg?"image/jpeg, image/png, image/webp":"image/jpeg, image/png, image/svg+xml, image/webp"-->
<!--            },-->
<!--            image_url() {-->
<!--                if (!this.image) return null;-->
<!--                return this.image + this.version;-->
<!--            },-->
<!--            server_credential() {-->

<!--                const token = document.head.querySelector('meta[name="csrf-token"]');-->

<!--                return {-->
<!--                    url: this.server,-->
<!--                    process: {-->
<!--                        method: "POST",-->
<!--                        withCredentials: true,-->

<!--                        headers: {-->
<!--                            'X-CSRF-TOKEN': token?token.content:''-->
<!--                        },-->
<!--                    }-->
<!--                };-->
<!--            }-->
<!--        },-->
<!--        watch:{-->
<!--            image(){-->
<!--                this.last_image=this.image;-->
<!--            }-->
<!--        },-->
<!--        created() {-->
<!--            this.last_image=this.image;-->
<!--            this.version = "?v=" + Math.random(100); //Invalidate cache!-->




<!--        },-->

<!--        methods: {-->
<!--            handleFilePondError(error){-->
<!--                this.showErrorAlert(null,error.body)-->
<!--                //  console.error('handleFilePondError',error)-->
<!--            },-->

<!--    handleProcessFile: function(error, file) {-->
<!--        if (!error) {-->
<!--            let response = JSON.parse(file.serverId);-->
<!--            this.$emit("response", response);-->

<!--            if (Array.isArray(response.files)) {-->
<!--                response.files.forEach(file => {-->
<!--                    console.log("done", file.path);-->
<!--                    this.$emit("new-path", file.path);-->
<!--                    this.$emit("new-url", file.url);-->
<!--                });-->
<!--            } else {-->
<!--                console.log("done", response.files.path);-->
<!--                this.$emit("new-path", response.files.path);-->
<!--                this.$emit("new-url", response.files.url);-->

<!--                this.$nextTick(() => {-->
<!--                    this.version = "?v=" + Math.random(100);-->
<!--                });-->
<!--            }-->

<!--            return true;-->
<!--        }-->
<!--    }-->
<!--    }-->
<!--    };-->
<!--</script>-->


