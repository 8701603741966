var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.hardware_list,"items-per-page":10,"loading":_vm.busy,"footer-props":{
    showFirstLastPage: true,
    'disable-pagination': false,
    firstIcon: 'first_page',
    lastIcon: 'last_page',
    prevIcon: 'arrow_back',
    nextIcon: 'arrow_forward',
       'items-per-page-text':'per page',


  }},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","rounded":"","small":""}},[_vm._v("delete\n            ")])]}},{key:"item.assign",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","rounded":"","small":""}},[_vm._v("assign\n            ")])]}},{key:"item.edit",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","rounded":"","small":""},on:{"click":function($event){return _vm.updateRemoveID(item.id)}}},[_vm._v("edit\n            ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }