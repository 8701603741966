<template>
    <div>
        <v-snackbar v-model="snackbar" color="#f2c4d3" :timeout="5000" top>
            <span style="color: #333333;">
                Please refer to this section for detailed information about individuals.
            </span>
            <!-- <v-btn text @click="snackbar = false">Close</v-btn> -->
        </v-snackbar>
        <v-snackbar v-model="snackbarTask" color="#f2c4d3" :timeout="5000" centered>
            <span style="color: #333333;">
                The message regarding task assignment has been successfully sent.
            </span>
            <!-- <v-btn text @click="snackbar = false">Close</v-btn> -->
        </v-snackbar>
        <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
        >
        </v-text-field>
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="20"
            :search="search"
            :loading="busy"
            :sort-desc="false"
            sort-by="dogs_count"
            :custom-filter="customFilter"
            show-expand
            align="center"
            :footer-props="{
                showFirstLastPage: true,
                'disable-pagination': false,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'arrow_back',
                nextIcon: 'arrow_forward',
                'items-per-page-text': 'items per page',
            }"
        >
            <!-- @click:row="item=>$router.push({name:'users',params:{profile_id:item.id}})" -->
            <template #item.access_level="{ value }">
                {{ getAccessLevel(value) }}
            </template>

            <template #item.location="{ item }">
                <template v-if="item.access_level == 5">
                    <v-btn @click.prevent="EditData(item.id)" icon>
                        <v-icon>navigation</v-icon>
                    </v-btn>
                </template>
            </template>

            <template #item.remove="{ item }">
                <v-dialog
                    v-model="dialog"
                    width="500"
                    :retain-focus="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-flex align-self-start>
                            <v-btn color="error"
                                   rounded
                                   v-bind="attrs"
                                   v-on="on"
                                   @click="updateRemoveID(item.id)"
                                   small>Delete
                            </v-btn>
                        </v-flex>
                    </template>
                    <v-card>
                        <v-card-title class="red white--text">
                            Are you sure you want to delete?
                        </v-card-title>
                        <v-card-text>
                            Once deleted, it is no longer possible to return the information. If you are sure, press the delete button, otherwise cancel.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="destroyUserData()"
                            >
                                Remove
                            </v-btn>
                            <v-btn
                                color="green"
                                @click="dialog = false"
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template #item.task="{ item }">
                <template v-if="item.access_level == 5">
                    <v-row justify="center">
                        <v-dialog
                            v-model="dialogTask"
                            persistent
                            max-width="600px"
                            :retain-focus="false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-flex align-self-center width="20px">
                                    <v-btn color="primary"
                                           small
                                           dark
                                           rounded
                                           v-bind="attrs"
                                           v-on="on"
                                           @click="uploadNumber(item)">Assign
                                    </v-btn>
                                </v-flex>
                            </template>
                            <v-card class="my-auto" min-height="400px">
                                <v-card-title>
                                    <span> Assign search location </span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                class="overflow-y-auto"
                                            >
                                                <v-date-picker v-model="date"></v-date-picker>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-autocomplete
                                                    :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]"
                                                    label="Select the search area."
                                                    v-model="region"
                                                ></v-autocomplete>
                                                <v-autocomplete
                                                    :items="region_section[region-1]"
                                                    label="Select the search region."
                                                    v-model="section"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="red"
                                        @click="dialogTask = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn
                                        color="green"
                                        @click="SendSMStoUser()"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </template>
            </template>

            <template #item.edit="{ item }">
                <v-row justify="center">
                    <v-dialog
                        v-model="dialog1"
                        persistent
                        max-width="600px"
                        :retain-focus="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-flex align-self-center width="20px">
                                <v-btn color="success"
                                       small
                                       dark
                                       rounded
                                       v-bind="attrs"
                                       v-on="on"
                                > Working
                                </v-btn>
                            </v-flex>
                        </template>
                        <v-card class="justify-center">
                            <v-card-title>
                                <span class="headline"> User info </span>
                            </v-card-title>
                            <v-card class="justify-center">
                                <div id="filepond">
                                    <file-pond
                                        name="userPhoto"
                                        ref="pond"
                                        class-name="my-pond"
                                        label-idle="Upload photo if needed"
                                        allow-multiple="false"
                                        accepted-file-types="image/jpeg, image/png"
                                        server="../user/upload"
                                        v-on:processfile="handleProcessFile">
                                    </file-pond>
                                </div>
                            </v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                                v-model="form.name"
                                                label="Name and Family*"
                                                hint="Name and Family"
                                                persistent-hint
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="form.national_ID"
                                                label="National ID*"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="form.shenasname"
                                                label="Personal ID*"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-select
                                                v-model="form.access_level"
                                                :items="access_levels"
                                                label="User level *"
                                                required
                                                dir="rtl"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="form.car_number"
                                                label="Previously selected car"
                                                readonly
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-select
                                                v-model="form.car_number"
                                                :items="carnumbers"
                                                label="New license plate number"
                                                dir="rtl"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="red"
                                    @click="dialog1 = false"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="green"
                                    @click="editUserData()"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>

            <template #expanded-item="{ header, item }">
                <td :colspan="headers.length">
                    <v-spacer></v-spacer>
                    <v-container fluid>
                        <v-layout row wrap align="center" justify="center">
                            <v-flex sm4 xs12 justify-center>
                                <v-avatar width="300px" height="100px">
                                    <v-img :src="'/storage/images/user/' + item.pic" contain max-height="400px">
                                    </v-img>
                                </v-avatar>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.last_login_time"
                                    label="Last time online"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.region"
                                    label="Area to be examined"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.car_number"
                                    label="Car No."
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.error_count"
                                    label="Number of daily location errors"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.dog_cap_number"
                                    label="Number of houses registered daily"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.dog_cap_number_10days"
                                    label="Average number of houses in the last 10 days"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.error_count"
                                    label="Overall daily average of homes"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.dog_cap_number"
                                    label="Overall average of the last 10 days"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </td>
            </template>
        </v-data-table>
    </div>
</template>


<script>
    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
    export default {
        name: "UsersIndex",

        components: {
            datePicker: VuePersianDatetimePicker
        },
        beforeCreate() {

            axios.get('../cars')
                .then( response => {
                    // this.responses = response.data[0].data.id;
                    // console.log(this.response);
                    // this.$router.go();
                    if (response.data.length){
                        this.carnumbers[0] = response.data[0]['number'];
                        // this.carnumbers[0]['value'] = response.data[0]['id'];
                        let i;
                        for (i = 1; i < response.data.length; i++)
                        {
                            // this.carnumbers.push({text:  response.data[i]['number'], value: response.data[i]['id']});
                            this.carnumbers.push(response.data[i]['number']);
                        }
                    }

                    // for (i = 0; i < response.data.length; i++) {
                    //     this.carnumbers[i]['text'] = response.data[i]['name'];
                    //     this.carnumbers[i]['value'] = response.data[i]['id'];
                    // }
                })
                .catch(errors =>{
                    console.log(errors);
                }).finally(()=> {
                    this.busy = false;
                }
            )
        },
        created(){
            this.fetchData();
        },
        mounted() {


        },

        data: function () {
            return {
                region_section:[
                    [1,2,3,4,5,6,7,8,9,10],
                    [1,2,3,4,5,6,7,8,9],
                    [1,2,3,4,5,6],
                    [1,2,3,4,5,6,7,8,9],
                    [1,2,3,4,5,6,7],
                    [1,2,3,4,5,6],
                    [1,2,3,4,5],
                    [1,2,3],
                    [1,2],
                    [1,2,3],
                    [1,2,3,4],
                    [1,2,3,4,5,6],
                    [1,2,3,4],
                    [1,2,3,4,5,6],
                    [1,2,3,4,5,6,7,8],
                    [1,2,3,4,5,6],
                    [1,2,3],
                    [1,2,3,4,5,6,7],
                    [1,2,3,4,5],
                    [1,2,3,4,5,6,7],
                    [1,2,3],
                    [1,2,3,4],
                ],
                date: '',
                region:'',
                section:'',
                taskcellnumber:'',
                url:'https://api.kavenegar.com/v1/66533457526E5956554A42544752595A444A33673553577370664261662F2B70642B5251617474634D6A6B3D/verify/',
                // https://api.kavenegar.com/v1/{API-KEY}/verify/lookup.json

                dialog: false,
                dialog1: false,
                dialogTask:false,
                fill: false,
                picker:false,
                gradient: gradients[4],
                gradients,
                padding: 8,
                radius: 10,
                value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
                width: 2,
                busy: false,
                search: '',
                users: [],
                removeUserID:'',
                editUserID:'',
                // carnumbers:[ {text: '', value: ''}],
                carnumbers:[],
                currentcarnumber:  {text: '', value: ''},
                snackkbar: true,
                snackkbarTask: false,





                access_levels: [
                    {text: 'Management', value: '1'},
                    {text: 'Operator', value: '2'},
                    {text: 'installation expert', value: '3'},
                    {text: 'Damage Expert', value: '4'},
                    {text: 'Support Expert', value: '5'},
                    // {text: 'مسئول تکنسین', value: '7'},
                    // {text: 'حراست و بازرسی', value: '8'},
                    {text: 'person without position', value: '6'},
                ],
                headers: [
                    {text: 'Personal ID', value: 'id'},
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    // { text: 'Family', value: 'family' },
                    {text: 'Cell number', value: 'email'},
                    {text: 'Access level', value: 'access_level'},
                    {text: 'region assigned', value: 'region'},
                    // {text: 'امتیاز', value: "id"},
                    {text: 'Tracking', value: 'location', sortable: false,},
                    {text: 'Assign a mission', value: 'task', sortable: false, width: "200px"},
                    {text: 'vacation', value: 'edit', sortable: false, width: "200px"},
                    {text: 'Number of registered houses', value: 'dog_cap_number', sortable: false},
                ],

                formCar:
                    {
                        'driver':'', 'number':''
                    },
                form:
                    {
                        'name':'', 'email':'', 'access_level':'', 'pic':'', 'national_ID':'', 'shenasname':'', 'car_number':'',
                        'error_count':'', 'dog_cap_number':'', 'car_ID':'', 'vacation':'', 'login_device':'',
                        'last_login_time':'', 'region':'', 'dog_cap_number_10days':'',
                    },
            }
        },
        methods: {
            customFilter(items, search, filter) {
                if (!search) { return items } //if the search is empty just return all the items

                function  new_filter (val, search) {
                    return val !== null &&
                        ['undefined', 'boolean'].indexOf(typeof val) === -1 &&
                        val.toString().toLowerCase().replace(/[^0-9a-zA-Z]+/g,"").indexOf(search) !== -1
                }

                let needleAry = search.toString().toLowerCase().split(" ").filter(x => x);
                //whenever we encounter a space in our search we will filter for both the first and second word (or third word)

                return items.filter(item => item.access_level===5);
                //foreach needle in our array cycle through the data (row[key]) in the current row looking for a match.
                // .some will return true and exit the loop if it finds one it will return false if it doesnt
                // .every will exit the loop if we dont find a match but will return true if all needles match
                // .filter the rows on each match

            },
            uploadNumber(form){
                this.taskcellnumber = form.email;
            },
            SendSMStoUser(){
                let formSMS = { 'receptor': '', 'token': '', 'token2': '', 'token3':'', 'template' : ''};
                formSMS.receptor = this.taskcellnumber;
                formSMS.token = this.date;
                formSMS.token2 = this.region;
                formSMS.token3 = this.section;
                formSMS.template = 'task';

                axios.post('../users/sms', formSMS)
                    .then( response => {
                        this.snackkbarTask = true;
                        this.dialogTask = false
                    })
                    .catch(errors =>{
                        // alert('errors');
                    }).finally(()=>{
                    this.busy=false;
                });
                // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                // let formSMS = { 'receptor': "09127752017", 'token': 'salam', 'template' : 'captured'};
                // axios.post(this.url , formSMS, {headers: {
                //     // remove headers
                // }})
                //     .then( response => {alert(response)})
                //     .catch(errors =>{
                //         alert(response);
                // });
            },
            //Edit Data
            updateEditID( id){
                this.editUserID = id;
            },
            uploadData(userForm){
                this.form = userForm;
                this.currentcarnumber.text = this.form.car_number;
                this.currentcarnumber.value = this.form.car_ID;

                // this.currentcarnumber['text'] = this.form.car_number;
                // this.currentcarnumber['value'] = this.form.car_ID;
            },
            handleProcessFile: function(error, file) {
                // this.form.first_fig = file.serverId;
                this.form.pic = file.serverId;
                // console.log(this.form.fig);
                console.log( this.form.first_fig);
            },

            editUserData() {
                // this.dialogAdd = false;
                // this.form.car_number = this.currentcarnumber.text;
                // this.form.car_ID = this.currentcarnumber.value;
                axios.put('../user/'+ this.form.id , this.form)
                    .then( response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.response);
                        this.formCar.driver = this.form.id;
                        this.formCar.number = this.form.car_number;
                        // this.formCar.id = this.form.car_ID;
                        axios.put('../cars/update', this.formCar)
                            .then( responses => {
                                // this.responses = response.data[0].data.id;
                                console.log(this.responses);
                                // this.$router.push({name:'carindex'});
                            })
                        // this.$router.push({name:'carindex'});
                    })
                    .catch(errors =>{
                        console.log(errors);
                    }).finally(()=>{
                    this.dialog1=false;
                    this.$router.go();
                });
            },


            //Delete Data
            updateRemoveID( id){
                this.removeUserID = id;
            },
            destroyUserData(){
                axios.delete('../user/'+this.removeUserID)
                    .then( response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'carindex'});
                    })
                    .catch(errors =>{
                        console.log(errors);
                    }).finally(()=>{
                    this.dialog=false;
                    this.$router.go();
                });
            },

            fetchData() {
                this.busy = true;

                axios.get('/user')
                    .then(response => {
                        this.users = response.data[0].data.filter(user=>user.access_level==5);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            // axios.get('../dogdata/' + this.$route.params.id)
            //     .then(response =>{
            //         this.doginfoshow = response.data[0].data;
            //         //this.temp = response.data[0].data.race;
            //         //this.doginfoshow= response.data[0].data;
            //     })
            //     .catch(error =>{
            //
            //     });

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            EditData: function (id) {
                this.$router.push('../users/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            getAccessLevel(access_level) {
                if (access_level == "1") return 'Management'
                else if (access_level == "2") return 'Operator'
                else if (access_level == "3") return 'Installation expert'
                else if (access_level == "4") return 'Damage expert'
                else if (access_level == "5") return 'Support specialist'
                else if (access_level == "7") return 'Responsible technician'
                else if (access_level == "8") return 'Security and inspection'
                else return 'Unapproved user'
            },


        }
    }

</script>

<style scoped>

</style>
