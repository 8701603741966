<template>

    <div style="width: 100%; height: 100%;">
        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
<!--            <mapMarker-->
<!--                :coordinates.sync="usersinfos"-->
<!--                color="Red"-->
<!--            />-->
<!--            <mapGeojsonLayer-->
<!--                sourceId="mySource"-->
<!--                :source="geoJsonSource"-->
<!--                layerId="myLayer"-->
<!--                :layer="geoJsonlayer"-->
<!--            />-->
            <MglGeojsonLayer
                sourceId="mySource"
                :source="geoJsonSource2"
                layerId="myLayer"
                :layer="geoJsonlayer2"
            />
            <MglMarker v-for="usersinfo in users" :key="usersinfo"
                       :coordinates.sync="usersinfo.LatLong"
                       :color="colors">
                <MglPopup>
                    <VCard>
                        <div>  I am {{usersinfo.first_name}}, and I was last online at {{usersinfo.Time}}</div>
                    </VCard>
                </MglPopup>
            </MglMarker>
        </MglMap>
    </div>
</template>

<script>
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";

    export default {
        name: "mapirMarker",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        data() {
            return {
                users:'',
                usersinfos:'',
                colors: "green",
                coordinates: [25.279652, 54.687157],
                centers: [25.279652, 54.687157],
                markerCoordinates: [25.279652, 54.687157],
                markerCoordinates1: [[25.279652, 54.687157],[25.279652, 54.687157]],
                markerCoordinates2: [25.279652, 54.687157],
                // mapirToken: "<Your API Key>"
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
                geoJsonSource: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            [51.3898668, 35.6899503 ],
                                            [51.6254851, 35.8203263],
                                            [51.3898668, 35.8203263],
                                            [51.6254851, 35.6899503],
                                        ]
                                    ]
                                }
                            },
                        ]
                    }
                },
                geoJsonlayer: {
                    id: "myLayer",
                    type: "fill",
                    source: "mySource",
                    paint: {
                        "fill-color": "#888888",
                        "fill-opacity": 0.4
                    }
                },

                geoJsonSource2: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            [51.3898668, 35.6899503 ],
                                            [51.3898668, 35.8041560],
                                            [51.1306695, 35.8041560],
                                            [51.1306695, 35.6899503],
                                        ]
                                    ]
                                }
                            },
                        ]
                    }
                },
                geoJsonlayer2: {
                    id: "myLayer",
                    type: "fill",
                    source: "mySource",
                    paint: {
                        "fill-color": "#777777",
                        "fill-opacity": 0.4
                    }
                }

            };
        },
        created() {
            this.fetchData();

        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/alluserlocation')
                    .then(response => {
                        this.users = response.data[0].data;
                        // this.usersinfos = this.users[1].LatLong;
                        // console.log(this.users[1]);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            }
        }
    }
</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>

<!--<template>-->

<!--    <div>-->
<!--        <div class="label-top" v-html="label"></div>-->
<!--        <div class="uploader-container mx-auto" :class="{dense:dense,dark:dark,disabled:disabled,'has-value':true}">-->
<!--            <v-slide-y-transition>-->
<!--                <v-avatar-->
<!--                    v-if="last_image"-->
<!--                    class="m-2 prev-img"-->
<!--                    size="76"-->
<!--                    :style="clearable?'top: 50%;':''"-->
<!--                >-->
<!--                    <img :src="image_url">-->
<!--                </v-avatar>-->
<!--            </v-slide-y-transition>-->

<!--            <file-pond-->
<!--                v-if="!clearable || !last_image"-->
<!--                ref="pond"-->
<!--                class="mx-auto pointer-pointer fadeIn"-->
<!--                :name="paramName"-->
<!--                style="min-height: 84px"-->

<!--                :label-idle="-->
<!--        (placeholderImage-->
<!--          ? <img src='${placeholderImage}' class='placeholder'>-->
<!--          : '') +-->
<!--          `<p class='text-dark m-0'><i class='fas fa-plus text-success ml-2'></i> ${$t(-->
<!--            'global.image_uploader.label'-->
<!--          )}  </p>`-->
<!--      "-->
<!--                :allow-multiple="allowMultiple"-->
<!--                :accepted-file-types="acceptedFileTypes"-->
<!--                :server="server_credential"-->
<!--                :files="logo"-->
<!--                :maxFileSize="maxFileSize"-->
<!--                :max-files="maxFiles"-->
<!--                check-validity="true"-->
<!--                @processfile="handleProcessFile"-->
<!--                @error="handleFilePondError"-->


<!--                allowImageResize="true"-->
<!--                imageResizeUpscale="false"-->
<!--                imageResizeMode="contain"-->
<!--                :imageResizeTargetWidth="imageResizeTargetWidth"-->
<!--            />-->

<!--            <p class="small file-size-limit">-->
<!--                {{ $t("global.image_uploader.max_size", { size: maxFileSize }) }}-->
<!--            </p>-->

<!--            <v-btn fab depressed small v-if="clearable && last_image" @click.stop="()=>{last_image=null;$emit('onClear')}" class="absolute-top-end m-2 z2"-->
<!--            ><v-icon>close</v-icon></v-btn-->
<!--            >-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    export default {-->
<!--        name: "ImageUploader",-->
<!--        props: {-->
<!--            label: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            server: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->

<!--            maxFileSize: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "1MB"-->
<!--            },-->

<!--            image: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            placeholderImage: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            paramName: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "photo"-->
<!--            },-->

<!--            maxFiles: {-->
<!--                required: false,-->
<!--                type: Number,-->
<!--                default: 1-->
<!--            },-->

<!--            allowMultiple: {-->
<!--                required: false,-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            clearable: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dense: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dark: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            disabled: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            imageResizeTargetWidth:{-->
<!--                default:1600-->
<!--            },-->


<!--            noSvg: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--        },-->

<!--        data: () => ({-->
<!--            logo: null,-->
<!--            version: "",-->
<!--            last_image:null-->
<!--        }),-->

<!--        computed: {-->

<!--            acceptedFileTypes(){-->
<!--                return this.noSvg?"image/jpeg, image/png, image/webp":"image/jpeg, image/png, image/svg+xml, image/webp"-->
<!--            },-->
<!--            image_url() {-->
<!--                if (!this.image) return null;-->
<!--                return this.image + this.version;-->
<!--            },-->
<!--            server_credential() {-->

<!--                const token = document.head.querySelector('meta[name="csrf-token"]');-->

<!--                return {-->
<!--                    url: this.server,-->
<!--                    process: {-->
<!--                        method: "POST",-->
<!--                        withCredentials: true,-->

<!--                        headers: {-->
<!--                            'X-CSRF-TOKEN': token?token.content:''-->
<!--                        },-->
<!--                    }-->
<!--                };-->
<!--            }-->
<!--        },-->
<!--        watch:{-->
<!--            image(){-->
<!--                this.last_image=this.image;-->
<!--            }-->
<!--        },-->
<!--        created() {-->
<!--            this.last_image=this.image;-->
<!--            this.version = "?v=" + Math.random(100); //Invalidate cache!-->




<!--        },-->

<!--        methods: {-->
<!--            handleFilePondError(error){-->
<!--                this.showErrorAlert(null,error.body)-->
<!--                //  console.error('handleFilePondError',error)-->
<!--            },-->

<!--    handleProcessFile: function(error, file) {-->
<!--        if (!error) {-->
<!--            let response = JSON.parse(file.serverId);-->
<!--            this.$emit("response", response);-->

<!--            if (Array.isArray(response.files)) {-->
<!--                response.files.forEach(file => {-->
<!--                    console.log("done", file.path);-->
<!--                    this.$emit("new-path", file.path);-->
<!--                    this.$emit("new-url", file.url);-->
<!--                });-->
<!--            } else {-->
<!--                console.log("done", response.files.path);-->
<!--                this.$emit("new-path", response.files.path);-->
<!--                this.$emit("new-url", response.files.url);-->

<!--                this.$nextTick(() => {-->
<!--                    this.version = "?v=" + Math.random(100);-->
<!--                });-->
<!--            }-->

<!--            return true;-->
<!--        }-->
<!--    }-->
<!--    }-->
<!--    };-->
<!--</script>-->


