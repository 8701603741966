<template>
    <div class="pt-16 bg-gray-200 full-height">
        <v-snackbar :timeout="3000" color="#f2c4d3" top v-model="snackkbarOkey">
            <span style="color: #333333;">
                Thank you, your request has been successfully submitted.
            </span>
        </v-snackbar>
        <v-snackbar :timeout="3000" color="orange" top v-model="snackkbarNokey">
            <span>
                Your cannot registered twice with one email. Please, try with another email
            </span>
        </v-snackbar>

        <v-card class="mx-auto rounded-form my-10" max-width="85%" min-width="370px" outlined>
            <v-img
                class="ma-0"
                contain
                :src="require('../../../assets/icons/HubbcastLogo.svg')"
                max-height="20px"
                width="110px"
            ></v-img>
            <v-card-title class="layout justify-center">
                The Hubbcast Partner Program
            </v-card-title>
            <v-card-subtitle class="layout justify-center">
                IoT is sport team business. Hence, we are actively looking forward to new partnerships in different
                areas. Please, tell us about yourself by filling out the following form.
            </v-card-subtitle>
            <v-flex class="mx-auto">
                <v-img
                    class="ma-0"
                    contain
                    :src="require('../../../assets/iamges/Forms/Forms_partnership.svg')"
                    max-height="150px"
                    width="200px"
                ></v-img>
            </v-flex>
            <v-card-text>
                <v-autocomplete
                    :items="['Smart Home vendors', 'Banking or payment providers', 'Vertically insurance software providers', 'SaaS providers', 'Others']"
                    buy
                    label="What is your industry?" v-model="form.major"
                ></v-autocomplete>
                <!--                'state', 'startdate','devicenum', 'errornum', 'address', 'unitnum', 'postalcode',
                        'unitset', 'ownerlist', 'appliancenum', 'applianceprice', 'hardwarenum', 'buildingtype', 'lat', 'long', 'pic', 'hardwarelist'
                -->
                <template v-if="form.major=='Others'">
                    <v-row>
                        <v-col
                            class="justify-center"
                            cols="12"
                            sm="6"
                        >
                            <v-text-field label="Other industry" placeholder="Please tell us about your industry"
                                          v-model="form.major "></v-text-field>
                        </v-col>
                    </v-row>
                </template>


                <template v-if="form.major">
                    <template>
                        <template>
                            <v-stepper v-model="e1"
                                       color="green"
                                       elevation="0"
                                       outlined
                                       vertical
                            >
                                <v-stepper-header>
                                    <v-stepper-step
                                        :complete="e1 > 1"
                                        step="1"
                                    >
                                        Personal information
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step
                                        :complete="e1 > 2"
                                        step="2"
                                    >
                                        Partnership information
                                    </v-stepper-step>

                                    <v-divider></v-divider>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-form
                                            ref="form"
                                            v-model="validPage1"
                                            lazy-validation
                                        >
                                            <v-row
                                                class="my-12">

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field label="Name"
                                                                  placeholder="Please write your first and last name"
                                                                  :rules="[v => !!v || 'Name is required',
                                                              v => (v && v.length < 30) || 'Name must be less than 30 character']"
                                                                  v-model="form.name "></v-text-field>
                                                </v-col>

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field label="Email"
                                                                  :rules="[ v => !!v || 'E-mail is required',
                                                                   v => /.+@.+\..+/.test(v) || 'E-mail must be valid',]"
                                                                  placeholder="Please write your email address"
                                                                  v-model="form.email "></v-text-field>
                                                </v-col>

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field label="Phone No."
                                                                  placeholder="Please Enter your phone number"
                                                                  :rules="[v => !!v || 'Phone No. is required',
                                                                   v => (v && v.length < 15) || 'Phone No. must be less than 15 number']"
                                                                  v-model="form.cellnumber "></v-text-field>
                                                </v-col>

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field label="Company"
                                                                  placeholder="Please Enter name of your company"
                                                                  :rules="[v => !!v || 'Company name is required']"
                                                                  v-model="form.company "></v-text-field>
                                                </v-col>

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field label="Website"
                                                                  placeholder="Please Enter your website"
                                                                  :rules="[ v => !!v || 'Website is required',
                                                                   v => /.+\..+/.test(v) || 'Website must be valid',]"
                                                                  v-model="form.website "></v-text-field>
                                                </v-col>

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-combobox
                                                        :items="['1-10', '11-50', '51-200', '201-500', '501-1000', '1000+']"
                                                        label="No. of employees"
                                                        :rules="[v => !!v || ' Number of employees is required']"
                                                        v-model="form.type"
                                                    ></v-combobox>
                                                </v-col>

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                >
                                                    <v-text-field label="Address"
                                                                  placeholder="Please enter the compony address"
                                                                  v-model="form.address "></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <!--                                        </v-card>-->
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    :disabled="!validPage1"
                                                    color="primary"
                                                    @click="validate"
                                                >
                                                    Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <v-form
                                            ref="form2"
                                            v-model="validPage2"
                                            lazy-validation
                                        >

                                            <v-card
                                                class="my-12"
                                                width="100%"
                                            >

                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-combobox
                                                        :items="['North America', 'South America', 'Europe', 'Africa', 'MENA', 'South and Southeast Asia', 'East Asia']"
                                                        label="What is your geographical location?"
                                                        v-model="form.region"
                                                        :rules="[v => !!v || ' Geographical location is required']"
                                                    ></v-combobox>
                                                </v-col>
                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                >
                                                    <v-textarea label="Your service or product"
                                                                :rules="[v => !!v || ' Your service or product is required']"
                                                                placeholder="Talk about your services that can be applicable in smart home insurance?"
                                                                v-model="form.challenge "></v-textarea>
                                                </v-col>
                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                >
                                                    <v-textarea label="Partnership proposal"
                                                                :rules="[v => !!v || ' Partnership proposal is required']"
                                                                placeholder="How we can make a partnership to create value for consumers?"
                                                                v-model="form.proposal "></v-textarea>
                                                </v-col>
                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                >
                                                    <v-textarea label="Market potential"
                                                                placeholder="How about the market potential of our partnership ?"
                                                                v-model="form.potential "></v-textarea>
                                                </v-col>
                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                >
                                                    <v-textarea label="Business model"
                                                                :rules="[v => !!v || ' business model is required']"
                                                                placeholder="Please describe your business model for our partnership"
                                                                v-model="form.financialModel "></v-textarea>
                                                </v-col>
                                                <v-col
                                                    class="justify-center"
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-combobox
                                                        :items="['Social networks, Linkedin, Twitter, Instagram, Facebook', 'Emails', 'Personal introduction', 'Search engines', 'Others']"
                                                        label="How do you meet with Hubbcast"
                                                        :rules="[v => !!v || ' Meet method is required']"
                                                        v-model="form.meetMethod"
                                                    ></v-combobox>
                                                </v-col>

                                                <v-card-text>
                                                    <v-container
                                                        class="grey lighten-5 mb-6 mx-auto" align="center"
                                                    >
                                                        Please choose your desired date from available time slot(CET):
                                                    </v-container>
                                                </v-card-text>
                                                <template>
                                                    <v-row>
                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                        >
                                                            <v-dialog
                                                                ref="dialog"
                                                                v-model="modal"
                                                                :return-value.sync="form.date"
                                                                persistent
                                                                width="290px"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="form.date"
                                                                        label="Picker in dialog"
                                                                        prepend-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="form.date"
                                                                    scrollable
                                                                    :min="currentDate"
                                                                    max="2021-11-30"
                                                                    :allowed-dates="allowedDates"
                                                                >
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="modal = false"
                                                                    >
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="updateDateAndSlot"
                                                                    >
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-dialog>
                                                        </v-col>

                                                        <v-col
                                                            class="justify-center"
                                                            cols="12"
                                                            sm="6"
                                                        >
                                                            <v-combobox
                                                                :items="TimeSlot"
                                                                label="Select the available time slot(CET)"
                                                                v-model="form.time"
                                                            ></v-combobox>
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-card>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <!--                            <v-btn text class="m-1" :to="{name:'profiles'}">لغو کردن </v-btn>-->
                                                <!--                            <v-btn depressed color="success" class="m-1" href="https://idpay.ir/niomatic-kit" :loading="busy">ورود درگاه اهدای نقدی</v-btn>-->
                                                <v-btn
                                                    :disabled="!validPage2"
                                                    :loading="busy"
                                                    @click="overAllValidate"
                                                    class="m-1"
                                                    color="success"
                                                    depressed>
                                                    Send
                                                </v-btn>
                                                <v-btn
                                                    color="primary"
                                                    @click="e1 = 1"
                                                >
                                                    back
                                                </v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </v-stepper-content>

                                </v-stepper-items>
                            </v-stepper>
                        </template>


                    </template>

                    <template>
                        <v-dialog
                            v-model="dialogSend"
                            width="500"
                        >

                            <v-card>
                                <v-card-title class="headline2 grey lighten-2">
                                    The partnership program form
                                </v-card-title>

                                <v-card-text>
                                    Would you like to send the information filled in the form as partnership program?
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="addPartnerForm"
                                        color="green"
                                        text
                                    >
                                        Yes
                                    </v-btn>
                                    <v-btn
                                        @click="dialogSend=false"
                                        color="red"
                                        text
                                    >
                                        No
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>

                </template>
            </v-card-text>
        </v-card>
    </div>

</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
    import moment from 'moment';
    export default {
        name: "BePartner",
        components: {
            FilePond
        },

        data: function () {
            return {
                TimeSlot:[],
                meetingList: [],
                notAvailableTimeSlot: [],
                currentDate: moment().format("YYYY-MM-DD"),
                notAvailableDates : ["2021-11-01", "2021-11-02"],
                validPage1: true,
                validPage2: true,

                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 30) || 'Name must be less than 30 characters',
                ],
                modal: false,
                date: '',
                e13: 2,
                plan: '',
                e1: 1,
                changedPlan: '',
                building_set: [],
                building_selected_id: '',
                building_id: '',
                valid: true,

                nameRules: [
                    // v => !!v || 'نام لازم می باشد',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the name is 10',
                ],
                familyRules: [
                    v => !!v || 'Last name required',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the last name is 10',
                ],
                cellnumRules: [
                    v => !!v || 'Contact number is required',
                    v => (v && v.length === 11) || ' Contact number is required',
                ],
                select: null,
                items: [
                    'Item 1',
                    'Item 2',
                    'Item 3',
                    'Item 4',
                ],
                checkbox: false,
                //myFiles: ['index.html'],
                dialogUnit: false,
                dialogSupporter: false,
                dialogChangePlan: false,
                dialogExtend: false,
                dialogSend: false,
                snackkbarOkey: false,
                snackkbarNokey: false,
                form:
                    {
                        'major': '',
                        'name': '',
                        'email': '',
                        'cellnumber': '',
                        'company': '',
                        'website': '',
                        'type': '',
                        'region': '',
                        'address': '',
                        'challenge': '',
                        'proposal': '',
                        'potential': '',
                        'financialModel': '',
                        'meetMethod': '',
                        'date': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                        'time': ''
                    },
                formUnitArray: [],
                changepaln: '',
                errors: null,
                responses: null,
                busy: false,
                request: '',
            }
        },

        mounted() {
            axios.get('../meetingList')
                .then(response => {
                    this.meetingList = response.data;

                    for (let i = 0; i < this.meetingList.length; i++) {
                        if ((this.meetingList[i]['8:00-8:30'] + this.meetingList[i]['8:30-9:00'] + this.meetingList[i]['9:00-9:30']
                            +this.meetingList[i]['19:00-19:30'] + this.meetingList[i]['19:30-20:00']  + this.meetingList[i]['20:00-20:30'] ) === "111111")
                        {
                            this.notAvailableDates.push(this.meetingList[i]['date'])
                        }
                    }
                });
        },

        methods: {
            updateDateAndSlot()
            {
                this.TimeSlot = ['8:00-8:30', '8:30-9:00', '9:00-9:30', '19:00-19:30', '19:30-20:00', '20:00-20:30'];

                this.$refs.dialog.save(this.form.date);
                for (let i = 0; i < this.meetingList.length; i++) {
                    if (this.meetingList[i]['date'] === this.form.date)
                    {
                        this.TimeSlot = [];
                        if (this.meetingList[i]['8:00-8:30'] ==="0")
                            this.TimeSlot.push('8:00-8:30');
                        if (this.meetingList[i]['8:30-9:00'] ==="0")
                            this.TimeSlot.push('8:30-9:00');
                        if (this.meetingList[i]['9:00-9:30'] ==="0")
                            this.TimeSlot.push('9:00-9:30');
                        if (this.meetingList[i]['19:00-19:30']==="0")
                            this.TimeSlot.push('19:00-19:30');
                        if (this.meetingList[i]['19:30-20:00']==="0")
                            this.TimeSlot.push('19:30-20:00');
                        if (this.meetingList[i]['20:00-20:30'] ==="0")
                            this.TimeSlot.push('20:00-20:30');
                    }

                }

            },
            allowedDates(a) {
                return !this.notAvailableDates.includes(a);
            },


            allowed_dates(){
                // return ["2021-11-01", "202 1-11-02"];
                if (this.allowedDates.indexOf(val) !== -1) {
                    return true
                } else {
                    return false
                }
            },
            overAllValidate() {
                if (this.$refs.form2.validate()) {
                    this.dialogSend = true;
                }
            },

            validate() {
                if (this.$refs.form.validate()) {
                    this.e1 = 2
                }
            },
            changeUnit() {
                this.dialogUnit = true;
                this.formUnitArray = [];
                for (let i = 0; i < this.form.unitnum; i++) {
                    this.formUnitArray.push({
                        'unit_Nom': '',
                        'name': '',
                        'family': '',
                        'cellnumber': '',
                        'area': '',
                        'postalcode': ''
                    });
                }
            }
            ,
            // validate() {
            //
            //     if (this.$refs.form.validate()) {
            //         this.dialogUnit = false;
            //     }
            // },
            reset() {
                this.$refs.form.reset();
            }
            ,

            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            }
            ,
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,
            addPartnerForm() {
                this.dialogSend = false;
                this.busy = true;
                axios.post('../bePartner', this.form)
                    .then(
                        response => {
                            this.snackkbarOkey = true;
                        }).catch(errors => {
                    this.snackkbarNokey = true;
                }).finally(() => {
                    this.busy = false;

                });
            },


            extendPlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ExtendPlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'msg': 'Send request to extend plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogExtend = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,

            changePlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ChangePlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'new_plan': this.changedPlan,
                        'msg': 'Request a change plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogChangePlan = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .headline2 {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    .GoldText {
        color: #fcc203;
    }

    .Price {
        font-size: 1.5rem;
    }

    .full-height {
        height: 100%;
    }
</style>

