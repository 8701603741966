<template>

    <div class="pt-16 overflow-visible">

        <v-card max-width="80%" class="mx-auto rounded-form" outlined>
            <v-card-title class="layout justify-center">
                Request procedure
            </v-card-title>
            <!--            <v-img :src="images" contain max-height="200px">-->
            <v-img :src="images" contain max-height="400px">
            </v-img>
            <v-container fluid>
                <v-row>
                </v-row>
                <v-card>
                    <li v-for="item in message">
                        <template v-if="item.userType=='citizen'">
                            <v-textarea label="Your massage"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        auto-grow
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='1'">
                            <v-textarea label="Manager message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='2'">
                            <v-textarea label="Operator message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='3'">
                            <v-textarea label="Installation expert message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='4'">
                            <v-textarea label="Claim expert message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='5'">
                            <v-textarea label="Support expert message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>

                    </li>
                </v-card>

            </v-container>

            <template v-if="dogs.state == 'Unread'">
                <v-card-text>
                    <v-select :items="users" item-text="name" itemid="id" return-object label="User"
                              :error-messages="errors" placeholder="Select user" v-model="selected_user"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="m-1">Cancel</v-btn>
                    <v-btn depressed color="secondary" class="m-2" @click="addNewTask" :loading="busy">Assign
                    </v-btn>
                </v-card-actions>
            </template>

            <template v-if="dogs.state == 'Inprogress'">
                <v-card-title class="layout justify-center">
                    Select the technician user.
                </v-card-title>
                <v-card-text>
                    <v-select :items="users" item-text="name" itemid="id" return-object label="User"
                              :error-messages="errors" placeholder="انتخاب کاربر" v-model="selected_user"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="m-1">Cancel</v-btn>
                    <v-btn depressed color="secondary" class="m-2" @click="assignTask" :loading="busy">Assign
                    </v-btn>
                </v-card-actions>
            </template>

            <template v-if="dogs.state == 'Underworking'">
                <v-card-title class="layout justify-center">
                    If the mission ends, please touch the appropriate option.
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="orange" class="m-2" @click="NotFinded" :loading="busy">Unfound building
                    </v-btn>
                    <v-btn depressed color="success" class="m-2" @click="Finded" :loading="busy">Enter information</v-btn>
                </v-card-actions>
            </template>

            <template v-if="dogs.state == 'Finished'">
                <template v-if="this.$store.state.users.access_level< 3">

                    <v-card-title class="layout justify-center">
                        Depending on the location of the report sent by the technician as well as the user's message, confirm the accuracy of the information.
                    </v-card-title>
                    <!--                    <v-container bg fill-height grid-list-md text-xs-center>-->
                    <!--                    <LocationForComparison></LocationForComparison>-->
                    <!--                    </v-container>-->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="orange" class="m-2" @click="ReportMistake" :loading="busy">Report error
                        </v-btn>
                        <v-btn depressed color="success" class="m-2" @click="LocationVerified" :loading="busy">Confirm location
                        </v-btn>
                    </v-card-actions>
                </template>
            </template>
        </v-card>
    </div>
</template>

<script>

    import vueFilePond from 'vue-filepond';
    // import LocationForComparison from "./LocationForComparison";

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond,

        },
        props: [
            'message',
        ],
        data() {
            return {
                video: {},
                canvas: {},
                captures: [],
                snackbar: true,
                text: 'Hello, I\'m a snackbar',
            }
        },
        data: function () {
            return {
                rules: [v => v.length <= 30 || 'حداکثر طول پیام 40 است'],
                //myFiles: ['index.html'],
                dogs: [],
                images: '',
                users: '',
                selected_user: '',
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '',
                        'state': '',
                        'id': '',
                        'level': '',
                        'catched_lon': '',
                        'catched_lat': '',
                        'textMessage': ''
                    },
                LatLong: {
                    'Lat': '',
                    'Long': ''
                },
                newStatus: {
                    status: true
                },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                gettingLocation: '',
                errorStr: '',
                locationLatLong: '',
                // textAreaMessage:'\nب'
                textAreaMessage: '',
                textMessage: '',
                images2: '',
                images5: ''
            }
        },
        //
        created() {
            this.fetchData();//users
            this.getMessageInfo();
            this.form.catched_lat = this.$store.state.LatLong.Lat;
            this.form.catched_lon = this.$store.state.LatLong.Long;

            this.locateAsync();
            // if(navigator.geolocation){
            //     navigator.geolocation.getCurrentPosition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // };
        },

        mounted: function () {


        },

        methods: {
            async locateAsync() {
                this.locationLatLong = await this.getLocation();
                this.form.catched_lat = this.locationLatLong.coords.latitude;
                this.form.catched_lon = this.locationLatLong.coords.longitude;
            },

            async getLocation() {
                return new Promise((resolve, reject) => {
                    if (!("geolocation" in navigator)) {
                        reject(new Error('Geolocation is not available.'));
                    }
                    navigator.geolocation.getCurrentPosition(resolve, reject);

                });
            },

            fetchData() {
                this.busy = true;
                axios.get('/users/level')
                    .then(response => {
                        console.log(response.data);
                        this.users = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;
                })
            },

            getMessageInfo() {
                this.busy = true;
                axios.get('/report/index/' + this.$route.params.id)
                    .then(response => {
                        //console.log(response);
                        this.dogs = response.data;
                        if (this.dogs.id2) {
                            axios.get('/user/' + this.dogs.id2)
                                .then(response => {
                                    this.images2 = "/storage/images/user/" + response.data.pic;
                                })
                        }
                        if (this.dogs.id5) {
                            axios.get('/user/' + this.dogs.id5)
                                .then(response => {
                                    this.images5 = "/storage/images/user/" + response.data.pic;
                                })
                        }
                        // this.images= require('../../../../public/storage/images/publics/'+this.dogs.fig);
                        this.images = '/storage/images/publics/' + this.dogs.fig;
                        //this.images=require( '../../../../storage/app/public/images/publics/Public_100420200728185f7979927beb2.jpg');
                    })
            },

            addNewTask() {
                this.form.textMessage = this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.name = this.selected_user.id;
                this.form.state = "Unread";
                this.form.id = this.$route.params.id;
                this.form.level = this.selected_user.access_level;
                this.newStatus.status = false;

                axios.post('../report/update', this.form)
                    .then(response => {
                        console.log(this.responses);
                        this.$store.commit("updateSnackbarAssign", true);
                        this.$router.push({
                            name: 'public',
                            params: {locationss: this.locationss, snackbarAssign: true}
                        });
                    })
                    .catch(errors => {
                        console.log(this.form);
                        alert('error');
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            assignTask() {
                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.name = this.selected_user.id;
                this.form.state = "Inprogress";
                this.form.id = this.$route.params.id;
                this.form.level = this.selected_user.access_level;
                //this.FindLevel1();
                axios.post('../report/update', this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'public'});
                        this.$store.commit("updateSnackbarInprogress", true);

                        this.$router.push({name: 'customerMessages', params: {snackbarInprogress: true}});

                        //alert('پیغام شما ثبت شد')
                        //this.$router.push(toString(response.data[0].data.id));
                        // flash('success message ...');
                        // axios.get('../report');
                    })
                    .catch(errors => {
                        console.log(this.form);
                        alert(errors);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            Finded() {

                this.form.catched_lon = this.$store.state.LatLong.Long;
                this.form.catched_lat = this.$store.state.LatLong.Lat;

                this.locateAsync();
                // this.form.catched_lat = this.locationLatLong.coords.latitude;
                // this.form.catched_lon = this.locationLatLong.coords.longitude;
                navigator.geolocation.getCurrentPosition(position => {
                    this.form.catched_lon = position.coords.longitude;
                    this.form.catched_lat = position.coords.latitude;
                    this.LatLong.Lat = position.coords.latitude;
                    this.LatLong.Long = position.coords.longitude;
                    this.$store.commit("updateLocation", this.LatLong);
                });
                if (this.form.catched_lon === '') {
                    this.snackbar = true;
                    this.text = "روشن";

                    // alert('salam');
                } else {
                    this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                    this.busy = true;
                    this.form.state = "Underworking";
                    this.form.id = this.$route.params.id;
                    this.form.level = 4;
                    this.$router.push({
                        name: 'create',
                        params: {
                            fig_id: this.images,
                            form_public: this.form,
                            locationss: this.locationss,
                            cell_number: this.dogs.cell_number
                        }
                    });
                    this.busy = false;
                }

                // axios.post('../report/update', this.form)
                //     .then( response => {
                //         console.log(this.responses);
                //     })
                //     .catch(errors =>{
                //         console.log(this.form);
                //         this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(()=>{
                //     this.busy=false;
                // });
            },

            NotFinded() {
                this.form.catched_lon = this.$store.state.LatLong.Long;
                this.form.catched_lat = this.$store.state.LatLong.Lat;
                this.locateAsync();


                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                        this.LatLong.Lat = position.coords.latitude;
                        this.LatLong.Long = position.coords.longitude;
                        this.$store.commit("updateLocation", this.LatLong);
                    });
                }
                if (this.form.catched_lon === '') {
                    this.snackbar = true;
                    this.text = "روشن";

                    // alert('salam');
                } else {
                    this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                    this.busy = true;
                    this.form.state = "Underworking";
                    this.form.id = this.$route.params.id;
                    this.form.level = 4;
                    axios.post('../report/update', this.form)
                        .then(response => {
                            //this.responses = response.data[0].data.id;
                            console.log(this.responses);
                            this.$store.commit("updateSnackbarNotFinded", true);
                            this.$router.push({name: 'customerMessages', params: {snackbarNotFinded: true}});

                            // this.$router.push({name:'public'});
                            //alert('پیغام شما ثبت شد')
                            //this.$router.push(toString(response.data[0].data.id));
                            // flash('success message ...');
                            // axios.get('../report');
                        })
                        .catch(errors => {
                            console.log(this.form);
                            this.errors = errors.response.data.errors;
                            console.log(this.errors);
                        }).finally(() => {
                        this.busy = false;
                    });
                }
            },

            ReportMistake() {
                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.state = "Finished";
                this.form.id = this.$route.params.id;
                this.form.level = 6;//Report A mistake
                axios.post('../report/update', this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.responses);
                        // this.$router.push({name:'public'});
                        this.$store.commit("updateSnackbarFinal", true);
                        this.$router.push({name: 'customerMessages', params: {snackbarFinal: true}});

                        //alert('پیغام شما ثبت شد')
                        //this.$router.push(toString(response.data[0].data.id));
                        // flash('success message ...');
                        // axios.get('../report');
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            LocationVerified() {
                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.state = "Finished";
                this.form.id = this.$route.params.id;
                // this.form.level=4;
                this.form.level = 7;//Verify
                axios.post('../report/update', this.form)
                    .then(response => {
                        //this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'public'});
                        this.$store.commit("updateSnackbarFinal", true);

                        this.$router.push({name: 'customerMessages', params: {snackbarFinal: true}});

                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
        }
    }
</script>

<style scoped>
    .spacing-playground .v-select .v-input__prepend-outer, .spacing-playground .v-select .v-input__append-outer {
        margin-top: 0.55rem;
        margin-right: 0.2rem;
    }

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>
