<template>
    <div>
        <v-select
            v-model="localForm.type"
            :items="['Fire detector modules']"
            label="Hardware type and brand"
            :error-messages="errors['type']"
            placeholder="Type">
        </v-select>
        <v-text-field
            v-model="localForm.unique_id"
            label="Hardware ID"
            :error-messages="errors['unique_id']"
            placeholder="Enter ID">
        </v-text-field>
        <v-text-field
            v-model="localForm.cell_number"
            label="Cell number"
            :error-messages="errors['cell_number']"
            placeholder="Enter cell number">
        </v-text-field>
        <v-text-field
            v-model="localForm.wifi_ssid"
            label="WiFi SSID"
            :error-messages="errors['wifi_ssid']"
            placeholder="Enter WiFi SSID">
        </v-text-field>
        <v-text-field
            v-model="localForm.wifi_password"
            label="WiFi Password"
            :error-messages="errors['wifi_password']"
            placeholder="Enter WiFi Password">
        </v-text-field>
        <v-text-field
            v-model="localForm.restful_address"
            label="Webhook or restfulAPI"
            :error-messages="errors['restful_address']"
            placeholder="Enter Webhook or restfulAPI">
        </v-text-field>
    </div>
</template>

<script>
    export default {
        props: {
            value: { // Use v-model
                type: Object,
                default: () => ({})
            },
            errors: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                localForm: this.value
            };
        },
        watch: {
            localForm: {
                handler(newVal) {
                    this.$emit('input', newVal); // This makes v-model work
                },
                deep: true
            }
        }
    }
</script>
