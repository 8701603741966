<template>
    <div class="map-container">
        <MglMap
            :accessToken="mapboxAccessToken"
            :mapStyle="'mapbox://styles/mapbox/dark-v10'"
            :center="mapCenter"
            :zoom="mapZoom"
            ref="map"
            @load="onMapLoad"
        >
            <MglNavigationControl position="top-right"/>
            <MglGeolocateControl position="top-right"/>
        </MglMap>
    </div>
</template>

<script>
    import {MglMap, MglGeojsonLayer, MglSource, MglGeolocateControl, MglNavigationControl,} from 'vue-mapbox';
    import Mapbox from 'mapbox-gl';

    export default {
        name: 'Heatmap',
        components: {
            MglMap,
            MglGeojsonLayer,
            MglSource,
            MglGeolocateControl,
            MglNavigationControl,
        },
        data() {
            return {
                mapboxAccessToken: 'pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ',
                mapCenter: [25.2797, 54.6872],
                mapZoom: 11.15,
                mapLoaded: false,
                sourceId: 'sampleData',
                layerId: 'heatmap-layer',
                layerType: 'heatmap',
                sampleData: {
                    type: 'FeatureCollection',
                    features: this.generateSampleData(),
                },
                heatmapPaint: {
                    'heatmap-weight': ['interpolate', ['linear'], ['get', 'magnitude'], 0, 0, 6, 1],
                    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
                    'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        'rgba(33,102,172,0)',
                        0.2,
                        'rgb(103,169,207)',
                        0.4,
                        'rgb(209,229,240)',
                        0.6,
                        'rgb(253,219,199)',
                        0.8,
                        'rgb(239,138,98)',
                        1,
                        'rgb(178,24,43)',
                    ],
                    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
                    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 20, 1],
                },

            };
        },
        methods: {
            onMapLoad() {
                this.mapLoaded = true;
                this.$refs.map.map.addSource('sampleData', {
                    type: 'geojson',
                    data: this.sampleData
                });
                this.$refs.map.map.addLayer({
                    id: 'heatmap-layer',
                    type: 'heatmap',
                    source: 'sampleData',
                    paint: this.heatmapPaint
                });
            },
            generateSampleData() {
                const features = [];
                for (let i = 0; i < 100; i++) {
                    const longitude = 25.2797 + (Math.random() - 0.5) / 10;
                    const latitude = 54.6872 + (Math.random() - 0.5) / 10;
                    features.push({
                        type: 'Feature',
                        properties: {magnitude: 1},
                        geometry: {type: 'Point', coordinates: [longitude, latitude]},
                    });
                }
                return features;
            },
        },
    };
</script>

<style scoped>
    .map-container {
        width: 100%;
        height: 97%;
    }
</style>


<!--<template>-->

<!--    <div style="width: 100%; height: 100%;">-->
<!--        <MglMap :accessToken="mapirToken"-->
<!--                :center.sync="centers"-->
<!--                mapStyle="mapbox://styles/mapbox/streets-v11"-->
<!--                :zoom="9">-->

<!--            <MglNavigationControl position="top-right"/>-->
<!--            <MglGeolocateControl position="top-right"/>-->
<!--            <MglGeojsonLayer-->
<!--                sourceId="mySource"-->
<!--                :source="geoJsonSource2"-->
<!--                layerId="myLayer"-->
<!--                :layer="geoJsonlayer2"-->
<!--            />-->
<!--            <MglMarker v-for="usersinfo in users" :key="usersinfo"-->
<!--                       :coordinates.sync="usersinfo.LatLong"-->
<!--                       :color="colors">-->
<!--                <MglPopup>-->
<!--                    <VCard>-->
<!--                        <div>Location of home associated with ID {{usersinfo.id}}</div>-->
<!--                    </VCard>-->
<!--                </MglPopup>-->
<!--            </MglMarker>-->
<!--        </MglMap>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    // import { mapir, mapMarker , mapPopup , mapGeojsonLayer, mapGeolocateControl, mapNavigationControl,} from "mapir-vue";-->
<!--    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";-->

<!--    export default {-->
<!--        name: "mapirMarker",-->
<!--        components: {-->
<!--            MglMap,-->
<!--            MglMarker,-->
<!--            MglPopup,-->
<!--            MglGeojsonLayer,-->
<!--            MglGeolocateControl,-->
<!--            MglNavigationControl,-->
<!--        },-->
<!--        data() {-->
<!--            return {-->
<!--                users: '',-->
<!--                usersinfos: '',-->
<!--                colors: "green",-->
<!--                coordinates: [51.420296, 35.732379],-->
<!--                centers: [25.295682, 54.706806],-->
<!--                markerCoordinates: [51.420296, 35.732379],-->
<!--                markerCoordinates1: [[52.420296, 35.732379], [53.420296, 35.732379]],-->
<!--                markerCoordinates2: [53.420296, 35.732379],-->
<!--                // mapirToken: "<Your API Key>"-->
<!--                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",-->
<!--                geoJsonSource: {-->
<!--                    type: "geojson",-->
<!--                    data: {-->
<!--                        "type": "FeatureCollection",-->
<!--                        "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},-->
<!--                        "features": [-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.682434]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.681212]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.688787]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6812435]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.684544]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.684353]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.684242]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6875751]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6836541]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6878944]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6831337]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6886433]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6875421]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994521",-->
<!--                                    "mag": 1000,-->
<!--                                    "time": 1507425650893,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994519",-->
<!--                                    "mag": 1.7,-->
<!--                                    "time": 1507425289659,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.8041560]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994517",-->
<!--                                    "mag": 1.6,-->
<!--                                    "time": 1507424832518,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.1306695, 35.8041560]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ci38021336",-->
<!--                                    "mag": 1.42,-->
<!--                                    "time": 1507423898710,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.1306695, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "us2000b2nn",-->
<!--                                    "mag": 4.2,-->
<!--                                    "time": 1507422626990,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994510",-->
<!--                                    "mag": 1.6,-->
<!--                                    "time": 1507422449194,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.5053, 63.0719, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "us2000b2nb",-->
<!--                                    "mag": 4.6,-->
<!--                                    "time": 1507420784440,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-178.4576, -20.2873, 614.26]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994298",-->
<!--                                    "mag": 2.4,-->
<!--                                    "time": 1507419370097,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-148.789, 63.1725, 7.5]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "nc72905861",-->
<!--                                    "mag": 1.39,-->
<!--                                    "time": 1507418785100,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ci38021304",-->
<!--                                    "mag": 1.11,-->
<!--                                    "time": 1507418426010,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-117.0155, 33.656333, 12.37]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994293",-->
<!--                                    "mag": 1.5,-->
<!--                                    "time": 1507417256497,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.512, 63.0879, 10.8]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994287",-->
<!--                                    "mag": 2.0,-->
<!--                                    "time": 1507413903714,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.4378, 63.0933, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994285",-->
<!--                                    "mag": 1.5,-->
<!--                                    "time": 1507413670029,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994283",-->
<!--                                    "mag": 1.4,-->
<!--                                    "time": 1507413587442,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.5325, 63.0844, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994280",-->
<!--                                    "mag": 1.3,-->
<!--                                    "time": 1507413266231,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-149.4752, 61.8518, 54.3]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994278",-->
<!--                                    "mag": 1.8,-->
<!--                                    "time": 1507413195076,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-150.8597, 61.6214, 50.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994274",-->
<!--                                    "mag": 1.9,-->
<!--                                    "time": 1507412827617,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [51.3898668, 35.6899503]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994273",-->
<!--                                    "mag": 1.2,-->
<!--                                    "time": 1507411925999,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.2484, 61.2705, 69.1]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16994270",-->
<!--                                    "mag": 2.0,-->
<!--                                    "time": 1507411814209,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-152.0732, 65.5942, 14.8]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "us2000b2ly",-->
<!--                                    "mag": 4.1,-->
<!--                                    "time": 1507411448780,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-90.5445, 13.5146, 54.36]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "nc72905841",-->
<!--                                    "mag": 1.38,-->
<!--                                    "time": 1507411214450,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-118.819504, 37.605499, 4.14]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "nc72905836",-->
<!--                                    "mag": 1.4,-->
<!--                                    "time": 1507410206440,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-118.930168, 37.636833, -0.71]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ci38021272",-->
<!--                                    "mag": 1.34,-->
<!--                                    "time": 1507408122250,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-117.509167, 34.1555, 16.34]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ci38021264",-->
<!--                                    "mag": 1.0,-->
<!--                                    "time": 1507407938100,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-116.792167, 33.5115, 5.16]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993963",-->
<!--                                    "mag": 1.4,-->
<!--                                    "time": 1507407100665,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-150.9126, 63.1812, 150.4]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "hv61936851",-->
<!--                                    "mag": 2.55,-->
<!--                                    "time": 1507406278360,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-155.078659, 19.374167, 2.15]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993960",-->
<!--                                    "mag": 1.4,-->
<!--                                    "time": 1507405129739,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-147.3106, 61.5726, 26.9]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993952",-->
<!--                                    "mag": 1.7,-->
<!--                                    "time": 1507403679922,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-150.5846, 60.2607, 34.2]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ci38021224",-->
<!--                                    "mag": 1.04,-->
<!--                                    "time": 1507401391710,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-116.929, 34.254833, 18.27]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993752",-->
<!--                                    "mag": 1.3,-->
<!--                                    "time": 1507401212982,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.5065, 63.0847, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993746",-->
<!--                                    "mag": 1.3,-->
<!--                                    "time": 1507399350671,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-147.8929, 63.5257, 3.3]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "us2000b2jk",-->
<!--                                    "mag": 4.6,-->
<!--                                    "time": 1507398878400,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-175.7258, -18.9821, 195.22]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993741",-->
<!--                                    "mag": 1.6,-->
<!--                                    "time": 1507398797233,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.3473, 63.0775, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "nc72905766",-->
<!--                                    "mag": 2.64,-->
<!--                                    "time": 1507397278960,-->
<!--                                    "felt": 4,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-121.137497, 36.579834, 7.72]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993738",-->
<!--                                    "mag": 1.4,-->
<!--                                    "time": 1507396778206,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.1075, 61.8312, 71.7]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993736",-->
<!--                                    "mag": 1.2,-->
<!--                                    "time": 1507396542471,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.3769, 63.0621, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "us2000b2ii",-->
<!--                                    "mag": 4.3,-->
<!--                                    "time": 1507395765330,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-94.8319, 16.7195, 58.84]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "uw61339006",-->
<!--                                    "mag": 1.91,-->
<!--                                    "time": 1507395622730,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-120.689833, 47.049167, 5.38]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993732",-->
<!--                                    "mag": 1.7,-->
<!--                                    "time": 1507395602456,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.5283, 63.0785, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993720",-->
<!--                                    "mag": 2.5,-->
<!--                                    "time": 1507394741482,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.6683, 60.7696, 67.1]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993714",-->
<!--                                    "mag": 1.6,-->
<!--                                    "time": 1507394402896,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-149.7591, 61.6478, 30.6]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993710",-->
<!--                                    "mag": 1.5,-->
<!--                                    "time": 1507393418705,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.3458, 63.0633, 0.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993699",-->
<!--                                    "mag": 1.6,-->
<!--                                    "time": 1507392875390,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.4669, 63.0675, 3.4]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993695",-->
<!--                                    "mag": 1.3,-->
<!--                                    "time": 1507392837463,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.5169, 63.083, 1.0]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993692",-->
<!--                                    "mag": 2.3,-->
<!--                                    "time": 1507392657193,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.4771, 63.0742, 4.3]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ak16993486",-->
<!--                                    "mag": 4.2,-->
<!--                                    "time": 1507392435159,-->
<!--                                    "felt": 2,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-151.458, 63.1276, 14.9]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "us2000b2hz",-->
<!--                                    "mag": 4.3,-->
<!--                                    "time": 1507392287310,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-94.9686, 16.6087, 69.65]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "nc72905751",-->
<!--                                    "mag": 1.21,-->
<!--                                    "time": 1507391530870,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-119.023666, 37.605335, 1.97]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "ci38021056",-->
<!--                                    "mag": 1.95,-->
<!--                                    "time": 1507390783500,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-116.793833, 33.493, 3.65]}-->
<!--                            },-->
<!--                            {"type": "Feature",-->
<!--                                "properties": {-->
<!--                                    "id": "hv61900626",-->
<!--                                    "mag": 2.91,-->
<!--                                    "time": 1504833891990,-->
<!--                                    "felt": null,-->
<!--                                    "tsunami": 0-->
<!--                                },-->
<!--                                "geometry": {"type": "Point", "coordinates": [-155.011833, 19.399333, 2.609]}-->
<!--                            }-->
<!--                        ]-->
<!--                    }-->
<!--                },-->
<!--                geoJsonlayer: {-->
<!--                    id: "myLayer",-->
<!--                    type: "heatmap",-->
<!--                    source: "mySource",-->
<!--                    maxzoom: 15,-->
<!--                    paint: {-->
<!--                        'fill-color': '#888888', // or any other valid color value-->
<!--                        'fill-opacity': 0.5, // a number between 0 and 1-->

<!--// Increase the heatmap weight based on frequency and property magnitude-->
<!--                        "heatmap-weight": [-->
<!--                            "interpolate",-->
<!--                            ["linear"],-->
<!--                            ["get", "mag"],-->
<!--                            0,-->
<!--                            0,-->
<!--                            6,-->
<!--                            1-->
<!--                        ],-->
<!--                        "heatmap-intensity": [-->
<!--                            "interpolate",-->
<!--                            ["linear"],-->
<!--                            ["zoom"],-->
<!--                            0,-->
<!--                            1,-->
<!--                            9,-->
<!--                            3-->
<!--                        ],-->
<!--                        "heatmap-color": [-->
<!--                            "interpolate",-->
<!--                            ["linear"],-->
<!--                            ["heatmap-density"],-->
<!--                            0,-->
<!--                            "rgba(33,102,172,0)",-->
<!--                            0.2,-->
<!--                            "rgb(103,169,207)",-->
<!--                            0.4,-->
<!--                            "rgb(209,229,240)",-->
<!--                            0.6,-->
<!--                            "rgb(253,219,199)",-->
<!--                            0.8,-->
<!--                            "rgb(239,138,98)",-->
<!--                            1,-->
<!--                            "rgb(178,24,43)"-->
<!--                        ],-->
<!--                        "heatmap-radius": [-->
<!--                            "interpolate",-->
<!--                            ["linear"],-->
<!--                            ["zoom"],-->
<!--                            0,-->
<!--                            2,-->
<!--                            9,-->
<!--                            20-->
<!--                        ],-->
<!--                        "heatmap-opacity": [-->
<!--                            "interpolate",-->
<!--                            ["linear"],-->
<!--                            ["zoom"],-->
<!--                            7,-->
<!--                            1,-->
<!--                            25,-->
<!--                            0-->
<!--                        ]-->
<!--                    }-->
<!--                },-->

<!--                geoJsonSource2: {-->
<!--                    type: "geojson",-->
<!--                    data: {-->
<!--                        type: "FeatureCollection",-->
<!--                        features: [-->
<!--                            {-->
<!--                                type: "Feature",-->
<!--                                properties: {},-->
<!--                                geometry: {-->
<!--                                    type: "Polygon",-->
<!--                                    coordinates: [-->
<!--                                        [-->
<!--                                            [51.3898668, 35.6899503],-->
<!--                                            [51.3898668, 35.8041560],-->
<!--                                            [51.1306695, 35.8041560],-->
<!--                                            [51.1306695, 35.6899503],-->
<!--                                        ]-->
<!--                                    ]-->
<!--                                }-->
<!--                            },-->
<!--                        ]-->
<!--                    }-->
<!--                },-->
<!--                geoJsonlayer2: {-->
<!--                    id: "myLayer",-->
<!--                    type: "heatmap",-->
<!--                    source: "mySource",-->
<!--                    paint: {-->
<!--                        "fill-color": "#111111",-->
<!--                        "fill-opacity": 0.4-->
<!--                    }-->
<!--                }-->

<!--            };-->
<!--        },-->
<!--        created() {-->
<!--            this.fetchData();-->

<!--        },-->
<!--        methods: {-->
<!--            fetchData() {-->
<!--                this.busy = true;-->
<!--                axios.get('/alldoglocation')-->
<!--                // axios.get('https://dweet.io/get/latest/dweet/for/Mohammad19871366/', { crossdomain: true },-->
<!--                //     {-->
<!--                //         "Access-Control-Allow-Origin": "https://dweet.io/get/latest/dweet/for/Mohammad19871366/",-->
<!--                //         "Access-Control-Allow-Methods": "GET",-->
<!--                //         "Access-Control-Max-Age": 86400,-->
<!--                //         "Access-Control-Allow-Headers": "Content-Type, Authorization",-->
<!--                //         'Access-Control-Allow-Credentials': 'true',-->
<!--                //         headers: {-->
<!--                //             // remove headers-->
<!--                //         }-->
<!--                //     })-->
<!--                    .then(response => {-->
<!--                        this.users = response.data[0].data;-->
<!--                        // this.users = response.data.with[0].content;-->
<!--                        // this.usersinfos = [this.users.Longitude, this.users.Latitude ];-->
<!--                        // this.usersinfos = this.users[1].LatLong;-->
<!--                        // console.log(this.users[1]);-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        // alert('unable to fetch');-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->

<!--                })-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->
<!--    .map-view {-->
<!--        height: calc(100vh - 48px);-->
<!--    }-->
<!--</style>-->

<!--<template>-->

<!--    <div>-->
<!--        <div class="label-top" v-html="label"></div>-->
<!--        <div class="uploader-container mx-auto" :class="{dense:dense,dark:dark,disabled:disabled,'has-value':true}">-->
<!--            <v-slide-y-transition>-->
<!--                <v-avatar-->
<!--                    v-if="last_image"-->
<!--                    class="m-2 prev-img"-->
<!--                    size="76"-->
<!--                    :style="clearable?'top: 50%;':''"-->
<!--                >-->
<!--                    <img :src="image_url">-->
<!--                </v-avatar>-->
<!--            </v-slide-y-transition>-->

<!--            <file-pond-->
<!--                v-if="!clearable || !last_image"-->
<!--                ref="pond"-->
<!--                class="mx-auto pointer-pointer fadeIn"-->
<!--                :name="paramName"-->
<!--                style="min-height: 84px"-->

<!--                :label-idle="-->
<!--        (placeholderImage-->
<!--          ? <img src='${placeholderImage}' class='placeholder'>-->
<!--          : '') +-->
<!--          `<p class='text-dark m-0'><i class='fas fa-plus text-success ml-2'></i> ${$t(-->
<!--            'global.image_uploader.label'-->
<!--          )}  </p>`-->
<!--      "-->
<!--                :allow-multiple="allowMultiple"-->
<!--                :accepted-file-types="acceptedFileTypes"-->
<!--                :server="server_credential"-->
<!--                :files="logo"-->
<!--                :maxFileSize="maxFileSize"-->
<!--                :max-files="maxFiles"-->
<!--                check-validity="true"-->
<!--                @processfile="handleProcessFile"-->
<!--                @error="handleFilePondError"-->


<!--                allowImageResize="true"-->
<!--                imageResizeUpscale="false"-->
<!--                imageResizeMode="contain"-->
<!--                :imageResizeTargetWidth="imageResizeTargetWidth"-->
<!--            />-->

<!--            <p class="small file-size-limit">-->
<!--                {{ $t("global.image_uploader.max_size", { size: maxFileSize }) }}-->
<!--            </p>-->

<!--            <v-btn fab depressed small v-if="clearable && last_image" @click.stop="()=>{last_image=null;$emit('onClear')}" class="absolute-top-end m-2 z2"-->
<!--            ><v-icon>close</v-icon></v-btn-->
<!--            >-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    export default {-->
<!--        name: "ImageUploader",-->
<!--        props: {-->
<!--            label: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            server: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->

<!--            maxFileSize: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "1MB"-->
<!--            },-->

<!--            image: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            placeholderImage: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            paramName: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "photo"-->
<!--            },-->

<!--            maxFiles: {-->
<!--                required: false,-->
<!--                type: Number,-->
<!--                default: 1-->
<!--            },-->

<!--            allowMultiple: {-->
<!--                required: false,-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            clearable: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dense: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dark: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            disabled: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            imageResizeTargetWidth:{-->
<!--                default:1600-->
<!--            },-->


<!--            noSvg: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--        },-->

<!--        data: () => ({-->
<!--            logo: null,-->
<!--            version: "",-->
<!--            last_image:null-->
<!--        }),-->

<!--        computed: {-->

<!--            acceptedFileTypes(){-->
<!--                return this.noSvg?"image/jpeg, image/png, image/webp":"image/jpeg, image/png, image/svg+xml, image/webp"-->
<!--            },-->
<!--            image_url() {-->
<!--                if (!this.image) return null;-->
<!--                return this.image + this.version;-->
<!--            },-->
<!--            server_credential() {-->

<!--                const token = document.head.querySelector('meta[name="csrf-token"]');-->

<!--                return {-->
<!--                    url: this.server,-->
<!--                    process: {-->
<!--                        method: "POST",-->
<!--                        withCredentials: true,-->

<!--                        headers: {-->
<!--                            'X-CSRF-TOKEN': token?token.content:''-->
<!--                        },-->
<!--                    }-->
<!--                };-->
<!--            }-->
<!--        },-->
<!--        watch:{-->
<!--            image(){-->
<!--                this.last_image=this.image;-->
<!--            }-->
<!--        },-->
<!--        created() {-->
<!--            this.last_image=this.image;-->
<!--            this.version = "?v=" + Math.random(100); //Invalidate cache!-->


<!--        },-->

<!--        methods: {-->
<!--            handleFilePondError(error){-->
<!--                this.showErrorAlert(null,error.body)-->
<!--                //  console.error('handleFilePondError',error)-->
<!--            },-->

<!--    handleProcessFile: function(error, file) {-->
<!--        if (!error) {-->
<!--            let response = JSON.parse(file.serverId);-->
<!--            this.$emit("response", response);-->

<!--            if (Array.isArray(response.files)) {-->
<!--                response.files.forEach(file => {-->
<!--                    console.log("done", file.path);-->
<!--                    this.$emit("new-path", file.path);-->
<!--                    this.$emit("new-url", file.url);-->
<!--                });-->
<!--            } else {-->
<!--                console.log("done", response.files.path);-->
<!--                this.$emit("new-path", response.files.path);-->
<!--                this.$emit("new-url", response.files.url);-->

<!--                this.$nextTick(() => {-->
<!--                    this.version = "?v=" + Math.random(100);-->
<!--                });-->
<!--            }-->

<!--            return true;-->
<!--        }-->
<!--    }-->
<!--    }-->
<!--    };-->
<!--</script>-->


<!--<template>-->
<!--<div >-->
<!--    <LocationMap :doginfos="doginfos"></LocationMap>-->
<!--</div>-->
<!--</template>-->
<!--<h1>Live Location of Dogs</h1>-->
<!--<script>-->
<!--    import LocationMap from "../components/LocationMap";-->
<!--    import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';-->
<!--    export default {-->
<!--        name: "AllMapShow",-->
<!--        components: {-->
<!--            LocationMap-->
<!--        },-->
<!--        mounted(){-->
<!--            axios.get('/dogdata')-->
<!--                .then(response =>{-->
<!--                    this.doginfos = response.data[0].data;-->
<!--                    this.loading = false;-->
<!--                })-->
<!--                .catch(errors =>{-->
<!--                    this.loading = false;-->
<!--                    alert('unable to fetch');-->
<!--                })-->
<!--        },-->

<!--        data:function () {-->
<!--            return {-->
<!--                loading:true,-->
<!--                doginfos: null,-->
<!--            }-->
<!--        },-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->
