<template>
<div>

    <v-data-table
        :headers="headers"
        :items="dogs"
        :items-per-page="10"
        sort-by="id"
        :sort-desc="true"
        @click:row="item=>$router.push('')"
        :loading="busy"
        @page-count="10"
        :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'items per page',
      }"
    >

        <template v-slot:item.track="{ item }">
            <v-btn @click.prevent="ShowLocation(item.id)" icon  ><v-icon>gps_not_fixed</v-icon></v-btn>
        </template>

        <template v-slot:item.edit="{ item }">
            <v-btn @click.prevent="EditData(item.id)" icon ><v-icon>edit</v-icon></v-btn>
        </template>


    </v-data-table>
</div>
</template>

<script>
    import PhotoCircle from "../components/PhotoCircle";
    import LocationMap from "../components/LocationMap";

    export default {
        name: "ProfilesIndex",

        components:{
            PhotoCircle
        },

        created() {
            this.fetchData()
        },
        mounted(){

        },



        data:function () {
            return {
                busy:false,
                dogs: [],

                headers: [
                    {text: 'Home ID', value: 'id'},

                    {
                        text: 'User Type',
                        align: 'start',
                        value: 'race',
                    },
                    { text: 'age', value: 'age' },
                    { text: 'type', value: 'sex' },
                    { text: 'color', value: 'color' },
                    { text: 'location', value: 'track', sortable: false, },
                    // { text: 'edit', value: 'edit',  sortable: false,},
                    { text: 'technician', value: 'technician'},
                ],


            }
        },
        methods: {
            fetchData(){
                this.busy = true;

                axios.get('/homedatas/notapp')
                    .then(response =>{
                        this.dogs = response.data[0].data;
                    })
                    .catch(errors =>{
                        // alert('unable to fetch');
                    }).finally(()=>{
                    this.busy = false;

                })
            },

                ShowLocation: function (id) {
                    this.$router.push('../doglocation/'+id)
                        .then()
                        .catch(error => {
                            alert('Cannot edit dog info')
                        })

                },
            EditData: function (id) {
                this.$router.push('../newprofiles/'+id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },


        }
    }

</script>

<style scoped>

</style>
