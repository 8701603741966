<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title> Message ID: {{ id }}</v-card-title>
                    <v-card-text style="height: 200px; overflow-y: auto;">
                        <div v-for="message in messages" :key="message.id">
                            <strong>{{ message.fullname }}:</strong> {{ message.msg }}
                            <span style="font-size: 0.8em; position: absolute; right: 0;">
                                {{ message.time }}
                            </span>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-container fluid>
                            <v-row align="center">
                                <v-col class="py-0" v-if="">
                                    <v-select
                                        v-model="status"
                                        :items="formattedItems"
                                        label="Select the status of message"
                                        outlined
                                        dense
                                        class="my-0"
                                    ></v-select>
                                </v-col>
                                <v-col class="py-0" cols="6">
                                    <v-text-field
                                        v-model="newMessage"
                                        label="Type a message"
                                        @keyup.enter="sendMessage"
                                        outlined
                                        dense
                                        clearable
                                        class="my-0"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" cols="2">
                                    <v-btn block @click="sendMessage" color="purple" dark>Send</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                items:['inprogress' , 'professional_response', 'confirmation', 'closed'],
                newMessage: "",
                enableSend: false,
            };

        },
        computed: {
            formattedItems() {
                return this.items.map(item => {
                    return { text: this.getTextState(item), value: item }
                });
            }
        },
        props: ['id', 'messages'],
        methods: {
            getTextState(state) {
                if (state == "submitted") return 'Unread message'
                else if (state == "inprogress") return 'In progress tasks'
                else if (state == "professional_response") return 'In action tasks'
                else if (state == "confirmation") return 'Approved tasks'
                else if (state == "closed") return 'Finished tasks'
            },
            sendMessage() {
                this.enableSend = true;
                if (this.newMessage.trim() !== "") {
                    let currentTime = new Date();
                    let newMessage = this.newMessage.trim();
                    let msg = {
                        'status': this.status,
                        'message': [{
                            'accessLevel': this.$store.state.users.access_level,
                            'id': this.$store.state.users.id,
                            'fullname': this.$store.state.users.first_name + ' ' + this.$store.state.users.surname,
                            'msg': newMessage,
                            'time': currentTime.toISOString()
                        }],
                    };
                    axios.put('../../team_msg/' + this.id, msg)
                        .then(response => {
                            // this.enableSend = false;
                            this.messages.push({
                                id: this.$store.state.users.id,
                                fullname: this.$store.state.users.first_name + ' ' + this.$store.state.users.surname,
                                msg: newMessage,
                                access_level: this.$store.state.users.access_level,
                                time: currentTime.toLocaleTimeString(undefined, {hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'}),
                            });
                        })
                        .catch(errors => {
                        })
                    this.newMessage = "";

                }
            },
        },
    };
</script>


<!--<template>-->

<!--    <div class="pt-16 overflow-visible">-->

<!--        <v-card max-width="80%" class="mx-auto rounded-form" outlined>-->
<!--            <v-card-title class="layout justify-center">-->
<!--                Messages from User-->
<!--            </v-card-title>-->
<!--            &lt;!&ndash;            <v-img :src="images" contain max-height="200px">&ndash;&gt;-->
<!--            <v-container fluid>-->
<!--                <v-row>-->


<!--                </v-row>-->
<!--                <v-card>-->
<!--                    <li v-for="item in messages.message">-->
<!--                        <template v-if="item.userType=='citizen'">-->
<!--                            <v-textarea label="Message"-->
<!--                                        v-model="item.msg"-->
<!--                                        readonly="readonly"-->
<!--                                        auto-grow-->
<!--                                        rows="2"-->
<!--                                        class="mx-5"-->
<!--                                        filled-->
<!--                                        shaped-->
<!--                            >-->
<!--                            </v-textarea>-->
<!--                        </template>-->
<!--                        <template v-if="item.userType=='1'">-->
<!--                            <v-textarea label="Message from management"-->
<!--                                        v-model="item.msg"-->
<!--                                        readonly="readonly"-->
<!--                                        rows="2"-->
<!--                                        class="mx-5"-->
<!--                                        filled-->
<!--                                        shaped-->
<!--                            >-->
<!--                            </v-textarea>-->
<!--                        </template>-->
<!--                        <template v-if="item.userType=='2'">-->
<!--                            <v-textarea label="Operator message"-->
<!--                                        v-model="item.msg"-->
<!--                                        readonly="readonly"-->
<!--                                        rows="2"-->
<!--                                        class="mx-5"-->
<!--                                        filled-->
<!--                                        shaped-->
<!--                            >-->
<!--                            </v-textarea>-->
<!--                        </template>-->
<!--                        <template v-if="item.userType=='3'">-->
<!--                            <v-textarea label="Installation expert message"-->
<!--                                        v-model="item.msg"-->
<!--                                        readonly="readonly"-->
<!--                                        rows="2"-->
<!--                                        class="mx-5"-->
<!--                                        filled-->
<!--                                        shaped-->
<!--                            >-->
<!--                            </v-textarea>-->
<!--                        </template>-->
<!--                        <template v-if="item.userType=='4'">-->
<!--                            <v-textarea label="Damage expert message"-->
<!--                                        v-model="item.msg"-->
<!--                                        readonly="readonly"-->
<!--                                        rows="2"-->
<!--                                        class="mx-5"-->
<!--                                        filled-->
<!--                                        shaped-->
<!--                            >-->
<!--                            </v-textarea>-->
<!--                        </template>-->
<!--                        <template v-if="item.userType=='5'">-->
<!--                            <v-textarea label="Expert support message"-->
<!--                                        v-model="item.msg"-->
<!--                                        readonly="readonly"-->
<!--                                        rows="2"-->
<!--                                        class="mx-5"-->
<!--                                        filled-->
<!--                                        shaped-->
<!--                            >-->
<!--                            </v-textarea>-->
<!--                        </template>-->

<!--                    </li>-->

<!--                    <template-->
<!--                        v-if="($store.state.users.access_level == 1 || $store.state.users.access_level == 2)&&(messages.status == 'submitted')">-->
<!--                        <v-text-field-->
<!--                            v-model="textMessage"-->
<!--                            :rules="rules"-->
<!--                            maxlength="40"-->
<!--                            label="Please enter your message."-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                        <v-card-text>-->
<!--                            <v-autocomplete-->
<!--                                v-if="messages.type == 'AddNewBuilding' || messages.type == 'RequestService' || messages.type == 'ExtendPlan' || messages.type == 'ChangePlan'"-->
<!--                                :items="users_level3" item-text="name" itemid="id" return-object label="User"-->
<!--                                :error-messages="errors" placeholder="Select user"-->
<!--                                v-model="selected_user"></v-autocomplete>-->
<!--                            <v-autocomplete v-if="messages.type == 'Support'" :items="users_level5" item-text="name"-->
<!--                                            itemid="id" return-object label="User"-->
<!--                                            :error-messages="errors" placeholder="Select user"-->
<!--                                            v-model="selected_user"></v-autocomplete>-->
<!--                            <v-autocomplete v-if="messages.type == 'ReportDamage'" :items="users_level4"-->
<!--                                            item-text="name" itemid="id" return-object label="User"-->
<!--                                            :error-messages="errors" placeholder="Select user"-->
<!--                                            v-model="selected_user"></v-autocomplete>-->
<!--                        </v-card-text>-->
<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn text class="m-1">Cancel</v-btn>-->
<!--                            <v-btn depressed color="secondary" class="m-2" @click="addNewTask" :loading="busy">Assign-->
<!--                            </v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </template>-->

<!--                    <template-->
<!--                        v-if="($store.state.users.access_level == 3 || $store.state.users.access_level == 4|| $store.state.users.access_level == 5)&&(messages.status == 'inprogress')">-->
<!--                        <v-text-field-->
<!--                            v-model="textMessage"-->
<!--                            :rules="rules"-->
<!--                            maxlength="40"-->
<!--                            label="Please type your message below."-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn text class="m-1">Cancel</v-btn>-->
<!--                            <v-btn depressed color="secondary" class="m-2" @click="reportTask" :loading="busy">Report-->
<!--                            </v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </template>-->

<!--                    <template-->
<!--                        v-if="($store.state.users.access_level == 1 || $store.state.users.access_level == 2)&&(messages.status == 'professional_response')">-->
<!--                        <v-text-field-->
<!--                            v-model="textMessage"-->
<!--                            :rules="rules"-->
<!--                            maxlength="40"-->
<!--                            label="Please type your message below."-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn text class="m-1">Cancel</v-btn>-->
<!--                            <v-btn depressed color="secondary" class="m-2" @click="confirmTask" :loading="busy">Approve-->
<!--                                the process-->
<!--                            </v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </template>-->


<!--                    <template-->
<!--                        v-if="($store.state.users.access_level == 1 || $store.state.users.access_level == 2)&&(messages.status == 'confirmation')">-->
<!--                        <v-text-field-->
<!--                            v-model="textMessage"-->
<!--                            :rules="rules"-->
<!--                            maxlength="40"-->
<!--                            label="Please type your message below."-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn text class="m-1">Cancel</v-btn>-->
<!--                            <v-btn depressed color="secondary" class="m-2" @click="closeTask" :loading="busy">Close the-->
<!--                                process-->
<!--                            </v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </template>-->
<!--                </v-card>-->
<!--            </v-container>-->
<!--        </v-card>-->
<!--    </div>-->

<!--</template>-->

<!--<script>-->

<!--    import vueFilePond from 'vue-filepond';-->
<!--    import LocationForComparison from "../../views/PublicReport/LocationForComparison";-->

<!--    import FilePondPluginFileValidateType-->
<!--        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';-->
<!--    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';-->
<!--    import 'filepond/dist/filepond.min.css';-->
<!--    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';-->

<!--    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);-->

<!--    export default {-->
<!--        name: "CheckUserMessage",-->
<!--        components: {-->
<!--            // InputField,-->
<!--            FilePond,-->
<!--            LocationForComparison,-->
<!--        },-->
<!--        props: [-->
<!--            'messages',-->
<!--            'userss',-->
<!--        ],-->
<!--        data() {-->
<!--            return {-->
<!--                video: {},-->
<!--                canvas: {},-->
<!--                captures: [],-->
<!--                snackbar: true,-->
<!--                text: 'Hello, I\'m a snackbar',-->
<!--            }-->
<!--        },-->
<!--        data: function () {-->
<!--            return {-->
<!--                building_selected_id: '',-->
<!--                rules: [v => v.length <= 30 || 'حداکثر طول پیام 40 است'],-->
<!--                //myFiles: ['index.html'],-->
<!--                dogs: [],-->
<!--                images: '',-->
<!--                users: '',-->
<!--                users_level3: '',-->
<!--                users_level4: '',-->
<!--                users_level5: '',-->
<!--                selected_user: '',-->
<!--                form:-->
<!--                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'-->
<!--                        'name': '',-->
<!--                        'state': '',-->
<!--                        'id': '',-->
<!--                        'level': '',-->
<!--                        'catched_lon': '',-->
<!--                        'catched_lat': '',-->
<!--                        'textMessage': ''-->
<!--                    },-->
<!--                LatLong: {-->
<!--                    'Lat': '',-->
<!--                    'Long': ''-->
<!--                },-->
<!--                newStatus: {-->
<!--                    status: true-->
<!--                },-->
<!--                errors: null,-->
<!--                responses: null,-->
<!--                busy: false,-->
<!--                snackbar: false,-->
<!--                text: '',-->
<!--                gettingLocation: '',-->
<!--                errorStr: '',-->
<!--                locationLatLong: '',-->
<!--                // textAreaMessage:'\nب'-->
<!--                textAreaMessage: '',-->
<!--                textMessage: '',-->
<!--                images2: '',-->
<!--                images5: ''-->
<!--            }-->
<!--        },-->
<!--        //-->
<!--        created() {-->
<!--            this.fetchData();//users-->
<!--            this.getMessageInfo();-->
<!--            this.form.catched_lat = this.$store.state.LatLong.Lat;-->
<!--            this.form.catched_lon = this.$store.state.LatLong.Long;-->

<!--            this.locateAsync();-->
<!--            // if(navigator.geolocation){-->
<!--            //     navigator.geolocation.getCurrentPosition(position => {-->
<!--            //         this.form.catched_lon = position.coords.longitude;-->
<!--            //         this.form.catched_lat = position.coords.latitude;-->
<!--            //     })-->
<!--            // };-->
<!--        },-->

<!--        mounted: function () {-->


<!--        },-->

<!--        methods: {-->
<!--            async locateAsync() {-->
<!--                this.locationLatLong = await this.getLocation();-->
<!--                this.form.catched_lat = this.locationLatLong.coords.latitude;-->
<!--                this.form.catched_lon = this.locationLatLong.coords.longitude;-->
<!--            },-->

<!--            async getLocation() {-->
<!--                return new Promise((resolve, reject) => {-->
<!--                    if (!("geolocation" in navigator)) {-->
<!--                        reject(new Error('Geolocation is not available.'));-->
<!--                    }-->
<!--                    navigator.geolocation.getCurrentPosition(resolve, reject);-->

<!--                });-->
<!--            },-->

<!--            fetchData() {-->
<!--                this.busy = true;-->
<!--                axios.get('/users/level/')-->
<!--                    .then(response => {-->
<!--                        console.log(response.data);-->
<!--                        this.users = response.data;-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        // alert('unable to fetch');-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->

<!--                //level3-->
<!--                axios.get('/users/levels/3')-->
<!--                    .then(response => {-->
<!--                        console.log(response.data);-->
<!--                        this.users_level3 = response.data;-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        // alert('unable to fetch');-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--                //level4-->
<!--                axios.get('/users/levels/4')-->
<!--                    .then(response => {-->
<!--                        console.log(response.data);-->
<!--                        this.users_level4 = response.data;-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        // alert('unable to fetch');-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                })-->
<!--                //level5-->
<!--                axios.get('/users/levels/5')-->
<!--                    .then(response => {-->
<!--                        console.log(response.data);-->
<!--                        this.users_level5 = response.data;-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        // alert('unable to fetch');-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                })-->
<!--            },-->

<!--            getMessageInfo() {-->
<!--                this.busy = true;-->
<!--                axios.get('/report/index/' + this.$route.params.id)-->
<!--                    .then(response => {-->
<!--                        //console.log(response);-->
<!--                        this.dogs = response.data;-->
<!--                        if (this.dogs.id2) {-->
<!--                            axios.get('/user/' + this.dogs.id2)-->
<!--                                .then(response => {-->
<!--                                    this.images2 = "/storage/images/user/" + response.data.pic;-->
<!--                                })-->
<!--                        }-->
<!--                        if (this.dogs.id5) {-->
<!--                            axios.get('/user/' + this.dogs.id5)-->
<!--                                .then(response => {-->
<!--                                    this.images5 = "/storage/images/user/" + response.data.pic;-->
<!--                                })-->
<!--                        }-->
<!--                        // this.images= require('../../../../public/storage/images/publics/'+this.dogs.fig);-->
<!--                        this.images = '/storage/images/publics/' + this.dogs.fig;-->
<!--                        //this.images=require( '../../../../storage/app/public/images/publics/Public_100420200728185f7979927beb2.jpg');-->
<!--                    })-->
<!--            },-->


<!--            addNewTask() {-->

<!--                let currentTime = new Date();-->
<!--                let msg = {-->
<!--                    'type': this.messages.type,-->
<!--                    'status': 'inprogress',-->
<!--                    'issue_tracking': currentTime.getTime(),-->
<!--                    'message': {-->
<!--                        'userType': this.$store.state.users.access_level,-->
<!--                        'id': this.selected_user.id,-->
<!--                        'msg': this.textMessage,-->
<!--                        'pic': '',-->
<!--                    },-->
<!--                };-->
<!--                axios.put('../msg/' + this.messages.issue_tracking, msg)-->
<!--                    .then(response => {-->
<!--                        this.snackkbar = true;-->
<!--                        this.$router.go();-->
<!--                    })-->
<!--                    .catch(errors => {-->

<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->
<!--            reportTask() {-->

<!--                let currentTime = new Date();-->
<!--                let msg = {-->
<!--                    'type': this.messages.type,-->
<!--                    'status': 'professional_response',-->
<!--                    'issue_tracking': currentTime.getTime(),-->
<!--                    'message': {-->
<!--                        'userType': this.$store.state.users.access_level,-->
<!--                        'id': '',-->
<!--                        'msg': this.textMessage,-->
<!--                        'pic': '',-->
<!--                    },-->
<!--                };-->
<!--                axios.put('../msg/' + this.messages.issue_tracking, msg)-->
<!--                    .then(response => {-->
<!--                        this.snackkbar = true;-->
<!--                        this.$router.go();-->
<!--                    })-->
<!--                    .catch(errors => {-->

<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->
<!--            confirmTask() {-->

<!--                let currentTime = new Date();-->
<!--                let msg = {-->
<!--                    'type': this.messages.type,-->
<!--                    'status': 'confirmation',-->
<!--                    'issue_tracking': currentTime.getTime(),-->
<!--                    'message': {-->
<!--                        'userType': this.$store.state.users.access_level,-->
<!--                        'id': '',-->
<!--                        'msg': this.textMessage,-->
<!--                        'pic': '',-->
<!--                    },-->
<!--                };-->
<!--                axios.put('../msg/' + this.messages.issue_tracking, msg)-->
<!--                    .then(response => {-->
<!--                        this.snackkbar = true;-->
<!--                        this.$router.go();-->
<!--                    })-->
<!--                    .catch(errors => {-->

<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->
<!--            closeTask() {-->

<!--                let currentTime = new Date();-->
<!--                let msg = {-->
<!--                    'type': this.messages.type,-->
<!--                    'status': 'closed',-->
<!--                    'issue_tracking': currentTime.getTime(),-->
<!--                    'message': {-->
<!--                        'userType': this.$store.state.users.access_level,-->
<!--                        'id': '',-->
<!--                        'msg': this.textMessage,-->
<!--                        'pic': '',-->
<!--                    },-->
<!--                };-->
<!--                axios.put('../msg/' + this.messages.issue_tracking, msg)-->
<!--                    .then(response => {-->
<!--                        this.snackkbar = true;-->
<!--                        this.$router.go();-->
<!--                    })-->
<!--                    .catch(errors => {-->

<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->

<!--            assignTask() {-->
<!--                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;-->
<!--                this.busy = true;-->
<!--                this.form.name = this.selected_user.id;-->
<!--                this.form.state = "Inprogress";-->
<!--                this.form.id = this.$route.params.id;-->
<!--                this.form.level = this.selected_user.access_level;-->
<!--                //this.FindLevel1();-->
<!--                axios.post('../report/update', this.form)-->
<!--                    .then(response => {-->
<!--                        // this.responses = response.data[0].data.id;-->
<!--                        console.log(response);-->
<!--                        // this.$router.push({name:'public'});-->
<!--                        this.$store.commit("updateSnackbarInprogress", true);-->

<!--                        this.$router.push({name: 'public', params: {snackbarInprogress: true}});-->

<!--                        //alert('پیغام شما ثبت شد')-->
<!--                        //this.$router.push(toString(response.data[0].data.id));-->
<!--                        // flash('success message ...');-->
<!--                        // axios.get('../report');-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        console.log(this.form);-->
<!--                        alert(errors);-->
<!--                        // this.errors = errors.response.data.errors;-->
<!--                        // console.log(this.errors);-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->

<!--            Finded() {-->

<!--                this.form.catched_lon = this.$store.state.LatLong.Long;-->
<!--                this.form.catched_lat = this.$store.state.LatLong.Lat;-->

<!--                this.locateAsync();-->
<!--                // this.form.catched_lat = this.locationLatLong.coords.latitude;-->
<!--                // this.form.catched_lon = this.locationLatLong.coords.longitude;-->
<!--                navigator.geolocation.getCurrentPosition(position => {-->
<!--                    this.form.catched_lon = position.coords.longitude;-->
<!--                    this.form.catched_lat = position.coords.latitude;-->
<!--                    this.LatLong.Lat = position.coords.latitude;-->
<!--                    this.LatLong.Long = position.coords.longitude;-->
<!--                    this.$store.commit("updateLocation", this.LatLong);-->
<!--                });-->
<!--                if (this.form.catched_lon === '') {-->
<!--                    this.snackbar = true;-->
<!--                    this.text = "روشن";-->

<!--                    // alert('salam');-->
<!--                } else {-->
<!--                    this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;-->
<!--                    this.busy = true;-->
<!--                    this.form.state = "Underworking";-->
<!--                    this.form.id = this.$route.params.id;-->
<!--                    this.form.level = 4;-->
<!--                    this.$router.push({-->
<!--                        name: 'create',-->
<!--                        params: {-->
<!--                            fig_id: this.images,-->
<!--                            form_public: this.form,-->
<!--                            locationss: this.locationss,-->
<!--                            cell_number: this.dogs.cell_number-->
<!--                        }-->
<!--                    });-->
<!--                    this.busy = false;-->
<!--                }-->

<!--            },-->

<!--            NotFinded() {-->
<!--                this.form.catched_lon = this.$store.state.LatLong.Long;-->
<!--                this.form.catched_lat = this.$store.state.LatLong.Lat;-->
<!--                this.locateAsync();-->


<!--                if (navigator.geolocation) {-->
<!--                    navigator.geolocation.getCurrentPosition(position => {-->
<!--                        this.form.catched_lon = position.coords.longitude;-->
<!--                        this.form.catched_lat = position.coords.latitude;-->
<!--                        this.LatLong.Lat = position.coords.latitude;-->
<!--                        this.LatLong.Long = position.coords.longitude;-->
<!--                        this.$store.commit("updateLocation", this.LatLong);-->
<!--                    });-->
<!--                }-->
<!--                if (this.form.catched_lon === '') {-->
<!--                    this.snackbar = true;-->
<!--                    this.text = "روشن";-->

<!--                    // alert('salam');-->
<!--                } else {-->
<!--                    this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;-->
<!--                    this.busy = true;-->
<!--                    this.form.state = "Underworking";-->
<!--                    this.form.id = this.$route.params.id;-->
<!--                    this.form.level = 4;-->
<!--                    axios.post('../report/update', this.form)-->
<!--                        .then(response => {-->
<!--                            //this.responses = response.data[0].data.id;-->
<!--                            console.log(this.responses);-->
<!--                            this.$store.commit("updateSnackbarNotFinded", true);-->
<!--                            this.$router.push({name: 'public', params: {snackbarNotFinded: true}});-->

<!--                            // this.$router.push({name:'public'});-->
<!--                            //alert('پیغام شما ثبت شد')-->
<!--                            //this.$router.push(toString(response.data[0].data.id));-->
<!--                            // flash('success message ...');-->
<!--                            // axios.get('../report');-->
<!--                        })-->
<!--                        .catch(errors => {-->
<!--                            console.log(this.form);-->
<!--                            this.errors = errors.response.data.errors;-->
<!--                            console.log(this.errors);-->
<!--                        }).finally(() => {-->
<!--                        this.busy = false;-->
<!--                    });-->
<!--                }-->
<!--            },-->

<!--            ReportMistake() {-->
<!--                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;-->
<!--                this.busy = true;-->
<!--                this.form.state = "Finished";-->
<!--                this.form.id = this.$route.params.id;-->
<!--                this.form.level = 6;//Report A mistake-->
<!--                axios.post('../report/update', this.form)-->
<!--                    .then(response => {-->
<!--                        // this.responses = response.data[0].data.id;-->
<!--                        console.log(this.responses);-->
<!--                        // this.$router.push({name:'public'});-->
<!--                        this.$store.commit("updateSnackbarFinal", true);-->
<!--                        this.$router.push({name: 'public', params: {snackbarFinal: true}});-->

<!--                        //alert('پیغام شما ثبت شد')-->
<!--                        //this.$router.push(toString(response.data[0].data.id));-->
<!--                        // flash('success message ...');-->
<!--                        // axios.get('../report');-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        console.log(this.form);-->
<!--                        // this.errors = errors.response.data.errors;-->
<!--                        // console.log(this.errors);-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->

<!--            LocationVerified() {-->
<!--                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;-->
<!--                this.busy = true;-->
<!--                this.form.state = "Finished";-->
<!--                this.form.id = this.$route.params.id;-->
<!--                // this.form.level=4;-->
<!--                this.form.level = 7;//Verify-->
<!--                axios.post('../report/update', this.form)-->
<!--                    .then(response => {-->
<!--                        //this.responses = response.data[0].data.id;-->
<!--                        console.log(response);-->
<!--                        // this.$router.push({name:'public'});-->
<!--                        this.$store.commit("updateSnackbarFinal", true);-->

<!--                        this.$router.push({name: 'public', params: {snackbarFinal: true}});-->

<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        console.log(this.form);-->
<!--                        // this.errors = errors.response.data.errors;-->
<!--                        // console.log(this.errors);-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                });-->
<!--            },-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->
<!--    .spacing-playground .v-select .v-input__prepend-outer, .spacing-playground .v-select .v-input__append-outer {-->
<!--        margin-top: 0.55rem;-->
<!--        margin-right: 0.2rem;-->
<!--    }-->

<!--    #camera {-->
<!--        text-align: center;-->
<!--        color: #2c3e50;-->
<!--    }-->

<!--    #video {-->
<!--        background-color: #000000;-->
<!--    }-->

<!--    #canvas {-->
<!--        display: none;-->
<!--    }-->

<!--    li {-->
<!--        display: inline;-->
<!--        padding: 5px;-->
<!--    }-->

<!--    .rounded-form {-->
<!--        border-radius: 28px !important;-->

<!--    }-->
<!--</style>-->

