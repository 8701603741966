<template>
    <svg version="1.1" id="Artwork" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
<g>
	<path d="M456.7,299.1c0-29.3-23.8-53.2-53.2-53.2H268.3V135.1c17.2-5.3,29.7-21.3,29.7-40.1c0-23.1-18.8-42-42-42
		c-23.1,0-42,18.8-42,42c0,18.9,12.5,34.9,29.7,40.1v110.8H108.4c-29.3,0-53.2,23.8-53.2,53.2v67.3c0,29.3,23.8,53.2,53.2,53.2h82.7
		l-41.7,61.9c-3.8,5.6-2.3,13.2,3.3,17c2.1,1.4,4.5,2.1,6.8,2.1c3.9,0,7.8-1.9,10.2-5.4l51-75.6h70.6l51,75.6
		c2.4,3.5,6.2,5.4,10.2,5.4c2.4,0,4.7-0.7,6.8-2.1c5.6-3.8,7.1-11.4,3.3-17l-41.7-61.9h82.7c29.3,0,53.2-23.8,53.2-53.2V299.1z
		 M238.5,94.9c0-9.6,7.8-17.5,17.5-17.5c9.6,0,17.5,7.8,17.5,17.5s-7.8,17.5-17.5,17.5C246.4,112.4,238.5,104.6,238.5,94.9z
		 M432.2,366.4c0,15.8-12.9,28.7-28.7,28.7H297.8c0,0,0,0,0,0h-83.6c0,0,0,0,0,0H108.4c-15.8,0-28.7-12.9-28.7-28.7v-67.3
		c0-15.8,12.9-28.7,28.7-28.7h295.2c15.8,0,28.7,12.9,28.7,28.7V366.4z"/>
    <path d="M366.9,320.5H253.2c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h113.7c6.8,0,12.3-5.5,12.3-12.3S373.7,320.5,366.9,320.5
		z"/>
    <circle cx="160.4" cy="332.7" r="16.2"/>
    <path d="M390.1,176.1c2.2,1.5,4.6,2.3,7.1,2.3c3.8,0,7.6-1.8,10-5.2c16.2-22.9,24.8-50,24.8-78.3c0-28.3-8.6-55.4-24.8-78.3
		c-3.9-5.5-11.6-6.8-17.1-2.9c-5.5,3.9-6.8,11.6-2.9,17.1c13.3,18.7,20.3,40.9,20.3,64.1c0,23.2-7,45.4-20.3,64.1
		C383.3,164.5,384.6,172.2,390.1,176.1z"/>
    <path d="M333.1,132.8c2.2,1.5,4.6,2.3,7.1,2.3c3.8,0,7.6-1.8,10-5.2c7.3-10.2,11.1-22.3,11.1-35c0-12.7-3.8-24.8-11.1-35
		c-3.9-5.5-11.6-6.8-17.1-2.9c-5.5,3.9-6.8,11.6-2.9,17.1c4.3,6.1,6.6,13.3,6.6,20.8s-2.3,14.7-6.6,20.8
		C326.3,121.3,327.5,128.9,333.1,132.8z"/>
    <path d="M121.9,13.8c-5.5-3.9-13.2-2.6-17.1,2.9C88.6,39.6,80,66.6,80,94.9c0,28.3,8.6,55.4,24.8,78.3c2.4,3.4,6.2,5.2,10,5.2
		c2.5,0,4.9-0.7,7.1-2.3c5.5-3.9,6.8-11.6,2.9-17.1c-13.3-18.7-20.3-40.9-20.3-64.1c0-23.2,7-45.4,20.3-64.1
		C128.7,25.3,127.4,17.7,121.9,13.8z"/>
    <path d="M178.9,57.1c-5.5-3.9-13.2-2.6-17.1,2.9c-7.3,10.2-11.1,22.3-11.1,35c0,12.7,3.8,24.7,11.1,35c2.4,3.4,6.2,5.2,10,5.2
		c2.5,0,4.9-0.7,7.1-2.3c5.5-3.9,6.8-11.6,2.9-17.1c-4.3-6.1-6.6-13.3-6.6-20.8s2.3-14.7,6.6-20.8C185.7,68.6,184.5,61,178.9,57.1z"
    />
</g>
</svg>
</template>

<script>
    export default {
        name: "Appliance"
    }
</script>

<style scoped>

</style>
