<template>

    <div class="test">
        <v-snackbar v-model="snackkbarSuccess" color="#f2c4d3" :timeout="3000" centered>
            <span style="color: #333333;">
                Successfully registered.
            </span>
        </v-snackbar>
        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto"
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >

                            <v-card-actions class="justify-center mx-auto">
                                <v-layout column align-center>
                                    <v-row>
                                        <v-avatar size='150' class="mx-auto" v-if="$store.state.customers.pic ">
                                            <!--                                            <v-img-->
                                            <!--                                                :src="'/storage/images/buildings/Building_0618202206302262ad70fe3bb54.jpg'"-->
                                            <!--                                            >-->
                                            <v-img

                                                :src="'/storage/images/citizen/'+ $store.state.customers.pic"
                                            >
                                            </v-img>
                                        </v-avatar>
                                        <v-avatar size='150' class="mx-auto" v-if="$store.state.customers.pic ===''">
                                            <v-img

                                                :src="'/storage/images/user/avatar.jpg'"
                                            >
                                            </v-img>
                                        </v-avatar>
                                    </v-row>
                                    <v-row>

                                    </v-row>
                                </v-layout>
                            </v-card-actions>
                            <!--                            <v-card width="100%" class="mx-0 rounded-form my-6" height="40pt">-->
                            <!--                                <div id="filepond">-->
                            <!--                                    <file-pond-->
                            <!--                                        allowImageResize="true"-->
                            <!--                                        allowFileEncode="true"-->
                            <!--                                        imageResizeTargetWidth="400"-->
                            <!--                                        imageResizeTargetHeight="400"-->
                            <!--                                        allowImageExifOrientation="true"-->
                            <!--                                        imageResizeUpscale="false"-->
                            <!--                                        allowImageTransform="true"-->
                            <!--                                        imageTransformOutputMimeType="image/png"-->
                            <!--                                        name="test"-->
                            <!--                                        ref="pond"-->
                            <!--                                        class-name="my-pond"-->
                            <!--                                        label-idle="ویرایش"-->
                            <!--                                        allow-multiple="false"-->
                            <!--                                        accepted-file-types="image/jpeg, image/png, image/*, image/gif, image/jpg"-->
                            <!--                                        allowFileSizeValidation="true"-->
                            <!--                                        maxFileSize="10MB"-->
                            <!--                                        minFileSize="50KB"-->
                            <!--                                        imageResizeMode="cover"-->
                            <!--                                        :server="server_credential"-->
                            <!--                                        allowFileRename="true"-->
                            <!--                                        captureMethod=""-->
                            <!--                                        v-on:init="handleFilePondInit"-->
                            <!--                                        v-on:processfile="handleProcessFile"></file-pond>-->
                            <!--                                </div>-->

                            <!--                            </v-card>-->
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center" class="text-sm-center">
                                <v-text-field
                                    label="First name and surname"
                                    v-model="$store.state.customers.name + ' ' +$store.state.customers.family"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-1"
                                >

                                    <template v-slot:label>
                                        First name and surname
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            account_box
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center">
                                <v-text-field
                                    label="National ID"
                                    v-model="$store.state.customers.national_ID"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-1"
                                >

                                    <template v-slot:label>
                                        National ID
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            accessibility_new
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center">
                                <v-text-field
                                    label="Cell number"
                                    v-model="$store.state.customers.cell_number"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-2"
                                >

                                    <template v-slot:label>
                                        Cell number
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            phone
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>
                    </v-row>


                </v-flex>
            </v-layout>

            <!--            -->

            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center" class="text-sm-center">
                                <v-text-field
                                    label="Address"
                                    v-model="$store.state.customers.address"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-1"
                                >

                                    <template v-slot:label>
                                        Address
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            home
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center">
                                <v-text-field
                                    label="postal code"
                                    v-model="$store.state.customers.postal_code"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-1"
                                >
                                    <template v-slot:label>
                                        postal code
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            gps_fixed
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center">
                                <v-text-field
                                    label="sex"
                                    v-model="$store.state.customers.sex"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-2"
                                >

                                    <template v-slot:label>
                                        sex
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            face
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>
                    </v-row>
                </v-flex>

            </v-layout>
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-btn color="orange"
                               width="30%"
                               dark
                               elevation="20"
                               rounded
                               @click="dialog1='true' , uploadData()">edit
                        </v-btn>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog
            v-model="dialog1"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card class="justify-center">
                <v-card-title>
                    <span class="headline2">User info</span>
                </v-card-title>
                <v-card class="justify-center">
                    <div id="filepond">
                        <file-pond
                            allowImageResize="true"
                            allowFileEncode="true"
                            imageResizeTargetWidth="400"
                            imageResizeTargetHeight="400"
                            allowImageExifOrientation="true"
                            imageResizeUpscale="false"
                            allowImageTransform="true"
                            imageTransformOutputMimeType="image/png"
                            name="citizenPhoto"
                            ref="pond"
                            class-name="my-pond"
                            label-idle="... Please take a photo horizontally"
                            allow-multiple="false"
                            accepted-file-types="image/jpeg, image/png, image/*, image/gif, image/jpg"
                            allowFileSizeValidation="true"
                            maxFileSize="10MB"
                            minFileSize="50KB"
                            imageResizeMode="cover"
                            :server="server_credential"
                            allowFileRename="true"
                            captureMethod=""
                            v-on:init="handleFilePondInit"
                            v-on:processfile="handleProcessFile"></file-pond>
                    </div>
                </v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="form.name"
                                    label="Name*"
                                    hint="Name"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="form.family"
                                    label="surname*"
                                    hint="surname"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12"
                                   sm="6"
                                   md="4">
                                <v-text-field
                                    v-model="form.national_ID"
                                    label="National ID*"
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="form.sex"
                                    label="sex*"
                                    hint="sex"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="form.postal_code"
                                    label="postal code*"
                                    hint="postal code"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="form.address"
                                    label="address*"
                                    hint="address"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        @click="dialog1 = false"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="green"
                        @click="editUserData()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-container
                class="grey lighten-5 mb-6 mx-auto" align="center"
            >
                <v-layout align-center row wrap>
                    <v-flex>
                        <v-row
                            class="mx-auto "
                            no-gutters
                            align="center" justify="space-around"
                        >
                            <v-card

                                class="mx-2 my-4"
                                width="80%"
                                rounded="3"
                                elevation="3"
                            >


                                <v-card-title>change password</v-card-title>

                                <v-row>
                                    <v-col
                                        cols="11"
                                        sm="5"
                                    >
                                        <v-text-field
                                            v-model="password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type=" 'password'"
                                            name="input-10-1"
                                            label="new password"
                                            hint="at least have 8 characters"
                                            counter
                                            @click:append="show1 = !show1"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="11"
                                        sm="5"
                                    >
                                        <v-text-field
                                            v-model="rePassword"
                                            :rules="[rules.required, rules.min, passwordConfirmationRule]"
                                            :type="'password'"
                                            name="input-10-2"
                                            label="retype new password"
                                            hint="retype new password"
                                            class="input-group--focused"
                                            @click:append="show2 = !show2"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions>
                                    <v-row class="mx-4">
                                        <v-btn
                                            color="deep-green lighten-2"
                                            @click.stop="dialogVocation2=true"
                                        >
                                            Save changes
                                        </v-btn>
                                    </v-row>
                                    <v-dialog
                                        v-model="dialogVocation2"
                                        max-width="290"
                                    >
                                        <v-card>


                                            <v-card-text>
                                                Are you sure you want to change your password?
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>

                                                <v-btn
                                                    color="red darken-1"
                                                    text
                                                    @click.prevent="dialogVocation2=false"
                                                >
                                                    No
                                                </v-btn>

                                                <v-btn
                                                    color="green darken-1"
                                                    text
                                                    @click.prevent="EditPassword"
                                                >
                                                    Yes
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-card-actions>
                            </v-card>
                            <!--related to manager-->
                            <!--                            <v-card-->

                            <!--                                class="mx-2 my-4"-->
                            <!--                                max-width="100%"-->
                            <!--                                rounded="3"-->
                            <!--                                elevation="3"-->
                            <!--                                v-if="$store.state.users.access_level == 1"-->
                            <!--                            >-->


                            <!--                                <v-card-title class="text-sm-justify ">ثبت تعطیلی</v-card-title>-->

                            <!--                                <v-card-text>-->
                            <!--                                    <div>اگر در روزی به غیر از جمعه، برای کلیه تکنسین ها، تعطیلی در نظر گرفته می شود،-->
                            <!--                                        لطفا یک روز قبل آن ثبت تعطیلی ثبت شود. به عنوان مثال اگر، روز شنبه تعطیل می-->
                            <!--                                        باشد،، می بایست در جمعه، آنرا ثبت نمایید.-->
                            <!--                                    </div>-->
                            <!--                                </v-card-text>-->

                            <!--                                <v-divider class="mx-4"></v-divider>-->
                            <!--                                <v-card-actions>-->

                            <!--                                    <v-btn-->
                            <!--                                        color="red lighten-6"-->
                            <!--                                        class="text&#45;&#45;white"-->
                            <!--                                        @click.stop="dialogAllVocation=true"-->
                            <!--                                    >-->
                            <!--                                        ثبت-->
                            <!--                                    </v-btn>-->
                            <!--                                    <v-dialog-->
                            <!--                                        v-model="dialogAllVocation"-->
                            <!--                                        max-width="290"-->
                            <!--                                    >-->
                            <!--                                        <v-card>-->


                            <!--                                            <v-card-text>-->
                            <!--                                                آیا {{dateTommorow | moment(" dddd,jDo jMMMM jYYYY") }} تعطیل ثبت شود؟-->
                            <!--                                            </v-card-text>-->

                            <!--                                            <v-card-actions>-->
                            <!--                                                <v-spacer></v-spacer>-->

                            <!--                                                <v-btn-->
                            <!--                                                    color="red darken-1"-->
                            <!--                                                    text-->
                            <!--                                                    @click.prevent="EditAllVacation(0)"-->
                            <!--                                                >-->
                            <!--                                                    خیر-->
                            <!--                                                </v-btn>-->

                            <!--                                                <v-btn-->
                            <!--                                                    color="green darken-1"-->
                            <!--                                                    text-->
                            <!--                                                    @click.prevent="EditAllVacation(1)"-->
                            <!--                                                >-->
                            <!--                                                    بله-->
                            <!--                                                </v-btn>-->
                            <!--                                            </v-card-actions>-->
                            <!--                                        </v-card>-->
                            <!--                                    </v-dialog>-->
                            <!--                                </v-card-actions>-->
                            <!--                            </v-card>-->
                        </v-row>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-dialog>
        <v-card class="mx-auto rounded-form" max-width="90%" min-width="400px" outlined>
            <v-card-title class="layout justify-center text-h4">
                Setting of your profile!
            </v-card-title>
            <v-card-subtitle class="layout justify-center mb-10">
                You can see and modify the personal data, security and privacy policy, and appearance.
            </v-card-subtitle>
            <Account></Account>
            <v-divider></v-divider>
            <Security></Security>
            <!--            <v-divider></v-divider>-->
            <!--            <Appearance></Appearance>-->
        </v-card>


    </div>
</template>

<script>
    // import AllUserLocation from "../views/User/AllUserLocation";

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import LiveTracking from "../../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    import vueFilePond, {setOptions} from 'vue-filepond';
    import Security from "./Tabs/Security";
    import Account from "./Tabs/Account";


    import 'filepond/dist/filepond.min.css';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize
        from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';
    import FilePondPluginFileEncode from 'filepond-plugin-file-encode/dist/filepond-plugin-file-encode.esm';
    import FilePondPluginImageExifOrientation
        from 'filepond-plugin-image-exif-orientation/dist/filepond-plugin-image-exif-orientation.esm';


    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform, FilePondPluginFileEncode, FilePondPluginImageExifOrientation);

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            Security,
            Account,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'user',
        ],
        data: () => ({

            pic: '',
            picID2: '',
            snackkbarSuccess: false,
            dialog1: false,
            show1: true,
            show2: true,
            password: '',
            rePassword: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'حداقل شامل 8 حرف باشد',
            },
            snackbarSetHoliday: false,
            dialogVocation: false,
            dialogVocation2: false,
            dialogAllVocation: false,
            pic: '',
            form:
                {
                    'name': '',
                    'family': '',
                    'national_ID': '',
                    'address': '',
                    'postal_code': '',
                    'sex': '',
                },
            dateTommorow: '',

            //Instead of cone
            //
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1
            //pi chart
            seriesPi: [14, 23, 21, 17],
            chartOptionsPi: {
                labels: ['سگ نر، بالغ', 'سگ ماده بالغ', 'سگ نر، توله', 'سگ ماده توله'],
                chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },

            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },

                xaxis: {
                    categories: ['واکسن 1', 'واکسن 2', 'واکسن 3', 'عقیم '],
                    position: 'top'
                }
            },

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        created() {
            this.picID2 = this.$store.state.customers.pic;
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
        },
        async mounted() {
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
        },
        methods: {
            editUserData() {
                axios.put('../customer/' + this.$store.state.customers.id, this.form)
                    .then(response => {
                        console.log(respons.data[0].data.id);
                    })
                    .catch(errors => {
                        console.log(errors);
                    }).finally(() => {
                    this.dialog1 = false;
                    this.$router.go();
                });
            },
            uploadData: function () {
                this.form.name = this.$store.state.customers.name;
                this.form.family = this.$store.state.customers.family;
                this.form.cell_number = this.$store.state.customers.cell_number;
                this.form.sex = this.$store.state.customers.sex;
                this.form.national_ID = this.$store.state.customers.national_ID;
                this.form.address = this.$store.state.customers.address;
                this.form.postal_code = this.$store.state.customers.postal_code;
            },

            handleProcessFile: function (error, file) {
                // console.log(file);
                // this.pic = file.serverId;
                // this.pic = this.picID2;
                axios.put('../customer/' + this.$store.state.customers.id, {'pic': this.pic})
                    .then(response => {
                        console.log(response);
                        // alert("upload");
                    })
                    .catch(errors => {
                        console.log(errors);
                    }).finally(() => {
                    this.dialog1 = false;
                    // this.$router.go();
                });
                this.$store.commit("updateCaptureFig", file.serverId);
                // if (this.fig_id === undefined) {
                //     this.form.public_fig = '';
                //     this.form.public_cellnumber = '';
                // } else {
                //     this.form.public_fig = this.fig_id;
                //     this.form.public_cellnumber = this.cellnumber;
                // }
                // console.log(this.form.first_fig);
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                // let currentDate = Date.now();
                // setOptions({
                //     fileRenameFunction: (file) => {
                //         this.picID2 = 'customer_' + currentDate + Math.random().toString() + '.png';
                //         return this.picID2;
                //     }
                // })
                // // example of instance method call on pond reference
                // this.$refs.pond.getFiles();
            },
            increase() {
                this.height += 10
            },
            EditPassword: function () {
                this.dialogVocation = false;
                axios.put('../customer/' + this.$store.state.customers.id, {'password': this.password})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                // axios.put('../user/'+ this.$store.state.users.id , {'tomorrow_vacation': num})
                //     .then(response => {
                //         this.$router.push({name: 'dashboard'});
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         // this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },

            EditAllVacation: function (num) {
                this.dialogAllVocation = false;

                // axios.put('../users/all' , {'holiday': num})
                //     .then(response => {
                //         this.snackbarSetHoliday = true;
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         // this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },

            getAccessLevel() {
                // if (this.$store.state.users.access_level == "1") return 'مدیریت'
                // else if (this.$store.state.users.access_level == "2") return 'کارشناس'
                // else if (this.$store.state.users.access_level == "3") return 'دکتر آراد کوه'
                // else if (this.$store.state.users.access_level == "4") return 'کارشناس مسئول'
                // else if (this.$store.state.users.access_level == "5") return 'تکنسین'
                // else return 'کاربر تایید نشده'
            },
        },
        computed: {
            server_credential() {
                return {
                    url: "../customer/upload",
                    process: {
                        method: "POST",
                        withCredentials: true,
                        onerror: response => {
                            alert('error')
                        },
                        onload: response => {
                            this.snackkbarSuccess = true;
                            this.picAlert = false;
                            this.picUpload = true;

                            // response = JSON.parse(response);
                            this.pic = response;
                            // alert(response);
                        }
                    }
                }
            },

            passwordConfirmationRule() {
                return () => (this.password === this.rePassword) || 'پسورد انتخابی و تکرار ان یکسان نیستند'
            },
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .rounded-form {
        border-radius: 20px !important;
    }
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .headline2 {
        font-family: IRANSans !important;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
