<template>
    <div class="test">
        <template>
            <v-card class="mx-auto rounded-form" max-width="90%" min-width="400px" outlined>
                <v-row justify="center" class="mx-5">
                    <v-col
                        cols="12"
                    >
                        <v-sheet
                            elevation="2"
                            rounded="xl"
                        >
                            <v-sheet
                                class="pa-3 text-right black--text"
                                dark
                                color="#CFA1BBFF"
                                rounded="t-xl"
                            >
                                <v-card-title>
                                    Active services
                                </v-card-title>
                            </v-sheet>

                            <div class="pa-4">
                                <v-chip-group
                                    active-class="primary--text"
                                    column
                                >
                                    <v-chip
                                        v-for="tag in tags"
                                        :key="tag.id"
                                    >
                                        {{ tag }}
                                    </v-chip>
                                </v-chip-group>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>
                <br>
                <v-card-title primary-title class="justify-lg-start">All Services</v-card-title>
                <v-divider class="mx-4 my-3"></v-divider>
                <v-container
                    class="grey lighten-5 mb-6 mx-auto" align="center"
                >
                    <v-layout row wrap>
                        <v-flex>
                            <!--                            <v-card-->
                            <!--                                class="mx-auto"-->
                            <!--                                width="90%"-->
                            <!--                                rounded="3"-->
                            <!--                                elevation="10"-->
                            <!--                                outlined-->
                            <!--                                flat-->
                            <!--                                shaped-->

                            <!--                            >-->
                            <v-row
                                no-gutters

                            >
                                <v-col
                                    v-for="brand in brands" :key="brand.id"
                                    lg="4"
                                    md="6"
                                    cols="12"
                                >
                                    <v-card class="my-3 mx-auto"
                                            width="80%"
                                            rounded="10"
                                            color="#CFA1BBFF"
                                    >
                                        <v-row justify="space-around">
                                            <v-avatar
                                                class="profile"
                                                color="#E7D0D7FF  "
                                                size="100"
                                            >
                                                <v-img
                                                    contain
                                                    width="90%"
                                                    :src="require('../../../../assets/icons/softwareBrands/' + brand.name + '.svg')"

                                                >
                                                </v-img>
                                            </v-avatar>
                                        </v-row>
                                        <v-row class="my-2" justify="space-around"
                                               dense>
                                            <v-btn
                                                depressed
                                                color="#D15A80FF"
                                                class="white--text"
                                                @click="$router.push({name: 'insurerProfile', params:{building: item}})"
                                            >
                                                More info!
                                            </v-btn>
                                        </v-row>

                                        <v-divider class="mx-4 my-3"></v-divider>
                                        <v-row
                                            class="my-0"
                                            dense
                                        >
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="Company"
                                                    class="text-field mx-2 my-0"
                                                    :value=brand.name
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="Category"
                                                    class="text-field mx-2"
                                                    :value=brand.category
                                                    append-icon="gold"
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            class="my-0"
                                            dense
                                        >
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="Service"
                                                    class="text-field mx-2"
                                                    :value=brand.service
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="Revenue model"
                                                    class="text-field mx-2"
                                                    :value=brand.revenueModel
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                            >
                                                <v-card-actions class="justify-center">
                                                    <v-btn
                                                        rounded
                                                        color="error"
                                                        dark
                                                        class="mx-auto justify-content-center"
                                                    >
                                                        Deactivate
                                                    </v-btn>
                                                </v-card-actions>

                                            </v-col>
                                        </v-row>
                                        <v-spacer></v-spacer>
                                    </v-card>
                                </v-col>

                            </v-row>
                            <!--                            </v-card>-->
<!--                            <v-card-->
<!--                                v-if="building_set!=''"-->
<!--                                class="mx-2 my-4"-->
<!--                                width="90%"-->
<!--                                rounded="3"-->
<!--                                elevation="3"-->
<!--                            >-->

<!--                                <v-row-->
<!--                                    no-gutters-->
<!--                                    align="center" justify="space-around"-->

<!--                                >-->
<!--                                    <li v-for="item in building_set" :key="item">-->
<!--                                        <v-col-->
<!--                                            cols="12"-->
<!--                                        >-->
<!--                                            <v-card class="my-3" outlined width="90%">-->
<!--                                                <v-row justify="space-around">-->
<!--                                                    <v-avatar class="profile"-->
<!--                                                              size="164">-->
<!--                                                        <v-img-->
<!--                                                            :src="'/storage/images/buildings/'+ item.pic"-->
<!--                                                        >-->
<!--                                                        </v-img>-->
<!--                                                    </v-avatar>-->
<!--                                                </v-row>-->
<!--                                                <v-row class="my-2" justify="space-around">-->
<!--                                                    <v-btn-->
<!--                                                        depressed-->
<!--                                                        color="success"-->
<!--                                                        @click="$router.push({name: 'homeprofile', params:{building: item}})"-->
<!--                                                    >-->
<!--                                                        &lt;!&ndash;                                                        :to="{name: 'homeprofile'}"&ndash;&gt;-->

<!--                                                        Show more-->
<!--                                                    </v-btn>-->
<!--                                                </v-row>-->

<!--                                                <v-divider class="mx-4 my-3"></v-divider>-->
<!--                                                <v-row-->
<!--                                                    class="m"-->
<!--                                                >-->
<!--                                                    <v-col-->
<!--                                                        cols="6"-->
<!--                                                    >-->
<!--                                                        <v-text-field-->
<!--                                                            label="Structure"-->
<!--                                                            outlined-->
<!--                                                            class="text-field mx-2"-->
<!--                                                            v-model="item.infrastructure"-->
<!--                                                        ></v-text-field>-->
<!--                                                    </v-col>-->
<!--                                                    <v-col-->
<!--                                                        cols="6"-->
<!--                                                    >-->
<!--                                                        <v-text-field-->
<!--                                                            label="age"-->
<!--                                                            outlined-->
<!--                                                            class="text-field mx-2"-->
<!--                                                            v-model="item.age"-->
<!--                                                        ></v-text-field>-->
<!--                                                    </v-col>-->
<!--                                                </v-row>-->
<!--                                                <v-row-->
<!--                                                    class="my-2"-->
<!--                                                >-->
<!--                                                    <v-col-->
<!--                                                        cols="6"-->
<!--                                                    >-->
<!--                                                        <v-text-field-->
<!--                                                            label="No. of Units"-->
<!--                                                            outlined-->
<!--                                                            class="text-field mx-2"-->
<!--                                                            v-model="item.unit_num"-->
<!--                                                        ></v-text-field>-->
<!--                                                    </v-col>-->
<!--                                                    <v-col-->
<!--                                                        cols="6"-->
<!--                                                    >-->
<!--                                                        <v-text-field-->
<!--                                                            label="Risk signals"-->
<!--                                                            outlined-->
<!--                                                            class="text-field mx-2"-->
<!--                                                            v-model="item.risk_count"-->
<!--                                                        ></v-text-field>-->
<!--                                                    </v-col>-->
<!--                                                </v-row>-->
<!--                                                <v-row-->
<!--                                                    class="my-0"-->
<!--                                                >-->
<!--                                                    <v-col-->
<!--                                                        cols="12"-->
<!--                                                    >-->
<!--                                                        <v-text-field-->
<!--                                                            label="Address"-->
<!--                                                            outlined-->
<!--                                                            class="text-field2 text-field mx-2 "-->
<!--                                                            v-model="item.address"-->
<!--                                                        ></v-text-field>-->
<!--                                                    </v-col>-->
<!--                                                </v-row>-->
<!--                                                <v-spacer></v-spacer>-->

<!--                                            </v-card>-->

<!--                                        </v-col>-->
<!--                                    </li>-->
<!--                                </v-row>-->
<!--                            </v-card>-->


                        </v-flex>
                    </v-layout>
                </v-container>

            </v-card>
        </template>
    </div>
</template>

<script>
    // import AllUserLocation from "../views/User/AllUserLocation";

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    // import LiveTracking from "../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    // import DashboardTab1 from "./DashboardTab1";
    // import DashboardTab2 from "./DashboardTab2";
    // import DashboardTab3 from "./DashboardTab3";
    // import DashboardTab4 from "./DashboardTab4";


    export default {

        components: {
            // DashboardTab4,
            // DashboardTab3,
            // DashboardTab2,
            // DashboardTab1,
            VueFunnelGraph,
            // LiveTracking,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'user',
        ],
        data: () => ({
            brands: [
                {
                    name: 'Stripe',
                    category: 'Finance',
                    service: 'Payment',
                    revenueModel: 'Commission',
                },{
                    name: 'Google Analytics',
                    category: 'Marketing',
                    service: 'SEO',
                    revenueModel: 'Free',
                },{
                    name: 'Hotjar',
                    category: 'Customer Analysis',
                    service: 'Behavior Analysis',
                    revenueModel: 'SaaS',
                },{
                    name: 'Intercom',
                    category: 'Customer Tool',
                    service: 'Chat',
                    revenueModel: 'SaaS',
                },{
                    name: 'Mailchimp',
                    category: 'Customer Tool',
                    service: 'E-mail',
                    revenueModel: 'SaaS',
                }],
            tags: [
                'Google Analytics',
                'Stripe',
            ],
            building_set: '',
            unit_set: '',
            test: 1,
            tab: null,
            items: [
                'Appetizers', 'Entrees', 'Deserts', 'Cocktails',
            ],
            search: null,
            rare: null,
            review: null,
            // tab: null,
            tab1: null,
            // tab: null,
            // tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

            lateLoginErrorNum: '',
            locationalert: false,
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            dateTommorow: '',
            dialogVocation: false,
            //Instead of cone

            seriesCone: [{
                name: 'پیام های صحیح',
                type: 'column',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
            }, {
                name: 'پیام های ارسالی',
                type: 'area',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
            }, {
                name: 'رضایت افراد',
                type: 'line',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
            }],
            chartOptionsCone: {
                chart: {
                    height: 350,
                    width: 250,
                    type: 'line',
                    stacked: false,
                    animations: {
                        enabled: false
                    },
                },
                stroke: {
                    width: [0, 2, 5],
                    curve: 'smooth'
                },

                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },

                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر',
                    'ابان', 'آذر', 'دی', 'بهمن'
                ],
                markers: {
                    size: 0
                },
                xaxis: {},
                yaxis: {
                    title: {
                        text: 'میزان',
                    },
                    min: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + "";
                            }
                            return y;

                        }
                    }
                }
            },


            //Table
            headers1: [
                {text: 'ای دی افراد', value: 'id'},

                {
                    text: 'نام',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'مقدا خطای مکانیر', value: 'location', sortable: false,},
            ],
            headers2: [
                {text: 'ای دی افراد', value: 'id'},

                {
                    text: 'نام',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'مقدار خطای مکانی', value: 'location', sortable: false,},
            ],
            headers3: [
                {text: 'ای دی افراد', value: 'id'},

                {
                    text: 'نام',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'تعداد سگ های گرفته شده ', value: 'location', sortable: false,},
            ],

            desserts1: [
                {
                    id: '1',
                    name: 'کیوان شفیعی',
                    email: '09127752017',
                    location: 20,
                },
                {
                    id: '2',
                    name: 'علیرضا شیردل',
                    email: '09124020821',
                    location: 21,
                },
            ],
            desserts2: [
                {
                    id: '1',
                    name: 'کیوان شفیعی',
                    email: '09127752017',
                    location: 14,
                },
                {
                    id: '2',
                    name: 'احسان شهسواری',
                    email: '09124020821',
                    location: 19,
                },
            ],
            desserts3: [
                {
                    id: '1',
                    name: 'مهران سبحانی',
                    email: '09127752017',
                    location: 15,
                },
                {
                    id: '2',
                    name: 'سعید حسین خانی',
                    email: '09124020821',
                    location: 8,
                },
            ],

            //
            alignments: [
                'start',
                'center',
                'end',

            ],
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1
            seriesChart1: [{
                name: 'تعداد خطاها',
                data: [{
                    x: '1 آبان',
                    y: null
                },
                    {
                        x: '2 آبان',
                        y: 44
                    },
                    {
                        x: '3 آبان',
                        y: 31
                    },
                    {
                        x: '4 آبان',
                        y: 38
                    },
                    {
                        x: '5 آبان',
                        y: null
                    },
                    {
                        x: '6 آبان',
                        y: 32
                    },
                    {
                        x: '7 آبان',
                        y: 55
                    },
                    {
                        x: '8 آبان',
                        y: 51
                    },
                    {
                        x: '9 آبان',
                        y: 67
                    },
                    {
                        x: '10 آبان',
                        y: 22
                    },
                    {
                        x: '11 آبان',
                        y: 34
                    },
                    {
                        x: '12 آبان',
                        y: null
                    },
                    {
                        x: '13 آبان',
                        y: null
                    },
                    {
                        x: '14 آبان',
                        y: 11
                    },
                    // {
                    //     x: '15 آبان',
                    //     y: 4
                    // },
                    // {
                    //     x: '16 آبان',
                    //     y: 15,
                    // },
                    // {
                    //     x: '17 آبان',
                    //     y: null
                    // },
                    // {
                    //     x: '18 آبان',
                    //     y: 9
                    // },
                    // {
                    //     x: '19 آبان',
                    //     y: 34
                    // },
                    // {
                    //     x: '20 آبان',
                    //     y: null
                    // },
                    // {
                    //     x: '21 آبان',
                    //     y: null
                    // },
                    // {
                    //     x: '22 آبان',
                    //     y: 13
                    // },
                    // {
                    //     x: '23 آبان',
                    //     y: null
                    // }
                ],
            }],

            seriesChart2: [{
                name: 'تعداد خطاها',
                data: [{
                    x: '1 آبان',
                    y: null
                },
                    {
                        x: '2 آبان',
                        y: 31
                    },
                    {
                        x: '3 آبان',
                        y: 12
                    },
                    {
                        x: '4 آبان',
                        y: 4
                    },
                    {
                        x: '5 آبان',
                        y: null
                    },
                    {
                        x: '6 آبان',
                        y: 9
                    },
                    {
                        x: '7 آبان',
                        y: 63
                    },
                    {
                        x: '8 آبان',
                        y: 13
                    },
                    {
                        x: '9 آبان',
                        y: 18
                    },
                    {
                        x: '10 آبان',
                        y: 19
                    },
                    {
                        x: '11 آبان',
                        y: 51
                    },
                    {
                        x: '12 آبان',
                        y: null
                    },
                    {
                        x: '13 آبان',
                        y: null
                    },
                    {
                        x: '14 آبان',
                        y: 23
                    },
                ],
            }],


            seriesChart3: [{
                name: 'تعداد خطاها',
                data: [{
                    x: '1 آبان',
                    y: null
                },
                    {
                        x: '2 آبان',
                        y: 18
                    },
                    {
                        x: '3 آبان',
                        y: 10
                    },
                    {
                        x: '4 آبان',
                        y: 9
                    },
                    {
                        x: '5 آبان',
                        y: null
                    },
                    {
                        x: '6 آبان',
                        y: 18
                    },
                    {
                        x: '7 آبان',
                        y: 51
                    },
                    {
                        x: '8 آبان',
                        y: 9
                    },
                    {
                        x: '9 آبان',
                        y: 17
                    },
                    {
                        x: '10 آبان',
                        y: 23
                    },
                    {
                        x: '11 آبان',
                        y: 3
                    },
                    {
                        x: '12 آبان',
                        y: null
                    },
                    {
                        x: '13 آبان',
                        y: null
                    },
                    {
                        x: '14 آبان',
                        y: 49
                    },
                ],
            }],

            chartOptionsChart1: {
                chart: {
                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 5,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },

            chartOptionsChart2: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#008FFB'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            chartOptionsChart3: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            //pi chart
            seriesPi: [14, 23, 21, 17],
            chartOptionsPi: {
                labels: ['سگ نر، بالغ', 'سگ ماده بالغ', 'سگ نر، توله', 'سگ ماده توله'],
                chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },

            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },

                xaxis: {
                    categories: ['واکسن 1', 'واکسن 2', 'واکسن 3', 'عقیم '],
                    position: 'top'
                }
            },

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        created() {
            axios.get('../citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.building_set = response.data;

                })
                .catch(errors => {
                    console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });

            axios.get('../citizenUnits/' + this.$store.state.customers.id)
                .then(response => {
                    this.unit_set = response.data;
                })
                .catch(errors => {
                    console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
            // console.log(this.$refs.funnel);
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            // this.form.name = this.$store.state.users.name;
            // this.form.access_level = this.$store.state.users.access_level;
            // this.form.national_ID = this.$store.state.users.national_ID;
            // this.form.shenasname = this.$store.state.users.shenasname;
            // this.form.car_number = this.$store.state.users.car_number;
            // this.form.pic = this.$store.state.users.pic;
        },

        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;


            this.loaded = false;
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
            // axios.get('../user/'+ this.$store.state.users.id)
            //     .then(response => {
            //         this.lateLoginErrorNum = response.data.last_login_error;
            //         if (response.data.gps_off_error==1)
            //         {
            //             this.locationalert = true;
            //         }
            //         else
            //         {
            //             this.locationalert = false;
            //
            //         }
            //     })
            //     .catch(errors => {
            //         console.log(this.form);
            //         // this.errors = errors.response.data.errors;
            //         console.log(this.errors);
            //     }).finally(() => {
            //     this.busy = false;
            // });
        },
        methods: {
            SendCurrentLocation() {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        let newLocation = {
                            Lat: position.coords.latitude,
                            Long: position.coords.longitude
                        };

                        this.$store.commit("updateLocation", newLocation);
                        //post to server
                        // axios.post('../saveUserLocation', {
                        //     ID: this.$store.state.users.id,
                        //     Long: position.coords.longitude,
                        //     Lat: position.coords.latitude,
                        // })
                        //     .then(response => {
                        //         console.log('Send');
                        //         this.locationalert=false;
                        //         this.$router.go();
                        //     })
                        //     .catch(errors => {
                        //         console.log('error');
                        //     });
                    })
                } else {
                    this.locationalert = true;
                }
            },
            increase() {
                this.height += 10
            },

            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                // axios.put('../user/'+ this.$store.state.users.id , this.form)
                //     .then(response => {
                //         this.$router.push({name: 'dashboard'});
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         // this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },

            getAccessLevel() {
                // if (this.$store.state.users.access_level == "1") return 'مدیریت'
                // else if (this.$store.state.users.access_level == "2") return 'کارشناس'
                // else if (this.$store.state.users.access_level == "3") return 'دکتر آراد کوه'
                // else if (this.$store.state.users.access_level == "4") return 'کارشناس مسئول'
                // else if (this.$store.state.users.access_level == "5") return 'تکنسین'
                // else return 'کاربر تایید نشده'
            },
        },
        computed: {
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: capitalize
    }

    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;
    }

    >>> .text-field {
        border-radius: 20px !important;
        font-size: 10px;
    }

    >>> .text-field2 {
        font-size: 12px;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
