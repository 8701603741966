<template>
    <div class="test">
        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-card

                            class="mx-2 my-4"
                            width="90%"
                            rounded="3"
                            elevation="3"
                        >


                            <v-card-title>
                                User performance reports
                            </v-card-title>
                            <v-card max-width="300pt" class="mx-auto my-6"
                                    v-if="$store.state.users.access_level == 1 || $store.state.users.access_level == 7 || $store.state.users.access_level == 8 || $store.state.users.access_level == 2">
                                <v-row>
                                    <v-card-text>
                                        <v-select :items="users" item-text="name" itemid="id" return-object
                                                  label="Users"
                                                  :error-messages="errors" placeholder="Select user"
                                                  v-model="selected_user"></v-select>
                                    </v-card-text>
                                </v-row>

                                <v-radio-group
                                    v-model="row"
                                    row
                                >
                                    <v-radio
                                        label="daily"
                                        value="daily"
                                    ></v-radio>
                                    <v-radio
                                        label="interval"
                                        value="interval"
                                    ></v-radio>
                                </v-radio-group>
                            </v-card>

                            <template v-if="row=='daily'">
                                <v-card-text>
                                    <div>View the performance report by selecting the date through the calendar below.
                                    </div>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="80%"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date"
                                                label="Pick date"
                                                prepend-icon="event_note"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            no-title
                                            scrollable
                                            width="200"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu.save(date)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>

                                    <!--                                    <date-picker-->
                                    <!--                                        v-model="date"-->
                                    <!--                                        format="YYYY-MM-DD"-->
                                    <!--                                        display-format="dddd jDD jMMMM jYYYY"></date-picker>-->
                                </v-card-text>

                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions>

                                    <v-btn
                                        class="white--text capitalize-text"
                                        color="#7B5D6A"
                                        @click="ShowFigOfDog()"
                                    >
                                        View
                                    </v-btn>
                                </v-card-actions>
                            </template>

                            <template v-if="row=='interval'">
                                <v-card height="90%">
                                    <v-card-actions>
                                        <v-container>
                                            <v-row align="center" justify="center">
                                                <v-col cols="4" align-self="center">
                                                    <v-card-text>
                                                        Starting day
                                                    </v-card-text>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-card>
                                                        <v-menu
                                                            ref="menu2"
                                                            v-model="menu2"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="dateStartCaptured"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="80%"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="dateStartCaptured"
                                                                    label="Pick date"
                                                                    prepend-icon="event_note"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="dateStartCaptured"
                                                                no-title
                                                                scrollable
                                                                width="200"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    text
                                                                    color="primary"
                                                                    @click="menu2 = false"
                                                                >
                                                                    Cancel
                                                                </v-btn>
                                                                <v-btn
                                                                    text
                                                                    color="primary"
                                                                    @click="$refs.menu2.save(dateStartCaptured)"
                                                                >
                                                                    OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-menu>
                                                        <!--                                                        <date-picker-->
                                                        <!--                                                            v-model="dateStartCaptured"-->
                                                        <!--                                                            format="YYYY-MM-DD"-->
                                                        <!--                                                            display-format="dddd jDD jMMMM jYYYY"></date-picker>-->
                                                    </v-card>
                                                </v-col>

                                            </v-row>

                                            <v-row align="center" justify="center" align-content="center">
                                                <v-col cols="4" align-self="center" class="mx-auto">
                                                    <v-card-text>
                                                        End day
                                                    </v-card-text>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-card>
                                                        <v-menu
                                                            ref="menu3"
                                                            v-model="menu3"
                                                            :close-on-content-click="false"
                                                            :return-value.sync="dateEndCaptured"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="80%"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="dateEndCaptured"
                                                                    label="Pick date"
                                                                    prepend-icon="event_note"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="dateEndCaptured"
                                                                no-title
                                                                scrollable
                                                                width="200"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    text
                                                                    color="primary"
                                                                    @click="menu3 = false"
                                                                >
                                                                    Cancel
                                                                </v-btn>
                                                                <v-btn
                                                                    text
                                                                    color="primary"
                                                                    @click="$refs.menu3.save(dateEndCaptured)"
                                                                >
                                                                    OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-menu>
                                                        <!--                                                        <date-picker-->
                                                        <!--                                                            v-model="dateEndCaptured"-->
                                                        <!--                                                            format="YYYY-MM-DD"-->
                                                        <!--                                                            display-format="dddd jDD jMMMM jYYYY"></date-picker>-->
                                                    </v-card>
                                                </v-col>

                                            </v-row>
                                            <v-row>
                                                <v-card class="mx-auto">
                                                    <v-alert v-model="alertStartEndCaptured"
                                                             color="orange" close-text="Close Alert"
                                                             class="white--text"
                                                             dismissible>
                                                        The start day must be before the end day!
                                                    </v-alert>
                                                    <v-btn
                                                        color="#7B5D6A"
                                                        elevation="20"
                                                        shaped
                                                        dark
                                                        class="white--text capitalize-text"
                                                        @click="showCaptureByDate">
                                                        Display
                                                    </v-btn>
                                                </v-card>
                                            </v-row>

                                        </v-container>
                                    </v-card-actions>
                                    <template>
                                        <apexchart ref="realtimeChart1" type="area" height="250"
                                                   :options="chartOptionsChart3" :series="seriesChart3"></apexchart>
                                        <apexchart ref="realtimeChart2" type="area" height="250"
                                                   :options="chartOptionsChart4" :series="seriesChart3"></apexchart>
                                    </template>
                                </v-card>
                            </template>

                        </v-card>
                    </v-row>
                </v-flex>
            </v-layout>
            <template v-if="showImage && (row=='daily')">

                <v-row
                    v-if="count && ($store.state.users.access_level == 1 || $store.state.users.access_level == 7 || $store.state.users.access_level == 8)"
                    class="mx-auto">

                    <v-col
                        cols="12"
                        sm="6"
                        class="mx-auto"
                    >
                        <v-chip
                            class="ma-2"
                            color="green"
                            text-color="white"
                        >
                            Number of homes registered today: {{count}}
                        </v-chip>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6">
                        <v-chip
                            class="ma-2"
                            color="red"
                            text-color="white"
                        >
                            Number of times offline in the range of 6 to 11: {{last_login_error}}
                        </v-chip>
                    </v-col>


                    <v-col
                        cols="12"
                        sm="6">
                        <v-chip
                            class="ma-2"
                            color="orange"
                            text-color="white"
                        >
                            First time to take a photo:{{times[0]}}
                        </v-chip>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6">
                        <v-chip
                            class="ma-2"
                            color="blue"
                            text-color="white"
                        >
                            Last time online {{time}} minutes ago
                        </v-chip>
                    </v-col>


                </v-row>
                <v-row>
                    <v-alert
                        width="100%"
                        border="top"
                        color="red lighten-2"
                        dark
                        v-if="count == 0"
                    >
                        Unfortunately, no house was registered on the selected day.
                    </v-alert>
                    <v-col cols="12"
                           class="justify-center"
                           v-if="count && ($store.state.users.access_level == 1 || $store.state.users.access_level == 7 || $store.state.users.access_level == 8)"
                    >
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto rounded-form" outlined width="97%" height="500px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="green"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm">
                                                Route map of user and location of registered houses
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <TechnicianLocationRep :doglocations="locations"
                                                               :userlocations="markerCoordinates2"/>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>
                    </v-col>
                    <v-col
                        v-for="n in count"
                        :key="n"
                        class="d-flex child-flex"
                        cols="6"
                        v-if="count"
                    >

                        <v-img
                            :src="imagess[n-1]"
                            :lazy-src="imagess[n-1]"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            @click="$router.push({name: 'capturedlocation', params:{latlong:locations[n-1], time:times[n-1]}})"
                        >
                            <!--                            this.$router.push({name: 'public', params: {snackbarInprogress: true}});-->
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';

    import VueApexCharts from 'vue-apexcharts'
    import LiveTracking from "../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    import moment from 'jalali-moment/jalali-moment';
    import TechnicianLocationRep from "../views/Dog/TechnicianLocationRep";

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            LiveTracking,
            TechnicianLocationRep,
            VueMoment,
            apexchart: VueApexCharts,
            moment
        },
        props: [
            'user',
        ],
        data: () => ({
            seriesChart3: [{
                name: 'تعداد سگ ها',
                data: [],
            }],
            seriesChart4: [{
                name: 'تعداد سگ ها',
                data: [],
            }],
            dateStartCaptured: '',
            chartOptionsChart3: {
                chart: {

                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        export: {
                            csv: {
                                // encoding:'utf-8',
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'date',
                                headerValue: 'value',
                                charset: 'utf-8',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                    type: 'area',
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                title: {
                    text: 'Buildings count',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'green'
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: false,
                    shared: false
                },
                theme: {
                    palette: 'palette1'
                },
                xaxis: {
                    type: 'category',
                }
            },

            chartOptionsChart4: {
                chart: {

                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        export: {
                            csv: {
                                // encoding:'utf-8',
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'date',
                                headerValue: 'value',
                                charset: 'utf-8',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                    type: 'area',
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                title: {
                    text: 'Number of user mistakes',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: 'red'
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: false,
                    shared: false
                },
                theme: {
                    palette: 'palette1'
                },
                xaxis: {
                    type: 'category',
                }
            },
            menu:false,
            menu2:false,
            menu3:false,
            dateEndCaptured: '',
            alertStartEndCaptured: false,
            showChart3: false,
            row: 'daily',
            last_login_error: '',
            markerCoordinates2: [],
            moments: '',
            users: [],
            selected_user: '',
            errors: '',
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            count: '',
            time: '',
            imagess: [],
            locations: [],
            times: [],
            showImage: false,
            dateTommorow: '',
            date: '',
            datetoGregorian: '',
            dialogVocation: false,


            //Table

            //
            alignments: [
                'start',
                'center',
                'end',

            ],
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1
            //
            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },

                xaxis: {
                    categories: ['واکسن 1', 'واکسن 2', 'واکسن 3', 'عقیم '],
                    position: 'top'
                }
            },

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        // data() {
        //     return {
        //         labels: ['Impressions', 'Add To Cart', 'Buy'],
        //         subLabels: ['Direct', 'Social Media', 'Ads'],
        //         values: [
        //             // with the given Labels and SubLabels here's what the values represent:
        //             //
        //             // Direct, Social, Ads
        //             //    |      |     |
        //             //    v      v     v
        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
        //             [3000, 1700, 1000], // Segments of "Add To Cart"
        //             [600,  200,  130]   // Segments of "Buy"
        //         ],
        //         colors: [
        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        //         ],
        //         direction: 'horizontal',
        //         gradientDirection: 'horizontal',
        //         height: 300,
        //         width: 800
        //     };
        // },
        created() {
            // console.log(this.$refs.funnel);
            this.moments = moment('2021-01-11 17:01:26', 'YYYY-M-D HH:mm:ss').add(3, 'hours', 30, 'minutes').locale('fa').format('YYYY/M/D HH:mm:ss');
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);

            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;

        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.fetchData();
            this.loaded = false;
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
        },
        methods: {
            updateSeriesLine() {
                this.$refs.realtimeChart1.updateSeries([{
                    data: this.seriesChart3[0].data,
                }], false, true);
                this.$refs.realtimeChart2.updateSeries([{
                    data: this.seriesChart3[0].data,
                }], false, true);
            },
            showCaptureByDate() {
                if ((this.dateStartCaptured) && (this.dateEndCaptured)) {
                    if (moment(this.dateStartCaptured).isAfter(this.dateEndCaptured)) {
                        this.alertStartEndCaptured = true;
                        this.showChart3 = false;
                    } else {
                        axios.post('../homedatas/num/user', {
                            'dateStart': this.dateStartCaptured,
                            'dateEnd': this.dateEndCaptured,
                            'user': this.selected_user
                        })
                            .then(response => {
                                // this.dogs = response.data[0].data;
                                this.seriesChart3[0].data = [];
                                // this.test = {x: this.seriesChart3[0].data[0].x, y:this.seriesChart3[0].data[0].y};
                                // this.
                                let startDate = moment(this.dateStartCaptured).locale('fa').format('dddd,jDo jMMMM');
                                let dateAxis = this.dateStartCaptured;
                                for (let index = 0; index < response.data.length; ++index) {
                                    this.seriesChart3[0].data[index] = {x: startDate, y: response.data[index]};
                                    dateAxis = moment(dateAxis).add(1, 'days');
                                    startDate = moment(dateAxis).locale('fa').format('jYYYY/jM/jD');
                                }
                                // let startDate = moment(this.dateStartCaptured).locale('fa').format('dddd,jDo jMMMM  jYYYY');
                                let endDate = moment(this.dateEndCaptured).locale('fa').format('jYYYY/jM/jD');
                                this.seriesChart3[0].data[0].x = startDate;
                                this.seriesChart3[0].data[response.data.length - 1].x = endDate;
                                this.chartOptionsChart3.chart.toolbar.export.csv = this.selected_user;
                                this.chartOptionsChart3.chart.toolbar.export.png = this.selected_user;
                                this.chartOptionsChart3.chart.toolbar.export.svg = this.selected_user;
                                this.seriesChart4 = this.seriesChart3;
                                this.updateSeriesLine();
                                this.showChart3 = true;

                            });
                    }
                }
            },
            fetchData() {
                this.busy = true;
                axios.get('../users/technicion')
                    .then(response => {
                        console.log(response.data);
                        this.users = response.data;
                    })
                    .catch(errors => {
                        alert('unable to fetch1');
                    }).finally(() => {
                    this.busy = false;
                })
            },
            ShowFigOfDog() {
                axios.post('../homedatas/date', {'date': this.date, 'user': this.selected_user})
                    .then(response => {
                        // this.dogs = response.data[0].data;
                        console.log(response);
                        let index;
                        this.count = response.data.length;
                        this.locations = [];
                        this.markerCoordinates2 = [];
                        this.imagess = [];
                        for (index = 0; index < response.data.length; ++index) {
                            this.imagess[index] = '/storage/images/dogs/' + response.data[index].first_fig;
                            this.locations[index] = [response.data[index].catched_lon, response.data[index].catched_lat];
                            this.times[index] = moment(response.data[index].created_at).locale('fa').format('YYYY/M/D HH:mm:ss');
                        }
                        this.showImage = true;
                        // this.count = response.length;
                        // '/storage/images/dogs/' +
                        // this.imagess = response;
                        let selected_id;
                        if (this.selected_user == "") {
                            selected_id = this.$store.state.users.id;
                            this.last_login_error = this.selected_user.last_login_error;
                        } else {
                            selected_id = this.selected_user.id;
                            this.last_login_error = 0;
                        }
                        axios.get('../usertrack/' + selected_id)
                            .then(response => {
                                let userslocation = response.data;
                                let i;
                                this.time = userslocation[0]["Time"];
                                for (i = 0; i < userslocation.length; i++) {
                                    this.markerCoordinates2[i] = userslocation[i]['LatLong'];
                                }
                            })
                            .catch(errors => {
                                // alert('unable to fetch track');
                            }).finally(() => {
                            this.busy = false;
                        })

                        // this.last_login_error = this.users[selected_id].last_login_error;

                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;

                })
            },
            increase() {
                this.height += 10
            },


            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'مدیریت'
                else if (this.$store.state.users.access_level == "2") return 'کارشناس'
                else if (this.$store.state.users.access_level == "3") return 'دکتر آراد کوه'
                else if (this.$store.state.users.access_level == "4") return 'کارشناس مسئول'
                else if (this.$store.state.users.access_level == "5") return 'تکنسین'
                else return 'کاربر تایید نشده'
            },
        },
        computed: {
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .capitalize-text {
        text-transform: capitalize !important;
    }
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
