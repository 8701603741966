<!--<template>-->
<!--    <div class="pt-16">-->

<!--        <v-card max-width="480px" class="mx-auto rounded-form" outlined>-->

<!--            <v-card-title>-->
<!--                Add an authorized hardware-->
<!--            </v-card-title>-->
<!--            <v-img :src="require('./../../assets/iamges/Hardware.jpg')" contain max-width="100%">-->
<!--            </v-img>-->
<!--            &lt;!&ndash;            'type', 'implementation_date','unique_id', 'cell_number', 'building_id', 'pic', 'wifi_ssid', 'wifi_password','remained_charge', 'restful_address', 'signal'&ndash;&gt;-->

<!--            <v-card-text>-->
<!--                <v-autocomplete-->
<!--                    :items="['Insurmatics', 'Honeywell Home', 'Bosch Smart Home',-->
<!--                    'Philips Hue', 'tado°', 'Legrand (Netatmo)', 'Gigaset', 'Schneider Electric',-->
<!--                    'Trust International']"-->
<!--                    Brand-->
<!--                    label="Hardware brand" v-model="form.brand"-->
<!--                ></v-autocomplete>-->
<!--                <template v-if="form.brand">-->
<!--                    <template v-if="form.brand=='Honeywell Home'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="['Door and Window Sensors' , 'Motion detectors', 'Water leak sensors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Bosch Smart Home'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="['Door and window sensors' , 'Motion detectors', 'Smoke detectors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Philips Hue'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="[ 'Motion detectors', 'Water leak sensors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='tado°'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="[ 'Water leak sensors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Legrand (Netatmo)'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="['Door and window sensors' , 'Smoke detectors','Water leak sensors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Gigaset'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="['Door and window sensors' , 'Motion detectors', 'Smoke detectors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Schneider Electric'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="['Door and window sensors' , 'Smoke detectors', 'Water leak sensors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Trust International'">-->
<!--                        <v-combobox-->
<!--                            v-model="form.type"-->
<!--                            :items="['Door and window sensors' , 'Motion detectors', 'Water leak sensors']"-->
<!--                            label="Type"-->
<!--                            chips-->
<!--                            multiple-->
<!--                        ></v-combobox>-->
<!--                    </template>-->
<!--                    <template v-if="form.brand=='Insurmatics'">-->
<!--                        <v-select :items="['Fire detector modules']" label="Hardware type and brand"-->
<!--                                  :error-messages="errors" placeholder="Type" v-model="form.type"></v-select>-->
<!--                        <v-text-field label="Hardware ID" :error-messages="errors" placeholder="Enter ID"-->
<!--                                      v-model="form.unique_id "></v-text-field>-->
<!--                        <v-text-field label="Cell number" :error-messages="errors"-->
<!--                                      placeholder="Enter cell number" v-model="form.cell_number "></v-text-field>-->
<!--                        <v-text-field label="WiFi SSID" :error-messages="errors"-->
<!--                                      placeholder="Enter WiFi SSID" v-model="form.wifi_ssid "></v-text-field>-->
<!--                        <v-text-field label="WiFi Password" :error-messages="errors"-->
<!--                                      placeholder="Enter WiFi Password" v-model="form.wifi_password "></v-text-field>-->
<!--                        <v-text-field label="Webhook or restfulAPI" :error-messages="errors"-->
<!--                                      placeholder="Enter Webhook or restfulAPI"-->
<!--                                      v-model="form.restful_address "></v-text-field>-->
<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn text class="m-1" :to="{name:'profiles'}">Cancel</v-btn>-->
<!--                            <v-btn depressed color="success" class="m-1" @click="addNewHardware" :loading="busy">-->
<!--                                Register Data-->
<!--                            </v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </template>-->
<!--                </template>-->


<!--            </v-card-text>-->

<!--        </v-card>-->

<!--    </div>-->
<!--</template>-->

<template>
    <div class="pt-16">
        <v-card class="mx-auto rounded-form" outlined max-width="480px">
            <v-card-title>Add an Authorized Hardware</v-card-title>
            <v-img contain :src="require('./../../assets/iamges/Hardware.jpg')" max-width="100%"></v-img>
            <v-card-text>
                <v-autocomplete v-model="form.brand" :items="brands" label="Hardware brand"></v-autocomplete>
                <div v-if="form.brand">
                    <template v-if="form.brand === 'Insurmatics'">
                        <v-select :items="['Fire detector modules']" label="Hardware type and brand"
                            :error-messages="errors" placeholder="Type" v-model="form.type">
                        </v-select>
                        <v-text-field label="Hardware ID" :error-messages="errors" placeholder="Enter ID"
                                      v-model="form.unique_id "></v-text-field>
                        <v-text-field label="Cell number" :error-messages="errors"
                                      placeholder="Enter cell number" v-model="form.cell_number "></v-text-field>
                        <v-text-field label="WiFi SSID" :error-messages="errors"
                                      placeholder="Enter WiFi SSID" v-model="form.wifi_ssid "></v-text-field>
                        <v-text-field label="WiFi Password" :error-messages="errors"
                                      placeholder="Enter WiFi Password" v-model="form.wifi_password "></v-text-field>
                        <v-text-field label="Webhook or restfulAPI" :error-messages="errors"
                                      placeholder="Enter Webhook or restfulAPI"
                                      v-model="form.restful_address "></v-text-field>
                    </template>
                    <template v-else>
                        <v-combobox v-model="form.type" :items="hardwareTypes[form.brand]" label="Type" chips
                                    multiple></v-combobox>
                    </template>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text :to="{name:'profiles'}">Cancel</v-btn>
                        <v-btn depressed color="success" @click="addNewHardware" :loading="busy">Register Data</v-btn>
                    </v-card-actions>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import FormDetails from "./components/FormDetails";

    export default {
        name: "OwnerCreate",

        components: {FormDetails},


        data: function () {
            return {
                //myFiles: ['index.html'],

                form: {
                    brand: '',
                    type: [], // Ensure this is an array if expecting multiple values
                    unique_id: '',
                    cell_number: '',
                    wifi_ssid: '',
                    wifi_password: '',
                    restful_address: '',
                },
                errors: null,
                responses: null,
                busy: false,
                brands: ['Insurmatics', 'Honeywell Home', 'Bosch Smart Home', 'Philips Hue', 'tado°', 'Legrand (Netatmo)', 'Gigaset', 'Schneider Electric', 'Trust International'],
                hardwareTypes: {
                    'Honeywell Home': ['Door and window sensors', 'Motion detectors', 'Water leak sensors'],
                    'Bosch Smart Home': ['Door and window sensors', 'Motion detectors', 'Smoke detectors'],
                    'Philips Hue': ['Motion detectors', 'Water leak sensors'],
                    'tado°': ['Water leak sensors'],
                    'Legrand (Netatmo)': ['Door and window sensors', 'Smoke detectors', 'Water leak sensors'],
                    'Gigaset': ['Door and window sensors', 'Motion detectors', 'Smoke detectors'],
                    'Schneider Electric': ['Door and window sensors', 'Motion detectors', 'Water leak sensors'],
                    'Trust International': ['Door and window sensors', 'Motion detectors', 'Water leak sensors'],
                    // Add other brands types similarly...
                }

            }
        },
        watch: {
            'form.brand': function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.form.type = []; // Reset type to empty array or null based on your requirement
                }
            }
        },

        mounted() {
            // this.video = this.$refs.video;
            // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            //     navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //         video.srcObject = stream;
            //         video.play();
            //         video.onplay = function () {
            //         };
            //         this.video.play();
            //     });
            // }
        },

        methods: {
            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            },
            addNewHardware() {
                /*axios.post('../dogdata/', {
                    race: 'Finn',
                    age: 'Williams',
                    sex: 'Williams',
                    catched_lat:'',
                    catched_lon: '',
                    vaccinated: '',
                    sterilized:''
                }).then(response => {

                            })
                         .catch(errors =>{
                             console.log(errors);
                         });*/

                this.busy = true;

                axios.post('hardware', this.form)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'hardwarelist'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>

