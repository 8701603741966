<template>
    <div class="not-found-container">
        <div class="not-found-content">
            <h1 class="not-found-title">404</h1>
            <p class="not-found-text">Oops! The page you are looking for does not exist.</p>
            <button class="go-back-button" @click="goBack">Go Back</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound",
        methods: {
            goBack() {
                this.$router.go(-1);
            }
        }
    };
</script>

<style scoped>
    .not-found-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #f5f5f5;
    }

    .not-found-content {
        text-align: center;
    }

    .not-found-title {
        font-size: 8em;
        color: #ff5252;
    }

    .not-found-text {
        font-size: 1.5em;
        margin-top: -40px;
        color: #616161;
    }

    .home-link {
        display: inline-block;
        margin-top: 50px;
        padding: 10px 20px;
        border: 1px solid #ff5252;
        color: #ff5252;
        font-weight: 600;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }

    .home-link:hover {
        background-color: #ff5252;
        color: #ffffff;
    }

    .go-back-button {
        display: inline-block;
        margin-top: 50px;
        padding: 10px 20px;
        border: 1px solid #ff5252;
        color: #ff5252;
        font-weight: 600;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        background-color: transparent;
    }

    .go-back-button:hover {
        background-color: #ff5252;
        color: #ffffff;
    }
</style>
