<template>
    <div>
        <v-snackbar v-model="snackkbar" color="#f2c4d3" :timeout="5000" top>
            <span style="color: #333333;">
                View car information here.
            </span>
        </v-snackbar>
        <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
        >
        </v-text-field>
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="20"
            :search="search"
            :loading="busy"
            sort-by="email"
            :sort-desc="true"
            align="center"
            :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'Items per page',
      }"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Technician Vehicle Information</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialogAdd"
                        max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="#9a7485ff"
                                dark
                                rounded
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Register
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">Please enter vehicle information.</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                                v-model="newCar.name"
                                                label="Car Model *"
                                                hint="Enter the model."
                                                persistent-hint
                                                required
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="newCar.number"
                                                label="License Plate *"
                                                hint="11S111-IR 11"
                                                persistent-hint
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="gray"
                                    @click="dialogAdd = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="green"
                                    @click="saveNewCarData"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <!--            @click:row="item=>$router.push({name:'users',params:{profile_id:item.id}})"-->
            <template #item.access_level="{value}">
                {{getAccessLevel(value)}}
            </template>

            <template v-slot:item.location="{ item }">
                <template>
                    <v-btn @click.prevent="EditData(item.id)" icon>
                        <v-icon>navigation</v-icon>
                    </v-btn>
                </template>
            </template>

            <template #item.remove="{item}">
                <!--                {{item.id}}-->
                <v-dialog
                    v-model="dialog"
                    width="500"
                    :retain-focus="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-flex align-self-start>
                            <v-btn color="error"
                                   v-bind="attrs"
                                   v-on="on"
                                   @click="updateRemoveID(item.id)"
                                   small>Remove
                            </v-btn>

                        </v-flex>
                    </template>
                    <v-card>
                        <v-card-title class="red white--text">
                            Are you sure you want to delete?
                        </v-card-title>
                        <v-card-text>
                            Once deleted, it is no longer possible to return the information. If you are sure, press the
                            delete button, otherwise cancel.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="destroyCar()"
                            >
                                Remove
                            </v-btn>

                            <v-btn
                                color="gray"
                                @click="dialog = false">
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template #item.edit="{item}">
                <v-row justify="center">
                    <v-dialog
                        v-model="dialog1"
                        persistent
                        max-width="600px"
                        :retain-focus="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-flex align-self-center width="20px">
                                <v-btn color="primary"
                                       small
                                       dark
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="uploadData(item.name, item.number, item.id)">Edit
                                </v-btn>
                            </v-flex>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">Vehicle Info</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                                v-model="editCar.name"
                                                label="Car Model *"
                                                hint="Car model"
                                                persistent-hint
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="editCar.number"
                                                label="License Plate *"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="gray"
                                    @click="dialog1 = false"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="green"
                                    @click="editCarData()"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]

    export default {
        name: "UsersIndex",


        created() {
            this.fetchData()
        },
        mounted() {

        },


        data: function () {
            return {
                dialog: false,
                dialog1: false,
                //Dialog ADD
                dialogAdd: false,
                newCar: {
                    name: '',
                    number: '',
                    driver1: '',
                    driver2: '',
                },
                editCar: {
                    name: '',
                    number: '',
                    driver1: '',
                    driver2: '',
                },
                editCarID: '',
                removeCarID: '',
                fill: false,
                gradient: gradients[4],
                gradients,
                padding: 8,
                radius: 10,
                value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
                width: 2,
                busy: false,
                search: '',
                users: [],
                snackkbar: true,


                headers: [
                    {text: 'id machine', value: 'id'},
                    {
                        text: 'Nanme',
                        align: 'start',
                        value: 'name',
                    },
                    // { text: 'Family', value: 'family' },
                    {text: 'number plate', value: 'number'},
                    {text: 'driver1', value: 'driver1'},
                    {text: 'driver2', value: 'driver2'},
                    // {text: 'امتیاز', value: "id"},
                    {text: 'Tracking', value: 'location', sortable: false,},
                    {text: 'Edit', value: 'edit', sortable: false, width: "200px"},
                    {text: 'Remove', value: 'remove', sortable: false, width: "200px"},
                    // { text: 'Dog ID', value: 'dog_ID' },
                    // { text: 'Dog ID', value: 'family' },
                ],


            }
        },
        methods: {
            saveNewCarData() {
                this.dialogAdd = false;
                axios.post('../car', this.newCar)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.response);
                        this.$router.go();
                    })
                    .catch(errors => {
                        console.log(this.newCar);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            //Edit
            uploadData(name, number, id) {
                this.editCar.name = name;
                this.editCar.number = number;
                this.editCarID = id;
            },
            editCarData() {
                this.dialogAdd = false;
                axios.put('../car/' + this.editCarID, this.editCar)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.response);
                        this.$router.push({name: 'carindex'});
                    })
                    .catch(errors => {
                        console.log(this.newCar);
                    }).finally(() => {
                    this.dialog1 = false;
                    this.$router.go();
                });
            },
            //Delete:
            updateRemoveID(id) {
                this.removeCarID = id;
            },
            destroyCar() {
                axios.delete('../car/' + this.removeCarID)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        this.$router.push({name: 'carindex'});
                    })
                    .catch(errors => {
                        console.log(this.newCar);
                    }).finally(() => {
                    this.dialog = false;
                    this.$router.go();
                });
            },
            // upload
            fetchData() {
                this.busy = true;

                axios.get('/car')
                    .then(response => {
                        this.users = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            // axios.get('../dogdata/' + this.$route.params.id)
            //     .then(response =>{
            //         this.doginfoshow = response.data[0].data;
            //         //this.temp = response.data[0].data.race;
            //         //this.doginfoshow= response.data[0].data;
            //     })
            //     .catch(error =>{
            //
            //     });

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            EditData: function (id) {
                this.$router.push('../carss/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            getAccessLevel(access_level) {
                if (access_level == "1") return 'Management'
                else if (access_level == "2") return 'Operator'
                else if (access_level == "3") return 'Installation expert'
                else if (access_level == "4") return 'Damage expert'
                else if (access_level == "5") return 'Support specialist'
                else if (access_level == "7") return 'Responsible technician'
                else if (access_level == "8") return 'Security and inspection'
                else return 'Unapproved user'
            },


        }
    }

</script>

<style scoped>

</style>
