<template>
    <div style="width: 100%; height: 100%;">
        <MglMap :accessToken="mapirToken"
                :center.sync="mapCenter"
                :zoom="mapZoom"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <MglNavigationControl position="top-right" />
            <MglGeolocateControl position="top-right" />
            <MglGeojsonLayer
                sourceId="mySource"
                :source="geoJsonSource"
                layerId="myLayer"
                :layer="geoJsonlayer"
            />

            <MglMarker v-for="doglocation in doglocations" :key="doglocation"
                       :coordinates.sync="doglocation"
                       color="blue">
            </MglMarker>

        </MglMap>
    </div>
</template>

<script>
    // import { mapir, mapMarker , mapPopup , mapGeojsonLayer, mapGeolocateControl, mapNavigationControl,} from "mapir-vue";
    import { MglMap, MglMarker , MglPopup , MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";

    export default {
        computed:{
            // coordinates(){
            //     return this.userslocation[0]["LatLong"];
            // },

            geoJsonSource() {

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                    coordinates:this.userlocations
                                }
                            }
                        ]
                    }
                };
            }
        },
        mounted()
        {

        },
        name: "TechnicianLocationRep",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        props: ['doglocations', 'userlocations'],//

        data() {
            return {
                geoJsonlayer: {
                    id: "myLayer",
                    type: "line",
                    source: "mySource",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    paint: {
                        "line-color": "#088",
                        "line-width": 4
                    }
                },
                count:'',
                colors: "Green",
                locations:[],
                times:[],
                coordinates: [51.420296, 35.732379],
                mapCenter: [25.2797, 54.6872],
                mapZoom: 11.15,
                // mapirToken: "<Your API Key>"
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
            };
        }
    };

</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>

