<template>
    <div class="pt-16">
        <v-snackbar v-model="snackkbarSuccess" color="#f2c4d3" :timeout="5000" centered>
            <span style="color: #333333;">
                Success! Your photo upload has been completed successfully.
            </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>

        <v-card max-width="480px" class="mx-auto rounded-form" outlined>
            <div id="filepond">
                <file-pond
                    allowImageResize="true"
                    allowFileEncode="true"
                    imageResizeTargetWidth="400"
                    imageResizeTargetHeight="400"
                    allowImageExifOrientation="true"
                    imageResizeUpscale="false"
                    allowImageTransform="true"
                    imageTransformOutputMimeType="image/png"
                    name="test"
                    ref="pond"
                    class-name="my-pond"
                    label-idle=" For a better view, please take the photo horizontally..."
                    allow-multiple="false"
                    accepted-file-types="image/jpeg, image/png, image/*, image/gif, image/jpg"
                    allowFileSizeValidation="true"
                    maxFileSize="10MB"
                    minFileSize="50KB"
                    imageResizeMode="cover"
                    :server="server_credential"
                    allowFileRename="true"
                    captureMethod=""
                    v-on:init="handleFilePondInit"
                    v-on:processfile="handleProcessFile"></file-pond>
            </div>
            <v-card-title>
                Please, enter building details
            </v-card-title>
            <v-img :src="require('./../../assets/iamges/BuildingType.png')" contain max-height="120px">

            </v-img>

            <v-card-text class="mb-16">
                <v-select :items="['Villa', 'Apartment', 'Commercial', 'Office']" label="Type" :error-messages="errors"
                          placeholder="Specify the building type" v-model="form.age "></v-select>
                <v-select
                    :items="['20 years and up', '20-15 years', '15-10 years', '5-10 years', '1-5 years', 'New build']"
                    label="age" :error-messages="errors" placeholder="Home Age"
                    v-model="form.sex "></v-select>
                <v-text-field label="structures" :error-messages="errors" placeholder="Structure material"
                              v-model="form.color "></v-text-field>
            </v-card-text>
            <v-card v-model="picAlert">
                <v-alert
                    v-model="picAlert"
                    dense
                    border="left"
                    type="warning"
                >
                    Go ahead and upload the photo.
                </v-alert>
            </v-card>
            <v-card v-if="locationalert">
                <v-alert
                    dense
                    border="left"
                    type="warning"
                >
                    Please,
                    <v-btn

                        color="#e65100"
                        rounded
                        class="shrink text-black-50"
                        @click.passive="getCurrentLocation()">
                        turn un
                    </v-btn>
                    your GPS.

                </v-alert>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-row>
                    <v-btn depressed color="success" class="m-1" @click="addNewDog">Register</v-btn>
                    <v-btn text class="m-1" :to="{name:'profiles'}">Cancel</v-btn>
                </v-row>


            </v-card-actions>


        </v-card>
        <v-card>

        </v-card>

    </div>

</template>

<script>
    import InputField from "../components/InputField";
    import vueFilePond, {setOptions} from 'vue-filepond';

    import 'filepond/dist/filepond.min.css';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize
        from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';
    import FilePondPluginFileEncode from 'filepond-plugin-file-encode/dist/filepond-plugin-file-encode.esm';
    import FilePondPluginImageExifOrientation
        from 'filepond-plugin-image-exif-orientation/dist/filepond-plugin-image-exif-orientation.esm';


    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform, FilePondPluginFileEncode, FilePondPluginImageExifOrientation);

    export default {

        name: "ProfilesCreate",
        props: [
            'fig_id',
            'form_public',
            'locationss',
            'cell_number',
        ],
        components: {
            InputField,
            FilePond,
        },
        data() {
            return {
                video: '',
                canvas: {},
                captures: [],
                // readerSize: {
                //     width: 640,
                //     height: 480
                // },
                detecteds: [],
            }
        },
        data: function () {
            return {
                picUpload: false,
                picAlert: false,
                locationalert: false,
                //myFiles: ['index.html'],
                snackkbarSuccess: false,
                picID2: '',
                booll: false,
                video: '',
                readerSize: {
                    width: 900,
                    height: 200
                },
                detecteds: [],
                form2:
                    {
                        'name': '',
                        'access_level': '',
                        'technician': ''
                    },
                form:
                    {
                        'race': '',
                        'age': '',
                        'sex': '',
                        'catched_lat': '',
                        'catched_lon': '',
                        'color': '',
                        'date': '',
                        'chip_id': '',
                        'vaccine_a': '',
                        'sterilized': '',
                        'ownerID': '',
                        'vaccine_b': '',
                        'vaccine_c': '',
                        'comment': '',
                        'vaccine_a_Last': '',
                        'vaccine_a_Next': '',
                        'vaccine_a_vet_name': '',
                        'vaccine_b_Last': '',
                        'vaccine_b_Next': '',
                        'vaccine_b_vet_name': '',
                        'vaccine_c_Last': '',
                        'vaccine_c_Next': '',
                        'vaccine_c_vet_name': '',
                        'sterilized_date': '',
                        'sterilized_vet_name': '',
                        'first_fig': '',
                        'final_fig': '',
                        'public_fig': '',
                        'confirmation': '',
                        'public_cellnumber': '',
                        'technician': ''
                    },
                LatLong: {
                    'Lat': '',
                    'Long': ''
                },

                errors: null,
                responses: null,
                busy: false,
            }
        },

        created: function () {

            // if(navigator.geolocation){
            //     navigator.geolocation.getCurrentPosition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // }
            this.form.catched_lat = this.$store.state.LatLong.Lat;
            this.form.catched_lon = this.$store.state.LatLong.Long;
            if (navigator.geolocation) {


                //this.form.catched_lon = position.coords.longitude;
                navigator.geolocation.getCurrentPosition(position => {
                    this.form.catched_lon = position.coords.longitude;
                    this.form.catched_lat = position.coords.latitude;
                    this.LatLong.Lat = position.coords.latitude;
                    this.LatLong.Long = position.coords.longitude;
                    this.$store.commit("updateLocation", this.LatLong);
                    //this.form.catched_lon = '23232';
                })
                //
                // this.form.catched_lat = '13';
                // this.form.catched_lon = '13';
            }
        },
        computed: {

            acceptedFileTypes() {
                return this.noSvg ? "image/jpeg, image/png, image/webp" : "image/jpeg, image/png, image/svg+xml, image/webp"
            },
            image_url() {
                if (!this.image) return null;
                return this.image + this.version;
            },
            server_credential() {
                return {
                    url: "../homedata/upload",
                    process: {
                        method: "POST",
                        withCredentials: true,
                        onerror: response => {
                            alert('error')
                        },
                        onload: response => {
                            this.snackkbarSuccess = true;
                            this.picAlert = false;
                            this.picUpload = true;
                            // response = JSON.parse(response);
                            // alert(response);
                        }
                    }
                };
            }
        },
        mounted() {
            // setOptions({
            //     server: {
            //         process: {
            //             url: "../dogdata/upload",
            //             method: "POST",
            //             onerror: response => response.data,
            //             onload: response => {
            //                 response = JSON.parse(response);
            //                 alert(response);
            //             }
            //         },
            //     }
            // })
        },

        methods: {

            updateSetOption: function () {
                let currentDate = Date.now();
                setOptions({
                    imageCropAspectRatio: '1:1',
                    imageResizeTargetWidth: 500,
                    imageResizeTargetHeight: 500,
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Dog_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Dog_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            logIt(data) {
                if ((data.codeResult.code.toString().length == 15) && (data.codeResult.code.toString().charAt(0) == "3")) {
                    this.form.chip_id = data.codeResult.code;
                }
                // alert(data.codeResult.code.toString().length);

                //alert(this.video.codeResult.code)
                // console.log('detected', data)
            },
            getCurrentLocation: function () {
                this.locationalert = false;
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                        // updateLocation (state, LatLongInfo)[
                        let newLocation = {
                            Lat: this.form.catched_lat,
                            Long: this.form.catched_lon
                        };
                        this.$store.commit("updateLocation", newLocation);
                    })
                } else {
                    this.geoLocationAlert = true;
                }
            },
            handleProcessFile: function (error, file) {
                this.form.first_fig = this.picID2;
                // alert("salam");
                // updateCaptureFig(state, newFig)
                this.$store.commit("updateCaptureFig", file.serverId);
                if (this.fig_id === undefined) {
                    this.form.public_fig = '';
                    this.form.public_cellnumber = '';
                } else {
                    this.form.public_fig = this.fig_id;
                    this.form.public_cellnumber = this.cell_number;
                }
                console.log(this.form.first_fig);
            },
            capture() {
                this.canvas = this.$refs.canvas;
                var context = this.canvas.getContext("2d").drawImage(this.video, 0, 0, 640, 480);
                this.captures.push(canvas.toDataURL("image/webp"));
            },
            showPosition(position) {
                x.innerHTML = "Latitude: " + position.coords.latitude +
                    "<br>Longitude: " + position.coords.longitude;
            },
            addNewDog() {
                this.form.catched_lon = this.$store.state.LatLong.Long;
                this.form.catched_lat = this.$store.state.LatLong.Lat;
                this.getCurrentLocation();
                // this.form.first_fig  = this.$store.state.capturedFig;
                this.form.first_fig = this.picID2;
                this.busy = true;
                this.form.race = "مخلوط";
                this.form.technician = this.$store.state.users.name;
                if (this.picUpload == false) {
                    this.picAlert = true;
                } else if (this.form.catched_lon == 53) {
                    this.locationalert = true;
                } else {
                    this.locationalert = false;
                    axios.post('../homedata', this.form)
                        .then(response => {
                            this.$store.commit("updateSnackbarCreateDogProfile", true);
                            axios.post('../saveUserLocation', {
                                ID: this.$store.state.users.id,
                                //ID:Number(d)-Number(this.InitTime),
                                Long: this.form.catched_lon,
                                Lat: this.form.catched_lat,
                            })
                                .then(response => {
                                    // this.responses = response.data[0].data.id;
                                    // //this.$router.push(toString(response.data[0].data.id));
                                    // this.$router.push({name:'profiles'});
                                    console.log('Send');
                                })
                                .catch(errors => {
                                    console.log('error');
                                });
                            if (this.form.public_fig.length > 0) {
                                axios.post('../report/update', this.form_public)
                                    .then(response => {

                                        this.$router.push({name: 'dashboard'});
                                    })
                            }
                            this.form2.name = this.$store.state.users.name;
                            this.form2.access_level = this.$store.state.users.access_level;
                            this.form2.technician = 1;
                            axios.put('../user/' + this.$store.state.users.id, this.form2)
                                .then(response => {
                                    this.$router.push({name: 'dashboard'});
                                })
                                .catch(errors => {
                                    // this.errors = errors.response.data.errors;
                                    console.log(this.errors);
                                }).finally(() => {
                                this.busy = false;
                            });
                            this.$router.push({name: 'dashboard'});

                            // this.responses = response.data[0].data.id;
                            //this.$router.push(toString(response.data[0].data.id));
                            // this.form_public.catch_lon = this.form.catched_lon;
                            // this.form_public.catched_lat = this.form.catched_lat;
                            // axios.post('../report/update', this.form_public)
                            //     .then( response => {
                            //         console.log(this.responses);
                            //
                            //     })
                            //     .catch(errors =>{
                            //         console.log(this.form);
                            //         this.errors = errors.response.data.errors;
                            //         console.log(this.errors);
                            //     }).finally(()=>{
                            //     this.busy=false;
                            // });
                            //this.$router.push({name:'profiles'});
                        })
                        .catch(errors => {
                            console.log(this.form);
                            // this.errors = errors.response.data.errors;
                            console.log(this.errors);
                        }).finally(() => {
                        this.busy = false;
                    });
                }


            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>
