import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '../../../public/css/fontiran.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.min.css'; // Ensure you are using css-loader
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import applianceIcon from '../Icons/applianceIcon'
import sensorIcon from '../Icons/sensorIcon'
import confirmedIcon from '../Icons/confirmedIcon'
import listIcon from "../Icons/listIcon";
import mapDis from "../Icons/mapDis";
import TechKPIs from "../Icons/TechKPIs";
import carInfo from "../Icons/carInfo";
import overviewIcon from "../Icons/overviewIcon";
import unreadIcon from "../Icons/unreadIcon";
import inprogressIcon from "../Icons/inprogressIcon";
import inActionIcon from "../Icons/inAction";
import managerListIcon from "../Icons/managerListIcon";
import calenderIcon from "../Icons/calenderIcon";
import gmailIcon from "../Icons/gmailIcon";
import handshakeIcon from "../Icons/handshakeIcon";
import addRegistrationIcon from "../Icons/addRegistrationIcon";
import paymentIcon from "../Icons/paymentIcon";
import IoTProtocolsLowCode from "../Icons/IoTProtocolsLowCode";
import integrationLCIcon from "../Icons/integrationLCIcon";
import partySoftwareIcon from "../Icons/3rdPartySoftwareIcon";
import alertLCIcon from "../Icons/alertLCIcon";
import dataVisualizationLCIcon from "../Icons/dataVisualizationLCIcon";
import MQTTLCIcon from "../Icons/IoTProtocols/MQTTLCIcon";
import TCPLCIcon from "../Icons/IoTProtocols/TCPLCIcon";
import UDPLCIcon from "../Icons/IoTProtocols/UDPLCIcon";
import ModbusLCIcon from "../Icons/IoTProtocols/ModbusLCIcon";
import websocketIcon from "../Icons/IoTProtocols/websocketIcon";
import KNXLCIcon from "../Icons/IoTProtocols/KNXLCIcon";
import APIIcon from "../Icons/IntegrationsLC/APIIcon";
import stripeIcon from "../Icons/3rdPartySoftwareLC/stripeIcon";
import googleTagManager from "../Icons/3rdPartySoftwareLC/googleTagManager";
import emailLCIcon from "../Icons/Alert and Notification/emailLCIcon";
import smsLCIcon from "../Icons/Alert and Notification/smsLCIcon";
import pushNotificationIcon from "../Icons/Alert and Notification/pushNotificationIcon";
import pieChartIcon from "../Icons/DataVisualization/pieChartIcon";
import barChartIcon from "../Icons/DataVisualization/barChartIcon";
import statisticalDashboardIcon from "../Icons/DataVisualization/statisticalDashboardIcon";
import eventTimelineIcon from "../Icons/DataVisualization/eventTimelineIcon";
import timeSeriesGraphIcon from "../Icons/DataVisualization/timeSeriesGraphIcon";

//
Vue.use(Vuetify);
Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed icons

// Vue.use(Vuetify, {
//     rtl: true
// })

export default new Vuetify({
    buttons: {
        capitalize: false,
    },
    icons: {
        iconfont: 'md' || 'faSvg',
        values: {
            timeSeriesGraph:{
                component: timeSeriesGraphIcon,
            },
            eventTimeline:{
                component: eventTimelineIcon,
            },
            statisticalDashboard:{
                component: statisticalDashboardIcon,
            },
            barChart:{
                component: barChartIcon,
            },
            pieChart:{
                component: pieChartIcon,
            },
            pushNotification:{
                component: pushNotificationIcon,
            },
            smsLC:{
                component: smsLCIcon,
            },
            emailLC:{
                component: emailLCIcon,
            },
            googleTagManager:{
                component: googleTagManager,
            },
            stripe:{
                component: stripeIcon,
            },
            API:{
                component: APIIcon,
            },
            KNX:{
                component: KNXLCIcon,
            },
            websocket:{
                component: websocketIcon,
            },
            Modbus:{
                component: ModbusLCIcon,
            },
            UDPLC:{
                component: UDPLCIcon,
            },
            TCPLC:{
                component: TCPLCIcon,
            },
            MQTTLC:{
                component: MQTTLCIcon,
            },
            dataVisualizationLC:{
                component: dataVisualizationLCIcon,
            },
            alertLC:{
                component: alertLCIcon,
            },
            thirdPartySoftware:{
                component: partySoftwareIcon,
            },
            integrationLP:{
                component: integrationLCIcon,
            },
            iotprotocols:{
                component: IoTProtocolsLowCode,
            },
            payment:{
                component: paymentIcon,
            },
            appRegistration:{
                component: addRegistrationIcon,
            },
            handshake:{
                component: handshakeIcon,
            },
            gmail:{
                component: gmailIcon,
            },
            custom: { // name of our custom icon
                component: applianceIcon, // our custom component
            },
            sensor: { // name of our custom icon
                component: sensorIcon, // our custom component
            },
            confirmed: { // name of our custom icon
                component: confirmedIcon, // our custom component
            },
            list: { // name of our custom icon
                component: listIcon, // our custom component
            },
            mapDis: { // name of our custom icon
                component: mapDis, // our custom component
            },
            techKPI: { // name of our custom icon
                component: TechKPIs, // our custom component
            },
            carInfo: { // name of our custom icon
                component: carInfo, // our custom component
            },
            overview: { // name of our custom icon
                component: overviewIcon, // our custom component
            },
            unread:{
                component: unreadIcon,
            },
            inprogress:{
                component: inprogressIcon,
            },
            inAction:{
                component:inActionIcon,
            },
            managerList:{
                component:managerListIcon,
            },
            calendar:{
                component:calenderIcon,
            },
        },
    },
    ltr: true,
});

