<template>


    <v-container fluid fill-height class="bg">
        <v-layout align-center justify-center>
            <!--                    Large Page-->
            <v-card
                shaped
                :loading="loading"
                width="70%"
                max-width="800pt"
                color="white"
                class="elevation-20"

                rounded="20"
                v-if="isHeaderLarge"
            >
                <v-row>
                    <v-col cols="5">
                        <v-card

                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to {{$store.state.business.name}} customer panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Reconnect with Your Smart Home & Insurance Hub!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click=""
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>

                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        outlined
                                        persistent-hint="Email*"
                                        v-model="form.cell_number"
                                        dense
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-fade-transition leave-absolute>
                                                <v-progress-circular
                                                    v-if="loading"
                                                    size="24"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                                <img
                                                    v-else
                                                    width="24"
                                                    height="24"
                                                    src="../../../assets/icons/email.svg"
                                                >
                                            </v-fade-transition>
                                        </template>


                                    </v-text-field>
                                    <v-text-field
                                        type="password"
                                        rounded
                                        outlined
                                        dense
                                        v-model="form.password"
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-fade-transition leave-absolute>
                                                <v-progress-circular
                                                    v-if="loading"
                                                    size="24"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                                <img
                                                    v-else
                                                    width="24"
                                                    height="24"
                                                    src="../../../assets/icons/Password.svg"


                                                >
                                            </v-fade-transition>
                                        </template>
                                    </v-text-field>

                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        Login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="mx-auto my-auto">
                        <img width="100%" src="../../../assets/icons/Customer/LoginCustomer.svg">
                    </v-col>
                </v-row>

            </v-card>

            <!--                    Medium size page-->
            <v-card
                shaped
                :loading="loading"
                width="80%"
                max-width="550pt"
                color="white"
                class="elevation-20"
                rounded="20"
                v-if="isHeaderMedium"
            >
                <v-row>
                    <v-col cols="9" class="my-auto mx-auto">
                        <img width="80%" src="../../../assets/icons/Customer/LoginCustomer.svg">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-card
                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to {{$store.state.business.name}} customer panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Reconnect with Your Smart Home & Insurance Hub!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click="loader = 'loading'"
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>
                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        label="Cell number or Email*"
                                        v-model="form.cell_number"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        rounded
                                        type="password"
                                        label="Password*"
                                        v-model="form.password"
                                        outlined
                                    ></v-text-field>
                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>

            </v-card>

            <!--                    Small size-->
            <v-card

                shaped
                :loading="loading"
                width="80%"
                min-width="250pt"
                color="white"
                class="elevation-20 my-10"
                rounded="20"
                v-if="isHeaderSmall"
            >
                <v-row>
                    <v-col cols="10" class="mx-auto my-auto">
                        <img src="../../../assets/icons/Customer/LoginCustomer.svg">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-card
                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to {{$store.state.business.name}} customer panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Reconnect with Your Smart Home & Insurance Hub!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click="loader = 'loading'"
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>
                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        label="Email*"
                                        v-model="form.cell_number"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        rounded
                                        type="password"
                                        label="Password*"
                                        v-model="form.password"
                                        outlined
                                    ></v-text-field>
                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>

            </v-card>


        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'LoginPanels',
        props: {
            source: String,
        },
        data() {
            return {
                customer:'',
                cookieData: '',
                googleLoginLink: 'google',
                loader: null,
                loading: false,
                user: '',
                userTest: '',
                form:
                    {
                        'cell_number': '', 'password': '',
                    },
            }
        },
        mounted() {
            this.checkForGoogleLogin();
        },
        methods: {
           async checkForGoogleLogin() {
                const urlParams = new URLSearchParams(window.location.search);
                console.log(urlParams);
                if (urlParams.has("id")) {
                    const customerData = {
                        id: urlParams.get("id"),
                        name: urlParams.get("name"),
                        family: urlParams.get("family"),
                        cell_number: urlParams.get("cell_number"),
                        pic: urlParams.get("pic"),
                        sex: urlParams.get("sex"),
                        national_ID: urlParams.get("national_ID"),
                        address: urlParams.get("address"),
                        postal_code: urlParams.get("postal_code"),
                    };
                    this.$store.commit('setCustomerAuthenticated', true);
                    localStorage.setItem("isCustomerAuthenticated", true);

                    this.customer = customerData;

                    this.$store.commit("updateCustomer", this.customer);
                    this.deleteCookie("customerInfo");
                    this.setCookie("customerInfo", this.customer, 1);
                    this.$emit("customerInfo", this.customer);
                    // await this.$router.push({name: "dashboard"});
                    // await this.$router.push({path: '/dashboard/'});
                    await this.$router.push({name: "propertyManagement"});
                    // this.$router.go(0);
                }
            },
            navigateToRegister() {
                this.$router.push({name: 'register'});
            },
            setCookie(name, value, hours, secure = true, sameSite = 'Strict') {
                let expires = '';
                if (hours) {
                    const date = new Date();
                    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
                    expires = `; expires=${date.toUTCString()}`;
                }

                const secureFlag = secure ? '; Secure' : '';
                const sameSiteFlag = sameSite ? `; SameSite=${sameSite}` : '';

                const encodedName = encodeURIComponent(name);
                const jsonString = JSON.stringify(value);
                const encodedValue = encodeURIComponent(jsonString);

                document.cookie = `${encodedName}=${encodedValue}${expires}${secureFlag}${sameSiteFlag}`;
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            deleteCookie(name) {
                this.setCookie(name, '', -1);
            },
            async postLoginData() {
                try {
                    let baseRoute = '../login';
                    if (this.$route.params.business) {
                        baseRoute = `../${this.$route.params.business}/login`;
                    }
                    const response = await axios.post( baseRoute , this.form);

                    const customerData = response.data.customer;
                    this.$store.commit('setCustomerAuthenticated', true);
                    localStorage.setItem('isCustomerAuthenticated', true);
                    const customer = {
                        name: customerData.name,
                        family: customerData.family,
                        cell_number: customerData.cell_number,
                        sex: customerData.sex,
                        pic: customerData.pic,
                        national_ID: customerData.national_ID,
                        address: customerData.address,
                        postal_code: customerData.postal_code,
                        id: customerData.id
                    };

                    this.$store.commit('updateCustomer', customer);
                    this.deleteCookie('customerInfo');
                    this.setCookie('customerInfo', customer, 1);
                    this.$emit('customerInfo', customer);

                    await this.$router.push({name: "propertyManagement"});
                } catch (error) {
                    console.error('An error occurred while logging in:', error);
                }
            },




        },
        computed: {

            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },
    };

</script>

<style scoped>
    .bg {
        background: url("../../../assets/icons/Background.svg") no-repeat center center;
        background-size: cover;
    }

    .v-btn {
        text-transform: none !important;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    .v-text-field .v-label--active {
        transform: translateX(-12%) translateX(-7.5px) translateY(-28px) scale(0.75) !important;

    }

    .v-text-field--dense .v-label--active {
        transform: translateX(-12%) translateX(-7.5px) translateY(-21px) scale(0.75) !important;

    }

</style>


