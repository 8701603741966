<template>
    <div class="pt-16">
        <v-snackbar :timeout="3000" color="#f2c4d3" top v-model="snackkbar">
            <span style="color: #333333;">
                Dear {{$store.state.customers.family}} , your request has been successfully submitted.
            </span>
        </v-snackbar>

        <v-card class="mx-auto rounded-form" max-width="70%" min-width="370px" outlined>
            <v-card-title class="layout justify-center">
                Apply to buy, renew and upgrade smart home insurance
            </v-card-title>
            <v-card-subtitle class="layout justify-center">
                Please complete your requested services according to the table below.
            </v-card-subtitle>
            <template class="justify-center mx-auto">

                <v-img :src="require('../../../assets/iamges/Buy.png')" class="mx-auto" contain max-width="200pt">
                </v-img>
            </template>
            <v-card-text>
                <v-autocomplete
                    :items="['Purchase', 'Renew', 'Upgrade']"
                    buy
                    label="What is the request you want?" v-model="request"
                ></v-autocomplete>
                <!--                'state', 'startdate','devicenum', 'errornum', 'address', 'unitnum', 'postal_code',
                        'unitset', 'ownerlist', 'appliancenum', 'applianceprice', 'hardwarenum', 'buildingtype', 'lat', 'long', 'pic', 'hardwarelist'
                -->
                <template v-if="request">
                    <template v-if="request=='Purchase'">
                        <template>
                            <v-stepper v-model="e1">
                                <v-stepper-header>
                                    <v-stepper-step
                                        :complete="e1 > 1"
                                        step="1"
                                    >
                                        Initial information
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step
                                        :complete="e1 > 2"
                                        step="2"
                                    >
                                        Choose a plan
                                    </v-stepper-step>

                                    <v-divider></v-divider>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <!--                                        <v-card-->
                                        <!--                                            class="mb-12"-->
                                        <!--                                            width="100%"-->
                                        <!--                                        >-->
                                        <v-row>
                                            <v-col
                                                cols="12"
                                            >
                                                <div id="filepondBuy">
                                                    <file-pond
                                                        accepted-file-types="image/jpeg, image/png"
                                                        allow-multiple="false"
                                                        class-name="my-pond"
                                                        label-idle="Please take a photo of the building... "
                                                        name="test"
                                                        ref="pond"
                                                        server="buildings/upload"
                                                        v-on:processfile="handleProcessFile">
                                                    </file-pond>
                                                </div>
                                            </v-col>
                                            <v-col
                                                class="justify-center"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-autocomplete
                                                    :items="['Commercial Property', 'Office', 'Apartment', 'Villa']"
                                                    label="Choose the type of building?"
                                                    v-model="form.building_type"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col
                                                class="justify-center"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-autocomplete
                                                    :items="[1, 2, 3 , 4, 5, 6, 7, 8 , 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                                                    @change="changeUnit" label="Units"
                                                    placeholder="Enter the number of units."
                                                    v-model="form.unit_num"
                                                >

                                                </v-autocomplete>

                                            </v-col>
                                            <v-col
                                                class="justify-center"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-autocomplete
                                                    :items="['Brick', 'Concrete', 'Metal']"
                                                    label="Choose the type of building structure?"
                                                    v-model="form.infrastructure"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col
                                                class="justify-center"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-autocomplete
                                                    :items="['New', '1-5 years old', '5-10 years old', '10-15 years old ', '15 years old']"
                                                    label="Choose the life of the building structure?"
                                                    v-model="form.age"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col
                                                class="justify-center"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-text-field label="Area"
                                                              placeholder="Total area of units with common areas."
                                                              v-model="form.total_area "></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12">
                                                <v-text-field label="Address"
                                                              placeholder="Enter the exact address of the building."
                                                              v-model="form.address">
                                                </v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12">
                                                <v-card height="200pt">
                                                    <div style="width: 100%; height: 100%;">
                                                        <MglMap :accessToken="mapirToken"
                                                                :center.sync="centers"
                                                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                                                zoom="9">
                                                            <MglAttributionControl />
                                                            <MglNavigationControl position="top-right" />
                                                            <MglGeolocateControl position="top-right" />
                                                            <!--            <mapGeojsonLayer-->
                                                            <!--                sourceId="mySource"-->
                                                            <!--                :source="geoJsonSource"-->
                                                            <!--                layerId="myLayer"-->
                                                            <!--                :layer="geoJsonlayer"-->
                                                            <!--            />-->
                                                            <MglMarker
                                                                :coordinates.sync="markerCoordinates"
                                                                color="blue"
                                                                :draggable="true"
                                                                @dragend="emitBtn = true"
                                                            />

                                                        </MglMap>
                                                        <!--        <v-btn-->
                                                        <!--            v-click-outside="emitBtn=false"-->
                                                        <!--            @click="emitLocation"-->
                                                        <!--        >-->
                                                        <!--            Set Location!-->
                                                        <!--        </v-btn>-->

                                                    </div>
                                                </v-card>
                                            </v-col>

                                        </v-row>
                                        <!--                                        </v-card>-->
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                @click="e1 = 2"
                                            >
                                                Continue
                                            </v-btn>
                                        </v-card-actions>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <v-card
                                            class="mb-12"
                                        >
                                            <v-card-text>
                                                <v-container
                                                    class="grey lighten-5 mb-6 mx-auto" align="center"
                                                >
                                                    Please choose your desired plan according to following information:
                                                </v-container>
                                            </v-card-text>
                                            <template>
                                                <v-card flat>
                                                    <v-card-text>
                                                        <template v-if="form.plan == 'basic'">
                                                            <p class="blue--text ">Home Insurance coverages, </p>
                                                            <p class="blue--text ">Communication with existing alarm
                                                                system. </p>
                                                            <p class="font-weight-bold Price blue--text align-content-center">
                                                                Price: 10$ per month</p>
                                                        </template>
                                                        <template v-if="form.plan == 'silver'">
                                                            <p class="text-gray-600 ">Home Insurance coverages</p>
                                                            <p class="text-gray-600 ">Communication with existing alarm
                                                                system</p>
                                                            <p class="text-gray-600 ">Communication with new smart home
                                                                devices.</p>
                                                            <p class="font-weight-bold Price text-gray-600 align-content-center">
                                                                Price: 15$ per month.</p>
                                                        </template>
                                                        <template v-if="form.plan == 'gold'">

                                                            <p class="GoldText ">Home Insurance coverages</p>
                                                            <p class="GoldText ">Communication with existing alarm
                                                                system</p>
                                                            <p class="GoldText ">Communication with new smart home
                                                                devices</p>
                                                            <p class="GoldText ">Value added services (emergency water
                                                                leakage, …).</p>
                                                            <h1 class="font-weight-bold Price GoldText align-content-center">
                                                                Price: 20$ per month.</h1>
                                                        </template>
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    md="6"
                                                                >
                                                                    <v-radio-group
                                                                        v-model="form.plan"
                                                                        column
                                                                    >
                                                                        <v-radio
                                                                            class="blue--text"
                                                                            color="primary"
                                                                            value="basic"
                                                                        >
                                                                            <template v-slot:label>
                                                                                <div><strong
                                                                                    class="primary--text">Basic</strong>
                                                                                </div>
                                                                            </template>
                                                                        </v-radio>
                                                                        <v-radio

                                                                            color="silver"
                                                                            value="silver"
                                                                        >
                                                                            <template v-slot:label>
                                                                                <div><strong
                                                                                    class="silver--text">Silver</strong>
                                                                                </div>
                                                                            </template>
                                                                        </v-radio>
                                                                        <v-radio
                                                                            color="#fcc203"
                                                                            value="gold"
                                                                        >
                                                                            <template v-slot:label>
                                                                                <div><strong
                                                                                    class="GoldText">Gold</strong></div>
                                                                            </template>
                                                                        </v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                            </template>

                                        </v-card>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <!--                            <v-btn text class="m-1" :to="{name:'profiles'}">لغو کردن </v-btn>-->
                                            <!--                            <v-btn depressed color="success" class="m-1" href="https://idpay.ir/niomatic-kit" :loading="busy">ورود درگاه اهدای نقدی</v-btn>-->
                                            <v-btn :loading="busy" @click="dialogBuy='true'" class="m-1" color="success"
                                                   depressed>
                                                Buy
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="e1 = 1"
                                            >
                                                back
                                            </v-btn>
                                        </v-card-actions>
                                    </v-stepper-content>

                                </v-stepper-items>
                            </v-stepper>
                        </template>


                        <template>
                            <v-dialog
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition"
                                v-model="dialogUnit"
                            >
                                <v-card>
                                    <v-toolbar
                                        color="success"
                                        dark
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="dialogUnit = false"
                                            dark
                                            icon
                                        >
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <template>
                                        <v-card
                                            max-width="95%"
                                            class="mx-auto my-3"
                                        >
                                            <v-card-title>
                                                Please record the details of the people who are located in each unit or
                                                that unit is owned by them.
                                            </v-card-title>
                                            <v-form
                                                ref="form"
                                                v-model="valid"
                                                lazy-validation
                                            >

                                                <li v-for="oneunitform in formUnitArray">
                                                    <v-container
                                                        class="grey lighten-5 mb-6 mx-auto" align="center"
                                                    >
                                                        <v-layout align-center row wrap>
                                                            <v-flex>
                                                                <v-row
                                                                    class="mx-auto "
                                                                    no-gutters
                                                                    align="center" justify="space-around"
                                                                >
                                                                    <v-col
                                                                        lg="4"
                                                                        md="6"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="oneunitform.name"
                                                                            class="mx-3"
                                                                            :counter="20"
                                                                            label="name"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col
                                                                        lg="4"
                                                                        md="6"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="oneunitform.family"
                                                                            :counter="20"
                                                                            class="mx-3"
                                                                            label="Surname"
                                                                            required
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col
                                                                        lg="4"
                                                                        md="6"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="oneunitform.cell_number"
                                                                            :rules="cellnumRules"
                                                                            class="mx-3"
                                                                            label="Cell number or Email"
                                                                            required
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col
                                                                        lg="4"
                                                                        md="6"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="oneunitform.unit_Nom"
                                                                            class="mx-3"
                                                                            :rules="[v => !!v || 'Unit selection is required']"
                                                                            label="unit number"
                                                                            required
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col
                                                                        lg="4"
                                                                        md="6"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="oneunitform.area"
                                                                            class="mx-3"
                                                                            :rules="[v => !!v || 'State the unit area']"
                                                                            label="Unit area"
                                                                            required
                                                                        ></v-text-field>
                                                                        <v-divider></v-divider>
                                                                    </v-col>
                                                                    <v-col
                                                                        lg="4"
                                                                        md="6"
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            v-model="oneunitform.postal_code"
                                                                            class="mx-3"
                                                                            :rules="[v => !!v || 'Enter the postal code']"
                                                                            label="Postal code"
                                                                            required
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </li>

                                                <v-checkbox
                                                    v-model="checkbox"
                                                    :rules="[v => !!v || 'You must accept the terms to continue']"
                                                    label="Do you agree with our policy?"
                                                    required
                                                ></v-checkbox>

                                                <v-btn
                                                    :disabled="!valid"
                                                    color="success"
                                                    class="mr-4"
                                                    @click="validate"
                                                >
                                                    Save
                                                </v-btn>

                                                <v-btn
                                                    color="error"
                                                    class="mr-4"
                                                    @click="reset"
                                                >
                                                    Reset Form
                                                </v-btn>
                                            </v-form>
                                        </v-card>
                                    </template>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-dialog>
                        </template>
                    </template>

                    <template>
                        <v-dialog
                            v-model="dialogBuy"
                            width="500"
                        >

                            <v-card>
                                <v-card-title class="headline2 grey lighten-2">
                                    Building manager self-declaration form
                                </v-card-title>

                                <v-card-text>
                                    I confess that all the information entered is correct and Insurance will not
                                    be held responsible for any possible violations.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="addNewBuilding"
                                        color="green"
                                        text
                                    >
                                        Yes, I agree
                                    </v-btn>
                                    <v-btn
                                        @click="dialogBuy=false"
                                        color="red"
                                        text
                                    >
                                        No
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>

                    <template v-if="request=='Renew'">
                        <v-autocomplete
                            :items="building_set"
                            item-text="address"
                            item-value="id"
                            v-model="building_selected_id"
                            :error-messages="errors" label="Building selection"
                            placeholder="Please enter your building.">
                        </v-autocomplete>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="busy" @click="dialogExtend='true'" class="m-1" color="success" depressed>
                                Extend
                            </v-btn>
                        </v-card-actions>
                    </template>
                    <template>
                        <v-dialog
                            v-model="dialogExtend"
                            width="500"
                        >
                            <v-card>
                                <v-card-title class="headline grey lighten-2">
                                    Building Manager Renewal Form
                                </v-card-title>

                                <v-card-text>
                                    I confess that all the information entered is correct and  Insurance will not
                                    be held responsible for any possible violations.
                                </v-card-text>

                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="extendPlan"
                                        color="green"
                                        text
                                    >
                                        Yes, I agree
                                    </v-btn>
                                    <v-btn
                                        @click="dialogExtend=false"
                                        color="red"
                                        text
                                    >
                                        No
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-if="request=='Upgrade'">
                        <v-autocomplete
                            :items="building_set"
                            item-text="address"
                            item-value="id"
                            v-model="building_selected_id"
                            :error-messages="errors" label="Building selection"
                            placeholder="Please enter your building.">
                        </v-autocomplete>
                        <v-autocomplete
                            :items="['Normal', 'Silver', 'Gold']"
                            label="Choose your plan?"
                            v-model="changedPlan"
                        ></v-autocomplete>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="busy" @click="dialogChangePlan='true'" class="m-1" color="success"
                                   depressed>
                                Change the plan
                            </v-btn>
                        </v-card-actions>
                    </template>
                    <template>
                        <v-dialog
                            v-model="dialogChangePlan"
                            width="500"
                        >
                            <v-card>
                                <v-card-title class="headline grey lighten-2">
                                    Building plan change form
                                </v-card-title>

                                <v-card-text>
                                    I confess that all the information entered is correct and  Insurance will not
                                    be held responsible for any possible violations.
                                </v-card-text>

                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="changePlan"
                                        color="green"
                                        text
                                    >
                                        Yes, I agree.
                                    </v-btn>
                                    <v-btn
                                        @click="dialogChangePlan=false"
                                        color="red"
                                        text
                                    >
                                        No.
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                </template>
            </v-card-text>
        </v-card>
    </div>

</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    import AddrLocation from "./Map/AddrLocation";
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl, MglAttributionControl} from "vue-mapbox";



    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "OwnerCreate",
        components: {
            AddrLocation,
            FilePond,
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
            MglAttributionControl,
        },

        data: function () {
            return {
                coordinates: [25.2571136, 54.6800386],
                centers: [25.2571136, 54.6800386],
                markerCoordinates: [25.2571136, 54.6800386],
                markerCoordinates1: [[51.425296, 35.734379],[51.440296, 35.792379]],
                markerCoordinates2: [53.420296, 35.732379],
                // mapirToken: "<Your API Key>"
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",

                plan: '',
                e1: 1,
                changedPlan: '',
                building_set: [],
                building_selected_id: '',
                building_id: '',
                valid: true,

                nameRules: [
                    // v => !!v || 'نام لازم می باشد',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the name is 10',
                ],
                familyRules: [
                    v => !!v || 'Last name required',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the last name is 10',
                ],
                cellnumRules: [
                    v => !!v || 'Contact number or Email is required',
                    v => {
                        if (!v) return true;
                        const isPhoneNumber = v.length >= 11 && /^\d+$/.test(v);
                        const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v);
                        return isPhoneNumber || isEmail || 'Invalid contact number or email';
                    },
                ],
                select: null,
                items: [
                    'Item 1',
                    'Item 2',
                    'Item 3',
                    'Item 4',
                ],
                checkbox: false,
                //myFiles: ['index.html'],
                dialogUnit: false,
                dialogSupporter: false,
                dialogChangePlan: false,
                dialogExtend: false,
                dialogBuy: false,
                snackkbar: false,
                form:
                    {
                        'building_type': '',
                        'unit_num': '',
                        'infrastructure': '',
                        'age': '',
                        'total_area': '',
                        'address': '',
                        'pic': '',
                        'plan': '',
                        'latitude':'',
                        'longitude':'',
                    },
                formUnitArray: [],
                changepaln: '',
                errors: null,
                responses: null,
                busy: false,
                request: '',
            }
        },
        mounted() {

            // this.building_set = this.$store.state.customers.id;
            console.log('inside Buy');
            console.log(this.$store.state.customers.id);
            console.log('inside Buy');
            axios.get('citizens/' + this.$store.state.customers.id)
                .then(response => {

                    this.building_set = response.data;
                    // this.snackkbar = true;
                })
                .catch(errors => {
                    console.log(this.form);
                    this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
        },

        methods: {
            changeUnit() {
                this.dialogUnit = true;
                this.formUnitArray = [];
                for (let i = 0; i < this.form.unit_num; i++) {
                    this.formUnitArray.push({
                        'unit_Nom': '',
                        'name': '',
                        'family': '',
                        'cell_number': '',
                        'area': '',
                        'postal_code': ''
                    });
                }
            },
            validate() {

                if (this.$refs.form.validate()) {
                    this.dialogUnit = false;
                }
            },
            reset() {
                this.$refs.form.reset();
            },

            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.pic = file.serverId;
                console.log(this.form.fig);
            },
            addNewBuilding() {
                this.form.latitude = this.markerCoordinates[1];
                this.form.longitude = this.markerCoordinates[0];
                this.dialogSupporter = false;
                this.busy = true;
                axios.post('building', this.form)
                    .then(response => {
                        this.building_id = response.data.id;
                        let completeAdd = true;
                        for (let i = 0; i < this.form.unit_num; i++) {
                            axios.post('unit', $.extend(this.formUnitArray[i], {'building_id': this.building_id}))
                                .then(response => {
                                })
                                .catch(errors => {
                                    completeAdd = false
                                })
                        }
                        if (completeAdd) {
                            let currentTime = new Date();
                            let msg = {
                                'type': 'AddNewBuilding',
                                'status': 'submitted',
                                'issue_tracking': currentTime.getTime(),
                                'message': [{
                                    'userType': 'citizen',
                                    'id': '',
                                    'msg': 'New Building has been registered',
                                }],
                            };
                            axios.post('msg', msg)
                                .then(response => {
                                    this.dialogBuy = false;
                                    this.snackkbar = true;
                                })
                                .catch(errors => {
                                })
                        }


                    }).catch(errors => {
                    // console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    // console.log(this.errors);
                }).finally(() => {
                    this.busy = false;

                });
            },

            extendPlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ExtendPlan',
                    'status': 'submitted',
                    'issue_tracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'msg': 'Send request to extend plan.',
                    }],
                };
                axios.post('msg', msg)
                    .then(response => {
                        this.dialogExtend = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            },

            changePlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ChangePlan',
                    'status': 'submitted',
                    'issue_tracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'new_plan': this.changedPlan,
                        'msg': 'Request a change plan.',
                    }],
                };
                axios.post('msg', msg)
                    .then(response => {
                        this.dialogChangePlan = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .headline2 {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    .GoldText {
        color: #fcc203;
    }

    .Price {
        font-size: 1.5rem;
    }
</style>

