<template>

</template>

<script>
    export default {
        name: "Logout",
        created() {
            // localStorage.clear();
            localStorage.removeItem('isCustomerAuthenticated');
            axios.get('../logout-citizen',{})
                .finally(err=>{
                    let baseRoute = '../login';
                    if (this.$route.params.business) {
                        baseRoute = `../../customer/${this.$route.params.business}/login`;
                        // baseRoute = '/login';
                    }
                    window.location = baseRoute;
                })
        }
    }
</script>

<style scoped>

</style>
