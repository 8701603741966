<template>
    <v-card class="mx-auto my-2" width="97%">
        <div>
            <v-snackbar v-model="snackkbar" color="#f2c4d3" :timeout="5000" top>
            <span style="color: #333333;">
                Please refer to this section for detailed information about your sensor.
            </span>
                <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
            </v-snackbar>
            <v-snackbar v-model="snackkbarTask" color="#f2c4d3" :timeout="5000" centered>
            <span style="color: #333333;">
              This message has been assigned to the relevant individual.
            </span>
                <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
            </v-snackbar>
            <v-text-field
                v-model="search"
                label="Search"
                single-line
                hide-details
            >
            </v-text-field>
            <v-data-table
                :headers="headers"
                :items="sensors"
                :items-per-page="20"
                :search="search"
                :loading="busy"
                sort-by="access_level"
                :sort-desc="false"
                show-expand
                align="center"
                :footer-props="{
                showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'item per page',

      }"
            >
                <!--            @click:row="item=>$router.push({name:'users',params:{profile_id:item.id}})"-->
                <template #item.access_level="{value}">
                    {{getAccessLevel(value)}}
                </template>

                <template v-slot:item.location="{ item }">
                    <template v-if="item.access_level == 5">
                        <v-btn @click.prevent="EditData(item.id)" icon>
                            <v-icon>navigation</v-icon>
                        </v-btn>
                    </template>
                </template>

                <template #item.remove="{item}">
                    <!--                <template v-slot:activator="{ on, attrs }">-->
                    <!--                    <v-flex align-self-start>-->
                    <v-btn color="error"
                           rounded
                           @click="dialog = 'true', updateRemoveID(item.id)"
                           small>Remove
                    </v-btn>

                    <!--                    </v-flex>-->
                    <!--                </template>-->
                </template>

                <template #item.task="{item}">
                    <template v-if="item.access_level == 5">

                        <v-btn color="primary"
                               small
                               dark
                               rounded
                               @click.stop.prevent="dialogTask='true' , id = item.TimeID, uploadNumber(item)"
                        >
                            اختصاص
                        </v-btn>
                    </template>
                </template>
                <!--                <template v-if="item.access_level == 5">-->
                <!--                    <v-row justify="center">-->
                <!--
                                    </v-row>-->
                <!--                </template>-->
                <!--            </template>-->

                <template #item.edit="{item}">
                <!--                <v-row justify="center">-->
                <v-btn color="success"
                       small
                       dark
                       rounded
                       @click="uploadData(item)">Active
                </v-btn>
                <!--                </v-row>-->
            </template>
                <template #item.moreinfo="{item}">
                    <!--                <v-row justify="center">-->
                    <v-btn color="orange"
                           small
                           dark
                           rounded
                           :to="{name:'moduledata'}">Details
                    </v-btn>
                    <!--                </v-row>-->
                </template>

                <template #expanded-item="{header, item}">
                    <!--                <v-flex md6>-->
                    <!--                </v-flex>-->
                    <td :colspan="headers.length">
                        <v-spacer></v-spacer>

                        <v-container fluid>
                            <v-layout row wrap align="center" justify="center">
<!--                                <v-flex sm4 xs12 justify-center>-->
<!--                                    <v-avatar width="300px" height="100px">-->
<!--                                        &lt;!&ndash;                                    //                    this.images= '/storage/images/publics/'+this.dogs.fig;&ndash;&gt;-->
<!--                                        <v-img :src="'/storage/images/user/'+ item.pic" contain max-height="400px">-->

<!--                                            &lt;!&ndash;                                    <v-img :src="require('../../../assets/iamges/g8013.png')" contain&ndash;&gt;-->
<!--                                            &lt;!&ndash;                                           max-height="100px">&ndash;&gt;-->
<!--                                        </v-img>-->
<!--                                    </v-avatar>-->
<!--                                </v-flex>-->

                                <v-flex sm12 xs12 justify-center align-self-center>
                                    <v-text-field
                                        v-model="item.address"
                                        label="Address"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap v-if="item.access_level == 5">
                                <v-flex sm4 xs12>
                                    <v-text-field
                                        v-model="item.car_number"
                                        label="شماره پلاک"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                                <v-flex sm4 xs12>
                                    <v-text-field
                                        v-model="item.error_count"
                                        label="تعداد خطاهای"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                                <v-flex sm4 xs12>
                                    <v-text-field
                                        v-model="item.dog_cap_number"
                                        label="تعداد سگ های گرفته شده"
                                        readonly
                                        filled
                                        rounded
                                        dense
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </td>

                </template>
            </v-data-table>

            <v-dialog
                v-model="dialogTask"
                persistent
                max-width="600px"
                :retain-focus="false">

                <v-card class="my-auto" min-height="400px">
                    <v-card-title>
                        <span>اختصاص مکان جستجو</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    class="overflow-y-auto"
                                >

                                    <v-date-picker v-model="date"></v-date-picker>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <!--                                                <v-select :items="['ID100','ID101','ID102','ID103','ID104','ID105','ID106','ID107' ]" label="ای دی سگ" :error-messages="errors" placeholder="لطفا ای دی سگ مد نظر را وارد نمایید" v-model="form.dog_ID " ></v-select>-->

                                    <v-autocomplete
                                        :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]"
                                        label="منطقه ی جستجو را انتخاب نمایید."
                                        v-model="region"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        :items="region_section[region-1]"
                                        label="ناحیه ی جستجو را انتخاب نمایید."
                                        v-model="section"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                        <!--                                <small>*indicates required field</small>-->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red"
                            @click="dialogTask = false"
                        >
                            بستن
                        </v-btn>
                        <v-btn
                            color="green"
                            @click="SendSMStoUser()"

                        >
                            ذخیره
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialog"
                width="500"
                :retain-focus="false"
            >

                <v-card>
                    <v-card-title class="red white--text">
                        Are you sure you want to delete?
                    </v-card-title>
                    <v-card-text>
                        Once deleted, it is no longer possible to return the information. If you are sure, press the delete button, otherwise cancel.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click="destroyUserData()"
                        >
                            Remove
                        </v-btn>

                        <v-btn
                            color="green"
                            @click="dialog = false">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog
                v-model="dialog1"
                persistent
                max-width="600px"
                :retain-focus="false"
            >

                <v-card class="justify-center">
                    <v-card-title>
                        <span class="headline">اطلاعات کاربر</span>
                    </v-card-title>
                    <v-card class="justify-center">
                        <div id="filepond">
                            <file-pond

                                name="userPhoto"
                                ref="pond"
                                class-name="my-pond"
                                label-idle="در صورت نیاز، عکس را اپلود کنید"
                                allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png"
                                server="../user/upload"
                                v-on:processfile="handleProcessFile">
                            </file-pond>
                        </div>
                    </v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                        v-model="form.name"
                                        label="نام و نام خانوادگی*"
                                        hint="نام و نام خانوادگی"
                                        persistent-hint
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       sm="6"
                                       md="4">
                                    <v-text-field
                                        v-model="form.national_ID"
                                        label="کدملی*"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       sm="6"
                                       md="4">
                                    <v-text-field
                                        v-model="form.shenasname"
                                        label="شناسنامه*"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select
                                        v-model="form.access_level"
                                        :items="access_levels"
                                        label="سطح کاربر*"
                                        required
                                        dir="rtl"
                                    ></v-select>
                                </v-col>
                                <template v-if="form.access_level == 5">
                                    <v-col cols="12"
                                           sm="6"
                                           md="4">
                                        <v-text-field
                                            v-model="form.car_number"
                                            label="ماشین انتخاب شده قبلی"
                                            readonly
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-select
                                            v-model="form.car_number"
                                            :items="carnumbers"
                                            label="شماره ی پلاک جدید"
                                            dir="rtl"
                                        ></v-select>
                                    </v-col>
                                </template>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red"
                            @click="dialog1 = false"
                        >
                            بستن
                        </v-btn>
                        <v-btn
                            color="green"
                            @click="editUserData()"
                        >
                            ذخیره
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </v-card>

</template>

<script>
    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

    export default {
        name: "UsersIndex",

        components: {
            datePicker: VuePersianDatetimePicker
        },
        beforeCreate() {

            axios.get('../cars')
                .then(response => {
                    // this.responses = response.data[0].data.id;
                    // console.log(this.response);
                    // this.$router.go();
                    if (response.data.length) {
                        this.carnumbers[0] = response.data[0]['number'];
                        // this.carnumbers[0]['value'] = response.data[0]['id'];
                        let i;
                        for (i = 1; i < response.data.length; i++) {
                            // this.carnumbers.push({text:  response.data[i]['number'], value: response.data[i]['id']});
                            this.carnumbers.push(response.data[i]['number']);
                        }
                    }
                })
                .catch(errors => {
                    console.log(errors);
                }).finally(() => {
                    this.busy = false;
                }
            )
        },
        created() {
             this.fetchSensorData();
        },
        mounted() {


        },

        data: function () {
            return {
                id: '',
                region_section: [
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    [1, 2, 3, 4, 5, 6, 7, 8, 9],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4, 5, 6, 7, 8, 9],
                    [1, 2, 3, 4, 5, 6, 7],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4, 5],
                    [1, 2, 3],
                    [1, 2],
                    [1, 2, 3],
                    [1, 2, 3, 4],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4, 5, 6, 7, 8],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3],
                    [1, 2, 3, 4, 5, 6, 7],
                    [1, 2, 3, 4, 5],
                    [1, 2, 3, 4, 5, 6, 7],
                    [1, 2, 3],
                    [1, 2, 3, 4],
                ],
                date: '',
                region: '',
                section: '',
                taskcellnumber: '',
                url: 'https://api.kavenegar.com/v1/66533457526E5956554A42544752595A444A33673553577370664261662F2B70642B5251617474634D6A6B3D/verify/',
                // https://api.kavenegar.com/v1/{API-KEY}/verify/lookup.json
                dialog: false,
                dialog1: false,
                dialogTask: false,
                dialogVocation: false,
                fill: false,
                picker: false,
                gradient: gradients[4],
                gradients,
                padding: 8,
                radius: 10,
                value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
                width: 2,
                busy: false,
                search: '',
                users: [],
                buildings: [],
                sensors: [],
                removeUserID: '',
                editUserID: '',
                // carnumbers:[ {text: '', value: ''}],
                carnumbers: [],
                currentcarnumber: {text: '', value: ''},
                snackkbar: true,
                snackkbarTask: false,


                access_levels: [
                    {text: 'مدیریت', value: '1'},
                    {text: 'کارشناس', value: '2'},
                    {text: 'دکتر', value: '3'},
                    {text: 'کارشناس مسئول', value: '4'},
                    {text: 'تکنسین', value: '5'},
                    {text: 'مسئول تکنسین', value: '7'},
                    {text: 'حراست و بازرسی', value: '8'},
                    {text: 'فرد بدون سمت', value: '6'},
                ],

                headers: [
                    {   text: 'Unique module ID',
                        align: 'start',
                        value: 'unique_id'
                    },
                    {text: 'Type', value: 'type'},
                    {text: 'Cell number', value: 'cell_number'},
                    {text: 'Wifi SSID', value: 'wifi_ssid'},
                    {text: 'Wifi Password', value: 'wifi_password'},
                    {text: 'Remained charge', value: 'remained_charge'},
                    {text: 'Building ID', value: 'building_id'},
                    {text: 'Implemented date', value: 'implementation_date'},
                    {text: 'Status', value: 'edit', sortable: false},
                    {text: 'More Information', value: 'moreinfo', sortable: false},
                    {text: 'Remove', value: 'remove', sortable: false},
                ],
                formCar:
                    {
                        'driver': '', 'number': ''
                    },
                form:
                    {
                        'name': '',
                        'email': '',
                        'access_level': '',
                        'pic': '',
                        'national_ID': '',
                        'shenasname': '',
                        'car_number': '',
                        'error_count': '',
                        'dog_cap_number': '',
                        'car_ID': ''
                    },
            }
        },
        methods: {
            uploadNumber(form) {
                this.taskcellnumber = form.email;
            },
            SendSMStoUser() {
                let formSMS = {'receptor': '', 'token': '', 'token2': '', 'token3': '', 'template': ''};
                formSMS.receptor = this.taskcellnumber;
                formSMS.token = this.date;
                formSMS.token2 = this.region;
                formSMS.token3 = this.section;
                formSMS.template = 'task';

                axios.post('../users/sms', formSMS)
                    .then(response => {
                        this.snackkbarTask = true;
                        this.dialogTask = false
                    })
                    .catch(errors => {
                        // alert('errors');
                    }).finally(() => {
                    this.busy = false;
                });

            },
            //Edit Data
            updateEditID(id) {
                this.editUserID = id;
            },
            uploadData(userForm) {
                this.form = userForm;
                this.currentcarnumber.text = this.form.car_number;
                this.currentcarnumber.value = this.form.car_ID;

                // this.currentcarnumber['text'] = this.form.car_number;
                // this.currentcarnumber['value'] = this.form.car_ID;
            },
            handleProcessFile: function (error, file) {
                // this.form.first_fig = file.serverId;
                this.form.pic = file.serverId;
                // console.log(this.form.fig);
                console.log(this.form.first_fig);
            },

            editUserData() {
                // this.dialogAdd = false;
                // this.form.car_number = this.currentcarnumber.text;
                // this.form.car_ID = this.currentcarnumber.value;
                axios.put('../user/' + this.form.id, this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.response);
                        this.formCar.driver = this.form.id;
                        this.formCar.number = this.form.car_number;
                        // this.formCar.id = this.form.car_ID;
                        axios.put('../cars/update', this.formCar)
                            .then(responses => {
                                // this.responses = response.data[0].data.id;
                                console.log(this.responses);
                                // this.$router.push({name:'carindex'});
                            })
                        // this.$router.push({name:'carindex'});
                    })
                    .catch(errors => {
                        console.log(errors);
                    }).finally(() => {
                    this.dialog1 = false;
                    this.$router.go();
                });
            },


            //Delete Data
            updateRemoveID(id) {
                this.removeUserID = id;
            },
            destroyUserData() {
                axios.delete('../user/' + this.removeUserID)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'carindex'});
                    })
                    .catch(errors => {
                        console.log(errors);
                    }).finally(() => {
                    this.dialog = false;
                    this.$router.go();
                });
            },

            fetchSensorData() {
                this.busy = true;
                this.sensors = [];
                axios.get('citizensBuilding')
                    .then(response => {
                        this.buildings = response.data;
                        let sensorCount = 0;
                        for (let i = 0; i < this.buildings.length; i++) {
                            axios.get('buildingInfo/hardware/'+this.buildings[i].id)
                                .then(response => {
                                    for ( let j = 0; j < response.data.length; j++ )
                                    {
                                        response.data[j].address = this.buildings[i].address;
                                    }
                                    this.sensors = this.sensors.concat(response.data);

                                })
                        }
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            // axios.get('../dogdata/' + this.$route.params.id)
            //     .then(response =>{
            //         this.doginfoshow = response.data[0].data;
            //         //this.temp = response.data[0].data.race;
            //         //this.doginfoshow= response.data[0].data;
            //     })
            //     .catch(error =>{
            //
            //     });

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            EditData: function (id) {
                this.$router.push('../users/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            getAccessLevel(access_level) {
                if (access_level == "1") return 'مدیریت'
                else if (access_level == "2") return 'کارشناس'
                else if (access_level == "3") return 'دکتر آراد کوه'
                else if (access_level == "4") return 'کارشناس مسئول'
                else if (access_level == "5") return 'تکنسین'
                else if (access_level == "7") return 'مسئول تکنسین'
                else if (access_level == "8") return 'حراست و بازرسی'
                else return 'کاربر تایید نشده'
            },


        }
    }

</script>

<style scoped>

</style>


//Sensor information
<!--<template>-->
<!--    <div class="test">-->
<!--        <v-container-->
<!--            class="grey lighten-5 mb-6 mx-auto" align="center"-->
<!--        >-->
<!--            <v-layout align-center row wrap>-->
<!--                <v-flex>-->
<!--                    <v-row-->
<!--                        class="mx-auto "-->
<!--                        no-gutters-->
<!--                        align="center" justify="space-around"-->
<!--                    >-->
<!--                        <v-card-->

<!--                            class="mx-2 my-4"-->
<!--                            width="90%"-->
<!--                            rounded="3"-->
<!--                            elevation="3"-->
<!--                        >-->


<!--                            <v-card-title>Performance report</v-card-title>-->
<!--                            <template >-->
<!--                                <v-card-text >-->
<!--                                    <div>View the performance video report by selecting the date through the calendar below. </div>-->
<!--                                    <date-picker-->
<!--                                        v-model="date"-->
<!--                                        format="YYYY-MM-DD"-->
<!--                                        display-format="dddd jDD jMMMM jYYYY"></date-picker>-->

<!--                                    <v-card max-width="300pt" class="mx-auto my-6"  >-->
<!--                                        <v-row>-->
<!--                                            <v-card-text>-->
<!--                                                <v-select :items="users" item-text="name" itemid="id" return-object label="Choose a house"-->
<!--                                                          :error-messages="errors" placeholder="Choose a house" v-model="selected_user"></v-select>-->
<!--                                            </v-card-text>-->
<!--                                        </v-row>-->

<!--                                    </v-card>-->

<!--                                </v-card-text>-->

<!--                                <v-divider class="mx-4"></v-divider>-->
<!--                                <v-card-actions>-->

<!--                                    <v-btn-->
<!--                                        color="deep-purple lighten-2"-->
<!--                                        @click="ShowFigOfDog()"-->
<!--                                    >-->
<!--                                        Display-->
<!--                                    </v-btn>-->
<!--                                </v-card-actions>-->
<!--                            </template>-->

<!--                        </v-card>-->
<!--                    </v-row>-->
<!--                </v-flex>-->
<!--            </v-layout>-->
<!--            <template v-if="showImage">-->

<!--                <v-row  class="mx-auto">-->

<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        class="mx-auto"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="green"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            Number of errors recorded today: {{count}}-->
<!--                        </v-chip>-->
<!--                    </v-col>-->

<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        v-if="count"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="red"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            Number of times offline in the range of 6 to 11: {{last_login_error}}-->
<!--                        </v-chip>-->
<!--                    </v-col>-->


<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        v-if="count"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="orange"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            First time to take a photo: {{times[0]}}-->
<!--                        </v-chip>-->
<!--                    </v-col>-->

<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        v-if="count"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="blue"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            Last time online{{time}} minutes age-->
<!--                        </v-chip>-->
<!--                    </v-col>-->


<!--                </v-row>-->
<!--                <v-row >-->
<!--                    <v-alert-->
<!--                        width="100%"-->
<!--                        border="top"-->
<!--                        color="red lighten-2"-->
<!--                        dark-->
<!--                        v-if="count == 0"-->
<!--                    >-->
<!--                        Unfortunately, no information was recorded in your name on the selected day.-->
<!--                    </v-alert>-->
<!--                    <v-col  cols="12"-->
<!--                            class="justify-center"-->
<!--                            v-if="count"-->
<!--                    >-->
<!--                        <v-flex align-self-center align="center" >-->
<!--                            <v-card class="mx-auto rounded-form" outlined width="97%" height="500px">-->
<!--                                <template class="justify-center">-->
<!--                                    <v-app-bar-->
<!--                                        dark-->
<!--                                        color="green"-->
<!--                                        class="text-sm-center"-->
<!--                                    >-->
<!--                                        <v-toolbar-title  align="justify-center">-->
<!--                                            <span class=" text-sm">-->
<!--                                               Track map and position of errors taken-->
<!--                                            </span>-->
<!--                                        </v-toolbar-title>-->

<!--                                        <v-spacer></v-spacer>-->
<!--                                    </v-app-bar>-->
<!--                                    <v-card height="90%">-->
<!--                                        <TechnicianLocationRep :doglocations="locations"  :userlocations="markerCoordinates2"/>-->
<!--                                    </v-card>-->
<!--                                </template>-->


<!--                            </v-card>-->
<!--                        </v-flex>-->
<!--                    </v-col>-->
<!--                    <v-col-->
<!--                        v-for="n in count"-->
<!--                        :key="n"-->
<!--                        class="d-flex child-flex"-->
<!--                        cols="6"-->
<!--                        v-if="count"-->
<!--                    >-->

<!--                        <v-img-->
<!--                            :src="imagess[n-1]"-->
<!--                            :lazy-src="imagess[n-1]"-->
<!--                            aspect-ratio="1"-->
<!--                            class="grey lighten-2"-->
<!--                            @click="$router.push({name: 'capturedlocation', params:{latlong:locations[n-1], time:times[n-1]}})"-->
<!--                        >-->
<!--                            &lt;!&ndash;                            this.$router.push({name: 'public', params: {snackbarInprogress: true}});&ndash;&gt;-->
<!--                            <template v-slot:placeholder>-->
<!--                                <v-row-->
<!--                                    class="fill-height ma-0"-->
<!--                                    align="center"-->
<!--                                    justify="center"-->
<!--                                >-->
<!--                                    <v-progress-circular-->
<!--                                        indeterminate-->
<!--                                        color="grey lighten-5"-->
<!--                                    ></v-progress-circular>-->
<!--                                </v-row>-->
<!--                            </template>-->
<!--                        </v-img>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </template>-->
<!--        </v-container>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    // import AllUserLocation from "../views/User/AllUserLocation";-->

<!--    const gradients = [-->
<!--        ['#222'],-->
<!--        ['#42b3f4'],-->
<!--        ['red', 'orange', 'yellow'],-->
<!--        ['purple', 'violet'],-->
<!--        ['#00c6ff', '#F0F', '#FF0'],-->
<!--        ['#f72047', '#ffd200', '#1feaea'],-->
<!--    ]-->
<!--    import {VueFunnelGraph} from 'vue-funnel-graph-js';-->
<!--    // import LineChart from './LineChart.js'-->

<!--    import VueApexCharts from 'vue-apexcharts'-->
<!--    // import LiveTracking from "../views/LiveTracking";-->
<!--    import VueMoment from 'vue-jalali-moment';-->
<!--    import moment from 'jalali-moment/jalali-moment';-->
<!--    // import TechnicianLocationRep from "../views/Dog/TechnicianLocationRep";-->

<!--    // Vue.use(VueApexCharts);-->
<!--    // window.Apex.chart = { fontFamily: IRANSans !important;};-->

<!--    // Vue.component('apexchart', VueApexCharts);-->

<!--    export default {-->

<!--        components: {-->
<!--            VueFunnelGraph,-->
<!--            VueMoment,-->
<!--            apexchart: VueApexCharts,-->
<!--            moment-->
<!--        },-->
<!--        props:[-->
<!--            'user',-->
<!--        ],-->
<!--        data: () => ({-->
<!--            last_login_error:'',-->
<!--            markerCoordinates2:[],-->
<!--            moments:'',-->
<!--            users: [],-->
<!--            selected_user: '',-->
<!--            errors:'',-->
<!--            form:-->
<!--                {-->
<!--                    'name': '',-->
<!--                    'access_level': '',-->
<!--                    'national_ID': '',-->
<!--                    'shenasname': '',-->
<!--                    'tomorrow_vacation':'',-->
<!--                    'car_number' : '',-->
<!--                    'pic' : ''-->
<!--                },-->
<!--            count:'',-->
<!--            time:'',-->
<!--            imagess:[],-->
<!--            locations:[],-->
<!--            times:[],-->
<!--            showImage : false,-->
<!--            dateTommorow:'',-->
<!--            date:'',-->
<!--            datetoGregorian:'',-->
<!--            dialogVocation:false,-->


<!--            //Table-->

<!--            //-->
<!--            alignments: [-->
<!--                'start',-->
<!--                'center',-->
<!--                'end',-->

<!--            ],-->
<!--            //Sparkline-->
<!--            fill: false,-->
<!--            selectedGradient: gradients[4],-->
<!--            padding: 8,-->
<!--            radius: 10,-->
<!--            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],-->
<!--            width: 2,-->
<!--            //Area Chart 1-->
<!--            //-->
<!--            //radar-->

<!--            seriesRa: [{-->
<!--                data: [80, 50, 30, 40],-->
<!--            }],-->
<!--            chartOptionsRa: {-->
<!--                chart: {-->
<!--                    type: 'radar',-->
<!--                },-->

<!--                xaxis: {-->
<!--                    categories: ['واکسن 1', 'واکسن 2', 'واکسن 3', 'عقیم '],-->
<!--                    position:'top'-->
<!--                }-->
<!--            },-->

<!--            //-->
<!--            access_level:'',-->
<!--            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],-->
<!--            loaded: false,-->
<!--            chartdata: null,-->
<!--            // height: ,-->
<!--            lineCap: 'round',-->
<!--            gradient: gradients[5],-->
<!--            number:[4,2,1,1,1,1],-->
<!--            gradientDirection: 'top',-->
<!--            gradients,-->
<!--            type: 'trend',-->
<!--            autoLineWidth: false,-->

<!--            labels: ['Impressions', 'Add To Cart', 'Buy'],-->
<!--            // subLabels: ['Direct', 'Social Media', 'Ads'],-->
<!--            values: [-->
<!--                // with the given Labels and SubLabels here's what the values represent:-->
<!--                //-->
<!--                // Direct, Social, Ads-->
<!--                //    |      |     |-->
<!--                //    v      v     v-->
<!--                [3000], // Segments of "Impressions" from top to bottom-->
<!--                [1700], // Segments of "Add To Cart"-->
<!--                [600]   // Segments of "Buy"-->
<!--            ],-->
<!--            colors: [-->
<!--                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment-->
<!--                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment-->
<!--                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment-->
<!--            ],-->
<!--            direction: 'vertical',-->
<!--            gradientDirection2: 'horizontal',-->
<!--            height: 100,-->
<!--            width2: 200,-->

<!--            chartOptions: {-->
<!--                chart: {-->
<!--                    id: 'vuechart-example'-->
<!--                },-->
<!--                xaxis: {-->
<!--                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]-->
<!--                }-->
<!--            },-->
<!--            series: [{-->
<!--                name: 'series-1',-->
<!--                data: [30, 40, 35, 50, 49, 60, 70, 91]-->
<!--            }]-->


<!--        }),-->

<!--        // data() {-->
<!--        //     return {-->
<!--        //         labels: ['Impressions', 'Add To Cart', 'Buy'],-->
<!--        //         subLabels: ['Direct', 'Social Media', 'Ads'],-->
<!--        //         values: [-->
<!--        //             // with the given Labels and SubLabels here's what the values represent:-->
<!--        //             //-->
<!--        //             // Direct, Social, Ads-->
<!--        //             //    |      |     |-->
<!--        //             //    v      v     v-->
<!--        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom-->
<!--        //             [3000, 1700, 1000], // Segments of "Add To Cart"-->
<!--        //             [600,  200,  130]   // Segments of "Buy"-->
<!--        //         ],-->
<!--        //         colors: [-->
<!--        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment-->
<!--        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment-->
<!--        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment-->
<!--        //         ],-->
<!--        //         direction: 'horizontal',-->
<!--        //         gradientDirection: 'horizontal',-->
<!--        //         height: 300,-->
<!--        //         width: 800-->
<!--        //     };-->
<!--        // },-->
<!--        created(){-->
<!--            // console.log(this.$refs.funnel);-->
<!--            this.moments = moment('2021-01-11 17:01:26', 'YYYY-M-D HH:mm:ss').add(3,'hours',30,'minutes').locale('fa').format('YYYY/M/D HH:mm:ss');-->
<!--            this.dateTommorow = new Date();-->
<!--            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);-->


<!--        },-->
<!--        async mounted() {-->
<!--            // console.log(this.$refs.funnel.clientWidth);-->
<!--            // this.width = this.$refs.funnel.clientWidth;-->
<!--            this.fetchData();-->
<!--            this.loaded = false;-->
<!--            try {-->
<!--                const {userlist} = await fetch('/api/userlist')-->
<!--                this.chartdata = userlist-->
<!--                this.loaded = true-->
<!--            } catch (e) {-->
<!--                console.error(e)-->
<!--            };-->
<!--            this.access_level = this.getAccessLevel();-->
<!--        },-->
<!--        methods: {-->
<!--            fetchData() {-->
<!--                this.busy = true;-->
<!--                axios.get('/users/technicion')-->
<!--                    .then(response => {-->
<!--                        console.log(response.data);-->
<!--                        this.users = response.data;-->
<!--                    })-->
<!--                    .catch(errors => {-->
<!--                        alert('unable to fetch1');-->
<!--                    }).finally(() => {-->
<!--                    this.busy = false;-->
<!--                })-->
<!--            },-->
<!--            ShowFigOfDog(){-->
<!--                axios.post('../dogdatas/date' ,{'date': this.date, 'user': this.selected_user})-->
<!--                    .then(response =>{-->
<!--                        // this.dogs = response.data[0].data;-->
<!--                        console.log(response);-->
<!--                        let index;-->
<!--                        this.count = response.data.length;-->
<!--                        this.locations=[];-->
<!--                        this.markerCoordinates2= [];-->
<!--                        this.imagess=[];-->
<!--                        for (index = 0; index < response.data.length; ++index) {-->
<!--                            this.imagess[index] = '/storage/images/dogs/' + response.data[index].first_fig;-->
<!--                            this.locations[index] = [response.data[index].catched_lon , response.data[index].catched_lat ];-->
<!--                            this.times[index] = moment(response.data[index].created_at).locale('fa').format('YYYY/M/D HH:mm:ss');-->
<!--                        }-->
<!--                        this.showImage = true;-->
<!--                        // this.count = response.length;-->
<!--                        // '/storage/images/dogs/' +-->
<!--                        // this.imagess = response;-->
<!--                        let selected_id;-->
<!--                        if (this.selected_user=="")-->
<!--                        {-->
<!--                            // selected_id = this.$store.state.users.id;-->
<!--                            this.last_login_error = this.selected_user.last_login_error;-->
<!--                        }-->
<!--                        else-->
<!--                        {-->
<!--                            selected_id = this.selected_user.id;-->
<!--                            this.last_login_error = 0;-->
<!--                        }-->
<!--                        axios.get('/usertrack/' + selected_id)-->
<!--                            .then(response =>{-->
<!--                                let userslocation = response.data;-->
<!--                                let i;-->
<!--                                this.time = userslocation[0]["Time"];-->
<!--                                for (i = 0; i < userslocation.length; i++) {-->
<!--                                    this.markerCoordinates2[i] = userslocation[i]['LatLong'];-->
<!--                                }-->
<!--                            })-->
<!--                            .catch(errors =>{-->
<!--                                alert('unable to fetch track');-->
<!--                            }).finally(()=>{-->
<!--                            this.busy = false;-->
<!--                        })-->

<!--                        // this.last_login_error = this.users[selected_id].last_login_error;-->

<!--                    })-->
<!--                    .catch(errors =>{-->
<!--                        alert('لطفا زنده گیر را انتخاب نمایید');-->
<!--                    }).finally(()=>{-->
<!--                    this.busy = false;-->

<!--                })-->
<!--            },-->
<!--            increase() {-->
<!--                this.height += 10-->
<!--            },-->

<!--            EditVacation: function ( num ) {-->
<!--                this.dialogVocation = false;-->
<!--                this.form.tomorrow_vacation = num;-->
<!--                // axios.put('../user/'+ this.$store.state.users.id , this.form)-->
<!--                //     .then(response => {-->
<!--                //         this.$router.push({name: 'dashboard'});-->
<!--                //     })-->
<!--                //     .catch(errors => {-->
<!--                //         console.log(this.form);-->
<!--                //         // this.errors = errors.response.data.errors;-->
<!--                //         console.log(this.errors);-->
<!--                //     }).finally(() => {-->
<!--                //     this.busy = false;-->
<!--                // });-->
<!--            },-->

<!--            getAccessLevel() {-->
<!--                // if (this.$store.state.users.access_level == "1") return 'مدیریت'-->
<!--                // else if (this.$store.state.users.access_level == "2") return 'کارشناس'-->
<!--                // else if (this.$store.state.users.access_level == "3") return 'دکتر آراد کوه'-->
<!--                // else if (this.$store.state.users.access_level == "4") return 'کارشناس مسئول'-->
<!--                // else if (this.$store.state.users.access_level == "5") return 'تکنسین'-->
<!--                // else return 'کاربر تایید نشده'-->
<!--            },-->
<!--        },-->
<!--        computed: {-->
<!--            graphWidth() {-->
<!--                return this.$refs.funnel.clientWidth;-->
<!--            },-->
<!--            myStyles() {-->
<!--                return {-->
<!--                    height: `${this.height}px`,-->
<!--                    position: 'relative'-->
<!--                }-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->
<!--    .test {-->
<!--        font-family: IRANSans !important;-->
<!--        color: #3490dc;-->
<!--    }-->
<!--    .v-card-text {-->
<!--        font-family: IRANSans !important;-->
<!--    }-->
<!--    .v-container {-->
<!--        font-family: IRANSans !important;-->
<!--    }-->
<!--    .rounded-form {-->
<!--        border-radius: 20px !important;-->
<!--    }-->
<!--    .test{-->
<!--        font-size: 0.875rem;-->
<!--    }-->
<!--    .toolbar__title,-->
<!--    .tabs__container {-->
<!--        background-color: rgba(255, 255, 255, 0.2);-->
<!--        justify-content: flex-start !important;-->
<!--        max-width: 600px;-->
<!--        width: 100%;-->
<!--    }-->

<!--    .centered-input >>> input {-->
<!--        text-align: center-->
<!--    }-->
<!--</style>-->
