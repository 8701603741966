<template>
    <div class="pt-16">

        <v-alert type="error" v-model="alert" style="color: #333333;">
            {{ errorMessage }}
        </v-alert>

        <v-snackbar v-model="snackbar" :timeout="3000" color="#f2c4d3">
            {{ snackbarMessage }}
            <v-btn color="pink" text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
        <v-card class="mx-auto rounded-form" max-width="90%" outlined>
            <v-card-title class="layout justify-center title-wrap" >
                Smart Wizard for Building Smart Property Insurance Software
            </v-card-title>
            <v-card-subtitle class="layout justify-center mb-10">
                Only three steps are needed to build your venture by yourself.
            </v-card-subtitle>
            <template class="justify-center mx-auto">
                <template class="mx-5">
                    <v-stepper v-model="element" class="stepper-style">
                        <v-card class="rounded-form mx-auto mt-10" width="90%">
                            <v-stepper-header
                            >
                                <v-stepper-step
                                    :complete="element > 1"
                                    step="1"
                                >
                                    General Information
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step
                                    :complete="element > 2"
                                    step="2"
                                >
                                    Services
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                    :complete="element > 3"
                                    step="3"
                                >
                                    Contact information
                                </v-stepper-step>
                            </v-stepper-header>
                        </v-card>


                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-auto" style="width: 80%">
                                <v-form ref="form" v-model="valid" lazy-validation
                                >
                                    <v-row>

                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field label="Company"
                                                          v-model="form.name"
                                                          :rules="nameRules"
                                                          placeholder="Enter the name of your company."
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model="uniqueAddress"
                                                :rules="addressRules"
                                                label="Unique address"
                                                placeholder="Please choose a unique address for your software address."
                                                ref="uniqueAddress"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model="uniqueBusinessAddress"
                                                :rules="addressBusinessRules"
                                                label="Business web address"
                                                placeholder="Please write web address for business side"
                                                ref="uniqueBusinessAddress"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model="uniqueCustomerAddress"
                                                :rules="addressCustomerRules"
                                                label="Customer web address"
                                                placeholder="Please write web address for customer side"
                                                ref="uniqueCustomerAddress"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-autocomplete
                                                multiple
                                                :items="['English', 'Lithuanian', 'German', 'Persian']"
                                                label="Choose your platform language?"
                                                v-model="language"
                                                :rules="[ v => (v && v.length > 0) || 'Language is required' ]"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model="form.website"
                                                label="Website"
                                                placeholder="Please mention your official website."
                                                :rules="[  v => !!v || 'Website is required',
                                                v => validURL(v) || 'Invalid website format']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model="form.cell_number"
                                                :rules="cellnumRules"
                                                label="Number"
                                                placeholder="Please mention your official number."
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="justify-center"
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model="uniqueEmailAddress"
                                                label="Manager"
                                                :rules="[
                                                v => !!v || 'Email is required',
                                                v => validEmail(v) || 'Invalid email format',
                                                v => this.isUniqueEmailAddress || this.emailError || 'Address must be unique',
                                                ]"
                                                placeholder="Please mention the official email of manager."
                                                ref="uniqueEmailAddress"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="9"
                                            class="mx-auto"
                                        >
                                            <div id="filepondBuy">
                                                <file-pond
                                                    accepted-file-types="image/jpeg, image/png"
                                                    allow-multiple="false"
                                                    class-name="my-pond"
                                                    label-idle="Please upload your Logo... "
                                                    name="test"
                                                    ref="pond"
                                                    server="../business/upload"
                                                    v-on:processfile="handleProcessFile"
                                                >
                                                </file-pond>
                                                <v-alert v-if="clickValidateFormOne && !isFileUploaded" type="error">
                                                    {{ fileErrorMessage }}
                                                </v-alert>
                                            </div>
                                        </v-col>


                                    </v-row>
                                    <!--                                        </v-card>-->
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="success"
                                            @click="validateFormOne"
                                            class="text-capitalize"
                                        >
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-form ref="form_2" v-model="valid2" lazy-validation
                                >
                                    <v-card
                                        class="mb-12"
                                    >
                                        <v-card-text>
                                            <v-container
                                                class="grey lighten-5 mb-6 mx-auto" align="center"
                                            >
                                                Please choose your desired plan according to following information:
                                            </v-container>
                                        </v-card-text>
                                        <template>
                                            <v-card flat>
                                                <v-card-text>
                                                    <v-container fluid>
                                                        <v-row>
                                                            <Plans @plan="updatePlan"></Plans>
                                                            <v-col
                                                                cols="12">

                                                                <v-card
                                                                    class="mx-auto rounded-form"
                                                                    color="#4A148C"
                                                                    dark
                                                                >


                                                                    <v-card-title>
                                                                        <v-icon
                                                                            large
                                                                            left
                                                                        >

                                                                        </v-icon>
                                                                        <span class="text-h6 font-weight-bold">Insurance services</span>
                                                                    </v-card-title>
                                                                    <v-card-subtitle
                                                                        class="text-h7 font-weight-light">
                                                                        please select among the available services that
                                                                        you
                                                                        would like to have!
                                                                    </v-card-subtitle>

                                                                    <v-card-text>
                                                                        <v-container fluid>
                                                                            <v-row>
                                                                                <v-col cols="12" sm="4" md="4"
                                                                                       v-for="(service, index) in insuranceServices"
                                                                                       :key="index">
                                                                                    <v-switch
                                                                                        v-model="service.value"
                                                                                        :label="service.label"
                                                                                        color="green"
                                                                                        hide-details
                                                                                    ></v-switch>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>

                                                            <v-col
                                                                cols="12"
                                                            >

                                                                <v-card
                                                                    class="mx-auto rounded-form"
                                                                    color="#311B92"
                                                                    dark

                                                                >


                                                                    <v-card-title>
                                                                        <v-icon
                                                                            large
                                                                            left
                                                                        >

                                                                        </v-icon>
                                                                        <span class="text-h6 font-weight-bold">Customer services</span>
                                                                    </v-card-title>
                                                                    <v-card-subtitle
                                                                        class="text-h7 font-weight-light">
                                                                        please select among the available services that
                                                                        your
                                                                        customer would like to have!
                                                                    </v-card-subtitle>

                                                                    <v-card-text>
                                                                        <v-container fluid>
                                                                            <v-row>
                                                                                <v-col cols="12" sm="4" md="4"
                                                                                       v-for="(service, index) in customerServices"
                                                                                       :key="index">
                                                                                    <v-switch
                                                                                        v-model="service.value"
                                                                                        :label="service.label"
                                                                                        color="green"
                                                                                        hide-details
                                                                                    ></v-switch>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-container>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                            </v-card>
                                        </template>

                                    </v-card>

                                    <v-card-actions>
                                        <v-btn
                                            color="orange"
                                            @click="element = 1"
                                            class="text-capitalize"
                                        >
                                            back
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn :loading="busy" @click="validateFormTwo " class="m-1 text-capitalize"
                                               color="success"
                                               depressed>
                                            Next Step
                                        </v-btn>
                                    </v-card-actions>
                                    <v-card-actions>
                                        <v-alert type="error" v-if="hasErrorTwo" dense>
                                            <ul style="padding: 0; list-style-position: inside;">
                                                <li v-for="(message, index) in errorMessagePageTwo" :key="index"
                                                    style="margin-bottom: 5px;">
                                                    {{ message }}
                                                </li>
                                            </ul>
                                        </v-alert>
                                    </v-card-actions>

                                </v-form>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <v-form ref="form_3" v-model="valid3" lazy-validation>
                                    <v-card
                                        class="mb-12"
                                    >
                                        <template>
                                            <v-card flat>
                                                <v-card-text>

                                                    <v-container fluid>

                                                        <v-row>
                                                            <v-col
                                                                class="justify-center"
                                                                cols="12"
                                                                sm="6"
                                                            >
                                                                <v-autocomplete
                                                                    v-model="form.region"
                                                                    :items="['North America', 'South America', 'Europe', 'Africa', 'MENA', 'South and Southeast Asia', 'East Asia']"
                                                                    label="What is your geographical location?"
                                                                    :rules="regionRules"
                                                                    placeholder="Your geographical location"
                                                                >

                                                                </v-autocomplete>

                                                            </v-col>
                                                            <v-col
                                                                class="justify-center"
                                                                cols="12"
                                                                sm="6"
                                                            >
                                                                <v-text-field
                                                                    label="What is the country of your headquarter?"
                                                                    placeholder="Your country."
                                                                    v-model="form.country"
                                                                    :rules="countryRules"
                                                                >
                                                                </v-text-field>
                                                            </v-col>

                                                            <v-col
                                                                class="justify-center"
                                                                cols="12"
                                                                sm="6"
                                                            >
                                                                <v-text-field
                                                                    label="What is the city of your headquarter?"
                                                                    placeholder="Your city."
                                                                    v-model="form.city"
                                                                    :rules="cityRules"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col
                                                                class="justify-center"
                                                                cols="12"
                                                                sm="6"
                                                            >
                                                                <v-text-field
                                                                    label="What is the postal code of your headquarter?"
                                                                    placeholder="Your postal code"
                                                                    v-model="form.postal_code"
                                                                    :rules="postalCodeRules"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col
                                                                cols="12">
                                                                <v-text-field label="Address"
                                                                              placeholder="Enter the exact address of the building."
                                                                              v-model="form.address"
                                                                              :rules="postalAddressRules"
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                            </v-card>
                                        </template>

                                    </v-card>

                                    <v-card-actions>
                                        <v-btn
                                            color="orange"
                                            @click="element = 2"
                                            class="text-capitalize"
                                        >
                                            back
                                        </v-btn>

                                        <v-spacer></v-spacer>
                                        <v-btn :loading="busy" @click="validateFormThree" class="m-1 text-capitalize"
                                               color="success"
                                               depressed>
                                            Finish
                                        </v-btn>

                                    </v-card-actions>
                                </v-form>
                            </v-stepper-content>

                        </v-stepper-items>
                    </v-stepper>

                </template>
            </template>
        </v-card>
    </div>

</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    import {
        mapir,
        mapMarker,
        mapPopup,
        mapGeojsonLayer,
        mapGeolocateControl,
        mapNavigationControl,
        mapAttributionControl
    } from "mapir-vue";
    import Plans from "../Dashboard/InsurerProfile/Components/NewApplication/Plans";


    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "OwnerCreate",
        components: {
            Plans,
            FilePond,
            mapir,
            mapMarker,
            mapPopup,
            mapGeojsonLayer,
            mapGeolocateControl,
            mapNavigationControl,
            mapAttributionControl,
        },
        computed: {
            // addressRules() {
            //     return [
            //         v => !!v || 'Address is required',
            //         v => (v && v.length >= 4 && v.length <= 50) || 'Address length must be between 4 and 50 characters',
            //         v => (v && /^[a-zA-Z]/.test(v)) || 'Address must start with a letter',
            //         v => (v && !/\s/.test(v)) || 'Address cannot contain spaces',
            //         v => this.isUniqueAddress || this.nameError || 'Address must be unique',
            //     ];
            // },
            fileErrorMessage() {
                return this.isFileUploaded ? '' : 'Logo upload is required';
            },
            fileRules() {
                return [
                    v => !!v || 'File is required',
                    v => this.isFileUploaded || 'File upload is required'
                ];
            },
            selectedInsuranceServices() {
                return this.insuranceServices
                    .filter(service => service.value)
                    .map(service => service.label);
            },
            selectedCustomerServices() {
                return this.customerServices
                    .filter(service => service.value)
                    .map(service => service.label);
            },
            language: {
                get() {
                    try {
                        // Convert the JSON string to an array
                        return JSON.parse(this.form.language);
                    } catch (error) {
                        // If the conversion fails, return an empty array
                        return [];
                    }
                },
                set(value) {
                    // Convert the array to a JSON string
                    this.form.language = JSON.stringify(value);
                },
            },
        },
        watch: {
            isUniqueCustomerAddress() {
                this.$refs.uniqueCustomerAddress.validate();
            },
            isUniqueBusinessAddress() {
                this.$refs.uniqueBusinessAddress.validate();
            },
            isUniqueAddress() {
                this.$refs.uniqueAddress.validate();
            },
            isUniqueEmailAddress() {
                this.$refs.uniqueEmailAddress.validate();
            },
            selectedInsuranceServices(newVal) {
                this.form.insurance_services = JSON.stringify(newVal);
            },
            selectedCustomerServices(newVal) {
                this.form.customer_services = JSON.stringify(newVal);
            },
            uniqueCustomerAddress(newVal, oldVal) {
                if (newVal.length > 5 && newVal !== oldVal) {
                    this.checkUniqueCustomerAddress();
                }
            },
            uniqueBusinessAddress(newVal, oldVal) {
                if (newVal.length > 5 && newVal !== oldVal) {
                    this.checkUniqueBusinessAddress();
                }
            },
            uniqueAddress(newVal, oldVal) {
                if (newVal.length > 3 && newVal !== oldVal) {
                    this.checkUniqueAddress();
                }
            },
            uniqueEmailAddress(newVal, oldVal) {
                if (newVal.length > 3 && newVal !== oldVal) {
                    this.checkUniqueEmailAddress();
                }
            }
            // uniqueAddress() {
            //     if (this.uniqueAddress.length > 3) {
            //         this.uniqueAddressCheckPending = true;
            //         axios.post('../business/validate_unique_name', {unique_address: this.uniqueAddress})
            //             .then(() => {
            //                 this.nameError = '';
            //                 this.isUniqueAddress = true;
            //             })
            //             .catch((error) => {
            //                 if (error.response.status === 422) {
            //                     this.nameError = error.response.data.errors.name[0];
            //                     this.isUniqueAddress = false;
            //                 }
            //             })
            //             .finally(() => {
            //                 this.uniqueAddressCheckPending = false;
            //             });
            //     }
            // }
        },
        data: function () {
            return {

                hasErrorTwo: false,
                errorMessagePageTwo: '',
                clickValidateFormOne: false,
                nameCustomerError: '',
                nameBusinessError: '',
                nameError: '',
                isUniqueCustomerAddress: true,
                isUniqueBusinessAddress: true,
                isUniqueAddress: true,
                isUniqueEmailAddress: true,
                isFileUploaded: false,
                uniqueCustomerAddress: '',
                uniqueBusinessAddress: '',
                uniqueAddress: '',
                uniqueEmailAddress: '',
                uniqueAddressCheckPending: false,
                customerServices: [
                    {label: 'Chat bot', value: false},
                    {label: 'Insurance advice', value: false},
                    {label: 'Maintenance service', value: false},
                ],
                insuranceServices: [
                    {label: 'Billing Services', value: false},
                    {label: 'Risk management', value: false},
                    {label: 'Fraud detection', value: false},
                    {label: 'Ticketing', value: false},
                    {label: 'Policy generation', value: false},
                    {label: 'Claim management', value: false},
                ],
                email: {'email': '',},
                form: {
                    'name': '',
                    'unique_address': '',
                    'logo': '',
                    'cell_number': '',
                    'language': JSON.stringify([]),
                    'plan_type': '',
                    'plan_end': '',
                    'insurance_services': JSON.stringify([]),
                    'customer_services': JSON.stringify([]),
                    'region': '',
                    'country': '',
                    'city': '',
                    'postal_code': '',
                    'address': '',
                    'website': ''
                },
                coordinates: [51.420296, 35.732379],
                centers: [51.420296, 35.732379],
                markerCoordinates: [51.420296, 35.732379],
                markerCoordinates1: [[51.425296, 35.734379], [51.440296, 35.792379]],
                markerCoordinates2: [53.420296, 35.732379],
                // mapirToken: "<Your API Key>"
                mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjI5ZDk5YjY3YWM5ODMzMDg4YzViZWY3OTFlNzgyNzUzZDk3NDYxNzUxMzQ0NmZlMDdkZjQ4NmZkYmYyNDEyYzk2NDg4MjE5MDY1NDU2NTIxIn0.eyJhdWQiOiIxMTAyNSIsImp0aSI6IjI5ZDk5YjY3YWM5ODMzMDg4YzViZWY3OTFlNzgyNzUzZDk3NDYxNzUxMzQ0NmZlMDdkZjQ4NmZkYmYyNDEyYzk2NDg4MjE5MDY1NDU2NTIxIiwiaWF0IjoxNjAxMzc5ODU3LCJuYmYiOjE2MDEzNzk4NTcsImV4cCI6MTYwMzg4NTQ1Nywic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.tyDRemEvf2jL-t4OXadIpGWoq3OpLQF8hMU_LDSfYMvb00TEyo0njISeSLydHTYDGTMkNVd-3y__w3GNYi24MmT5ZNkeiYH-lvijsneDXe4JDBLG2R1agwFviWHcYX_E3RiBb5Lqm_vI-my48CjB4-Z8uve1n3NuMcHp8mi1VXeosR0LXy65Km1WOIS1-PJw1amwiSL5FycOqHTqeM7cA8uxuwRZvMz5vBJrT6bZ_0tjCrzz519v9lAmK1vlWFpsN4eymO5w2ss0b3WkTYse52cbQGIGgvlAbNwPtnDYxbYAZalB5xCMf3LqxU2O1WbnN7Jpaw6YiSXWBrTmgt305A",

                plan: '',
                element: 1,
                changedPlan: '',
                building_set: [],
                building_selected_id: '',
                building_id: '',
                valid: true,
                valid2: true,
                valid3: true,
                regionRules: [
                    v => !!v || ' Geographical location is required'
                ],
                countryRules: [
                    v => !!v || 'Country is required',
                    v => (v && v.length >= 2) || 'Country must be at least 2 characters long'
                ],
                cityRules: [
                    v => !!v || 'City is required',
                    v => (v && v.length >= 2) || 'City must be at least 2 characters long'
                ],
                postalCodeRules: [
                    v => !!v || 'Postal code is required',
                    v => (v && v.length >= 5) || 'Postal code must be valid'
                ],
                PostalAddressRules: [
                    v => !!v || 'Address is required',
                    v => (v && v.length >= 5) || 'Address must be at least 5 characters long'
                ],
                addressCustomerRules: [
                    v => !!v || 'Website is required',
                    v => this.validURL(v) || 'Invalid website format',
                    v => (v && v.length >= 4 && v.length <= 50) || 'Address length must be between 4 and 50 characters',
                    v => (v && /^[a-zA-Z]/.test(v)) || 'Address must start with a letter and cannot contain spaces',
                    v => (v && !/\s/.test(v)) || 'Address cannot contain spaces',
                    v => this.isUniqueCustomerAddress || this.nameCustomerError || 'Address must be unique',
                ],
                addressBusinessRules: [
                    v => !!v || 'Website is required',
                    v => this.validURL(v) || 'Invalid website format',
                    v => (v && v.length >= 4 && v.length <= 50) || 'Address length must be between 4 and 50 characters',
                    v => (v && /^[a-zA-Z]/.test(v)) || 'Address must start with a letter and cannot contain spaces',
                    v => (v && !/\s/.test(v)) || 'Address cannot contain spaces',
                    v => this.isUniqueBusinessAddress || this.nameBusinessError || 'Address must be unique',
                ],
                addressRules: [
                    v => !!v || 'Address is required',
                    v => (v && v.length >= 4 && v.length <= 50) || 'Address length must be between 4 and 50 characters',
                    v => (v && /^[a-zA-Z]/.test(v)) || 'Address must start with a letter and cannot contain spaces',
                    v => (v && !/\s/.test(v)) || 'Address cannot contain spaces',
                    v => this.isUniqueAddress || this.nameError || 'Address must be unique',
                ],


                nameRules: [
                    v => !!v || 'Company name is required',
                    v => (v && v.length <= 30) || 'The maximum number of characters in the name is 30',
                    v => (v && v.length >= 3) || 'The minimum number of characters in the name is 3',
                ],

                familyRules: [
                    v => !!v || 'Last name required',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the last name is 10',
                ],
                cellnumRules: [
                    v => !!v || 'Contact number is required',
                    v => (v && v.length > 10 && v.length < 16) || ' Contact number must be valid',
                ],
                select: null,
                items: [
                    'Item 1',
                    'Item 2',
                    'Item 3',
                    'Item 4',
                ],
                checkbox: false,
                //myFiles: ['index.html'],
                dialogUnit: false,
                dialogSupporter: false,
                dialogChangePlan: false,
                dialogExtend: false,
                dialogBuy: false,
                snackbar: false,
                snackbarError: false,
                errorMessage: '',
                alert: false,
                snackbarMessage: '',
                formUnitArray: [],
                changepaln: '',
                errors: null,
                responses: null,
                busy: false,
                request: '',
            }
        },
        mounted() {

            // this.building_set = this.$store.state.customers.id;
            axios.get('../citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.building_set = response.data;
                    // this.snackkbar = true;
                })
                .catch(errors => {
                    console.log(this.form);
                    this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
        },

        methods: {
            validateFormThree() {

                if (this.$refs.form_3.validate()) {
                    this.createApplication();
                }
            },
            validateFormTwo() {
                this.errorMessagePageTwo = [];
                this.hasErrorTwo = false;

                let insuranceServices = JSON.parse(this.form.insurance_services);
                let customerServices = JSON.parse(this.form.customer_services);

                if (!insuranceServices.length) {
                    this.errorMessagePageTwo.push('At least one insurance service is needed.');
                    this.hasErrorTwo = true;
                }
                if (!customerServices.length) {
                    this.errorMessagePageTwo.push('At least one customer service is needed.');
                    this.hasErrorTwo = true;
                }
                if (!this.form.plan_type) {
                    this.errorMessagePageTwo.push('Plan must be selected');
                    this.hasErrorTwo = true;
                }
                if (!this.hasErrorTwo) {
                    this.element = 3;
                }
                return !this.hasErrorTwo;
            },
            checkUniqueEmailAddress() {
                axios.post('../business/validate_unique_email', {email: this.uniqueEmailAddress})
                    .then(() => {
                        this.emailError = '';
                        this.isUniqueEmailAddress = true;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.emailError = error.response.data.errors.email[0];
                            this.isUniqueEmailAddress = false;
                        }
                    });
            },
            checkUniqueCustomerAddress() {
                axios.post('../business/validate_unique_customer_address', {custom_domain: this.uniqueCustomerAddress})
                    .then(() => {
                        this.nameCustomerError = '';
                        this.isUniqueCustomerAddress = true;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.nameCustomerError = error.response.data.errors.unique_address[0];
                            this.isUniqueCustomerAddress = false;
                        }
                    });
            },
            checkUniqueBusinessAddress() {
                axios.post('../business/validate_unique_business_address', {custom_domain: this.uniqueBusinessAddress})
                    .then(() => {
                        this.nameBusinessError = '';
                        this.isUniqueBusinessAddress = true;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.nameBusinessError = error.response.data.errors.unique_address[0];
                            this.isUniqueBusinessAddress = false;
                        }
                    });
            },
            checkUniqueAddress() {
                axios.post('../business/validate_unique_name', {unique_address: this.uniqueAddress})
                    .then(() => {
                        this.nameError = '';
                        this.isUniqueAddress = true;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.nameError = error.response.data.errors.unique_address[0];
                            this.isUniqueAddress = false;
                        }
                    });
            },
            validateFormOne() {
                this.clickValidateFormOne = true;
                if (this.$refs.form.validate() && this.isFileUploaded) {
                    this.element = 2;
                }
            },
            validEmail(email) {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            validURL(str) {
                var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?' + // port
                    '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
                    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
                return !!pattern.test(str);
            },
            createApplication() {
                this.form.unique_address = this.uniqueAddress;
                axios.post(`../business/newappliction/register`, this.form)
                    .then(response => {
                        console.log(response);
                        console.log('user info is registered');
                        let business = response.data.business;
                        console.log(business);
                        console.log(business.unique_address);
                        axios.post(`../tenant`, {
                            business_id: business.id,
                            custom_domain: this.uniqueCustomerAddress,
                            tenant_type: 'customer'
                        })
                            .then(response => {
                                console.log(response);
                                axios.post(`../tenant`, {
                                    business_id: business.id,
                                    custom_domain: this.uniqueBusinessAddress,
                                    tenant_type: 'business'
                                })
                                    .then(response => {
                                        console.log(response);
                                        axios.post(`../business/developer/@${business.unique_address}/register`, {email: this.uniqueEmailAddress})
                                            .then(response => {
                                                console.log(response);
                                                console.log('user info is registered');
                                                this.snackbar = true;
                                                this.snackbarMessage = "Your smart homeowner insurance software has been successfully created.";

                                                // Redirect to dashboard page after 3 seconds
                                                setTimeout(() => {
                                                    this.$router.push({name: 'dashboard'});
                                                }, 5000);
                                            })
                                            .catch(error => {
                                                console.error(error);
                                                if (error.response.status === 404) {
                                                    console.log('Business not found');
                                                } else if (error.response.status === 500) {
                                                    console.log('Server error');
                                                }
                                            });
                                    })
                                    .catch(error => {

                                    });

                            })
                            .catch(error => {

                            });
                    })
                    .catch(error => {
                        // handle any errors that occur during the request
                        if (error.response.status === 422) { // if there is a validation error
                            let errors = error.response.data.errors;
                            this.errorMessage = Object.values(errors).join(', ');
                            this.alert = true;
                        } else {
                            // Handle other types of errors
                            this.errorMessage = 'This operation is not successfully proceeded';
                            this.alert = true;

                        }
                        console.error(error);
                    });

                this.$store.commit("updateApplication", 'okey');
                console.log(this.$store.state.Application_set);
                console.log(this.dialogBuy);

            },
            updatePlan(variable) {
                console.log(variable);
                this.form.plan_type = variable;
                let currentDate = new Date();
                currentDate.setFullYear(currentDate.getFullYear() + 1);
                let year = currentDate.getFullYear();
                let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // Add one because months are 0-indexed, and pad with leading 0 if necessary
                let day = currentDate.getDate().toString().padStart(2, '0');  // Pad with leading 0 if necessary
                this.form.plan_end = `${year}-${month}-${day}`;
            },
            validate() {

                if (this.$refs.form.validate()) {
                    this.dialogUnit = false;
                }
            },
            reset() {
                this.$refs.form.reset();
            },

            handleProcessFile: function (error, file) {
                if (file) {
                    this.isFileUploaded = true;

                } else {
                    this.isFileUploaded = false;
                }
                this.form.logo = file ? file.serverId : '';
                console.log(this.form.logo);
            },
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .stepper >>> .stepper-elevation > {
        border-radius-root: 28px !important;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    .stepper-style {
        elevation: below;
        box-shadow: blue;
    }

    .title-wrap {
        overflow-wrap: break-word; /* This helps to break long words */
        word-wrap: break-word;     /* This is an alias for overflow-wrap */
        white-space: normal;       /* This ensures that text wraps normally */
        word-break: keep-all; /* Prevents breaking words */
    }
</style>

