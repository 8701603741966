var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"#f2c4d3","timeout":5000,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticStyle:{"color":"#333333"}},[_vm._v("\n            Please view the detailed car information here.\n        ")])]),_vm._v(" "),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":20,"search":_vm.search,"loading":_vm.busy,"sort-by":"email","sort-desc":true,"align":"center"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Organization Message Box")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                            New Message\n                        ")])]}}]),model:{value:(_vm.dialogAdd),callback:function ($$v) {_vm.dialogAdd=$$v},expression:"dialogAdd"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Please enter car information.")])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Car Model*","hint":"Enter the model.","persistent-hint":"","required":""},model:{value:(_vm.newCar.name),callback:function ($$v) {_vm.$set(_vm.newCar, "name", $$v)},expression:"newCar.name"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Car License Plate*","hint":"11S111-IR 11","persistent-hint":"","required":""},model:{value:(_vm.newCar.number),callback:function ($$v) {_vm.$set(_vm.newCar, "number", $$v)},expression:"newCar.number"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"gray"},on:{"click":function($event){_vm.dialogAdd = false}}},[_vm._v("\n                                Cancel\n                            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.saveNewCarData}},[_vm._v("\n                                Save\n                            ")])],1)],1)],1)],1)]},proxy:true},{key:"item.access_level",fn:function(ref){
var value = ref.value;
return [_vm._v("\n            "+_vm._s(_vm.getAccessLevel(value))+"\n        ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.EditData(item.id)}}},[_c('v-icon',[_vm._v("navigation")])],1)]]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"500","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-flex',{attrs:{"align-self-start":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.updateRemoveID(item.id)}}},'v-btn',attrs,false),on),[_vm._v("Delete\n                        ")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"red white--text"},[_vm._v("\n                        Are you sure you want to delete?\n                    ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n                        Once deleted, it is no longer possible to retrieve the information. If you are sure, press\n                        the\n                        delete button, otherwise cancel.\n                    ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.destroyCar()}}},[_vm._v("\n                            Delete\n                        ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"gray"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                            Cancel\n                        ")])],1)],1)],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-flex',{attrs:{"align-self-center":"","width":"20px"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""},on:{"click":function($event){return _vm.uploadData(item.name, item.number, item.id)}}},'v-btn',attrs,false),on),[_vm._v("View\n                            ")])],1)]}}],null,true),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Car Information")])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Car Model*","hint":"Car Model","persistent-hint":"","required":""},model:{value:(_vm.editCar.name),callback:function ($$v) {_vm.$set(_vm.editCar, "name", $$v)},expression:"editCar.name"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Car License Plate*","required":""},model:{value:(_vm.editCar.number),callback:function ($$v) {_vm.$set(_vm.editCar, "number", $$v)},expression:"editCar.number"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"gray"},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v("\n                                Close\n                            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.editCarData()}}},[_vm._v("\n                                Save\n                            ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }