<!--<template>-->

<!--    <div class="test">-->
<!--        <v-container-->
<!--            class="grey lighten-5 mb-6 mx-auto" align="center"-->
<!--        >-->
<!--            <v-layout align-center row wrap>-->
<!--                <v-flex>-->
<!--                    <v-row-->
<!--                        class="mx-auto "-->
<!--                        no-gutters-->
<!--                        align="center" justify="space-around"-->
<!--                    >-->
<!--                        <v-card-->

<!--                            class="mx-2 my-4"-->
<!--                            width="90%"-->
<!--                            rounded="3"-->
<!--                            elevation="3"-->
<!--                        >-->
<!--                            <apexchart type="area" height="250" :options="chartOptionsChart1"-->
<!--                                       :series="seriesChart1">-->
<!--                            </apexchart>-->

<!--                            <v-card class="my-card">-->
<!--                                <v-card-title class="headline text-center">Performance Report</v-card-title>-->

<!--                                <v-card-text>-->
<!--                                    <div>Select a date to view the performance video report:</div>-->
<!--                                    <v-date-picker v-model="date" format="YYYY-MM-DD" class="my-3"></v-date-picker>-->
<!--                                </v-card-text>-->

<!--                                <v-divider class="mx-4 my-3"></v-divider>-->

<!--                                <v-card-actions class="justify-center">-->
<!--                                    <v-btn color="deep-purple lighten-2" @click="ShowFigOfDog">Display</v-btn>-->
<!--                                </v-card-actions>-->

<!--                                <v-card-title class="mt-3">-->
<!--                                    {{ messageType }}-->
<!--                                    <v-spacer></v-spacer>-->
<!--                                </v-card-title>-->

<!--                                <v-data-table :headers="headers" :items="messages" :search="search" :loading="busy" :items-per-page="20">-->
<!--                                    <template v-slot:item.id="{ item }">-->
<!--                                        <v-chip label color="blue lighten-4">{{ item.issue_tracking }}</v-chip>-->
<!--                                    </template>-->
<!--                                    <template v-slot:item.status="{ item }">-->
<!--                                        <v-icon :color="getColor(item.status)">{{ getIconMessage(item.status) }}</v-icon>-->
<!--                                        <v-chip :color="getColor(item.status)" dark>{{ getTextState(item.status) }}</v-chip>-->
<!--                                    </template>-->
<!--                                    <template v-slot:item.progress="{ item }">-->
<!--                                        <v-progress-circular :size="45" :value="getProgressValue(item.status)">{{ getProgressValue(item.status) }}%</v-progress-circular>-->
<!--                                    </template>-->
<!--                                    <template v-slot:item.topic="{ item }">{{ getTopicMessage(item.type) }}</template>-->
<!--                                    <template v-slot:item.lastupdate="{ item }">{{ getLastUpdateTime(item.issue_tracking) }}</template>-->
<!--                                    <template v-slot:item.edit="{ item }">-->
<!--                                        <v-btn :to="{name: 'showmessage', params: {message: item.message }}" icon>-->
<!--                                            <v-icon color="orange">edit</v-icon>-->
<!--                                        </v-btn>-->
<!--                                    </template>-->
<!--                                </v-data-table>-->
<!--                            </v-card>-->
<!--                        </v-card>-->


<!--                    </v-row>-->
<!--                </v-flex>-->
<!--            </v-layout>-->
<!--            <template v-if="showImage">-->

<!--                <v-row class="mx-auto">-->

<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        class="mx-auto"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="green"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            Number of errors recorded today: {{count}}-->
<!--                        </v-chip>-->
<!--                    </v-col>-->

<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        v-if="count"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="red"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            Number of times offline in the range of 6 to 11: {{last_login_error}}-->
<!--                        </v-chip>-->
<!--                    </v-col>-->


<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        v-if="count"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="orange"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            First time to take a photo: {{times[0]}}-->
<!--                        </v-chip>-->
<!--                    </v-col>-->

<!--                    <v-col-->
<!--                        cols="12"-->
<!--                        sm="6"-->
<!--                        v-if="count"-->
<!--                    >-->
<!--                        <v-chip-->
<!--                            class="ma-2"-->
<!--                            color="blue"-->
<!--                            text-color="white"-->
<!--                        >-->
<!--                            Last time online{{time}} minutes age-->
<!--                        </v-chip>-->
<!--                    </v-col>-->


<!--                </v-row>-->
<!--                <v-row>-->
<!--                    <v-alert-->
<!--                        width="100%"-->
<!--                        border="top"-->
<!--                        color="red lighten-2"-->
<!--                        dark-->
<!--                        v-if="count == 0"-->
<!--                    >-->
<!--                        &lt;!&ndash;                        Unfortunately, no information was recorded in your name on the selected day.&ndash;&gt;-->
<!--                    </v-alert>-->
<!--                    <v-col cols="12"-->
<!--                           class="justify-center"-->
<!--                           v-if="count"-->
<!--                    >-->
<!--                        <v-flex align-self-center align="center">-->
<!--                            <v-card class="mx-auto rounded-form" outlined width="97%" height="500px">-->
<!--                                <template class="justify-center">-->
<!--                                    <v-app-bar-->
<!--                                        dark-->
<!--                                        color="green"-->
<!--                                        class="text-sm-center"-->
<!--                                    >-->
<!--                                        <v-toolbar-title align="justify-center">-->
<!--                                            <span class=" text-sm">-->
<!--                                               Track map and position of errors taken-->
<!--                                            </span>-->
<!--                                        </v-toolbar-title>-->

<!--                                        <v-spacer></v-spacer>-->
<!--                                    </v-app-bar>-->
<!--                                    <v-card height="90%">-->
<!--                                        <TechnicianLocationRep :doglocations="locations"-->
<!--                                                               :userlocations="markerCoordinates2"/>-->
<!--                                    </v-card>-->
<!--                                </template>-->


<!--                            </v-card>-->
<!--                        </v-flex>-->
<!--                    </v-col>-->
<!--                    <v-col-->
<!--                        v-for="n in count"-->
<!--                        :key="n"-->
<!--                        class="d-flex child-flex"-->
<!--                        cols="6"-->
<!--                        v-if="count"-->
<!--                    >-->

<!--                        <v-img-->
<!--                            :src="imagess[n-1]"-->
<!--                            :lazy-src="imagess[n-1]"-->
<!--                            aspect-ratio="1"-->
<!--                            class="grey lighten-2"-->
<!--                            @click="$router.push({name: 'capturedlocation', params:{latlong:locations[n-1], time:times[n-1]}})"-->
<!--                        >-->
<!--                            &lt;!&ndash;                            this.$router.push({name: 'public', params: {snackbarInprogress: true}});&ndash;&gt;-->
<!--                            <template v-slot:placeholder>-->
<!--                                <v-row-->
<!--                                    class="fill-height ma-0"-->
<!--                                    align="center"-->
<!--                                    justify="center"-->
<!--                                >-->
<!--                                    <v-progress-circular-->
<!--                                        indeterminate-->
<!--                                        color="grey lighten-5"-->
<!--                                    ></v-progress-circular>-->
<!--                                </v-row>-->
<!--                            </template>-->
<!--                        </v-img>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </template>-->
<!--        </v-container>-->

<!--    </div>-->
<!--</template>-->

<template>
    <div class="performance-report-container">
        <v-container class="mx-auto my-6">
            <v-card class="mx-auto my-4" max-width="90%">
                <apexchart type="area" height="250" :options="chartOptionsChart1" :series="seriesChart1"></apexchart>

                <v-card-title class="text-center">Performance Report</v-card-title>
                <v-card-text>
                    Please select a date to generate the performance analysis report:
                    <v-date-picker v-model="date" format="YYYY-MM-DD" class="mt-3"></v-date-picker>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="deep-purple lighten-2" @click="ShowFigOfDog">Display</v-btn>
                </v-card-actions>

                <v-divider class="my-4"></v-divider>

                <v-card-title>{{ messageType }}</v-card-title>
                <v-data-table :headers="headers" :items="messages" :search="search" :loading="busy"
                              :items-per-page="20">
                    <template v-slot:item.id="{ item }">
                        <v-chip label color="blue lighten-4">{{ item.issue_tracking }}</v-chip>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-icon :color="getColor(item.status)">{{ getIconMessage(item.status) }}</v-icon>
                        <v-chip :color="getColor(item.status)" dark>{{ getTextState(item.status) }}</v-chip>
                    </template>
                    <template v-slot:item.progress="{ item }">
                        <v-progress-circular :size="45" :value="getProgressValue(item.status)">{{
                            getProgressValue(item.status) }}%
                        </v-progress-circular>
                    </template>
                    <template v-slot:item.topic="{ item }">{{ getTopicMessage(item.type) }}</template>
                    <template v-slot:item.lastupdate="{ item }">{{ getLastUpdateTime(item.issue_tracking) }}</template>
                    <template v-slot:item.edit="{ item }">
                        <v-btn :to="{name: 'showmessage', params: {message: item.message }}" icon>
                            <v-icon color="orange">edit</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
            <div class="performance-report-container">
                <v-container class="mx-auto my-6">
                    <!-- Existing Performance Report Card -->
                    <v-card class="mx-auto my-4" max-width="90%">
                        <!-- Performance Report Content -->
                    </v-card>

                    <!-- Safety Tips and Reminders Section -->
                    <v-card outlined tile class="pa-3 mx-auto my-4" max-width="90%">
                        <v-card-title class="headline text-center">
                            <v-icon large color="red">mdi-alert-circle</v-icon>
                            Safety Tips and Reminders
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list two-line subheader>
                            <v-subheader>What to do in case of a fire:</v-subheader>
                            <v-list-item-group color="primary">
                                <v-list-item v-for="(tip, index) in fireSafetyTips" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ tip.title }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ tip.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>

                            <v-divider class="my-4"></v-divider>

                            <v-subheader>Reminders for checking or maintaining detectors:</v-subheader>
                            <v-list-item-group color="secondary">
                                <v-list-item v-for="(reminder, index) in detectorReminders" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ reminder.title }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ reminder.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                    <div v-if="showImage" class="image-information-container">
                        <v-row class="mx-auto">
                            <!-- Information chips about errors and times -->
                            <template v-for="item in summaryItems">
                                <v-col cols="12" sm="6">
                                    <v-chip :color="item.color" text-color="white" class="ma-2">{{ item.text }}</v-chip>
                                </v-col>
                            </template>
                        </v-row>

                        <!-- Map and Error Position Section -->
                        <ErrorPositionSection :count="count" :locations="locations" :times="times"
                                              :imagess="imagess"></ErrorPositionSection>
                    </div>
                </v-container>
            </div>
        </v-container>
    </div>
</template>
<script>
    // import AllUserLocation from "../views/User/AllUserLocation";

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    // import LiveTracking from "../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    import moment from 'jalali-moment/jalali-moment';
    // import TechnicianLocationRep from "../views/Dog/TechnicianLocationRep";

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            VueMoment,
            apexchart: VueApexCharts,
            moment
        },
        props: [
            'user',
        ],
        data: () => ({
            fireSafetyTips: [
                {title: "Evacuate immediately", description: "Do not waste time saving property. Leave immediately."},
                {title: "Stay low", description: "Smoke rises to the ceiling. Stay low or crawl to your nearest exit."},
                {
                    title: "Check doors for heat",
                    description: "Use the back of your hand to feel the top of the door, doorknob, and the crack between the door and door frame before opening."
                },
            ],
            detectorReminders: [
                {title: "Test monthly", description: "Press the test button to ensure it is working."},
                {
                    title: "Change batteries",
                    description: "Replace the batteries at least once a year, or consider using 10-year batteries."
                },
                {
                    title: "Replace detectors",
                    description: "Replace the entire unit every 10 years or as recommended by the manufacturer."
                },
            ],
            id: '',
            busy: false,
            messages: [],
            search: '',
            messageType: 'Risk Signals List',
            headers: [
                {text: 'Message id', value: 'id'},
                {text: 'status', value: 'status'},
                {text: 'Risk', value: 'topic'},
                {text: 'Date', value: 'lastupdate',},
                {text: 'Time', value: 'edit', sortable: false,},
            ],

            chartOptionsChart1: {
                chart: {
                    type: 'area',
                    height: 250,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        dynamicAnimation: {
                            speed: 800
                        }
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true
                        },
                        autoSelected: 'zoom'
                    },
                    dropShadow: {
                        enabled: true,
                        opacity: 0.3,
                        blur: 5,
                        left: -7,
                        top: 22
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 3
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd MMM'
                    }
                },
                yaxis: {
                    labels: {
                        formatter: (val) => `${val.toFixed(2)} ppm` // Assuming ppm (parts per million) for smoke density
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    },
                    y: {
                        formatter: (val) => `${val} ppm`
                    }
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 7
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            height: 300
                        }
                    }
                }]
            },
            seriesChart1: [{
                name: 'Risk signals',
                data: [{
                    x: '1 Aug',
                    y: null
                },
                    {
                        x: '2 Aug',
                        y: 0
                    },
                    {
                        x: '3 Aug',
                        y: 1
                    },
                    {
                        x: '4 Aug',
                        y: 0
                    },
                    {
                        x: '5 Aug',
                        y: null
                    },
                    {
                        x: '6 Aug',
                        y: 0
                    },
                    {
                        x: '7 Aug',
                        y: 0
                    },
                    {
                        x: '8 Aug',
                        y: 0
                    },
                    {
                        x: '9 Aug',
                        y: 2
                    },
                    {
                        x: '10 Aug',
                        y: 0
                    },
                    {
                        x: '11 Aug',
                        y: 0
                    },
                    {
                        x: '12 Aug',
                        y: null
                    },
                    {
                        x: '13 Aug',
                        y: null
                    },
                    {
                        x: '14 Aug',
                        y: 0
                    },
                ],
            }],

            last_login_error: '',
            markerCoordinates2: [],
            moments: '',
            users: [],
            selected_user: '',
            errors: '',
            count: '',
            time: '',
            imagess: [],
            locations: [],
            times: [],
            showImage: false,
            dateTommorow: '',
            date: '',
            datetoGregorian: '',
            dialogVocation: false,
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },

                xaxis: {
                    categories: ['واکسن 1', 'واکسن 2', 'واکسن 3', 'عقیم '],
                    position: 'top'
                }
            },

            //
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        created() {
            this.moments = moment('2021-01-11 17:01:26', 'YYYY-M-D HH:mm:ss').add(3, 'hours', 30, 'minutes').locale('fa').format('YYYY/M/D HH:mm:ss');
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.fetchData();
            this.loaded = false;
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
        },
        methods: {


            fetchData() {
                this.busy = true;
                axios.get('../hardwares/1/')
                    .then(response => {
                        console.log(response);
                        this.messages = response;
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            getTopicMessage(item) {
                if (item == "AddNewBuilding") return 'New building'
                else if (item == "ExtendPlan") return 'Plan extension'
                else if (item == "ChangePlan") return 'Change plan'
                else if (item == "RequestService") return 'Request service'
                else if (item == "ReportDamage") return 'Damage report'
                else if (item == "Support") return 'Support'
            },
            getColor(state) {
                if (state == "submitted") return 'red'
                else if (state == "inprogress") return '#EC407A'
                else if (state == "professional_response") return '#F57C00'
                else if (state == "confirmation") return '#827717'
                else if (state == "closed") return 'green'
            },
            getLastUpdateTime(time) {
                let currentTime = new Date();
                let time_diff = Math.floor((currentTime.getTime() - time) / 60000);
                // return time_diff;
                if (parseInt(time_diff) < 60) {
                    return time_diff + " minutes age";
                } else if (parseInt(time_diff) < 1440) {
                    let hour = Math.floor(parseInt(time_diff) / 60);
                    let min = time_diff % 60;

                    return hour + " hour" + min + " minutes age";
                } else {
                    let hourTotal = Math.floor(parseInt(time_diff) / 60);
                    let days = Math.floor(hourTotal / 24);
                    let hour = time_diff % 24;

                    return days + " day" + hour + " hours ago";

                }
            },
            getProgressValue(state) {
                if (state == "submitted") return '0'
                else if (state == "inprogress") return '30'
                else if (state == "professional_response") return '50'
                else if (state == "confirmation") return '80'
                else if (state == "closed") return '100'
            },
            getIconMessage(state) {
                if (state == "submitted") return 'email'
                else if (state == "inprogress") return 'forward'
                else if (state == "professional_response") return 'explore'
                else if (state == "confirmation") return 'build'
                else if (state == "closed") return 'verified_user'
            },
            getTextState(state) {
                if (state == "submitted") return 'submitted'
                else if (state == "inprogress") return 'in progress'
                else if (state == "professional_response") return 'expert response'
                else if (state == "confirmation") return 'confirmation'
                else if (state == "closed") return 'closed'
            },

            fetchData() {
                this.busy = true;
                axios.get('/users/technicion')
                    .then(response => {
                        console.log(response.data);
                        this.users = response.data;
                    })
                    .catch(errors => {
                        alert('unable to fetch1');
                    }).finally(() => {
                    this.busy = false;
                })
            },
            ShowFigOfDog() {
                axios.post('../homedatas/date', {'date': this.date, 'user': this.selected_user})
                    .then(response => {
                        // this.dogs = response.data[0].data;
                        console.log(response);
                        let index;
                        this.count = response.data.length;
                        this.locations = [];
                        this.markerCoordinates2 = [];
                        this.imagess = [];
                        for (index = 0; index < response.data.length; ++index) {
                            this.imagess[index] = '/storage/images/dogs/' + response.data[index].first_fig;
                            this.locations[index] = [response.data[index].catched_lon, response.data[index].catched_lat];
                            this.times[index] = moment(response.data[index].created_at).locale('fa').format('YYYY/M/D HH:mm:ss');
                        }
                        this.showImage = true;
                        // this.count = response.length;
                        // '/storage/images/dogs/' +
                        // this.imagess = response;
                        let selected_id;
                        if (this.selected_user == "") {
                            // selected_id = this.$store.state.users.id;
                            this.last_login_error = this.selected_user.last_login_error;
                        } else {
                            selected_id = this.selected_user.id;
                            this.last_login_error = 0;
                        }
                        axios.get('/usertrack/' + selected_id)
                            .then(response => {
                                let userslocation = response.data;
                                let i;
                                this.time = userslocation[0]["Time"];
                                for (i = 0; i < userslocation.length; i++) {
                                    this.markerCoordinates2[i] = userslocation[i]['LatLong'];
                                }
                            })
                            .catch(errors => {
                            }).finally(() => {
                            this.busy = false;
                        })
                    })
                    .catch(errors => {
                    }).finally(() => {
                    this.busy = false;
                })
            },
        },
    }
</script>


<style scoped>
    .performance-report-container {
        font-family: 'IRANSans', sans-serif;
    }

    .image-information-container, .error-position-section {
        margin-top: 20px;
    }

    .my-card {
        border-radius: 15px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .safety-tips-container .v-card {
        background-color: #fff3e0; /* Light background to draw attention */
    }

    .v-icon {
        margin-right: 8px; /* Spacing between icon and text */
    }

    .v-divider {
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>
