<template style="word-break:break-word">
    <div>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            top
        >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    close
                </v-btn>
            </template>
        </v-snackbar>

        <template>
            <v-card width="100%"
                    height="500px"
                    v-if="isHeaderMedium || isHeaderLarge">
                <v-card
                    width="100%"
                    height="100%"
                    class="bg"
                >
                    <v-row class="my-20 ml-15">
                        <v-col cols="5" class=" mx-auto">
                            <v-card-subtitle class=" mt-6 text-center testHeader font-weight-bold">
                                Uniting the property industry with next-level collaboration!
                            </v-card-subtitle>
                            <v-card-subtitle class="mt-4 mx-auto text-center textHeaderDefinition ">
                                Transforming the property industry with powerful data insights and low-code
                                collaboration
                            </v-card-subtitle>
                            <v-card-actions class="mb-4 justify-center">
                                <v-btn
                                    rounded
                                    width="100px"
                                    color="#673147ff"
                                    class="white--text self-center"
                                    :to="{name:'demo'}"

                                >
                                    Get demo
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                        <v-col cols="7">
                            <v-img
                                contain
                                class="full-width mr-5"
                                v-if="isHeaderLarge"
                                :src="require('../../../assets/iamges/HeaderSoftware.svg')"
                                height="350"
                            ></v-img>
                            <v-img
                                contain
                                class="full-width mr-4"
                                v-if="isHeaderMedium"
                                min-width="300"
                                :src="require('../../../assets/iamges/HeaderSoftware.svg')"
                                height="350"

                            ></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>

            <v-card width="100%"
                    height="900px"
                    class="lightPurple"
                    v-if="isHeaderSmall">
                <v-row>
                    <v-card width="100%"
                            height="450px"
                            color="#d3b6c9ff"
                    >
                        <v-card-subtitle class=" my-10 text-center testHeader font-weight-bold">
                            Uniting the property industry with next-level collaboration!

                        </v-card-subtitle>
                        <v-card-subtitle class="my-10 mx-5 textHeaderDefinition ">
                            Transforming the property industry with powerful data insights and low-code
                            collaboration
                        </v-card-subtitle>

                        <v-card-actions class="mt-10 justify-center">
                            <v-btn
                                rounded
                                width="100px"
                                color="#673147ff"
                                class="white--text self-center"
                                :to="{name:'demo'}"
                            >
                                Get demo
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
                <v-row>
                    <v-card width="100%"
                            height="450px"
                    >
                        <v-img
                            contain
                            class="full-width my-8"
                            :src="require('../../../assets/iamges/HeaderSoftware.svg')"
                            height="350"

                        ></v-img>
                    </v-card>
                </v-row>

            </v-card>
        </template>

        <!--        Partners-->
        <v-container class="mt-10">

            <v-row class="justify-center mx-auto" no-gutters>
                <v-col
                    class="mx-auto"
                    cols="6"
                    md="3"
                    sm="3"
                    xs="6"
                >
                    <v-card width="90% "
                            max-width="200px"
                            class="black--text"
                            elevation="0"
                    >
                        <a href="https://hubbcast.com/">
                            <v-img
                                contain
                                class="full-width mx-auto"
                                :src="require('../../../assets/iamges/partners/insurmatics.svg')"
                                height="120px"
                            ></v-img>
                        </a>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="6"
                    md="3"
                    sm="3"
                    xs="6"
                >
                    <v-card width="90% "
                            max-width="200px"
                            class="black--text"
                            elevation="0"
                    >
                        <a href="https://selldone.com/">
                            <v-img
                                contain
                                class="full-width mr-5"
                                :src="require('../../../assets/iamges/partners/Selldone.svg')"
                                height="100px"
                            ></v-img>
                        </a>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="6"
                    md="3"
                    sm="3"
                    xs="6"
                >
                    <v-card width="90% "
                            max-width="200px"
                            class="black--text"
                            elevation="0"
                    >
                        <a href="https://hosa.ir/">
                            <v-img
                                contain
                                class="full-width mr-5"
                                :src="require('../../../assets/iamges/partners/HOSA.svg')"
                                height="100px"
                            ></v-img>
                        </a>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="6"
                    md="3"
                    sm="3"
                    xs="6"
                >
                    <v-card width="90% "
                            max-width="200px"
                            class="black--text"
                            elevation="0"
                    >
                        <a href="https://ioteedom.com/">
                            <v-img
                                contain
                                class="full-width mr-5"
                                :src="require('../../../assets/iamges/partners/IOTEEDOM.svg')"
                                height="130px"
                            ></v-img>
                        </a>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>


        <v-container class="mt-10 mb-30">
            <v-card-title class=" justify-center titleText "
                          style="word-break: normal"
            >Don't be left in the dark ages - join the data-driven property revolution with Hubbcast.
            </v-card-title>

            <v-card-title class="justify-center subtitleText"
            >
                <p style="font-size:16px; font-weight: normal;  text-align: justify; text-justify: inter-word; word-break: normal">
                    <span
                        style="color: #7b4a66ff; font-size:18px; font-weight: bold; font-family: Helvetica;">Hubbcast</span>
                    is the ultimate proptech platform. Say hello to
                    <span
                        style="color: #7b4a66ff; font-size:18px; font-weight: bold; font-family: Helvetica;">Hubbcast</span>,
                    the all-in-one solution for property
                    professionals looking to streamline workflows, boost productivity, and stay ahead of the curve. Our
                    mission is to transform property industry with data-driven decisions through technology and
                    collaboration.
                </p>
            </v-card-title>
            <v-row class="justify-center mx-auto">
                <v-col
                    class="mx-auto"
                    xs="12"
                    sm="12"
                    md="4"
                >
                    <v-card width="90% " min-width="280px"
                            color="#7b4a77ff"
                            class="white--text"
                            height="220px"
                            dark
                            elevation="24"
                            raised
                            shaped
                    >
                        <v-card-title>
                            Connectivity
                        </v-card-title>
                        <v-card-text>
                            Hubbcast connects players in the property industry, making it easier to collaborate and
                            share information. This leads to more efficient decision-making and better outcomes for all
                            involved..
                        </v-card-text>

                    </v-card>

                </v-col>
                <v-col
                    class="mx-auto"
                    xs="12"
                    sm="12"
                    md="4"
                >
                    <v-card width="90%" min-width="280px"
                            color="#7b4a66ff"
                            height="220px"
                            class="white--text"
                            dark
                            elevation="24"
                            raised
                            shaped
                    >
                        <v-card-title>
                            Efficiency
                        </v-card-title>
                        <v-card-text>
                            Hubbcast streamlines workflows and automates time-consuming tasks, freeing up valuable time
                            for property players to focus on more strategic initiatives.
                        </v-card-text>
                    </v-card>

                </v-col>
                <v-col
                    class="mx-auto"
                    xs="12"
                    sm="12"
                    md="4"
                >
                    <v-card width="90%" min-width="280px"
                            height="220px"
                            color="#7b4a4eff" class="white--text "
                            dark
                            elevation="24"
                            raised
                            shaped
                    >
                        <v-card-title>
                            Innovation
                        </v-card-title>
                        <v-card-text>
                            Hubbcast's low-code platform enables property players to create custom solutions tailored to
                            their unique needs without requiring extensive coding knowledge.
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>

        <v-divider></v-divider>

        <!--        Benefits and features-->
        <v-container class="mt-10 mb-30">
            <p style="font-size:20px; font-weight: Bold;  text-align: justify; text-justify: inter-word; color: #7b4a66ff">
                Features and benefits</p>
            <v-card-title class="justify-center titleText"
                          style="word-break: normal"
            >
                Discover the Power of Hubbcast: Innovative Features and Benefits for Streamlined Property Management.
            </v-card-title>
            <v-row class="justify-center mx-auto" no-gutters style="word-break: normal">
                <v-col
                    class="mx-auto"
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/AdvancedAnalytics.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            Advanced analytics
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast provides property industry players with valuable data insights and analysis to help
                            inform smarter, data-driven decisions.
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/LowCode.svg')"
                            height="70px"

                        ></v-img>

                        <v-card-title class="justify-center" style="word-break: break-word">
                            Low code platform
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast's low-code platform makes it easy for property industry players to adopt digital
                            tools and processes, even if they don't have extensive technical expertise.
                        </v-card-text>
                    </v-card>

                </v-col>

                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >

                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/Cloud.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            Cloud Technology
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            With Hubbcast's cloud, all of the platform's features and capabilities can be accessed from
                            anywhere, at any time, using any device which makes it easy for property industry players to
                            collaborate and share data.
                        </v-card-text>
                    </v-card>

                </v-col>

                <v-col
                    class="ma-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/UserCenteric.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            User-centric approach
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast's user-centric approach not only increases user engagement but also enhances user
                            satisfaction, making it a top-notch feature.
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>

            <v-row class="justify-center mx-auto" no-gutters>
                <v-col
                    class="mx-auto"
                    cols="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/UIUX.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            User-friendly interface
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast's intuitive interface makes it easy for property industry players to navigate the
                            platform and access the tools they need to succeed.
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/AutomatedWorkflows.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            Automated workflows
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast's automation tools streamline workflows and reduce manual data entry, freeing up
                            time and resources for more important tasks.
                        </v-card-text>
                    </v-card>

                </v-col>

                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >

                    <v-card width="90% "
                            class="black--text"
                            height="350px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/SustainabilitySolutions.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            Sustainability solutions
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast helps property industry players reduce their environmental impact by providing
                            energy management systems and other green building certifications.
                        </v-card-text>
                    </v-card>

                </v-col>

                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="3"
                    lg="3"
                    xl="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            height="300px"
                            elevation="0"
                    >
                        <v-img
                            contain
                            class="full-width"
                            :src="require('../../../assets/icons/Compatible.svg')"
                            height="70px"

                        ></v-img>
                        <v-card-title class="justify-center" style="word-break: normal">
                            Interoperability
                        </v-card-title>
                        <v-card-text style="word-break: normal" class="text-center">
                            Hubbcast can operate with different platforms and hardware protocols, allowing for seamless
                            integration
                            with your existing technology ecosystem.
                        </v-card-text>
                    </v-card>

                </v-col>

            </v-row>
        </v-container>
        <!--        HUB of propert-->
        <v-container class="mt-20 mb-30" style="word-break: normal">
            <v-card-title class="justify-center titleText" style="word-break: normal"
            >Hubbcast - The Hub for Property Insurers, Real Estate Providers, Developers, and Smart Home Manufacturers
            </v-card-title>
            <v-card-subtitle class="textHeaderDefinition text-center ">
                Connecting the Dots for a Smarter Property Industry!
            </v-card-subtitle>

            <v-row class="justify-center mx-auto" style="word-break: normal">
                <v-card width="90% "
                        class="black--text"
                        height="500px"
                        elevation="0"
                >
                    <v-img
                        contain
                        class="full-width"
                        :src="require('../../../assets/icons/HUB.svg')"
                        height="500px"

                    ></v-img>
                </v-card>
            </v-row>
            <v-row class="justify-center my-8">
                <v-card-text>
                    At Hubbcast, we understand the importance of collaboration and connectivity in the property
                    industry.
                    That's why we've developed a platform that serves as a hub between property insurers, real estate
                    providers, property developers, and smart home manufacturers. Our platform enables these players to
                    access valuable data insights, streamline their workflows, and make smarter, data-driven decisions.
                    With
                    Hubbcast, property players can stay ahead of the curve and thrive in a rapidly evolving market. Join
                    us
                    today and discover the power of collaboration.
                </v-card-text>
                <v-card-actions class=" justify-center">
                    <v-btn
                        rounded
                        width="100px"
                        color="#7b4a66ff"
                        class="white--text self-center"
                        :to="{name:'demo'}"

                    >
                        Get demo
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-container>
        <!--Steps-->
        <v-container class="mt-20 mb-30" style="word-break: normal">
            <v-card-title class="justify-center titleText" style="word-break: normal"
            >Transform Your Enterprise into a Digital Powerhouse in 3 Simple Steps with Hubbcast!
            </v-card-title>
            <v-card-subtitle class="mt-10">
                <p>
                    <strong>1- Connect:</strong> Join the hub and connect with the leading players in the property
                    industry, including
                    insurers, real estate providers, property developers, and smart home manufacturers.
                </p>
                <p>
                    <strong>2- Unlock Insights:</strong> Utilize Hubbcast's data-driven insights to gain a competitive
                    edge in the market
                    and make smarter, more informed decisions.
                </p>
                <p>
                    <strong> 3- Collaborate:</strong> Leverage our collaborative platform to streamline processes,
                    increase efficiency, and
                    drive innovation within your enterprise.
                </p>
            </v-card-subtitle>
            <v-row class="justify-center mx-auto" style="word-break: normal">
                <v-card width="90% "
                        class="black--text"
                        height="300px"
                        elevation="0"
                        v-if="isHeaderLarge"
                >
                    <v-img
                        contain
                        class="full-width"
                        :src="require('../../../assets/icons/StepHorizontal.svg')"
                        height="300px"

                    ></v-img>
                </v-card>

                <v-card width="90% "
                        class="black--text"
                        height="800px"
                        elevation="0"
                        v-if="isHeaderMedium || isHeaderSmall"
                >
                    <v-img
                        contain
                        class="full-width"
                        :src="require('../../../assets/icons/StepVertical.svg')"
                        height="800px"
                    ></v-img>
                </v-card>

            </v-row>

        </v-container>
        <!--        Lowcode-->
        <v-card
            width="100%"
            color="#583549ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                >
                    <v-card width="90% " min-width="280px"
                            color="#583549ff"
                            class="white--text"
                            height="400px"
                            dark
                            elevation="0"
                    >
                        <v-card-title style="word-break: break-word">
                            Low code platform for property players!
                        </v-card-title>
                        <v-card-text class="justify-center" style="word-break: normal">
                            Low-code platforms are becoming increasingly popular in the property development industry,
                            as they allow developers to build applications more quickly and efficiently. At Hubbcast, we
                            understand the importance of low-code development and offer a user-friendly platform that
                            empowers developers to create custom applications without extensive coding knowledge. Our
                            platform also provides valuable data insights to help developers make informed decisions and
                            stay ahead of industry trends. With Hubbcast, property developers can streamline their
                            workflow, improve efficiency, and stay competitive in a rapidly evolving market.
                        </v-card-text>
                        <v-btn
                            rounded
                            width="100px"
                            color="white"
                            class="brown--text ml-5"
                            :to="{name:'demo'}"
                        >
                            Get demo
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"

                >
                    <v-img contain
                           class="full-width"
                           :src="require('../../../assets/icons/BenefitOfLowCode.svg')"
                           height="400px">
                    </v-img>
                </v-col>
            </v-row>
        </v-card>

        <!--        Marketplace-->
        <v-card
            width="100%"
            color="#7b4a72ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"

                >
                    <v-img contain
                           class="full-width mx-auto mb-3"
                           :src="require('../../../assets/icons/MarketPlace.svg')"
                           max-width="85%"
                           max-height="400px">
                    </v-img>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                >
                    <v-card width="90% " min-width="280px"
                            color="#7b4a72ff"
                            class="white--text"
                            height="500px"
                            dark
                            elevation="0"
                    >
                        <v-card-title style="word-break: normal">
                            Marketplace for Smart Devices
                        </v-card-title>
                        <v-card-text class="justify-center" style="word-break: normal">
                            <p>At Hubbcast, we understand the importance of integrating smart devices into property
                            management. That's why we've created a marketplace for property players to easily discover
                            and purchase the latest smart devices and technology. From smart thermostats to security
                            systems, our marketplace offers a wide range of products that can help increase efficiency
                                and sustainability in the property industry.</p>

                            <p>With Hubbcast's marketplace, you can trust that all products are carefully curated and
                            vetted by our team. Plus, with easy integration with our low-code platform, you can quickly
                            and seamlessly connect your smart devices to our system and start seeing the benefits of
                                data-driven insights.</p>

                            <p>Whether you're a property developer or manager, our marketplace has something for everyone.
                                Start browsing today and discover the future of smart property management with Hubbcast.</p>
                        </v-card-text>
                        <v-btn
                            rounded
                            width="150px"
                            color="white"
                            class="purple--text ml-5"
                            :to="{name:'partner'}"
                        >
                            Be our partner
                        </v-btn>
                    </v-card>
                </v-col>

            </v-row>
        </v-card>
        <!--        Protocols-->
        <v-card
            width="100%"
            color="#7b4a64ff"
            class="white--text"
        >
            <v-card-title class="justify-center my-8">
                The protocols and technologies we support
            </v-card-title>
            <v-row class="justify-center mx-auto" no-gutters>

                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text mx-auto"
                            elevation="0"
                            color="#7b4a64ff"

                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/AMPQ.svg')"
                            height="45px"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/RestfulApi.svg')"
                            height="45px"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/mqtt.svg')"
                            height="40px"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/BLE.svg')"
                            height="45px"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                            height="45px"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/wifi.svg')"
                            height="100%"

                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                            height="45px"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/nb-iot.svg')"
                            height="100%"

                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                            height="45px"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/zigbee.svg')"
                            height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto my-8"
                    cols="4"
                    xs="4"
                    sm="3"
                    md="3"
                >
                    <v-card width="90% "
                            class="black--text"
                            elevation="0"
                            color="#7b4a64ff"
                            height="45px"
                    >
                        <v-img
                            contain
                            class="full-width mr-5"
                            :src="require('../../../assets/iamges/protocols/zwave.svg')"
                            height="100%"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <v-divider class="mx-4 "></v-divider>


    </div>

</template>

<script>

    import vueFilePond, {setOptions} from 'vue-filepond';
    // import headerSVG from '../../js/LandingPage/Icons/headerMediumSVG';

    let currentDate = Date.now()
    // setOptions({
    //     fileRenameFunction: (file) => {
    //         return currentDate + `${file.extension}`;
    //     }
    // })
    // setOptions({
    //     chunkUploads: true,
    //     chunkForce: true,
    //     server: {
    //         url: "/uploads",
    //         headers: {
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         process: {
    //             method: 'POST',
    //         },
    //     }
    // });
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize
        from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    // import PublicMessage from "./Components/PublicMessage";
    // import RegisterSupporters from "../Components/RegisterSupporters";
    // import RegisterVet from "../Components/RegisterVet";

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {
        created() {
            // if(navigator.geolocation){
            //     navigator.geolocation.getCurrentPos
            //     ition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // };
        },
        name: "landingPage",
        components: {
            // InputField,
            FilePond,
            // PublicMessage,
            // RegisterSupporters,
            // RegisterVet

        },
        props: {
            savingSuccessful: false,
            citizen: '',
        },
        data() {
            return {
                drawerMedium: false,
                drawerSmall: false,
                selectComponent: 1,
                video: {},
                canvas: {},
                captures: [],
                snackbar: false,
                text: 'Hello, I\'m a snackbar',
                sampleFig: '',
                picID2: '',
            }
        },
        data: function () {
            return {
                snackbarColor: '',
                tab: null,
                loading: false,
                valid: true,
                name: '',
                nameRules: [
                    v => !!v || 'نام و نام خانوادگی لازم می باشد',
                    v => (v && v.length <= 50) || 'حداکثر تعداد کاراکتر 50 عدد می باشد',
                ],
                cellNumber: '',
                cellNumberRules: [
                    v => !!v || 'شماره تماس الزامی می باشد',
                    v => (v && v.length == 11) || ' تعداد کاراکتر 11 عدد می باشد',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'وارد کردن ایمیل الزامی است',
                    v => /.+@.+\..+/.test(v) || 'شکل ایمیل را صحیح وارد نمایید.',
                ],
                selectedNotification: null,
                notificationItems: [
                    'hosa.ir',
                    'شبکه های اجتماعی و خبری',
                    'معرفی سازمانی',
                    'دوستان و همکاران',
                    'موارد دیگر',
                ],
                selectedJob: null,
                jobItems: [
                    'کارشناس یا مدیر شهرداری مرتبط با حیوانات شهری',
                    'کارشناس یا مدیر شهرداری دیگر بخش ها',
                    'شرکت خصوصی',
                    'حامی و علاقه مند به حیوانات شهری',
                    'موارد دیگر',
                ],
                comments: '',
                checkbox: false,
                drawer: false,
                drawerMedium: false,
                drawerSmall: false,
                selectComponent: 1,

                //myFiles: ['index.html'],
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'dateofadoption', 'address', 'comments'
                        'name': '', 'cellnumber': '', 'email': '', 'notification': '', 'comments': '', 'job': ''
                    },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                sampleFig: '',
                picID2: ''

            }
        },
        mounted() {
            // this.$store.commit("changeNameCitizen", this.citizen);
        },
        computed: {

            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
            // handleProcessFile: function(error, file) {
            //     this.form.fig = file.serverId;
            //     this.picID2 = file;
            //     alert(file.serverId);
            //     // this.$store.commit("updatePublicFig", file.serverId);
            //
            //     // this.setFigValue;
            //     // let response = JSON.parse(file.serverId);
            //     // this.$emit("response", response);
            //     // this.setFigValue(file.serverId);
            //     // console.log(this.form.fig);
            // },
            picID: function (file) {
                this.picID2 = file;
            }
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods: {
            sendFormData() {
                if (this.$refs.form.validate()) {
                    this.addNewDog();
                }
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            updateSetOption: function () {
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            // getCurrentLocation: function(){
            //     if(navigator.geolocation){
            //         navigator.geolocation.getCurrentPosition(position => {
            //             this.form.catched_lon = position.coords.longitude;
            //             this.form.catched_lat = position.coords.latitude;
            //         })
            //     }
            // },

            callback(msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function (error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            addNewDog() {
                this.busy = true;
                // this.getCurrentLocation();
                axios.post('../forms', this.form)
                    .then(response => {
                        console.log(response);
                        this.snackbar = true;
                        this.snackbarColor = 'green';
                        this.text = 'ثبت نام شما با موفقیت به پایان رسید. پس بررسی به اطلاع شما خواهد رسید';
                        // this.text = this.form.fig;
                    })
                    .catch(errors => {
                        // this.text = errors.response.data.errors;
                        this.snackbarColor = 'red';
                        this.text = 'شماره ی تماس تکراری است. به ازای هر ثبت نام، می بایست شماره تماس منحصر به فرد وجود داشته باشد.';
                        //'name':'','comments':'', 'cellnumber':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                        this.snackbar = true;
                        // this.text = 'لطفا فیلدها را کامل پرکنید';

                        //this.text = this.form.fig;
                        // console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(errors);
                    }).finally(() => {
                    this.busy = false;
                });

            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;
    }

    .test {
        font-size: 1em;
    }

    .testHeader {
        font-size: 1.5em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }

    .testToolbar {
        font-size: 1em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }

    .titleText {
        font-size: 1.5em;
        line-height: 30px;
        color: #000000;
    }

    .subtitleText {
        font-size: 1em;
        line-height: 30px;
        color: #000000;
    }

    .textHeaderDefinition {
        font-size: .9em;
        color: #999999;
    }

    .lightgray {
        background-color: whitesmoke !important;
    }

    .lightPurple {
        color: #e5d5ffff;
    }

    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/
    .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../../assets/icons/Header.svg") no-repeat center center;

        background-size: cover;
        transform: scale(1.1);
    }


    .smallbg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../../assets/icons/sample.png") no-repeat center center;
        background-size: cover;
        background-color: red;
        transform: scale(1.1);
    }
</style>
