<template style="word-break:break-word">
    <div>

        <!--        Why Hubbcast-->
        <v-card
            width="100%"
            color="#a36789ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">

            <v-col
                class="mx-auto"
                cols="12"
                xs="12"
                sm="12"
                md="8"
            >
                <v-card width="90% " min-width="280px"
                        color="#a36789ff"
                        class="white--text"
                        min-height="350px"
                        dark
                        elevation="0"
                >
                    <v-card-title style="word-break: break-word">
                        Why Hubbcast?
                    </v-card-title>
                    <v-card-text class="justify-center" style="word-break: normal">
                        <p class="font-weight-bold ">Innovators... Dreamers...</p>

                        At Hubbcast, we believe that the property industry can do better. It's a massive industry, estimated to be worth $228 trillion globally, but it's also highly fragmented and lacks transparency. Outdated practices, siloed data, and inefficient processes are holding it back from reaching its full potential.

                        That's where Hubbcast comes in. We're on a mission to revolutionize the game and help the industry move towards a more sustainable, connected, and data-driven future.
                        Join us on this journey towards a better, smarter, and more connected property industry. Contact us today to learn more about how we can help your business thrive.
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                class="mx-auto"
                cols="12"
                xs="12"
                sm="12"
                md="4"

            >
                <v-img contain
                       class="full-width"
                       :src="require('../../../assets/iamges/WhyInsurmatics/About Us.svg')"
                       min-height="200px"
                       max-height="400px">
                </v-img>
            </v-col>
        </v-row>

        </v-card>
<!--        Low code platform-->
        <v-card
        width="100%"
        color="#a3246cff"
        class="white--text"
    >
        <v-row class="justify-center mx-auto">
            <v-col
                class="mx-auto"
                cols="12"
                xs="12"
                sm="12"
                md="4"

            >
                <v-img contain
                       class="full-width"
                       :src="require('../../../assets/iamges/WhyInsurmatics/LowCodePlatform.svg')"
                       min-height="200px"
                       max-height="400px">
                </v-img>
            </v-col>
            <v-col
                class="mx-auto"
                cols="12"
                xs="12"
                sm="12"
                md="8"
            >
                <v-card width="90% " min-width="280px"
                        color="#a3246cff"
                        class="white--text"
                        min-height="350px"
                        dark
                        elevation="0"
                >
                    <v-card-title style="word-break: break-word">
                        Low-code platform
                    </v-card-title>
                    <v-card-text class="justify-center" style="word-break: normal">
                        <!--                            <p class="font-weight-bold ">Innovators... Dreamers...</p>-->
                        Low-code platforms are becoming increasingly popular because they can significantly reduce development time and cost, while still producing high-quality and scalable software. With a low-code platform, developers can focus on the application's business logic and user experience, while the platform takes care of the underlying technical details such as server configuration, database management, and security.

                        At Hubbcast, we leverage the power of low-code development to provide our clients with a platform that can quickly adapt to their evolving needs. Our low-code platform allows us to develop and deploy solutions much faster than traditional development methods, which ultimately means a faster time-to-market for our clients.

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
        <!--        AI-driven insights-->
        <v-card
            width="100%"
            color="#8228a3ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">


                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="8"
                >
                    <v-card width="90% " min-width="280px"
                            color="#8228a3ff"
                            class="white--text"
                            min-height="350px"
                            dark
                            elevation="0"
                    >
                        <v-card-title style="word-break: break-word">
                            AI-driven insights
                        </v-card-title>
                        <v-card-text class="justify-center" style="word-break: normal">
                            <!--                            <p class="font-weight-bold ">Innovators... Dreamers...</p>-->
                            At Hubbcast, we leverage AI to provide our clients with real-time data analysis and predictions that help them make more informed decisions.

                            Our AI algorithms can detect patterns and trends that may not be immediately apparent to humans, and can also identify correlations between different data points. This enables us to provide insights into areas such as customer behavior, market trends, and operational efficiency, allowing our clients to make data-driven decisions that can help them stay ahead of the competition.

                            Our AI-driven insights are powered by machine learning models that continually learn and improve over time, ensuring that our clients receive the most accurate and up-to-date information possible.
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"
                >
                    <v-img contain
                           class="full-width"
                           :src="require('../../../assets/iamges/WhyInsurmatics/AI-driven insights.svg')"
                           min-height="200px"
                           max-height="400px">
                    </v-img>
                </v-col>
            </v-row>
        </v-card>
<!--        Sustainability-->
        <v-card
            width="100%"
            color="#a36795ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"

                >
                    <v-img contain
                           class="full-width"
                           :src="require('../../../assets/iamges/WhyInsurmatics/Sustainability.svg')"
                           min-height="200px"
                           max-height="400px">
                    </v-img>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="8"
                >
                    <v-card width="90% " min-width="280px"
                            color="#a36795ff"
                            class="white--text"
                            min-height="350px"
                            dark
                            elevation="0"
                    >
                        <v-card-title style="word-break: break-word">
                            Sustainability
                        </v-card-title>
                        <v-card-text class="justify-center" style="word-break: normal">
                            <!--                            <p class="font-weight-bold ">Innovators... Dreamers...</p>-->
                            At Hubbcast, we believe that sustainability is a key pillar of the future of the property industry. The real estate sector accounts for a significant portion of global carbon emissions, and there is a growing urgency to take action to reduce these emissions.


                            Through our low-code platform and AI-driven insights, we are helping property companies to make data-driven decisions that prioritize sustainability. By leveraging technology to optimize energy efficiency, reduce waste, and improve the environmental impact of buildings, we are helping to create a more sustainable future for the industry.
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

        </v-card>

<!--        Collaboration-->

        <v-card
            width="100%"
            color="#632a88ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">

                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="8"
                >
                    <v-card width="90% " min-width="280px"
                            color="#632a88ff"
                            class="white--text"
                            min-height="350px"
                            dark
                            elevation="0"
                    >
                        <v-card-title style="word-break: break-word">
                            Collaboration
                        </v-card-title>
                        <v-card-text class="justify-center" style="word-break: normal">
                            <!--                            <p class="font-weight-bold ">Innovators... Dreamers...</p>-->
                            At Hubbcast, we believe that collaboration is key to unlocking the full potential of the property industry. Our platform enables collaboration between stakeholders, including developers, investors, brokers, and property managers, by providing a centralized location to access and share data and insights.

                            With the ability to easily collaborate and share information, stakeholders are able to make more informed decisions and work towards common goals. This promotes a more efficient and effective industry that benefits everyone involved.
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"

                >
                    <v-img contain
                           class="full-width"
                           :src="require('../../../assets/iamges/WhyInsurmatics/Collaboration.svg')"
                           max-height="400px"
                           min-height="200px">
                    </v-img>
                </v-col>
            </v-row>
        </v-card>



        <!--        541253ff-->
        <!--        Flexible insurance-->
<!--        <v-card-->
<!--            width="100%"-->
<!--            color="#541253ff"-->
<!--            class="white&#45;&#45;text"-->
<!--        >-->
<!--            <v-row class="justify-center mx-auto">-->
<!--                <v-col-->
<!--                    class="mx-auto"-->
<!--                    cols="12"-->
<!--                    xs="12"-->
<!--                    sm="6"-->
<!--                    md="6"-->
<!--                >-->
<!--                    <v-card width="90% " min-width="280px"-->
<!--                            color="#541253ff"-->
<!--                            class="white&#45;&#45;text"-->
<!--                            height="400px"-->
<!--                            dark-->
<!--                            elevation="0"-->
<!--                    >-->
<!--                        <v-card-title style="word-break: break-word">-->
<!--                            Flexible insurance policy-->
<!--                        </v-card-title>-->
<!--                        <v-card-text class="justify-center" style="word-break: normal">-->
<!--                            <p>╸ On-demand White-label product</p>-->
<!--                            <p>╸ Convenience of buying, issuing the policies, claims management, and related-->
<!--                                services </p>-->
<!--                            <p>╸ Valuable Data for Better risk management</p>-->
<!--                            <p>╸ Competitive advantage through saving time and cost and product customization</p>-->
<!--                            <p>╸ Value-added services for water leakage, clogged pipe, and the marketplace of smart-->
<!--                                devices.</p>-->

<!--                        </v-card-text>-->
<!--                        &lt;!&ndash;                        <v-btn&ndash;&gt;-->
<!--                        &lt;!&ndash;                            rounded&ndash;&gt;-->
<!--                        &lt;!&ndash;                            width="100px"&ndash;&gt;-->
<!--                        &lt;!&ndash;                            color="white"&ndash;&gt;-->
<!--                        &lt;!&ndash;                            class="purple&#45;&#45;text ml-5"&ndash;&gt;-->
<!--                        &lt;!&ndash;                        >&ndash;&gt;-->
<!--                        &lt;!&ndash;                            Get demo&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </v-btn>&ndash;&gt;-->
<!--                    </v-card>-->
<!--                </v-col>-->
<!--                <v-col-->
<!--                    class="mx-auto"-->
<!--                    cols="12"-->
<!--                    xs="12"-->
<!--                    sm="6"-->
<!--                    md="6"-->

<!--                >-->
<!--                    <v-img contain-->
<!--                           class="full-width"-->
<!--                           :src="require('../../../assets/iamges/WhyInsurmatics/homeinsurance.png')"-->
<!--                           height="400px">-->
<!--                    </v-img>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--        </v-card>-->


        <!--        Unleash new business model-->
        <v-card
            width="100%"
            color="#421254ff"
            class="white--text"
        >
            <v-row class="justify-center mx-auto">
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="4"

                >
                    <v-img contain
                           class="full-width mx-auto mb-3"
                           :src="require('../../../assets/iamges/WhyInsurmatics/UnleashNewBussinessModel.svg')"
                           max-width="85%"
                           min-height="200px"
                           max-height="400px">
                    </v-img>
                </v-col>
                <v-col
                    class="mx-auto"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="8"
                >
                    <v-card width="90% " min-width="280px"
                            color="#421254ff"
                            class="white--text"
                            min-height="350px"
                            dark
                            elevation="0"
                    >
                        <v-card-title style="word-break: normal">
                            Unleash new business model
                        </v-card-title>
                        <v-card-text class="justify-center" style="word-break: normal">

                            <p>╸ Predictive analytics for risk management and improved customer experience</p>
                            <p>╸ Integration with IoT devices for real-time data collection and analysis</p>
                            <p>╸ Automated underwriting and claims processing for faster turnaround times and increased efficiency</p>
                            <p>╸ Partnership with other industry players for cross-selling opportunities and expanded offerings</p>
                            <p>╸ Customizable dashboards for data visualization and tracking of key performance indicators</p>
                        </v-card-text>

                    </v-card>
                </v-col>

            </v-row>
        </v-card>

        <!--        541253ff-->

        <!--        Leverage technology to win-->
<!--        <v-card-->
<!--            width="100%"-->
<!--            color="#632a88ff"-->
<!--            class="white&#45;&#45;text"-->
<!--        >-->
<!--            <v-row class="justify-center mx-auto">-->
<!--                <v-col-->
<!--                    class="mx-auto"-->
<!--                    cols="12"-->
<!--                    xs="12"-->
<!--                    sm="6"-->
<!--                    md="6"-->
<!--                >-->
<!--                    <v-card width="90% " min-width="280px"-->
<!--                            color="#632a88ff"-->
<!--                            class="white&#45;&#45;text"-->
<!--                            height="400px"-->
<!--                            dark-->
<!--                            elevation="0"-->
<!--                    >-->
<!--                        <v-card-title style="word-break: break-word">-->
<!--                            Leverage technology to win-->
<!--                        </v-card-title>-->
<!--                        <v-card-text class="justify-center" style="word-break: normal">-->
<!--                            The best leverage to overcome scarcity and reach abundance is to use technology. Due to the-->
<!--                            inevitable trend of digitalization, insurance companies are also experiencing a new space-->
<!--                            with the help of technology. What insurmatics does is to provide equal access to technology-->
<!--                            for all insurance companies. In fact, Insurmatics, with the help of artificial intelligence-->
<!--                            and the Internet of Things, provides better risk management, cost-effective prevention, and-->
<!--                            claim fraud detection. Also, with the help of the concept of low code programming, the-->
<!--                            implementation time of products can be drastically reduced.-->

<!--                        </v-card-text>-->
<!--                        &lt;!&ndash;                        <v-btn&ndash;&gt;-->
<!--                        &lt;!&ndash;                            rounded&ndash;&gt;-->
<!--                        &lt;!&ndash;                            width="100px"&ndash;&gt;-->
<!--                        &lt;!&ndash;                            color="white"&ndash;&gt;-->
<!--                        &lt;!&ndash;                            class="purple&#45;&#45;text ml-5"&ndash;&gt;-->
<!--                        &lt;!&ndash;                        >&ndash;&gt;-->
<!--                        &lt;!&ndash;                            Get demo&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </v-btn>&ndash;&gt;-->
<!--                    </v-card>-->
<!--                </v-col>-->
<!--                <v-col-->
<!--                    class="mx-auto"-->
<!--                    cols="12"-->
<!--                    xs="12"-->
<!--                    sm="6"-->
<!--                    md="6"-->

<!--                >-->
<!--                    <v-img contain-->
<!--                           class="full-width"-->
<!--                           :src="require('../../../assets/iamges/WhyInsurmatics/technology.svg')"-->
<!--                           height="400px">-->
<!--                    </v-img>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--        </v-card>-->

    </div>

</template>

<script>

    import vueFilePond, {setOptions} from 'vue-filepond';
    // import headerSVG from '../../js/LandingPage/Icons/headerMediumSVG';

    let currentDate = Date.now()
    // setOptions({
    //     fileRenameFunction: (file) => {
    //         return currentDate + `${file.extension}`;
    //     }
    // })
    // setOptions({
    //     chunkUploads: true,
    //     chunkForce: true,
    //     server: {
    //         url: "/uploads",
    //         headers: {
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         process: {
    //             method: 'POST',
    //         },
    //     }
    // });
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize
        from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    // import PublicMessage from "./Components/PublicMessage";
    // import RegisterSupporters from "../Components/RegisterSupporters";
    // import RegisterVet from "../Components/RegisterVet";

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {
        created() {
            // if(navigator.geolocation){
            //     navigator.geolocation.getCurrentPosition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // };
        },
        name: "messagetest1",
        components: {
            // InputField,
            FilePond,
            // PublicMessage,
            // RegisterSupporters,
            // RegisterVet

        },
        props: {
            savingSuccessful: false,
            citizen: '',
        },
        data() {
            return {
                drawerMedium: false,
                drawerSmall: false,
                selectComponent: 1,
                video: {},
                canvas: {},
                captures: [],
                snackbar: false,
                text: 'Hello, I\'m a snackbar',
                sampleFig: '',
                picID2: '',
            }
        },
        data: function () {
            return {
                snackbarColor: '',
                tab: null,
                loading: false,
                valid: true,
                name: '',
                nameRules: [
                    v => !!v || 'نام و نام خانوادگی لازم می باشد',
                    v => (v && v.length <= 50) || 'حداکثر تعداد کاراکتر 50 عدد می باشد',
                ],
                cellNumber: '',
                cellNumberRules: [
                    v => !!v || 'شماره تماس الزامی می باشد',
                    v => (v && v.length == 11) || ' تعداد کاراکتر 11 عدد می باشد',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'وارد کردن ایمیل الزامی است',
                    v => /.+@.+\..+/.test(v) || 'شکل ایمیل را صحیح وارد نمایید.',
                ],
                selectedNotification: null,
                notificationItems: [
                    'hosa.ir',
                    'شبکه های اجتماعی و خبری',
                    'معرفی سازمانی',
                    'دوستان و همکاران',
                    'موارد دیگر',
                ],
                selectedJob: null,
                jobItems: [
                    'کارشناس یا مدیر شهرداری مرتبط با حیوانات شهری',
                    'کارشناس یا مدیر شهرداری دیگر بخش ها',
                    'شرکت خصوصی',
                    'حامی و علاقه مند به حیوانات شهری',
                    'موارد دیگر',
                ],
                comments: '',
                checkbox: false,
                drawer: false,
                drawerMedium: false,
                drawerSmall: false,
                selectComponent: 1,

                //myFiles: ['index.html'],
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'dateofadoption', 'address', 'comments'
                        'name': '', 'cellnumber': '', 'email': '', 'notification': '', 'comments': '', 'job': ''
                    },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                sampleFig: '',
                picID2: ''

            }
        },
        mounted() {
            // this.$store.commit("changeNameCitizen", this.citizen);
        },
        computed: {

            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
            // handleProcessFile: function(error, file) {
            //     this.form.fig = file.serverId;
            //     this.picID2 = file;
            //     alert(file.serverId);
            //     // this.$store.commit("updatePublicFig", file.serverId);
            //
            //     // this.setFigValue;
            //     // let response = JSON.parse(file.serverId);
            //     // this.$emit("response", response);
            //     // this.setFigValue(file.serverId);
            //     // console.log(this.form.fig);
            // },
            picID: function (file) {
                this.picID2 = file;
            }
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods: {
            sendFormData() {
                if (this.$refs.form.validate()) {
                    this.addNewDog();
                }
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            updateSetOption: function () {
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            // getCurrentLocation: function(){
            //     if(navigator.geolocation){
            //         navigator.geolocation.getCurrentPosition(position => {
            //             this.form.catched_lon = position.coords.longitude;
            //             this.form.catched_lat = position.coords.latitude;
            //         })
            //     }
            // },

            callback(msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function (error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            addNewDog() {
                this.busy = true;
                // this.getCurrentLocation();
                axios.post('../forms', this.form)
                    .then(response => {
                        console.log(response);
                        this.snackbar = true;
                        this.snackbarColor = 'green';
                        this.text = 'ثبت نام شما با موفقیت به پایان رسید. پس بررسی به اطلاع شما خواهد رسید';
                        // this.text = this.form.fig;
                    })
                    .catch(errors => {
                        // this.text = errors.response.data.errors;
                        this.snackbarColor = 'red';
                        this.text = 'شماره ی تماس تکراری است. به ازای هر ثبت نام، می بایست شماره تماس منحصر به فرد وجود داشته باشد.';
                        //'name':'','comments':'', 'cellnumber':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                        this.snackbar = true;
                        // this.text = 'لطفا فیلدها را کامل پرکنید';

                        //this.text = this.form.fig;
                        // console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(errors);
                    }).finally(() => {
                    this.busy = false;
                });

            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;
    }

    .test {
        font-size: 1em;
    }

    .testHeader {
        font-size: 1.5em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }

    .testToolbar {
        font-size: 1em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }

    .titleText {
        font-size: 1.5em;
        line-height: 30px;
        color: #000000;
    }

    .subtitleText {
        font-size: 1em;
        line-height: 30px;
        color: #000000;
    }

    .textHeaderDefinition {
        font-size: .9em;
        color: #999999;
    }

    .lightgray {
        background-color: whitesmoke !important;
    }

    .lightPurple {
        color: #e5d5ffff;
    }

    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/
    .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../../assets/icons/Header.png") no-repeat center center;
        background-size: cover;
        transform: scale(1.1);
    }


    .smallbg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../../assets/icons/sample.png") no-repeat center center;
        background-size: cover;
        background-color: red;
        transform: scale(1.1);
    }
</style>
