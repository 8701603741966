<template>
    <div class="pt-16">

        <v-card max-width="600px" class="mx-auto rounded-form" outlined >
            <v-card-actions class="justify-center">
            </v-card-actions>
            <v-card-title class="layout justify-center">
                Consultation request
            </v-card-title>
            <v-img :src="require('../../../assets/iamges/SupportRequest.jpg')" contain max-height="300px">

            </v-img>
            <v-card-text>
                <v-autocomplete
                    :items = "['System Crash Report', 'User Guide', 'Purchase Guide']"
                    label="What support do you want?"
                    v-model="topic"
                ></v-autocomplete>
                <v-textarea  label="Comments"  placeholder="Please enter more details." v-model="supportComments "></v-textarea>


            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="m-1" >Cancel </v-btn>
                <v-btn depressed color="blue" class="m-2" @click="dialogConsult='true'" :loading="busy">Send </v-btn>

            </v-card-actions>
            <template>
                <v-dialog
                    v-model="dialogConsult"
                    width="500"
                >
                    <v-card>
                        <v-card-title class="headline2 grey lighten-2">
                            Support request form
                        </v-card-title>
                        <v-card-text>
                            I confess that all the information entered is correct and  Insurance will not be held responsible for any possible violations.
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="requestSupport"
                                color="green"
                                text
                            >
                                Yes, I agree.
                            </v-btn>
                            <v-btn
                                @click="dialogConsult=false"
                                color="red"
                                text
                            >
                                No.
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

        </v-card>
        <v-snackbar
            v-model="snackbar"
            :top=true
            timeout=10000
        >
            Wait for the experts to call
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>

    import vueFilePond , { setOptions } from 'vue-filepond';
    let currentDate = Date.now()
    // setOptions({
    //     fileRenameFunction: (file) => {
    //         return currentDate + `${file.extension}`;
    //     }
    // })
    // setOptions({
    //     chunkUploads: true,
    //     chunkForce: true,
    //     server: {
    //         url: "/uploads",
    //         headers: {
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         process: {
    //             method: 'POST',
    //         },
    //     }
    // });
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {
        created(){

        },
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond
        },
        props:{
            savingSuccessful: false
        },
        data() {
            return {
                video: {},
                canvas: {},
                captures: [],
                text: 'Hello, I\'m a snackbar',
                sampleFig:'',
                picID2:'',



            }
        },
        data:function () {
            return{
                topic:'',
                supportComments:'',
                dialogConsult:false,
                //myFiles: ['index.html'],
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name':'','comments':'', 'cell_number':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                    },
                errors: null,
                responses:null,
                busy:false,
                snackbar: false,
                text: '',
                sampleFig:'',
                picID2:''

            }
        },

        mounted: function(){
            // this.Bol = true;
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(position => {
                    this.form.catched_lon = position.coords.longitude;
                    this.form.catched_lat = position.coords.latitude;
                })
            };
        },
        computed:{
            // handleProcessFile: function(error, file) {
            //     this.form.fig = file.serverId;
            //     this.picID2 = file;
            //     alert(file.serverId);
            //     // this.$store.commit("updatePublicFig", file.serverId);
            //
            //     // this.setFigValue;
            //     // let response = JSON.parse(file.serverId);
            //     // this.$emit("response", response);
            //     // this.setFigValue(file.serverId);
            //     // console.log(this.form.fig);
            // },
            picID:function (file) {
                this.picID2 = file;
            }
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods: {
            updateSetOption: function () {
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            getCurrentLocation: function () {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                    })
                }
            },

            callback(msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function (error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            requestSupport() {
                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'Support',
                    'status': 'submitted',
                    'issue_tracking':currentTime.getTime(),
                    'message': [{'userType':'citizen',
                        'id': '',
                        'topic': this.topic,
                        'msg': this.supportComments,
                    }],
                };
                axios.post('msg', msg)
                    .then(response => {
                        this.dialogConsult = false;
                        this.snackbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }
    #video {
        background-color: #000000;
    }
    #canvas {
        display: none;
    }
    li {
        display: inline;
        padding: 5px;
    }
    .rounded-form{
        border-radius: 28px !important;

    }
    .headline2 {
        font-family: IRANSans !important;
    }
    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/
</style>
