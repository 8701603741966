<template>
    <div style="width: 100%; height: 100%;">
        <MglMap
            :accessToken="mapirToken"
            :center="center"
            mapStyle="mapbox://styles/mapbox/streets-v11"
            :zoom="zoom"
        >
            <MglGeojsonLayer
                sourceId="mySource"
                :source="geoJsonSource"
                layerId="myLayer"
                :layer="geoJsonlayer"
            />
            <MglMarker
                v-for="(location, index) in trace"
                :key="index"
                :coordinates="location"
                :color="markerColor"
            />
        </MglMap>
    </div>
</template>

<script>
    import Mapbox from "mapbox-gl";
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl} from "vue-mapbox";



    export default {
        name: "WalkingTrace",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        data() {
            return {
                center: [25.279651, 54.687157], // Centered at Vilnius
                zoom: 13,
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
                trace: [],
                geojson: null,
                lineColor: "red",
                lineWidth: 5,
                markerColor: "blue",
                geoJsonlayer: {
                    id: "myLayer",
                    type: "line",
                    source: "mySource",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    paint: {
                        "line-color": "#088",
                        "line-width": 4
                    }
                }
            };
        },
        created(){
            this.generateRandomLocations();
        },
        computed:{
            geoJsonSource() {

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                    coordinates:this.trace,
                                }
                            }
                        ]
                    }
                };
            }
        },
        methods: {
            generateRandomLocations() {
                const centerLat = 54.687157;
                const centerLng = 25.279651;
                const step = 0.0005; // Roughly equal to 20 meters
                const variations = 0.0004; // Roughly equal to 10 meters
                const numberOfLocations = Math.floor(Math.random() * (12 - 8 + 1)) + 8; // Random number between 8 and 12

                let currentLat = centerLat;
                let currentLng = centerLng;
                let totalDistance = 0;

                this.trace = [[currentLng, currentLat]]; // Initialize trace with the starting point

                for (let i = 1; i < numberOfLocations; i++) {
                    const angle = Math.random() * Math.PI * 2;
                    const latVariation = (Math.random() - 0.5) * variations;
                    const lngVariation = (Math.random() - 0.5) * variations * Math.cos(currentLat * (Math.PI / 180));

                    const newLat = currentLat + step * Math.cos(angle) + latVariation;
                    const newLng = currentLng + step * Math.sin(angle) + lngVariation;

                    totalDistance += this.calculateDistance(currentLat, currentLng, newLat, newLng);

                    this.trace.push([newLng, newLat]);

                    currentLat = newLat;
                    currentLng = newLng;
                }

                console.log('Total distance of the path:', totalDistance, 'km');
            },

            calculateDistance(lat1, lon1, lat2, lon2) {
                const R = 6371; // Radius of the earth in km
                const dLat = this.deg2rad(lat2 - lat1);
                const dLon = this.deg2rad(lon2 - lon1);
                const a =
                    Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
                    Math.sin(dLon/2) * Math.sin(dLon/2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                const distance = R * c; // Distance in km
                return distance;
            },

            deg2rad(deg) {
                return deg * (Math.PI/180);
            },

            // generateRandomLocations() {
            //     const centerLat = 54.687157;
            //     const centerLng = 25.279651;
            //     const step = 0.002; // Roughly equal to 200 meters
            //     const variations = 0.001; // Roughly equal to 100 meters
            //
            //     let currentLat = centerLat;
            //     let currentLng = centerLng;
            //
            //     for (let i = 0; i < 10; i++) {
            //         const angle = Math.random() * Math.PI * 2;
            //         const latVariation = (Math.random() - 0.5) * variations;
            //         const lngVariation = (Math.random() - 0.5) * variations * Math.cos(currentLat * (Math.PI / 180));
            //
            //         currentLat += step * Math.cos(angle) + latVariation;
            //         currentLng += step * Math.sin(angle) + lngVariation;
            //
            //         this.trace.push([currentLng, currentLat]);
            //     }
            // },
        },
    };
</script>

<style scoped>
    .map-view {
        height: calc(100vh - 48px);
    }
</style>
