<template>
    <svg
        width="6.3499999mm"
        height="6.3499999mm"
        viewBox="0 0 6.3499999 6.3499999"
        version="1.1"
        id="svg5"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        sodipodi:docname="IoT.svg"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <sodipodi:namedview
            id="namedview7"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            inkscape:document-units="mm"
            showgrid="false"
            inkscape:zoom="11.573962"
            inkscape:cx="16.286558"
            inkscape:cy="8.4240816"
            inkscape:window-width="1920"
            inkscape:window-height="1001"
            inkscape:window-x="-9"
            inkscape:window-y="-9"
            inkscape:window-maximized="1"
            inkscape:current-layer="g1285"
            height="6.35mm"/>
        <defs
            id="defs2"/>
        <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            id="layer1">
            <g
                style="fill:none"
                id="g839"
                transform="matrix(0.26458333,0,0,0.26458333,86.437069,91.923522)">
                <g
                    id="g1285"
                    transform="translate(637.33907,-123.16675)">
                    <g
                        id="g1251"
                        transform="matrix(0.12722797,0,0,0.12722797,-831.70534,-185.07219)"
                        style="stroke-width:7.85989;fill:#1a1a1a">
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -974.87283,-205.83179 c -2.6e-4,-22.55 0.13111,-31.91764 0.29192,-20.81697 0.16082,11.10067 0.16103,29.55067 4.7e-4,41 -0.16055,11.44934 -0.29213,2.36697 -0.29239,-20.18303 z m 88.98639,-9 c -4.5e-4,-16.5 0.13655,-23.39031 0.30444,-15.31181 0.16789,8.07851 0.16826,21.57851 8.2e-4,30 -0.16745,8.4215 -0.30481,1.81181 -0.30526,-14.68819 z"
                            id="path1265"/>
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -1014.8629,-184.83179 0.033,-20 9.7523,-0.0573 9.75235,-0.0573 -9.48135,0.34328 -9.4813,0.34328 -0.3044,19.71404 -0.3043,19.71404 0.033,-20 z m 74.7834,-20.23899 c 3.1625,-0.19845 8.3375,-0.19845 11.5,0 3.1625,0.19845 0.575,0.36082 -5.75,0.36082 -6.325,0 -8.9125,-0.16237 -5.75,-0.36082 z m -74.8228,-20.59015 c -0.2805,-0.73103 -0.3589,-5.34353 -0.1743,-10.25 l 0.3358,-8.92086 0.2057,9.71379 0.2056,9.71379 9.75,0.32692 9.75,0.32692 -9.7814,0.20929 c -7.3648,0.15758 -9.9074,-0.11908 -10.2914,-1.11985 z m 74.8228,0.5941 c 2.6125,-0.206 6.8875,-0.206 9.5,0 2.6125,0.206 0.475,0.37454 -4.75,0.37454 -5.225,0 -7.3625,-0.16854 -4.75,-0.37454 z"
                            id="path1263"/>
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -1029.8295,-258.33179 c 0.9955,-1.1 2.035,-2 2.31,-2 0.275,0 -0.3145,0.9 -1.31,2 -0.9955,1.1 -2.035,2 -2.31,2 -0.275,0 0.3145,-0.9 1.31,-2 z"
                            id="path1261"/>
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -912.45706,-259.08179 c -1.24387,-1.58606 -1.2085,-1.62143 0.37756,-0.37755 1.66575,1.30636 2.19548,2.12755 1.37244,2.12755 -0.20765,0 -0.99515,-0.7875 -1.75,-1.75 z"
                            id="path1259"/>
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -1034.8733,-205.33179 c 0,-22.275 0.1317,-31.3875 0.2926,-20.25 0.1609,11.1375 0.1609,29.3625 0,40.5 -0.1609,11.1375 -0.2926,2.025 -0.2926,-20.25 z m 178.90903,30.5 c 0.003,-5.5 0.17053,-7.62242 0.37317,-4.71648 0.20265,2.90594 0.20052,7.40594 -0.005,10 -0.20498,2.59406 -0.37077,0.21648 -0.36817,-5.28352 z m -9.92298,-39.5 c 0,-16.225 0.13752,-22.8625 0.30561,-14.75 0.16808,8.1125 0.16808,21.3875 0,29.5 -0.16809,8.1125 -0.30561,1.475 -0.30561,-14.75 z m 9.92298,-40.5 c 0.003,-5.5 0.17053,-7.62241 0.37317,-4.71648 0.20265,2.90594 0.20052,7.40594 -0.005,10 -0.20498,2.59407 -0.37077,0.21648 -0.36817,-5.28352 z"
                            id="path1257"/>
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -893.0795,-185.04804 c 1.5125,-0.22913 3.9875,-0.22913 5.5,0 1.5125,0.22912 0.275,0.41659 -2.75,0.41659 -3.025,0 -4.2625,-0.18747 -2.75,-0.41659 z m 30.01375,-0.001 c 1.52006,-0.22986 3.77006,-0.22371 5,0.0136 1.22994,0.23736 -0.0137,0.42542 -2.76375,0.41792 -2.75,-0.008 -3.75631,-0.20172 -2.23625,-0.43157 z m -42.88199,-49.78275 c 0.002,-6.6 0.16382,-9.16987 0.35965,-5.71081 0.19583,3.45905 0.19422,8.85905 -0.004,12 -0.1974,3.14094 -0.35762,0.31081 -0.35565,-6.28919 z m 40.53069,-9.81414 c 0.4487,-0.44777 2.78946,-0.66455 5.20169,-0.48173 l 4.38586,0.33241 -5.20169,0.48173 c -2.86092,0.26495 -4.83456,0.11537 -4.38586,-0.33241 z m -144.12895,-0.42426 c 2.906,-0.20265 7.406,-0.20052 10.00002,0.005 2.59406,0.20528 0.21648,0.37107 -5.28352,0.36847 -5.5,-0.003 -7.6224,-0.17053 -4.7165,-0.37317 z m 68.9665,0.001 c 2.8875,-0.20201 7.6125,-0.20201 10.5,0 2.8875,0.202 0.525,0.36727 -5.25,0.36727 -5.775,0 -8.1375,-0.16527 -5.25,-0.36727 z m 47.5,0.021 c 1.5125,-0.22913 3.9875,-0.22913 5.5,0 1.5125,0.22912 0.275,0.41659 -2.75,0.41659 -3.025,0 -4.2625,-0.18747 -2.75,-0.41659 z"
                            id="path1255"/>
                        <path
                            style="fill:#1a1a1a;fill-opacity:1;stroke-width:7.85989"
                            d="m -1034.11,-208.73999 0.2805,-43.40819 3.0533,-3.77142 c 5.083,-6.27853 9.2817,-7.83914 22.2276,-8.26175 6.2045,-0.20254 13.23016,-0.0759 15.61255,0.28131 6.43843,0.9655 12.23432,5.18028 15.1389,11.00901 l 2.46765,4.95193 v 41.30365 41.30366 h -10 -10 v -20 -20 h -10 -10 v 20 20 h -9.5305 -9.5306 z m 38.7805,-26.12801 v -10.53621 l -9.75,0.28621 -9.75,0.28621 -0.285,10.25 -0.285,10.25 h 10.035 10.035 z m 30,20.03621 v -49.5 h 20.3185 c 14.98127,0 21.62106,0.38682 25.27727,1.4726 5.36404,1.59294 10.58468,6.17897 12.3429,10.8425 0.58373,1.5483 1.06133,9.58703 1.06133,17.86384 0,17.25822 -1.06151,20.51155 -8.33894,25.55732 -3.78396,2.62358 -4.66252,2.78139 -17.32359,3.11185 l -13.33747,0.34812 v 19.90188 19.90189 h -10 -10 z m 40,-20 v -10.5 h -10 -10 v 10.5 10.5 h 10 10 z m 30,60 v -9.5 h 5 5 v -30.5 -30.5 h -5 -5 v -9.5 -9.5 h 19.5 19.5 v 9.44521 9.44521 l -4.75,0.30479 -4.75,0.30479 v 30 30 l 4.75,0.30479 4.75,0.30479 v 9.44521 9.44521 h -19.5 -19.5 z"
                            id="path1253"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "APIIcon"
    }
</script>

<style scoped>

</style>
