import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        isAuthenticated: false,
        isCustomerAuthenticated: false,
        isDeveloperAuthenticated: false,

        routerHistory: [],
        users: {
            access_level: '6',
            created_at: '2020',
            email: 'aghababaie@hosa.ir',
            email_verified_at: null,
            id: '',
            first_name: '',
            surname: '',
            updated_at: '2020',
            pic: '',
        },
        developers: {
            created_at: '2020',
            email: 'aghababaie@hosa.ir',
            login_device: '',
            id: '',
            first_name: '',
            surname: '',
            pic: '',
        },
        business:{
            name:'',
            unique_address:'',
            logo:'',
            cell_number:'',
            language:{},//json
            plan_type:'',
            plan_end:'',
            insurance_services:{},//json
            customer_services:{},//json
            region:'',
            country:'',
            city:'',
            postal_code:'',
            address:'',
            website:'',
            developer_id:''
        },

        Application_set: '',
        customers: {
            name: '',
            family: '',
            cell_number: '',
            sex: '',
            pic: '',
            national_ID: '',
            address: '',
            postal_code: '',
            id: '',
        },
        publicFig: 'fig1',
        capturedFig: 'fig2',
        LatLong: {
            Lat: '31',
            Long: '53',
        },
        SnackbarAssign: false,
        SnackbarInprogress: false,
        SnackbarNotFinded: false,
        SnackbarFinal: false,
        SnackbarCreateDogProfile: false,
    };
};

const store = new Vuex.Store({
    state: getDefaultState(),
    getters: {},
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        setAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        setCustomerAuthenticated(state, isCustomerAuthenticated) {
            state.isCustomerAuthenticated = isCustomerAuthenticated;
        },
        setDeveloperAuthenticated(state, isDeveloperAuthenticated) {
            state.isDeveloperAuthenticated = isDeveloperAuthenticated;
        },
        updateApplication(state, ApplicationInfo) {
            state.Application_set = ApplicationInfo;
        },
        updateBusiness(state, businessInfo) {
            state.business = {...state.business, ...businessInfo};
        },
        updateCustomer(state, customerInfo) {
            state.customers = {...state.customers, ...customerInfo};
        },
        updateDeveloper(state, developerInfo) {
            state.developers = {...state.developers, ...developerInfo};
        },
        updateUser(state, userInfo) {
            state.users = {...state.users, ...userInfo};
        },
        updateLocation(state, LatLongInfo) {
            state.LatLong = {...state.LatLong, ...LatLongInfo};
        },
        updateSnackbarAssign(state, newState) {
            state.SnackbarAssign = newState;
        },
        updateSnackbarInprogress(state, newState) {
            state.SnackbarInprogress = newState;
        },
        updateSnackbarNotFinded(state, newState) {
            state.SnackbarNotFinded = newState;
        },
        updateSnackbarFinal(state, newState) {
            state.SnackbarFinal = newState;
        },
        updateSnackbarCreateDogProfile(state, newState) {
            state.SnackbarCreateDogProfile = newState;
        },
        updatePublicFig(state, newFig) {
            state.publicFig = newFig;
        },
        updateCaptureFig(state, newFig) {
            state.capturedFig = newFig;
        },
    },
    actions: {
        resetState({commit}) {
            commit('resetState');
        },
    },
});

export default store;


// import Vue from "vue";
// import Vuex from "vuex";
//
// Vue.use(Vuex);
//
// export default new Vuex.Store({
//     state: {
//         isAuthenticated: false,
//         isCustomerAuthenticated: false,
//         routerHistory: [],
//         users: {
//             access_level: "",
//             created_at: "2020",
//             email: "aghababaie@hosa.ir",
//             email_verified_at: null,
//             id: "",
//             first_name: "",
//             surname: "",
//             updated_at: "2020",
//             pic: ""
//         },
//         Application_set: '',
//         // if (this.$store.state.users.access_level == "1") return 'Management'
//
// //'name', 'family','cellnumber', 'sex', 'password', 'pic', 'national_ID',
//         //         'address', 'postal_code'
//         customers: {
//             name: "",
//             family: "",
//             cell_number: "",
//             sex: "",
//             pic: "",
//             national_ID: "",
//             address: "",
//             postal_code: "",
//             id: "",
//         },
//         // this.$store.state.user
//         publicFig: 'fig1',
//         // this.$store.state.publicFig
//         capturedFig: 'fig2',
//         // this.$store.state.capturedFig
//         // publicFig:'fig1',
//         // // this.$store.state.publicFig
//
//
//         LatLong: {
//             Lat: "31",
//             Long: "53",
//         },
//         // this.$store.state.LatLong
//
//         SnackbarAssign: false,
//         // this.$store.state.SnackbarAssign
//         SnackbarInprogress: false,
//         // this.$store.state.SnackbarInprogress
//         SnackbarNotFinded: false,
//         // this.$store.state.snackbarNotFinded
//         SnackbarFinal: false,
//         // this.$store.state.snackbarFinal
//
//         SnackbarCreateDogProfile: false,
//         // this.$store.state.SnackbarCreateDogProfile
//
//     },
//     getters: {},
//     mutations: {
//         setAuthenticated (state, isAuthenticated) {
//             state.isAuthenticated = isAuthenticated;
//         },
//         setCustomerAuthenticated (state, isCustomerAuthenticated) {
//             state.isCustomerAuthenticated = isCustomerAuthenticated;
//         },
//         updateApplication(state, ApplicationInfo) {
//             state.Application_set = ApplicationInfo;
//         },
//         updateCustomer(state, customerInfo) {
//             state.customers.name = customerInfo.name;
//             state.customers.family = customerInfo.family;
//             state.customers.cell_number = customerInfo.cell_number;
//             state.customers.sex = customerInfo.sex;
//             state.customers.pic = customerInfo.pic;
//             state.customers.national_ID = customerInfo.national_ID;
//             state.customers.address = customerInfo.address;
//             state.customers.postal_code = customerInfo.postal_code;
//             state.customers.id = customerInfo.id;
//         },
//         updateUser(state, userInfo){
//             state.users.first_name = userInfo.first_name;
//             state.users.surname = userInfo.surname;
//             state.users.access_level = userInfo.access_level;
//             state.users.email = userInfo.email;
//             state.users.id = userInfo.id;
//             state.users.pic = userInfo.pic;
//         },
//         changeName(state, userInfo) {
//             state.users.first_name = userInfo.first_name;
//             state.users.surname = userInfo.surname;
//             state.users.access_level = userInfo.access_level;
//             state.users.email = userInfo.email;
//             state.users.id = userInfo.id;
//             state.users.pic = userInfo.pic;
//         },
//         // this.$store.commit("changeName", userInfo);
//
//         updateLocation(state, LatLongInfo) {
//             state.LatLong.Lat = LatLongInfo.Lat;
//             state.LatLong.Long = LatLongInfo.Long;
//         },
//
//         updateSnackbarAssign(state, newState) {
//             state.SnackbarAssign = newState;
//
//         },
//
//         updateSnackbarInprogress(state, newState) {
//             state.SnackbarInprogress = newState;
//
//         },
//
//         updateSnackbarNotFinded(state, newState) {
//             state.SnackbarNotFinded = newState;
//
//         },
//
//         updateSnackbarFinal(state, newState) {
//             state.SnackbarFinal = newState;
//
//         },
//
//         updateSnackbarCreateDogProfile(state, newState) {
//             state.SnackbarCreateDogProfile = newState;
//         },
//
//         updatePublicFig(state, newFig) {
//             state.publicFig = newFig;
//         },
//         // this.$store.commit("updatePublicFig", newFig);
//
//
//         updateCaptureFig(state, newFig) {
//             state.capturedFig = newFig;
//         }
//         // this.$store.commit("updateCaptureFig", newFig);
//     },
//     // this.$store.commit("updateSnackbarAssign", status);
//
//     actions: {}
// });
