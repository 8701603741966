<template>
    <div>
        <v-snackbar v-model="snackkbar" color="#f2c4d3" :timeout="5000" top>
            <span style="color: #333333;">
                See information about people in this section.
            </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>
        <v-snackbar v-model="snackkbarTask" color="#f2c4d3" :timeout="5000" centered>
            <span style="color: #333333;">
                Message related to assigning tasks was sent.
            </span>
        </v-snackbar>
        <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
        >
        </v-text-field>
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="20"
            :search="search"
            :loading="busy"
            sort-by="access_level"
            :sort-desc="false"
            show-expand
            align="center"
            :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'item per page',

      }"
        >
            <!--            @click:row="item=>$router.push({name:'users',params:{profile_id:item.id}})"-->
            <template #item.access_level="{value}">
                {{getAccessLevel(value)}}
            </template>

            <template v-slot:item.location="{ item }">
                <template v-if="item.access_level == 5">
                    <v-btn @click.prevent="EditData(item.id)" icon>
                        <v-icon>navigation</v-icon>
                    </v-btn>
                </template>
            </template>

            <template #item.remove="{item}">
                <!--                <template v-slot:activator="{ on, attrs }">-->
                <!--                    <v-flex align-self-start>-->
                <v-btn color="error"
                       rounded
                       @click="dialog = 'true', updateRemoveID(item.id)"
                       small>Remove
                </v-btn>

                <!--                    </v-flex>-->
                <!--                </template>-->
            </template>

            <template #item.task="{item}">
                <template v-if="item.access_level == 5">

                    <v-btn color="primary"
                           small
                           dark
                           rounded
                           @click.stop.prevent="dialogTask='true' , id = item.TimeID, uploadNumber(item)"
                    >
                        Assign
                    </v-btn>
                </template>
            </template>
            <!--                <template v-if="item.access_level == 5">-->
            <!--                    <v-row justify="center">-->
            <!--
                                </v-row>-->
            <!--                </template>-->
            <!--            </template>-->

            <template #item.edit="{item}">
                <!--                <v-row justify="center">-->
                <v-btn color="orange"
                       small
                       dark
                       rounded
                       @click="dialog1='true', uploadData(item)">Edit
                </v-btn>
                <!--                </v-row>-->
            </template>

            <template #expanded-item="{header, item}">
                <!--                <v-flex md6>-->
                <!--                </v-flex>-->
                <td :colspan="headers.length">
                    <v-spacer></v-spacer>

                    <v-container fluid>
                        <v-layout row wrap align="center" justify="center">
                            <v-flex sm4 xs12 justify-center>
                                <v-avatar width="300px" height="100px">
                                    <!--                                    //                    this.images= '/storage/images/publics/'+this.dogs.fig;-->
                                    <v-img :src="'/storage/images/user/'+ item.pic" contain max-height="400px">

                                        <!--                                    <v-img :src="require('../../../assets/iamges/g8013.png')" contain-->
                                        <!--                                           max-height="100px">-->
                                    </v-img>
                                </v-avatar>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.national_ID"
                                    label="national ID"
                                    readonly
                                    filled
                                    rounded
                                    dense

                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.shenasname"
                                    label="Personal ID"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap v-if="item.access_level == 5">

                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.error_count"
                                    label="Number of Units"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.error_count"
                                    label="UnitsSets"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12>
                                <v-text-field
                                    v-model="item.dog_cap_number"
                                    label="تعداد سگ های گرفته شده"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <!--                    <v-row>-->
                        <!--                        <v-col cols="12" md="4">-->
                        <!--                            <h1>Salam</h1>-->
                        <!--                        </v-col>-->
                        <!--                        <v-col cols="12" md="8">-->
                        <!--                            <h1>Bye</h1>-->
                        <!--                        </v-col>-->
                        <!--                    </v-row>-->
                        <!--                    <h1>{{item.name}}</h1>-->
                    </v-container>
                </td>

            </template>
        </v-data-table>

        <v-dialog
            v-model="dialogTask"
            persistent
            max-width="600px"
            :retain-focus="false">

            <v-card class="my-auto" min-height="400px">
                <v-card-title>
                    <span>اختصاص مکان جستجو</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                class="overflow-y-auto"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="80%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            label="Pick date"
                                            prepend-icon="event_note"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateEndCaptured"
                                        no-title
                                        scrollable
                                        width="200"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>

<!--                                <date-picker v-model="date"></date-picker>-->
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <!--                                                <v-select :items="['ID100','ID101','ID102','ID103','ID104','ID105','ID106','ID107' ]" label="ای دی سگ" :error-messages="errors" placeholder="لطفا ای دی سگ مد نظر را وارد نمایید" v-model="form.dog_ID " ></v-select>-->

                                <v-autocomplete
                                    :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]"
                                    label="منطقه ی جستجو را انتخاب نمایید."
                                    v-model="region"
                                ></v-autocomplete>
                                <v-autocomplete
                                    :items="region_section[region-1]"
                                    label="ناحیه ی جستجو را انتخاب نمایید."
                                    v-model="section"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                    <!--                                <small>*indicates required field</small>-->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        @click="dialogTask = false"
                    >
                        بستن
                    </v-btn>
                    <v-btn
                        color="green"
                        @click="SendSMStoUser()"

                    >
                        ذخیره
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog"
            width="500"
            :retain-focus="false"
        >

            <v-card>
                <v-card-title class="red white--text">
                    آیا مطمئن هستید که می خواهید حذف کنید؟
                </v-card-title>
                <v-card-text>
                    پس از حذف امکان بازگشت اطلاعات دیگر وجود ندارد. اگر اطمینان دارید دکمه حذف را بزنید در غیر
                    اینصورت، کنسل کنید.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        @click="destroyUserData()"
                    >
                        حذف
                    </v-btn>

                    <v-btn
                        color="green"
                        @click="dialog = false">
                        کنسل
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog
            v-model="dialog1"
            persistent
            max-width="600px"
            :retain-focus="false"
        >

            <v-card class="justify-center">
                <v-card-title>
                    <span class="headline">اطلاعات کاربر</span>
                </v-card-title>
                <v-card class="justify-center">
                    <div id="filepond">
                        <file-pond

                            name="userPhoto"
                            ref="pond"
                            class-name="my-pond"
                            label-idle="در صورت نیاز، عکس را اپلود کنید"
                            allow-multiple="false"
                            accepted-file-types="image/jpeg, image/png"
                            server="../user/upload"
                            v-on:processfile="handleProcessFile">
                        </file-pond>
                    </div>
                </v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="form.name"
                                    label="نام و نام خانوادگی*"
                                    hint="نام و نام خانوادگی"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="6"
                                   md="4">
                                <v-text-field
                                    v-model="form.national_ID"
                                    label="کدملی*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"
                                   sm="6"
                                   md="4">
                                <v-text-field
                                    v-model="form.shenasname"
                                    label="شناسنامه*"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-select
                                    v-model="form.access_level"
                                    :items="access_levels"
                                    label="سطح کاربر*"
                                    required
                                    dir="rtl"
                                ></v-select>
                            </v-col>
                            <template v-if="form.access_level == 5">
                                <v-col cols="12"
                                       sm="6"
                                       md="4">
                                    <v-text-field
                                        v-model="form.car_number"
                                        label="ماشین انتخاب شده قبلی"
                                        readonly
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select
                                        v-model="form.car_number"
                                        :items="carnumbers"
                                        label="شماره ی پلاک جدید"
                                        dir="rtl"
                                    ></v-select>
                                </v-col>
                            </template>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        @click="dialog1 = false"
                    >
                        بستن
                    </v-btn>
                    <v-btn
                        color="green"
                        @click="editUserData()"
                    >
                        ذخیره
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

    export default {
        name: "UsersIndex",

        components: {
            datePicker: VuePersianDatetimePicker
        },
        beforeCreate() {

            axios.get('../cars')
                .then(response => {
                    // this.responses = response.data[0].data.id;
                    // console.log(this.response);
                    // this.$router.go();
                    if (response.data.length) {
                        this.carnumbers[0] = response.data[0]['number'];
                        // this.carnumbers[0]['value'] = response.data[0]['id'];
                        let i;
                        for (i = 1; i < response.data.length; i++) {
                            // this.carnumbers.push({text:  response.data[i]['number'], value: response.data[i]['id']});
                            this.carnumbers.push(response.data[i]['number']);
                        }
                    }

                    // for (i = 0; i < response.data.length; i++) {
                    //     this.carnumbers[i]['text'] = response.data[i]['name'];
                    //     this.carnumbers[i]['value'] = response.data[i]['id'];
                    // }
                })
                .catch(errors => {
                    console.log(errors);
                }).finally(() => {
                    this.busy = false;
                }
            )
        },
        created() {
            this.fetchData();
        },
        mounted() {


        },

        data: function () {
            return {
                id: '',
                region_section: [
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    [1, 2, 3, 4, 5, 6, 7, 8, 9],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4, 5, 6, 7, 8, 9],
                    [1, 2, 3, 4, 5, 6, 7],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4, 5],
                    [1, 2, 3],
                    [1, 2],
                    [1, 2, 3],
                    [1, 2, 3, 4],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3, 4, 5, 6, 7, 8],
                    [1, 2, 3, 4, 5, 6],
                    [1, 2, 3],
                    [1, 2, 3, 4, 5, 6, 7],
                    [1, 2, 3, 4, 5],
                    [1, 2, 3, 4, 5, 6, 7],
                    [1, 2, 3],
                    [1, 2, 3, 4],
                ],
                date: '',
                menu:false,
                region: '',
                section: '',
                taskcellnumber: '',
                url: 'https://api.kavenegar.com/v1/66533457526E5956554A42544752595A444A33673553577370664261662F2B70642B5251617474634D6A6B3D/verify/',
                // https://api.kavenegar.com/v1/{API-KEY}/verify/lookup.json
                dialog: false,
                dialog1: false,
                dialogTask: false,
                dialogVocation: false,
                fill: false,
                picker: false,
                gradient: gradients[4],
                gradients,
                padding: 8,
                radius: 10,
                value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
                width: 2,
                busy: false,
                search: '',
                users: [],
                removeUserID: '',
                editUserID: '',
                // carnumbers:[ {text: '', value: ''}],
                carnumbers: [],
                currentcarnumber: {text: '', value: ''},
                snackkbar: true,
                snackkbarTask: false,


                access_levels: [
                    {text: 'مدیریت', value: '1'},
                    {text: 'کارشناس', value: '2'},
                    {text: 'دکتر', value: '3'},
                    {text: 'کارشناس مسئول', value: '4'},
                    {text: 'تکنسین', value: '5'},
                    {text: 'مسئول تکنسین', value: '7'},
                    {text: 'حراست و بازرسی', value: '8'},
                    {text: 'فرد بدون سمت', value: '6'},
                ],

                headers: [
                    {text: 'ID', value: 'id'},

                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    // { text: 'Family', value: 'family' },
                    {text: 'Cell phone number', value: 'email'},
                    {text: 'Access level', value: 'access_level'},
                    // {text: 'امتیاز', value: "id"},
                    {text: 'Tracking', value: 'location', sortable: false,},
                    {text: 'edit', value: 'edit', sortable: false, width: "200px"},
                    {text: 'remove', value: 'remove', sortable: false, width: "200px"},
                    // { text: 'Dog ID', value: 'dog_ID' },
                    // { text: 'Dog ID', value: 'family' },
                ],
                formCar:
                    {
                        'driver': '', 'number': ''
                    },
                form:
                    {
                        'name': '',
                        'email': '',
                        'access_level': '',
                        'pic': '',
                        'national_ID': '',
                        'shenasname': '',
                        'car_number': '',
                        'error_count': '',
                        'dog_cap_number': '',
                        'car_ID': ''
                    },
            }
        },
        methods: {
            uploadNumber(form) {
                this.taskcellnumber = form.email;
            },
            SendSMStoUser() {
                let formSMS = {'receptor': '', 'token': '', 'token2': '', 'token3': '', 'template': ''};
                formSMS.receptor = this.taskcellnumber;
                formSMS.token = this.date;
                formSMS.token2 = this.region;
                formSMS.token3 = this.section;
                formSMS.template = 'task';

                axios.post('../users/sms', formSMS)
                    .then(response => {
                        this.snackkbarTask = true;
                        this.dialogTask = false
                    })
                    .catch(errors => {
                        // alert('errors');
                    }).finally(() => {
                    this.busy = false;
                });
                // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                // let formSMS = { 'receptor': "09127752017", 'token': 'salam', 'template' : 'captured'};
                // axios.post(this.url , formSMS, {headers: {
                //     // remove headers
                // }})
                //     .then( response => {alert(response)})
                //     .catch(errors =>{
                //         alert(response);
                // });
            },
            //Edit Data
            updateEditID(id) {
                this.editUserID = id;
            },
            uploadData(userForm) {
                this.form = userForm;
                this.currentcarnumber.text = this.form.car_number;
                this.currentcarnumber.value = this.form.car_ID;

                // this.currentcarnumber['text'] = this.form.car_number;
                // this.currentcarnumber['value'] = this.form.car_ID;
            },
            handleProcessFile: function (error, file) {
                // this.form.first_fig = file.serverId;
                this.form.pic = file.serverId;
                // console.log(this.form.fig);
                console.log(this.form.first_fig);
            },

            editUserData() {
                // this.dialogAdd = false;
                // this.form.car_number = this.currentcarnumber.text;
                // this.form.car_ID = this.currentcarnumber.value;
                axios.put('../user/' + this.form.id, this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.response);
                        this.formCar.driver = this.form.id;
                        this.formCar.number = this.form.car_number;
                        // this.formCar.id = this.form.car_ID;
                        axios.put('../cars/update', this.formCar)
                            .then(responses => {
                                // this.responses = response.data[0].data.id;
                                console.log(this.responses);
                                // this.$router.push({name:'carindex'});
                            })
                        // this.$router.push({name:'carindex'});
                    })
                    .catch(errors => {
                        console.log(errors);
                    }).finally(() => {
                    this.dialog1 = false;
                    this.$router.go();
                });
            },


            //Delete Data
            updateRemoveID(id) {
                this.removeUserID = id;
            },
            destroyUserData() {
                axios.delete('../user/' + this.removeUserID)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'carindex'});
                    })
                    .catch(errors => {
                        console.log(errors);
                    }).finally(() => {
                    this.dialog = false;
                    this.$router.go();
                });
            },

            fetchData() {
                this.busy = true;

                axios.get('/user')
                    .then(response => {
                        this.users = response.data[0].data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            // axios.get('../dogdata/' + this.$route.params.id)
            //     .then(response =>{
            //         this.doginfoshow = response.data[0].data;
            //         //this.temp = response.data[0].data.race;
            //         //this.doginfoshow= response.data[0].data;
            //     })
            //     .catch(error =>{
            //
            //     });

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            EditData: function (id) {
                this.$router.push('../users/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            getAccessLevel(access_level) {
                if (access_level == "1") return 'مدیریت'
                else if (access_level == "2") return 'کارشناس'
                else if (access_level == "3") return 'دکتر آراد کوه'
                else if (access_level == "4") return 'کارشناس مسئول'
                else if (access_level == "5") return 'تکنسین'
                else if (access_level == "7") return 'مسئول تکنسین'
                else if (access_level == "8") return 'حراست و بازرسی'
                else return 'کاربر تایید نشده'
            },


        }
    }

</script>

<style scoped>

</style>
