<template>
        <v-main>
            <v-snackbar v-model="snackbarOkay" color="#f2c4d3" :timeout="3000" centered>
            <span style="color: #333333;">
                You have successfully registered to Hubbcast Developer Panel! You will be redirected to the login page.
            </span>
            </v-snackbar>

            <v-container fluid fill-height class="bg">
                <v-layout align-center justify-center>
                    <!--                    Large Page-->
                    <v-card
                        shaped
                        :loading="loading"
                        width="70%"
                        max-width="800pt"
                        color="white"
                        class="elevation-20"
                        rounded="20"
                        v-if="isHeaderLarge"
                    >
                        <v-row>
                            <v-col cols="5">
                                <v-card

                                    class="elevation-0"
                                    width="100%">
                                    <v-card-text>
                                        <v-card-title class="text-black-50 text-no-wrap">
                                            Register to Hubbcast developer panel
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Unlock Innovation - Power Your Ideas with Hubbcast.
                                        </v-card-subtitle>


                                        <v-form
                                            ref="form"
                                            v-model="valid"
                                            lazy-validation

                                        >
                                            <v-text-field
                                                dense
                                                rounded
                                                v-model="form.first_name"
                                                label="First name*"
                                                :counter="20"
                                                :rules="nameRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                dense
                                                rounded
                                                v-model="form.surname"
                                                label="Surname*"
                                                :counter="20"
                                                :rules="surnameRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                dense
                                                rounded
                                                v-model="form.email"
                                                label="Email*"
                                                :rules="emailRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                dense
                                                rounded
                                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                @click:append="showPassword = !showPassword"
                                                :type="showPassword ? 'text' : 'password'"
                                                v-model="form.password"
                                                label="Password*"
                                                :rules="passwordRules"
                                                outlined
                                                required

                                            ></v-text-field>
                                            <v-text-field
                                                dense
                                                rounded
                                                :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                                                @click:append="showConfirmPassword = !showConfirmPassword"
                                                :type="showConfirmPassword ? 'text' : 'password'"
                                                v-model="form.password_confirmation"
                                                label="Repeat password*"
                                                :rules="confirmPasswordRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-checkbox
                                                v-model="checkbox"
                                                label="Keep me logged in"
                                            ></v-checkbox>
                                            <v-alert
                                                dense
                                                border="left"
                                                type="warning"
                                                v-if="snackbarNokay"
                                            >
                                                Unfortunately, your email address has already been registered.
                                            </v-alert>
                                            <v-btn
                                                rounded
                                                :loading="loading"
                                                :disabled="!valid"
                                                color="info"
                                                width="100%"
                                                depressed
                                                class="ma-2 white--text"
                                                @click="postRegistrationData"
                                                block
                                                elevation="10"
                                            >

                                                Register
                                            </v-btn>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-text class="my-1 ">
                                        Go to login page via:
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="navigateToLogin"
                                        >
                                            Login
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="my-auto mx-auto">
                                <img src="../../../assets/icons/Panel/Register.svg">

                            </v-col>
                        </v-row>

                    </v-card>
                    <v-card
                        shaped
                        :loading="loading"
                        width="80%"
                        max-width="550pt"
                        color="white"
                        class="elevation-20 my-10"
                        rounded="20"
                        v-if="isHeaderMedium"
                    >
                        <v-row>
                            <v-col cols="9" class="mx-auto">
                                <img src="../../../assets/icons/Panel/Register.svg">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="11">
                                <v-card
                                    class="elevation-0"
                                    width="100%">
                                    <v-card-text>
                                        <v-card-title class="text-black-50 title-wrap">
                                            Register to Hubbcast developer panel
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Unlock Innovation - Power Your Ideas with Hubbcast.
                                        </v-card-subtitle>

                                        <v-form
                                            ref="form"
                                            v-model="valid"
                                            lazy-validation

                                        >
                                            <v-text-field
                                                rounded
                                                v-model="form.first_name"
                                                label="First name*"
                                                :counter="20"
                                                :rules="nameRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                v-model="form.surname"
                                                label="Surname*"
                                                :counter="20"
                                                :rules="surnameRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                v-model="form.email"
                                                label="Email*"
                                                :rules="emailRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                @click:append="showPassword = !showPassword"
                                                :type="showPassword ? 'text' : 'password'"
                                                v-model="form.password"
                                                label="Password*"
                                                :rules="passwordRules"
                                                outlined
                                                required

                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                                                @click:append="showConfirmPassword = !showConfirmPassword"
                                                :type="showConfirmPassword ? 'text' : 'password'"
                                                v-model="form.password_confirmation"
                                                label="Repeat password*"
                                                :rules="confirmPasswordRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-checkbox
                                                v-model="checkbox"
                                                label="Keep me logged in"
                                            ></v-checkbox>
                                            <v-alert
                                                dense
                                                border="left"
                                                type="warning"
                                                v-if="snackbarNokay"
                                            >
                                                Unfortunately, your email address has already been registered.
                                            </v-alert>
                                            <v-btn
                                                rounded
                                                :loading="loading"
                                                :disabled="!valid"
                                                color="info"
                                                width="100%"
                                                depressed
                                                class="ma-2 white--text"
                                                @click="postRegistrationData"
                                                block
                                                elevation="10"
                                            >

                                                Register
                                            </v-btn>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-text class="my-1 ">
                                        Go to login panel via
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="navigateToLogin"
                                        >
                                            Login
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>

                    </v-card>

                    <!--                    Small size-->
                    <v-card

                        shaped
                        :loading="loading"
                        width="80%"
                        min-width="250pt"
                        color="white"
                        class="elevation-20 my-10"
                        rounded="20"
                        v-if="isHeaderSmall"
                    >
                        <v-row>
                            <v-col cols="10" class="mx-auto">
                                <img src="../../../assets/icons/Panel/Register.svg">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="11">
                                <v-card
                                    class="elevation-0"
                                    width="100%">
                                    <v-card-text class="">
                                        <v-card-title class="text-black-50 title-wrap">
                                           Register to Hubbcast developer panel
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Unlock Innovation - Power Your Ideas with Hubbcast.
                                        </v-card-subtitle>

                                        <v-form
                                            ref="form"
                                            v-model="valid"
                                            lazy-validation

                                        >
                                            <v-text-field
                                                rounded
                                                v-model="form.first_name"
                                                label="First name*"
                                                :counter="20"
                                                :rules="nameRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                v-model="form.surname"
                                                label="Surname*"
                                                :counter="20"
                                                :rules="surnameRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                v-model="form.email"
                                                label="Email*"
                                                :rules="emailRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                @click:append="showPassword = !showPassword"
                                                :type="showPassword ? 'text' : 'password'"
                                                v-model="form.password"
                                                label="Password*"
                                                :rules="passwordRules"
                                                outlined
                                                required

                                            ></v-text-field>
                                            <v-text-field
                                                rounded
                                                :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                                                @click:append="showConfirmPassword = !showConfirmPassword"
                                                :type="showConfirmPassword ? 'text' : 'password'"
                                                v-model="form.password_confirmation"
                                                label="Repeat password*"
                                                :rules="confirmPasswordRules"
                                                outlined
                                                required
                                            ></v-text-field>
                                            <v-checkbox
                                                v-model="checkbox"
                                                label="Keep me logged in"
                                            ></v-checkbox>
                                            <v-alert
                                                dense
                                                border="left"
                                                type="warning"
                                                v-if="snackbarNokay"
                                            >
                                                Unfortunately, your email address has already been registered.
                                            </v-alert>
                                            <v-btn
                                                rounded
                                                :loading="loading"
                                                :disabled="!valid"
                                                color="info"
                                                width="100%"
                                                depressed
                                                class="ma-2 white--text"
                                                @click="postRegistrationData"
                                                block
                                                elevation="10"
                                            >

                                                Register
                                            </v-btn>
                                        </v-form>
                                    </v-card-text>
                                    <!--                                    <v-card-actions>-->
                                    <!--                                        <v-btn color="primary" to="/">Login</v-btn>-->
                                    <!--                                    </v-card-actions>-->
                                    <v-card-text class="my-1">
                                        Go to login Page via:
                                        <v-btn
                                            class="ma-0"
                                            text
                                            color="primary"
                                            @click="navigateToLogin"
                                        >
                                            Login
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-card>
                </v-layout>
            </v-container>
        </v-main>
</template>

<script>
    // import routerLanding from "../../routerLanding";
    export default {
        name: 'landingApp',
        props: {
            source: String,
        },
        methods: {
            navigateToLogin() {
                this.$router.push({name: 'login'});
            },
            postRegistrationData() {
                if (true) {
                    axios.post(`../panel/register`, this.form)
                        .then(response => {
                            console.log(response);
                            console.log('user info is registered');
                            this.snackbarOkay = true;
                            // Redirect to login page after 5 seconds (or however long you want the delay to be)
                            setTimeout(() => {
                                this.$router.push({name: 'login'});
                            }, 3000);
                        })
                        .catch(error => {
                            // handle any errors that occur during the request
                            this.snackbarNokay = true;
                            console.error(error);
                        });
                }
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            wait(ms) {
                let start = new Date().getTime();
                let end = start;
                while (end < start + ms) {
                    end = new Date().getTime();
                }
            },
            validate() {
                this.$refs.form.validate()
            }
            ,
            reset() {
                this.$refs.form.reset()
            }
            ,
        },
        data() {
            return {
                snackbarNokay: false,
                snackbarOkay: false,
                checkbox: false,
                valid: true,
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 20) || 'Name must be less than 20 characters',
                ],
                surnameRules: [
                    v => !!v || 'Surname is required',
                    v => (v && v.length <= 20) || 'Surname must be less than 20 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                passwordRules: [
                    (v) => !!v || 'Please type password.',
                    (v) => (v && v.length >= 8) || 'minimum 8 characters',
                ],
                confirmPasswordRules: [
                    (v) => !!v || 'type confirm password',
                    (v) =>
                        v === this.form.password || 'The password confirmation does not match!!!!!',
                ],
                confirmPassword: '',
                form:
                    {
                        'first_name': '', 'surname': '', 'email': '', 'password': '', 'password_confirmation': ''
                    },
                loader: null,
                showPassword: false,
                showConfirmPassword: false,
                loading: false,
            }
        }
        ,
        computed: {

            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            }
            ,
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            }
            ,
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            }
            ,
        }
        ,
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            }
            ,
        }
        ,
    }
    ;

</script>

<style scoped>
    .bg {
        background: url("../../../assets/icons/Background.svg") no-repeat center center;
        background-size: cover;
    }

    .v-btn {
        text-transform: none !important;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .title-wrap {
        overflow-wrap: break-word; /* This helps to break long words */
        word-wrap: break-word;     /* This is an alias for overflow-wrap */
        white-space: normal;       /* This ensures that text wraps normally */
        word-break: keep-all; /* Prevents breaking words */
    }

</style>
