<template>

    <div style="width: 100%; height: 100%;">
        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <!--            <mapMarker-->
            <!--                :coordinates.sync="usersinfos"-->
            <!--                color="Red"-->
            <!--            />-->
            <!--            <mapGeojsonLayer-->
            <!--                sourceId="mySource"-->
            <!--                :source="geoJsonSource"-->
            <!--                layerId="myLayer"-->
            <!--                :layer="geoJsonlayer"-->
            <!--            />-->
            <MglGeojsonLayer
                sourceId="mySource"
                :source="geoJsonSource2"
                layerId="myLayer"
                :layer="geoJsonlayer2"
            />
            <MglMarker v-for="usersinfo in users" :key="usersinfo"
                       :coordinates.sync="usersinfo.LatLong"
                       :color="colors">
                <MglPopup>
                    <VCard>
                        <div>سلام من {{usersinfo.first_name}} هستم، زمان اخرین بازدید من {{usersinfo.Time}}</div>
                    </VCard>
                </MglPopup>
            </MglMarker>
        </MglMap>
    </div>
</template>

<script>
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";


    export default {
        name: "example-component",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        data() {
            return {
                users: '',
                usersinfos: '',
                colors: "green",
                coordinates: [51.420296, 35.732379],
                centers: [51.420296, 35.732379],
                markerCoordinates: [51.420296, 35.732379],
                markerCoordinates1: [[52.420296, 35.732379], [53.420296, 35.732379]],
                markerCoordinates2: [53.420296, 35.732379],
                // mapirToken: "<Your API Key>"
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
                geoJsonSource: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            [51.3898668, 35.6899503],
                                            [51.6254851, 35.8203263],
                                            [51.3898668, 35.8203263],
                                            [51.6254851, 35.6899503],
                                        ]
                                    ]
                                }
                            },
                        ]
                    }
                },
                geoJsonlayer: {
                    id: "myLayer",
                    type: "fill",
                    source: "mySource",
                    paint: {
                        "fill-color": "#888888",
                        "fill-opacity": 0.4
                    }
                },

                geoJsonSource2: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            [51.3898668, 35.6899503],
                                            [51.3898668, 35.8041560],
                                            [51.1306695, 35.8041560],
                                            [51.1306695, 35.6899503],
                                        ]
                                    ]
                                }
                            },
                        ]
                    }
                },
                geoJsonlayer2: {
                    id: "myLayer",
                    type: "fill",
                    source: "mySource",
                    paint: {
                        "fill-color": "#777777",
                        "fill-opacity": 0.4
                    }
                }

            };
        },
        created() {
            this.fetchData();

        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/alluserlocation')
                    .then(response => {
                        this.users = response.data[0].data;
                        // this.usersinfos = this.users[1].LatLong;
                        // console.log(this.users[1]);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            }
        }
    }
</script>

<style scoped>
    .map-view {
        height: calc(100vh - 48px);
    }
</style>

<!--<template>-->
<!--    <div >-->

<!--        <LocationMap :doginfos="doginfos"></LocationMap>-->
<!--    </div>-->
<!--</template>-->
<!--<h1>Live Location of Dogs</h1>-->
<!--<script>-->
<!--    import LocationMap from "../components/LocationMap";-->
<!--    import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';-->
<!--    export default {-->
<!--        name: "AllMapShow",-->
<!--        components: {-->
<!--            LocationMap-->
<!--        },-->
<!--        mounted(){-->
<!--            axios.get('/dogdata')-->
<!--                .then(response =>{-->
<!--                    this.doginfos = response.data[0].data;-->
<!--                    this.loading = false;-->
<!--                })-->
<!--                .catch(errors =>{-->
<!--                    this.loading = false;-->
<!--                    alert('unable to fetch');-->
<!--                })-->
<!--        },-->

<!--        data:function () {-->
<!--            return {-->
<!--                loading:true,-->
<!--                doginfos: null,-->
<!--            }-->
<!--        },-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->


<!--&lt;!&ndash;<template>&ndash;&gt;-->

<!--&lt;!&ndash;    <div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="label-top" v-html="label"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="uploader-container mx-auto" :class="{dense:dense,dark:dark,disabled:disabled,'has-value':true}">&ndash;&gt;-->
<!--&lt;!&ndash;            <v-slide-y-transition>&ndash;&gt;-->
<!--&lt;!&ndash;                <v-avatar&ndash;&gt;-->
<!--&lt;!&ndash;                    v-if="last_image"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="m-2 prev-img"&ndash;&gt;-->
<!--&lt;!&ndash;                    size="76"&ndash;&gt;-->
<!--&lt;!&ndash;                    :style="clearable?'top: 50%;':''"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                    <img :src="image_url">&ndash;&gt;-->
<!--&lt;!&ndash;                </v-avatar>&ndash;&gt;-->
<!--&lt;!&ndash;            </v-slide-y-transition>&ndash;&gt;-->

<!--&lt;!&ndash;            <file-pond&ndash;&gt;-->
<!--&lt;!&ndash;                v-if="!clearable || !last_image"&ndash;&gt;-->
<!--&lt;!&ndash;                ref="pond"&ndash;&gt;-->
<!--&lt;!&ndash;                class="mx-auto pointer-pointer fadeIn"&ndash;&gt;-->
<!--&lt;!&ndash;                :name="paramName"&ndash;&gt;-->
<!--&lt;!&ndash;                style="min-height: 84px"&ndash;&gt;-->

<!--&lt;!&ndash;                :label-idle="&ndash;&gt;-->
<!--&lt;!&ndash;        (placeholderImage&ndash;&gt;-->
<!--&lt;!&ndash;          ? <img src='${placeholderImage}' class='placeholder'>&ndash;&gt;-->
<!--&lt;!&ndash;          : '') +&ndash;&gt;-->
<!--&lt;!&ndash;          `<p class='text-dark m-0'><i class='fas fa-plus text-success ml-2'></i> ${$t(&ndash;&gt;-->
<!--&lt;!&ndash;            'global.image_uploader.label'&ndash;&gt;-->
<!--&lt;!&ndash;          )}  </p>`&ndash;&gt;-->
<!--&lt;!&ndash;      "&ndash;&gt;-->
<!--&lt;!&ndash;                :allow-multiple="allowMultiple"&ndash;&gt;-->
<!--&lt;!&ndash;                :accepted-file-types="acceptedFileTypes"&ndash;&gt;-->
<!--&lt;!&ndash;                :server="server_credential"&ndash;&gt;-->
<!--&lt;!&ndash;                :files="logo"&ndash;&gt;-->
<!--&lt;!&ndash;                :maxFileSize="maxFileSize"&ndash;&gt;-->
<!--&lt;!&ndash;                :max-files="maxFiles"&ndash;&gt;-->
<!--&lt;!&ndash;                check-validity="true"&ndash;&gt;-->
<!--&lt;!&ndash;                @processfile="handleProcessFile"&ndash;&gt;-->
<!--&lt;!&ndash;                @error="handleFilePondError"&ndash;&gt;-->


<!--&lt;!&ndash;                allowImageResize="true"&ndash;&gt;-->
<!--&lt;!&ndash;                imageResizeUpscale="false"&ndash;&gt;-->
<!--&lt;!&ndash;                imageResizeMode="contain"&ndash;&gt;-->
<!--&lt;!&ndash;                :imageResizeTargetWidth="imageResizeTargetWidth"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->

<!--&lt;!&ndash;            <p class="small file-size-limit">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ $t("global.image_uploader.max_size", { size: maxFileSize }) }}&ndash;&gt;-->
<!--&lt;!&ndash;            </p>&ndash;&gt;-->

<!--&lt;!&ndash;            <v-btn fab depressed small v-if="clearable && last_image" @click.stop="()=>{last_image=null;$emit('onClear')}" class="absolute-top-end m-2 z2"&ndash;&gt;-->
<!--&lt;!&ndash;            ><v-icon>close</v-icon></v-btn&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;</template>&ndash;&gt;-->

<!--&lt;!&ndash;<script>&ndash;&gt;-->
<!--&lt;!&ndash;    export default {&ndash;&gt;-->
<!--&lt;!&ndash;        name: "ImageUploader",&ndash;&gt;-->
<!--&lt;!&ndash;        props: {&ndash;&gt;-->
<!--&lt;!&ndash;            label: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: String&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            server: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: String&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            maxFileSize: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: String,&ndash;&gt;-->
<!--&lt;!&ndash;                default: "1MB"&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            image: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: String&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            placeholderImage: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: String&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            paramName: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: String,&ndash;&gt;-->
<!--&lt;!&ndash;                default: "photo"&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            maxFiles: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: Number,&ndash;&gt;-->
<!--&lt;!&ndash;                default: 1&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            allowMultiple: {&ndash;&gt;-->
<!--&lt;!&ndash;                required: false,&ndash;&gt;-->
<!--&lt;!&ndash;                type: Boolean,&ndash;&gt;-->
<!--&lt;!&ndash;                default: false&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            clearable: {&ndash;&gt;-->
<!--&lt;!&ndash;                type: Boolean,&ndash;&gt;-->
<!--&lt;!&ndash;                default: false&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            dense: {&ndash;&gt;-->
<!--&lt;!&ndash;                type: Boolean,&ndash;&gt;-->
<!--&lt;!&ndash;                default: false&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            dark: {&ndash;&gt;-->
<!--&lt;!&ndash;                type: Boolean,&ndash;&gt;-->
<!--&lt;!&ndash;                default: false&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->

<!--&lt;!&ndash;            disabled: {&ndash;&gt;-->
<!--&lt;!&ndash;                type: Boolean,&ndash;&gt;-->
<!--&lt;!&ndash;                default: false&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            imageResizeTargetWidth:{&ndash;&gt;-->
<!--&lt;!&ndash;                default:1600&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->


<!--&lt;!&ndash;            noSvg: {&ndash;&gt;-->
<!--&lt;!&ndash;                type: Boolean,&ndash;&gt;-->
<!--&lt;!&ndash;                default: false&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;        },&ndash;&gt;-->

<!--&lt;!&ndash;        data: () => ({&ndash;&gt;-->
<!--&lt;!&ndash;            logo: null,&ndash;&gt;-->
<!--&lt;!&ndash;            version: "",&ndash;&gt;-->
<!--&lt;!&ndash;            last_image:null&ndash;&gt;-->
<!--&lt;!&ndash;        }),&ndash;&gt;-->

<!--&lt;!&ndash;        computed: {&ndash;&gt;-->

<!--&lt;!&ndash;            acceptedFileTypes(){&ndash;&gt;-->
<!--&lt;!&ndash;                return this.noSvg?"image/jpeg, image/png, image/webp":"image/jpeg, image/png, image/svg+xml, image/webp"&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            image_url() {&ndash;&gt;-->
<!--&lt;!&ndash;                if (!this.image) return null;&ndash;&gt;-->
<!--&lt;!&ndash;                return this.image + this.version;&ndash;&gt;-->
<!--&lt;!&ndash;            },&ndash;&gt;-->
<!--&lt;!&ndash;            server_credential() {&ndash;&gt;-->

<!--&lt;!&ndash;                const token = document.head.querySelector('meta[name="csrf-token"]');&ndash;&gt;-->

<!--&lt;!&ndash;                return {&ndash;&gt;-->
<!--&lt;!&ndash;                    url: this.server,&ndash;&gt;-->
<!--&lt;!&ndash;                    process: {&ndash;&gt;-->
<!--&lt;!&ndash;                        method: "POST",&ndash;&gt;-->
<!--&lt;!&ndash;                        withCredentials: true,&ndash;&gt;-->

<!--&lt;!&ndash;                        headers: {&ndash;&gt;-->
<!--&lt;!&ndash;                            'X-CSRF-TOKEN': token?token.content:''&ndash;&gt;-->
<!--&lt;!&ndash;                        },&ndash;&gt;-->
<!--&lt;!&ndash;                    }&ndash;&gt;-->
<!--&lt;!&ndash;                };&ndash;&gt;-->
<!--&lt;!&ndash;            }&ndash;&gt;-->
<!--&lt;!&ndash;        },&ndash;&gt;-->
<!--&lt;!&ndash;        watch:{&ndash;&gt;-->
<!--&lt;!&ndash;            image(){&ndash;&gt;-->
<!--&lt;!&ndash;                this.last_image=this.image;&ndash;&gt;-->
<!--&lt;!&ndash;            }&ndash;&gt;-->
<!--&lt;!&ndash;        },&ndash;&gt;-->
<!--&lt;!&ndash;        created() {&ndash;&gt;-->
<!--&lt;!&ndash;            this.last_image=this.image;&ndash;&gt;-->
<!--&lt;!&ndash;            this.version = "?v=" + Math.random(100); //Invalidate cache!&ndash;&gt;-->


<!--&lt;!&ndash;        },&ndash;&gt;-->

<!--&lt;!&ndash;        methods: {&ndash;&gt;-->
<!--&lt;!&ndash;                handleFilePondError(error){&ndash;&gt;-->
<!--&lt;!&ndash;                    this.showErrorAlert(null,error.body)&ndash;&gt;-->
<!--&lt;!&ndash;                    //  console.error('handleFilePondError',error)&ndash;&gt;-->
<!--&lt;!&ndash;                },&ndash;&gt;-->
<!--&lt;!&ndash;            handleProcessFile: function(error, file) {&ndash;&gt;-->
<!--&lt;!&ndash;                if (!error) {&ndash;&gt;-->
<!--&lt;!&ndash;                    let response = JSON.parse(file.serverId);&ndash;&gt;-->
<!--&lt;!&ndash;                    this.$emit("response", response);&ndash;&gt;-->

<!--&lt;!&ndash;                    if (Array.isArray(response.files)) {&ndash;&gt;-->
<!--&lt;!&ndash;                        response.files.forEach(file => {&ndash;&gt;-->
<!--&lt;!&ndash;                            console.log("done", file.path);&ndash;&gt;-->
<!--&lt;!&ndash;                            this.$emit("new-path", file.path);&ndash;&gt;-->
<!--&lt;!&ndash;                            this.$emit("new-url", file.url);&ndash;&gt;-->
<!--&lt;!&ndash;                        });&ndash;&gt;-->
<!--&lt;!&ndash;                    } else {&ndash;&gt;-->
<!--&lt;!&ndash;                        console.log("done", response.files.path);&ndash;&gt;-->
<!--&lt;!&ndash;                        this.$emit("new-path", response.files.path);&ndash;&gt;-->
<!--&lt;!&ndash;                        this.$emit("new-url", response.files.url);&ndash;&gt;-->

<!--&lt;!&ndash;                        this.$nextTick(() => {&ndash;&gt;-->
<!--&lt;!&ndash;                            this.version = "?v=" + Math.random(100);&ndash;&gt;-->
<!--&lt;!&ndash;                        });&ndash;&gt;-->
<!--&lt;!&ndash;                    }&ndash;&gt;-->

<!--&lt;!&ndash;                    return true;&ndash;&gt;-->
<!--&lt;!&ndash;                }&ndash;&gt;-->
<!--&lt;!&ndash;            }&ndash;&gt;-->
<!--&lt;!&ndash;        }&ndash;&gt;-->
<!--&lt;!&ndash;    };&ndash;&gt;-->
<!--&lt;!&ndash;</script>&ndash;&gt;-->

<!--&lt;!&ndash;<template>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="container">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="row justify-content-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="col-md-8">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="card">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="card-header">Example Component</div>&ndash;&gt;-->

<!--&lt;!&ndash;                    <div class="card-body">&ndash;&gt;-->
<!--&lt;!&ndash;                        I'm an example component.&ndash;&gt;-->
<!--&lt;!&ndash;                        Please show me&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;</template>&ndash;&gt;-->

<!--&lt;!&ndash;<script>&ndash;&gt;-->
<!--&lt;!&ndash;    export default {&ndash;&gt;-->
<!--&lt;!&ndash;        mounted() {&ndash;&gt;-->
<!--&lt;!&ndash;            console.log('Component mounted.')&ndash;&gt;-->
<!--&lt;!&ndash;        }&ndash;&gt;-->
<!--&lt;!&ndash;    }&ndash;&gt;-->
<!--&lt;!&ndash;</script>&ndash;&gt;-->
<!--&lt;!&ndash;<template>&ndash;&gt;-->
<!--&lt;!&ndash;    <div >&ndash;&gt;-->

<!--&lt;!&ndash;        <LocationMap :doginfos="doginfos"></LocationMap>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;</template>&ndash;&gt;-->
<!--&lt;!&ndash;<h1>Live User of Dogs</h1>&ndash;&gt;-->
<!--&lt;!&ndash;<script>&ndash;&gt;-->
<!--&lt;!&ndash;    import LocationMap from "../components/LocationMap";&ndash;&gt;-->
<!--&lt;!&ndash;    import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';&ndash;&gt;-->
<!--&lt;!&ndash;    export default {&ndash;&gt;-->
<!--&lt;!&ndash;        name: "AllMapShow",&ndash;&gt;-->
<!--&lt;!&ndash;        components: {&ndash;&gt;-->
<!--&lt;!&ndash;            LocationMap&ndash;&gt;-->
<!--&lt;!&ndash;        },&ndash;&gt;-->
<!--&lt;!&ndash;        mounted(){&ndash;&gt;-->
<!--&lt;!&ndash;            axios.get('/dogdata')&ndash;&gt;-->
<!--&lt;!&ndash;                .then(response =>{&ndash;&gt;-->
<!--&lt;!&ndash;                    this.doginfos = response.data[0].data;&ndash;&gt;-->
<!--&lt;!&ndash;                    this.loading = false;&ndash;&gt;-->
<!--&lt;!&ndash;                })&ndash;&gt;-->
<!--&lt;!&ndash;                .catch(errors =>{&ndash;&gt;-->
<!--&lt;!&ndash;                    this.loading = false;&ndash;&gt;-->
<!--&lt;!&ndash;                    alert('unable to fetch');&ndash;&gt;-->
<!--&lt;!&ndash;                })&ndash;&gt;-->
<!--&lt;!&ndash;        },&ndash;&gt;-->

<!--&lt;!&ndash;        data:function () {&ndash;&gt;-->
<!--&lt;!&ndash;            return {&ndash;&gt;-->
<!--&lt;!&ndash;                loading:true,&ndash;&gt;-->
<!--&lt;!&ndash;                doginfos: null,&ndash;&gt;-->
<!--&lt;!&ndash;            }&ndash;&gt;-->
<!--&lt;!&ndash;        },&ndash;&gt;-->
<!--&lt;!&ndash;    }&ndash;&gt;-->
<!--&lt;!&ndash;</script>&ndash;&gt;-->

<!--&lt;!&ndash;<style scoped>&ndash;&gt;-->

<!--&lt;!&ndash;</style>&ndash;&gt;-->
