<template>

</template>

<script>
    export default {
        name: "Logout",
        created() {
            // localStorage.clear();
            localStorage.removeItem('isDeveloperAuthenticated');
            axios.get('../panel/logout-developer',{})
                .finally(err=>{
                    window.location = `../panel/login`;
                })
        }
    }
</script>

<style scoped>

</style>
