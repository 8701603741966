<template>

    <div style="width: 100%; height: 100%;">

        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <MglGeojsonLayer
                sourceId="mySource"
                :source="geoJsonSource"
                layerId="myLayer"
                :layer="geoJsonlayer"
            >
                <MglPopup anchor="top" :showed="true">
                    <VCard>
                        <div>Hello, I'm popup!</div>
                    </VCard>
                </MglPopup>
            </MglGeojsonLayer>

            <MglGeojsonLayer
                sourceId="mySource2"
                :source="geoJsonSource2"
                layerId="myLayer2"
                :layer="geoJsonlayer2"
            >
                <MglPopup>
                    <VCard>
                        <div> سلام، اخرین زمان انلاین شدن من دقیقه پیش</div>
                    </VCard>
                </MglPopup>
            </MglGeojsonLayer>
            <!--            <mapMarker :coordinates.sync="this.markerCoordinates2[0]" :color="colors">-->
            <!--                <mapPopup>-->
            <!--                    <VCard>-->
            <!--                        <div>  سلام، اخرین زمان انلاین شدن من دقیقه {{time}} پیش </div>-->
            <!--                    </VCard>-->
            <!--                </mapPopup>-->
            <!--            </mapMarker>-->
        </MglMap>

        <!--        <mapir :apiKey="mapirToken" :center="centers">-->
        <!--&lt;!&ndash;            <mapMarker&ndash;&gt;-->
        <!--&lt;!&ndash;                :coordinates.sync="usersinfos"&ndash;&gt;-->
        <!--&lt;!&ndash;                color="Red"&ndash;&gt;-->
        <!--&lt;!&ndash;            />&ndash;&gt;-->
        <!--            <mapMarker v-for="userlocation in userslocation"-->
        <!--                       :coordinates.sync="userlocation.LatLong"-->
        <!--                       :color="colors">-->
        <!--                <mapPopup>-->
        <!--                    <VCard>-->
        <!--                        <div>Hello, I'm Mohammad, last update {{userlocation.Time}} min ago</div>-->
        <!--                    </VCard>-->
        <!--                </mapPopup>-->
        <!--            </mapMarker>-->
        <!--        </mapir>-->
    </div>
</template>

<script>
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";

    export default {
        name: "UserLocation",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        data() {
            // var markerCoordinates1= [[52.420296, 35.732379],[53.420296, 35.732379]];

            return {
                coordinates: [51.373155, 35.688368],
                markerCoordinates2: [],
                markerCoordinates3: [],
                zoom: 10,
                userslocation: '',
                time: '',
                usersinfos: '',
                driver1: '',
                driver2: '',
                colors: "green",
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
                geoJsonlayer: {
                    id: "myLayer",
                    type: "line",
                    source: "mySource",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    paint: {
                        "line-color": "#088",
                        "line-width": 6
                    }
                },
                geoJsonlayer2: {
                    id: "myLayer2",
                    type: "line",
                    source: "mySource2",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    paint: {
                        "line-color": "red",
                        "line-width": 6
                    }
                }
            };
        },
        computed: {
            // coordinates(){
            //     return this.userslocation[0]["LatLong"];
            // },

            geoJsonSource() {

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",

                                    coordinates: this.markerCoordinates2
                                    // coordinates: [
                                    //     [51.4429329, 35.6047140],
                                    //     [51.4355518, 35.6048126],
                                    //     [51.4276772, 35.6053393],
                                    //
                                    // ]
                                }
                            },
                            // {
                            //     type: "Feature",
                            //     properties: {},
                            //     geometry: {
                            //         type: "LineString",
                            //         coordinates:[
                            //             [51.4358800, 35.6058112],
                            //             [51.4401459, 35.6094621],
                            //             [51.4415519, 35.6128540],
                            //         ]
                            //     }
                            // }
                        ]
                    }
                };
            },


            geoJsonSource2() {

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            // {
                            //     type: "Feature",
                            //     properties: {},
                            //     geometry: {
                            //         type: "LineString",
                            //
                            //         // coordinates:this.markerCoordinates2
                            //         coordinates:[
                            //             [51.4429329, 35.6047140],
                            //             [51.4355518, 35.6048126],
                            //             [51.4276772, 35.6053393],
                            //
                            //         ]
                            //     }
                            // },
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                    coordinates: this.markerCoordinates3

                                    // coordinates: [
                                    //     [51.4358800, 35.6058112],
                                    //     [51.4401459, 35.6094621],
                                    //     [51.4415519, 35.6128540],
                                    // ]
                                }
                            }
                        ]
                    }
                };
            }
        },
        created() {
            this.fetchData();

        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/car/' + this.$route.params.id)
                    .then(response => {
                        this.driver1 = response.data.driver1;
                        axios.get('/usertrack/' + this.driver1)
                            .then(response => {
                                this.userslocation = response.data;
                                var i;
                                this.time = this.userslocation[0]["Time"];
                                for (i = 0; i < this.userslocation.length; i++) {
                                    this.markerCoordinates2[i] = this.userslocation[i]['LatLong'];
                                }
                            });
                        this.driver2 = response.data.driver2;
                        axios.get('/usertrack/' + this.driver2)
                            .then(response => {
                                this.userslocation = response.data;
                                var i;
                                this.time = this.userslocation[0]["Time"];
                                for (i = 0; i < this.userslocation.length; i++) {
                                    this.markerCoordinates3[i] = this.userslocation[i]['LatLong'];
                                }
                            });
                    })
                    .catch(errors => {
                    }).finally(() => {
                    this.busy = false;
                })
                // axios.get('/usertrack/' + this.$route.params.id)
                //     .then(response =>{
                //         this.userslocation = response.data;
                //         var i;
                //         this.time = this.userslocation[0]["Time"];
                //          for (i = 0; i < this.userslocation.length; i++) {
                //              this.markerCoordinates2[i] = this.userslocation[i]['LatLong'];
                //          }
                //     })
                //     .catch(errors =>{
                //         alert('unable to fetch');
                //     }).finally(()=>{
                //     this.busy = false;
                // })
            },
        }
    }
</script>

<style scoped>
    .map-view {
        height: calc(100vh - 48px);
    }
</style>

<!--<template>-->

<!--    <div>-->
<!--        <div class="label-top" v-html="label"></div>-->
<!--        <div class="uploader-container mx-auto" :class="{dense:dense,dark:dark,disabled:disabled,'has-value':true}">-->
<!--            <v-slide-y-transition>-->
<!--                <v-avatar-->
<!--                    v-if="last_image"-->
<!--                    class="m-2 prev-img"-->
<!--                    size="76"-->
<!--                    :style="clearable?'top: 50%;':''"-->
<!--                >-->
<!--                    <img :src="image_url">-->
<!--                </v-avatar>-->
<!--            </v-slide-y-transition>-->

<!--            <file-pond-->
<!--                v-if="!clearable || !last_image"-->
<!--                ref="pond"-->
<!--                class="mx-auto pointer-pointer fadeIn"-->
<!--                :name="paramName"-->
<!--                style="min-height: 84px"-->

<!--                :label-idle="-->
<!--        (placeholderImage-->
<!--          ? <img src='${placeholderImage}' class='placeholder'>-->
<!--          : '') +-->
<!--          `<p class='text-dark m-0'><i class='fas fa-plus text-success ml-2'></i> ${$t(-->
<!--            'global.image_uploader.label'-->
<!--          )}  </p>`-->
<!--      "-->
<!--                :allow-multiple="allowMultiple"-->
<!--                :accepted-file-types="acceptedFileTypes"-->
<!--                :server="server_credential"-->
<!--                :files="logo"-->
<!--                :maxFileSize="maxFileSize"-->
<!--                :max-files="maxFiles"-->
<!--                check-validity="true"-->
<!--                @processfile="handleProcessFile"-->
<!--                @error="handleFilePondError"-->


<!--                allowImageResize="true"-->
<!--                imageResizeUpscale="false"-->
<!--                imageResizeMode="contain"-->
<!--                :imageResizeTargetWidth="imageResizeTargetWidth"-->
<!--            />-->

<!--            <p class="small file-size-limit">-->
<!--                {{ $t("global.image_uploader.max_size", { size: maxFileSize }) }}-->
<!--            </p>-->

<!--            <v-btn fab depressed small v-if="clearable && last_image" @click.stop="()=>{last_image=null;$emit('onClear')}" class="absolute-top-end m-2 z2"-->
<!--            ><v-icon>close</v-icon></v-btn-->
<!--            >-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--    export default {-->
<!--        name: "ImageUploader",-->
<!--        props: {-->
<!--            label: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            server: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->

<!--            maxFileSize: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "1MB"-->
<!--            },-->

<!--            image: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            placeholderImage: {-->
<!--                required: false,-->
<!--                type: String-->
<!--            },-->
<!--            paramName: {-->
<!--                required: false,-->
<!--                type: String,-->
<!--                default: "photo"-->
<!--            },-->

<!--            maxFiles: {-->
<!--                required: false,-->
<!--                type: Number,-->
<!--                default: 1-->
<!--            },-->

<!--            allowMultiple: {-->
<!--                required: false,-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            clearable: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dense: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            dark: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->

<!--            disabled: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--            imageResizeTargetWidth:{-->
<!--                default:1600-->
<!--            },-->


<!--            noSvg: {-->
<!--                type: Boolean,-->
<!--                default: false-->
<!--            },-->
<!--        },-->

<!--        data: () => ({-->
<!--            logo: null,-->
<!--            version: "",-->
<!--            last_image:null-->
<!--        }),-->

<!--        computed: {-->

<!--            acceptedFileTypes(){-->
<!--                return this.noSvg?"image/jpeg, image/png, image/webp":"image/jpeg, image/png, image/svg+xml, image/webp"-->
<!--            },-->
<!--            image_url() {-->
<!--                if (!this.image) return null;-->
<!--                return this.image + this.version;-->
<!--            },-->
<!--            server_credential() {-->

<!--                const token = document.head.querySelector('meta[name="csrf-token"]');-->

<!--                return {-->
<!--                    url: this.server,-->
<!--                    process: {-->
<!--                        method: "POST",-->
<!--                        withCredentials: true,-->

<!--                        headers: {-->
<!--                            'X-CSRF-TOKEN': token?token.content:''-->
<!--                        },-->
<!--                    }-->
<!--                };-->
<!--            }-->
<!--        },-->
<!--        watch:{-->
<!--            image(){-->
<!--                this.last_image=this.image;-->
<!--            }-->
<!--        },-->
<!--        created() {-->
<!--            this.last_image=this.image;-->
<!--            this.version = "?v=" + Math.random(100); //Invalidate cache!-->


<!--        },-->

<!--        methods: {-->
<!--            handleFilePondError(error){-->
<!--                this.showErrorAlert(null,error.body)-->
<!--                //  console.error('handleFilePondError',error)-->
<!--            },-->

<!--    handleProcessFile: function(error, file) {-->
<!--        if (!error) {-->
<!--            let response = JSON.parse(file.serverId);-->
<!--            this.$emit("response", response);-->

<!--            if (Array.isArray(response.files)) {-->
<!--                response.files.forEach(file => {-->
<!--                    console.log("done", file.path);-->
<!--                    this.$emit("new-path", file.path);-->
<!--                    this.$emit("new-url", file.url);-->
<!--                });-->
<!--            } else {-->
<!--                console.log("done", response.files.path);-->
<!--                this.$emit("new-path", response.files.path);-->
<!--                this.$emit("new-url", response.files.url);-->

<!--                this.$nextTick(() => {-->
<!--                    this.version = "?v=" + Math.random(100);-->
<!--                });-->
<!--            }-->

<!--            return true;-->
<!--        }-->
<!--    }-->
<!--    }-->
<!--    };-->
<!--</script>-->


