<template>
    <div>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label = "Search for a home using its unique ID"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headerss"
            :items="dogs"
            :search="search"
            :items-per-page="10"
            sort-by="id"
            :sort-desc="true"
            @click:row="item=>$router.push('')"
            :loading="busy"
            :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'item per page',
      }"
        >

            <template v-slot:item.track="{ item }">
                <v-btn @click.prevent="ShowLocation(item.id)" icon>
                    <v-icon>gps_not_fixed</v-icon>
                </v-btn>
            </template>

            <template #item.edit="{item}">
                <!--            <template v-if="this.$store.state.user.access_level == 5">-->
                <v-row justify="center">
                    <v-dialog
                        v-model="dialog1"
                        persistent
                        max-width="600px"
                        :retain-focus="false"
                    >
                        <v-card class="justify-center">
                            <v-card-title>
                                <span class = "headline"> User info </span>
                            </v-card-title>
                            <v-card class="justify-center">
                                <div id="filepond">
                                    <file-pond

                                        name="userPhoto"
                                        ref="pond"
                                        class-name="my-pond"
                                        label-idle = "Feel free to upload a photo if needed."
                                        allow-multiple="false"
                                        accepted-file-types="image/jpeg, image/png"
                                        server="../user/upload"
                                        v-on:processfile="handleProcessFile">
                                    </file-pond>
                                </div>
                            </v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >
                                            <v-text-field
                                                v-model="form.name"
                                                label = "Name *"
                                                hint = "Last Name"
                                                persistent-hint
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="form.national_ID"
                                                label = "Code *"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="form.shenasname"
                                                label = "ID *"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-select
                                                v-model="form.access_level"
                                                :items="access_levels"
                                                label = "User level *"
                                                required
                                                dir="rtl"
                                            ></v-select>
                                        </v-col>
                                        <!--                                        <v-col-->
                                        <!--                                            cols="12"-->
                                        <!--                                            sm="6"-->
                                        <!--                                        >-->
                                        <!--                                            <v-select-->
                                        <!--                                                v-model="form.car_ID"-->
                                        <!--                                                :items="carnumbers"-->
                                        <!--                                                label="سطح کاربر*"-->
                                        <!--                                                required-->
                                        <!--                                                dir="rtl"-->
                                        <!--                                            ></v-select>-->
                                        <!--                                        </v-col>-->
                                        <v-col cols="12"
                                               sm="6"
                                               md="4">
                                            <v-text-field
                                                v-model="form.car_number"
                                                label = "Car Selected Previously"
                                                readonly
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-select
                                                v-model="form.car_number"
                                                :items="carnumbers"
                                                label = "Recent License Plate Number"
                                                dir="rtl"
                                            ></v-select>
                                        </v-col>
                                        <!--                                        <v-col-->
                                        <!--                                            cols="12"-->
                                        <!--                                            sm="6"-->
                                        <!--                                        >-->
                                        <!--                                            <v-autocomplete-->
                                        <!--                                                v-model="form.access_level"-->
                                        <!--                                                :items="carnumbers"-->
                                        <!--                                                label="شماره ی پلاک"-->
                                        <!--                                            ></v-autocomplete>-->
                                        <!--                                        </v-col>-->
                                    </v-row>
                                </v-container>
                                <!--                                <small>*indicates required field</small>-->
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="red"
                                    @click="dialog1 = false"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="green"
                                    @click="editUserData()"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
                <!--            </template>-->

            </template>

            <template v-slot:item.edit="{ item }">
                <v-btn @click.prevent="EditData(item.id)" icon>
                    <v-icon>edit</v-icon>
                </v-btn>
            </template>


        </v-data-table>


    </div>
</template>

<script>
    import PhotoCircle from "../components/PhotoCircle";
    import LocationMap from "../components/LocationMap";

    export default {
        name: "ProfilesIndex",

        components: {
            PhotoCircle
        },

        created() {
            this.fetchData()
        },
        mounted() {

        },

        computed: {
            // a computed getter
            headerss: function () {
                if (this.$store.state.users.access_level == 5) {
                    let h5 = [
                        {text: 'Home ID', value: 'id'},

                        {
                            text: 'User',
                            align: 'start',
                            value: 'race',
                        },
                        {text: 'Home ID', value: 'chip_id'},
                        {text: 'age', value: 'age'},
                        {text: 'type', value: 'sex'},
                        {text: 'ownerID', value: 'ownerID'},
                        {text: 'color', value: 'color'},
                        {text: 'Registration date', value: 'date'},
                        {text: 'location', value: 'track', sortable: false,},

                        // { text: 'اصلاح', value: 'edit',  sortable: false,},
                    ];
                    return h5

                } else {
                    let h1 = [
                        {text: 'id', value: 'id'},

                        {
                            text: 'User',
                            align: 'start',
                            value: 'race',
                        },
                        {text: 'Home ID', value: 'chip_id'},
                        {text: 'Age', value: 'age'},
                        {text: 'Type', value: 'sex'},
                        {text: 'Owner ID', value: 'ownerID'},
                        {text: 'Color', value: 'color'},
                        {text: 'Registration date', value: 'date'},
                        {text: 'Location', value: 'track', sortable: false,},
                        {text: 'Edit', value: 'edit', sortable: false},
                        // { text: 'اصلاح', value: 'edit',  sortable: false,},
                    ];
                    return h1;
                }
                // `this` points to the vm instance

            }
        },

        data: function () {
            return {
                busy: false,
                dogs: [],
                search:'',
                headers: [
                    {text: 'شناسه ی خانه', value: 'id'},

                    {
                        text: 'کاربری',
                        align: 'start',
                        value: 'race',
                    },
                    {text: 'ای دی چیپ', value: 'chip_id'},
                    {text: 'سن', value: 'age'},
                    {text: 'جنس', value: 'sex'},
                    {text: 'ای دی صاحب', value: 'ownerID'},
                    {text: 'رنگ', value: 'color'},
                    {text: 'تاریخ ثبت شدن', value: 'date'},
                    {text: 'ردیابی', value: 'track', sortable: false,},
                    {text: 'ویرایش', value: 'edit', sortable: false},

                    // { text: 'اصلاح', value: 'edit',  sortable: false,},
                ],
            }
        },
        methods: {
            fetchData() {
                this.busy = true;

                axios.get('/homedata')
                    .then(response => {
                        this.dogs = response.data[0].data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },
            EditData: function (id) {
                this.$router.push('../profile/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },


        }
    }

</script>

<style scoped>

</style>
