<template>
    <div>

        <v-data-table
            show-expand
            :headers="headers"
            :items="dogs"
            :items-per-page="10"

            :loading="busy"
            :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'per page',


      }"
        >
            <template #item.remove="{item}">
                <v-btn color="red"
                       rounded
                       small>delete
                </v-btn>
            </template>

            <template #item.assign="{item}">
                <v-btn color="blue"
                       rounded
                       small>assign
                </v-btn>
            </template>
            <template #item.edit="{item}">
                <v-btn color="green"
                       rounded
                       @click="updateRemoveID(item.id)"
                       small>edit
                </v-btn>
            </template>
            <template #item.user="{item}">
                <v-chip
                    :color="getColor(item.verified)"
                    dark
                >
                    {{getTextState(item.verified)}}
                </v-chip>
            </template>
            <template #expanded-item="{header, item}">
                <!--                <v-flex md6>-->
                <!--                </v-flex>-->
                <td :colspan="headers.length">
                    <v-spacer></v-spacer>
                    <v-container fluid>
                        <v-layout row wrap align="center" justify="center">
                            <v-flex sm4 xs12 justify-center>
                                <v-avatar width="300px" height="100px">
                                    <!--                                    //                    this.images= '/storage/images/publics/'+this.dogs.fig;-->
                                    <v-img :src="'/storage/images/owner/'+ item.fig" contain max-height="400px">
                                    </v-img>
                                </v-avatar>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.number"
                                    label="cell number"
                                    readonly
                                    filled
                                    rounded
                                    dense

                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.comments"
                                    label="comments"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>

                        <v-layout row wrap align="center" justify="center">
                            <v-flex sm4 xs12 justify-center>
                                <v-text-field
                                    v-model="item.date_of_adoption"
                                    label="date of registration"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.age"
                                    label="age"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex sm4 xs12 justify-center align-self-center>
                                <v-text-field
                                    v-model="item.dog_ID"
                                    label="comments"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </td>

            </template>
        </v-data-table>


    </div>
</template>

<script>

    export default {
        name: "OwnersIndex",


        created() {

        },
        mounted() {
            this.fetchData();
        },


        data: function () {
            return {
                busy: false,
                dogs: [],

                headers: [
                    {text: 'ID', value: 'id'},

                    {
                        text: 'name',
                        align: 'start',
                        value: 'name',
                    },
                    {text: 'surname', value: 'family'},
                    {text: 'sex', value: 'sex'},

                    {text: 'building Type', value: 'user', sortable: false},
                    {text: 'confirm', value: 'edit', sortable: false},
                    {text: 'assign home', value: 'assign', sortable: false},
                    {text: 'delete', value: 'remove', sortable: false},
                    // { text: 'Dog ID', value: 'family' },
                ],


            }
        },
        methods: {
            updateRemoveID(id) {
                axios.put('../owner/' + id, {'verified': 1})
                    .then(response => {
                        console.log(response);
                        this.$router.go(0);
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            getTextState(state) {
                if (state == "0") return 'تایید نشده'
                else return 'تایید شده';
            },
            getColor(state) {
                if (state == "0") return 'orange'
                else return 'green';
            },
            fetchData() {
                this.busy = true;

                axios.get('/owner')
                    .then(response => {
                        this.dogs = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },


        }
    }

</script>

<style scoped>

</style>
