<template>
    <v-card flat>
        <v-row>
            <v-col
                cols="12"
                md="4"
            >
                <v-card
                    class="mx-auto"
                    width="100%"
                    elevation="5"
                >
                    <v-card flat color="light-blue lighten-4">
                        <v-list-item two-line

                        >
                            <v-list-item-content>
                                <v-list-item-title class="text-h5">
                                    Basic plan
                                </v-list-item-title>
                                <v-list-item-subtitle>14 days, free</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>

                    <v-divider></v-divider>

                    <v-card-text>
                        <v-row align="center">
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="3 Hardware Vendors"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Hardware security ratings"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Risk assessments"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Hubbcast ML methods"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>

                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Cloud infrastructure"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="500 total consumers"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="3 software integrations"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </v-row>
                    </v-card-text>


                    <v-card-actions class="justify-center mx-auto">
                        <v-btn
                            @click="$emit('plan', 'Basic')"
                            elevation="5"
                            color="light-blue lighten-4"
                            class="text-capitalize"
                        >
                            Select plan
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
            <v-col
                cols="12"
                md="4"

            >
                <v-card
                    class="mx-auto"
                    width="100%"
                    elevation="5"
                >
                    <v-card flat color="#EEEEEE">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5">
                                    Silver plan
                                </v-list-item-title>
                                <v-list-item-subtitle>14 days, free</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>

                    <v-divider></v-divider>

                    <v-card-text>
                        <v-row align="center">
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="All Basic features plus"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Plus 2 Hardware Vendors"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Specific Risk assessments"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Two custom domains"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>

                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Advance marketing tools"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="2500 total consumers"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="API & Webhook"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </v-row>
                    </v-card-text>


                    <v-card-actions class="justify-center mx-auto">
                        <v-btn
                            @click="$emit('plan', 'Silver')"
                            color="#EEEEEE"
                            class="text-capitalize"
                            elevation="10"
                        >
                            Select plan
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                md="4"

            >
                <v-card
                    class="mx-auto"
                    width="100%"
                    elevation="5"
                >
                    <v-card flat color="#FFF9C4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5">
                                    Gold plan
                                </v-list-item-title>
                                <v-list-item-subtitle>14 days, free</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                    <v-divider></v-divider>

                    <v-card-text>
                        <v-row align="center">
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="All Silver features plus"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Unlimited hardware venders"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Unlimited software integration"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Audit and activity log"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>

                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="On-premise & cloud"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="50'000 total consumers"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                hide-details="15"
                                dense
                                solo
                                disabled
                                class="textField"
                                flat
                                value="Phone & Priority Support"
                            >
                                <template #prepend>
                                    <v-icon color="green" right>
                                        done
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </v-row>
                    </v-card-text>


                    <v-card-actions class="justify-center mx-auto" >
                        <v-btn
                            @click="$emit('plan', 'Gold')"
                            color="#FFF9C4"
                            class="text-capitalize"
                            elevation="10"
                        >
                            Select plan
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>


    </v-card>

</template>

<script>
    export default {
        name: "Plans"
    }
</script>

<style scoped>
    .textField {

        font-size: .8em;
        text-decoration-color: #3c366b;
        text-space: .8em;
        line-height: .8em;

    }

</style>
