<template>

    <v-app id="inspire">

        <template v-if="!$store.state.isDeveloperAuthenticated">
            <Login v-if="$route.name === 'login' || $route.name === 'NotFound'" @developerInfo="handleLogin"></Login>
            <Register v-else-if="$route.name === 'register'"></Register>
            <NotFound v-else></NotFound>
        </template>
        <template v-else>

            <v-app-bar
                v-if="isHeaderLarge"
                app
            >
                <v-spacer></v-spacer>
                <v-toolbar-title>Hubbcast dev panel</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-img
                    class="ma-0"
                    :src="require('../../assets/icons/HubbcastLogo.svg')"
                    max-height="40px"
                    min-width="100px"
                    max-width="110px"
                    @click="$router.push('/')"
                ></v-img>
            </v-app-bar>
            <v-navigation-drawer
                v-if="isHeaderLarge"
                app
                dense
                permanent
                absolute
                v-model="drawer"
                :mini-variant.sync="mini"
            >
                <v-list-item class="px-2">
                    <v-list-item-avatar>
                        <v-img
                            contain
                            width="90%"
                            :src="'/storage/images/developer/'+ developer.pic"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-title :style="fontSizeStyle">{{developer.first_name + ' ' + developer.surname}}
                    </v-list-item-title>

                    <v-btn icon @click.stop="toggleMini">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item
                        v-for="item in itemsApp"
                        :key="item.title"
                        link
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="item in itemsGeneral"
                        :key="item.title"
                        link
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="item in itemsSetting"
                        :key="item.title"
                        link
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <v-app-bar
                v-if="isHeaderSmall || isHeaderMedium"
                absolute
            >
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <v-toolbar-title class="titleSmallSize ml-2">Hubbcast dev panel</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-img
                    class="ma-0"
                    :src="require('../../assets/icons/HubbcastLogo.svg')"
                    max-height="40px"
                    min-width="90px"
                    max-width="100px"
                    @click="$router.push('/')"
                ></v-img>
            </v-app-bar>
            <v-navigation-drawer
                v-if="isHeaderSmall || isHeaderMedium"
                app
                temporary
                v-model="drawer"

            >

                <v-list-item class="px-2">
                    <v-list-item-avatar>

                        <v-img
                            contain
                            width="90%"
                            :src="'/storage/images/developer/'+ developer.pic"
                        ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-title :style="fontSizeStyle">{{developer.first_name + ' ' + developer.surname}}
                    </v-list-item-title>

                    <v-btn
                        icon
                        @click.stop="drawer = !drawer"
                    >
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item
                        v-for="item in itemsApp"
                        :key="item.title"
                        link
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="item in itemsGeneral"
                        :key="item.title"
                        link
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="item in itemsSetting"
                        :key="item.title"
                        link
                        :to="item.route"

                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>


            <!-- Sizes your content based upon application components -->
            <v-main class="my-0"
                    v-if="isHeaderLarge"
            >
                <router-view></router-view>
            </v-main>

            <v-main class="my-15"
                    v-if="isHeaderMedium || isHeaderSmall"
            >
                <router-view></router-view>
            </v-main>

            <v-footer app>
                <!-- -->
            </v-footer>
        </template>
    </v-app>
</template>

<script>
    // import routerLanding from "../../routerLanding";
    import Login from "./Auth/Login";
    import Register from "./Auth/Register";
    import NotFound from "./Auth/NotFound";

    export default {
        name: 'panel',
        components: {
            // InputField,
            Login,
            Register,
            NotFound
        },
        props: {
            source: String,
        },
        mounted: function () {
            document.addEventListener('click', this.handleClickOutside);
            if (localStorage.getItem('isDeveloperAuthenticated')) {
                this.$store.commit('setDeveloperAuthenticated', true);
                this.developerCookie = this.getCookie('developerInfo');
                this.developer = this.$store.state.developers;
                if (this.getCookie('developerInfo')) {
                    this.developer = this.getCookie('developerInfo');
                    this.$store.commit('updateDeveloper', this.developer);
                }
            }

            // this.Bol = true;
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },


        methods: {
            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    this.drawer = false;
                }
            },
            toggleMini() {
                this.mini = !this.mini;
            },
            handleLogin(developerInfo) {
                this.developer = developerInfo;
                this.$store.commit('updateDeveloper', this.developer);
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            postRegistrationData() {
                this.validate();
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
        },
        data() {
            return {
                developerCookie: '',
                developer: [],
                drawer: false,
                itemsApp: [
                    {title: 'Apps', icon: 'apps', route: ({name: 'dashboard'})},
                    {title: 'Add an app', icon: '$vuetify.icons.appRegistration', route: ({name: 'newApp'})},
                ],
                itemsGeneral: [
                    {title: 'Mailbox', icon: 'mail', route: ({name: 'mailbox'})},
                    {title: 'Wallet', icon: '$vuetify.icons.payment', route: ({name: 'wallet'})},
                    {title: 'Become partner', icon: '$vuetify.icons.handshake', route: ({name: 'becomePartner'})},
                ],
                itemsSetting: [
                    {title: 'Setting', icon: 'settings', route: ({name: 'setting'})},
                    {title: 'Log out', icon: 'logout', route: ({name: 'logout'})},
                ],
                mini: false,
                checkbox: false,
                valid: true,
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 20) || 'Name must be less than 20 characters',
                ],
                surnameRules: [
                    v => !!v || 'Surname is required',
                    v => (v && v.length <= 20) || 'Surname must be less than 20 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                passwordRules: [
                    (v) => !!v || 'Please type password.',
                    (v) => (v && v.length >= 8) || 'minimum 8 characters',
                ],
                confirmPasswordRules: [
                    (v) => !!v || 'type confirm password',
                    (v) =>
                        v === this.form.password || 'The password confirmation does not match.',
                ],
                confirmPassword: '',
                form:
                    {
                        'firstName': '', 'surname': '', 'email': '', 'password': '',
                    },
                loader: null,
                showPassword: false,
                showConfirmPassword: false,
                loading: false,
            }
        },
        computed: {
            shouldShowMini() {
                return (this.isHeaderLarge || this.isHeaderMedium) && this.mini;
            },
            fontSizeStyle() {
                const length = this.developer.first_name.length + this.developer.surname.length;
                let fontSize;

                if (length <= 10) {
                    fontSize = '16px';  // Use a larger font size for shorter names
                } else if (length <= 20) {
                    fontSize = '12px';  // Use a medium font size for medium-length names
                } else {
                    fontSize = '10px';  // Use a smaller font size for longer names
                }

                return {fontSize};
            },
            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },
    };

</script>

<style scoped>
    .px-2 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .titleSmallSize {
        font-size: 1rem !important;
    }

    .bg {
        background: url("../../assets/icons/Background.svg") no-repeat center center;
        background-size: cover;
    }

    .v-btn {
        text-transform: none !important;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>
