<template>
    <div>
        <v-snackbar v-model="snackbar" color="#f2c4d3" :timeout="5000" top>
                    <span style="color: #333333;">
                        The inbox has been updated as of {{ new Date() | date: 'longDate' }}.
                    </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>
        <br>
        <v-card
            width="95%"
            class="custom-top-margin mx-auto rounded-card"
            elevation="20"
        >

            <v-app-bar color="#7B5D6A" window style="height: 95px;"> <!-- Increased height of v-app-bar -->



                <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                    <v-btn :to="{name:'customerMessages'}" icon color="white" large>
                        <v-icon large>account_circle</v-icon>
                    </v-btn>
                    <v-row justify="center"> <!-- Added v-row with justify="center" -->
                        <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                            <div style="color: whitesmoke; font-size: 8pt; align-content: center">Client Communication Channel</div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                    <v-btn :to="{name:'userMessages'}" icon color="white" large>
                        <v-icon large>people_alt</v-icon>
                    </v-btn>
                    <v-row justify="center"> <!-- Added v-row with justify="center" -->
                        <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                            <div style="color: whitesmoke; font-size: 8pt; align-content: center">Team Collaboration Channel
                            </div>
                        </v-col>
                    </v-row>
                </v-card>

            </v-app-bar>
            <v-main class="mb-15" style="background-color: #f5f5f5;">
                <router-view></router-view>
            </v-main>
        </v-card>
        <!--        if (state=="Unread") return 'red'-->
        <!--        else if (state=="Inprogress") return 'pink lighten-3'-->
        <!--        else if (state=="Underworking") return 'amber'-->
        <!--        else if (state=="Finished") return 'lime'-->
        <!--        else if (state=="Verified") return 'green'-->

    </div>
</template>

<script>
    import VueMoment from 'vue-jalali-moment';

    export default {
        components: {
            VueMoment,
        },
        name: "OwnersIndex",


        created() {
            this.fetchData()
        },
        mounted() {

        },
        props: [
            'user',
            'locationss',
            // 'snackbarAssign'
        ],
        props: {
            'snackbarAssign': Boolean,
            'snackbarInprogress': Boolean,
            'snackbarNotFinded': Boolean,
            'snackbarFinal': Boolean
        },
        computed: {},

        data: function () {
            return {
                snackbar: true,
                id: '',
                dialog: false,
                busy: false,
                messages: [],
                dogsss: [],
                search: '',
                messageType: 'Communication Panel',

                headers: [
                    {text: 'Message ID', value: 'id', sortable: true},
                    {text: 'progress', value: 'progress'},
                    {text: 'status', value: 'status'},
                    {text: 'topic', value: 'topic'},
                    {text: 'last update', value: 'lastupdate',},
                    {text: 'track', value: 'track', sortable: false,},
                    {text: 'edit', value: 'edit', sortable: false,},
                    {text: 'remove', value: 'remove', sortable: false,},
                ],
                desserts: [],
            }
        },
        methods: {
            ShowAll() {
                this.search = '';
                this.messageType = 'Inbox'
            },

            ShowVerified() {
                this.search = 'closed';
                this.messageType = 'Finished tasks'
            },
            ShowFinished() {
                this.search = 'confirmation';
                this.messageType = 'Approved tasks'
            },
            ShowUnderworking() {
                this.search = 'professional_response';
                this.messageType = 'In action tasks';
            },
            ShowInprogress() {
                this.search = 'inprogress';
                this.messageType = 'In progress tasks';
            },
            ShowUnread() {
                this.search = 'submitted';
                this.messageType = 'Unread messages';
            },

            fetchData() {
                this.busy = true;
            },

            ShowLocation: function (id) {
                this.$router.push('../publics/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },
            alert() {
                alert('Hi')
            },
            EditData: function (id) {
                this.$router.push({name: 'editmessage', params: {id: id, locationss: this.locationss}})
                    .then()
                    .catch(error => {
                        alert('Cannot edit info')
                    })
            },

            ShowRemoveDiolog() {
                axios.put('/report/delete/' + this.id)
                    .then(response => {
                        console.log(response);
                        this.$router.go();
                        // this.$router.push({name:'public'});
                        // this.dogss = response.data;
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            EditData: function (id) {
                this.$router.push({name: 'editmessage', params: {id: id, locationss: this.locationss}})
                    .then()
                    .catch(error => {
                        alert('Cannot edit info')
                    })
            },
            getTopicMessage(item) {
                if (item == "AddNewBuilding") return 'New building'
                else if (item == "ExtendPlan") return 'extension plan'
                else if (item == "ChangePlan") return 'Change Plan'
                else if (item == "RequestService") return 'Request Service'
                else if (item == "ReportDamage") return 'Damage Report'
                else if (item == "Support") return 'support'
            },
            getColor(state) {
                if (state == "submitted") return 'red'
                else if (state == "inprogress") return '#EC407A'
                else if (state == "professional_response") return '#F57C00'
                else if (state == "confirmation") return '#827717'
                else if (state == "closed") return 'green'
            },
            getLastUpdateTime(time) {
                let currentTime = new Date();
                let time_diff = Math.floor((currentTime.getTime() - time) / 60000);
                // return time_diff;
                if (parseInt(time_diff) < 60) {
                    return time_diff + " Minutes ago";
                } else if (parseInt(time_diff) < 1440) {
                    let hour = Math.floor(parseInt(time_diff) / 60);
                    let min = time_diff % 60;

                    return hour + " hours" + min + " minutes ago";
                } else {
                    let hourTotal = Math.floor(parseInt(time_diff) / 60);
                    let days = Math.floor(hourTotal / 24);
                    let hour = time_diff % 24;

                    return days + " days" + hour + " hours ago";

                }
            },
            getProgressValue(state) {
                if (state == "submitted") return '0'
                else if (state == "inprogress") return '30'
                else if (state == "professional_response") return '50'
                else if (state == "confirmation") return '80'
                else if (state == "closed") return '100'
            },
            getIconMessage(state) {
                if (state == "submitted") return 'email'
                else if (state == "inprogress") return 'forward'
                else if (state == "professional_response") return 'explore'
                else if (state == "confirmation") return 'build'
                else if (state == "closed") return 'verified_user'
            },
            getTextState(state) {
                if (state == "submitted") return 'Unread message'
                else if (state == "inprogress") return 'In progress tasks'
                else if (state == "professional_response") return 'In action tasks'
                else if (state == "confirmation") return 'Approved tasks'
                else if (state == "closed") return 'Finished tasks'
            },

        }
    }

</script>

<style scoped>
    .v-btn.capitalize {
        text-transform: capitalize !important;
    }
    .icon-button {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .icon-circle {
        background-color: pink; /* or any color you prefer for the circle */
        border-radius: 50%; /* makes the div a circle */
        width: 56px; /* adjust based on your preferred size */
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-top-margin {
        margin-top: 15px;
    }

    .rounded-card {
        border-radius: 20px !important;
        overflow: hidden;
    }

</style>
