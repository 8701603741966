<template>
    <div class="test">
        <v-container
            class="grey lighten-5 mx-auto" align="center"
        >

            <v-card max-width=" 480px" class="mx-auto rounded-form" v-if="locationalert==true">
                <v-alert
                    dense
                    border="left"
                    type="warning"
                    class="my-auto mx-auto"
                >
                    <v-btn
                        color="#e65100"
                        rounded
                        class="shrink text-black-50"
                        @click.passive="SendCurrentLocation()">
                        Send Your location
                    </v-btn>
                    .
                </v-alert>
            </v-card>

            <v-card max-width=" 480px" class="mx-auto rounded-form"
                    v-if="$store.state.users.access_level==5 || $store.state.users.access_level==7">
                <v-card-text class="text-center">
                    Number of errors, not sending location
                    <v-chip
                        color="red"
                        text-color="white"
                    >
                        {{lateLoginErrorNum}}
                    </v-chip>
                </v-card-text>
            </v-card>

            <v-layout align-center row class="mb-5">
                <v-row
                    class="mx-auto"
                    no-gutters
                    align="center" justify="space-around"

                >
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center"
                        v-if="$store.state.users.access_level==1 || $store.state.users.access_level==7"
                    >
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto my-3 rounded-form" outlined width="97%" height="575px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title class="title-container" align="justify-center">
                                            <span class=" text-sm black--text ">
                                               Daily Count of Newly Registered Buildings
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <apexchart ref="realtimeChart" type="area" height="250"
                                                   :options="chartOptionsChart3" :series="seriesChart3"></apexchart>
                                        <v-card-actions>
                                            <v-container>
                                                <v-row align="center" justify="center">
                                                    <v-col cols="4" align-self="center">
                                                        <v-card-text>
                                                            Start Date
                                                        </v-card-text>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <v-card>
                                                            <v-menu
                                                                ref="menu2"
                                                                v-model="menu2"
                                                                :close-on-content-click="false"
                                                                :return-value.sync="dateStartCaptured"
                                                                transition="scale-transition"
                                                                offset-y
                                                                max-width="80%"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="dateStartCaptured"
                                                                        label="Pick date"
                                                                        prepend-icon="event_note"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="dateStartCaptured"
                                                                    no-title
                                                                    scrollable
                                                                    width="200"
                                                                >
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="menu2 = false"
                                                                    >
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="$refs.menu2.save(dateStartCaptured)"
                                                                    >
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-card>
                                                    </v-col>

                                                </v-row>

                                                <v-row align="center" justify="center" align-content="center">
                                                    <v-col cols="4" align-self="center" class="mx-auto">
                                                        <v-card-text>
                                                            End Date
                                                        </v-card-text>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <v-card>
                                                            <v-menu
                                                                ref="menu3"
                                                                v-model="menu3"
                                                                :close-on-content-click="false"
                                                                :return-value.sync="dateEndCaptured"
                                                                transition="scale-transition"
                                                                offset-y
                                                                max-width="80%"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        v-model="dateEndCaptured"
                                                                        label="Pick date"
                                                                        prepend-icon="event_note"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="dateEndCaptured"
                                                                    no-title
                                                                    scrollable
                                                                    width="200"
                                                                >
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="menu3 = false"
                                                                    >
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="$refs.menu3.save(dateEndCaptured)"
                                                                    >
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-card>
                                                    </v-col>

                                                </v-row>
                                                <v-row>
                                                    <v-card class="mx-auto">
                                                        <v-alert v-model="alertStartEndCaptured"
                                                                 color="orange" close-text="Close Alert"
                                                                 class="white--text"
                                                                 dismissible>
                                                            The start day must be before the end day!
                                                        </v-alert>
                                                        <v-btn
                                                            color="#7B5D6A"
                                                            elevation="20"
                                                            shaped

                                                            dark
                                                            class="white--text capitalize-text"
                                                            @click="showCaptureByDate">
                                                            Show
                                                        </v-btn>
                                                    </v-card>
                                                </v-row>

                                            </v-container>

                                        </v-card-actions>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>

                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center"
                        v-if="$store.state.users.access_level==1 || $store.state.users.access_level==7">
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto my-3 rounded-form" outlined width="97%" height="575px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text ">
                                                Comprehensive Building Overview
                                            </span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card class="chart-container">
                                        <apexchart ref="realtimePieChart" type="polarArea" height="45%"
                                                   :options="chartOptionsPi"
                                                   :series="seriesPi"></apexchart>
                                        <apexchart type="radar" height="45%" :options="chartOptionsRa"
                                                   :series="seriesRa"></apexchart>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>


                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center">

                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto rounded-form" outlined width="95%" height="500px">
                                <v-app-bar
                                    dark
                                    color="#f2c4d3"
                                >
                                    <v-toolbar-title align="justify-center">

                                        <v-flex align-self-center>
                                            <span class="text-black-50 text-sm black--text">Client Communication Channel, updated on {{ formatDate(new Date()) }}</span>
                                        </v-flex>
                                    </v-toolbar-title>

                                </v-app-bar>
                                <v-row
                                    no-gutters>
                                    <v-col>
                                        <v-flex class="justify-center">
                                            <v-chip
                                                class="ma-2"
                                                color="red"
                                                text-color="white"
                                            >
                                                Unread
                                            </v-chip>
                                        </v-flex>
                                        <v-card width="90%" elevation="0" class="mx-auto">
                                            <v-text-field
                                                readonly
                                                v-model="number[0]"
                                                class="centered-input text--darken-3 mt-3 mx-auto"
                                            >
                                            </v-text-field>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-flex class="justify-center">
                                            <v-chip
                                                class="ma-2"
                                                color="#EC407A"
                                                text-color="white"
                                            >
                                                In process
                                            </v-chip>
                                        </v-flex>
                                        <v-card width="90%" elevation="0" class="mx-auto">
                                            <v-text-field
                                                readonly
                                                v-model="number[1]"
                                                class="centered-input text--darken-3 mt-3 mx-auto"
                                            >
                                            </v-text-field>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row
                                    no-gutters>
                                    <v-col>
                                        <v-flex class="justify-center">
                                            <v-chip
                                                class="ma-2"
                                                color="#F57C00"
                                                text-color="white"
                                            >
                                                In action
                                            </v-chip>
                                        </v-flex>
                                        <v-card width="90%" elevation="0" class="mx-auto">
                                            <v-text-field
                                                readonly
                                                v-model="number[2]"
                                                class="centered-input text--darken-3 mt-3 mx-auto"
                                            >
                                            </v-text-field>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-flex class="justify-center">
                                            <v-chip
                                                class="ma-2"
                                                color="#827717"
                                                text-color="white"
                                            >
                                                Completed
                                            </v-chip>
                                        </v-flex>
                                        <v-card width="90%" elevation="0" class="mx-auto">
                                            <v-text-field
                                                readonly
                                                v-model="number[3]"
                                                class="centered-input text--darken-3 mt-3 mx-auto"
                                            >
                                            </v-text-field>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row
                                    no-gutters>
                                    <v-col>
                                        <v-flex class="justify-center">
                                            <v-chip
                                                class="ma-2"
                                                color="#7E57C2"
                                                text-color="white"
                                            >
                                                Location difference error
                                            </v-chip>
                                        </v-flex>
                                        <v-card width="90%" elevation="0" class="mx-auto">
                                            <v-text-field
                                                readonly
                                                v-model="number[4]"
                                                class="centered-input text--darken-3 mt-3 mx-auto"
                                            >
                                            </v-text-field>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-flex class="justify-center">
                                            <v-chip
                                                class="ma-2"
                                                color="green"
                                                text-color="white"
                                            >
                                                Confirmed
                                            </v-chip>
                                        </v-flex>
                                        <v-card width="90%" elevation="0" class="mx-auto">
                                            <v-text-field
                                                readonly
                                                v-model="number[5]"
                                                class="centered-input text--darken-3 mt-3 mx-auto"
                                            >
                                            </v-text-field>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-spacer></v-spacer>
                            </v-card>
                        </v-flex>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center">
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto my-3 rounded-form" outlined width="95%" height="500px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text">
                                                Heatmap of Building Locations
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">

                                        <live-tracking/>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>
                    </v-col>
                </v-row>
            </v-layout>
            <v-layout align-center row wrap>

            </v-layout>
        </v-container>

        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >

            <v-layout align-center row class="mb-5">
                <v-row
                    class="mx-auto"
                    no-gutters
                    align="center" justify="space-around"
                >
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center">
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto my-3 rounded-form" outlined width="97%" height="500px">
                                <template class="justify-center" id="funnel-graph-wrapper" ref="funnel">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text ">
                                                Statistics on Building Types
                                            </span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <v-card class="my-3 mx-auto" width="90%" height="45%" elevation="24">
                                            <v-card-title>
                                                     <span class="  text-sm grey--text text-uppercase">
                                                         commercial and office building
                                                     </span>
                                            </v-card-title>
                                            <v-sparkline

                                                :fill="fill"
                                                :gradient="gradient"
                                                :line-width="width"
                                                :padding="padding"
                                                :smooth="radius || false"
                                                :value="value"
                                            ></v-sparkline>
                                        </v-card>

                                        <v-card class="my-5 mx-auto" width="90%" height="45%" elevation="24">
                                            <v-card-title>
                                                <span class="text-sm grey--text text-uppercase">
                                                    Housing statistic
                                                </span>
                                            </v-card-title>
                                            <v-sparkline
                                                :fill="fill"
                                                :gradient="gradient"
                                                :line-width="width"
                                                :padding="padding"
                                                :smooth="radius || false"
                                                :value="value"
                                            ></v-sparkline>
                                        </v-card>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>

                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center">
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto my-3 rounded-form" outlined width="97%" height="500px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text ">
                                                Messaging System Overview
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <apexchart type="line" height="350" :options="chartOptionsCone"
                                                   :series="seriesCone"></apexchart>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>

                    </v-col>
                </v-row>
            </v-layout>
            <v-layout align-center row class="mb-5">
                <v-row
                    class="mx-auto"
                    no-gutters
                    align="center" justify="space-around"
                >
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center">
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto  my-3 rounded-form" outlined width="97%" height="550px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text ">
                                                Location Error Overview
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <v-card flat height="55%" class="mb-2">
                                            <div id="chart">
                                                <apexchart type="area" height="250" :options="chartOptionsChart2"
                                                           :series="seriesChart2"></apexchart>
                                                <v-card-actions>
                                                    <v-data-table
                                                        dense
                                                        :headers="headers2"
                                                        :items="desserts2"
                                                        item-key="name"
                                                        class="elevation-1"
                                                        mobile-breakpoint="0"
                                                    ></v-data-table>
                                                </v-card-actions>
                                            </div>
                                        </v-card>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>

                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="justify-center">
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto  my-3 rounded-form" outlined width="97%" height="550px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#f2c4d3"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text ">
                                                Homeowner Risk Signal Overview
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <apexchart type="area" height="250" :options="chartOptionsChart1"
                                                   :series="seriesChart1"></apexchart>
                                        <v-card-actions>
                                            <v-data-table
                                                dense
                                                :headers="headers1"
                                                :items="desserts1"
                                                item-key="name"
                                                class="elevation-1"
                                                mobile-breakpoint="0"
                                            ></v-data-table>
                                        </v-card-actions>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>

                    </v-col>
                </v-row>
            </v-layout>

        </v-container>

    </div>
</template>

<script>
    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ];

    import VueApexCharts from 'vue-apexcharts'
    import VueMoment from 'vue-jalali-moment';
    import moment from 'jalali-moment/jalali-moment';
    import JsonExcel from "vue-json-excel";
    import LiveTracking from "../views/LiveTracking";


    export default {
        components: {
            LiveTracking,
            VueMoment,
            apexchart: VueApexCharts,
            downloadExcel: JsonExcel,
            moment
        },
        props: [
            'user',
        ],
        data: () => ({
            alertStartEndCaptured: false,
            menu2: false,
            menu3: false,
            dateStartCaptured: '',
            dateEndCaptured: '',
            lateLoginErrorNum: '',
            locationalert: false,
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            dateTommorow: '',
            dialogVocation: false,
            //Instead of cone

            seriesCone: [{
                name: 'Correct messages',
                type: 'column',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }, {
                name: 'Messages sent',
                type: 'area',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }, {
                name: 'People Satisfaction',
                type: 'line',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }],
            chartOptionsCone: {
                chart: {
                    toolbar: {

                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        export: {
                            csv: {
                                // encoding:'utf-8',
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                charset: 'utf-8',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                    height: 350,
                    width: 250,
                    type: 'line',
                    stacked: false,
                    animations: {
                        enabled: false
                    },
                },
                stroke: {
                    width: [0, 2, 5],
                    curve: 'smooth'
                },

                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },

                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: ['Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July',
                    'August', 'September', 'October', 'November', 'December'
                ],
                markers: {
                    size: 0
                },
                xaxis: {},
                yaxis: {
                    title: {
                        text: 'value',
                    },
                    min: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + "";
                            }
                            return y;

                        }
                    }
                }
            },

            //Table
            headers1: [
                {text: 'user ID', value: 'id'},

                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'location error num', value: 'location', sortable: false,},
            ],
            headers2: [
                {text: 'user ID', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'location error num', value: 'location', sortable: false,},],
            headers3: [
                {text: 'user ID', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'Number of dogs taken', value: 'location', sortable: false,},],

            desserts1: [
                {
                    id: '1',
                    name: 'Keyvan Shafiee',
                    email: '09127752017',
                    location: 20,
                },
                {
                    id: '2',
                    name: 'Alireza Shirdel',
                    email: '09124020821',
                    location: 21,
                },
            ],
            desserts2: [
                {
                    id: '1',
                    name: 'Keyvan Shafiee',
                    email: '09127752017',
                    location: 14,
                },
                {
                    id: '2',
                    name: 'Ehsan Shahsavari',
                    email: '09124020821',
                    location: 19,
                },
            ],
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1
            seriesChart1: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan',
                    y: null
                },
                    {
                        x: '2 Jan',
                        y: 44
                    },
                    {
                        x: '3 Jan',
                        y: 31
                    },
                    {
                        x: '4 Jan',
                        y: 38
                    },
                    {
                        x: '5 Jan',
                        y: null
                    },
                    {
                        x: '6 Jan',
                        y: 32
                    },
                    {
                        x: '7 Jan',
                        y: 55
                    },
                    {
                        x: '8 Jan',
                        y: 51
                    },
                    {
                        x: '9 Jan',
                        y: 67
                    },
                    {
                        x: '10 Jan',
                        y: 22
                    },
                    {
                        x: '11 Jan',
                        y: 34
                    },
                    {
                        x: '12 Jan',
                        y: null
                    },
                    {
                        x: '13 Jan',
                        y: null
                    },
                    {
                        x: '14 Jan',
                        y: 11
                    },
                ],
            }],
            seriesChart2: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan.',
                    y: null
                },
                    {
                        x: '2 Jan.',
                        y: 31
                    },
                    {
                        x: '3 Jan.',
                        y: 12
                    },
                    {
                        x: '4 Jan.',
                        y: 4
                    },
                    {
                        x: '5 Jan.',
                        y: null
                    },
                    {
                        x: '6 Jan.',
                        y: 9
                    },
                    {
                        x: '7 Jan.',
                        y: 63
                    },
                    {
                        x: '8 Jan.',
                        y: 13
                    },
                    {
                        x: '9 Jan.',
                        y: 18
                    },
                    {
                        x: '10 Jan.',
                        y: 19
                    },
                    {
                        x: '11 Jan.',
                        y: 51
                    },
                    {
                        x: '12 Jan.',
                        y: null
                    },
                    {
                        x: '13 Jan.',
                        y: null
                    },
                    {
                        x: '14 Jan.',
                        y: 23
                    },
                ],
            }],
            seriesChart3: [{
                name: 'Number of homes',
                data: [],
            }],


            chartOptionsChart1: {
                chart: {
                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 5,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            chartOptionsChart2: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#008FFB'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            chartOptionsChart3: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: false,
                    shared: false
                },
                theme: {
                    palette: 'palette1'
                },
                xaxis: {
                    type: 'category',
                }
            },
            //pi chart
            seriesPi: [14, 23, 21, 17],
            chartOptionsPi: {
                labels: ['Office', 'Commercial', 'Apartment', 'Villa House'],
                chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },

            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },

                xaxis: {
                    categories: ['New', '5-10', '10 -15 ', '15 and above'],
                    position: 'top'
                }
            },
            //
            access_level: '',
            loaded: false,
            chartdata: null,
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradients,
            type: 'trend',
            values: [
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            height: 100,
            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]
        }),
        beforeCreate() {
            axios.get('../homedatas/homeStatistics')
                .then(response => {
                    console.log(response);
                    console.log('homeStatistics');
                    this.seriesPi = [parseInt(response.data['0']), parseInt(response.data['1']), parseInt(response.data['2']), parseInt(response.data['3'])];
                    this.updatePieChart();
                    console.log(response.data['1']);
                })
        },
        created() {
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.dateEndCaptured = moment(new Date()).format("YYYY-MM-DD");
            this.dateStartCaptured = moment(new Date()).add(-3, 'd').format("YYYY-MM-DD");
            axios.post('../homedatas/num', {'dateStart': this.dateStartCaptured, 'dateEnd': this.dateEndCaptured})
                .then(response => {
                    // this.dogs = response.data[0].data;
                    this.seriesChart3[0].data = [];
                    // this.test = {x: this.seriesChart3[0].data[0].x, y:this.seriesChart3[0].data[0].y};
                    // this.
                    let startDate = moment(this.dateStartCaptured).format("YYYY-MM-DD");
                    let dateAxis = this.dateStartCaptured;
                    for (let index = 0; index < response.data.length; ++index) {
                        this.seriesChart3[0].data[index] = {x: startDate, y: response.data[index]};
                        dateAxis = moment(dateAxis).add(1, 'days');
                        startDate = moment(dateAxis).format("YYYY-MM-DD");
                    }
                    let endDate = moment(this.dateEndCaptured).format("YYYY-MM-DD");
                    this.seriesChart3[0].data[0].x = moment(this.dateStartCaptured).format("YYYY-MM-DD");
                    this.seriesChart3[0].data[response.data.length - 1].x = endDate;
                    this.updateSeriesLine();
                });
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;
            this.getMessageCount();
        },
        async mounted() {
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            this.access_level = this.getAccessLevel();
            axios.get('../user/' + this.$store.state.users.id)
                .then(response => {
                    this.lateLoginErrorNum = response.data.last_login_error;
                    if (response.data.gps_off_error == 1) {
                        this.locationalert = true;
                    } else {
                        this.locationalert = false;

                    }
                })
                .catch(errors => {
                    console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
        },
        methods: {
            getMessageCount() {
                axios.get('../users/messageCount').then(response => {
                    console.log(response.data);
                    this.number = response.data;
                });
            },
            updatePieChart() {
                this.$refs.realtimePieChart.updateSeries([{
                    data: this.seriesPi,
                }], false, true);
            },
            formatDate(date) {
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                return `${day}/${month}/${year} ${hours}:${minutes}`;
            },
            updateSeriesLine() {
                this.$refs.realtimeChart.updateSeries([{
                    data: this.seriesChart3[0].data,
                }], false, true);
            },
            showCaptureByDate() {
                if ((this.dateStartCaptured) && (this.dateEndCaptured)) {
                    if (moment(this.dateStartCaptured).isAfter(this.dateEndCaptured)) {
                        this.alertStartEndCaptured = true;
                    } else {
                        axios.post('../homedatas/num', {
                            'dateStart': this.dateStartCaptured,
                            'dateEnd': this.dateEndCaptured
                        })
                            .then(response => {
                                // this.dogs = response.data[0].data;
                                this.seriesChart3[0].data = [];
                                // this.test = {x: this.seriesChart3[0].data[0].x, y:this.seriesChart3[0].data[0].y};
                                // this.
                                let startDate = moment(this.dateStartCaptured).format("YYYY-MM-DD");
                                let dateAxis = this.dateStartCaptured;
                                for (let index = 0; index < response.data.length; ++index) {
                                    this.seriesChart3[0].data[index] = {x: startDate, y: response.data[index]};
                                    dateAxis = moment(dateAxis).add(1, 'days');
                                    startDate = moment(dateAxis).format("YYYY-MM-DD");
                                }
                                // let startDate = moment(this.dateStartCaptured).locale('fa').format('dddd,jDo jMMMM  jYYYY');
                                let endDate = moment(this.dateEndCaptured).format("YYYY-MM-DD");
                                this.seriesChart3[0].data[0].x = moment(this.dateStartCaptured).format("YYYY-MM-DD");
                                this.seriesChart3[0].data[response.data.length - 1].x = endDate;
                                this.updateSeriesLine();
                            });
                    }
                }
            },
            SendCurrentLocation() {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        let newLocation = {
                            Lat: position.coords.latitude,
                            Long: position.coords.longitude
                        };

                        this.$store.commit("updateLocation", newLocation);
                        //post to server
                        axios.post('../saveUserLocation', {
                            ID: this.$store.state.users.id,
                            Long: position.coords.longitude,
                            Lat: position.coords.latitude,
                        })
                            .then(response => {
                                console.log('Send');
                                this.locationalert = false;
                                this.$router.go();
                            })
                            .catch(errors => {
                                console.log('error');
                            });
                    })
                } else {
                    this.locationalert = true;
                }
            },
            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'Management'
                else if (this.$store.state.users.access_level == "2") return 'Operator'
                else if (this.$store.state.users.access_level == "3") return 'Installation expert'
                else if (this.$store.state.users.access_level == "4") return 'Damage expert'
                else if (this.$store.state.users.access_level == "5") return 'Support specialist'
                else if (this.$store.state.users.access_level == "7") return 'Responsible technician'
                else if (this.$store.state.users.access_level == "8") return 'Security and inspection'
                else return 'Unapproved user'
            },
        },
        computed: {
            formattedDate() {
                return new Date().toLocaleDateString();
            },
        }
    }
</script>

<style scoped>
    .rounded-form {
        border-radius: 10px;
    }

    .text-black-50 {
        color: rgba(0, 0, 0, 0.5);
    }

    .card-container {
        margin: 15px auto;
        border-radius: 8px;
        width: 97%;
        height: 575px;
    }

    .title-container {
        text-align: center;
        color: #000000;
    }

    .chart-container {
        height: 90%;
        padding: 10px;
    }

    .responsive-text {
        /* Default text size for larger screens */
        font-size: 1em;
    }

    /* Adjust text size for small screens (less than 600px) */
    @media (max-width: 599px) {
        .responsive-text {
            font-size: 0.8em; /* Adjust this value as needed */
        }
    }

    .capitalize-text {
        text-transform: capitalize !important;
    }

    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
