<!--Orginal-->

<template>
    <div class="pt-16 bg-gray-200 full-height">
        <v-snackbar :timeout="3000" color="#f2c4d3" top v-model="snackkbarOkey">
            <span style="color: #333333;">
                Thank you, your request has been successfully submitted.
            </span>
        </v-snackbar>
        <v-snackbar :timeout="3000" color="orange" top v-model="snackkbarNokey">
            <span>
                Your cannot registered twice with one email. Please, try with another email
            </span>
        </v-snackbar>

        <v-card class="mx-auto rounded-form my-10" max-width="85%" min-width="370px" outlined>
            <v-img
                class="ma-0"
                contain
                :src="require('../../../assets/icons/insurmatics.png')"
                max-height="20px"
                width="110px"
            ></v-img>
            <v-card-title class="layout justify-center">
                Create your application only with some questions
            </v-card-title>
            <v-stepper v-model="e1"
                       elevation="24"
                       light
                       rounded
                       color="purple"
            >
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                        color="success"
                    >
                        <v-chip>
                            <v-icon color="grey darken-1">$vuetify.icons.info</v-icon>
                            General info
                        </v-chip>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                        color="success"
                    >
                        <v-chip>
                            <v-icon color="grey darken-1">$vuetify.icons.feature</v-icon>
                            Features
                        </v-chip>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 3"
                        step="3"
                        color="success"
                    >
                        <v-chip><v-icon color="grey darken-1">$vuetify.icons.hardware</v-icon>
                            Hardware</v-chip>

                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 4"
                        step="4"
                        color="success"
                    >
                        <v-chip><v-icon color="grey darken-1">$vuetify.icons.notification</v-icon>
                            Notification system</v-chip>

                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 5"
                        step="5"
                        color="success"
                    >
                        <v-chip>
                            <v-icon color="grey darken-1">$vuetify.icons.customer</v-icon>
                            Customer relations
                        </v-chip>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 6"
                        step="6"
                        color="success"
                    >
                        <v-chip>
                            <v-icon color="grey darken-1">$vuetify.icons.plan</v-icon>
                            Plan
                        </v-chip>

                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                            class="mb-12"
                            color=" lighten-1"
                            min-height="200px"
                        >
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                            >
                                <v-text-field
                                    v-model="nameOfInsurer"
                                    :counter="10"
                                    :rules="nameRules"
                                    label="Name of your company"
                                    required
                                ></v-text-field>
                                <v-select
                                    v-model="selectSize"
                                    :items="itemsSize"
                                    :rules="[v => !!v || 'Size is required']"
                                    label="Select the company size"
                                    required
                                ></v-select>
                                <v-select
                                    v-model="selectRegion"
                                    :items="itemsRegion"
                                    :rules="[v => !!v || 'Size is required']"
                                    label="Select the working region"
                                    chips
                                    required
                                    multiple
                                ></v-select>
                                <v-text-field
                                    v-model="username"
                                    hide-details="auto"
                                    :hint="usernameHint"
                                    :error-messages="usernameError"
                                    :success-messages="usernameSuccess"
                                    label="Select the user name"
                                    required
                                ></v-text-field>

                                <v-text-field
                                    v-model="address"
                                    :rules="addressRules"
                                    label="Address"
                                    required
                                ></v-text-field>

                                <v-text-field
                                    v-model="phoneNum"
                                    :rules="phoneNumRules"
                                    label="Phone number"
                                    required
                                ></v-text-field>

                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    label="E-mail"
                                    required
                                ></v-text-field>

                                <div id="filepond">
                                    <file-pond

                                        name="test"
                                        ref="pond"
                                        class-name="my-pond"
                                        label-idle="Please uplad your logo"
                                        allow-multiple="false"
                                        accepted-file-types="image/jpeg, image/png"
                                        server= "https://tracker.hosa.ir/owner/upload"
                                        v-on:processfile="handleProcessFile">
                                    </file-pond>
                                </div>

                            </v-form>
                        </v-card>

                        <v-btn
                            :disabled="!valid"
                            color="primary"
                            class="mr-4"
                            @click="e1 = 2"

                        >
                            Continue
                        </v-btn>
<!--                        @click="validate"-->

                        <v-btn
                            color="orange"
                            class="mr-4"
                            @click="reset"
                        >
                            Reset form
                        </v-btn>

                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            class="mb-12"
                            color=" lighten-1"
                            min-height="200px"
                        >
                            <v-card
                                class="justify-center mx-auto"
                            width="90%">
                                <v-card-title class="justify-center">
                                    Machine Learning Services
                                </v-card-title>
                                <v-row class="justify-center">
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Insurance advice"
                                            color="purple"
                                            value="red"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Fraud Detection"
                                            color="purple"
                                            value="red darken-3"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Risk Management"
                                            color="purple"
                                            value="indigo"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Map Analysis"
                                            color="purple"
                                            value="indigo darken-3"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card
                                class="justify-center mx-auto"
                                width="90%">
                                <v-card-title class="justify-center">
                                    Insurance related Services
                                </v-card-title>
                                <v-row class="justify-center">
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Pricing"
                                            color="purple"
                                            value="red"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="ex4"
                                            label="On-demand"
                                            color="purple"
                                            value="red darken-3"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Home Appliance Insurance"
                                            color="purple"
                                            value="indigo"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Policy Generator"
                                            color="purple"
                                            value="indigo darken-3"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card
                                class="justify-center mx-auto"
                                width="90%">
                                <v-card-title class="justify-center">
                                    Other Services
                                </v-card-title>
                                <v-row class="justify-center">
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Maintenance service"
                                            color="purple"
                                            value="red"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Support"
                                            color="purple"
                                            value="red darken-3"
                                            hide-details
                                        ></v-checkbox>

                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                            v-model="ex4"
                                            label="Consultancy"
                                            color="purple"
                                            value="indigo"
                                            hide-details
                                        ></v-checkbox>
                                        <v-checkbox
                                        v-model="ex4"
                                        label="Claim Request"
                                        color="purple"
                                        value="indigo darken-3"
                                        hide-details
                                    ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>

                            <v-textarea
                                class="my-10"
                                solo
                                name="input-7-4"
                                label="Write your favourable service that you need"
                            ></v-textarea>
                        </v-card>


                        <v-btn
                            color="primary"
                            @click="e1 = 3"
                        >
                            Continue
                        </v-btn>

                        <v-btn
                            @click="e1 = 1">
                            Back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                    <v-card
                        class="mb-12"
                        color="grey lighten-1"
                        height="200px"
                    ></v-card>

                    <v-btn
                        color="primary"
                        @click="e1 = 4"
                    >
                        Continue
                    </v-btn>

                        <v-btn
                            @click=" e1 = 2">
                            Back
                    </v-btn>
                </v-stepper-content>
                    <v-stepper-content step="4">
                        <v-card
                            class="mb-12"
                            color="grey lighten-1"
                            height="200px"
                        ></v-card>

                        <v-btn
                            color="primary"
                            @click="e1 = 5"
                        >
                            Continue
                        </v-btn>

                        <v-btn
                            @click=" e1 = 3">
                            Back
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="5">
                        <v-card
                            class="mb-12"
                            color="grey lighten-1"
                            height="200px"
                        ></v-card>

                        <v-btn
                            color="primary"
                            @click=" e1 = 6"
                        >
                            Continue
                        </v-btn>

                        <v-btn
                            @click="e1 = 4"
                        >
                            Back
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="6">
                        <v-card
                            class="mb-12"
                            color="grey lighten-1"
                            height="200px"
                        ></v-card>

                        <v-btn
                            color="primary"
                            @click=""
                        >
                            Save
                        </v-btn>

                        <v-btn
                            @click="e1 = 5">
                            Back
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </div>

</template>


<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
    import moment from 'moment';
    export default {
        name: "Onboarding",
        components: {
            FilePond
        },

        data: function () {
            return {
                name: '',
                nameOfInsurer: '',
                email: '',
                size: '',
                region: '',
                suggestHost: '',
                numOfCustomer: '',
                phoneNum: '',
                emailAddress: '',

                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                image:'../assets/iamges/Forms/Forms_partnership.svg',
                TimeSlot:[],
                meetingList: [],
                notAvailableTimeSlot: [],
                currentDate: moment().format("YYYY-MM-DD"),
                notAvailableDates : ["2021-11-01", "2021-11-02"],
                validPage1: true,
                validPage2: true,

                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 30) || 'Name must be less than 30 characters',
                ],
                phoneNumRules: [
                    v => !!v || 'Phone Number is required',
                    v => (v && v.length <= 30) || 'phone number must be less than 30 digits',
                    v => Number.isInteger(Number(v)) || "The value must be an integer number",
                ],

                modal: false,
                date: '',
                e13: 2,
                plan: '',
                e1: 1,
                changedPlan: '',
                building_set: [],
                building_selected_id: '',
                building_id: '',
                valid: true,

                familyRules: [
                    v => !!v || 'Last name required',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the last name is 20',
                ],
                cellnumRules: [
                    v => !!v || 'Contact number is required',
                    v => (v && v.length === 11) || ' Contact number is required',
                ],
                select: null,
                selectSize: null,
                selectRegion: null,
                username: '',
                address: '',
                addressRules: [
                    v => !!v || 'address is required',
                    v => (v && v.length <= 50) || 'The maximum number of characters in the address is 50',
                ],
                usernameHint: 'Please Choose a username which is unique with at least 6 characters',

                itemsSize: [
                    'Micro insurer (1-10)',
                    'Small insurer (11-49)',
                    'Medium-sized insurer (50-249)',
                    'Large insurer (250 or more)',
                ],
                itemsRegion: [
                    'North America',
                    'South America',
                    'Central America',
                    'Caribbean',
                    'Central & South Asia',
                    'Northeastern Asia',
                    'Southeastern Asia',
                    'Australia and Oceania',
                    'Northern Europe',
                    'Southern Europe',
                    'Eastern Europe',
                    'Western Europe',
                    'Middle East',
                    'Northern Africa',
                    'Southern Africa',
                ],
                items: [
                    'Item 1',
                    'Item 2',
                    'Item 3',
                    'Item 4',
                ],
                checkbox: false,
                //myFiles: ['index.html'],
                dialogUnit: false,
                dialogSupporter: false,
                dialogChangePlan: false,
                dialogExtend: false,
                dialogSend: false,
                snackkbarOkey: false,
                snackkbarNokey: false,
                form:
                    {
                        'major': '',
                        'name': '',
                        'email': '',
                        'cellnumber': '',
                        'company': '',
                        'website': '',
                        'type': '',
                        'region': '',
                        'address': '',
                        'challenge': '',
                        'proposal': '',
                        'potential': '',
                        'financialModel': '',
                        'meetMethod': '',
                        'date': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                        'time': ''
                    },
                formUnitArray: [],
                changepaln: '',
                errors: null,
                responses: null,
                busy: false,
                request: '',
            }
        },
        computed:{
            usernameSuccess() {
                return this.username.length > 7 ? 'this username is available' : ''
            },
            usernameError() {
                return ((this.username.length < 8)&&(this.username.length > 0)) ? 'Please,select the correct username' : ''
            }

        },
        mounted() {
            axios.get('../meetingList')
                .then(response => {
                    this.meetingList = response.data;

                    for (let i = 0; i < this.meetingList.length; i++) {
                        if ((this.meetingList[i]['8:00-8:30'] + this.meetingList[i]['8:30-9:00'] + this.meetingList[i]['9:00-9:30']
                            +this.meetingList[i]['19:00-19:30'] + this.meetingList[i]['19:30-20:00']  + this.meetingList[i]['20:00-20:30'] ) === "111111")
                        {
                            this.notAvailableDates.push(this.meetingList[i]['date'])
                        }
                    }
                });
        },

        methods: {
            imageUrlAlt(event) {
                event.target.src = "https://tracker.hosa.ir/storage/images/dogs/Dog_16495754791330.8420601673639483.png";
            },
            updateDateAndSlot()
            {
                this.TimeSlot = ['8:00-8:30', '8:30-9:00', '9:00-9:30', '19:00-19:30', '19:30-20:00', '20:00-20:30'];

                this.$refs.dialog.save(this.form.date);
                for (let i = 0; i < this.meetingList.length; i++) {
                    if (this.meetingList[i]['date'] === this.form.date)
                    {
                        this.TimeSlot = [];
                        if (this.meetingList[i]['8:00-8:30'] ==="0")
                            this.TimeSlot.push('8:00-8:30');
                        if (this.meetingList[i]['8:30-9:00'] ==="0")
                            this.TimeSlot.push('8:30-9:00');
                        if (this.meetingList[i]['9:00-9:30'] ==="0")
                            this.TimeSlot.push('9:00-9:30');
                        if (this.meetingList[i]['19:00-19:30']==="0")
                            this.TimeSlot.push('19:00-19:30');
                        if (this.meetingList[i]['19:30-20:00']==="0")
                            this.TimeSlot.push('19:30-20:00');
                        if (this.meetingList[i]['20:00-20:30'] ==="0")
                            this.TimeSlot.push('20:00-20:30');
                    }

                }

            },
            allowedDates(a) {
                return !this.notAvailableDates.includes(a);
            },


            allowed_dates(){
                // return ["2021-11-01", "202 1-11-02"];
                if (this.allowedDates.indexOf(val) !== -1) {
                    return true
                } else {
                    return false
                }
            },
            overAllValidate() {
                if (this.$refs.form2.validate()) {
                    this.dialogSend = true;
                }
            },

            validate() {
                if (this.$refs.form.validate()) {
                    this.e1 = 2
                }
            },
            changeUnit() {
                this.dialogUnit = true;
                this.formUnitArray = [];
                for (let i = 0; i < this.form.unitnum; i++) {
                    this.formUnitArray.push({
                        'unit_Nom': '',
                        'name': '',
                        'family': '',
                        'cellnumber': '',
                        'area': '',
                        'postalcode': ''
                    });
                }
            }
            ,
            // validate() {
            //
            //     if (this.$refs.form.validate()) {
            //         this.dialogUnit = false;
            //     }
            // },
            reset() {
                this.$refs.form.reset();
            }
            ,

            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            }
            ,
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,
            addPartnerForm() {
                this.dialogSend = false;
                this.busy = true;
                axios.post('../bePartner', this.form)
                    .then(
                        response => {
                            this.snackkbarOkey = true;
                        }).catch(errors => {
                    this.snackkbarNokey = true;
                }).finally(() => {
                    this.busy = false;

                });
            },


            extendPlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ExtendPlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'msg': 'Send request to extend plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogExtend = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,

            changePlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ChangePlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'new_plan': this.changedPlan,
                        'msg': 'Request a change plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogChangePlan = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .headline2 {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    .GoldText {
        color: #fcc203;
    }

    .Price {
        font-size: 1.5rem;
    }

    .full-height {
        height: 100%;
    }
</style>

