import Vue from 'vue'
import VueRouter from 'vue-router'
import InsurmaticsLogin from "./LoginPanel/Auth/InsurmaticsLogin";
import InsurmaticsRegister from "./LoginPanel/Auth/InsurmaticsRegister";
import LandingPage from "./LoginPanel/LandingPage/LandingPage";
import GetDemo from "./LoginPanel/InsuranceCompanies/GetDemo";
import BePartner from "./LoginPanel/InsuranceCompanies/BePartner";
import BeInJourney from "./LoginPanel/InsuranceCompanies/BeInJourney";
import FAQ from "./LoginPanel/FAQ/FAQ";
import WhyInsurmatics from "./LoginPanel/WhyInsurmatics/WhyInsurmatics";
import Onboarding from "./LoginPanel/Onboarding/Onboarding";
import ForbiddenError from "./LoginPanel/ErrorPage/ForbiddenError";
import Privacy from "./LoginPanel/GeneralPages/Privacy"
import TermsOfUse from "./LoginPanel/GeneralPages/TermsOfUse"

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        // {
        //     path: '*',
        //     redirect: '/'
        // },
        // { path: '/', name: 'register', component: InsurmaticsRegister },
        { path: '/', name: 'landingPage', component: LandingPage },

        { path: '/home', name: 'landingPage', component: LandingPage },
        { path: '/demo', name: 'demo', component: GetDemo },
        { path: '/order', name: 'onboarding', component: Onboarding },
        { path: '/partner', name: 'partner', component: BePartner },
        { path: '/journey', name: 'journey', component: BeInJourney },
        { path: '/faq', name: 'faq', component: FAQ },
        { path: '/whyHubbcast', name: 'whyinsurmatics', component: WhyInsurmatics },
        { path: '/loginPanel', name: 'login', component: InsurmaticsLogin },
        { path: '/privacy', name: 'privacy', component: Privacy },
        { path: '/terms', name: 'terms', component: TermsOfUse },
        { path: '/registerPanel', name: 'Register', component: InsurmaticsRegister },
        {
            path: '/403',
            name: 'Forbidden',
            component: ForbiddenError
        }
    ],
    fallback:true
})




// import Vue from 'vue'
// import VueRouter from 'vue-router'
//
// import InsurmaticsLogin from "./LoginPanel/Auth/InsurmaticsLogin";
// import InsurmaticsRegister from "./LoginPanel/Auth/InsurmaticsRegister";
// import LandingPage from "./LoginPanel/LandingPage/LandingPage";
// import GetDemo from "./LoginPanel/InsuranceCompanies/GetDemo";
// import BePartner from "./LoginPanel/InsuranceCompanies/BePartner";
// import BeInJourney from "./LoginPanel/InsuranceCompanies/BeInJourney";
// import FAQ from "./LoginPanel/FAQ/FAQ";
// import WhyInsurmatics from "./LoginPanel/WhyInsurmatics/WhyInsurmatics";
// // import LowCodeDemo from "./LoginPanel/LowCodeDemo/LowCodeDemo";
// import Onboarding from "./LoginPanel/Onboarding/Onboarding";
//
// Vue.use(VueRouter);
//
// export default new VueRouter({
//     mode: 'hash',
//     routes: [
//         {
//             path: '/',
//             name: 'register',
//             component: InsurmaticsRegister,
//         },
//         {
//             path: '/home',
//             name: 'landingPage',
//             component: LandingPage
//         },
//         {
//             path: '/demo',
//             name: 'demo',
//             component: GetDemo
//         },
//         {
//             path: '/order',
//             name: 'onboarding',
//             component: Onboarding
//         },
//         // {
//         //     path: '/lowcodedemo',
//         //     name: 'lowcodedemo',
//         //     component: LowCodeDemo
//         // },
//         {
//             path: '/partner',
//             name: 'partner',
//             component: BePartner
//         },
//         {
//             path: '/journey',
//             name: 'journey',
//             component: BeInJourney
//         },
//         {
//             path: '/faq',
//             name: 'faq',
//             component: FAQ
//         },
//         {
//             path: '/whyinsurmatics',
//             name: 'whyinsurmatics',
//             component: WhyInsurmatics
//         },
//         {
//             path: '/login',
//             name: 'login',
//             component: InsurmaticsLogin,
//         },
//         {
//             path: '/register',
//             name: 'Register',
//             component: InsurmaticsRegister,
//         },
//         // {
//         //     path: '/reports',
//         //     name: 'report',
//         //     component: TechnisionReports
//         //     // name: 'home',
//         //     // component: ExampleComponent
//         // },
//         // {
//         //     path: '/home',
//         //     name: 'dashboard',
//         //     component: Dashboard
//         //     // name: 'home',
//         //     // component: ExampleComponent
//         // },
//         //
//         // {
//         //     path: '/ownerlist',
//         //     name: 'owner',
//         //     component: OwnersIndex
//         // },
//         // {
//         //     path: '/hardwarecreate',
//         //     name: 'hardwarecreate',
//         //     component: HardwareCreate
//         // },
//         // {
//         //     path: '/ownercreate',
//         //     name: 'ownercreate',
//         //     component: OwnerCreate
//         // },
//         // {
//         //     path: '/map',
//         //     name: 'map',
//         //     component: LiveTracking
//         // },
//         // {
//         //     path: '/',
//         //     name: 'dashboard',
//         //     component: Dashboard
//         // },
//         // {
//         //     path: '/js/vuetify.js.map',
//         //     name: 'dashboard',
//         //     component: Dashboard
//         // },
//         // {
//         //     path: '/doglocation/:id',
//         //     name: 'deglocation',
//         //      component: DogLocation
//         // },
//         // {
//         //     path: 'capturedlocation',
//         //     name: 'capturedlocation',
//         //     component: CapturedLocation,
//         //     props: true
//         // },
//         // {
//         //     path: '/profiles',
//         //     name: 'profiles',
//         //     component: ProfilesIndex
//         // },
//         // {
//         //     path: '/newprofiles',
//         //     name: 'newprofiles',
//         //     component: ProfilesNotApproved
//         // },
//         // {
//         //     path: '/newprofiles/:id',
//         //     name: 'assess',
//         //     component: AssessDog
//         // },
//         // {
//         //     path: '/profile/create',
//         //     name: 'create',
//         //     component: ProfilesCreate,
//         //     props: true
//         // },
//         // {
//         //     path: '/profile/:id', name: 'profile',
//         //     component: DogInfoShow
//         // },
//         //
//         // {
//         //     path: '/editmessage/:id', name: 'editmessage',
//         //     component: EditPublicMessage,
//         //     props: true
//         // },
//         // {
//         //     path: '/users/:id', name: 'users',
//         //     component: UserLocation
//         // },
//         // {
//         //     path: '/carss/:id', name: 'cars',
//         //     component: CarLocation
//         // },
//         // {
//         //     path: '/publics/:id', name: 'publics',
//         //     component: PublicMessageLocation,
//         //     props:true,
//         // },
//         // {
//         //     path: '/users/', name: 'userindex',
//         //     component: UsersIndex
//         // },
//         //
//         // {
//         //     path: '/technicion/', name: 'technicionindex',
//         //     component: TechnicianIndex
//         // },
//         // {
//         //     path: '/carss/', name: 'carindex',
//         //     component: CarsIndex
//         // },
//         // {
//         //     path: '/ticket/', name: 'ticketbox',
//         //     component: TicketBox
//         // },
//         // // {
//         // //     path: '/profile/:id/edit',
//         // //     name: 'edit',
//         // //     component: DogInfoShow
//         // // },
//         // {
//         //     path: '/setting',
//         //     name: 'setting',
//         //    // component: DogInfoShow
//         // },
//         // {
//         //     path: '/alluserlocation',
//         //     name: 'alluserlocation',
//         //     component: AllUserLocation
//         // },
//         // {
//         //     path: '/logout', name: 'logout',
//         //
//         //     component: Logout
//         // },
//         // {
//         //     path: '/logout', name: 'logout',
//         //
//         //     component: Logout
//         // },
//         //
//         // {
//         //     path: '/main', name: 'dashboard',
//         //     props: true,
//         //     component: Dashboard
//         // },
//         // {
//         //     path: '/userprofile', name: 'userprofile',
//         //     props: true,
//         //     component: Profile
//         // },
//         //
//         // {
//         //     path: '/customerprofile', name: 'customerprofile',
//         //     props: true,
//         //     component: ProfileCustomer
//         // },
//
//     ],
//
// });
