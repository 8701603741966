import Vue from 'vue'
import VueRouter from 'vue-router'
import ExampleComponent from "./components/ExampleComponent";
import ProfilesCreate from "./views/ProfilesCreate";
import DogInfoShow from "./views/DogInfoShow";
import ProfilesIndex from "./views/ProfilesIndex";
//import LocationMap from "./components/LocationMap";
import LiveTracking from "./views/LiveTracking";
import Logout from "./Actions/Logout";
import DogLocation from "./views/DogLocation";
import OwnersIndex from "./views/OwnersIndex";
import OwnerCreate from "./views/OwnerCreate";
import OverAllUserLocation from "./views/OverAllUserLocation";
import AllUserLocation from "./views/User/AllUserLocation";
import UserLocation from "./views/User/UserLocation";
import UsersIndex from "./views/User/UsersIndex";
import MessageIndex from "./EnterprisePanel/Messages/MessageIndex";

import PublicMessageLocation from "./views/PublicReport/PublicMessageLocation";
import EditPublicMessage from "./views/PublicReport/EditPublicMessage";
import ProfilesNotApproved from "./nest/ProfilesNotApproved";
import AssessDog from "./nest/AssessDog";
import CarsIndex from "./views/User/CarsIndex";
import Dashboard from "./Dashboard/Dashboard";
import CarLocation from "./views/User/CarLocation";
import TicketBox from "./views/Ticket/TicketBox";
import TechnicianIndex from "./views/User/TechnicianIndex";
import Profile from "./Profile/Profile";
import TechnisionReports from "./Dashboard/TechnisionReports";
import CapturedLocation from "./views/Dog/CapturedLocation";
import ProfileCustomer from "./Customer/Profile/ProfileCustomer";
import HardwareCreate from "./Hardware/HardwareCreate";
import Login from "./EnterprisePanel/Auth/Login";
import Register from "./EnterprisePanel/Auth/Register";
import NotFound from "./Business/NotFound";
import performanceHighlights from "./Dashboard/performanceHighlights";
import dailyInsights from "./Dashboard/dailyInsights";
import technicianEvaluation from "./Dashboard/technicianEvaluation";
import agentEvaluation from "./Dashboard/agentEvaluation";
import customerMessages from "./EnterprisePanel/Messages/customerMessages";
import userMessages from "./EnterprisePanel/Messages/userMessages";
import CheckUserMessage from "./EnterprisePanel/Messages/CheckUserMessage";
import HardwareListing from "./Hardware/HardwareListing";
import TraceLocation from "./components/TraceLocation";
import propertyManagerDirectory from "./views/PublicReport/Homeowners/propertyManagerDirectory";
import propertyOwnerDirectory from "./views/PublicReport/Homeowners/propertyOwnerDirectory";

Vue.use(VueRouter);

const hostname = window.location.hostname;

const baseUrl = ['hubbcast.com', 'localhost'].includes(hostname) || hostname.endsWith('.hubbcast.com')
    ? '/business'
    : '/';

const routePath = baseUrl === '/business' ? '/:business' : '';
// const hostname = window.location.hostname;
//
// const baseUrl = ['hubbcast.com', 'localhost'].includes(hostname) || hostname.endsWith('.hubbcast.com')
//     ? '/business'
//     : '/';
// const router = new VueRouter({
//     // Your routes here
//     mode: 'history',
//     base: baseUrl,
//     routes: [
//         {
//             path: '/:business/login',
//             name: 'login',
//             component: Login,
//             props: true,
//         },
//         {
//             path: '/:business/register',
//             name: 'register',
//             component: Register,
//             props: true,
//
//         },
//         {
//             path: '/:business/public',
//             name: 'public',
//             component: MessageIndex,
//             props: true
//         },
//         {
//             path: '/:business/reports',
//             name: 'report',
//             component: TechnisionReports,
//             props: true,
//
//             // name: 'home',
//             // component: ExampleComponent
//         },
//         {
//             path: '/:business/home',
//             name: 'dashboard',
//             component: Dashboard,
//             props: true,
//
//             // name: 'home',
//             // component: ExampleComponent
//         },
//
//         {
//             path: '/:business/ownerlist',
//             name: 'owner',
//             component: OwnersIndex,
//             props: true,
//
//         },
//         {
//             path: '/:business/hardwarecreate',
//             name: 'hardwarecreate',
//             component: HardwareCreate,
//             props: true,
//
//         },
//         {
//             path: '/:business/ownercreate',
//             name: 'ownercreate',
//             component: OwnerCreate,
//             props: true,
//
//         },
//         {
//             path: '/:business/map',
//             name: 'map',
//             component: LiveTracking,
//             props: true,
//
//         },
//         {
//             path: '/:business/',
//             name: 'dashboard',
//             component: Dashboard,
//             props: true,
//
//         },
//         {
//             path: '/js/vuetify.js.map',
//             name: 'dashboard',
//             component: Dashboard,
//             props: true,
//
//         },
//         {
//             path: '/:business/doglocation/:id',
//             name: 'deglocation',
//             component: DogLocation,
//             props: true,
//
//         },
//         {
//             path: '/:business/capturedlocation',
//             name: 'capturedlocation',
//             component: CapturedLocation,
//             props: true
//         },
//         {
//             path: '/:business/profiles',
//             name: 'profiles',
//             component: ProfilesIndex,
//             props: true,
//
//         },
//         {
//             path: '/:business/newprofiles',
//             name: 'newprofiles',
//             component: ProfilesNotApproved,
//             props: true,
//
//         },
//         {
//             path: '/:business/newprofiles/:id',
//             name: 'assess',
//             component: AssessDog,
//             props: true,
//
//         },
//         {
//             path: '/:business/profile/create',
//             name: 'create',
//             component: ProfilesCreate,
//             props: true
//         },
//         {
//             path: '/:business/profile/:id', name: 'profile',
//             component: DogInfoShow,
//             props: true,
//
//         },
//
//         {
//             path: '/:business/editmessage/:id', name: 'editmessage',
//             component: EditPublicMessage,
//             props: true
//         },
//         {
//             path: '/:business/users/:id', name: 'users',
//             component: UserLocation,
//             props: true,
//
//         },
//         {
//             path: '/:business/carss/:id', name: 'cars',
//             component: CarLocation
//         },
//         {
//             path: '/:business/publics/:id', name: 'publics',
//             component: PublicMessageLocation,
//             props: true,
//         },
//         {
//             path: '/:business/users/', name: 'userindex',
//             component: UsersIndex,
//             props: true,
//
//         },
//
//         {
//             path: '/:business/technicion/', name: 'technicionindex',
//             component: TechnicianIndex,
//             props: true,
//
//         },
//         {
//             path: '/:business/carss/', name: 'carindex',
//             component: CarsIndex,
//             props: true,
//
//         },
//         {
//             path: '/:business/ticket/', name: 'ticketbox',
//             component: TicketBox,
//             props: true,
//
//         },
//         // {
//         //     path: '/profile/:id/edit',
//         //     name: 'edit',
//         //     component: DogInfoShow
//         // },
//         {
//             path: '/:business/setting',
//             name: 'setting',
//             props: true,
//
//             // component: DogInfoShow
//         },
//         {
//             path: '/:business/alluserlocation',
//             name: 'alluserlocation',
//             component: AllUserLocation,
//             props: true,
//
//         },
//         {
//             path: '/:business/logout', name: 'logout',
//             props: true,
//             component: Logout
//         },
//         {
//             path: '/:business/logout', name: 'logout',
//             props: true,
//             component: Logout
//         },
//
//         {
//             path: '/:business/main', name: 'dashboard',
//             props: true,
//             component: Dashboard
//         },
//         {
//             path: '/:business/userprofile', name: 'userprofile',
//             props: true,
//             component: Profile
//         },
//
//         {
//             path: '/:business/customerprofile', name: 'customerprofile',
//             props: true,
//             component: ProfileCustomer
//         },
//         {
//             path: '/:business/*', name: 'NotFound',
//             component: NotFound
//         },
//         // {
//         //     path: '/:business/*',
//         //     redirect: to => {
//         //         const { params, path } = to;
//         //         if (!params.business.startsWith('@')) {
//         //             return {};
//         //         }
//         //         return {
//         //             name: 'NotFound',
//         //             params: { business: params.business }
//         //         };
//         //     }
//         // }
//         // {
//         //     path: '*',
//         //     redirect: (to) => {
//         //         const business = to.params.business;
//         //         return { name: 'NotFound', params: { business: business } };
//         //     }
//         // },
//
//         // {
//         //     path: '*',
//         //     props: true,
//         //     component: NotFound
//         // },
//
//     ],
// });
const router = new VueRouter({
    // Your routes here
    mode: 'history',
    base: baseUrl,
    routes: [
        {
            path: `${routePath}/login`,
            name: 'login',
            component: Login,
            props: true,
        },
        {
            path: `${routePath}/register`,
            name: 'register',
            component: Register,
            props: true,

        },
        {
            path: `${routePath}/public`,
            // name: 'public',
            component: MessageIndex,
            props: true,
            children: [
                {
                    path: '/',
                    name: 'customerMessages',
                    component: customerMessages
                },
                {
                    path: 'customer',
                    name: 'customerMessages',
                    component: customerMessages
                },
                {
                    path: 'team',
                    name: 'userMessages',
                    component: userMessages
                },
                {
                    path: 'checkUserMessage/:id',
                    name: 'checkUserMessage',
                    component: CheckUserMessage,
                    props: true
                },
                {
                    path: 'editmessage/:id',
                    name: 'editmessage',
                    component: EditPublicMessage,
                    props: true
                },
            ]
        },
        {
            path: `${routePath}/reports`,
            name: 'report',
            component: TechnisionReports,
            props: true,

            // name: 'home',
            // component: ExampleComponent
        },
        {
            path: `${routePath}/home`,
            name: 'dashboard',
            component: Dashboard,
            props: true,
            children: [
                {
                    path: '/',
                    name: 'performanceHighlights',
                    component: performanceHighlights
                },
                {
                    path: 'assessment',
                    name: 'performanceHighlights',
                    component: performanceHighlights
                },
                {
                    path: 'dailyInsights',
                    name: 'dailyInsights',
                    component: dailyInsights
                },
                {
                    path: 'technicianEvaluation',
                    name: 'technicianEvaluation',
                    component: technicianEvaluation
                },
                {
                    path: 'agentEvaluation',
                    name: 'agentEvaluation',
                    component: agentEvaluation
                }
            ]

            // name: 'home',
            // component: ExampleComponent
        },

        // {
        //     path: `${routePath}/ownerlist`,
        //     name: 'owner',
        //     component: OwnersIndex,
        //     props: true,
        //
        // },

        {
            path: `${routePath}/ownerlist/`,
            component: OwnersIndex,
            props: true,
            children: [
                {
                    path: '',
                    name: 'propertyManagerDirectory',
                    component: propertyManagerDirectory
                },
            ]
        },
        {
            path: `${routePath}/ownerlist/`,
            name: 'owner',
            component: OwnersIndex,
            props: true,
            redirect: {name: 'propertyManagerDirectory'}, // Redirects to default child route
            children: [
                {
                    path: 'propertyManagerDirectory',
                    name: 'propertyManagerDirectory',
                    component: propertyManagerDirectory
                },
                {
                    path: 'propertyOwnerDirectory',
                    name: 'propertyOwnerDirectory',
                    component: propertyOwnerDirectory
                },
            ]
        },
        {
            path: `${routePath}/hardwarecreate`,
            name: 'hardwarecreate',
            component: HardwareCreate,
            props: true,

        },
        {
            path: `${routePath}/hardwarelist`,
            name: 'hardwarelist',
            component: HardwareListing,
            props: true,

        },
        {
            path: `${routePath}/ownercreate`,
            name: 'ownercreate',
            component: OwnerCreate,
            props: true,

        },
        {
            path: `${routePath}/map`,
            name: 'map',
            component: LiveTracking,
            props: true,

        },
        {
            path: `${routePath}/`,
            name: 'dashboard',
            component: Dashboard,
            props: true,
            children: [
                {
                    path: '/',
                    name: 'performanceHighlights',
                    component: performanceHighlights
                },
            ]

        },
        {
            path: '/js/vuetify.js.map',
            name: 'dashboard',
            component: Dashboard,
            props: true,

        },
        {
            path: `${routePath}/traceLocation/:id`,
            name: 'traceLocation',
            component: TraceLocation,
            props: true,

        },

        {
            path: `${routePath}/doglocation/:id`,
            name: 'deglocation',
            component: DogLocation,
            props: true,

        },
        {
            path: `${routePath}/capturedlocation`,
            name: 'capturedlocation',
            component: CapturedLocation,
            props: true
        },
        {
            path: `${routePath}/profiles`,
            name: 'profiles',
            component: ProfilesIndex,
            props: true,

        },
        {
            path: `${routePath}/newprofiles`,
            name: 'newprofiles',
            component: ProfilesNotApproved,
            props: true,

        },
        {
            path: `${routePath}/newprofiles/:id`,
            name: 'assess',
            component: AssessDog,
            props: true,

        },
        {
            path: `${routePath}/profile/create`,
            name: 'create',
            component: ProfilesCreate,
            props: true
        },
        {
            path: `${routePath}/profile/:id`, name: 'profile',
            component: DogInfoShow,
            props: true,

        },


        {
            path: `${routePath}/users/:id`, name: 'users',
            component: UserLocation,
            props: true,

        },
        {
            path: `${routePath}/carss/:id`, name: 'cars',
            component: CarLocation
        },
        {
            path: `${routePath}/publics/:id`, name: 'publics',
            component: PublicMessageLocation,
            props: true,
        },
        {
            path: `${routePath}/users/`, name: 'userindex',
            component: UsersIndex,
            props: true,

        },

        {
            path: `${routePath}/technicion/`, name: 'technicionindex',
            component: TechnicianIndex,
            props: true,

        },
        {
            path: `${routePath}/carss/`, name: 'carindex',
            component: CarsIndex,
            props: true,

        },
        {
            path: `${routePath}/ticket/`, name: 'ticketbox',
            component: TicketBox,
            props: true,

        },
        // {
        //     path: '/profile/:id/edit',
        //     name: 'edit',
        //     component: DogInfoShow
        // },
        {
            path: `${routePath}/setting`,
            name: 'setting',
            props: true,

            // component: DogInfoShow
        },
        {
            path: `${routePath}/all-user-locations`,
            name: 'all-user-locations',
            component: AllUserLocation,
            props: true,

        },
        {
            path: `${routePath}/logout`, name: 'logout',
            props: true,
            component: Logout
        },
        {
            path: `${routePath}/logout`, name: 'logout',
            props: true,
            component: Logout
        },

        {
            path: `${routePath}/main`, name: 'dashboard',
            props: true,
            component: Dashboard
        },
        {
            path: `${routePath}/userprofile`, name: 'userprofile',
            props: true,
            component: Profile
        },

        {
            path: `${routePath}/customerprofile`, name: 'customerprofile',
            props: true,
            component: ProfileCustomer
        },
        {
            path: `${routePath}/*`, name: 'NotFound',
            component: NotFound
        },
        // {
        //     path: '/:business/*',
        //     redirect: to => {
        //         const { params, path } = to;
        //         if (!params.business.startsWith('@')) {
        //             return {};
        //         }
        //         return {
        //             name: 'NotFound',
        //             params: { business: params.business }
        //         };
        //     }
        // }
        // {
        //     path: '*',
        //     redirect: (to) => {
        //         const business = to.params.business;
        //         return { name: 'NotFound', params: { business: business } };
        //     }
        // },

        // {
        //     path: '*',
        //     props: true,
        //     component: NotFound
        // },

    ],
});


router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    const business = to.params.business;
    const supportedDomains = ['hubbcast.com', 'localhost'];
    const isCustomDomain = !supportedDomains.includes(window.location.hostname) && !window.location.hostname.endsWith('.hubbcast.com');

    // Redirect to login if not authenticated
    if (!isAuthenticated && to.name !== 'login' && to.name !== 'register') {
        if (isCustomDomain) {
            next({ name: 'login' });
        } else {
            next({ name: 'login', params: { business: business } });
        }
        return;
    }

    // If authenticated and trying to access login or register, redirect to dashboard
    if (isAuthenticated && (to.name === 'login' || to.name === 'register')) {
        if (isCustomDomain) {
            next({ name: 'dashboard' });
        } else {
            next({ name: 'dashboard', params: { business: business } });
        }
        return;
    }

    // For business parameter that doesn't start with '@', redirect to login
    if (!isCustomDomain && business && !business.startsWith('@')) {
        next({ name: 'login', params: { business: business } });
        return;
    }

    // Continue with the current route
    next();
});
``
export default router;


