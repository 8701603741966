<template>

    <!--        -->


    <div class="pt-16 full-height" style="background-color: rgba(326, 37, 64, .05);">
        <v-card class="mx-auto rounded-form my-10" max-width="85%" min-width="370px" outlined>
            <v-card-title class="layout justify-center testHeader">
                Frequently Asked Questions
            </v-card-title>
            <v-card-subtitle class="layout justify-center">
                Hubbcast FAQ: Your Guide to Understanding Our Features and Services. Have a question? Contact us at
                info@hubbcast.com
            </v-card-subtitle>

            <v-card>
                <template class="mx-auto my-8 ">
                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="justify-self-start">
                                <span class="custom-title font-weight-bolder">
                                    What is Hubbcast?
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Hubbcast is a cutting-edge technology company that provides low-code platforms,
                                AI-driven insights, and collaboration tools to streamline processes and boost
                                profitability for companies in the real estate, insurance, and property industries.
                                Our goal is to empower businesses to unleash new business models and revenue streams
                                through the use of innovative technology solutions.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                How does the integration of smart home technology benefit the property industry?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content color="gray" class="custom-body">
                                <p> Smart homes refer to properties that are equipped with connected devices and systems
                                    that allow homeowners to remotely control and automate various functions, such as
                                    lighting, temperature, security, and energy consumption. Smart home technology has
                                    become increasingly popular in recent years, and it's projected to continue growing
                                    in the coming years.</p>

                                <p> According to a report by Statista, the revenue generated by the smart home market in
                                    Europe is expected to reach 20.8 billion U.S. dollars by 2025. The report also
                                    highlights that the most popular smart home device category in Europe is smart
                                    security systems, followed by smart lighting and smart thermostats.</p>

                                <p> In the property industry, smart home technology can offer numerous benefits,
                                    including increased energy efficiency, improved security, and enhanced convenience
                                    for homeowners. By integrating smart devices and systems into their properties, real
                                    estate professionals can also differentiate their offerings in a crowded market and
                                    potentially increase property values.</p>

                                <p> At Hubbcast, we recognize the importance of smart home technology in the property
                                    industry, and our platform includes features that enable seamless integration with
                                    various smart devices and systems. Our data-driven insights also help property
                                    professionals identify the most popular smart home devices among their target
                                    market, allowing them to tailor their offerings and stay ahead of emerging
                                    trends.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">How does Hubbcast define its target audience,
                                business approach and ideal customer?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Hubbcast's customer base includes insurance companies, real estate businesses, and
                                property management firms looking to streamline their operations and improve their
                                profitability. Our business model is based on providing a low-code platform and data
                                analytics tools to these companies to help them make data-driven decisions and create
                                custom solutions tailored to their unique needs. Our target market includes small to
                                large companies in the insurance and real estate industries across various regions,
                                including Europe, North America, and Asia-Pacific.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                How can one company inquire about working with Hubbcast?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                If your company is interested in working with Hubbcast, we would be happy to schedule an
                                online or in-person meeting to discuss how our technology can help streamline your
                                business processes and boost profitability. To request a meeting, please visit our
                                website and fill out the contact form or send an email to our team at info@hubbcast.com.
                                We'll be in touch as soon as possible to set up a consultation and show you how Hubbcast
                                can work for your specific needs.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                What sets Hubbcast apart from other platforms in the property industry?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Hubbcast stands out from other platforms in the property industry due to its
                                cutting-edge
                                technology and innovative solutions. Our low-code platform allows users to create custom
                                apps
                                and solutions without requiring extensive coding experience, making it accessible to a
                                wide
                                range of professionals. Additionally, our AI-driven insights approach allows for the
                                discovery
                                of valuable insights and data-driven decision making in various use cases.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                How does Hubbcast ensure data privacy and security?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                At Hubbcast, we take data privacy and security very seriously. We have implemented
                                various measures
                                to ensure that our customers' data is protected at all times. Our platform is built with
                                industry-standard security protocols and is hosted on secure servers. We also regularly
                                conduct
                                security audits to identify any potential vulnerabilities and take necessary actions to
                                address them.
                                Additionally, we comply with all relevant data protection regulations and laws, such as
                                GDPR, to
                                ensure that our customers' data is handled lawfully and ethically.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                Can Hubbcast integrate with my existing tools and systems?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Yes, Hubbcast can integrate with your existing tools and systems to streamline your
                                workflows and maximize your productivity. Our platform is designed to be flexible and
                                customizable, allowing for seamless integration with a wide range of applications and
                                services. Whether you're using CRM software, accounting tools, or other business
                                systems, Hubbcast can work with your existing technology stack to deliver comprehensive
                                solutions that meet your unique needs. Our team of experts can provide guidance and
                                support throughout the integration process to ensure a smooth transition and minimal
                                disruption to your operations.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                How user-friendly is the Hubbcast platform?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Hubbcast is designed with a user-friendly interface and requires no extensive coding
                                experience. Our low-code platform allows users to easily create custom apps and
                                solutions tailored to their unique needs, with intuitive drag-and-drop features and
                                templates. Our goal is to provide a seamless and hassle-free experience for our users,
                                ensuring they can efficiently collaborate with team members, clients, and partners, and
                                stay ahead of the curve in the fast-paced world of real estate.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">
                                Does Hubbcast offer training and support for its users?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Yes, Hubbcast offers comprehensive training and support to ensure that its users can
                                make the most of its platform and features. Upon signing up, users will have access to a
                                range of resources, including tutorials, documentation, and FAQs. In addition, Hubbcast
                                offers personalized training sessions to help users get started and make the most of the
                                platform. Ongoing support is also available via email and phone, with dedicated support
                                staff available to answer any questions or concerns that users may have.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>


                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">What pricing options are available for
                                Hubbcast?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Hubbcast offers an enterprise SaaS solution with three different functionalities to
                                choose from. Pricing options are available upon request and can be customized based on
                                the specific needs of your business. Please contact us at info@hubbcast.com for more
                                information.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">How can I measure the ROI of using Hubbcast
                                in my business?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                <p>There are several ways to measure the ROI of using Hubbcast in your business. Here
                                    are
                                    some examples:</p>

                                <p><strong>1- Increased Efficiency</strong>: Hubbcast can help streamline your workflows
                                    and reduce manual
                                    effort, resulting in time and cost savings. You can measure the ROI by calculating
                                    the reduction in labor costs and the time saved.</p>

                                <p><strong>2- Increased Revenue</strong>: Hubbcast's data-driven insights and
                                    value-added services can help
                                    you identify new revenue streams and upsell opportunities. You can measure the ROI
                                    by tracking the revenue generated from these new opportunities.</p>

                                <p><strong>3- Improved Customer Satisfaction</strong>: Hubbcast's collaboration tools
                                    can help you
                                    communicate more effectively with clients and partners, leading to better customer
                                    satisfaction and retention. You can measure the ROI by conducting customer
                                    satisfaction surveys and tracking customer retention rates.</p>

                                <p><strong>4- Reduced Risk</strong>: Hubbcast's digital process of issuing policy and
                                    claim management can
                                    help reduce the risk of errors and fraud. You can measure the ROI by calculating the
                                    reduction in costs associated with errors and fraud.</p>

                                <p>Overall, the ROI of using Hubbcast will depend on your specific business needs and
                                    goals. Our team can work with you to develop a customized ROI analysis to help you
                                    make an informed decision. </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">Can Hubbcast handle large amounts of data and
                                complex workflows?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Yes, Hubbcast is designed to handle large amounts of data and complex workflows in the
                                property industry. Its AI-driven insights and low-code platform can help automate and
                                streamline various processes, making it easier to manage and analyze vast amounts of
                                data. Additionally, Hubbcast can be customized to fit the specific needs of each
                                business, ensuring that it can handle any level of complexity.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">What happens to my data if I decide to stop
                                using Hubbcast?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                If you decide to stop using Hubbcast, you will still own your data and have the ability
                                to export it. We ensure that your data is securely stored and that there is a smooth
                                transition process for you to move your data to another platform if necessary.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">How does Hubbcast ensure reliable and
                                accurate data analysis?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Hubbcast ensures reliable and accurate data analysis through its advanced AI-driven
                                algorithms and machine learning models. The platform uses various data sources and
                                processing methods to clean, standardize, and analyze the data. It also has a rigorous
                                quality assurance process to ensure the accuracy of the data analysis results.
                                Additionally, Hubbcast allows users to customize their data analysis models and
                                dashboards, providing them with the flexibility to extract the insights they need.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">Does Hubbcast offer customization options for
                                its solutions?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                Yes, Hubbcast offers customization options for its solutions. We understand that each
                                business has unique needs and requirements, and therefore we offer tailored solutions
                                that meet those needs. Our team works closely with clients to understand their specific
                                requirements and develop solutions that meet their needs. Whether it's customizing
                                workflows, reports, or dashboards, we work to ensure that our solutions are tailored to
                                your specific needs.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels inset>
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header class="custom-title">Could Hubbcast's solutions help to improve the safety and security
                                of residential properties?
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="custom-body">
                                Certainly. Hubbcast's solutions are designed to improve the safety and security of
                                residential properties by providing data-driven insights and tools for risk assessment,
                                policy management, and incident reporting. With our AI-driven approach and smart home
                                integration, we can identify potential hazards and mitigate risks in real-time, helping
                                homeowners and property managers take proactive measures to ensure the safety of their
                                properties and the people who live there.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </template>
            </v-card>
        </v-card>


        <!--        <v-card class="mx-auto rounded-form my-10" max-width="85%" min-width="370px" outlined>-->
        <!--            <v-img-->
        <!--                class="ma-0"-->
        <!--                contain-->
        <!--                :src="require('../../assets/icons/insurmatics.png')"-->
        <!--                max-height="20px"-->
        <!--                width="110px"-->
        <!--            ></v-img>-->
        <!--            <v-card-title class="layout justify-center">-->
        <!--                The Insurmatics Partner Program-->
        <!--            </v-card-title>-->
        <!--            <v-card-subtitle class="layout justify-center">-->
        <!--                IoT is sport team business. Hence, we are actively looking forward to new partnerships in different-->
        <!--                areas. Please, tell us about yourself by filling out the following form.-->
        <!--            </v-card-subtitle>-->
        <!--            <v-flex class="mx-auto">-->
        <!--                <v-img-->
        <!--                    class="ma-0"-->
        <!--                    contain-->
        <!--                    :src="require('../../assets/iamges/Forms/Forms_partnership.svg')"-->
        <!--                    max-height="150px"-->
        <!--                    width="200px"-->
        <!--                ></v-img>-->
        <!--            </v-flex>-->
        <!--            <v-card-text>-->
        <!--                <v-autocomplete-->
        <!--                    :items="['Smart Home vendors', 'Banking or payment providers', 'Vertically insurance software providers', 'SaaS providers', 'Others']"-->
        <!--                    buy-->
        <!--                    label="What is your industry?" v-model="request"-->
        <!--                ></v-autocomplete>-->
        <!--                &lt;!&ndash;                'state', 'startdate','devicenum', 'errornum', 'address', 'unitnum', 'postalcode',-->
        <!--                        'unitset', 'ownerlist', 'appliancenum', 'applianceprice', 'hardwarenum', 'buildingtype', 'lat', 'long', 'pic', 'hardwarelist'-->
        <!--                &ndash;&gt;-->
        <!--                <template v-if="request=='Others'">-->
        <!--                    <v-row>-->
        <!--                        <v-col-->
        <!--                            class="justify-center"-->
        <!--                            cols="12"-->
        <!--                            sm="6"-->
        <!--                        >-->
        <!--                            <v-text-field label="Other industry" placeholder="Please tell us about your industry"-->
        <!--                                          v-model="form.feild "></v-text-field>-->
        <!--                        </v-col>-->
        <!--                    </v-row>-->
        <!--                </template>-->


        <!--                <template v-if="request">-->
        <!--                    <template>-->
        <!--                        <template>-->
        <!--                            <v-stepper v-model="e1"-->
        <!--                                       color="green"-->
        <!--                                       elevation="0"-->
        <!--                                       outlined-->
        <!--                                       vertical-->
        <!--                            >-->
        <!--                                <v-stepper-header>-->
        <!--                                    <v-stepper-step-->
        <!--                                        :complete="e1 > 1"-->
        <!--                                        step="1"-->
        <!--                                    >-->
        <!--                                        Personal information-->
        <!--                                    </v-stepper-step>-->

        <!--                                    <v-divider></v-divider>-->

        <!--                                    <v-stepper-step-->
        <!--                                        :complete="e1 > 2"-->
        <!--                                        step="2"-->
        <!--                                    >-->
        <!--                                        Partnership information-->
        <!--                                    </v-stepper-step>-->

        <!--                                    <v-divider></v-divider>-->
        <!--                                </v-stepper-header>-->

        <!--                                <v-stepper-items>-->
        <!--                                    <v-stepper-content step="1">-->
        <!--                                        <v-form-->
        <!--                                            ref="form"-->
        <!--                                            v-model="validPage1"-->
        <!--                                            lazy-validation-->
        <!--                                        >-->
        <!--                                            <v-row-->
        <!--                                                class="my-12">-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-text-field label="Name"-->
        <!--                                                                  placeholder="Please write your first and last name"-->
        <!--                                                                  :rules="[v => !!v || 'Name is required',-->
        <!--                                                              v => (v && v.length < 30) || 'Name must be less than 30 character']"-->
        <!--                                                                  v-model="form.name "></v-text-field>-->
        <!--                                                </v-col>-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-text-field label="Email"-->
        <!--                                                                  :rules="[ v => !!v || 'E-mail is required',-->
        <!--                                                                   v => /.+@.+\..+/.test(v) || 'E-mail must be valid',]"-->
        <!--                                                                  placeholder="Please write your email address"-->
        <!--                                                                  v-model="form.email "></v-text-field>-->
        <!--                                                </v-col>-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-text-field label="Phone No."-->
        <!--                                                                  placeholder="Please Enter your phone number"-->
        <!--                                                                  :rules="[v => !!v || 'Phone No. is required',-->
        <!--                                                                   v => (v && v.length < 15) || 'Phone No. must be less than 15 number']"-->
        <!--                                                                  v-model="form.cellnumber "></v-text-field>-->
        <!--                                                </v-col>-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-text-field label="Company"-->
        <!--                                                                  placeholder="Please Enter name of your company"-->
        <!--                                                                  :rules="[v => !!v || 'Company name is required']"-->
        <!--                                                                  v-model="form.company "></v-text-field>-->
        <!--                                                </v-col>-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-text-field label="Website"-->
        <!--                                                                  placeholder="Please Enter your website"-->
        <!--                                                                  :rules="[ v => !!v || 'Website is required',-->
        <!--                                                                   v => /.+\..+/.test(v) || 'Website must be valid',]"-->
        <!--                                                                  v-model="form.website "></v-text-field>-->
        <!--                                                </v-col>-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-combobox-->
        <!--                                                        :items="['1-10', '11-50', '51-200', '201-500', '501-1000', '1000+']"-->
        <!--                                                        label="No. of employees"-->
        <!--                                                        :rules="[v => !!v || ' Number of employees is required']"-->
        <!--                                                        v-model="form.noOfEmployees"-->
        <!--                                                    ></v-combobox>-->
        <!--                                                </v-col>-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                >-->
        <!--                                                    <v-text-field label="Address"-->
        <!--                                                                  placeholder="Please enter the compony address"-->
        <!--                                                                  v-model="form.address "></v-text-field>-->
        <!--                                                </v-col>-->
        <!--                                            </v-row>-->
        <!--                                            &lt;!&ndash;                                        </v-card>&ndash;&gt;-->
        <!--                                            <v-card-actions>-->
        <!--                                                <v-spacer></v-spacer>-->
        <!--                                                <v-btn-->
        <!--                                                    :disabled="!validPage1"-->
        <!--                                                    color="primary"-->
        <!--                                                    @click="validate"-->
        <!--                                                >-->
        <!--                                                    Continue-->
        <!--                                                </v-btn>-->
        <!--                                            </v-card-actions>-->
        <!--                                        </v-form>-->
        <!--                                    </v-stepper-content>-->

        <!--                                    <v-stepper-content step="2">-->
        <!--                                        <v-form-->
        <!--                                            ref="form2"-->
        <!--                                            v-model="validPage2"-->
        <!--                                            lazy-validation-->
        <!--                                        >-->

        <!--                                            <v-card-->
        <!--                                                class="my-12"-->
        <!--                                                width="100%"-->
        <!--                                            >-->

        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-combobox-->
        <!--                                                        :items="['North America', 'South America', 'Europe', 'Africa', 'MENA', 'South and Southeast Asia', 'East Asia']"-->
        <!--                                                        label="What is your geographical location?"-->
        <!--                                                        v-model="form.region"-->
        <!--                                                        :rules="[v => !!v || ' Geographical location is required']"-->
        <!--                                                    ></v-combobox>-->
        <!--                                                </v-col>-->
        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                >-->
        <!--                                                    <v-textarea label="Your service or product"-->
        <!--                                                                :rules="[v => !!v || ' Your service or product is required']"-->
        <!--                                                                placeholder="Talk about your services that can be applicable in smart home insurance?"-->
        <!--                                                                v-model="form.challenge "></v-textarea>-->
        <!--                                                </v-col>-->
        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                >-->
        <!--                                                    <v-textarea label="Partnership proposal"-->
        <!--                                                                :rules="[v => !!v || ' Partnership proposal is required']"-->
        <!--                                                                placeholder="How we can make a partnership to create value for consumers?"-->
        <!--                                                                v-model="form.challenge "></v-textarea>-->
        <!--                                                </v-col>-->
        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                >-->
        <!--                                                    <v-textarea label="Market potential"-->
        <!--                                                                placeholder="How about the market potential of our partnership ?"-->
        <!--                                                                v-model="form.challenge "></v-textarea>-->
        <!--                                                </v-col>-->
        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                >-->
        <!--                                                    <v-textarea label="Business model"-->
        <!--                                                                :rules="[v => !!v || ' Business model is required']"-->
        <!--                                                                placeholder="Please describe your business model for our partnership"-->
        <!--                                                                v-model="form.challenge "></v-textarea>-->
        <!--                                                </v-col>-->
        <!--                                                <v-col-->
        <!--                                                    class="justify-center"-->
        <!--                                                    cols="12"-->
        <!--                                                    sm="6"-->
        <!--                                                >-->
        <!--                                                    <v-combobox-->
        <!--                                                        :items="['Social networks, Linkedin, Twitter, Instagram, Facebook', 'Emails', 'Personal introduction', 'Search engines', 'Others']"-->
        <!--                                                        label="How do you meet with Insurmatics"-->
        <!--                                                        v-model="form.region"-->
        <!--                                                    ></v-combobox>-->
        <!--                                                </v-col>-->

        <!--                                                <v-card-text>-->
        <!--                                                    <v-container-->
        <!--                                                        class="grey lighten-5 mb-6 mx-auto" align="center"-->
        <!--                                                    >-->
        <!--                                                        Please choose your desired date from available time slot(CET):-->
        <!--                                                    </v-container>-->
        <!--                                                </v-card-text>-->
        <!--                                                <template>-->
        <!--                                                    <v-row>-->
        <!--                                                        <v-col-->
        <!--                                                            cols="12"-->
        <!--                                                            sm="6"-->
        <!--                                                        >-->
        <!--                                                            <v-dialog-->
        <!--                                                                ref="dialog"-->
        <!--                                                                v-model="modal"-->
        <!--                                                                :return-value.sync="date"-->
        <!--                                                                persistent-->
        <!--                                                                width="290px"-->
        <!--                                                            >-->
        <!--                                                                <template v-slot:activator="{ on, attrs }">-->
        <!--                                                                    <v-text-field-->
        <!--                                                                        v-model="date"-->
        <!--                                                                        label="Picker in dialog"-->
        <!--                                                                        prepend-icon="mdi-calendar"-->
        <!--                                                                        readonly-->
        <!--                                                                        v-bind="attrs"-->
        <!--                                                                        v-on="on"-->
        <!--                                                                    ></v-text-field>-->
        <!--                                                                </template>-->
        <!--                                                                <v-date-picker-->
        <!--                                                                    v-model="date"-->
        <!--                                                                    scrollable-->
        <!--                                                                    :min="currentDate"-->
        <!--                                                                    max="2021-11-30"-->
        <!--                                                                    :allowed-dates="allowedDates"-->
        <!--                                                                >-->
        <!--                                                                    <v-spacer></v-spacer>-->
        <!--                                                                    <v-btn-->
        <!--                                                                        text-->
        <!--                                                                        color="primary"-->
        <!--                                                                        @click="modal = false"-->
        <!--                                                                    >-->
        <!--                                                                        Cancel-->
        <!--                                                                    </v-btn>-->
        <!--                                                                    <v-btn-->
        <!--                                                                        text-->
        <!--                                                                        color="primary"-->
        <!--                                                                        @click="$refs.dialog.save(date)"-->
        <!--                                                                    >-->
        <!--                                                                        OK-->
        <!--                                                                    </v-btn>-->
        <!--                                                                </v-date-picker>-->
        <!--                                                            </v-dialog>-->
        <!--                                                        </v-col>-->

        <!--                                                        <v-col-->
        <!--                                                            class="justify-center"-->
        <!--                                                            cols="12"-->
        <!--                                                            sm="6"-->
        <!--                                                        >-->
        <!--                                                            <v-combobox-->
        <!--                                                                :items="['8:00-8:30', '8:30-9:00', '9:00-9:30', '19:00-19:30', '19:30-20:00', '20:00-20:30']"-->
        <!--                                                                label="Select the available time slot(CET)"-->
        <!--                                                                v-model="form.time"-->
        <!--                                                            ></v-combobox>-->
        <!--                                                        </v-col>-->
        <!--                                                    </v-row>-->
        <!--                                                </template>-->

        <!--                                            </v-card>-->

        <!--                                            <v-card-actions>-->
        <!--                                                <v-spacer></v-spacer>-->
        <!--                                                &lt;!&ndash;                            <v-btn text class="m-1" :to="{name:'profiles'}">لغو کردن </v-btn>&ndash;&gt;-->
        <!--                                                &lt;!&ndash;                            <v-btn depressed color="success" class="m-1" href="https://idpay.ir/niomatic-kit" :loading="busy">ورود درگاه اهدای نقدی</v-btn>&ndash;&gt;-->
        <!--                                                <v-btn-->
        <!--                                                    :disabled="!validPage2"-->
        <!--                                                    :loading="busy"-->
        <!--                                                    @click="overAllValidate"-->
        <!--                                                    class="m-1"-->
        <!--                                                    color="success"-->
        <!--                                                    depressed>-->
        <!--                                                    Send-->
        <!--                                                </v-btn>-->
        <!--                                                <v-btn-->
        <!--                                                    color="primary"-->
        <!--                                                    @click="e1 = 1"-->
        <!--                                                >-->
        <!--                                                    back-->
        <!--                                                </v-btn>-->
        <!--                                            </v-card-actions>-->
        <!--                                        </v-form>-->
        <!--                                    </v-stepper-content>-->

        <!--                                </v-stepper-items>-->
        <!--                            </v-stepper>-->
        <!--                        </template>-->


        <!--                    </template>-->

        <!--                    <template>-->
        <!--                        <v-dialog-->
        <!--                            v-model="dialogSend"-->
        <!--                            width="500"-->
        <!--                        >-->

        <!--                            <v-card>-->
        <!--                                <v-card-title class="headline2 grey lighten-2">-->
        <!--                                    The partnership program form-->
        <!--                                </v-card-title>-->

        <!--                                <v-card-text>-->
        <!--                                    Would you like to send the information filled in the form as partnership program?-->
        <!--                                </v-card-text>-->

        <!--                                <v-divider></v-divider>-->

        <!--                                <v-card-actions>-->
        <!--                                    <v-spacer></v-spacer>-->
        <!--                                    <v-btn-->
        <!--                                        @click="addNewBuilding"-->
        <!--                                        color="green"-->
        <!--                                        text-->
        <!--                                    >-->
        <!--                                        Yes-->
        <!--                                    </v-btn>-->
        <!--                                    <v-btn-->
        <!--                                        @click="dialogSend=false"-->
        <!--                                        color="red"-->
        <!--                                        text-->
        <!--                                    >-->
        <!--                                        No-->
        <!--                                    </v-btn>-->
        <!--                                </v-card-actions>-->
        <!--                            </v-card>-->
        <!--                        </v-dialog>-->
        <!--                    </template>-->

        <!--                </template>-->
        <!--            </v-card-text>-->
        <!--        </v-card>-->
    </div>

</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
    import moment from 'moment';

    export default {
        name: "BePartner",
        components: {
            FilePond
        },

        data: function () {
            return {
                currentDate: moment().format("YYYY-MM-DD"),
                notAvailableDates: ["2021-11-01", "2021-11-02"],
                validPage1: true,
                validPage2: true,

                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 30) || 'Name must be less than 30 characters',
                ],
                modal: false,
                date: '',
                e13: 2,
                plan: '',
                e1: 1,
                changedPlan: '',
                building_set: [],
                building_selected_id: '',
                building_id: '',
                valid: true,

                nameRules: [
                    // v => !!v || 'نام لازم می باشد',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the name is 10',
                ],
                familyRules: [
                    v => !!v || 'Last name required',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the last name is 10',
                ],
                cellnumRules: [
                    v => !!v || 'Contact number is required',
                    v => (v && v.length === 11) || ' Contact number is required',
                ],
                select: null,
                items: [
                    'Item 1',
                    'Item 2',
                    'Item 3',
                    'Item 4',
                ],
                checkbox: false,
                //myFiles: ['index.html'],
                dialogUnit: false,
                dialogSupporter: false,
                dialogChangePlan: false,
                dialogExtend: false,
                dialogSend: false,
                snackkbar: false,
                form:
                    {
                        'buildingtype': '',
                        'unitnum': '',
                        'infrastructure': '',
                        'age': '',
                        'totalarea': '',
                        'address': '',
                    },
                formUnitArray: [],
                changepaln: '',
                errors: null,
                responses: null,
                busy: false,
                request: '',
            }
        },
        created() {
            this.date = (new Date(Date.now() - ((new Date())).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            // // let res = new Date(this.date);
            // this.date = this.date.setDate(this.date.getDate() + 7);

            // this.building_set = this.$store.state.customers.id;
            // axios.get('../citizens/' + this.$store.state.customers.id)
            //     .then(response => {
            //         this.building_set = response.data;
            //         // this.snackkbar = true;
            //     })
            //     .catch(errors => {
            //         console.log(this.form);
            //         this.errors = errors.response.data.errors;
            //         console.log(this.errors);
            //     }).finally(() => {
            //     this.busy = false;
            // });
        },

        methods: {
            allowedDates(a) {
                return !this.notAvailableDates.includes(a);
            },

            allowed_dates() {
                // return ["2021-11-01", "202 1-11-02"];
                if (this.allowedDates.indexOf(val) !== -1) {
                    return true
                } else {
                    return false
                }
            },
            overAllValidate() {
                if (this.$refs.form2.validate()) {
                    this.dialogSend = true;
                }
            },

            validate() {
                if (this.$refs.form.validate()) {
                    this.e1 = 2
                }
            },
            changeUnit() {
                this.dialogUnit = true;
                this.formUnitArray = [];
                for (let i = 0; i < this.form.unitnum; i++) {
                    this.formUnitArray.push({
                        'unit_Nom': '',
                        'name': '',
                        'family': '',
                        'cellnumber': '',
                        'area': '',
                        'postalcode': ''
                    });
                }
            }
            ,
            // validate() {
            //
            //     if (this.$refs.form.validate()) {
            //         this.dialogUnit = false;
            //     }
            // },
            reset() {
                this.$refs.form.reset();
            }
            ,

            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            }
            ,
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,
            addNewBuilding() {
                this.dialogSupporter = false;
                this.busy = true;
                axios.post('../building', this.form)
                    .then(response => {
                        this.building_id = response.data.id;
                        //
                        let completeAdd = true;
                        for (let i = 0; i < this.form.unitnum; i++) {
                            axios.post('../unit', $.extend(this.formUnitArray[i], {'building_id': this.building_id}))
                                .then(response => {
                                })
                                .catch(errors => {
                                    completeAdd = false
                                })
                        }
                        if (completeAdd) {
                            let currentTime = new Date();
                            let msg = {
                                'type': 'AddNewBuilding',
                                'status': 'submitted',
                                'issuetracking': currentTime.getTime(),
                                'message': [{
                                    'userType': 'citizen',
                                    'id': '',
                                    'msg': 'اطلاعات ساختمان جدید ثبت شد.',
                                }],
                            };
                            axios.post('../msg', msg)
                                .then(response => {
                                    this.dialogBuy = false;
                                    this.snackkbar = true;
                                })
                                .catch(errors => {
                                })
                        }


                    }).catch(errors => {
                    // console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    // console.log(this.errors);
                }).finally(() => {
                    this.busy = false;

                });
            }
            ,

            extendPlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ExtendPlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'msg': 'Send request to extend plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogExtend = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,

            changePlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ChangePlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'new_plan': this.changedPlan,
                        'msg': 'Request a change plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogChangePlan = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .custom-title {
        color: #333;
        font-size: 15px;
        font-weight: bold;
        margin: 0;
    }

    .custom-body {
        font-size: 14px;
        color: #555;
        padding: 10px;
    }

    .headline2 {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    .GoldText {
        color: #fcc203;
    }

    .Price {
        font-size: 1.5rem;
    }

    .full-height {
        height: 100%;
    }

    .testHeader {
        font-size: 1em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }
</style>

