<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-icon left>bar_chart</v-icon>
                        Wallet Reports and Analytics
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card class="mb-4">
                                    <v-card-title>Wallet Distribution</v-card-title>
                                    <v-card-text>
                                        <div class="chart-container">
                                            <apexchart
                                                type="pie"

                                                :options="walletDistributionOptions"
                                                :series="walletDistributionData">
                                            </apexchart>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card class="mb-4">
                                    <v-card-title>Transaction History</v-card-title>
                                    <v-card-text>
                                        <apexchart type="line" height="350" :options="transactionHistoryOptions" :series="transactionHistoryData"></apexchart>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card class="mb-4">
                                    <v-card-title>Monthly Expenditures</v-card-title>
                                    <v-card-text>
                                        <apexchart type="bar" height="350" :options="monthlyExpendituresOptions" :series="monthlyExpendituresData"></apexchart>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        components: {
            apexchart: VueApexCharts
        },
        data() {
            return {
                walletDistributionOptions: {
                    chart: {
                        type: 'pie'
                    },
                    labels: ['Wallet 1', 'Wallet 2', 'Wallet 3', 'Wallet 4'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                walletDistributionData: [44, 55, 13, 43],

                transactionHistoryOptions: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    }
                },
                transactionHistoryData: [{
                    name: "Transactions",
                    data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 135, 145, 150]
                }],

                monthlyExpendituresOptions: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    },
                    yaxis: {
                        title: {
                            text: '$ (thousands)'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "$ " + val + " thousands"
                            }
                        }
                    }
                },
                monthlyExpendituresData: [{
                    name: 'Expenditure',
                    data: [44, 55, 41, 64, 22, 43, 21, 49, 62, 69, 91, 148]
                }]
            }
        }
    }
</script>

<style scoped>

    .v-card-title {
        display: flex;
        align-items: center;
        font-weight: bold;
    }

    .chart-container {
        max-width: 95%;

        margin: 0 auto;
    }
    @media (max-width: 950px) {
        .chart-container {
            max-width: 70%; /* Full width for smaller screens */
        }
    }

    @media (max-width: 500px) {
        .chart-container {
            height: 250px;
            max-width: 100%; /* Full width for smaller screens */
        }
    }

    /*@media (max-width: 400px) {*/
    /*    .chart-container {*/
    /*        height: 200px;*/
    /*        max-width: 100%; !* Adjust further if necessary *!*/
    /*    }*/
    /*}*/
</style>
