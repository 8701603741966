<template>
    <svg
        viewBox="0 0 20 20"
        version="1.1"
        id="svg4"
        sodipodi:docname="data-visualization-major.svg"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <defs
            id="defs8" />
        <sodipodi:namedview
            id="namedview6"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            showgrid="false"
            inkscape:zoom="40.6"
            inkscape:cx="9.9876847"
            inkscape:cy="9.9876847"
            inkscape:window-width="1920"
            inkscape:window-height="1001"
            inkscape:window-x="-9"
            inkscape:window-y="-9"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg4" />
        <path
            fill-rule="evenodd"
            d="M0 2a1 1 0 011-1h18a1 1 0 110 2h-1v11.5a1.5 1.5 0 01-1.5 1.5h-3.22l.69 2.758a1 1 0 11-1.94.485L11.22 16H8.78l-.81 3.242a1 1 0 11-1.94-.485L6.72 16H3.5A1.5 1.5 0 012 14.5V3H1a1 1 0 01-1-1zm15 3h-2v8h2V5zM9 7h2v6H9V7zM7 9H5v4h2V9z"
            fill="#5C5F62"
            id="path2"
            style="fill:#1a1a1a" />
    </svg>

</template>

<script>
    export default {
        name: "dataVisualizationIcon"
    }
</script>

<style scoped>

</style>
