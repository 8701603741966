<template>

    <div class="pt-16 overflow-visible">

        <v-card max-width="80%" class="mx-auto rounded-form" outlined>
            <v-card-title class="layout justify-center">
                Messages from Client
            </v-card-title>
            <!--            <v-img :src="images" contain max-height="200px">-->
            <v-img :src="images" contain max-height="400px">
            </v-img>
            <v-container fluid>
                <v-row>

                    <template v-if="dogs.id5 !=''">
                        <v-avatar
                            color="gray"
                            size="50"
                        >
                            <v-img :src="images5"></v-img>
                        </v-avatar>
                    </template>

                    <template v-if="dogs.id2 !=''">
                        <v-avatar
                            color="gray"
                            size="50"
                        >
                            <v-img :src="images2"></v-img>
                        </v-avatar>
                    </template>

                </v-row>
                <v-card>
                    <li v-for="item in messages.message">
                        <template v-if="item.userType=='citizen'">
                            <v-textarea label="Message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        auto-grow
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='1'">
                            <v-textarea label="Message from management"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='2'">
                            <v-textarea label="Operator message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='3'">
                            <v-textarea label="Installation expert message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='4'">
                            <v-textarea label="Damage expert message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>
                        <template v-if="item.userType=='5'">
                            <v-textarea label="Expert support message"
                                        v-model="item.msg"
                                        readonly="readonly"
                                        rows="2"
                                        class="mx-5"
                                        filled
                                        shaped
                            >
                            </v-textarea>
                        </template>

                    </li>

                    <template v-if="($store.state.users.access_level == 1 || $store.state.users.access_level == 2)&&(messages.status == 'submitted')">
                        <v-text-field
                            v-model="textMessage"
                            :rules="rules"
                            maxlength="40"
                            label="Please enter your message."
                        >
                        </v-text-field>

                        <v-card-text >
                            <v-autocomplete v-if="messages.type == 'AddNewBuilding' || messages.type == 'RequestService' || messages.type == 'ExtendPlan' || messages.type == 'ChangePlan'" :items="users_level3" :item-text="getItemText" itemid="id" return-object label="User"
                                            :error-messages="errors" placeholder="Select user"  v-model="selected_user"></v-autocomplete>
                            <v-autocomplete v-if="messages.type == 'Support'" :items="users_level5" :item-text="getItemText" itemid="id" return-object label="User"
                                            :error-messages="errors" placeholder="Select user" v-model="selected_user"></v-autocomplete>
                            <v-autocomplete v-if="messages.type == 'ReportDamage'" :items="users_level4" :item-text="getItemText" itemid="id" return-object label="User"
                                            :error-messages="errors" placeholder="Select user" v-model="selected_user"></v-autocomplete>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text class="m-1">Cancel</v-btn>
                            <v-btn depressed color="secondary" class="m-2" @click="addNewTask" :loading="busy">Assign
                            </v-btn>
                        </v-card-actions>
                    </template>

                    <template v-if="($store.state.users.access_level == 3 || $store.state.users.access_level == 4|| $store.state.users.access_level == 5)&&(messages.status == 'inprogress')" >
                        <v-text-field
                            v-model="textMessage"
                            :rules="rules"
                            maxlength="40"
                            label="Please type your message below."
                        >
                        </v-text-field>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text class="m-1">Cancel</v-btn>
                            <v-btn depressed color="secondary" class="m-2" @click="reportTask" :loading="busy">Report
                            </v-btn>
                        </v-card-actions>
                    </template>

                    <template v-if="($store.state.users.access_level == 1 || $store.state.users.access_level == 2)&&(messages.status == 'professional_response')">
                        <v-text-field
                            v-model="textMessage"
                            :rules="rules"
                            maxlength="40"
                            label="Please type your message below."
                        >
                        </v-text-field>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="m-1">Cancel</v-btn>
                        <v-btn depressed color="secondary" class="m-2" @click="confirmTask" :loading="busy">Approve the process
                        </v-btn>
                    </v-card-actions>
                </template>


                    <template v-if="($store.state.users.access_level == 1 || $store.state.users.access_level == 2)&&(messages.status == 'confirmation')">
                        <v-text-field
                            v-model="textMessage"
                            :rules="rules"
                            maxlength="40"
                            label="Please type your message below."
                        >
                        </v-text-field>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text class="m-1">Cancel</v-btn>
                            <v-btn depressed color="secondary" class="m-2" @click="closeTask" :loading="busy">Close the process
                            </v-btn>
                        </v-card-actions>
                    </template>
                </v-card>
            </v-container>
        </v-card>
    </div>
</template>

<script>

    import vueFilePond from 'vue-filepond';
    import LocationForComparison from "./LocationForComparison";

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond,
            LocationForComparison,
        },
        props: [
            'messages',
            'id'
        ],
        data() {
            return {
                video: {},
                canvas: {},
                captures: [],
                snackbar: true,
                text: 'Hello, I\'m a snackbar',
            }
        },
        data: function () {
            return {
                building_selected_id:'',
                rules: [v => v.length <= 50 || 'Maximum length is 50'],
                //myFiles: ['index.html'],
                dogs: [],
                images: '',
                users: '',
                users_level3:'',
                users_level4:'',
                users_level5:'',
                selected_user: '',
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '',
                        'state': '',
                        'id': '',
                        'level': '',
                        'catched_lon': '',
                        'catched_lat': '',
                        'textMessage': ''
                    },
                LatLong: {
                    'Lat': '',
                    'Long': ''
                },
                newStatus: {
                    status: true
                },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                gettingLocation: '',
                errorStr: '',
                locationLatLong: '',
                // textAreaMessage:'\nب'
                textAreaMessage: '',
                textMessage: '',
                images2:'',
                images5:''
            }
        },
        //
        created() {
            this.fetchData();//users
            this.getMessageInfo();
            this.form.catched_lat = this.$store.state.LatLong.Lat;
            this.form.catched_lon = this.$store.state.LatLong.Long;

            this.locateAsync();
            // if(navigator.geolocation){
            //     navigator.geolocation.getCurrentPosition(position => {
            //         this.form.catched_lon = position.coords.longitude;
            //         this.form.catched_lat = position.coords.latitude;
            //     })
            // };
        },

        mounted: function () {


        },
        computed: {
            usersLevelItems() {
                return this.users_level3.map(user => ({
                    name: `${user.first_name} ${user.surname}`,
                    id: user.id,
                }));
            },
            usersLevelWithFullName() {
                if (!Array.isArray(this.users_level3)) {
                    console.error('users_level3 is not an array', this.users_level3);
                    return [];
                }
                return this.users_level3.map(user => ({
                    ...user,
                    full_name: `${user.first_name} ${user.surname}`,
                }));
            },
        },

        methods: {
            getItemText(item) {
                return `${item.first_name} ${item.surname}`;
            },
            async locateAsync() {
                this.locationLatLong = await this.getLocation();
                this.form.catched_lat = this.locationLatLong.coords.latitude;
                this.form.catched_lon = this.locationLatLong.coords.longitude;
            },

            async getLocation() {
                return new Promise((resolve, reject) => {
                    if (!("geolocation" in navigator)) {
                        reject(new Error('Geolocation is not available.'));
                    }
                    navigator.geolocation.getCurrentPosition(resolve, reject);

                });
            },

            fetchData() {
                this.busy = true;
                axios.get('../../users/level')
                    .then(response => {
                        console.log(response.data);
                        this.users = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;
                });

                //level3
                axios.get('../../users/levels/3')
                    .then(response => {
                        console.log(response.data);
                        this.users_level3 = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;
                });
                //level4
                axios.get('../../users/levels/4')
                    .then(response => {
                        console.log(response.data);
                        this.users_level4 = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;
                })
                //level5
                axios.get('../../users/levels/5')
                    .then(response => {
                        console.log(response.data);
                        this.users_level5 = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;
                })
            },

            getMessageInfo() {
                this.busy = true;
                axios.get('report/index/' + this.$route.params.id)
                    .then(response => {
                        //console.log(response);
                        this.dogs = response.data;
                        if(this.dogs.id2){
                            axios.get('user/' + this.dogs.id2)
                                .then(response => {
                                    this.images2 = "/storage/images/user/" + response.data.pic;
                                })
                        }
                        if(this.dogs.id5){
                            axios.get('/user/' + this.dogs.id5)
                                .then(response => {
                                    this.images5 = "/storage/images/user/" + response.data.pic;
                                })
                        }
                        // this.images= require('../../../../public/storage/images/publics/'+this.dogs.fig);
                        this.images = '/storage/images/publics/' + this.dogs.fig;
                        //this.images=require( '../../../../storage/app/public/images/publics/Public_100420200728185f7979927beb2.jpg');
                    })
            },


            addNewTask() {

                let currentTime = new Date();
                let msg = {
                    'type': this.messages.type,
                    'status': 'inprogress',
                    'issue_tracking':currentTime.getTime(),
                    'message': {'userType': this.$store.state.users.access_level,
                        'id': this.selected_user.id,
                        'msg': this.textMessage,
                        'pic': '',
                    },
                };
                axios.put('../../msg/'+this.messages.issue_tracking, msg)
                    .then(response => {
                        this.snackkbar = true;
                        this.$router.go();
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });

                // this.form.textMessage = this.$store.state.users.name + ":" + this.textMessage;
                // this.busy = true;
                // this.form.name = this.selected_user.id;
                // this.form.state = "Unread";
                // this.form.id = this.$route.params.id;
                // this.form.level = this.selected_user.access_level;
                // this.newStatus.status = false;
                //
                // axios.post('../report/update', this.form)
                //     .then(response => {
                //         console.log(this.responses);
                //         this.$store.commit("updateSnackbarAssign", true);
                //         this.$router.push({
                //             name: 'public',
                //             params: {locationss: this.locationss, snackbarAssign: true}
                //         });
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         alert('error');
                //         // this.errors = errors.response.data.errors;
                //         // console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },
            reportTask() {

                let currentTime = new Date();
                let msg = {
                    'type': this.messages.type,
                    'status': 'professional_response',
                    'issue_tracking':currentTime.getTime(),
                    'message': {'userType': this.$store.state.users.access_level,
                        'id': '',
                        'msg': this.textMessage,
                        'pic': '',
                    },
                };
                axios.put('../../msg/'+this.messages.issue_tracking, msg)
                    .then(response => {
                        this.snackkbar = true;
                        this.$router.go();
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });

                // this.form.textMessage = this.$store.state.users.name + ":" + this.textMessage;
                // this.busy = true;
                // this.form.name = this.selected_user.id;
                // this.form.state = "Unread";
                // this.form.id = this.$route.params.id;
                // this.form.level = this.selected_user.access_level;
                // this.newStatus.status = false;
                //
                // axios.post('../report/update', this.form)
                //     .then(response => {
                //         console.log(this.responses);
                //         this.$store.commit("updateSnackbarAssign", true);
                //         this.$router.push({
                //             name: 'public',
                //             params: {locationss: this.locationss, snackbarAssign: true}
                //         });
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         alert('error');
                //         // this.errors = errors.response.data.errors;
                //         // console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },
            confirmTask() {

                let currentTime = new Date();
                let msg = {
                    'type': this.messages.type,
                    'status': 'confirmation',
                    'issue_tracking':currentTime.getTime(),
                    'message': {'userType': this.$store.state.users.access_level,
                        'id': '',
                        'msg': this.textMessage,
                        'pic': '',
                    },
                };
                axios.put('../../msg/'+this.messages.issue_tracking, msg)
                    .then(response => {
                        this.snackkbar = true;
                        this.$router.go();
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });

                // this.form.textMessage = this.$store.state.users.name + ":" + this.textMessage;
                // this.busy = true;
                // this.form.name = this.selected_user.id;
                // this.form.state = "Unread";
                // this.form.id = this.$route.params.id;
                // this.form.level = this.selected_user.access_level;
                // this.newStatus.status = false;
                //
                // axios.post('../report/update', this.form)
                //     .then(response => {
                //         console.log(this.responses);
                //         this.$store.commit("updateSnackbarAssign", true);
                //         this.$router.push({
                //             name: 'public',
                //             params: {locationss: this.locationss, snackbarAssign: true}
                //         });
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         alert('error');
                //         // this.errors = errors.response.data.errors;
                //         // console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },
            closeTask() {

                let currentTime = new Date();
                let msg = {
                    'type': this.messages.type,
                    'status': 'closed',
                    'issue_tracking':currentTime.getTime(),
                    'message': {'userType': this.$store.state.users.access_level,
                        'id': '',
                        'msg': this.textMessage,
                        'pic': '',
                    },
                };
                axios.put('../../msg/'+this.messages.issue_tracking, msg)
                    .then(response => {
                        this.snackkbar = true;
                        this.$router.go();
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });

                // this.form.textMessage = this.$store.state.users.name + ":" + this.textMessage;
                // this.busy = true;
                // this.form.name = this.selected_user.id;
                // this.form.state = "Unread";
                // this.form.id = this.$route.params.id;
                // this.form.level = this.selected_user.access_level;
                // this.newStatus.status = false;
                //
                // axios.post('../report/update', this.form)
                //     .then(response => {
                //         console.log(this.responses);
                //         this.$store.commit("updateSnackbarAssign", true);
                //         this.$router.push({
                //             name: 'public',
                //             params: {locationss: this.locationss, snackbarAssign: true}
                //         });
                //     })
                //     .catch(errors => {
                //         console.log(this.form);
                //         alert('error');
                //         // this.errors = errors.response.data.errors;
                //         // console.log(this.errors);
                //     }).finally(() => {
                //     this.busy = false;
                // });
            },

            assignTask() {
                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.name = this.selected_user.id;
                this.form.state = "Inprogress";
                this.form.id = this.$route.params.id;
                this.form.level = this.selected_user.access_level;
                //this.FindLevel1();
                axios.post('../../report/update', this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'public'});
                        this.$store.commit("updateSnackbarInprogress", true);

                        this.$router.push({name: 'customerMessages', params: {snackbarInprogress: true}});

                        //alert('پیغام شما ثبت شد')
                        //this.$router.push(toString(response.data[0].data.id));
                        // flash('success message ...');
                        // axios.get('../report');
                    })
                    .catch(errors => {
                        console.log(this.form);
                        alert(errors);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            Finded() {

                this.form.catched_lon = this.$store.state.LatLong.Long;
                this.form.catched_lat = this.$store.state.LatLong.Lat;

                this.locateAsync();
                // this.form.catched_lat = this.locationLatLong.coords.latitude;
                // this.form.catched_lon = this.locationLatLong.coords.longitude;
                navigator.geolocation.getCurrentPosition(position => {
                    this.form.catched_lon = position.coords.longitude;
                    this.form.catched_lat = position.coords.latitude;
                    this.LatLong.Lat = position.coords.latitude;
                    this.LatLong.Long = position.coords.longitude;
                    this.$store.commit("updateLocation", this.LatLong);
                });
                if (this.form.catched_lon === '') {
                    this.snackbar = true;
                    this.text = "روشن";

                    // alert('salam');
                } else {
                    this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                    this.busy = true;
                    this.form.state = "Underworking";
                    this.form.id = this.$route.params.id;
                    this.form.level = 4;
                    this.$router.push({
                        name: 'create',
                        params: {
                            fig_id: this.images,
                            form_public: this.form,
                            locationss: this.locationss,
                            cell_number: this.dogs.cell_number
                        }
                    });
                    this.busy = false;
                }

                // axios.post('../report/update', this.form)
                //     .then( response => {
                //         console.log(this.responses);
                //     })
                //     .catch(errors =>{
                //         console.log(this.form);
                //         this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(()=>{
                //     this.busy=false;
                // });
            },

            NotFinded() {
                this.form.catched_lon = this.$store.state.LatLong.Long;
                this.form.catched_lat = this.$store.state.LatLong.Lat;
                this.locateAsync();


                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                        this.LatLong.Lat = position.coords.latitude;
                        this.LatLong.Long = position.coords.longitude;
                        this.$store.commit("updateLocation", this.LatLong);
                    });
                }
                if (this.form.catched_lon === '') {
                    this.snackbar = true;
                    this.text = "روشن";

                    // alert('salam');
                } else {
                    this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                    this.busy = true;
                    this.form.state = "Underworking";
                    this.form.id = this.$route.params.id;
                    this.form.level = 4;
                    axios.post('../report/update', this.form)
                        .then(response => {
                            //this.responses = response.data[0].data.id;
                            console.log(this.responses);
                            this.$store.commit("updateSnackbarNotFinded", true);
                            this.$router.push({name: 'customerMessages', params: {snackbarNotFinded: true}});

                            // this.$router.push({name:'public'});
                            //alert('پیغام شما ثبت شد')
                            //this.$router.push(toString(response.data[0].data.id));
                            // flash('success message ...');
                            // axios.get('../report');
                        })
                        .catch(errors => {
                            console.log(this.form);
                            this.errors = errors.response.data.errors;
                            console.log(this.errors);
                        }).finally(() => {
                        this.busy = false;
                    });
                }
            },

            ReportMistake() {
                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.state = "Finished";
                this.form.id = this.$route.params.id;
                this.form.level = 6;//Report A mistake
                axios.post('../report/update', this.form)
                    .then(response => {
                        // this.responses = response.data[0].data.id;
                        console.log(this.responses);
                        // this.$router.push({name:'public'});
                        this.$store.commit("updateSnackbarFinal", true);
                        this.$router.push({name: 'customerMessages', params: {snackbarFinal: true}});

                        //alert('پیغام شما ثبت شد')
                        //this.$router.push(toString(response.data[0].data.id));
                        // flash('success message ...');
                        // axios.get('../report');
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            LocationVerified() {
                this.form.textMessage = this.dogs.message + this.$store.state.users.name + ":" + this.textMessage;
                this.busy = true;
                this.form.state = "Finished";
                this.form.id = this.$route.params.id;
                // this.form.level=4;
                this.form.level = 7;//Verify
                axios.post('../report/update', this.form)
                    .then(response => {
                        //this.responses = response.data[0].data.id;
                        console.log(response);
                        // this.$router.push({name:'public'});
                        this.$store.commit("updateSnackbarFinal", true);

                        this.$router.push({name: 'customerMessages', params: {snackbarFinal: true}});

                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        // console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
        }
    }
</script>

<style scoped>
    .spacing-playground .v-select .v-input__prepend-outer, .spacing-playground .v-select .v-input__append-outer {
        margin-top: 0.55rem;
        margin-right: 0.2rem;
    }

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>


<!--<template>-->
<!--<div class="pt-16">-->
<!--<v-card max-width="480px" class="mx-auto rounded-form" outlined >-->
<!--    <v-card-title class="text-uppercase">-->
<!--        مشخصات سگ را وارد نمایید.-->
<!--    </v-card-title>-->
<!--    <v-img :src="require('../../../assets/iamges/g8013.png')" contain max-height="120px">-->
<!--    </v-img>-->

<!--    <v-card-text>-->
<!--        <v-text-field  label="ای دی کاربر" :error-messages="errors" placeholder="لطفا سن خود را وارد نمایید." v-model="form.id "></v-text-field>-->
<!--    </v-card-text>-->
<!--    <v-card-actions>-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn depressed color="success" class="m-1" @click="addNewDog" :loading="busy">اضافه کردن </v-btn>-->
<!--    </v-card-actions>-->
<!--</v-card>-->

<!--</div>-->
<!--</template>-->

<!--<script>-->

<!--    export default {-->
<!--        name: "EditPublicMessage",-->
<!--        components: {-->
<!--        },-->
<!--        data() {-->
<!--            return {-->
<!--                form:-->
<!--                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'-->
<!--                        'id':'',-->
<!--                    },-->
<!--            }-->
<!--        },-->
<!--        data:function () {-->
<!--            return{-->
<!--                //myFiles: ['index.html'],-->
<!--                errors: null,-->
<!--                responses:null,-->
<!--                busy:false,-->
<!--            }-->
<!--        },-->
<!--        mounted: {-->

<!--        },-->
<!--        computed: {-->
<!--        },-->
<!--        created() {-->
<!--            this.fetchData();-->
<!--            this.getMessageInfo();-->
<!--        },-->
<!--        methods:{-->
<!--            getMessageInfo(){-->

<!--            },-->
<!--            fetchData(){-->
<!--                this.busy = true;-->

<!--                axios.get('/report/index')-->
<!--                    .then(response =>{-->
<!--                        console.log(response);-->
<!--                        this.dogs = response.data;-->
<!--                    })-->
<!--                    .catch(errors =>{-->
<!--                        alert('unable to fetch');-->
<!--                    }).finally(()=>{-->
<!--                    this.busy = false;-->

<!--                })-->
<!--            },-->
<!--            addNewDog() {-->
<!--                this.busy=true;-->
<!--                axios.post('../dogdata', this.form)-->
<!--                    .then( response => {-->
<!--                        this.responses = response.data[0].data.id;-->
<!--                        //this.$router.push(toString(response.data[0].data.id));-->
<!--                        this.$router.push({name:'profiles'});-->
<!--                        })-->
<!--                    .catch(errors =>{-->
<!--                        console.log(this.form);-->
<!--                        // this.errors = errors.response.data.errors;-->
<!--                        console.log(this.errors);-->
<!--                    }).finally(()=>{-->
<!--                    this.busy=false;-->
<!--                });-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->

<!--    #camera {-->
<!--        text-align: center;-->
<!--        color: #2c3e50;-->
<!--    }-->
<!--    #video {-->
<!--        background-color: #000000;-->
<!--    }-->
<!--    #canvas {-->
<!--        display: none;-->
<!--    }-->
<!--    li {-->
<!--        display: inline;-->
<!--        padding: 5px;-->
<!--    }-->
<!--.rounded-form{-->
<!--    border-radius: 28px !important;-->

<!--}-->
<!--</style>-->
