<template>
    <div>
        <v-snackbar v-model="snackbar" color="#f2c4d3" :timeout="5000" top>
                    <span style="color: #333333;">
                        The inbox was updated on {{ new Date()}}.
                    </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>
        <v-container
            class="mb-6 mx-auto" align="center"
        >
            <br>
            <v-toolbar
                dense
                color="gray"
            >
                <v-row class="mx-0">
                    <v-flex>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ShowAll()"
                                >
                                    <v-icon
                                        color="gray"
                                    >
                                        email
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>View All Messages</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ShowUnread()"
                                >
                                    <v-icon color="grey darken-1">$vuetify.icons.unread</v-icon>
                                </v-btn>
                            </template>
                            <span> View Unread Messages </span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small

                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ShowInprogress()"
                                >
                                    <!--                                <v-icon color="grey darken-1">$vuetify.icons.inprogress</v-icon>-->
                                    <v-icon color="#ff589bff">hourglass_empty</v-icon>
                                </v-btn>
                            </template>
                            <span> View In-progress Tasks </span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ShowUnderworking()"
                                >
                                    <!--                                <v-icon color="grey darken-1">$vuetify.icons.inAction</v-icon>-->
                                    <v-icon color="#fb9f61ff">schedule</v-icon>

                                </v-btn>
                            </template>
                            <span> View Active Tasks </span>
                        </v-tooltip>
                    </v-flex>

                    <v-flex>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ShowFinished()"
                                >
                                    <v-icon color="#827717">check_circle</v-icon>

                                    <!--                                <v-icon-->
                                    <!--                                    color="#827717"-->
                                    <!--                                >-->
                                    <!--                                    assignment_turned_in-->
                                    <!--                                </v-icon>-->
                                </v-btn>
                            </template>
                            <span> View Approved Tasks</span>
                        </v-tooltip>
                    </v-flex>

                    <v-flex>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="ShowVerified()"
                                >
                                    <v-icon
                                        color="green"
                                    >
                                        check_circle_outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span> View Completed Tasks </span>
                        </v-tooltip>
                    </v-flex>
                </v-row>
            </v-toolbar>
            <!--        if (state=="Unread") return 'red'-->
            <!--        else if (state=="Inprogress") return 'pink lighten-3'-->
            <!--        else if (state=="Underworking") return 'amber'-->
            <!--        else if (state=="Finished") return 'lime'-->
            <!--        else if (state=="Verified") return 'green'-->

            <v-card>

                <v-card-title>
                    {{messageType}}
                    <v-spacer></v-spacer>
                    <!--                <v-text-field-->
                    <!--                    v-model="search"-->
                    <!--                    append-icon="mdi-magnify"-->
                    <!--                    label="جستجو"-->
                    <!--                    single-line-->
                    <!--                    hide-details-->
                    <!--                ></v-text-field>-->
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="messages"
                    :search="search"
                    :loading="busy"
                    :items-per-page="20"
                    sort-by="id"


                >
                    <template v-slot:item.id="{ item }">
                        <v-chip>
                            {{ item.id }}
                        </v-chip>
                    </template>
                    <!--                <template  v-slot:item.name="{ item }" >-->
                    <!--                    <v-chip>-->
                    <!--                        {{ item.name }}-->
                    <!--                    </v-chip>-->
                    <!--                </template>-->
                    <template v-slot:item.status="{ item }">
                        <v-icon
                            :color="getColor(item.status)"
                        >
                            {{getIconMessage(item.status)}}
                        </v-icon>
                        <v-chip
                            :color="getColor(item.status)"
                            dark
                        >
                            {{getTextState(item.status)}}
                        </v-chip>
                    </template>
                    <template v-slot:item.progress="{ item }">
                        <v-progress-circular :size="45" :value="getProgressValue(item.status)">
                            {{getProgressValue(item.status)}}%
                        </v-progress-circular>
                    </template>
                    <template v-slot:item.topic="{ item }">
                        {{ getTopicMessage(item.type) }}
                    </template>

                    <template v-slot:item.updated_at="{ item }">
                        {{ getLastUpdateTime(item.updated_at) }}
                    </template>
                    <template v-slot:item.track="{ item }">
                        <v-chip
                            v-for="name in getUniqueContributor(item.message)"
                            :key="name"
                            color="blue-grey"
                            dark
                        >
                            {{ name }}
                        </v-chip>
                    </template>

                    <template v-slot:item.edit="{ item }">
                        <v-btn :to="{ name: 'checkUserMessage', params: { id: item.id, messages: item.message }}" icon>
                            <v-icon color="orange">edit</v-icon>
                        </v-btn>
                    </template>

                    <template #item.remove="{item}">
                        <v-btn @click.stop.prevent="dialog='true' , id = item.id" icon>
                            <v-icon color="red">delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
            <v-dialog
                v-model="dialog"
                width="500"
                :retain-focus="false"
            >
                <v-card>
                    <v-card-title class="red white--text">
                        Are you sure you want to delete?
                    </v-card-title>
                    <v-card-text>
                        Once deleted, it is no longer possible to return the information. If you are sure, press the
                        delete
                        button; otherwise, cancel.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click.prevent="ShowRemoveDiolog"
                        >
                            Delete
                        </v-btn>

                        <v-btn
                            color="green"
                            @click="dialog = false">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
    import VueMoment from 'vue-jalali-moment';
    import moment from 'moment';

    export default {
        components: {
            VueMoment,

        },
        name: "OwnersIndex",


        created() {
            console.log('created userMessage');
            this.fetchData()
        },
        mounted() {
            console.log('mounted');

        },
        props: [
            'user',
            'locationss',
            // 'snackbarAssign'
        ],
        props: {
            'snackbarAssign': Boolean,
            'snackbarInprogress': Boolean,
            'snackbarNotFinded': Boolean,
            'snackbarFinal': Boolean
        },
        computed: {},

        data: function () {
            return {
                snackbar: true,
                id: '',
                dialog: false,
                busy: false,
                messages: [],
                dogsss: [],
                search: '',
                messageType: 'Team Collaboration Channel',
                headers: [
                    {text: 'Message ID', value: 'id', sortable: true},

                    // {
                    //     text: 'نام',
                    //     align: 'start',
                    //     sortable: false,
                    //     value: 'name',
                    // },

                    {text: 'Progress', value: 'progress'},
                    {text: 'Status', value: 'status'},
                    {text: 'Topic', value: 'topic'},
                    {text: 'Last update', value: 'updated_at',},
                    {text: 'Contributors', value: 'track', sortable: false,},
                    {text: 'Check', value: 'edit', sortable: false,},
                    {text: 'Remove', value: 'remove', sortable: false,},
                ],
                desserts: [],
            }
        },
        methods: {
            ShowAll() {
                this.search = '';
                this.messageType = 'Inbox'
            },

            ShowVerified() {
                this.search = 'closed';
                this.messageType = 'Finished tasks'
            },
            ShowFinished() {
                this.search = 'confirmation';
                this.messageType = 'Approved tasks'
            },
            ShowUnderworking() {
                this.search = 'professional_response';
                this.messageType = 'In action tasks';
            },
            ShowInprogress() {
                this.search = 'inprogress';
                this.messageType = 'In progress tasks';
            },
            ShowUnread() {
                this.search = 'submitted';
                this.messageType = 'Unread messages';
            },
            fetchData() {
                this.busy = true;
                axios.get('../team_msg')
                    .then(response => {
                        console.log('response:')
                        console.log(response);
                        this.messages = response.data;
                        console.log(this.messages);

                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../publics/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },
            // this.$router.push({name:'create' , params: { fig_id: this.images, form_public: this.form } });
            alert() {
                alert('Hi')
            },
            EditData: function (id) {
                this.$router.push({name: 'editmessage', params: {id: id, locationss: this.locationss}})
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },

             ShowRemoveDiolog() {
                 console.log("Delete method called");
                axios.delete('../team_msg/' + this.id)
                    .then(response => {
                        console.log(response);
                        this.dialog = false;
                        this.$router.go();
                        // this.$router.push({name:'public'});
                        // this.dogss = response.data;
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                        this.busy = false;

                    })
            },
            EditData: function (id) {
                this.$router.push({name: 'editmessage', params: {id: id, locationss: this.locationss}})
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },
            getTopicMessage(item) {
                if (item == "ChangeAccessLevel") return 'New access request'
                else if (item == "ExtendPlan") return 'extension plan'
                else if (item == "ChangePlan") return 'Change Plan'
                else if (item == "RequestService") return 'Request Service'
                else if (item == "ReportDamage") return 'Damage Report'
                else if (item == "Support") return 'support'
            },
            getColor(state) {
                if (state == "submitted") return 'red'
                else if (state == "inprogress") return '#EC407A'
                else if (state == "professional_response") return '#F57C00'
                else if (state == "confirmation") return '#827717'
                else if (state == "closed") return 'green'
            },
            getLastUpdateTime(time) {
                let currentTime = new Date();
                let timeMoment = moment.utc(time, 'YYYY-MM-DD HH:mm:ss');

                // Convert the moment object to a JavaScript Date object
                let timeDate = timeMoment.toDate();

                let diffInMs = currentTime - timeDate;

                let seconds = Math.floor((diffInMs / 1000) % 60);
                let minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
                let hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
                let days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

                // Format the time difference string
                let timeString = "";

                if (days > 0) {
                    timeString += `${days} days, `;
                }
                if (hours > 0 || days > 0) { // Added condition to show hours if days are also present
                    timeString += `${hours} hours, and`;
                }
                if (minutes >= 0 || hours > 0 || days > 0) { // Added condition to show minutes if hours or days are present
                    timeString += ` ${minutes} minutes `;
                }
                timeString += `ago`;

                return timeString;

                let time_diff = Math.floor((currentTime.getTime() - time) / 60000);
                // return time_diff;
                if (parseInt(time_diff) < 60) {
                    return time_diff + " Minutes ago";
                } else if (parseInt(time_diff) < 1440) {
                    let hour = Math.floor(parseInt(time_diff) / 60);
                    let min = time_diff % 60;

                    return hour + " hours" + min + " minutes ago";
                } else {
                    let hourTotal = Math.floor(parseInt(time_diff) / 60);
                    let days = Math.floor(hourTotal / 24);
                    let hour = time_diff % 24;

                    return days + " days" + hour + " hours ago";

                }
            },
            getProgressValue(state) {
                if (state == "submitted") return '0'
                else if (state == "inprogress") return '30'
                else if (state == "professional_response") return '50'
                else if (state == "confirmation") return '80'
                else if (state == "closed") return '100'
            },
            getIconMessage(state) {
                if (state == "submitted") return 'email'
                else if (state == "inprogress") return 'forward'
                else if (state == "professional_response") return 'explore'
                else if (state == "confirmation") return 'build'
                else if (state == "closed") return 'verified_user'
            },
            getTextState(state) {
                if (state == "submitted") return 'Unread message'
                else if (state == "inprogress") return 'In progress tasks'
                else if (state == "professional_response") return 'In action tasks'
                else if (state == "confirmation") return 'Approved tasks'
                else if (state == "closed") return 'Finished tasks'
            },
            getUniqueContributor(messageArray) {
                // Ensure messageArray is an array and has content
                if (!Array.isArray(messageArray) || messageArray.length === 0) {
                    return [];
                }
                // const fullnamesArray = messageArray.map(item => item.fullname);
                // console.log(fullnamesArray);
                // console.log('fullnamesArray');

                // Use Set to ensure uniqueness and map to extract the 'fullname' property
                const uniqueFullnames = [...new Set(messageArray.map(item => item.fullname))];
                return uniqueFullnames;
            }

        }
    }

</script>

<style scoped>

</style>
