<template>
    <svg
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
        version="1.1"
        id="svg6"
        sodipodi:docname="payments_black_24dp.svg"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <defs
            id="defs10" />
        <sodipodi:namedview
            id="namedview8"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            showgrid="false"
            inkscape:zoom="8.7291667"
            inkscape:cx="36.486873"
            inkscape:cy="5.097852"
            inkscape:window-width="1920"
            inkscape:window-height="1001"
            inkscape:window-x="-9"
            inkscape:window-y="-9"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg6" />
        <path
            d="M0 0h24v24H0z"
            fill="none"
            id="path2" />
        <path
            d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-9-1c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-6v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"
            id="path4"
            style="fill:#757575;fill-opacity:1" />
    </svg>
</template>

<script>
    export default {
        name: "Appliance"
    }
</script>

<style scoped>

</style>
