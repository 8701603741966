<template>


    <v-container fluid fill-height class="bg">
        <v-layout align-center justify-center>
            <!--                    Large Page-->
            <v-card
                shaped
                :loading="loading"
                width="70%"
                max-width="800pt"
                color="white"
                class="elevation-20"

                rounded="20"
                v-if="isHeaderLarge"
            >
                <v-row>
                    <v-col cols="5">
                        <v-card

                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to Hubbcast panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Build your smart home application in just 3 mins!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click=""
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>

                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        outlined
                                        persistent-hint="Email*"
                                        v-model="form.email"
                                        dense
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-fade-transition leave-absolute>
                                                <v-progress-circular
                                                    v-if="loading"
                                                    size="24"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                                <img
                                                    v-else
                                                    width="24"
                                                    height="24"
                                                    src="../../../assets/icons/email.svg"
                                                >
                                            </v-fade-transition>
                                        </template>


                                    </v-text-field>
                                    <v-text-field
                                        type="password"
                                        rounded
                                        outlined
                                        dense
                                        v-model="form.password"
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-fade-transition leave-absolute>
                                                <v-progress-circular
                                                    v-if="loading"
                                                    size="24"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                                <img
                                                    v-else
                                                    width="24"
                                                    height="24"
                                                    src="../../../assets/icons/Password.svg"


                                                >
                                            </v-fade-transition>
                                        </template>
                                    </v-text-field>

                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        Login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="mx-auto my-auto">
                        <!-- <img src="../../../../public/images/PanelLogin.svg">-->
<!--                        <img src="../../../assets/icons/Panel/Login.svg">-->
<!--                        <img src="../../../assets/icons/Customer/LoginCustomer.svg">-->
                        <img :src="require('../../../assets/icons/Panel/Login.svg')">
                    </v-col>
                </v-row>

            </v-card>

            <!--                    Medium size page-->
            <v-card
                shaped
                :loading="loading"
                width="80%"
                max-width="550pt"
                color="white"
                class="elevation-20"
                rounded="20"
                v-if="isHeaderMedium"
            >
                <v-row>
                    <v-col cols="9" class="my-auto mx-auto">
                        <!-- <img src="../../../../public/images/PanelLogin.svg"> -->
                        <img src="../../../assets/icons/Panel/Login.svg">

<!--                        <img :src="require('../../../assets/icons/Panel/Login.svg')">-->

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-card
                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to Hubbcast panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Build your smart home application in just 3 mins!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click="loader = 'loading'"
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>
                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        label="Cell number or Email*"
                                        v-model="form.email"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        rounded
                                        type="password"
                                        label="Password*"
                                        v-model="form.password"
                                        outlined
                                    ></v-text-field>
                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>

            </v-card>

            <!--                    Small size-->
            <v-card

                shaped
                :loading="loading"
                width="80%"
                min-width="250pt"
                color="white"
                class="elevation-20 my-10"
                rounded="20"
                v-if="isHeaderSmall"
            >
                <v-row>
                    <v-col cols="10" class="mx-auto my-auto">
<!--                        <img src="../../../assets/icons/Panel/Login.svg">-->
                        <img :src="require('../../../assets/icons/Panel/Login.svg')">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-card
                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to Hubbcast panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Build your smart home application in just 3 mins!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click="loader = 'loading'"
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>
                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        label="Email*"
                                        v-model="form.email"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        rounded
                                        type="password"
                                        label="Password*"
                                        v-model="form.password"
                                        outlined
                                    ></v-text-field>
                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>

            </v-card>


        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'LoginPanels',
        props: {
            source: String,
        },
        data() {
            return {
                customer:'',
                cookieData: '',
                googleLoginLink: 'google',
                loader: null,
                loading: false,
                user: '',
                userTest: '',
                form:
                    {
                        'email': '', 'password': '',
                    },
            }
        },
        mounted() {
            this.checkForGoogleLogin();
        },
        methods: {
            async checkForGoogleLogin() {
                const urlParams = new URLSearchParams(window.location.search);
                console.log(urlParams);
                if (urlParams.has("id")) {
                    const developerData = {
                        id: urlParams.get("id"),
                        first_name: urlParams.get("first_name"),
                        surname: urlParams.get("surname"),
                        email: urlParams.get("email"),
                        pic: urlParams.get("pic"),
                        national_ID: urlParams.get("national_ID"),
                    };

                    this.$store.commit("isDeveloperAuthenticated", true);
                    localStorage.setItem("isDeveloperAuthenticated", true);

                    this.developer = developerData;

                    this.$store.commit("updateDeveloper", this.developer);
                    this.deleteCookie("developerInfo");
                    this.setCookie("developerInfo", this.developer, 1);
                    this.$emit("developerInfo", this.developer);
                    await this.$router.push({name: "dashboard"});
                }
            },

            navigateToRegister() {
                this.$router.push({name: 'register'});
            },
            setCookie(name, value, hours, secure = true, sameSite = 'Strict') {
                let expires = '';
                if (hours) {
                    const date = new Date();
                    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
                    expires = `; expires=${date.toUTCString()}`;
                }

                const secureFlag = secure ? '; Secure' : '';
                const sameSiteFlag = sameSite ? `; SameSite=${sameSite}` : '';

                const encodedName = encodeURIComponent(name);
                const jsonString = JSON.stringify(value);
                const encodedValue = encodeURIComponent(jsonString);

                document.cookie = `${encodedName}=${encodedValue}${expires}${secureFlag}${sameSiteFlag}`;
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            deleteCookie(name) {
                this.setCookie(name, '', -1);
            },
            async postLoginData() {
                try {
                    const response = await axios.post(`../panel/login`, this.form);

                    const developerData = response.data.developer;
                    console.log(response);
                    console.log(response.data);
                    console.log(developerData);
                    this.$store.commit('setDeveloperAuthenticated', true);
                    localStorage.setItem('isDeveloperAuthenticated', true);
                    console.log('this.$store.state.isDeveloperAuthenticated:');
                    console.log(this.$store.state.isDeveloperAuthenticated);
                    const developer = {
                        first_name: developerData.first_name,
                        surname: developerData.surname,
                        email: developerData.email,
                        pic: developerData.pic,
                        national_ID: developerData.national_ID,
                        id: developerData.id
                    };

                    this.$store.commit('updateDeveloper', developer);
                    this.deleteCookie('developerInfo');
                    this.setCookie('developerInfo', developer, 1);
                    this.$emit('developerInfo', developer);

                    await this.$router.push({name: 'dashboard'});
                } catch (error) {
                    console.error('An error occurred while logging in:', error);
                }
            },




        },
        computed: {

            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },
    };

</script>

<style scoped>
    .bg {
        background: url("../../../assets/icons/Background.svg") no-repeat center center;
        background-size: cover;
    }

    .v-btn {
        text-transform: none !important;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    .v-text-field .v-label--active {
        transform: translateX(-12%) translateX(-7.5px) translateY(-28px) scale(0.75) !important;

    }

    .v-text-field--dense .v-label--active {
        transform: translateX(-12%) translateX(-7.5px) translateY(-21px) scale(0.75) !important;

    }

</style>


