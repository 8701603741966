<template>
    <div class="test">
        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-card

                            class="mx-2 my-4"
                            width="90%"
                            rounded="3"
                            elevation="3"
                        >


                            <v-card-title>Daily home statistics</v-card-title>
                            <template>
                                <v-card-text>
                                    <div>View house statistics by selecting the date via the calendar below.</div>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="80%"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date"
                                                label="Pick date"
                                                prepend-icon="event_note"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            no-title
                                            scrollable
                                            width="200"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu.save(date)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
<!--                                    <date-picker-->
<!--                                        v-model="date"-->
<!--                                        format="YYYY-MM-DD"-->
<!--                                        display-format="dddd jDD jMMMM jYYYY"></date-picker>-->

                                </v-card-text>

                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions>

                                    <v-btn
                                        color="deep-purple lighten-2"
                                        @click="ShowFigOfDog()"
                                    >
                                        Display
                                    </v-btn>
                                </v-card-actions>
                            </template>

                        </v-card>
                    </v-row>
                </v-flex>
            </v-layout>
            <v-layout align-center row class="mb-5"
                      v-if="count && ($store.state.users.access_level == 1 || $store.state.users.access_level == 7 || $store.state.users.access_level == 8)"
            >
                <v-row
                    class="mx-auto"
                    no-gutters
                    align="center" justify="space-around"
                >
                    <v-col
                        cols="12"
                        class="justify-center"
                    >
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto my-auto rounded-form" outlined width="80%" height="500px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="#DCEDC8"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm black--text ">
                                                General statistics of Tehran houses
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <apexchart type="polarArea" height="45%" :options="chartOptionsPi"
                                                   :series="seriesPi"></apexchart>
                                        <apexchart type="radar" height="45%" :options="chartOptionsRa"
                                                   :series="seriesRa"></apexchart>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>
                    </v-col>
                </v-row>
            </v-layout>
            <template v-if="showImage">

                <v-row
                    v-if="count && ($store.state.users.access_level == 1 || $store.state.users.access_level == 7 || $store.state.users.access_level == 8)"
                    class="mx-auto">

                    <v-chip
                        class="ma-2 mx-auto text-lg-center"
                        color="green"
                        text-color="white"
                    >
                        Number of registered houses today: {{count}}
                    </v-chip>

                </v-row>
                <v-row>
                    <v-alert
                        width="100%"
                        border="top"
                        color="red lighten-2"
                        dark
                        v-if="count == 0"
                    >
                        Unfortunately, no house was registered on the selected day.
                    </v-alert>
                    <v-col cols="12"
                           class="justify-center"
                           v-if="count && ($store.state.users.access_level == 1 || $store.state.users.access_level == 7 || $store.state.users.access_level == 8)"
                    >
                        <v-flex align-self-center align="center">
                            <v-card class="mx-auto rounded-form" outlined width="97%" height="500px">
                                <template class="justify-center">
                                    <v-app-bar
                                        dark
                                        color="green"
                                        class="text-sm-center"
                                    >
                                        <v-toolbar-title align="justify-center">
                                            <span class=" text-sm">
                                                Route map of user and location of registered houses
                                            </span>
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>
                                    </v-app-bar>
                                    <v-card height="90%">
                                        <TechnicianLocationRep :doglocations="locations"
                                                               :userlocations="markerCoordinates2"/>
                                    </v-card>
                                </template>


                            </v-card>
                        </v-flex>
                    </v-col>
                    <v-col
                        v-for="n in count"
                        :key="n"
                        class="d-flex child-flex"
                        cols="6"
                        v-if="count"
                    >

                        <v-img
                            :src="imagess[n-1]"
                            :lazy-src="imagess[n-1]"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            @click="$router.push({name: 'capturedlocation', params:{latlong:locations[n-1], time:times[n-1]}})"
                        >
                            <!--                            this.$router.push({name: 'public', params: {snackbarInprogress: true}});-->
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>
    import AllUserLocation from "../views/User/AllUserLocation";

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import LiveTracking from "../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    import moment from 'jalali-moment/jalali-moment';
    import TechnicianLocationRep from "../views/Dog/TechnicianLocationRep";

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            LiveTracking,
            TechnicianLocationRep,
            VueMoment,
            apexchart: VueApexCharts,
            moment
        },
        props: [
            'user',
        ],
        data: () => ({
            last_login_error: '',
            markerCoordinates2: [],
            moments: '',
            users: [],
            selected_user: '',
            errors: '',
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            count: '',
            time: '',
            imagess: [],
            locations: [],
            times: [],
            showImage: false,
            dateTommorow: '',
            date: '',
            menu:false,
            datetoGregorian: '',
            dialogVocation: false,


            //Table

            //
            alignments: [
                'start',
                'center',
                'end',

            ],
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1
            //
            //pi chart
            seriesPi: [],
            chartOptionsPi: {
                labels: ['اداری', 'تجاری', 'آپارتمان', 'خانه ویلایی'],
                chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },

            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                    toolbar: false,
                    zoom: {
                        enabled: true
                    },
                },

                xaxis: {
                    categories: ['نوساز', '5-10', '10-15', '15 به بالا '],
                    position: 'top'
                }
            },

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        // data() {
        //     return {
        //         labels: ['Impressions', 'Add To Cart', 'Buy'],
        //         subLabels: ['Direct', 'Social Media', 'Ads'],
        //         values: [
        //             // with the given Labels and SubLabels here's what the values represent:
        //             //
        //             // Direct, Social, Ads
        //             //    |      |     |
        //             //    v      v     v
        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
        //             [3000, 1700, 1000], // Segments of "Add To Cart"
        //             [600,  200,  130]   // Segments of "Buy"
        //         ],
        //         colors: [
        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        //         ],
        //         direction: 'horizontal',
        //         gradientDirection: 'horizontal',
        //         height: 300,
        //         width: 800
        //     };
        // },
        created() {
            // console.log(this.$refs.funnel);
            this.moments = moment('2021-01-11 17:01:26', 'YYYY-M-D HH:mm:ss').add(3, 'hours', 30, 'minutes').locale('fa').format('YYYY/M/D HH:mm:ss');
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;

        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.fetchData();
            this.loaded = false;
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/users/technicion')
                    .then(response => {
                        console.log(response.data);
                        this.users = response.data;
                    })
                    .catch(errors => {
                        alert('unable to fetch1');
                    }).finally(() => {
                    this.busy = false;
                })
            },
            ShowFigOfDog() {
                axios.post('../homedatas/all', {'date': this.date})
                    .then(response => {
                        // this.dogs = response.data[0].data;
                        console.log(response);
                        let index;
                        this.count = response.data.length;
                        this.seriesPi = [0, 0, 0, 0];
                        this.locations = [];
                        this.markerCoordinates2 = [];
                        this.imagess = [];
                        for (index = 0; index < response.data.length; ++index) {
                            this.imagess[index] = '/storage/images/dogs/' + response.data[index].first_fig;
                            this.locations[index] = [response.data[index].catched_lon, response.data[index].catched_lat];
                            this.times[index] = moment(response.data[index].created_at).locale('fa').format('YYYY/M/D HH:mm:ss');
                            if ((response.data[index].age == "توله") && (response.data[index].sex == "نر")) {
                                this.seriesPi[2]++;
                            } else if ((response.data[index].age == "توله") && (response.data[index].sex == "ماده")) {
                                this.seriesPi[3]++;
                            } else if ((response.data[index].age == "بالغ") && (response.data[index].sex == "نر")) {
                                this.seriesPi[0]++;
                            } else if ((response.data[index].age == "بالغ") && (response.data[index].sex == "ماده")) {
                                this.seriesPi[1]++;
                            }
                        }
                        this.showImage = true;
                        // this.count = response.length;
                        // '/storage/images/dogs/' +
                        // this.imagess = response;
                        let selected_id;
                        if (this.selected_user == "") {
                            selected_id = this.$store.state.users.id;
                            this.last_login_error = this.selected_user.last_login_error;
                        } else {
                            selected_id = this.selected_user.id;
                            this.last_login_error = 0;
                        }
                        // this.last_login_error = this.users[selected_id].last_login_error;
                    })
                    .catch(errors => {
                        alert('لطفا زنده گیر را انتخاب نمایید');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            increase() {
                this.height += 10
            },

            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                axios.put('../user/' + this.$store.state.users.id, this.form)
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'مدیریت'
                else if (this.$store.state.users.access_level == "2") return 'کارشناس'
                else if (this.$store.state.users.access_level == "3") return 'دکتر آراد کوه'
                else if (this.$store.state.users.access_level == "4") return 'کارشناس مسئول'
                else if (this.$store.state.users.access_level == "5") return 'تکنسین'
                else return 'کاربر تایید نشده'
            },
        },
        computed: {
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
