<template>
    <svg
        width="6.3499999mm"
        height="6.3499999mm"
        viewBox="0 0 6.3499999 6.3499999"
        version="1.1"
        id="svg5"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        sodipodi:docname="IoT.svg"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <sodipodi:namedview
            id="namedview7"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            inkscape:document-units="mm"
            showgrid="false"
            inkscape:zoom="11.573962"
            inkscape:cx="25.704249"
            inkscape:cy="14.731343"
            inkscape:window-width="1920"
            inkscape:window-height="1001"
            inkscape:window-x="-9"
            inkscape:window-y="-9"
            inkscape:window-maximized="1"
            inkscape:current-layer="g839"
            height="6.35mm" />
        <defs
            id="defs2" />
        <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            id="layer1">
            <g
                style="fill:none"
                id="g839"
                transform="matrix(0.26458333,0,0,0.26458333,86.437069,91.923522)">
                <path
                    clip-rule="evenodd"
                    d="m -319.30182,-345.41681 c 1.33852,-1.24652 3.13593,-2.01068 5.11053,-2.01068 2.4937,0 4.7023,1.21764 6.0648,3.08692 3.0837,0.50852 5.4352,3.18568 5.4352,6.41308 0,3.5899 -2.9102,6.5 -6.5,6.5 h -2.5 v 3 c 0,0.5523 0.4477,1 1,1 h 2.1707 c 0.4118,-1.1652 1.5231,-2 2.8293,-2 1.6569,0 3,1.3431 3,3 0,1.6569 -1.3431,3 -3,3 -1.3062,0 -2.4175,-0.8348 -2.8293,-2 h -2.1707 c -1.6569,0 -3,-1.3431 -3,-3 v -3 h -2 v 3 c 0,1.6569 -1.34315,3 -3,3 h -2.17071 c -0.41183,1.1652 -1.52307,2 -2.82929,2 -1.65685,0 -3,-1.3431 -3,-3 0,-1.6569 1.34315,-3 3,-3 1.30622,0 2.41746,0.8348 2.82929,2 h 2.17071 c 0.55229,0 1,-0.4477 1,-1 v -3 h -2 c -3.86599,0 -7,-3.134 -7,-7 0,-3.86599 3.13401,-7 7,-7 0.1306,0 0.26046,0.004 0.38947,0.0107 z m -1.39989,7.37231 c -0.39053,-0.39052 -0.39053,-1.02369 0,-1.41421 0.7893,-0.7893 1.72633,-1.4154 2.7576,-1.84257 1.03126,-0.42716 2.13652,-0.64702 3.25282,-0.64702 1.1162,0 2.2215,0.21986 3.2528,0.64702 1.0313,0.42716 1.9683,1.05327 2.7576,1.84257 0.3905,0.39052 0.3905,1.02369 0,1.41421 -0.3905,0.39053 -1.0237,0.39053 -1.4142,0 -0.6036,-0.60358 -1.3202,-1.08237 -2.1088,-1.40902 -0.7886,-0.32666 -1.6338,-0.49478 -2.4874,-0.49478 -0.8536,0 -1.6988,0.16812 -2.48746,0.49478 -0.78861,0.32665 -1.50517,0.80544 -2.10875,1.40902 -0.39052,0.39053 -1.02369,0.39053 -1.41421,0 z m 2.51042,2.53121 c -0.39052,-0.3905 -0.39052,-1.0237 0,-1.4142 0.45963,-0.4596 1.00528,-0.82423 1.6058,-1.07298 0.6005,-0.24874 1.2442,-0.37677 1.8942,-0.37677 0.65,0 1.2936,0.12802 1.8942,0.37677 0.6005,0.24875 1.1461,0.61338 1.6058,1.07298 0.3905,0.3905 0.3905,1.0237 0,1.4142 -0.3906,0.3905 -1.0237,0.3905 -1.4142,0 -0.274,-0.2739 -0.5991,-0.4912 -0.957,-0.6394 -0.3579,-0.1483 -0.7414,-0.2246 -1.1288,-0.2246 -0.3874,0 -0.7709,0.0763 -1.1288,0.2246 -0.3579,0.1482 -0.6831,0.3655 -0.95698,0.6394 -0.39053,0.3905 -1.02369,0.3905 -1.41422,0 z"
                    fill="#758ca3"
                    fill-rule="evenodd"
                    id="path827"
                    style="fill:#333333" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "IoTProtocolsLowCode"
    }
</script>

<style scoped>

</style>
