<template>
    <div>
        <v-card
            rounded="10"
            class="mt-4 mx-auto rounded-card"
            width="90%"
            elevation="24"

        >
            <template>
                <v-app-bar color="#7B5D6A" window style="height: 95px;"> <!-- Increased height of v-app-bar -->

                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'propertyManagerDirectory'}" icon color="white" large>
                            <v-icon large>mdi-office-building</v-icon>
                        </v-btn>
                        <v-row justify="center"> <!-- Added v-row with justify="center" -->
                            <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Property Manager Directory
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'propertyOwnerDirectory'}" icon color="white" large>
                            <v-icon large>mdi-home</v-icon>
                        </v-btn>
                        <v-row justify="center"> <!-- Added v-row with justify="center" -->
                            <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Property Owner Directory
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-app-bar>
                <v-main class="mb-15" style="background-color: #f5f5f5;">
                    <router-view></router-view>
                </v-main>
            </template>
        </v-card>
        <!--        @click:row="item=>$router.push({name:'profile',params:{profile_id:item.id}})"-->


    </div>
</template>

<script>
    // import propertyManagerDirectory from "./PublicReport/Homeowners/propertyManagerDirectory";
    // import propertyOwnerDirectory from "./PublicReport/Homeowners/propertyOwnerDirectory";

    export default {
        components: {
            // propertyManagerDirectory,
            // propertyOwnerDirectory,
        },
        name: "OwnersIndex",


        created() {

        },
        mounted() {
            this.fetchData();
        },


        data: function () {
            return {
                tab: '',
                busy: false,
                dogs: [],

                headers: [
                    {text: 'شناسه ی دارنده', value: 'id'},

                    {
                        text: 'نام',
                        align: 'start',
                        value: 'name',
                    },
                    {text: 'نام خانوادگی', value: 'family'},
                    {text: 'جنس', value: 'sex'},

                    {text: 'نوع کاربری', value: 'user', sortable: false},
                    {text: 'تایید کردن', value: 'edit', sortable: false},
                    {text: 'اختصاص سگ', value: 'assign', sortable: false},
                    {text: 'حذف', value: 'remove', sortable: false},
                    // { text: 'Dog ID', value: 'family' },
                ],


            }
        },
        methods: {
            updateRemoveID(id) {
                axios.put('../owner/' + id, {'verified': 1})
                    .then(response => {
                        console.log(response);
                        this.$router.go(0);
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            getTextState(state) {
                if (state == "0") return 'تایید نشده'
                else return 'تایید شده';
            },
            getColor(state) {
                if (state == "0") return 'orange'
                else return 'green';
            },
            fetchData() {
                this.busy = true;

                axios.get('/owner')
                    .then(response => {
                        this.dogs = response.data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },


        }
    }

</script>

<style scoped>
    .rounded-card {
        border-radius: 20px !important;
        overflow: hidden;
    }

</style>
