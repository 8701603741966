<template>
    <v-card class="mx-auto my-6 rounded-form" width="90%" max-width="1200" outlined elevation="10">
        <v-card-title class="justify-center title">Browse Property Manager Plans</v-card-title>
        <v-divider class="mx-4 my-3"></v-divider>
        <v-container class="grey lighten-5 mb-6 mx-auto" align="center">
            <v-row no-gutters class="px-3 py-1" align="center" justify="space-around">
                <v-col v-if="!building_set.length" cols="12" class="text-center">
                    <v-card-text class="subtitle-1">No registered building</v-card-text>
                </v-col>

                <v-col v-for="item in building_set" :key="item.id" lg="4" md="6" cols="12" class="mb-4">
                    <v-card class="rounded-lg card-style" elevation="3">
                        <v-img :src="'/storage/images/buildings/' + item.pic" class="white--text align-end" height="200px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                            <v-btn fab small dark color="#7b5765ff" class="mt-4 btn-style" @click="$router.push({ name: 'homeprofile', params: { building: item } })">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-img>
                        <v-divider class="mx-4 my-2"></v-divider>
                        <v-row no-gutters class="px-1 py-1">
                            <v-col cols="6">
                                <v-text-field dense readonly label="Structure" outlined class="text-field mx-1" v-model="item.infrastructure"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense readonly label="Age" outlined class="text-field mx-1" v-model="item.age"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="px-1 py-1">
                            <v-col cols="6">
                                <v-text-field dense readonly label="No. of Units" outlined class="text-field mx-1" v-model="item.unit_num"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field dense readonly label="Risk signals" outlined class="text-field mx-1" v-model="item.risk_count"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="px-1 py-1">
                            <v-col cols="12">
                                <v-text-field dense readonly label="Address" outlined class="text-field mx-1" v-model="item.address"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>


<script>
    export default {
        props: ['user'],
        data: () => ({
            building_set: '',
        }),
        created() {
            axios.get('../citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.building_set = response.data;
                })
                .catch(errors => {
                    console.log(this.errors);
                });
        },
    }
</script>

<style scoped>
    .title {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #4a4a4a;
    }

    .card-style {
        background-color: #f8f9fa;
        border: 1px solid #e0e0e0;
        transition: transform 0.3s;
    }

    .card-style:hover {
        transform: scale(1.05);
    }

    .btn-style {
        background-color: #7b5765;
        transition: background-color 0.3s;
    }

    .btn-style:hover {
        background-color: #5a3d4f;
    }

    .text-field {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin-bottom: 8px !important;
    }

    .v-card-text, .v-container {
        font-family: 'Roboto', sans-serif;
    }

    .rounded-form {
        border-radius: 20px;
    }

    .mx-auto {
        margin: 0 auto;
    }

    .my-6 {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .px-1 {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .py-1 {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }
</style>
