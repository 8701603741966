<template>
    <div>
        <v-snackbar v-model="snackbar" color="#f2c4d3" :timeout="5000" top>
                    <span style="color: #333333;">
                        The messages updated in {{ new Date() }} are as follows.
                    </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>
        <br>

        <v-toolbar
            dense
            color="gray"
        >
            <v-row class="mx-0">
                <v-flex>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="ShowAll()"
                            >
                                <v-icon
                                    color="gray"
                                >
                                    home
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>User messages</span>
                    </v-tooltip>
                </v-flex>
                <v-flex>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="ShowUnread()"
                            >
                                <v-icon
                                    color="red"
                                >
                                    email
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Sent by users</span>
                    </v-tooltip>
                </v-flex>
                <v-flex>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small

                                v-bind="attrs"
                                v-on="on"
                                @click="ShowInprogress()"
                            >
                                <v-icon
                                    color="#EC407A"
                                >
                                    assignment_ind
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Wait for response </span>
                    </v-tooltip>
                </v-flex>
                <v-flex>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="ShowUnderworking()"
                            >
                                <v-icon
                                    color="#F57C00"

                                >
                                    assignment
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>response from insurer</span>
                    </v-tooltip>
                </v-flex>

                <v-flex>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="ShowFinished()"
                            >
                                <v-icon
                                    color="#827717"
                                >
                                    assignment_turned_in
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Confirmation</span>
                    </v-tooltip>
                </v-flex>

                <v-flex>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="ShowVerified()"
                            >
                                <v-icon
                                    color="green"
                                >
                                    verified_user
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Closed</span>
                    </v-tooltip>
                </v-flex>
                <!--                <v-flex>-->
                <!--                    <v-tooltip bottom>-->
                <!--                        <template v-slot:activator="{ on, attrs }">-->
                <!--                            <v-btn-->
                <!--                                icon-->
                <!--                                small-->
                <!--                                v-bind="attrs"-->
                <!--                                v-on="on"-->
                <!--                                @click="ShowUnVerified()"-->
                <!--                            >-->
                <!--                                <v-icon-->
                <!--                                    color="#7E57C2"-->
                <!--                                >-->
                <!--                                    error-->
                <!--                                </v-icon>-->
                <!--                            </v-btn>-->
                <!--                        </template>-->
                <!--                        <span>خطای مکانی</span>-->
                <!--                    </v-tooltip>-->
                <!--                </v-flex>-->
            </v-row>
        </v-toolbar>
        <v-card>

            <v-card-title>
                {{messageType}}
                <v-spacer></v-spacer>
                <!--                <v-text-field-->
                <!--                    v-model="search"-->
                <!--                    append-icon="mdi-magnify"-->
                <!--                    label="جستجو"-->
                <!--                    single-line-->
                <!--                    hide-details-->
                <!--                ></v-text-field>-->
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="messages"
                :search="search"
                :loading="busy"
                :items-per-page="20"

            >
                <template v-slot:item.id="{ item }">
                    <v-chip>
                        {{ item.issue_tracking }}
                    </v-chip>
                </template>
                <!--                <template  v-slot:item.name="{ item }" >-->
                <!--                    <v-chip>-->
                <!--                        {{ item.name }}-->
                <!--                    </v-chip>-->
                <!--                </template>-->
                <template v-slot:item.status="{ item }">
                    <v-icon
                        :color="getColor(item.status)"
                    >
                        {{getIconMessage(item.status)}}
                    </v-icon>
                    <v-chip
                        :color="getColor(item.status)"
                        dark
                    >
                        {{getTextState(item.status)}}
                    </v-chip>
                </template>
                <template v-slot:item.progress="{ item }">
                    <v-progress-circular :size="45" :value="getProgressValue(item.status)">
                        {{getProgressValue(item.status)}}%
                    </v-progress-circular>
                </template>
                <template v-slot:item.topic="{ item }">
                    {{ getTopicMessage(item.type) }}
                </template>

                <template v-slot:item.lastupdate="{ item }">
                    {{ getLastUpdateTime(item.issue_tracking) }}
                </template>
                <!--                <template v-slot:item.track="{ item }">-->
                <!--                    <v-btn @click.prevent="ShowLocation(item.TimeID)" icon  ><v-icon color="blue">gps_not_fixed</v-icon></v-btn>-->
                <!--                </template>-->

                <template v-slot:item.edit="{ item }">
                    <v-btn :to="{name:'showmessage', params: {message: item.message }}" icon>
                        <v-icon color="orange">edit</v-icon>
                    </v-btn>
                </template>

                <!--                <template #item.remove="{item}">-->
                <!--                    <v-btn @click.stop.prevent="dialog='true' , id = item.TimeID" icon ><v-icon color="red">delete</v-icon></v-btn>-->
                <!--                </template>-->
            </v-data-table>
        </v-card>
        <v-dialog
            v-model="dialog"
            width="500"
            :retain-focus="false"
        >
            <v-card>
                <v-card-title class="red white--text">
                    Are you sure you want to delete?
                </v-card-title>
                <v-card-text>
                    Once deleted, it is no longer possible to return the information. If you are sure, press the delete
                    button, otherwise cancel.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        @click.passive="ShowRemoveDiolog"
                    >
                        Remove
                    </v-btn>

                    <v-btn
                        color="green"
                        @click="dialog = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import VueMoment from 'vue-jalali-moment';

    export default {
        components: {
            VueMoment,
        },
        name: "OwnersIndex",


        created() {
            this.fetchData()
        },
        mounted() {

        },
        props: [
            'user',
            'locationss',
            // 'snackbarAssign'
        ],
        props: {
            'snackbarAssign': Boolean,
            'snackbarInprogress': Boolean,
            'snackbarNotFinded': Boolean,
            'snackbarFinal': Boolean
        },
        computed: {},

        data: function () {
            return {
                selectedMessage: '',
                snackbar: true,
                id: '',
                dialog: false,
                busy: false,
                messages: [],
                dogsss: [],
                search: '',
                messageType: 'The process of all requests',
                headers: [
                    {text: 'Message number', value: 'id'},

                    // {
                    //     text: 'نام',
                    //     align: 'start',
                    //     sortable: false,
                    //     value: 'name',
                    // },

                    {text: 'work progression', value: 'progress'},
                    {text: 'status', value: 'status'},
                    {text: 'Topic', value: 'topic'},
                    {text: 'Last update', value: 'lastupdate',},
                    // { text: 'مسیر حرکت', value: 'track', sortable: false, },
                    {text: 'Read', value: 'edit', sortable: false,},
                    // { text: 'حذف', value: 'remove',  sortable: false,},
                ],
                desserts: [],
            }
        },
        methods: {
            ShowAll() {
                this.search = '';
                this.messageType = 'Inbox'
            },
            ShowUnVerified() {
                this.search = 'UnV_erified';
                this.messageType = 'Unverified'
            },
            ShowVerified() {
                this.search = 'closed';
                this.messageType = 'closed'
            },
            ShowFinished() {
                this.search = 'confirmed';
                this.messageType = 'confirmed'
            },
            ShowUnderworking() {
                this.search = 'professional_response';
                this.messageType = 'professional response'
            },
            ShowInprogress() {
                this.search = 'inprogress';
                this.messageType = 'in progress'
            },
            ShowUnread() {
                this.search = 'submitted';
                this.messageType = 'submitted'
            },
            fetchData() {
                this.busy = true;
                axios.get('citizen/messages')
                    .then(response => {
                        console.log(response);
                        this.messages = response.data;
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../publics/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },
            // this.$router.push({name:'create' , params: { fig_id: this.images, form_public: this.form } });
            alert() {
                alert('Hi')
            },
            EditData: function () {
                this.$router.push({path: '../addappliance'})
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },

            ShowRemoveDiolog() {
                axios.put('/report/delete/' + this.id)
                    .then(response => {
                        console.log(response);
                        this.$router.go();
                        // this.$router.push({name:'public'});
                        // this.dogss = response.data;
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            EditData: function (id) {
                this.$router.push({name: 'editmessage', params: {id: id, locationss: this.locationss}})
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },
            getTopicMessage(item) {
                if (item == "AddNewBuilding") return 'New building'
                else if (item == "ExtendPlan") return 'Plan extension'
                else if (item == "ChangePlan") return 'Change plan'
                else if (item == "RequestService") return 'Request service'
                else if (item == "ReportDamage") return 'Damage report'
                else if (item == "Support") return 'Support'
            },
            getColor(state) {
                if (state == "submitted") return 'red'
                else if (state == "inprogress") return '#EC407A'
                else if (state == "professional_response") return '#F57C00'
                else if (state == "confirmation") return '#827717'
                else if (state == "closed") return 'green'
            },
            getLastUpdateTime(time) {
                let currentTime = new Date();
                let time_diff = Math.floor((currentTime.getTime() - time) / 60000);
                // return time_diff;
                if (parseInt(time_diff) < 60) {
                    return time_diff + " minutes age";
                } else if (parseInt(time_diff) < 1440) {
                    let hour = Math.floor(parseInt(time_diff) / 60);
                    let min = time_diff % 60;

                    return hour + " hour" + min + " minutes age";
                } else {
                    let hourTotal = Math.floor(parseInt(time_diff) / 60);
                    let days = Math.floor(hourTotal / 24);
                    let hour = time_diff % 24;

                    return days + " day" + hour + " hours ago";

                }
            },
            getProgressValue(state) {
                if (state == "submitted") return '0'
                else if (state == "inprogress") return '30'
                else if (state == "professional_response") return '50'
                else if (state == "confirmation") return '80'
                else if (state == "closed") return '100'
            },
            getIconMessage(state) {
                if (state == "submitted") return 'email'
                else if (state == "inprogress") return 'forward'
                else if (state == "professional_response") return 'explore'
                else if (state == "confirmation") return 'build'
                else if (state == "closed") return 'verified_user'
            },
            getTextState(state) {
                if (state == "submitted") return 'submitted'
                else if (state == "inprogress") return 'in progress'
                else if (state == "professional_response") return 'expert response'
                else if (state == "confirmation") return 'confirmation'
                else if (state == "closed") return 'closed'
            },

        }
    }

</script>

<style scoped>

</style>
