<template>
    <div class="map-view" >
        <!--    {{doginfos}}-->
        <l-map v-if="show" :zoom="zoom" :center="center">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker
                :key="index"
                v-for="(doginfo, index) in doginfos"
                :lat-lng="latLng(doginfo.catched_lat, doginfo.catched_lon)"
            ></l-marker>
            <!--&lt;!&ndash;        <l-marker :lat-lng= "latLng(35, 52)">&ndash;&gt;-->
            <!--                        :keys="index"-->
            <!--                        v-for="(doginfo, index) in doginfos"-->
            <!--                        :lat-lng="latLng(doginfo.catched_lat, doginfo.catched_lon)"-->
            <!--        </l-marker>-->
        </l-map>
    </div>
</template>

<script>
    import { LMap, LTileLayer, LMarker} from 'vue2-leaflet';
    export default {
        name: "AllDogLocation",
        props:{
            doginfos: Array

        },
        components: {
            LMap,
            LTileLayer,
            LMarker,

        },
        methods:{
            latLng : function (lat, lng) {
                return L.latLng(lat, lng);
            }
        },
        data :function () {
            return{

                show:false,

                zoom:11,
                center: L.latLng(35.5057496, 51.3554893),
                url:'https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=ef2ca72a4a16486995e3d050d1302568',
                //url:'dreamy',
                attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                marker: L.latLng(35.5987618, 51.4405782),
                //marker: L.latLng(35.5987618, 52.4405782),
            }
        },
        created() {
            setTimeout(()=>{ this.show=true }, 400);

        }
    }
</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>
