<template>
    <v-app v-if="!$store.state.isAuthenticated">
        <!-- Login, Register, NotFound Templates -->
        <template v-if="$route.name == 'login' || $route.name == 'NotFound'">
            <LoginPanel @userInfo="handleLogin"></LoginPanel>
        </template>
        <template v-else-if="$route.name == 'register'">
            <RegisterPanel></RegisterPanel>
        </template>
        <template v-else>
            <NotFound></NotFound>
        </template>
    </v-app>

    <v-app id="inspire" v-else>
        <!-- Snackbar Notifications -->

        <v-snackbar v-model="snackkbar" color="#f2c4d3" :timeout="3000" top>
            <span style="color: #333333;">
                 Welcome, {{user.first_name}}! It's a pleasure to have you on the {{businessData.name}} Business Panel.
            </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>
        <v-snackbar v-model="$store.state.SnackbarAssign" color="#f2c4d3" :timeout="4000" centered>
            <span style="color: #333333;">
                The information has been successfully forwarded to our specialist. You may now proceed to the subsequent step.
            </span>
            <!--            <v-btn text @click="snackbarAssign = false">بستن</v-btn>-->
        </v-snackbar>
        <v-snackbar v-model="$store.state.SnackbarInprogress" color="#f2c4d3" :timeout="6000" centered>
            <span style="color: #333333;">
                The technician has successfully received the location details and customer report.
            </span>
            <!--            <v-btn text @click="snackbarInprogress = false">بستن</v-btn>-->
        </v-snackbar>
        <v-snackbar v-model="$store.state.SnackbarNotFinded" color="#b22222" :timeout="5000" centered>
            <span>
                The senior management team has been duly notified about the unavailability of the home address. Dispatch of the confirmation will proceed in accordance with the guidance provided by our experts.            </span>
        </v-snackbar>
        <v-snackbar v-model="$store.state.SnackbarFinal" color="#f2c4d3" :timeout="4000" centered>
            <span style="color: #333333;">
                The performance report for the technician has been meticulously documented, taking into account their online location data.
            </span>
        </v-snackbar>

        <v-snackbar v-model="$store.state.SnackbarCreateDogProfile" color="#f2c4d3" :timeout="4000" top>
            <span style="color: #333333;">
               We have successfully received and securely archived the home information
            </span>
        </v-snackbar>
        <v-navigation-drawer
            v-if="isHeaderLarge"
            app
            dense
            permanent
            absolute
            v-model="drawer"
            :mini-variant.sync="mini"
            left
        >

            <v-list-item class="px-2">

                <v-list-item-avatar>
                    <v-img
                        contain
                        width="90%"
                        :src="isLink(user.pic) ? user.pic : '/storage/images/user/' + user.pic"
                    ></v-img>
                </v-list-item-avatar>

                <v-list-item-title :style="fontSizeStyle">{{user.first_name + ' ' + user.surname}}
                </v-list-item-title>
                <v-btn icon @click.stop="toggleMini">
                    <v-icon>arrow_back</v-icon>
                </v-btn>
            </v-list-item>
            <v-divider></v-divider>


            <v-list-item
                flat="true"
                dense
                :append="true"
                class="mt-4"
                link
                :to="{name:'performanceHighlights' ,  params: { user: user }} "
                v-if="$store.state.users.access_level < 9 || $store.state.users.access_level == 5 "
                :user="$store.state.users"
            >
                <v-list-item-action>
                    <v-icon color="grey darken-1">dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-title class="list-title-style text--darken-1">Dashboard</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
                flat="true"
                dense
                class="mt-4"
                link
                :to="{name:'customerMessages' ,  params: { user: $store.state.users , locationss:locationss }} "
                :user="$store.state.users"
            >
                <v-list-item-action>
                    <v-icon color="grey darken-1">mail_outline</v-icon>
                </v-list-item-action>
                <v-list-item-title class="list-title-style text--darken-1">Messages</v-list-item-title>
            </v-list-item>

            <v-list dense>


                <v-list v-if="user.access_level <6 || user.access_level == 7 || user.access_level == 8">
                    <v-divider></v-divider>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>bar_chart</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                                v-if="user.access_level <6 || user.access_level == 7 || user.access_level == 8"
                                class=" text--darken-2 list-title-style">Property Analytics
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'map'}" v-if="user.access_level < 2 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.mapDis</v-icon>

                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Distribution Overview
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'create'}"
                            v-if="user.access_level < 5 || user.access_level == 5 || user.access_level == 7"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Building Registry</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'report'}"
                            v-if="user.access_level == 1 || user.access_level == 5 || user.access_level == 4 || user.access_level == 8 || user.access_level == 7"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">assessment</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1"> Visual Reports
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'newprofiles'}"
                            v-if="user.access_level == 1  || user.access_level == 4 || user.access_level == 8 || user.access_level == 7"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.confirmed</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Approved Listings
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'profiles'}" v-if="user.access_level < 4 || user.access_level == 8 "
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-0">$vuetify.icons.list</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="grey--text text--darken-1">Residential Listings
                                </v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list v-if="user.access_level <3 || user.access_level == 8">
                    <v-divider></v-divider>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>home</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-if="user.access_level <3 || user.access_level == 8"
                                               class="list-title-style text--darken-2">Homeowners
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'owner'}"
                            v-if="user.access_level < 3 || user.access_level == 4 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">view_list</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Homeowner Directory</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'ownercreate'}" v-if="user.access_level < 3 || user.access_level == 4"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">group_add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Register Homeowner
                            </v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>

                <v-list
                    v-if="user.access_level == 1 || user.access_level == 8|| user.access_level == 7 || user.access_level == 2">
                    <v-divider></v-divider>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>group</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                                v-if="user.access_level == 1 || user.access_level == 8|| user.access_level == 7 || user.access_level == 2"
                                class="list-title-style text--darken-2">HR Insights
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'userindex'}" v-if="user.access_level == 1 || user.access_level == 8 "
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">account_box</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Employee Records</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'technicionindex'}"
                            v-if="user.access_level == 1 || user.access_level == 7 || user.access_level == 2 "
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-0">$vuetify.icons.techKPI</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Technician Performance
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'carindex'}" v-if="user.access_level == 1 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-0">$vuetify.icons.carInfo</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Vehicle Details</v-list-item-title>
                        </v-list-item>


                        <!--                // User Tracking-->
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'all-user-locations'}"
                            v-if=" user.access_level == 1 || user.access_level == 8|| user.access_level == 7 "
                        >
                            <!--                                user.access_level == 1 || user.access_level == 8|| user.access_level == 7-->
                            <v-list-item-action>
                                <v-icon color="grey darken-1">track_changes</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Technician Locations
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list v-if="user.access_level <3 || user.access_level == 8">
                    <v-divider></v-divider>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>memory</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-if="user.access_level <3 || user.access_level == 8"
                                               class="list-title-style  text--darken-2">Hardware
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'hardwarelist'}"
                            v-if="user.access_level < 3 || user.access_level == 4 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">list</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class=" grey--text text--darken-1">Hardware Listings</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'hardwarecreate'}" v-if="user.access_level < 3 || user.access_level == 4"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class=" grey--text text--darken-1">Add Hardware
                            </v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>account_circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="list-title-style text--darken-2">User Settings</v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'userprofile'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">settings</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class=" grey--text text--darken-1">Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4 list-item-with-padding"
                            link
                            :to="{name:'logout'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">exit_to_app</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class=" grey--text text--darken-1">Log Out</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>


                <!--                <v-list-item-->
                <!--                    class="mt-4"-->
                <!--                    link-->

                <!--                >-->
                <!--                    <v-list-item-action>-->
                <!--                        &lt;!&ndash;                            <v-icon color="grey darken-1">exit_to_app</v-icon>&ndash;&gt;-->
                <!--                    </v-list-item-action>-->
                <!--                    &lt;!&ndash;                        <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">خروج</v-list-item-title>&ndash;&gt;-->
                <!--                </v-list-item>-->
            </v-list>
        </v-navigation-drawer>


        <v-app-bar
            v-if="isHeaderLarge"
            dark
            app
            clipped-right
            color="transparent"
            style="background-image: linear-gradient(135deg, #9a7485 0%, #9a7485 100%);"
        >
            <!--            <v-app-bar-nav-icon @click.stop="drawer = !drawer" title="Toggle Menu"></v-app-bar-nav-icon>-->

            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#9c7a95"
                @click="$router.go(-1)"
                title="Go Back"
            >
                <v-icon dark>
                    arrow_back
                </v-icon>
            </v-btn>

            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#9c7a95"
                @click="$router.go(1)"
                title="Go Forward"
            >
                <v-icon dark>
                    arrow_forward
                </v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-toolbar-title class="align-center">
                {{businessData.name}}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-list-item-avatar class="mx-4" tile style="width: 80px; height: 80px;">
                <v-img
                    contain
                    :src="'/storage/images/businesses/'+ businessData.logo"
                    style="width: 100%; height: 100%;"
                >
                </v-img>
            </v-list-item-avatar>
        </v-app-bar>

        <v-navigation-drawer
            v-if="isHeaderSmall || isHeaderMedium"
            temporary
            v-model="drawer"
            app
            left
        >
            <v-list-item class="px-2">

                <v-list-item-avatar>
                    <v-img
                        contain
                        width="90%"
                        :src="isLink(user.pic) ? user.pic : '/storage/images/user/' + user.pic"
                    ></v-img>
                </v-list-item-avatar>

                <v-list-item-title :style="fontSizeStyle">{{user.first_name + ' ' + user.surname}}
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>


            <v-list-item
                flat="true"
                dense
                :append="true"
                class="mt-4"
                link
                :to="{name:'performanceHighlights' ,  params: { user: user }} "
                v-if="$store.state.users.access_level < 9 || $store.state.users.access_level == 5 "
                :user="$store.state.users"
            >
                <v-list-item-action>
                    <v-icon color="grey darken-1">dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">Dashboard</v-list-item-title>
            </v-list-item>

            <v-list-item
                flat="true"
                dense
                class="mt-4"
                link
                :to="{name:'customerMessages' ,  params: { user: $store.state.users , locationss:locationss }} "
                :user="$store.state.users"
            >
                <v-list-item-action>
                    <v-icon color="grey darken-1">mail_outline</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">Messages</v-list-item-title>
            </v-list-item>


            <v-list dense>


                <v-list v-if="user.access_level <6 || user.access_level == 7 || user.access_level == 8">
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title
                                v-if="user.access_level <6 || user.access_level == 7 || user.access_level == 8"
                                class="grey--text text--darken-2">Property Analytics
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'map'}" v-if="user.access_level < 2 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.mapDis</v-icon>

                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Distribution Overview
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'create'}"
                            v-if="user.access_level < 5 || user.access_level == 5 || user.access_level == 7"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Building Registry</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'report'}"
                            v-if="user.access_level == 1 || user.access_level == 5 || user.access_level == 4 || user.access_level == 8 || user.access_level == 7"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">assessment</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1"> Visual Reports
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'newprofiles'}"
                            v-if="user.access_level == 1  || user.access_level == 4 || user.access_level == 8 || user.access_level == 7"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">$vuetify.icons.confirmed</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Approved Listings
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'profiles'}" v-if="user.access_level < 4 || user.access_level == 8 "
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-0">$vuetify.icons.list</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Residential Listings
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>


                <v-list v-if="user.access_level <3 || user.access_level == 8">
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title v-if="user.access_level <3 || user.access_level == 8"
                                               class="grey--text text--darken-2">Homeowners
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'owner'}"
                            v-if="user.access_level < 3 || user.access_level == 4 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">view_list</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Homeowner Directory</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'ownercreate'}" v-if="user.access_level < 3 || user.access_level == 4"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">group_add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Register Homeowner
                            </v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>


                <v-list
                    v-if="user.access_level == 1 || user.access_level == 8|| user.access_level == 7 || user.access_level == 2">
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title
                                v-if="user.access_level == 1 || user.access_level == 8|| user.access_level == 7 || user.access_level == 2"
                                class="grey--text text--darken-2">HR Insights
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'userindex'}" v-if="user.access_level == 1 || user.access_level == 8 "
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">account_box</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Employee Records</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'technicionindex'}"
                            v-if="user.access_level == 1 || user.access_level == 7 || user.access_level == 2 "
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-0">$vuetify.icons.techKPI</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Technician Performance
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'carindex'}" v-if="user.access_level == 1 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-0">$vuetify.icons.carInfo</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Vehicle Details</v-list-item-title>
                        </v-list-item>


                        <!--                // User Tracking-->
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'all-user-locations'}"
                            v-if=" user.access_level == 1 || user.access_level == 8|| user.access_level == 7 "
                        >
                            <!--                                user.access_level == 1 || user.access_level == 8|| user.access_level == 7-->
                            <v-list-item-action>
                                <v-icon color="grey darken-1">track_changes</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Technician Locations
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list v-if="user.access_level <3 || user.access_level == 8">
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title v-if="user.access_level <3 || user.access_level == 8"
                                               class="grey--text text--darken-2">Hardware
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'hardwarelist'}"
                            v-if="user.access_level < 3 || user.access_level == 4 || user.access_level == 8"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">list</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Hardware Listings</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'hardwarecreate'}" v-if="user.access_level < 3 || user.access_level == 4"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">add</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Add Hardware
                            </v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>

                <v-list>
                    <v-list-group
                    >
                        <template v-slot:activator>
                            <v-list-item-title class="grey--text text--darken-2">User Settings</v-list-item-title>
                        </template>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'userprofile'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">settings</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="mt-4"
                            link
                            :to="{name:'logout'}"
                        >
                            <v-list-item-action>
                                <v-icon color="grey darken-1">exit_to_app</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="grey--text text--darken-1">Log Out</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>


                <v-list-item
                    class="mt-4"
                    link

                >
                    <v-list-item-action>
                        <!--                            <v-icon color="grey darken-1">exit_to_app</v-icon>-->
                    </v-list-item-action>
                    <!--                        <v-list-item-title class="grey--text text--darken-1">خروج</v-list-item-title>-->
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            v-if="isHeaderSmall || isHeaderMedium"
            dark
            app
            clipped-right
            color="transparent"
            style="background-image: linear-gradient(135deg, #9a7485 0%, #9a7485 100%);"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" title="Toggle Menu"></v-app-bar-nav-icon>

            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#9c7a95"
                @click="$router.go(-1)"
                title="Go Back"
            >
                <v-icon dark>
                    arrow_back
                </v-icon>
            </v-btn>

            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#9c7a95"
                @click="$router.go(1)"
                title="Go Forward"
            >
                <v-icon dark>
                    arrow_forward
                </v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-toolbar-title class="align-center">
                {{businessData.name}}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-list-item-avatar class="mx-4" tile style="width: 80px; height: 80px;">
                <v-img
                    contain
                    :src="'/storage/images/businesses/'+ businessData.logo"
                    style="width: 100%; height: 100%;"
                >
                </v-img>
            </v-list-item-avatar>
        </v-app-bar>

        <v-main class="mb-15">
            <router-view></router-view>
        </v-main>
        <v-bottom-navigation
            fixed
            :value="value"
            color="teal"
            grow
        >
            <v-btn :to="{name:'customerMessages'}">
                <span> Messages </span>
                <v-icon>mail_outline</v-icon>
            </v-btn>

            <v-btn :to="{name:'dashboard'}">
                <span> Dashboard </span>
                <v-icon>dashboard</v-icon>
            </v-btn>


            <v-btn :to="{name:'logout'}">
                <span> Exit </span>
                <v-icon>exit_to_app</v-icon>
            </v-btn>
        </v-bottom-navigation>


    </v-app>
</template>


<script>
    import LoginPanel from '../EnterprisePanel/Auth/Login';
    import RegisterPanel from '../EnterprisePanel/Auth/Register';
    import NotFound from "../Business/NotFound";

    export default {
        name: "App",
        props: [
            // 'user'
            // 'locationLatLong',
            'businessData'
        ],

        data: () => ({
            mini: false,
            userCookie: '',
            user: [],
            loggedIn: false,
            showLogin: true,
            drawer: true,
            value: 1,
            snackkbar: true,
            InitTime: 0,
            admins: [
                ['Management', 'mdi-account-multiple-outline'],
                ['Settings', 'mdi-cog-outline'],
            ],
            cruds: [
                ['Create', 'mdi-plus-outline'],
                ['Read', 'mdi-file-outline'],
                ['Update', 'mdi-update'],
                ['Delete', 'mdi-delete'],
            ],
            items: [
                {icon: 'gps_fixed', text: 'مکان های خانه ها ', to: {name: 'map'}},
                {icon: 'featured_play_list', text: 'اطلاعات کلیه خانه ها', to: {name: 'profiles'}},
                //{ icon: 'contacts', text: 'Dog Owners' , to:{name:'profiles'}},
                {icon: 'add', text: 'اضافه کردن خانه جدید', to: {name: 'create'}},


            ],
            locationLatLong: null,
            locationss: null

        }),


        components: {
            NotFound,
            LoginPanel,
            RegisterPanel,
        },
        mounted() {
            console.log('mounted');
            console.log(this.businessData);
            this.$store.commit("updateBusiness", this.businessData);
            if (localStorage.getItem('isAuthenticated')) {
                this.$store.commit('setAuthenticated', true);

                this.userCookie = this.getCookie('userInfo');
                this.user = this.$store.state.users;
                if (this.getCookie('userInfo')) {
                    this.user = this.getCookie('userInfo');
                    this.$store.commit('updateUser', this.user);
                }
            }


            this.InitTime = 0;

        },
        created() {
            console.log('created');
            console.log(this.businessData);

            //  this.$vuetify.theme.dark = true
            Echo.channel('location')
                .listen('SendPosition', (e) => {
                    //
                    console.log(e);
                });
            window.axios.interceptors.request.use(
                (config) => {
                    if (config.method === 'get') {
                        //config.url = config.url;
                    } else {
                        config.data = {
                            ...config.data,
                        };
                    }
                    return config;
                }
            )
        },
        watch: {},
        methods: {
            toggleMini() {
                this.mini = !this.mini;
            },

            isLink(str) {
                return str.startsWith('http://') || str.startsWith('https://');
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            messageFromCookie() {
                // Get the message from the cookie and decrypt it
                const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                const decryptedMessage = cookieValue ? atob(cookieValue) : "";

                return decryptedMessage;
            },
            handleLogin(userInfo) {
                this.user = userInfo;
            },

            PreviousPage: function () {

            },
            async locateAsync() {
                this.locationLatLong = await this.watchLocation();
                this.locationss = {
                    Lat: this.locationLatLong.coords.latitude,
                    Long: this.locationLatLong.coords.longitude
                };
                this.$store.commit("updateLocation", this.locationss);

                let c = new Date();
                let d = c.getTime();
                console.log(this.locationLatLong.coords.longitude);
                console.log(this.locationLatLong.coords.latitude);
                console.log(this.InitTime);
                console.log(d);
                if (Number(d) - Number(this.InitTime) > 60000)//Number(d) - Number(this.InitTime)
                {
                    axios.post('../saveUserLocation', {
                        ID: this.user.id,
                        //ID:Number(d)-Number(this.InitTime),
                        Long: this.locationLatLong.coords.longitude,
                        Lat: this.locationLatLong.coords.latitude,
                        // email: 'freddyb34@gmail.com'
                    })
                        .then(response => {
                            // this.responses = response.data[0].data.id;
                            // //this.$router.push(toString(response.data[0].data.id));
                            // this.$router.push({name:'profiles'});
                            this.InitTime = d;
                            console.log('Send');
                        })
                        .catch(errors => {
                            console.log('error');
                        });
                }
            },
            async watchLocation() {
                return new Promise((resolve, reject) => {
                    if (!("geolocation" in navigator)) {
                        reject(new Error('Geolocation is not available.'));
                    }
                    navigator.geolocation.watchPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 20000,
                        maximumAge: 300000
                    });


                });
            },
            Distance: function (lat1, lon1, lat2, lon2, unit) {
                if ((lat1 == lat2) && (lon1 == lon2)) {
                    return 0;
                } else {
                    var radlat1 = Math.PI * lat1 / 180;
                    var radlat2 = Math.PI * lat2 / 180;
                    var theta = lon1 - lon2;
                    var radtheta = Math.PI * theta / 180;
                    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                    if (dist > 1) {
                        dist = 1;
                    }
                    dist = Math.acos(dist);
                    dist = dist * 180 / Math.PI;
                    dist = dist * 60 * 1.1515;
                    if (unit == "K") {
                        dist = dist * 1.609344
                    }
                    if (unit == "N") {
                        dist = dist * 0.8684
                    }
                    return dist;
                }
            }
        },
        computed: {

            fontSizeStyle() {
                const firstNameLength = this.user && this.user.first_name ? this.user.first_name.length : 0;
                const surnameLength = this.user && this.user.surname ? this.user.surname.length : 0;
                const length = firstNameLength + surnameLength;
                let fontSize;

                if (length <= 10) {
                    fontSize = '16px';  // Use a larger font size for shorter names
                } else if (length <= 20) {
                    fontSize = '14px';  // Use a medium font size for medium-length names
                } else {
                    fontSize = '12px';  // Use a smaller font size for longer names
                }

                return {fontSize};
            },
            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true;
                    case 'sm':
                        return false;
                    case 'md':
                        return false;
                    case 'lg':
                        return false;
                    case 'xl':
                        return false;
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
        }
    }
</script>

<style>

    .v-text-field input {
        font-size: 1.2em;
        font-family: IRANSans !important;

    }

    .v-application {
        font-family: IRANSans !important;
    }

    .application {
        font-family: IRANSans !important;
    }

    .headline,
    .title,
    .subheading {
        font-family: IRANSans !important;
    }

    body {
        font-family: IRANSans !important;
    }

    .list-item-with-padding {
        padding-left: 35px !important; /* Add left padding for list items */
    }

    .list-title-style {
        font-size: 1.5rem; /* Increase font size */
        font-weight: bold; /* Make it bold */
        padding-left: 0; /* Remove left padding */
    }

</style>
