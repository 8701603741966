<template>

    <svg
        width="52.376999mm"
        height="35.078999mm"
        viewBox="0 0 52.376999 35.078999"
        version="1.1"
        id="svg5"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        sodipodi:docname="Unread.svg"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <sodipodi:namedview
            id="namedview7"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            inkscape:document-units="mm"
            showgrid="false"
            inkscape:zoom="0.74653479"
            inkscape:cx="397.16836"
            inkscape:cy="347.60604"
            inkscape:window-width="1920"
            inkscape:window-height="1001"
            inkscape:window-x="-9"
            inkscape:window-y="-9"
            inkscape:window-maximized="1"
            inkscape:current-layer="layer1" />
        <defs
            id="defs2" />
        <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            id="layer1">
            <path
                style="fill:#ff0000;stroke-width:1"
                d="m 1.3157837,34.642912 c -1.35744995,-0.90385 -1.31564295257,-0.48729 -1.31564295257,-13.10873 0,-11.32815 0.0216300026,-11.8556798 0.48598500257,-11.8556798 0.382919,0 13.44681725,8.6398898 14.44266525,9.5517498 0.256719,0.23506 -0.457421,1.11347 -5.7400493,7.0604 -1.717434,1.9334 -3.048257,3.63558 -2.957385,3.78262 0.163908,0.2652 0.709143,-0.15633 5.6750353,-4.38748 4.429188,-3.77386 5.476994,-4.63021 5.665414,-4.63021 0.101422,0 0.974037,0.47625 1.939144,1.05833 1.5369,0.92695 1.953898,1.05802 3.358948,1.05582 1.414292,-0.002 1.801222,-0.1275 3.268482,-1.05833 0.91536,-0.5807 1.75507,-1.05582 1.86602,-1.05582 0.11096,0 2.51719,1.97091 5.34718,4.3798 2.83,2.40888 5.36444,4.50235 5.6321,4.65216 1.12802,0.63133 0.11737,-0.72783 -4.05853,-5.45803 -2.4011,-2.71981 -4.36563,-5.04685 -4.36563,-5.17119 0,-0.21124 8.4065,-6.07566 8.70931,-6.07566 0.0763,0 0.64826,0.35816 1.27098,0.79591 0.65276,0.45886 1.94375,0.99107 3.04858,1.25677 l 1.91636,0.46086 0.002,8.69157 0.002,8.69156 -0.89959,0.89958 -0.89958,0.89959 H 22.839608 c -18.9337773,0 -20.9303223,-0.0404 -21.5238243,-0.43559 z M 15.213684,16.410142 C 11.866704,14.568112 7.4613917,12.167182 5.4240997,11.074752 1.4377587,8.9371922 0.35846875,8.1240822 0.61616275,7.4525422 0.76111475,7.0748022 2.5799437,7.0326622 18.740401,7.0326622 H 36.70353 l 0.15941,1.34597 c 0.0877,0.74029 0.45114,1.8679398 0.80771,2.5059098 l 0.6483,1.15994 -3.08596,1.69722 c -1.69729,0.93347 -4.75285,2.6233 -6.79014,3.75519 -3.37109,1.87291 -3.85881,2.06716 -5.423962,2.16018 l -1.71979,0.10221 z m 27.997146,-2.84917 c -2.92347,-1.07785 -4.58552,-3.49205 -4.5757,-6.6463998 0.009,-2.92583 1.22231,-4.94048 3.79507,-6.30214005 1.53255,-0.81111 4.61635,-0.81765 6.12099,-0.013 C 53.83054,3.4228122 53.598,10.900562 48.15432,13.361852 c -1.3391,0.60546 -3.59488,0.6963 -4.94349,0.19909 z"
                id="path836" />
        </g>
    </svg>

</template>

<script>
    export default {
        name: "Appliance"
    }
</script>

<style scoped>

</style>
