<template>
    <v-app>
        <v-container>
            <v-row>
                <v-col cols="12" class="text-center">
                    <v-icon size="64" color="primary">help_outline</v-icon>
                    <h1 class="display-1">Support and FAQs</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                            <v-expansion-panel-header>{{ faq.question }}</v-expansion-panel-header>
                            <v-expansion-panel-content>{{ faq.answer }}</v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="headline">General Support Information</v-card-title>
                        <v-card-text>
                            <p>For any issues or questions related to your wallet, please feel free to reach out to our
                                support team. We are here to help you with:</p>
                            <ul>
                                <li>Account setup and verification</li>
                                <li>Wallet management</li>
                                <li>Transaction disputes</li>
                                <li>Security concerns</li>
                                <li>General inquiries</li>
                            </ul>
                            <p>You can contact us via email at <a href="mailto:support@hubbcast.com">support@hubbcast.com</a>
                                or call us at +370 66978994. Our support team is available 24/7 to assist you.</p>
                            <p>For more information, visit our <a href="/help-center">Help Center</a>.</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>
    export default {
        name: "SupportFAQ",
        data() {
            return {
                faqs: [
                    {
                        question: "How do I create a wallet?",
                        answer: "To create a wallet, navigate to the 'My Wallets' section and click on 'Add New Wallet'. Follow the on-screen instructions to set up your wallet.",
                        active: false
                    },
                    {
                        question: "How can I view my transaction history?",
                        answer: "You can view your transaction history by going to the 'Transaction History' section. Here, you'll find a detailed list of all your past transactions.",
                        active: false
                    },
                    {
                        question: "How do I transfer funds to another account?",
                        answer: "To transfer funds, go to the 'Transfer Funds' section. Enter the recipient's account details, the amount you want to transfer, and confirm the transaction.",
                        active: false
                    },
                    {
                        question: "What should I do if I encounter a transaction issue?",
                        answer: "If you encounter any issues with a transaction, please contact our support team immediately. Provide as much detail as possible to help us resolve the issue quickly.",
                        active: false
                    },
                    {
                        question: "How do I reset my password?",
                        answer: "If you need to reset your password, click on the 'Forgot Password' link on the login page and follow the instructions to reset your password.",
                        active: false
                    },
                    {
                        question: "Can I link multiple bank accounts to my wallet?",
                        answer: "Yes, you can link multiple bank accounts to your wallet. Go to the 'Account Settings' section and add your bank accounts under 'Linked Accounts'.",
                        active: false
                    },
                    {
                        question: "How do I update my personal information?",
                        answer: "To update your personal information, navigate to the 'Profile' section and edit your details. Make sure to save the changes before exiting.",
                        active: false
                    },
                    {
                        question: "What are the fees for transferring funds?",
                        answer: "The fees for transferring funds vary depending on the amount and destination. Please refer to the 'Fees and Charges' section under 'Help' for detailed information.",
                        active: false
                    },
                    {
                        question: "Is my wallet secure?",
                        answer: "Yes, we use industry-standard encryption and security measures to protect your wallet and personal information. Ensure you do not share your login credentials with anyone.",
                        active: false
                    },
                    {
                        question: "How do I contact support?",
                        answer: "You can contact our support team via email at support@example.com or call us at 1-800-123-4567 during our working hours, Monday to Friday, 9 AM to 5 PM.",
                        active: false
                    }
                ]
            };
        }
    }
</script>

<style scoped>
    .text-center {
        text-align: center;
    }

    .display-1 {
        font-size: 2.5rem;
        font-weight: bold;
    }

    .mt-5 {
        margin-top: 3rem;
    }
</style>
