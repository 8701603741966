<template>
    <div class="pt-16 bg-gray-200">
        <v-card max-width="80%" class="mx-auto mb-0 rounded-form" outlined>
            <v-flex row wrap class="box mx-auto" xs8>
                <v-row>
                    <v-col
                        cols="12"
                        class="justify-center"
                    >
                        <div id="filepond">
                            <file-pond

                                name="appliance"
                                ref="pond"
                                class-name="my-pond"
                                label-idle="Please take the picture ..."
                                allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png"
                                server="../appliance/upload"
                                v-on:processfile="handleProcessFile">
                            </file-pond>
                        </div>
                    </v-col>
                </v-row>

            </v-flex>


            <v-card-title class="layout justify-center">
                Register home appliance information
            </v-card-title>
            <v-card-subtitle class="layout justify-center">
                Please provide all the following information in detail.
            </v-card-subtitle>
            <v-img :src="require('../../../assets/iamges/AppliancePrice.png')" contain max-height="400pt">
            </v-img>
            <v-card-text>
                <v-flex row wrap class="box mx-auto" xs9>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-autocomplete :items="buildings"
                                            item-text="address"
                                            item-value="id"
                                            @change="updateBuilding"
                                            label="Building name" :error-messages="errors" placeholder="Building"
                                            v-model="selected_building "></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-autocomplete :items="unitsSelected"
                                            item-value="id"
                                            item-text="unit_Nom"
                                            :disabled="unitBox"
                                            label="Unit Name" :error-messages="errors" placeholder="Unit"
                                            v-model="form.unit_id"></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-autocomplete
                                :items="['Whirpool', 'LG', ' Samsung', 'Bosch', 'Gaggenau Hausgeräte', 'General Electric', 'Miele', 'Fisher & Paykel', 'Thermador', 'KitchenAid']"
                                label="Brand Name"
                                :error-messages="errors" placeholder="Brand"
                                v-model="form.brand "></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-autocomplete :items="['Air conditioner','Blender', 'Bread machine', 'Clothes dryer', 'Clothes iron',
                            'Coffeemaker', 'Dishwasher', 'Electric drill', 'Electric water boiler','Food processor', 'Kitchen hood',
                            'Fan', 'Freezer', 'Hair dryer', 'Mixer', 'Convection oven', 'Microwave oven', 'Refrigerator', 'Sewing machine',
                            'Slow cooker', 'Television set', 'Toaster', 'Vacuum cleaner', 'Water dispenser','Washing machine']"
                                            label="Name" :error-messages="errors" placeholder="Name"
                                            v-model="form.name "></v-autocomplete>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-text-field label="Model" :error-messages="errors" placeholder="Model"
                                          v-model="form.model "></v-text-field>
                        </v-col>



                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-text-field label="Production Year" :error-messages="errors"
                                          placeholder=" Write the year of manufacture of the device"
                                          v-model="form.date_of_build "></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-text-field label="Estimated price" :error-messages="errors"
                                          placeholder=" Write down the estimated price."
                                          v-model="form.price "></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-text-field label="barcode" :error-messages="errors"
                                          placeholder="Read the barcode from the sheet"
                                          v-model="form.chip_id "></v-text-field>
                            <v-btn @click="booll=!booll">Barcode scanning with the camera</v-btn>
                            <v-form class="mb-40" height="200" v-if="true==booll">
                                <v-quagga :onDetected="logIt" :readerSize="readerSize"
                                          :readerTypes="['ean_reader', 'code_128_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader',
                                                  'codabar_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader','code_93_reader' ]"></v-quagga>

                            </v-form>
                        </v-col>
                    </v-row>

                </v-flex>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="m-1" :to="{name:'profiles'}">Cancel</v-btn>
                    <v-btn depressed color="success" class="m-1" @click="dialogSupporter='true'" :loading="busy">
                        Register
                    </v-btn>
                </v-card-actions>
                <template>
                    <v-dialog
                        v-model="dialogSupporter"
                        width="500"
                    >

                        <v-card>
                            <v-card-title class="headline grey lighten-2">
                                Self-declaration form
                            </v-card-title>

                            <v-card-text>
                                I confess that all the information entered is correct and the Hubbcast Company
                                will not be held responsible for any possible violations.

                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="green"
                                    text
                                    @click="addNewHomeAppliance"
                                >
                                    Yes, I agree
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>

            </v-card-text>

        </v-card>

    </div>
</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "OwnerCreate",
        components: {
            FilePond
        },

        data: function () {
            return {
                selected_unit: '',
                unitBox: true,
                buildings: [],
                units: [],
                unitsSelected: [],
                selected_building: '',
                readerSize: {
                    width: 640,
                    height: 480
                },
                booll: false,
                //myFiles: ['index.html'],
                dialogSupporter: false,
                snackkbar: false,
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'model': '',
                        'brand': '',
                        'chip_id': '',
                        'price': '',
                        'date_of_build': '',
                        'unit_id': '',
                        'name': '',
                        'fig': ''
                    },
                // 'name', 'family', 'sex', 'number', 'fig', 'age', 'degree',  'number_of_nezam', 'address', 'comments', 'verified'

                formVet:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '',
                        'age': '',
                        'sex': '',
                        'family': '',
                        'degree': '',
                        'number_of_nezam': '',
                        'address': '',
                        'comments': '',
                        'fig': '',
                        'number': '',
                        'verified': '0'
                    },
                errors: null,
                responses: null,
                busy: false,
                service: '',
            }
        },
        mounted() {

            axios.get('../citizenUnits/' + this.$store.state.customers.id)
                .then(response => {
                    this.units = response.data;
                    for (let i = 0; i < this.units.length; i++) {
                        this.buildings[i] = this.units[i]['address'];
                    }
                    this.buildings = [...new Set(this.buildings)];
                    this.snackkbar = true;
                })
                .catch(errors => {
                    console.log(this.form);
                    this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });

            // this.video = this.$refs.video;
            // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            //     navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //         video.srcObject = stream;
            //         video.play();
            //         video.onplay = function () {
            //         };
            //         this.video.play();
            //     });
            // }
        },

        methods: {
            updateBuilding: function () {
                this.unitBox = false;
                let j = 0;
                this.unitsSelected = [];
                for (let i = 0; i < this.units.length; i++) {
                    if (this.units[i]['address'] == this.selected_building) {
                        this.unitsSelected.push(this.units[i]);
                    }
                }

                // axios.get('../buildings/' + this.selected_building)
                //     .then( response => {
                //         this.units = response.data;
                //         //this.$router.push(toString(response.data[0].data.id));
                //         this.snackkbar = true;
                //     })
                //     .catch(errors =>{
                //         console.log(this.form);
                //         this.errors = errors.response.data.errors;
                //         console.log(this.errors);
                //     }).finally(()=>{
                //     this.busy=false;
                // });

            },
            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.pic = file.serverId;
                console.log(this.form.pic);
            },
            logIt(data) {
                if ((data.codeResult.code.toString().length > 5)) {
                    this.form.chip_id = data.codeResult.code;
                }
                // alert(data.codeResult.code.toString().length);

                //alert(this.video.codeResult.code)
                // console.log('detected', data)
            },
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            addNewHomeAppliance() {
                // this.form.unit_id = this.selected_unit;
                this.dialogSupporter = false;
                this.busy = true;
                axios.post('../appliance', this.form)
                    .then(response => {
                        this.responses = response.data;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.snackkbar = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>

