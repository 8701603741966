<template>


    <v-container fluid fill-height class="bg">
        <v-layout align-center justify-center>
            <!--                    Large Page-->
            <v-card
                shaped
                :loading="loading"
                width="70%"
                max-width="800pt"
                color="white"
                class="elevation-20"

                rounded="20"
                v-if="isHeaderLarge"
            >
                <v-row>
                    <v-col cols="5">
                        <v-card

                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to Hubbcast panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Build your smart homeowner solution in just 3 mins!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click=""
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>

                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        outlined
                                        persistent-hint="Email*"
                                        v-model="form.email"
                                        dense
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-fade-transition leave-absolute>
                                                <v-progress-circular
                                                    v-if="loading"
                                                    size="24"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                                <img
                                                    v-else
                                                    width="24"
                                                    height="24"
                                                    src="../../../assets/icons/email.svg"
                                                >
                                            </v-fade-transition>
                                        </template>


                                    </v-text-field>
                                    <v-text-field
                                        type="password"
                                        rounded
                                        outlined
                                        dense
                                        v-model="form.password"
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-fade-transition leave-absolute>
                                                <v-progress-circular
                                                    v-if="loading"
                                                    size="24"
                                                    color="info"
                                                    indeterminate
                                                ></v-progress-circular>
                                                <img
                                                    v-else
                                                    width="24"
                                                    height="24"
                                                    src="../../../assets/icons/Password.svg"


                                                >
                                            </v-fade-transition>
                                        </template>
                                    </v-text-field>

                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        Login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="my-auto">
                        <img width="100%" src="../../../assets/icons/Business/Login.svg">
                    </v-col>
                </v-row>

            </v-card>

            <!--                    Medium size page-->
            <v-card
                shaped
                :loading="loading"
                width="80%"
                max-width="550pt"
                color="white"
                class="elevation-20"
                rounded="20"
                v-if="isHeaderMedium"
            >
                <v-row>
                    <v-col cols="9" class="mx-auto">
                        <img width="80%" src="../../../assets/icons/Business/Login.svg">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-card
                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to business panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Build your claim improvement service in just 3 mins!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click="loader = 'loading'"
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>
                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        label="Email*"
                                        v-model="form.email"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        rounded
                                        type="password"
                                        label="Password*"
                                        v-model="form.password"
                                        outlined
                                    ></v-text-field>
                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>

            </v-card>

            <!--                    Small size-->
            <v-card

                shaped
                :loading="loading"
                width="80%"
                min-width="250pt"
                color="white"
                class="elevation-20 my-10"
                rounded="20"
                v-if="isHeaderSmall"
            >
                <v-row>
                    <v-col cols="10" class="ma-auto">
                        <img src="../../../assets/icons/Business/Login.svg">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-card
                            class="elevation-0"
                            width="100%">
                            <v-card-text>
                                <v-card-title class="text-black-50">
                                    Login to business panel
                                </v-card-title>
                                <v-card-subtitle>
                                    Build your claim improvement service in just 3 mins!
                                </v-card-subtitle>

                                <v-btn
                                    rounded
                                    :loading="loading"
                                    :disabled="loading"
                                    width="100%"
                                    depressed
                                    class="ma-2 my-4"
                                    @click="loader = 'loading'"
                                    block
                                    outlined
                                    :href="googleLoginLink"
                                >

                                    Login with Google

                                    <v-icon color="grey darken-1" right>$vuetify.icons.gmail</v-icon>

                                </v-btn>
                                <v-form
                                >
                                    <v-text-field
                                        rounded
                                        label="Email*"
                                        v-model="form.email"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        rounded
                                        type="password"
                                        label="Password*"
                                        v-model="form.password"
                                        outlined
                                    ></v-text-field>
                                    <v-checkbox
                                        label="Keep me logged in"
                                    ></v-checkbox>
                                    <v-btn
                                        rounded
                                        :loading="loading"
                                        :disabled="loading"
                                        color="info"
                                        width="100%"
                                        depressed
                                        class="ma-2 white--text"
                                        @click="postLoginData"
                                        block
                                        elevation="10"
                                    >

                                        login
                                    </v-btn>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="my-1 ">
                                Don't have an account?
                                <v-btn
                                    text
                                    color="primary"
                                    @click="navigateToRegister"
                                >
                                    Register
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>

            </v-card>


        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'LoginPanels',
        props: {
            source: String,
        },
        data() {
            return {

                cookieData: '',
                googleLoginLink: 'google',
                loader: null,
                loading: false,
                user: '',
                userTest: '',
                form:
                    {
                        'email': '', 'password': '',
                    },
            }
        },
        mounted() {
            this.checkForGoogleLogin();
        },
        methods: {
            async checkForGoogleLogin() {
                const urlParams = new URLSearchParams(window.location.search);

                if (urlParams.has("id")) {
                    const userData = {
                        id: urlParams.get("id"),
                        first_name: urlParams.get("first_name"),
                        surname: urlParams.get("surname"),
                        email: urlParams.get("email"),
                        pic: urlParams.get("pic"),
                        access_level: urlParams.get("access_level") || 'user', // Default access level if it's not provided
                    };

                    this.$store.commit("setAuthenticated", true);
                    localStorage.setItem("isAuthenticated", true);

                    this.user = userData;

                    this.$store.commit("updateUser", this.user);
                    this.deleteCookie("userInfo");
                    this.setCookie("userInfo", this.user, 1);
                    this.$emit("userInfo", this.user);
                    await this.$router.push({ name: "dashboard" });
                }
            },

            navigateToRegister() {
                this.$router.push({name: 'register'});
            },
            setCookie(name, value, hours, secure = true, sameSite = 'Strict') {
                let expires = '';
                if (hours) {
                    const date = new Date();
                    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
                    expires = `; expires=${date.toUTCString()}`;
                }

                const secureFlag = secure ? '; Secure' : '';
                const sameSiteFlag = sameSite ? `; SameSite=${sameSite}` : '';

                const encodedName = encodeURIComponent(name);
                const jsonString = JSON.stringify(value);
                const encodedValue = encodeURIComponent(jsonString);

                document.cookie = `${encodedName}=${encodedValue}${expires}${secureFlag}${sameSiteFlag}`;
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            deleteCookie(name) {
                this.setCookie(name, '', -1);
            },
            postLoginData() {
                if (true) {
                    axios.post('../login', this.form)
                        .then(response => {
                            console.log('userInfoInDataBase');
                            console.log(response.data.user);
                            // handle the response from the server
                            this.$store.commit('setAuthenticated', true);
                            localStorage.setItem('isAuthenticated', true);
                            const userData = response.data.user;
                            console(userData);
                            this.user = {
                                access_level: userData.access_level,
                                name: userData.name,
                                email: userData.email,
                                id: userData.id,
                                pic: userData.pic
                            };
                            this.$store.commit('updateUser', this.user);
                            this.deleteCookie('userInfo');
                            this.setCookie('userInfo', this.user, 1);
                            const arrayFromCookie = this.getCookie('userInfo');
                            this.userTest = this.getCookie('userInfo');
                            console.log( 'user info in cookie' );
                            console.log( arrayFromCookie );
                            this.$emit('userInfo', this.user);
                            this.$router.push({path: '/home'});
                        })
                        .catch(error => {
                            // handle any errors that occur during the request
                            console.error(error);
                        });
                    // axios.post('../panel/login', this.form)
                    //     .then(
                    //         response => {
                    //             this.snackbarOkay = true;
                    //             this.snackbarNokay = false;
                    //             // this.$router.push({ path: '/panel' })
                    //             this.$store.commit('setAuthenticated', true);
                    //             this.$router.push({name: '../panel'})
                    //             // this.sleep(100).then(() => {
                    //             //     this.$router.push({path: '../../../panel/'});
                    //             // });
                    //
                    //         }).catch(errors => {
                    //     this.snackbarNokay = true;
                    // }).finally(() => {
                    //     this.busy = false;
                    //
                    // });
                }
            },



        },
        computed: {

            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },
    };

</script>

<style scoped>
    .bg {
        background: url("../../../assets/icons/Background.svg") no-repeat center center;
        background-size: cover;
    }

    .v-btn {
        text-transform: none !important;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    .v-text-field .v-label--active {
        transform: translateX(-12%) translateX(-7.5px) translateY(-28px) scale(0.75) !important;

    }

    .v-text-field--dense .v-label--active {
        transform: translateX(-12%) translateX(-7.5px) translateY(-21px) scale(0.75) !important;

    }

    /*@-moz-keyframes loader {*/
    /*    from {*/
    /*        transform: rotate(0);*/
    /*    }*/
    /*    to {*/
    /*        transform: rotate(360deg);*/
    /*    }*/
    /*}*/

    /*@-webkit-keyframes loader {*/
    /*    from {*/
    /*        transform: rotate(0);*/
    /*    }*/
    /*    to {*/
    /*        transform: rotate(360deg);*/
    /*    }*/
    /*}*/

    /*@-o-keyframes loader {*/
    /*    from {*/
    /*        transform: rotate(0);*/
    /*    }*/
    /*    to {*/
    /*        transform: rotate(360deg);*/
    /*    }*/
    /*}*/

    /*@keyframes loader {*/
    /*    from {*/
    /*        transform: rotate(0);*/
    /*    }*/
    /*    to {*/
    /*        transform: rotate(360deg);*/
    /*    }*/
    /*}*/
</style>


