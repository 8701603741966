<template>
    <div>

        <v-snackbar :timeout="3000" color="#f2c4d3" top v-model="snackkbarOkey">
            <span style="color: #333333;">
                Thank you, your request has been successfully submitted.
            </span>
        </v-snackbar>
        <v-snackbar :timeout="3000" color="orange" top v-model="snackkbarNokey">
            <span>
                Your cannot registered twice with one email. Please, try with another email
            </span>
        </v-snackbar>

        <v-card class="mx-auto rounded-form" max-width="90%" min-width="400px" outlined>
        <div id="app">
                <div class="wrapper">
                    <v-app-bar
                        absolute
                        color="#e8eaf6"
                        elevate="10"
                        scroll-target="#scrolling-techniques-7"
                    >
                        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

                        <v-toolbar-title :style="{ fontSize: fontSize }">
                            Hubbcast Low code IDE
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon>
                            <v-icon>save</v-icon>
                        </v-btn>

                        <v-btn icon>
                            <v-icon>build</v-icon>
                        </v-btn>

                        <v-btn icon>
                            <v-icon>autorenew</v-icon>
                        </v-btn>
                    </v-app-bar>
                    <v-navigation-drawer
                        v-model="drawer"
                        absolute
                        temporary
                    >

                        <v-list dense>

                            <v-list-item two-lifne>
                                <v-list-item-avatar>
                                    <img src="../../../../assets/icons/LogoLinkedIn.svg" height="2">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title> Graphical Blocks</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>


                        <!--                        <v-list-item-->
                        <!--                            flat="true"-->
                        <!--                            dense-->
                        <!--                            :append="true"-->
                        <!--                            class="mt-4"-->
                        <!--                            link-->
                        <!--                        >-->
                        <!--                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">-->
                        <!--                                <div class="drag-drawflow" draggable="true" v-on:dragstart="drag" data-node="facebook">-->
                        <!--                                    <i class="fab fa-facebook"></i><span> Facebook</span>-->
                        <!--                                </div>-->
                        <!--                            </v-list-item-title>-->
                        <!--                        </v-list-item>-->

                        <!--                        <v-list-item-->
                        <!--                            flat="true"-->
                        <!--                            dense-->
                        <!--                            class="mt-4"-->
                        <!--                            link-->
                        <!--                        >-->
                        <!--                            <v-list-item-action>-->
                        <!--                                <v-icon color="grey darken-1">mail_outline</v-icon>-->
                        <!--                            </v-list-item-action>-->
                        <!--                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Message Inbox</v-list-item-title>-->
                        <!--                        </v-list-item>-->


                        <v-list dense>


                            <v-list>
                                <v-list-group
                                >
                                    <template v-slot:activator>
                                        <v-icon color="grey darken-1">$vuetify.icons.iotprotocols</v-icon>
                                        <v-list-item-title class="grey--text text--darken-2">IoT protocols
                                        </v-list-item-title>

                                    </template>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <v-list-item
                                            class="mt-4"
                                            link
                                        >
                                            <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                                 data-node="MQTT">
                                                <v-icon color="grey darken-1">$vuetify.icons.MQTTLC</v-icon>
                                                <span>MQTT</span>
                                            </div>

                                        </v-list-item>
                                        <!--                                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Insurance advice-->
                                        <!--                                            </v-list-item-title>-->
                                    </v-list-item>
                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <v-list-item
                                            class="mt-4"
                                            link
                                        >
                                            <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                                 data-node="TCP">
                                                <v-icon color="grey darken-1">$vuetify.icons.TCPLC</v-icon>
                                                <span>TCP</span>
                                            </div>

                                        </v-list-item>
                                        <!--                                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Insurance advice-->
                                        <!--                                            </v-list-item-title>-->
                                    </v-list-item>
                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <v-list-item
                                            class="mt-4"
                                            link
                                        >
                                            <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                                 data-node="UDP">
                                                <v-icon color="grey darken-1">$vuetify.icons.UDPLC</v-icon>
                                                <span>UDP</span>
                                            </div>

                                        </v-list-item>
                                    </v-list-item>
                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <v-list-item
                                            class="mt-4"
                                            link
                                        >
                                            <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                                 data-node="Modbus">
                                                <v-icon color="grey darken-1">$vuetify.icons.Modbus</v-icon>
                                                <span>Modbus</span>
                                            </div>
                                        </v-list-item>
                                        <!--                                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Insurance advice-->
                                        <!--                                            </v-list-item-title>-->
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <v-list-item
                                            class="mt-4"
                                            link
                                        >
                                            <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                                 data-node="WebSocket">
                                                <v-icon color="grey darken-1">$vuetify.icons.websocket</v-icon>
                                                <span>WebSocket</span>
                                            </div>
                                        </v-list-item>
                                        <!--                                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Insurance advice-->
                                        <!--                                            </v-list-item-title>-->
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <v-list-item
                                            class="mt-4"
                                            link
                                        >
                                            <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                                 data-node="KNXnetIP">
                                                <v-icon color="grey darken-1">$vuetify.icons.KNX</v-icon>
                                                <span>KNXnet/IP </span>
                                            </div>
                                        </v-list-item>
                                        <!--                                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Insurance advice-->
                                        <!--                                            </v-list-item-title>-->
                                    </v-list-item>
                                </v-list-group>
                            </v-list>


                            <v-list>
                                <v-list-group
                                >
                                    <template v-slot:activator>
                                        <v-icon color="grey darken-1">$vuetify.icons.integrationLP</v-icon>
                                        <v-list-item-title class="grey--text text--darken-2">Integration
                                        </v-list-item-title>
                                    </template>


                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="APIblock">
                                            <v-icon color="grey darken-1">$vuetify.icons.API</v-icon>
                                            <span>API block</span>
                                        </div>
                                    </v-list-item>

                                </v-list-group>
                            </v-list>

                            <v-list>
                                <v-list-group
                                >
                                    <template v-slot:activator>
                                        <v-icon color="grey darken-1">$vuetify.icons.thirdPartySoftware</v-icon>
                                        <v-list-item-title class="grey--text text--darken-2">3rd Party Software
                                        </v-list-item-title>
                                    </template>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="stripe">
                                            <v-icon color="grey darken-1">$vuetify.icons.stripe</v-icon>
                                            <span>Stripe</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="googleTegManager">
                                            <v-icon color="grey darken-1">$vuetify.icons.googleTagManager</v-icon>
                                            <span> Google Tag Manager</span>
                                        </div>
                                    </v-list-item>

                                </v-list-group>
                            </v-list>

                            <v-list>
                                <v-list-group
                                >
                                    <template v-slot:activator>
                                        <v-icon color="grey darken-1">$vuetify.icons.alertLC</v-icon>
                                        <v-list-item-title class="grey--text text--darken-2">Alert and Notification
                                        </v-list-item-title>
                                    </template>
                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="email">
                                            <v-icon color="grey darken-1">$vuetify.icons.emailLC</v-icon>
                                            <span> Email</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="sms">
                                            <v-icon color="grey darken-1">$vuetify.icons.smsLC</v-icon>
                                            <span>SMS</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="push">
                                            <v-icon color="grey darken-1">$vuetify.icons.pushNotification</v-icon>
                                            <span> Push Notification</span>
                                        </div>
                                    </v-list-item>
                                    <!--                                    <v-list-item-->
                                    <!--                                        class="mt-4"-->
                                    <!--                                        link-->
                                    <!--                                    >-->
                                    <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                                    <!--                                             data-node="multiple">-->
                                    <!--                                            <i class="fas fa-code-branch"></i>-->
                                    <!--                                            <v-icon color="grey darken-1">email</v-icon>-->

                                    <!--                                            <span> Email</span>-->
                                    <!--                                        </div>-->

                                    <!--                                    </v-list-item>-->
                                    <!--                                    <v-list-item-->
                                    <!--                                        class="mt-4"-->
                                    <!--                                        link-->
                                    <!--                                    >-->
                                    <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag">-->
                                    <!--                                            <i class="fab fa-aws"></i><span> AWS</span>-->
                                    <!--                                        </div>-->
                                    <!--                                    </v-list-item>-->

                                </v-list-group>
                            </v-list>

                            <v-list>
                                <v-list-group
                                >
                                    <template v-slot:activator>
                                        <v-icon color="grey darken-1">$vuetify.icons.dataVisualizationLC</v-icon>
                                        <v-list-item-title class="grey--text text--darken-2">Data Visualizations
                                        </v-list-item-title>
                                    </template>
                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="timeSeriesGraph">
                                            <v-icon color="grey darken-1">$vuetify.icons.timeSeriesGraph</v-icon>
                                            <span>Time Series Graph</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="eventTimeline">
                                            <v-icon color="grey darken-1">$vuetify.icons.eventTimeline</v-icon>
                                            <span>Event Timeline</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="statisticalDashboard">
                                            <v-icon color="grey darken-1">$vuetify.icons.statisticalDashboard</v-icon>
                                            <span>Summary Dashboard</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="hardware">
                                            <v-icon color="grey darken-1">$vuetify.icons.barChart</v-icon>
                                            <span>Bar Chart</span>
                                        </div>
                                    </v-list-item>

                                    <v-list-item
                                        class="mt-4"
                                        link
                                    >
                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"
                                             data-node="hardware">
                                            <v-icon color="grey darken-1">$vuetify.icons.pieChart</v-icon>
                                            <span>Pie Chart</span>
                                        </div>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>

                            <!--                            <v-list>-->
                            <!--                                <v-list-group-->
                            <!--                                >-->
                            <!--                                    <template v-slot:activator>-->
                            <!--                                        <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-2">Homeowners-->
                            <!--                                        </v-list-item-title>-->
                            <!--                                    </template>-->

                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <v-list-item-action>-->
                            <!--                                            <v-icon color="grey darken-1">view_list</v-icon>-->
                            <!--                                        </v-list-item-action>-->

                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="ListofHomeowners">-->
                            <!--                                            <span>Data visualizations</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->

                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <v-list-item-action>-->
                            <!--                                            <v-icon color="grey darken-1">group_add</v-icon>-->
                            <!--                                        </v-list-item-action>-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="AddHolder">-->
                            <!--                                            <span>Add a new holder</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->

                            <!--                                </v-list-group>-->
                            <!--                            </v-list>-->

                            <!--                            <v-list>-->
                            <!--                                <v-list-group-->
                            <!--                                >-->
                            <!--                                    <template v-slot:activator>-->
                            <!--                                        <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-2">Social Network-->
                            <!--                                        </v-list-item-title>-->
                            <!--                                    </template>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="facebook">-->
                            <!--                                            <i class="fab fa-facebook"></i><span> Facebook</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="slack">-->
                            <!--                                            <i class="fab fa-slack"></i><span> Slack recive message</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="telegram">-->
                            <!--                                            <i class="fab fa-telegram"></i><span> Telegram send message</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="email">-->
                            <!--                                            <i class="fas fa-at"></i><span> Email send</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->
                            <!--                                </v-list-group>-->
                            <!--                            </v-list>-->

                            <!--                            <v-list>-->
                            <!--                                <v-list-group-->
                            <!--                                >-->
                            <!--                                    <template v-slot:activator>-->
                            <!--                                        <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-2">Debug & Log-->
                            <!--                                        </v-list-item-title>-->

                            <!--                                    </template>-->

                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        &lt;!&ndash;                                <v-list-item-action>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <v-icon color="grey darken-1">gps_fixed</v-icon>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-list-item-action>&ndash;&gt;-->
                            <!--                                        <v-chip-->
                            <!--                                            class="ma-2"-->
                            <!--                                            close-->
                            <!--                                            color="green"-->
                            <!--                                            outlined-->
                            <!--                                        >-->
                            <!--                                            <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Graphical Blocks-->
                            <!--                                            </v-list-item-title>-->
                            <!--                                        </v-chip>-->
                            <!--                                    </v-list-item>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="log">-->
                            <!--                                            <i class="fas fa-file-signature"></i><span> File Log</span>-->
                            <!--                                        </div>-->

                            <!--                                    </v-list-item>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="github">-->
                            <!--                                            <i class="fab fa-github"></i><span> Github Star</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->
                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <div class="drag-drawflow" draggable="true" v-on:dragstart="drag"-->
                            <!--                                             data-node="template">-->
                            <!--                                            <i class="fas fa-code"></i><span> Template</span>-->
                            <!--                                        </div>-->
                            <!--                                    </v-list-item>-->

                            <!--                                    <v-list-item-->
                            <!--                                        class="mt-4"-->
                            <!--                                        link-->
                            <!--                                    >-->
                            <!--                                        <v-list-item-action>-->
                            <!--                                            <v-icon color="grey darken-1">featured_play_list</v-icon>-->
                            <!--                                        </v-list-item-action>-->
                            <!--                                        <v-list-item-title class="grey&#45;&#45;text text&#45;&#45;darken-1">Information of all houses-->
                            <!--                                        </v-list-item-title>-->
                            <!--                                    </v-list-item>-->
                            <!--                                </v-list-group>-->
                            <!--                            </v-list>-->


                            <!--                    <v-subheader v-if="user.access_level < 4 || user.access_level == 8" class="mt-4 grey--text text--darken-1">صاحبان سگ ها-->
                            <!--                    </v-subheader>-->

                            <!--                    <v-subheader v-if="user.access_level == 1 || user.access_level == 8|| user.access_level == 7" class="mt-4 grey--text text--darken-1">مشخصات پرسنل حوزه-->

                            <!--                    </v-subheader>-->
                            <!--                // User information-->

                            <!--                    <v-subheader  class="mt-4 grey--text text--darken-1 text-black-50">ناحیه ی کاربری-->

                            <!--                    </v-subheader>-->


                            <v-list-item
                                class="mt-4"
                                link

                            >
                                <v-list-item-action>
                                    <!--                            <v-icon color="grey darken-1">exit_to_app</v-icon>-->
                                </v-list-item-action>
                                <!--                        <v-list-item-title class="grey--text text--darken-1">خروج</v-list-item-title>-->
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>


                    <v-sheet
                        id="scrolling-techniques-7"
                        class="overflow-y-auto my-20"
                        min-height="600"
                        max-height="600"

                    >

                        <!--                                                            <p v-on:dragstart="dragStart(event)" draggable="true" id="dragtarget">Drag me into the rectangle!</p>-->

                        <!--                                                            <div id="droptarget" v-on:drop="drop(event)" v-on:dragover="allowDrop(event)">-->
                        <!--                                                            </div>-->

                        <!--                                                            <p style="clear:both;"><strong>Note:</strong> drag events are not supported in Internet Explorer 8 and-->
                        <!--                                                                earlier versions or Safari 5.1 and earlier versions.</p>-->

                        <!--                                                            <p id="demo"></p>-->


                        <div class="col-right">

                            <div id="drawflow" v-on:drop="drop" v-on:dragover="allowDrop">
                                <div class="bar-zoom">
                                    <i class="fas fa-search-minus" onclick="editor.zoom_out()"></i>
                                    <i class="fas fa-search" onclick="editor.zoom_reset()"></i>
                                    <i class="fas fa-search-plus" onclick="editor.zoom_in()"></i>
                                    <!--                                    <i class="fas fa-search-plus" onclick="dataExported=editor.export()"></i>-->
                                    <!--                                    <v-btn @click="dataExported=editor.export()"> Click</v-btn>-->
                                </div>
                            </div>
                        </div>


                    </v-sheet>
                </div>
                <!--        <div id="drawflow"></div>-->
            </div>
        </v-card>


    </div>

</template>

<!--<template>-->


<!--</template>-->

<script>

    import vueFilePond from 'vue-filepond';
    import Vue from 'vue';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
    import moment from 'moment';
    import draggable from "vuedraggable"
    import VueDragResize from 'vue-drag-resize'
    import Drawflow from 'drawflow'
    import styleDrawflow from 'drawflow/dist/drawflow.min.css'
    import NodeClick from './NodeClick.vue'

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);


    export default {
        name: "GetDemo",
        components: {
            FilePond,
            draggable,
            VueDragResize,
            Drawflow,
            NodeClick,
            styleDrawflow
        },

        data: function () {
            return {
                fontSize: '24px',
                dataExported: '',
                pos_x: '',
                pos_y: '',
                elements: null,
                mobile_item_selec: '',
                mobile_last_move: null,
                transform: '',
                editor: null,
                width: 0,
                height: 0,
                top: 0,
                left: 0,
                mini: true,
                drawer: false,
                TimeSlot: [],
                meetingList: [],
                notAvailableTimeSlot: [],
                currentDate: moment().format("YYYY-MM-DD"),

                notAvailableDates: ["2021-11-01", "2021-11-02"],
                validPage1: true,
                validPage2: true,

                modal: false,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                e13: 2,
                plan: '',
                e1: 1,
                changedPlan: '',
                building_set: [],
                building_selected_id: '',
                building_id: '',
                valid: true,

                nameRules: [
                    // v => !!v || 'نام لازم می باشد',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the name is 10',
                ],
                familyRules: [
                    v => !!v || 'Last name required',
                    v => (v && v.length <= 20) || 'The maximum number of characters in the last name is 10',
                ],
                cellnumRules: [
                    v => !!v || 'Contact number is required',
                    v => (v && v.length === 11) || ' Contact number is required',
                ],
                select: null,
                checkbox: false,
                //myFiles: ['index.html'],
                dialogUnit: false,
                dialogSupporter: false,
                dialogChangePlan: false,
                dialogExtend: false,
                dialogSend: false,
                snackkbarOkey: false,
                snackkbarNokey: false,
                form:
                    {
                        'major': '',
                        'name': '',
                        'email': '',
                        'company': '',
                        'role': '',
                        'region': '',
                        'challenge': '',
                        'meetMethod': '',
                        'date': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                        'time': ''
                    },
                formUnitArray: [],
                changepaln: '',
                errors: null,
                responses: null,
                busy: false,
                request: '',
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.setFontSize);
        },
        mounted() {
            this.setFontSize();
            window.addEventListener('resize', this.setFontSize);

            const dataToImport = {
                "drawflow": {
                    "Home": {
                        "data": {
                            "5": {
                                "id": 5,
                                "name": "Claims processing",
                                "data": {},
                                "class": "ClaimsProcessing",
                                "html": "<div>\n              <div class=\"box\">\n                MQTT protocols\n              </div>\n            </div>",
                                "typenode": false,
                                "inputs": {
                                    "input_1": {"connections": [{"node": "13", "input": "output_1"}]},
                                    "input_2": {"connections": [{"node": "14", "input": "output_1"}]},
                                    "input_3": {"connections": [{"node": "15", "input": "output_1"}]}
                                },
                                "outputs": {
                                    "output_1": {
                                        "connections": [{
                                            "node": "7",
                                            "output": "input_1"
                                        }, {"node": "19", "output": "input_1"}, {"node": "18", "output": "input_1"}]
                                    },
                                    "output_2": {
                                        "connections": [{"node": "6", "output": "input_1"}, {
                                            "node": "19",
                                            "output": "input_1"
                                        }, {"node": "18", "output": "input_1"}]
                                    }
                                },
                                "pos_x": 73,
                                "pos_y": -80
                            },
                            "6": {
                                "id": 6,
                                "name": "Fraud Prevention",
                                "data": {},
                                "class": "FraudPrevention",
                                "html": "<div>\n              <div class=\"box\">\n                Fraud Prevention\n              </div>\n            </div>",
                                "typenode": false,
                                "inputs": {
                                    "input_1": {"connections": [{"node": "5", "input": "output_2"}]},
                                    "input_2": {"connections": []}
                                },
                                "outputs": {"output_1": {"connections": [{"node": "11", "output": "input_2"}]}},
                                "pos_x": 466,
                                "pos_y": -135
                            },
                            "7": {
                                "id": 7,
                                "name": "Risk Management",
                                "data": {},
                                "class": "RiskManagement",
                                "html": "<div>\n              <div class=\"box\">\n                Risk Management\n              </div>\n            </div>",
                                "typenode": false,
                                "inputs": {
                                    "input_1": {"connections": [{"node": "5", "input": "output_1"}]},
                                    "input_2": {"connections": []},
                                    "input_3": {"connections": []}
                                },
                                "outputs": {"output_1": {"connections": [{"node": "11", "output": "input_1"}]}},
                                "pos_x": 464,
                                "pos_y": -260
                            },
                            // "8": {
                            //     "id": 8,
                            //     "name": "List of Homeowners",
                            //     "data": {"type": "type_3", "channel": "type_1"},
                            //     "class": "ListofHomeowners",
                            //     "html": "<div>\n            <div class=\"title-box\"> Homeowners</div>\n            <div class=\"box\">\n              <p>select type</p>\n              <select df-channel>\n                <option value=\"type_1\">Type 1</option>\n                <option value=\"type_2\">Type 2</option>\n                <option value=\"type_3\">Type 3</option>\n                <option value=\"type_4\">Type 4</option>\n              </select>\n            </div>\n          </div>",
                            //     "typenode": false,
                            //     "inputs": {"input_1": {"connections": [{"node": "10", "input": "output_1"}]}},
                            //     "outputs": {},
                            //     "pos_x": 799,
                            //     "pos_y": 485
                            // },
                            // "9": {
                            //     "id": 9,
                            //     "name": "Add Holder",
                            //     "data": {},
                            //     "class": "AddHolder",
                            //     "html": "<div>\n              <div class=\"box\">\n                Add Holder\n              </div>\n            </div>",
                            //     "typenode": false,
                            //     "inputs": {"input_1": {"connections": []}},
                            //     "outputs": {"output_1": {"connections": [{"node": "10", "output": "input_1"}]}},
                            //     "pos_x": 47,
                            //     "pos_y": 411
                            // },
                            // "10": {
                            //     "id": 10,
                            //     "name": "Forms",
                            //     "data": {},
                            //     "class": "Forms",
                            //     "html": "<div>\n              <div class=\"box\">\n                Forms\n              </div>\n            </div>",
                            //     "typenode": false,
                            //     "inputs": {
                            //         "input_1": {"connections": [{"node": "9", "input": "output_1"}]},
                            //         "input_2": {"connections": []},
                            //         "input_3": {"connections": []},
                            //         "input_4": {"connections": []},
                            //         "input_5": {"connections": []}
                            //     },
                            //     "outputs": {
                            //         "output_1": {
                            //             "connections": [{
                            //                 "node": "17",
                            //                 "output": "input_1"
                            //             }, {"node": "8", "output": "input_1"}]
                            //         }
                            //     },
                            //     "pos_x": 439,
                            //     "pos_y": 317
                            // },
                            "11": {
                                "id": 11,
                                "name": "Charts",
                                "data": {},
                                "class": "Charts",
                                "html": "<div>\n              <div class=\"box\">\n                Time Series Chart\n              </div>\n            </div>",
                                "typenode": false,
                                "inputs": {
                                    "input_1": {"connections": [{"node": "7", "input": "output_1"}]},
                                    "input_2": {"connections": [{"node": "6", "input": "output_1"}]},
                                    "input_3": {"connections": []}
                                },
                                "outputs": {},
                                "pos_x": 875,
                                "pos_y": -179
                            },
                            // "12": {
                            //     "id": 12,
                            //     "name": "Charts",
                            //     "data": {},
                            //     "class": "Charts",
                            //     "html": "<div>\n              <div class=\"box\">\n                Charts\n              </div>\n            </div>",
                            //     "typenode": false,
                            //     "inputs": {
                            //         "input_1": {"connections": [{"node": "13", "input": "output_1"}]},
                            //         "input_2": {"connections": [{"node": "14", "input": "output_1"}]},
                            //         "input_3": {"connections": [{"node": "15", "input": "output_1"}]}
                            //     },
                            //     "outputs": {},
                            //     "pos_x": 67,
                            //     "pos_y": -311
                            // },
                            "13": {
                                "id": 13,
                                "name": "hardware",
                                "data": {"Brand": "channel_3", "channel": "brand_2"},
                                "class": "hardware",
                                "html": "<div>\n            <div class=\"title-box\"> Device</div>\n            <div class=\"box\">\n              <p>select brand</p>\n              <select df-channel>\n                <option value=\"brand_1\">LG</option>\n                <option value=\"brand_2\">Bosch</option>\n                <option value=\"brand_3\">Samsung</option>\n                <option value=\"brand_4\">LG</option>\n              </select>\n            </div>\n          </div>",
                                "typenode": false,
                                "inputs": {"input_1": {"connections": []}},
                                "outputs": {
                                    "output_1": {
                                        "connections": [{
                                            "node": "5",
                                            "output": "input_1"
                                        }, {"node": "12", "output": "input_1"}]
                                    }
                                },
                                "pos_x": -309,
                                "pos_y": -279
                            },
                            "14": {
                                "id": 14,
                                "name": "hardware",
                                "data": {"Brand": "channel_3"},
                                "class": "hardware",
                                "html": "<div>\n            <div class=\"title-box\"> Device</div>\n            <div class=\"box\">\n              <p>select brand</p>\n              <select df-channel>\n                <option value=\"brand_1\">Arcelik</option>\n                <option value=\"brand_2\">Bosch</option>\n                <option value=\"brand_3\">Samsung</option>\n                <option value=\"brand_4\">LG</option>\n              </select>\n            </div>\n          </div>",
                                "typenode": false,
                                "inputs": {"input_1": {"connections": []}},
                                "outputs": {
                                    "output_1": {
                                        "connections": [{
                                            "node": "5",
                                            "output": "input_2"
                                        }, {"node": "12", "output": "input_2"}]
                                    }
                                },
                                "pos_x": -309,
                                "pos_y": -81
                            },
                            "15": {
                                "id": 15,
                                "name": "hardware",
                                "data": {"Brand": "channel_3", "channel": "brand_3"},
                                "class": "hardware",
                                "html": "<div>\n            <div class=\"title-box\"> Device</div>\n            <div class=\"box\">\n              <p>select brand</p>\n              <select df-channel>\n                <option value=\"brand_1\">Arcelik</option>\n                <option value=\"brand_2\">Bosch</option>\n                <option value=\"brand_3\">Samsung</option>\n                <option value=\"brand_4\">LG</option>\n              </select>\n            </div>\n          </div>",
                                "typenode": false,
                                "inputs": {"input_1": {"connections": []}},
                                "outputs": {
                                    "output_1": {
                                        "connections": [{
                                            "node": "5",
                                            "output": "input_3"
                                        }, {"node": "12", "output": "input_3"}]
                                    }
                                },
                                "pos_x": -295,
                                "pos_y": 127
                            },
                            // "17": {
                            //     "id": 17,
                            //     "name": "dbclick",
                            //     "data": {"name": ""},
                            //     "class": "dbclick",
                            //     "html": "<div>\n            <div class=\"title-box\"><i class=\"fas fa-mouse\"></i> Db Click</div>\n              <div class=\"box dbclickbox\" ondblclick=\"showpopup(event)\">\n                Db Click here\n                <div class=\"modal\" style=\"display:none\">\n                  <div class=\"modal-content\">\n                    <span class=\"close\" onclick=\"closemodal(event)\">&times;</span>\n                    Change your variable {name} !\n                    <input type=\"text\" df-name>\n                  </div>\n                </div>\n              </div>\n            </div>",
                            //     "typenode": false,
                            //     "inputs": {"input_1": {"connections": [{"node": "10", "input": "output_1"}]}},
                            //     "outputs": {"output_1": {"connections": []}},
                            //     "pos_x": 793,
                            //     "pos_y": 319
                            // },
                            "18": {
                                "id": 18,
                                "name": "email",
                                "data": {},
                                "class": "email",
                                "html": "<div>\n              <div class=\"title-box\"><i class=\"fas fa-at\"></i> Send Email </div>\n            </div>",
                                "typenode": false,
                                "inputs": {
                                    "input_1": {
                                        "connections": [{"node": "5", "input": "output_2"}, {
                                            "node": "5",
                                            "input": "output_1"
                                        }]
                                    }
                                },
                                "outputs": {},
                                "pos_x": 471,
                                "pos_y": 91
                            },
                            "19": {
                                "id": 19,
                                "name": "sms",
                                "data": {},
                                "class": "sms",
                                "html": "<div>\n            <div class=\"title-box\">Send SMS</div>\n          </div>",
                                "typenode": false,
                                "inputs": {
                                    "input_1": {
                                        "connections": [{"node": "5", "input": "output_2"}, {
                                            "node": "5",
                                            "input": "output_1"
                                        }]
                                    }
                                },
                                "outputs": {},
                                "pos_x": 479,
                                "pos_y": -18
                            }
                        }
                    }
                }
            };
            // const dataToImport = {
            //     "drawflow": {
            //         "Home": {
            //             "data": {
            //                 "1": {
            //                     "id": 1,
            //                     "name": "facebook",
            //                     "data": {},
            //                     "class": "facebook",
            //                     "typenode": false,
            //                     "inputs": {},
            //                     "outputs": {"output_1": {"connections": [{"node": "3", "output": "input_1"}]}},
            //                     "pos_x": 84,
            //                     "pos_y": 554
            //                 },
            //                 "2": {
            //                     "id": 2,
            //                     "name": "Name",
            //                     "data": {},
            //                     "class": "Class",
            //                     "html": "NodeClick",
            //                     "typenode": "vue",
            //                     "inputs": {},
            //                     "outputs": {"output_1": {"connections": [{"node": "3", "output": "input_1"}]}},
            //                     "pos_x": 120,
            //                     "pos_y": 350
            //                 },
            //                 "3": {
            //                     "id": 3,
            //                     "name": "Name2",
            //                     "data": {},
            //                     "class": "Class",
            //                     "html": "NodeClick",
            //                     "typenode": "vue",
            //                     "inputs": {
            //                         "input_1": {
            //                             "connections": [{"node": "2", "input": "output_1"}, {
            //                                 "node": "1",
            //                                 "input": "output_1"
            //                             }]
            //                         }
            //                     }, "outputs": {},
            //                     "pos_x": 336,
            //                     "pos_y": 515
            //                 },
            //             }
            //         },
            //     }
            // };


            const id = document.getElementById("drawflow");
            this.editor = new Drawflow(id, Vue);
            this.editor.reroute = true;
            this.editor.zoom_value = 0.05;
            this.editor.zoom = 0.6;
            this.editor.start();
            this.editor.zoom_refresh();
            const props = {};
            const options = {};
            this.editor.on('nodeCreated', function (id) {
                // console.log("Node created " + id);
            });

            this.editor.on('nodeRemoved', function (id) {
                // console.log("Node removed " + id);
            });

            this.editor.on('nodeSelected', function (id) {
                // console.log("Node selected " + id);
            });

            this.editor.on('moduleCreated', function (name) {
                // console.log("Module Created " + name);
            });

            this.editor.on('moduleChanged', function (name) {
                // console.log("Module Changed " + name);
            });

            this.editor.on('connectionCreated', function (connection) {
                // console.log('Connection created');
                // console.log(connection);
            });

            this.editor.on('connectionRemoved', function (connection) {
                // console.log('Connection removed');
                // console.log(connection);
            });

            this.editor.on('mouseMove', function (position) {
                // console.log('Position mouse x:' + position.x + ' y:' + position.y);
            });

            this.editor.on('nodeMoved', function (id) {
                // console.log("Node moved " + id);
            });

            this.editor.on('zoom', function (zoom) {
                // console.log('Zoom level ' + zoom);
            });

            this.editor.on('translate', function (position) {
                // console.log('Translate x:' + position.x + ' y:' + position.y);
            });
            this.editor.on('addReroute', function (id) {
                // console.log("Reroute added " + id);
            });

            this.editor.on('removeReroute', function (id) {
                // console.log("Reroute removed " + id);
            });

            this.elements = document.getElementsByClassName('drag-drawflow');
            for (var i = 0; i < this.elements.length; i++) {
                this.elements[i].addEventListener('touchend', this.drop, false);
                this.elements[i].addEventListener('touchmove', this.positionMobile, false);
                this.elements[i].addEventListener('touchstart', this.drag, false);
            }

            var mobile_item_selec = '';
            var mobile_last_move = null;

            this.editor.registerNode('NodeClick', NodeClick, props, options);
            const data = {};
            const data2 = {};
            // this.pos_x = 100;
            // this.pos_y = 200;
            // this.editor.addNode('facebook', 0, 1, 100, 200, 'facebook', {}, 'facebook');
            // this.editor.addNode('Name', 0, 1, 150, 300, 'Class', data, 'NodeClick', 'vue');
            // this.editor.addNode('Name2', 1, 1, 200, 400, 'Class', data2, 'NodeClick', 'vue');
            this.editor.import(dataToImport);

            // this.editor.import(dataToImport);

            // this.editor.start();
            // this.editor.import(dataToImport);

            axios.get('../meetingList')
                .then(response => {
                    this.meetingList = response.data;

                    for (let i = 0; i < this.meetingList.length; i++) {
                        if ((this.meetingList[i]['8:00-8:30'] + this.meetingList[i]['8:30-9:00'] + this.meetingList[i]['9:00-9:30']
                            + this.meetingList[i]['19:00-19:30'] + this.meetingList[i]['19:30-20:00'] + this.meetingList[i]['20:00-20:30']) === "111111") {
                            this.notAvailableDates.push(this.meetingList[i]['date'])
                        }
                    }
                });
        },

        methods: {
            setFontSize() {
                const width = window.innerWidth;
                if (width <= 600) {
                    this.fontSize = '11px';
                } else if (width <= 900) {
                    this.fontSize = '16px';
                } else if (width <= 1200) {
                    this.fontSize = '20px';
                } else {
                    this.fontSize = '24px';
                }
            },
            // dragStart(event) {
            //     event.dataTransfer.setData("Text", event.target.id);
            // },
            //
            // allowDrop(event) {
            //     event.preventDefault();
            //     document.getElementById("demo").innerHTML = "The p element is OVER the droptarget.";
            //     event.target.style.border = "4px dotted green";
            // },
            //
            // drop(event) {
            //     event.preventDefault();
            //     var data = event.dataTransfer.getData("Text");
            //     event.target.appendChild(document.getElementById(data));
            //     document.getElementById("demo").innerHTML = "The p element was dropped.";
            // },

            positionMobile(event) {
                this.mobile_last_move = event;
            },

            allowDrop(event) {
                console.log(event);
                event.preventDefault();
            },

            drag: function (event) {
                this.drawer = false;
                // console.log(event);
                if (event.type === "touchstart") {
                    console.log("1");
                    this.mobile_item_selec = event.target.closest(".drag-drawflow").getAttribute('data-node');
                } else {
                    console.log("2");
                    event.dataTransfer.setData("node", event.target.getAttribute('data-node'));
                }
            },

            drop(event) {
                console.log(event);
                if (event.type === "touchend") {
                    var parentdrawflow = document.elementFromPoint(this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY).closest("#drawflow");
                    if (parentdrawflow != null) {
                        this.addNodeToDrawFlow(this.mobile_item_selec, this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY);
                    }
                    this.mobile_item_selec = '';
                } else {
                    event.preventDefault();
                    var data = event.dataTransfer.getData("node");
                    this.addNodeToDrawFlow(data, event.clientX, event.clientY);
                }

            },

            addNodeToDrawFlow(name, pos_x, pos_y) {
                if (this.editor.editor_mode === 'fixed') {
                    return false;
                }
                pos_x = pos_x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)));
                pos_y = pos_y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)));


                switch (name) {
                    case 'MQTT':
                        var MQTT = '<div>\n' +
                            '              <div class="box">\n' +
                            '                MQTT\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('MQTT', 3, 2, pos_x, pos_y, 'MQTT', {}, MQTT);
                        break;

                    case 'TCP':
                        var TCP = '<div>\n' +
                            '              <div class="box">\n' +
                            '                TCP\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('TCP', 3, 2, pos_x, pos_y, 'TCP', {}, TCP);
                        break;

                    case 'UDP':
                        var UDP = '<div>\n' +
                            '              <div class="box">\n' +
                            '                UDP\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('UDP', 3, 2, pos_x, pos_y, 'UDP', {}, UDP);
                        break;

                    case 'Modbus':
                        var Modbus = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Modbus\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Modbus', 3, 2, pos_x, pos_y, 'Modbus', {}, Modbus);
                        break;

                    case 'WebSocket':
                        var WebSocket = '<div>\n' +
                            '              <div class="box">\n' +
                            '                WebSocket\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('WebSocket', 3, 2, pos_x, pos_y, 'WebSocket', {}, WebSocket);
                        break;

                    case 'KNXnetIP':
                        var KNXnetIP = '<div>\n' +
                            '              <div class="box">\n' +
                            '                KNXnet/IP\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('KNXnetIP', 3, 2, pos_x, pos_y, 'KNXnetIP', {}, KNXnetIP);
                        break;

                    case 'facebook':
                        var facebook = '<div>\n' +
                            '          <div class="title-box"><i class="fab fa-facebook"></i> Facebook Message</div>\n' +
                            '        </div>';
                        const props = {};
                        const options = {};
                        this.editor.registerNode('NodeClick', NodeClick, props, options);
                        this.editor.addNode('facebook', 0, 1, pos_x, pos_y, 'Class', {}, 'facebook');
                        // this.editor.addNode('Name', 1, 1, 200, 400, 'Class', data2, 'NodeClick', 'vue');
                        // editor.addNode('facebook', 0,  1, pos_x, pos_y, 'facebook', {}, facebook );

                        break;
                    case 'APIblock':
                        var facebook = '<div>\n' +
                            '          <div class="title-box"> API Block</div>\n' +
                            '        </div>';
                        const props1 = {};
                        const options2 = {};
                        this.editor.registerNode('NodeClick', NodeClick, props1, options2);
                        this.editor.addNode('APIblock', 1, 1, pos_x, pos_y, 'Class', {}, 'APIblock');
                        // this.editor.addNode('Name', 1, 1, 200, 400, 'Class', data2, 'NodeClick', 'vue');
                        // editor.addNode('facebook', 0,  1, pos_x, pos_y, 'facebook', {}, facebook );

                        break;
                    case 'slack':
                        var slackchat = '<div>\n' +
                            '            <div class="title-box"><i class="fab fa-slack"></i> Slack chat message</div>\n' +
                            '          </div>';
                        this.editor.addNode('slack', 1, 0, pos_x, pos_y, 'slack', {}, slackchat);
                        break;

                    case 'stripe':
                        var stripe = '<div>\n' +
                            '            <div class="title-box"><i class="fab fa-slack"></i>Stripe</div>\n' +
                            '          </div>';
                        this.editor.addNode('stripe', 2, 2, pos_x, pos_y, 'stripe', {}, stripe);
                        break;

                    case 'googleTegManager':
                        var googleTegManager = '<div>\n' +
                            '            <div class="title-box"><i class="fab fa-slack"></i> Google Tag Manager</div>\n' +
                            '          </div>';
                        this.editor.addNode('googleTegManager', 2, 3, pos_x, pos_y, 'googleTegManager', {}, googleTegManager);
                        break;

                    case 'email':
                        var email = '<div>\n' +
                            '            <div class="title-box"> Email</div>\n' +
                            '          </div>';
                        this.editor.addNode('email', 2, 2, pos_x, pos_y, 'email', {}, email);
                        break;

                    case 'sms':
                        var sms = '<div>\n' +
                            '            <div class="title-box"> SMS</div>\n' +
                            '          </div>';
                        this.editor.addNode('sms', 2, 2, pos_x, pos_y, 'sms', {}, sms);
                        break;

                    case 'push':
                        var push = '<div>\n' +
                            '            <div class="title-box"></i> Push Notification</div>\n' +
                            '          </div>';
                        this.editor.addNode('push', 2, 2, pos_x, pos_y, 'slack', {}, push);
                        break;
                    case 'github':
                        const githubtemplate = '<div class="bg-success card-devices" >\n' +
                            '<div class="header">\n' +
                            '<h1> Test </h1>\n' +
                            '</div>\n' +
                            '<div class="body" >\n' +
                            '<span @click="Clicked"> Name :</span>\n' +
                            '</div>\n' +
                            '</div>';
                        // const githubtemplate = '<div>\n' +
                        //     '            <div class="title-box"><i class="fab fa-github "></i> Github Stars</div>\n' +
                        //     '            <div class="box">\n' +
                        //     '              <p>Enter repository url</p>\n' +
                        //     '            <input type="text" df-name>\n' +
                        //     '            </div>\n' +
                        //     '          </div>';
                        this.editor.addNode('github', 0, 1, pos_x, pos_y, 'github', {"name": ''}, githubtemplate);
                        break;
                    case 'hardware':
                        var hardware = '<div>\n' +
                            '            <div class="title-box"> Device</div>\n' +
                            '            <div class="box">\n' +
                            '              <p>select brand</p>\n' +
                            '              <select df-channel>\n' +
                            '                <option value="brand_1">Arcelik</option>\n' +
                            '                <option value="brand_2">Bosch</option>\n' +
                            '                <option value="brand_3">Samsung</option>\n' +
                            '                <option value="brand_4">LG</option>\n' +
                            '              </select>\n' +
                            '            </div>\n' +
                            '          </div>';
                        this.editor.addNode('hardware', 1, 1, pos_x, pos_y, 'hardware', {"Brand": 'channel_3'}, hardware);
                        break;
                    case 'telegram':
                        var telegrambot = '<div>\n' +
                            '            <div class="title-box"><i class="fab fa-telegram-plane"></i> Telegram bot</div>\n' +
                            '            <div class="box">\n' +
                            '              <p>Send to telegram</p>\n' +
                            '              <p>select channel</p>\n' +
                            '              <select df-channel>\n' +
                            '                <option value="channel_1">Channel 1</option>\n' +
                            '                <option value="channel_2">Channel 2</option>\n' +
                            '                <option value="channel_3">Channel 3</option>\n' +
                            '                <option value="channel_4">Channel 4</option>\n' +
                            '              </select>\n' +
                            '            </div>\n' +
                            '          </div>';
                        this.editor.addNode('telegram', 1, 0, pos_x, pos_y, 'telegram', {"channel": 'channel_3'}, telegrambot);
                        break;
                    case 'aws':
                        var aws = '<div>\n' +
                            '            <div class="title-box"><i class="fab fa-aws"></i> Aws Save </div>\n' +
                            '            <div class="box">\n' +
                            '              <p>Save in aws</p>\n' +
                            '              <input type="text" df-db-dbname placeholder="DB name"><br><br>\n' +
                            '              <input type="text" df-db-key placeholder="DB key">\n' +
                            '              <p>Output Log</p>\n' +
                            '            </div>\n' +
                            '          </div>';
                        this.editor.addNode('aws', 1, 1, pos_x, pos_y, 'aws', {"db": {"dbname": '', "key": ''}}, aws);
                        break;
                    case 'log':
                        var log = '<div>\n' +
                            '              <div class="title-box"><i class="fas fa-file-signature"></i> Save log file </div>\n' +
                            '            </div>';
                        this.editor.addNode('log', 1, 0, pos_x, pos_y, 'log', {}, log);
                        break;
                    case 'google':
                        var google = '<div>\n' +
                            '              <div class="title-box"><i class="fab fa-google-drive"></i> Google Drive save </div>\n' +
                            '            </div>';
                        this.editor.addNode('google', 1, 0, pos_x, pos_y, 'google', {}, google);
                        break;
                    // case 'email':
                    //     var email = '<div>\n' +
                    //         '              <div class="title-box"><i class="fas fa-at"></i> Send Email </div>\n' +
                    //         '            </div>';
                    //
                    //     this.editor.addNode('email', 1, 0, pos_x, pos_y, 'email', {}, email);
                    //     break;

                    case 'template':
                        var template = '<div>\n' +
                            '              <div class="title-box"><i class="fas fa-code"></i> Template</div>\n' +
                            '              <div class="box">\n' +
                            '                Ger Vars\n' +
                            '                <textarea df-template></textarea>\n' +
                            '                Output template with vars\n' +
                            '              </div>\n' +
                            '            </div>';

                        this.editor.addNode('template', 1, 1, pos_x, pos_y, 'template', {"template": 'Write your template'}, template);
                        break;
                    case 'Forms':
                        var Forms = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Forms\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Forms', 5, 1, pos_x, pos_y, 'Forms', {}, Forms);
                        break;
                    case 'Charts':
                        var Charts = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Charts\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Charts', 3, 0, pos_x, pos_y, 'Charts', {}, Charts);
                        break;

                    case 'timeSeriesGraph':
                        var timeSeriesGraph = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Time Series Graph\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('timeSeriesGraph', 3, 0, pos_x, pos_y, 'timeSeriesGraph', {}, timeSeriesGraph);
                        break;
                    case 'eventTimeline':
                        var eventTimeline = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Event Timeline\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('eventTimeline', 3, 0, pos_x, pos_y, 'eventTimeline', {}, eventTimeline);
                        break;
                    case 'statisticalDashboard':
                        var statisticalDashboard = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Summary Dashboard\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('statisticalDashboard', 3, 0, pos_x, pos_y, 'statisticalDashboard', {}, statisticalDashboard);
                        break;
                    case 'barChart':
                        var barChart = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Bar Chart\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('barChart', 3, 0, pos_x, pos_y, 'barChart', {}, barChart);
                        break;
                    case 'pieChart':
                        var pieChart = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Pie Chart\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('pieChart', 3, 0, pos_x, pos_y, 'pieChart', {}, pieChart);
                        break;


                    case 'insuranceadvice':
                        var insuranceadvice = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Insurance Advice\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('insurance Advice', 3, 2, pos_x, pos_y, 'insuranceadvice', {}, insuranceadvice);
                        break;
                    case 'ClaimsProcessing':
                        var ClaimsProcessing = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Claims processing\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Claims processing', 3, 2, pos_x, pos_y, 'ClaimsProcessing', {}, ClaimsProcessing);
                        break;

                    case 'FraudPrevention':
                        var FraudPrevention = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Fraud Prevention\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Fraud Prevention', 2, 1, pos_x, pos_y, 'FraudPrevention', {}, FraudPrevention);
                        break;
                    case 'RiskManagement':
                        var RiskManagement = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Risk Management\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Risk Management', 3, 1, pos_x, pos_y, 'RiskManagement', {}, RiskManagement);
                        break;
                    case 'multiple':
                        var multiple = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Multiple/IO\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('multiple', 3, 1, pos_x, pos_y, 'multiple', {}, multiple);
                        break;
                    case 'ListofHomeowners':
                        var ListofHomeowners = '<div>\n' +
                            '            <div class="title-box"> Homeowners</div>\n' +
                            '            <div class="box">\n' +
                            '              <p>select type</p>\n' +
                            '              <select df-channel>\n' +
                            '                <option value="type_1">Type 1</option>\n' +
                            '                <option value="type_2">Type 2</option>\n' +
                            '                <option value="type_3">Type 3</option>\n' +
                            '                <option value="type_4">Type 4</option>\n' +
                            '              </select>\n' +
                            '            </div>\n' +
                            '          </div>';
                        this.editor.addNode('List of Homeowners', 1, 0, pos_x, pos_y, 'ListofHomeowners', {"type": 'type_3'}, ListofHomeowners);
                        break;
                    case 'AddHolder':
                        var AddHolder = '<div>\n' +
                            '              <div class="box">\n' +
                            '                Add Holder\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('Add Holder', 1, 1, pos_x, pos_y, 'AddHolder', {}, AddHolder);
                        break;
                    case 'personalized':
                        var personalized = '<div>\n' +
                            '              Personalized\n' +
                            '            </div>';
                        this.editor.addNode('personalized', 1, 1, pos_x, pos_y, 'personalized', {}, personalized);
                        break;
                    case 'dbclick':
                        var dbclick = '<div>\n' +
                            '            <div class="title-box"><i class="fas fa-mouse"></i> Db Click</div>\n' +
                            '              <div class="box dbclickbox" ondblclick="showpopup(event)">\n' +
                            '                Db Click here\n' +
                            '                <div class="modal" style="display:none">\n' +
                            '                  <div class="modal-content">\n' +
                            '                    <span class="close" onclick="closemodal(event)">&times;</span>\n' +
                            '                    Change your variable {name} !\n' +
                            '                    <input type="text" df-name>\n' +
                            '                  </div>\n' +
                            '                </div>\n' +
                            '              </div>\n' +
                            '            </div>';
                        this.editor.addNode('dbclick', 1, 1, pos_x, pos_y, 'dbclick', {name: ''}, dbclick);
                        break;
                    default:
                }
            },

            positionMobile(ev) {
                this.mobile_last_move = ev;
            },

            closemodal(e) {
                e.target.closest(".drawflow-node").style.zIndex = "2";
                e.target.parentElement.parentElement.style.display = "none";
                //document.getElementById("modalfix").style.display = "none";
                this.editor.precanvas.style.transform = transform;
                this.editor.precanvas.style.left = '0px';
                this.editor.precanvas.style.top = '0px';
                this.editor.editor_mode = "edit";
            },

            changeModule(event) {
                var all = document.querySelectorAll(".menu ul li");
                for (var i = 0; i < all.length; i++) {
                    all[i].classList.remove('selected');
                }
                event.target.classList.add('selected');
            },

            changeMode(option) {
                //console.log(lock.id);
                if (option == 'lock') {
                    lock.style.display = 'none';
                    unlock.style.display = 'block';
                } else {
                    lock.style.display = 'block';
                    unlock.style.display = 'none';
                }

            },

            showpopup(e) {
                e.target.closest(".drawflow-node").style.zIndex = "9999";
                e.target.children[0].style.display = "block";
                //document.getElementById("modalfix").style.display = "block";

                //e.target.children[0].style.transform = 'translate('+translate.x+'px, '+translate.y+'px)';
                this.transform = editor.precanvas.style.transform;
                this.editor.precanvas.style.transform = '';
                this.editor.precanvas.style.left = editor.canvas_x + 'px';
                this.editor.precanvas.style.top = editor.canvas_y + 'px';
                console.log(this.transform);

                //e.target.children[0].style.top  =  -editor.canvas_y - editor.container.offsetTop +'px';
                //e.target.children[0].style.left  =  -editor.canvas_x  - editor.container.offsetLeft +'px';
                this.editor.editor_mode = "fixed";
            },

            resize(newRect) {
                this.width = newRect.width;
                this.height = newRect.height;
                this.top = newRect.top;
                this.left = newRect.left;
            }
            ,
            updateDateAndSlot() {
                this.TimeSlot = ['8:00-8:30', '8:30-9:00', '9:00-9:30', '19:00-19:30', '19:30-20:00', '20:00-20:30'];

                this.$refs.dialog.save(this.form.date);
                for (let i = 0; i < this.meetingList.length; i++) {
                    if (this.meetingList[i]['date'] === this.form.date) {
                        this.TimeSlot = [];
                        if (this.meetingList[i]['8:00-8:30'] === "0")
                            this.TimeSlot.push('8:00-8:30');
                        if (this.meetingList[i]['8:30-9:00'] === "0")
                            this.TimeSlot.push('8:30-9:00');
                        if (this.meetingList[i]['9:00-9:30'] === "0")
                            this.TimeSlot.push('9:00-9:30');
                        if (this.meetingList[i]['19:00-19:30'] === "0")
                            this.TimeSlot.push('19:00-19:30');
                        if (this.meetingList[i]['19:30-20:00'] === "0")
                            this.TimeSlot.push('19:30-20:00');
                        if (this.meetingList[i]['20:00-20:30'] === "0")
                            this.TimeSlot.push('20:00-20:30');
                    }
                    //     (this.meetingList[i]['8:00-8:30'] + this.meetingList[i]['8:30-9:00'] + this.meetingList[i]['9:00-9:30']
                    //     +this.meetingList[i]['19:00-19:30'] + this.meetingList[i]['19:30-20:00']  + this.meetingList[i]['20:00-20:30'] ) === "111111")
                    // {
                    //     this.notAvailableDates.push(this.meetingList[i]['date'])
                    // }
                }

            }
            ,
            allowedDates(a) {
                return !this.notAvailableDates.includes(a);
            }
            ,


            overAllValidate() {
                if (this.$refs.form2.validate()) {
                    this.dialogSend = true;
                }
            }
            ,

            validate() {
                if (this.$refs.form.validate()) {
                    this.e1 = 2
                }
            }
            ,
            changeUnit() {
                this.dialogUnit = true;
                this.formUnitArray = [];
                for (let i = 0; i < this.form.unitnum; i++) {
                    this.formUnitArray.push({
                        'unit_Nom': '',
                        'name': '',
                        'family': '',
                        'cellnumber': '',
                        'area': '',
                        'postalcode': ''
                    });
                }
            }
            ,

            reset() {
                this.$refs.form.reset();
            }
            ,

            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            }
            ,
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,

            addDemoForm() {
                this.dialogSend = false;
                this.busy = true;
                axios.post('../getDemo', this.form)
                    .then(
                        response => {
                            this.snackkbarOkey = true;
                        }).catch(errors => {
                    this.snackkbarNokey = true;
                }).finally(() => {
                    this.busy = false;

                });
            }
            ,

            extendPlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ExtendPlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'msg': 'Send request to extend plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogExtend = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
            ,

            changePlan() {

                this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'ChangePlan',
                    'status': 'submitted',
                    'issuetracking': currentTime.getTime(),
                    'message': [{
                        'userType': 'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'new_plan': this.changedPlan,
                        'msg': 'Request a change plan.',
                    }],
                };
                axios.post('../msg', msg)
                    .then(response => {
                        this.dialogChangePlan = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>


    :root {
        --border-color: #cacaca;
        --background-color: #ffffff;
        /*--dfNodeBackgroundColor: rgba(200, 124, 124, 1);*/
        --background-box-title: #f7f7f7;
        --dfNodeType: flex;
        --dfNodeTypeFloat: none;
        --dfNodeBackgroundColor: #7b5d78ff;
        --dfNodeTextColor: #000000;
        --dfNodeBorderSize: 2px;
        /*--dfNodeBorderColor: #000000;*/
        --dfNodeBorderColor: #cacaca;
        /*--dfNodeBorderRadius: 4px;*/
        --dfNodeBorderRadius: 50%;
        --dfNodeMinHeight: 40px;
        --dfNodeMinWidth: 160px;
        --dfNodePaddingTop: 15px;
        --dfNodePaddingBottom: 15px;
        --dfNodeBoxShadowHL: 0px;
        --dfNodeBoxShadowVL: 2px;
        --dfNodeBoxShadowBR: 15px;
        --dfNodeBoxShadowS: 2px;
        --dfNodeBoxShadowColor: #000000;

        --dfNodeHoverBackgroundColor: #ffffff;
        --dfNodeHoverTextColor: #000000;
        --dfNodeHoverBorderSize: 2px;
        --dfNodeHoverBorderColor: #000000;
        --dfNodeHoverBorderRadius: 4px;

        --dfNodeHoverBoxShadowHL: 0px;
        --dfNodeHoverBoxShadowVL: 2px;
        --dfNodeHoverBoxShadowBR: 15px;
        --dfNodeHoverBoxShadowS: 2px;
        --dfNodeHoverBoxShadowColor: #4ea9ff;

        /*--dfNodeSelectedBackgroundColor: #000000;*/
        --dfNodeSelectedBackgroundColor: #00a86b;
        --dfNodeSelectedTextColor: #ffffff;
        --dfNodeSelectedBorderSize: 2px;
        /*--dfNodeSelectedBorderColor: #000000;*/
        --dfNodeSelectedBorderColor: #00a86b;
        --dfNodeSelectedBorderRadius: 4px;

        --dfNodeSelectedBoxShadowHL: 0px;
        --dfNodeSelectedBoxShadowVL: 2px;
        --dfNodeSelectedBoxShadowBR: 15px;
        --dfNodeSelectedBoxShadowS: 2px;
        --dfNodeSelectedBoxShadowColor: #4ea9ff;
    }

    .drag-drawflow {
        /*line-height: 50px;*/
        top: 1px;
        padding-left: 20px;
        cursor: move;
        user-select: none;
        /*border-right: 1px solid #cacaca;*/
    }

    .them-edit-link {
        position: absolute;
        top: 10px;
        right: 100px;
        color: black;
        font-size: 40px;
    }

    .them-edit-link a {
        text-decoration: none;
    }

    .github-link {
        position: absolute;
        top: 10px;
        right: 20px;
        color: black;
    }

    /*.col {*/
    /*    overflow: auto;*/
    /*    width: 300px;*/
    /*    height: 100%;*/
    /*    border-right: 1px solid #cacaca;*/

    /*}*/
    /*.wrapper {*/
    /*    width: 100%;*/
    /*    height: calc(100vh - 67px);*/
    /*    display: flex;*/
    /*}*/

    .menu {
        position: absolute;
        height: 40px;
        display: block;
        background: white;
        width: 100%;
    }

    .menu ul {
        padding: 0px;
        margin: 0px;
        line-height: 40px;
    }

    .menu ul li {
        display: inline-block;
        margin-left: 10px;
        border-right: 1px solid #cacaca;
        padding-right: 10px;
        line-height: 40px;
        cursor: pointer;
    }

    .menu ul li.selected {
        font-weight: bold;
    }

    .btn-export {
        float: right;
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-weight: bold;
        border: 1px solid #0e5ba3;
        background: #4ea9ff;
        padding: 5px 10px;
        border-radius: 4px;
        cursor: pointer;
        z-index: 5;
    }

    .btn-clear {
        float: right;
        position: absolute;
        top: 10px;
        right: 85px;
        color: white;
        font-weight: bold;
        border: 1px solid #96015b;
        background: #e3195a;
        padding: 5px 10px;
        border-radius: 4px;
        cursor: pointer;
        z-index: 5;
    }

    .swal-wide {
        width: 80% !important;
    }

    .btn-lock {
        float: right;
        position: absolute;
        bottom: 10px;
        right: 140px;
        display: flex;
        font-size: 24px;
        color: white;
        padding: 5px 10px;
        background: #555555;
        border-radius: 4px;
        border-right: 1px solid #cacaca;
        z-index: 5;
        cursor: pointer;
    }

    .bar-zoom {
        float: right;
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        font-size: 24px;
        color: white;
        padding: 5px 10px;
        background: #555555;
        border-radius: 4px;
        border-right: 1px solid #cacaca;
        z-index: 5;
    }

    .bar-zoom svg {
        cursor: pointer;
        padding-left: 10px;
    }

    .bar-zoom svg:nth-child(1) {
        padding-left: 0px;
    }


    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    #drawflow {
        background: #ffffff;
        background-size: 25px 25px;
        background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px),
        linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
        width: 100%;
        height: 800px;
        /*border: 1px solid red;*/

    }

    @media only screen and (max-width: 768px) {
        .col {
            width: 50px;
        }

        .col .drag-drawflow span {
            display: none;
        }

        #drawflow {
            width: calc(100vw - 51px);
        }
    }


    /* Editing Drawflow */

    /*.drawflow .drawflow-node {*/
    /*    !*background: #ffffff;*!*/
    /*    !*border: 1px solid #cacaca;*!*/
    /*    !*-webkit-box-shadow: 0 2px 15px 2px #cacaca;*!*/
    /*    !*box-shadow: 0 2px 15px 2px #cacaca;*!*/
    /*    !*padding: 0px;*!*/
    /*    !*width: 200px;*!*/

    /*    display: var(--dfNodeType);*/
    /*    background: white;*/
    /*    color: var(--dfNodeTextColor);*/
    /*    border: var(--dfNodeBorderSize)  solid var(--dfNodeBorderColor);*/
    /*    border-radius: var(--dfNodeBorderRadius);*/
    /*    min-height: var(--dfNodeMinHeight);*/
    /*    width: auto;*/
    /*    min-width: var(--dfNodeMinWidth);*/
    /*    padding-top: var(--dfNodePaddingTop);*/
    /*    padding-bottom: var(--dfNodePaddingBottom);*/
    /*    -webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);*/
    /*    box-shadow:  var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS) var(--dfNodeBoxShadowColor);*/

    /*}*/
    .drawflow .drawflow-node {
        /* ... other properties ... */
        background-color: purple; /* Default non-selected node color */
        border-radius: 50%; /* Circle shape */
        /* Make sure width and height are the same to form a perfect circle */
        width: 100px; /* Adjust as needed */
        height: 100px; /* Adjust as needed */
        /* Adjust your flex items alignment if necessary */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .drawflow .drawflow-node.selected {
        background-color: green;
        border-color: var(--dfNodeSelectedBorderColor);
        /*background: white;*/
        /*border: 1px solid #4ea9ff;*/
        -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
        box-shadow: 0 2px 20px 2px #4ea9ff;
    }

    .drawflow .drawflow-node.selected .title-box {
        color: #22598c;
        /*border-bottom: 1px solid #4ea9ff;*/
    }

    .drawflow .connection .main-path {
        stroke: #4ea9ff;
        stroke-width: 3px;
    }

    .drawflow .drawflow-node .input, .drawflow .drawflow-node .output {
        height: 15px;
        width: 15px;
        border: 2px solid #cacaca;
    }

    .drawflow .drawflow-node .input:hover, .drawflow .drawflow-node .output:hover {
        background: #4ea9ff;
    }

    .drawflow .drawflow-node .output {
        right: 10px;
    }

    .drawflow .drawflow-node .input {
        left: -10px;
        background: white;
    }

    .drawflow > .drawflow-delete {
        border: 2px solid #43b993;
        background: white;
        color: #43b993;
        -webkit-box-shadow: 0 2px 20px 2px #43b993;
        box-shadow: 0 2px 20px 2px #43b993;
    }

    .drawflow-delete {
        border: 2px solid #4ea9ff;
        background: white;
        color: #4ea9ff;
        -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
        box-shadow: 0 2px 20px 2px #4ea9ff;
    }

    .drawflow-node .title-box {
        height: 50px;
        line-height: 50px;
        background: #f7f7f7;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 4px 4px 0px 0px;
        padding-left: 10px;
    }

    .drawflow .title-box svg {
        position: initial;
    }

    .drawflow-node .box {
        padding: 10px 20px 20px 20px;
        font-size: 14px;
        color: #555555;

    }

    .drawflow-node .box p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .drawflow-node.welcome {
        width: 250px;
    }

    .drawflow-node.slack .title-box {
        border-radius: 4px;
    }

    .drawflow-node input, .drawflow-node select, .drawflow-node textarea {
        border-radius: 4px;
        border: 1px solid #cacaca;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        width: 158px;
        color: #555555;
    }

    .drawflow-node textarea {
        height: 100px;
    }


    .drawflow-node.personalized {
        background: red;
        height: 200px;
        text-align: center;
        color: white;
    }

    .drawflow-node.personalized .input {
        background: yellow;
    }

    .drawflow-node.personalized .output {
        background: green;
    }

    .drawflow-node.personalized.selected {
        background: blue;
    }

    .drawflow .connection .point {
        stroke: #cacaca;
        stroke-width: 2;
        fill: white;

    }

    .drawflow .connection .point.selected, .drawflow .connection .point:hover {
        fill: #4ea9ff;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 7;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.7);

    }

    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 400px; /* Could be more or less, depending on screen size */
    }

    /* The Close Button */
    .modal .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        .modal-content {
            width: 80%;
        }
    }

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .headline2 {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    .GoldText {
        color: #fcc203;
    }

    .Price {
        font-size: 1.5rem;
    }

    .full-height {
        height: 100%;
    }

</style>

