<template>
    <div class="test">
        <v-card
            width="95%"
            class="custom-top-margin mx-auto rounded-card mb-10"
            elevation="20"

        >
            <template>
                <v-app-bar color="#7B5D6A" window style="height: 95px;">
                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'InsurerOverview'}" icon color="white" large>
                            <v-icon large>dashboard</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Overall review
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'LowCodePlatform'}" icon color="white" large>
                            <v-icon large>code</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Low Code Platform
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'SoftwareMarketplace'}" icon color="white" large>
                            <v-icon large>mdi-cloud-download</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Software Marketplace
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'HardwareMarketplace'}" icon color="white" large>
                            <v-icon large>mdi-chip</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Hardware Marketplace
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent"
                            class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'SettingBusiness'}" icon color="white" large>
                            <v-icon large>mdi-cog</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt; align-content: center">Setting
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-app-bar>

                <v-main class="" style="background-color: #f5f5f5;">
<!--                    <v-container fluid class="mx-auto">-->
                        <router-view></router-view>
<!--                    </v-container>-->
                </v-main>
            </template>
        </v-card>
    </div>
</template>

<script>
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    import VueApexCharts from 'vue-apexcharts';
    import VueMoment from 'vue-jalali-moment';
    import overallReview from "./InsurerProfile";
    import DashboardTab2 from "./InsurerProfile";
    import LowCodePlatform from "./InsurerLCPlatform";
    import SoftwareMarketplace from "./ServiceMarketplace";
    import HardwareMarketplace from "./HardwareMarketplace";
    import SettingBusiness from "./Setting";

    export default {
        components: {
            HardwareMarketplace,
            SoftwareMarketplace,
            SettingBusiness,
            LowCodePlatform,
            DashboardTab2,
            overallReview,
            VueFunnelGraph,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'business', 'businessUniqueAddress'
        ],
        data: () => ({
            test: 1,
            tab: null,
            items: [
                'Appetizers', 'Entrees', 'Deserts', 'Cocktails',
            ],
            search: null,
            rare: null,
            review: null,
            tab1: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            lateLoginErrorNum: '',
            locationalert: false,
            form: {
                'name': '',
                'access_level': '',
                'national_ID': '',
                'shenasname': '',
                'tomorrow_vacation': '',
                'car_number': '',
                'pic': ''
            },
            dateTommorow: '',
            dialogVocation: false,
            seriesCone: [{
                name: 'پیام های صحیح',
                type: 'column',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
            }, {
                name: 'پیام های ارسالی',
                type: 'area',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
            }, {
                name: 'رضایت افراد',
                type: 'line',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
            }],
            chartOptionsCone: {
                chart: {
                    height: 350,
                    width: 250,
                    type: 'line',
                    stacked: false,
                    animations: {
                        enabled: false
                    },
                },
                stroke: {
                    width: [0, 2, 5],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },
                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: ['Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July',
                    'August', 'September', 'October', 'November', 'December'
                ],
                markers: {
                    size: 0
                },
                xaxis: {},
                yaxis: {
                    title: {
                        text: 'Level',
                    },
                    min: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + "";
                            }
                            return y;
                        }
                    }
                }
            },
            headers1: [
                {text: 'ID people', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                {text: 'Spatial error value', value: 'location', sortable: false,},
            ],
            headers2: [
                {text: 'id people', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                {text: 'location error value', value: 'location', sortable: false,},],
            headers3: [
                {text: 'id people', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                {text: 'Number of dogs taken', value: 'location', sortable: false,},],
            desserts1: [
                {
                    id: '1',
                    name: 'Keyvan Shafiee',
                    email: '09127752017',
                    location: 20,
                },
                {
                    id: '2',
                    name: 'Alireza Shirdel',
                    email: '09124020821',
                    location: 21,
                },
            ],
            desserts2: [
                {
                    id: '1',
                    name: 'Keyvan Shafiee',
                    email: '09127752017',
                    location: 14,
                },
                {
                    id: '2',
                    name: 'Ehsan Shahsavari',
                    email: '09124020821',
                    location: 19,
                },
            ],
            desserts3: [
                {
                    id: '1',
                    name: 'Mehran Sobhani',
                    email: '09127752017',
                    location: 15,
                },
                {
                    id: '2',
                    name: 'Saeed Hossein Khani',
                    email: '09124020821',
                    location: 8,
                },
            ],
            alignments: [
                'start',
                'center',
                'end',
            ],
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            seriesChart1: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan',
                    y: null
                },
                    {
                        x: '2 Jan',
                        y: 44
                    },
                    {
                        x: '3 Jan',
                        y: 31
                    },
                    {
                        x: '4 Jan',
                        y: 38
                    },
                    {
                        x: '5 Jan',
                        y: null
                    },
                    {
                        x: '6 Jan',
                        y: 32
                    },
                    {
                        x: '7 Jan',
                        y: 55
                    },
                    {
                        x: '8 Jan',
                        y: 51
                    },
                    {
                        x: '9 Jan',
                        y: 67
                    },
                    {
                        x: '10 Jan',
                        y: 22
                    },
                    {
                        x: '11 Jan',
                        y: 34
                    },
                    {
                        x: '12 Jan',
                        y: null
                    },
                    {
                        x: '13 Jan',
                        y: null
                    },
                    {
                        x: '14 Jan',
                        y: 11
                    },
                ],
            }],
            seriesChart2: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan.',
                    y: null
                },
                    {
                        x: '2 Jan.',
                        y: 31
                    },
                    {
                        x: '3 Jan.',
                        y: 12
                    },
                    {
                        x: '4 Jan.',
                        y: 4
                    },
                    {
                        x: '5 Jan.',
                        y: null
                    },
                    {
                        x: '6 Jan.',
                        y: 9
                    },
                    {
                        x: '7 Jan.',
                        y: 63
                    },
                    {
                        x: '8 Jan.',
                        y: 13
                    },
                    {
                        x: '9 Jan.',
                        y: 18
                    },
                    {
                        x: '10 Jan.',
                        y: 19
                    },
                    {
                        x: '11 Jan.',
                        y: 51
                    },
                    {
                        x: '12 Jan.',
                        y: null
                    },
                    {
                        x: '13 Jan.',
                        y: null
                    },
                    {
                        x: '14 Jan.',
                        y: 23
                    },
                ],
            }],
            seriesChart3: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan.',
                    y: null
                },
                    {
                        x: '2 Jan.',
                        y: 18
                    },
                    {
                        x: '3 Jan.',
                        y: 10
                    },
                    {
                        x: '4 Jan.',
                        y: 9
                    },
                    {
                        x: '5 Jan.',
                        y: null
                    },
                    {
                        x: '6 Jan.',
                        y: 18
                    },
                    {
                        x: '7 Jan.',
                        y: 51
                    },
                    {
                        x: '8 Jan.',
                        y: 9
                    },
                    {
                        x: '9 Jan.',
                        y: 17
                    },
                    {
                        x: '10 Jan.',
                        y: 23
                    },
                    {
                        x: '11 Jan.',
                        y: 3
                    },
                    {
                        x: '12 Jan.',
                        y: null
                    },
                    {
                        x: '13 Jan.',
                        y: null
                    },
                    {
                        x: '14 Jan.',
                        y: 49
                    },
                ],
            }],
            chartOptionsChart1: {
                chart: {
                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 5,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            chartOptionsChart2: {
                chart: {
                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#008FFB'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            chartOptionsChart3: {
                chart: {
                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            seriesPi: [14, 23, 21, 17],
            chartOptionsPi: {
                labels: ['male dog, adult', 'adult female dog', 'male dog, puppy', 'female dog puppy'],
                chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },
            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },
                xaxis: {
                    categories: ['vaccine 1', 'vaccine 2', 'vaccine 3', 'sterile'],
                    position: 'top'
                }
            },
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,
            labels: ['Impressions', 'Add To Cart', 'Buy'],
            values: [
                [3000],
                [1700],
                [600]
            ],
            colors: [
                ['#FFB178', '#FF3C8E'],
                ['#A0BBFF', '#EC77FF'],
                ['#A0F9FF', '#7795FF']
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,
            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]
        }),
        created() {
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;
        },
        async mounted() {
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            this.access_level = this.getAccessLevel();
            axios.get('../user/' + this.$store.state.users.id)
                .then(response => {
                    this.lateLoginErrorNum = response.data.last_login_error;
                    if (response.data.gps_off_error == 1) {
                        this.locationalert = true;
                    } else {
                        this.locationalert = false;
                    }
                })
                .catch(errors => {
                    console.log(this.form);
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
        },
        methods: {
            SendCurrentLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        let newLocation = {
                            Lat: position.coords.latitude,
                            Long: position.coords.longitude
                        };
                        this.$store.commit("updateLocation", newLocation);
                        axios.post('../saveUserLocation', {
                            ID: this.$store.state.users.id,
                            Long: position.coords.longitude,
                            Lat: position.coords.latitude,
                        })
                            .then(response => {
                                console.log('Send');
                                this.locationalert = false;
                                this.$router.go();
                            })
                            .catch(errors => {
                                console.log('error');
                            });
                    })
                } else {
                    this.locationalert = true;
                }
            },
            increase() {
                this.height += 10
            },
            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                axios.put('../user/' + this.$store.state.users.id, this.form)
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'Management'
                else if (this.$store.state.users.access_level == "2") return 'Expert'
                else if (this.$store.state.users.access_level == "3") return 'Support'
                else if (this.$store.state.users.access_level == "4") return 'Responsible'
                else if (this.$store.state.users.access_level == "5") return 'Technician'
                else return 'Unapproved user'
            },
        },
        computed: {
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .custom-top-margin {
        margin-top: 15px;
    }
    .rounded-card {
        border-radius: 20px !important;
        overflow: hidden;
    }
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }
    .v-card-text {
        font-family: IRANSans !important;
    }
    .v-container {
        font-family: IRANSans !important;
    }
    .rounded-form {
        border-radius: 20px !important;
    }
    .test {
        font-size: 0.875rem;
    }
    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }
    .centered-input >>> input {
        text-align: center
    }
</style>
