<template>
    <div class=" flex items-center justify-center rounded-full text-white bg-blue-400 w-10 h-10 border border-gray-400">
        Fig
    </div>
</template>

<script>
    export default {
        name: "DogPhotoCircle"
    }
</script>

<style scoped>

</style>
