<template>

    <div style="width: 100%; height: 100%;">
        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <MglGeojsonLayer
                sourceId="mySource"
                :source="geoJsonSource"
                layerId="myLayer"
                :layer="geoJsonlayer"
            />
            <MglMarker v-for="usersinfo in users" :key="usersinfo"
                       :coordinates.sync="usersinfo.LatLong"
                       :color="colors">
                <MglPopup>
                    <VCard>
                        <div>I am {{usersinfo.first_name}}, the last activity time was {{usersinfo.Time}}.</div>
                    </VCard>
                </MglPopup>
            </MglMarker>
            <MglMarker
                :coordinates.sync="coordinates"
                color="red"
            >
            <MglPopup>
                <VCard>
                    <div><a v-bind:href="link" target="_blank" title="Opens in a new window"><em>Navigator to reach home</em></a></div>
                </VCard>
            </MglPopup>
            </MglMarker>
        </MglMap>
    </div>
</template>

<script>
    import {MglMap, MglMarker, MglPopup, MglGeojsonLayer, MglGeolocateControl, MglNavigationControl,} from "vue-mapbox";

    export default {
        name: "mapirMarker",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
        },
        data() {
            return {
                users:'',
                usersinfos:'',
                colors: "green",
                link:'',
                coordinates: [51.420296, 35.732379],
                centers: [51.420296, 35.732379],
                markerCoordinates: [51.420296, 35.732379],
                markerCoordinates1: [[52.420296, 35.732379],[53.420296, 35.732379]],
                markerCoordinates2: [53.420296, 35.732379],
                // mapirToken: "<Your API Key>"
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",
                geoJsonSource: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            [51.35061264038086, 35.74888069888655],
                                            [51.34992599487305, 35.72352080874787],
                                            [51.39352798461914, 35.678912411239935],
                                            [51.4683723449707, 35.69048511140501],
                                            [51.48468017578124, 35.7491593341318],
                                            [51.39919281005859, 35.78244922192265],
                                            [51.35061264038086, 35.74888069888655]
                                        ]
                                    ]
                                }
                            },
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [
                                        [
                                            [52.35061264038086, 34.74888069888655],
                                            [52.34992599487305, 34.72352080874787],
                                            [52.39352798461914, 34.678912411239935],
                                            [52.4683723449707, 34.69048511140501],
                                            [52.48468017578124, 34.7491593341318],
                                            [52.39919281005859, 34.78244922192265],
                                            [52.35061264038086, 34.74888069888655]
                                        ]
                                    ]
                                }
                            }
                        ]
                    }
                },
                geoJsonlayer: {
                    id: "myLayer",
                    type: "fill",
                    source: "mySource",
                    paint: {
                        "fill-color": "#888888",
                        "fill-opacity": 0.4
                    }
                }
            };
        },
        created() {
            this.fetchData();
            this.getNewData();

        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/alluserlocation')
                    .then(response => {
                        this.users = response.data[0].data;
                        // this.usersinfos = this.users[1].LatLong;
                        console.log(this.users[1]);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            getNewData() {
                this.busy = true;
                axios.get('/report/index/'+ this.$route.params.id)
                    .then(response => {
                        this.usersinfos = response.data;
                        this.coordinates = [Number(this.usersinfos.Long), Number(this.usersinfos.Lat)];
                        this.link = "http://www.google.com/maps/place/" + this.usersinfos.Lat + "," + this.usersinfos.Long;
                        //this.usersinfos = this.users[1].LatLong;
                        console.log(this.users);
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
        }
    }
</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>


