<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-icon left>transfer_within_a_station</v-icon>
                        Transfer Funds
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-select
                                        :items="accounts"
                                        label="From Account"
                                        v-model="transfer.fromAccount"
                                        :rules="[v => !!v || 'Account is required']"
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        :items="accounts"
                                        label="To Account"
                                        v-model="transfer.toAccount"
                                        :rules="[v => !!v || 'Account is required']"
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Amount"
                                        v-model="transfer.amount"
                                        :rules="[v => !!v || 'Amount is required', v => v > 0 || 'Amount must be greater than zero']"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="transfer.date"
                                                label="Transfer Date"
                                                prepend-icon="event"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="transfer.date"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Transfer Notes (Optional)"
                                        v-model="transfer.notes"
                                        outlined
                                        dense
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <v-btn
                                        color="primary"
                                        @click="submit"
                                        :disabled="!valid"
                                    >
                                        Transfer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-title>Transaction Summary</v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>From: {{ transfer.fromAccount }}</v-list-item-title>
                                    <v-list-item-title>To: {{ transfer.toAccount }}</v-list-item-title>
                                    <v-list-item-title>Amount: {{ transfer.amount }}</v-list-item-title>
                                    <v-list-item-title>Date: {{ transfer.date }}</v-list-item-title>
                                    <v-list-item-title v-if="transfer.notes">Notes: {{ transfer.notes }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            valid: false,
            menu: false,
            transfer: {
                fromAccount: '',
                toAccount: '',
                amount: '',
                date: '',
                notes: '',
            },
            accounts: [
                'Account 1',
                'Account 2',
                'Account 3',
                'Account 4'
            ]
        }),
        methods: {
            submit() {
                if (this.$refs.form.validate()) {
                    // Handle the transfer logic here
                    console.log('Transfer details:', this.transfer);
                }
            }
        }
    }
</script>

<style scoped>
    .v-card-title {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .v-card-title v-icon {
        margin-right: 10px;
    }
</style>
