<template>
    <div class="pt-16">

        <v-card max-width="600px" class="mx-auto rounded-form" outlined >
            <v-card-actions class="justify-center">
            <v-card width="90%" align="center">

            <div id="filepond" >
                <file-pond
                    allowImageResize="true"
                    imageResizeTargetWidth="400"
                    imageResizeTargetHeight="400"
                    imageResizeUpscale="false"
                    allowImageTransform="true"
                    imageTransformOutputMimeType="image/png"
                    name="public"
                    ref="pond"
                    class-name="my-pond"
                    label-idle="... Please take a photo horizontally"
                    allow-multiple="false"
                    accepted-file-types="image/png, image/jpeg, image/jpg, image/*"
                    server= "report/upload"
                    allowFileRename="true"
                    captureMethod="environment"
                    v-on:init="handleFilePondInit"
                    v-on:processfile="handleProcessFile">
                </file-pond>
            </div>
            </v-card>
            </v-card-actions>
            <v-card-title class="layout justify-center">
                Request maintenance service
            </v-card-title>
            <v-img :src="require('../../../assets/iamges/HomeService.png')" contain max-height="300px">

            </v-img>
            <v-card-text>
                <v-autocomplete
                    :items="building_set"
                    item-text="address"
                    item-value="id"
                    v-model="building_selected_id"
                    :error-messages="errors" label="Building selection"
                    placeholder="Please enter your building.">
                </v-autocomplete>
                <v-textarea  label="Description"  placeholder="Please enter more details." v-model="textCommnet "></v-textarea>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="m-1" >Cancel </v-btn>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="busy" @click="dialogChangePlan='true'" class="m-1" color="primary" depressed>
                        Send
                    </v-btn>
                </v-card-actions>
<!--                <v-btn depressed color="blue" class="m-2" @click="requestService" :loading="busy">ارسال </v-btn>-->
                <v-snackbar
                    v-model="snackbar"
                    :top=true
                    timeout=10000
                >
                    {{ text }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="pink"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-card-actions>
            <template>
                <v-dialog
                    v-model="dialogChangePlan"
                    width="500"
                >
                    <v-card>
                        <v-card-title class="headline grey lighten-2">
                            Service request submission form
                        </v-card-title>

                        <v-card-text>
                            I confess that all the information entered is correct and  Insurance will not be held responsible for any possible violations.
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="requestService"
                                color="green"
                                text
                            >
                                Yes, I agree
                            </v-btn>
                            <v-btn
                                @click="dialogChangePlan=false"
                                color="red"
                                text
                            >
                                No
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-card>

    </div>
</template>

<script>

    import vueFilePond , { setOptions } from 'vue-filepond';
    let currentDate = Date.now()
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {
        created(){

        },
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond
        },
        props:{
            savingSuccessful: false
        },
        data() {
            return {
                video: {},
                canvas: {},
                captures: [],
                snackbar: false,
                text: 'Hello, I\'m a snackbar',
                sampleFig:'',
                picID2:'',



            }
        },
        data:function () {
            return{
                dialogChangePlan: false,
                textCommnet:'',
                building_set:[],
                building_selected_id:'',
                //myFiles: ['index.html'],
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name':'','comments':'', 'cell_number':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                    },
                errors: null,
                responses:null,
                busy:false,
                snackbar: false,
                text: '',
                sampleFig:'',
                picID2:''

            }
        },

        mounted: function(){
            axios.get('citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.building_set = response.data;
                    // this.snackkbar = true;
                })
                .catch(errors => {
                    console.log(this.form);
                    console.log(this.errors);
                }).finally(() => {
            });
            // this.Bol = true;
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(position => {
                    this.form.catched_lon = position.coords.longitude;
                    this.form.catched_lat = position.coords.latitude;
                })
            };
        },
        computed:{
            // },
            picID:function (file) {
                this.picID2 = file;
            }
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods:{
            updateSetOption: function(){
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            getCurrentLocation: function(){
                if(navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                    })
                }
            },

            callback (msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function(error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            requestService() {
                this.updateSetOption();
                this.busy=true;
                    this.getCurrentLocation();
                // this.busy = true;
                let currentTime = new Date();
                let msg = {
                    'type': 'RequestService',
                    'status': 'submitted',
                    'issue_tracking':currentTime.getTime(),
                    'message': [{'userType':'citizen',
                        'id': '',
                        'building_id': this.building_selected_id,
                        'msg': this.textCommnet,
                        'pic': '',
                    }],
                };
                axios.post('msg', msg)
                    .then(response => {
                        this.dialogChangePlan = false;
                        this.snackkbar = true;
                    })
                    .catch(errors => {

                    }).finally(() => {
                    this.busy = false;
                });
                // if ( this.form.catched_lon ===''){
                //     this.snackbar = true;
                //     this.text = 'برای ثبت موقعیت مکانی، لطفا مکانیاب خود را فعال نمایید.';
                //     this.busy=false;
                // }
                // else
                // {
                //     axios.post('../report/message', this.form)
                //         .then( response => {
                //             console.log(response);
                //             this.snackbar = true;
                //             this.text = 'ممنون از همکاری شما. گزارش کار به محضر شما خواهد رسید.';
                //             // this.text = this.form.fig;
                //         })
                //         .catch(errors =>{
                //             this.text = errors.response.data.errors;
                //
                //             //'name':'','comments':'', 'cellnumber':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                //             this.snackbar = true;
                //             // this.text = 'لطفا فیلدها را کامل پرکنید';                            this.text = 'لطفا فیلدها را کامل پرکنید';
                //
                //             //this.text = this.form.fig;
                //             // console.log(this.form);
                //             // this.errors = errors.response.data.errors;
                //             console.log(errors);
                //         }).finally(()=>{
                //         this.busy=false;
                //     });
                // }
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }
    #video {
        background-color: #000000;
    }
    #canvas {
        display: none;
    }
    li {
        display: inline;
        padding: 5px;
    }
    .rounded-form{
        border-radius: 28px !important;

    }
    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/
</style>
