<template>
    <div>
        <v-row class="my-0" dense>
            <v-col cols="6">
                <v-text-field label="Name" class="text-field mx-2 my-0" :value="business.name" dense></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="Plan" class="text-field mx-2" :value="business.plan_type" append-icon="gold" dense></v-text-field>
            </v-col>
        </v-row>
        <v-row class="my-0" dense>
            <v-col cols="6">
                <v-text-field label="No. of employees" class="text-field mx-2" :value="business.users_count" dense></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field label="No. of customers" class="text-field mx-2" :value="business.customers_count" dense></v-text-field>
            </v-col>
        </v-row>
        <v-row class="my-0" dense>
            <v-col cols="12">
                <v-text-field label="Address" class="text-field2 text-field mx-2" :value="business.address + ', ' + business.city" dense></v-text-field>
            </v-col>
        </v-row>
        <v-row class="my-0" dense>
            <v-col sm="6" cols="12">
                <v-card-actions class="justify-center" style="width: 100%;">
                    <v-btn rounded class="mx-auto white--text text-no-wrap" :href="formatUrl('hubbcast.com/business/@' + business.unique_address)" dense color="#b2829dff" style="font-size: 9px; padding: 1px 3px; text-transform: capitalize;">
                        <span class="text-overflow">Hubbcast Business link</span>
                    </v-btn>
                </v-card-actions>
            </v-col>
            <v-col sm="6" cols="12">
                <v-card-actions class="justify-center" style="width: 100%;">
                    <v-btn rounded class="mx-auto white--text text-no-wrap capital" :href="formatUrl('hubbcast.com/customer/@' + business.unique_address)" dense color="#b2829dff" style="font-size: 9px; padding: 1px 3px; text-transform: capitalize;">
                        <span class="text-overflow">Hubbcast Customer link</span>
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row class="my-0" dense>
            <v-col sm="6" cols="12">
                <v-card-actions class="justify-center" style="width: 100%;">
                    <v-btn rounded class="mx-auto white--text text-no-wrap" :href="getCustomDomainHref(business.business_tenant)" dense color="#b2829dff" style="font-size: 10px; padding: 1px 3px;text-transform: capitalize;">
                        <span class="text-overflow">Business Custom Link</span>
                    </v-btn>
                </v-card-actions>
            </v-col>

            <v-col sm="6" cols="12">
                <v-card-actions class="justify-center" style="width: 100%;">
                    <v-btn rounded class="mx-auto white--text text-no-wrap" :href="getCustomDomainHref(business.customer_tenant)" dense color="#b2829dff" style="font-size: 10px; padding: 1px 3px; text-transform: capitalize;">
                        <span class="text-overflow">Customer Custom Link</span>
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-row class="my-0" dense>
            <v-col cols="12">
                <v-card-actions class="justify-center">
                    <v-btn rounded class="mx-auto white--text" :href="formatUrl(business.website)" dense color="#b2829dff" style="text-transform: capitalize ">Website Link</v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: ['business'],
        methods: {
            formatUrl(url) {
                if (!/^https?:\/\//i.test(url)) {
                    url = 'http://' + url;
                }
                return url;
            },
            getCustomDomainHref(tenant) {
                return tenant && tenant.custom_domain
                    ? this.formatUrl(tenant.custom_domain)
                    : null;
            }
        }
    }
</script>
