var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"#f2c4d3","timeout":5000,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticStyle:{"color":"#333333"}},[_vm._v("\n                    The inbox was updated on "+_vm._s(new Date())+".\n                ")])]),_vm._v(" "),_c('v-container',{staticClass:"mb-6 mx-auto",attrs:{"align":"center"}},[_c('br'),_vm._v(" "),_c('v-toolbar',{attrs:{"dense":"","color":"gray"}},[_c('v-row',{staticClass:"mx-0"},[_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowAll()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("\n                                    email\n                                ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("View All Messages")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowUnread()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("$vuetify.icons.unread")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(" View Unread Messages ")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowInprogress()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#ff589bff"}},[_vm._v("hourglass_empty")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(" View In-progress Tasks ")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowUnderworking()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#fb9f61ff"}},[_vm._v("schedule")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(" View Active Tasks ")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowFinished()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#827717"}},[_vm._v("check_circle")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(" View Approved Tasks")])])],1),_vm._v(" "),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ShowVerified()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("\n                                    check_circle_outline\n                                ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(" View Completed Tasks ")])])],1)],1)],1),_vm._v(" "),_c('v-card',[_c('v-card-title',[_vm._v("\n                "+_vm._s(_vm.messageType)+"\n                "),_c('v-spacer')],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.messages,"search":_vm.search,"loading":_vm.busy,"items-per-page":20,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v("\n                        "+_vm._s(item.id)+"\n                    ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColor(item.status)}},[_vm._v("\n                        "+_vm._s(_vm.getIconMessage(item.status))+"\n                    ")]),_vm._v(" "),_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v("\n                        "+_vm._s(_vm.getTextState(item.status))+"\n                    ")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-circular',{attrs:{"size":45,"value":_vm.getProgressValue(item.status)}},[_vm._v("\n                        "+_vm._s(_vm.getProgressValue(item.status))+"%\n                    ")])]}},{key:"item.topic",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.getTopicMessage(item.type))+"\n                ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.getLastUpdateTime(item.updated_at))+"\n                ")]}},{key:"item.track",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getUniqueContributor(item.message)),function(name){return _c('v-chip',{key:name,attrs:{"color":"blue-grey","dark":""}},[_vm._v("\n                        "+_vm._s(name)+"\n                    ")])})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'checkUserMessage', params: { id: item.id, messages: item.message }},"icon":""}},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("edit")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.dialog='true' , _vm.id = item.id}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)]}}])})],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"red white--text"},[_vm._v("\n                    Are you sure you want to delete?\n                ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n                    Once deleted, it is no longer possible to return the information. If you are sure, press the\n                    delete\n                    button; otherwise, cancel.\n                ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.ShowRemoveDiolog($event)}}},[_vm._v("\n                        Delete\n                    ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                        Cancel\n                    ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }