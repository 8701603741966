<template>
    <!--    -->

    <v-card
        width="95%"
        class="custom-top-margin mx-auto rounded-card"
        elevation="20"
    >
        <template v-if="isUnAuthorized">
            <v-snackbar v-model="snackbarMessageSubmitted" color="#f2c4d3" :timeout="5000" top>-->
                <span style="color: #333333;">
                                        Your request was successfully submitted on {{ new Date() | date : 'longDate' }}.
                                    </span>
                <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
                <
            </v-snackbar>
            <v-card outlined class="pa-4" color="error lighten-5">
                <v-card-title class="headline mb-2" color="error">
                    Access Denied
                </v-card-title>
                <v-card-subtitle class="mb-4 subtitle-1">
                    Limited Access Level
                </v-card-subtitle>
                <v-card-text class="body-2 mb-4">
                    It appears that your account lacks the required permissions to access this section. To request
                    additional access, kindly click the button below. Our management team will promptly review your
                    request. You can also track the status of your request in the 'Messages' section.
                </v-card-text>
                <v-card-actions>

                    <v-btn color="error" dark @click="dialogAccessRequest = true" class="capitalize">
                        Send Access Request
                    </v-btn>
                </v-card-actions>
            </v-card>
            <template>
                <v-dialog v-model="dialogAccessRequest" max-width="400px">
                    <v-card>
                        <v-card-title class="headline grey lighten-2" color="error">
                            Confirm Access Request
                        </v-card-title>
                        <v-card-text>
                            Are you sure you want to request enhanced access? Our team will evaluate and determine your
                            eligibility for additional access levels.
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn color="grey" text @click="dialogAccessRequest = false" class="capitalize">
                                Cancel
                            </v-btn>
                            <v-btn color="error" text @click="sendRequest" class="capitalize">
                                Confirm
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </template>
        <template v-else>
            <v-app-bar color="#7B5D6A" window style="height: 95px;"> <!-- Increased height of v-app-bar -->

                <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                    <v-btn :to="{name:'performanceHighlights'}" icon color="white" large>
                        <v-icon large>assessment</v-icon>
                    </v-btn>
                    <v-row justify="center"> <!-- Added v-row with justify="center" -->
                        <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                            <div style="color: whitesmoke; font-size: 8pt; align-content: center">Performance Highlights
                            </div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                    <v-btn :to="{name:'dailyInsights'}" icon color="white" large>
                        <v-icon large>calendar_today</v-icon>
                    </v-btn>
                    <v-row justify="center"> <!-- Added v-row with justify="center" -->
                        <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                            <div style="color: whitesmoke; font-size: 8pt; align-content: center">Daily Insights</div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                    <v-btn :to="{name:'technicianEvaluation'}" icon color="white" large>
                        <v-icon large>person</v-icon>
                    </v-btn>
                    <v-row justify="center"> <!-- Added v-row with justify="center" -->
                        <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                            <div style="color: whitesmoke; font-size: 8pt; align-content: center">Technician Evaluation
                            </div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                    <v-btn :to="{name:'agentEvaluation'}" icon color="white" large>
                        <v-icon large>group</v-icon>
                    </v-btn>
                    <v-row justify="center"> <!-- Added v-row with justify="center" -->
                        <v-col cols="12" class="text-center"> <!-- Added v-col with text-center class -->
                            <div style="color: whitesmoke; font-size: 8pt; align-content: center">Agent Evaluation</div>
                        </v-col>
                    </v-row>
                </v-card>

            </v-app-bar>
            <v-main class="mb-15" style="background-color: #f5f5f5;">
                <router-view></router-view>
            </v-main>
        </template>

    </v-card>
</template>

<!--        <div class="test">-->

<!--            <template>-->
<!--                <v-card-->
<!--                    rounded="10"-->
<!--                    class="mb-auto"-->
<!--                >-->
<!--                    <v-tabs-->
<!--                        v-model="tab"-->
<!--                        background-color="green accent-20"-->
<!--                        grow-->
<!--                        centered-->
<!--                        dark-->
<!--                        icons-and-text-->
<!--                        show-arrows-->
<!--                    >-->
<!--                        <v-tabs-slider></v-tabs-slider>-->
<!--                        <v-tab-->
<!--                            key="tab1"-->
<!--                            href="#tab1">-->
<!--                            Performance Highlights-->

<!--                            <v-icon>assessment</v-icon>-->
<!--                        </v-tab>-->
<!--                        <v-tab-->
<!--                            v-if="$store.state.users.access_level==1 || $store.state.users.access_level==2"-->
<!--                            key="tab2"-->
<!--                            href="#tab2"-->
<!--                        >-->
<!--                            Daily Insights-->
<!--                            <v-icon>calendar_today</v-icon>-->
<!--                        </v-tab>-->
<!--                        <v-tab-->
<!--                            v-if="$store.state.users.access_level==1 || $store.state.users.access_level==2"-->
<!--                            key="tab3"-->
<!--                            href="#tab3"-->

<!--                        >-->
<!--                            Technician Evaluation-->
<!--                            <v-icon>person</v-icon>-->
<!--                        </v-tab>-->
<!--                        <v-tab-->
<!--                            v-if="$store.state.users.access_level==1 || $store.state.users.access_level==2"-->
<!--                            key="tab4"-->
<!--                            href="#tab4"-->
<!--                        >-->
<!--                            Agent Evaluation-->
<!--                            <v-icon>group</v-icon>-->
<!--                        </v-tab>-->

<!--                    </v-tabs>-->

<!--                    <v-tabs-items v-model="tab">-->
<!--                        <v-tab-item-->
<!--                            key="tab1"-->
<!--                            :value="'tab1'"-->
<!--                        >-->
<!--                            <DashboardTab1></DashboardTab1>-->
<!--                        </v-tab-item>-->

<!--                        <v-tab-item-->
<!--                            key="tab3"-->
<!--                            :value="'tab3'"-->
<!--                        >-->
<!--                            <DashboardTab2></DashboardTab2>-->
<!--                        </v-tab-item>-->

<!--                        <v-tab-item-->
<!--                            key="tab4"-->
<!--                            :value="'tab4'"-->
<!--                        >-->
<!--                            <DashboardTab4></DashboardTab4>-->
<!--                        </v-tab-item>-->

<!--                        <v-tab-item-->
<!--                            key="tab2"-->
<!--                            :value="'tab2'"-->
<!--                        >-->
<!--                            <DashboardTab3></DashboardTab3>-->
<!--                        </v-tab-item>-->
<!--                    </v-tabs-items>-->
<!--                </v-card>-->
<!--            </template>-->
<!--            &lt;!&ndash;        Please Uncomment&ndash;&gt;-->
<!--            &lt;!&ndash;        Section II&ndash;&gt;-->


<!--            &lt;!&ndash;        Please Uncomment&ndash;&gt;-->

<!--        </div>-->
<!--    </v-card>-->
<!--</template>-->

<script>
    import AllUserLocation from "../views/User/AllUserLocation";

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import LiveTracking from "../views/LiveTracking";
    import VueMoment from 'vue-jalali-moment';
    import DashboardTab1 from "./DashboardTab1";
    import DashboardTab2 from "./DashboardTab2";
    import DashboardTab3 from "./DashboardTab3";
    import DashboardTab4 from "./DashboardTab4";


    export default {

        components: {
            DashboardTab4,
            DashboardTab3,
            DashboardTab2,
            DashboardTab1,
            VueFunnelGraph,
            LiveTracking,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'user',
        ],
        data: () => ({
            snackbarMessageSubmitted: false,
            items: [
                {title: 'Home'},
                {title: 'Profile'},
                {title: 'Settings'},
                // ... add more items as needed
            ],
            test: 1,
            tab: null,
            // items: [
            //     'Appetizers', 'Entrees', 'Deserts', 'Cocktails',
            // ],
            search: null,
            rare: null,
            review: null,
            // tab: null,
            tab1: null,
            // tab: null,
            // tab: null,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

            lateLoginErrorNum: '',
            locationalert: false,
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            dateTommorow: '',
            dialogVocation: false,
            dialogAccessRequest: false,
            //Instead of cone

            seriesCone: [{
                name: 'پیام های صحیح',
                type: 'column',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
            }, {
                name: 'پیام های ارسالی',
                type: 'area',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
            }, {
                name: 'رضایت افراد',
                type: 'line',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
            }],
            chartOptionsCone: {
                chart: {
                    height: 350,
                    width: 250,
                    type: 'line',
                    stacked: false,
                    animations: {
                        enabled: false
                    },
                },
                stroke: {
                    width: [0, 2, 5],
                    curve: 'smooth'
                },

                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },

                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: ['Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July',
                    'August', 'September', 'October', 'November', 'December'
                ],
                markers: {
                    size: 0
                },
                xaxis: {},
                yaxis: {
                    title: {
                        text: 'Level',
                    },
                    min: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + "";
                            }
                            return y;

                        }
                    }
                }
            },


            //Table
            headers1: [
                {text: 'ID people', value: 'id'},

                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'Spatial error value', value: 'location', sortable: false,},
            ],
            headers2: [
                {text: 'id people', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'location error value', value: 'location', sortable: false,},],
            headers3: [
                {text: 'id people', value: 'id'},
                {
                    text: 'name',
                    align: 'start',
                    value: 'name',
                },
                // { text: 'Family', value: 'family' },
                // {text: 'امتیاز', value: "id"},
                {text: 'Number of dogs taken', value: 'location', sortable: false,},],

            desserts1: [
                {
                    id: '1',
                    name: 'Keyvan Shafiee',
                    email: '09127752017',
                    location: 20,
                },
                {
                    id: '2',
                    name: 'Alireza Shirdel',
                    email: '09124020821',
                    location: 21,
                },
            ],
            desserts2: [
                {
                    id: '1',
                    name: 'Keyvan Shafiee',
                    email: '09127752017',
                    location: 14,
                },
                {
                    id: '2',
                    name: 'Ehsan Shahsavari',
                    email: '09124020821',
                    location: 19,
                },
            ],
            desserts3: [
                {
                    id: '1',
                    name: 'Mehran Sobhani',
                    email: '09127752017',
                    location: 15,
                },
                {
                    id: '2',
                    name: 'Saeed Hossein Khani',
                    email: '09124020821',
                    location: 8,
                },
            ],

            //
            alignments: [
                'start',
                'center',
                'end',

            ],
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1

            seriesChart1: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan',
                    y: null
                },
                    {
                        x: '2 Jan',
                        y: 44
                    },
                    {
                        x: '3 Jan',
                        y: 31
                    },
                    {
                        x: '4 Jan',
                        y: 38
                    },
                    {
                        x: '5 Jan',
                        y: null
                    },
                    {
                        x: '6 Jan',
                        y: 32
                    },
                    {
                        x: '7 Jan',
                        y: 55
                    },
                    {
                        x: '8 Jan',
                        y: 51
                    },
                    {
                        x: '9 Jan',
                        y: 67
                    },
                    {
                        x: '10 Jan',
                        y: 22
                    },
                    {
                        x: '11 Jan',
                        y: 34
                    },
                    {
                        x: '12 Jan',
                        y: null
                    },
                    {
                        x: '13 Jan',
                        y: null
                    },
                    {
                        x: '14 Jan',
                        y: 11
                    },
                ],
            }],

            seriesChart2: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan.',
                    y: null
                },
                    {
                        x: '2 Jan.',
                        y: 31
                    },
                    {
                        x: '3 Jan.',
                        y: 12
                    },
                    {
                        x: '4 Jan.',
                        y: 4
                    },
                    {
                        x: '5 Jan.',
                        y: null
                    },
                    {
                        x: '6 Jan.',
                        y: 9
                    },
                    {
                        x: '7 Jan.',
                        y: 63
                    },
                    {
                        x: '8 Jan.',
                        y: 13
                    },
                    {
                        x: '9 Jan.',
                        y: 18
                    },
                    {
                        x: '10 Jan.',
                        y: 19
                    },
                    {
                        x: '11 Jan.',
                        y: 51
                    },
                    {
                        x: '12 Jan.',
                        y: null
                    },
                    {
                        x: '13 Jan.',
                        y: null
                    },
                    {
                        x: '14 Jan.',
                        y: 23
                    },
                ],
            }],

            seriesChart3: [{
                name: 'Number of errors',
                data: [{
                    x: '1 Jan.',
                    y: null
                },
                    {
                        x: '2 Jan.',
                        y: 18
                    },
                    {
                        x: '3 Jan.',
                        y: 10
                    },
                    {
                        x: '4 Jan.',
                        y: 9
                    },
                    {
                        x: '5 Jan.',
                        y: null
                    },
                    {
                        x: '6 Jan.',
                        y: 18
                    },
                    {
                        x: '7 Jan.',
                        y: 51
                    },
                    {
                        x: '8 Jan.',
                        y: 9
                    },
                    {
                        x: '9 Jan.',
                        y: 17
                    },
                    {
                        x: '10 Jan.',
                        y: 23
                    },
                    {
                        x: '11 Jan.',
                        y: 3
                    },
                    {
                        x: '12 Jan.',
                        y: null
                    },
                    {
                        x: '13 Jan.',
                        y: null
                    },
                    {
                        x: '14 Jan.',
                        y: 49
                    },
                ],
            }],

            chartOptionsChart1: {
                chart: {
                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 5,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },

            chartOptionsChart2: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#008FFB'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            chartOptionsChart3: {
                chart: {

                    type: 'area',
                    toolbar: false,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                colors: ['#546E7A'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 0.8,
                    type: 'pattern',
                    pattern: {
                        style: ['verticalLines', 'horizontalLines'],
                        width: 6,
                        height: 6
                    },
                },
                markers: {
                    size: 5,
                    hover: {
                        size: 9
                    }
                },
                tooltip: {
                    intersect: true,
                    shared: false
                },
                theme: {
                    palette: 'palette2'
                },
                xaxis: {
                    type: 'category',
                }
            },
            //pi chart
            seriesPi: [14, 23, 21, 17],
            chartOptionsPi: {
                labels: ['male dog, adult', 'adult female dog', 'male dog, puppy', 'female dog puppy'], chart: {
                    type: 'polarArea',
                },
                stroke: {
                    colors: ['#fff']
                },
                fill: {
                    opacity: 0.8
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {},
                        legend: {
                            position: 'top'
                        }
                    }
                }]
            },

            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],
            chartOptionsRa: {
                chart: {
                    type: 'radar',
                },

                xaxis: {
                    categories: ['vaccine 1', 'vaccine 2', 'vaccine 3', 'sterile'], position: 'top'
                }
            },

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        // data() {
        //     return {
        //         labels: ['Impressions', 'Add To Cart', 'Buy'],
        //         subLabels: ['Direct', 'Social Media', 'Ads'],
        //         values: [
        //             // with the given Labels and SubLabels here's what the values represent:
        //             //
        //             // Direct, Social, Ads
        //             //    |      |     |
        //             //    v      v     v
        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
        //             [3000, 1700, 1000], // Segments of "Add To Cart"
        //             [600,  200,  130]   // Segments of "Buy"
        //         ],
        //         colors: [
        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        //         ],
        //         direction: 'horizontal',
        //         gradientDirection: 'horizontal',
        //         height: 300,
        //         width: 800
        //     };
        // },
        created() {
            // console.log(this.$refs.funnel);
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;
        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
            axios.get('../user/' + this.$store.state.users.id)
                .then(response => {
                    this.lateLoginErrorNum = response.data.last_login_error;
                    if (response.data.gps_off_error == 1) {
                        this.locationalert = true;
                    } else {
                        this.locationalert = false;

                    }
                })
                .catch(errors => {
                    console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
        },
        methods: {
            sendRequest() {
                let currentTime = new Date();

                let msg = {
                    'type': 'ChangeAccessLevel',
                    'status': 'submitted',
                    'location': '',
                    'message': [{
                        'accessLevel': '6',
                        'id': this.$store.state.users.id,
                        'fullname': this.$store.state.users.first_name + ' ' + this.$store.state.users.surname,
                        'msg': 'New Request has been submitted',
                        'time': currentTime
                    }],
                };
                axios.post('../team_msg', msg)
                    .then(response => {
                        this.dialogAccessRequest = false;
                        this.snackbarMessageSubmitted = true;
                    })
                    .catch(errors => {
                    })
            },
            handleClick(item) {
                // Handle the click event for each item
                console.log(item.title);
            },
            getCookie(name) {
                const encodedNameEQ = `${encodeURIComponent(name)}=`;
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    while (cookie.charAt(0) === ' ') {
                        cookie = cookie.substring(1, cookie.length);
                    }
                    if (cookie.indexOf(encodedNameEQ) === 0) {
                        const encodedValue = cookie.substring(encodedNameEQ.length, cookie.length);
                        const jsonString = decodeURIComponent(encodedValue);
                        return JSON.parse(jsonString);
                    }
                }
                return null;
            },
            SendCurrentLocation() {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        let newLocation = {
                            Lat: position.coords.latitude,
                            Long: position.coords.longitude
                        };

                        this.$store.commit("updateLocation", newLocation);
                        //post to server
                        axios.post('../saveUserLocation', {
                            ID: this.$store.state.users.id,
                            Long: position.coords.longitude,
                            Lat: position.coords.latitude,
                        })
                            .then(response => {
                                console.log('Send');
                                this.locationalert = false;
                                this.$router.go();
                            })
                            .catch(errors => {
                                console.log('error');
                            });
                    })
                } else {
                    this.locationalert = true;
                }
            },
            increase() {
                this.height += 10
            },

            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                axios.put('../user/' + this.$store.state.users.id, this.form)
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'Management'
                else if (this.$store.state.users.access_level == "2") return 'Expert'
                else if (this.$store.state.users.access_level == "3") return 'Support'
                else if (this.$store.state.users.access_level == "4") return 'Responsible'
                else if (this.$store.state.users.access_level == "5") return 'Technician'
                else return 'Unapproved user'
            },
        }
        ,
        computed: {
            isUnAuthorized() {
                return this.$store.state.users.access_level == 6;
            }
            ,
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            }
            ,
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .v-btn.capitalize {
        text-transform: capitalize !important;
    }

    .icon-button {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .icon-circle {
        background-color: pink; /* or any color you prefer for the circle */
        border-radius: 50%; /* makes the div a circle */
        width: 56px; /* adjust based on your preferred size */
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-top-margin {
        margin-top: 15px;
    }

    .rounded-card {
        border-radius: 20px !important;
        overflow: hidden;
    }

    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
