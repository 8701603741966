// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue';

import router from './router';
import routerCustomer from './routerCustomer';
import routerPanel from "./routerPanel";
import routerLanding from "./routerLanding";

import App from './components/App';
import Customer from "./Customer/CustomerApp";
import Landing from "./LoginPanel/LandingApp"
import Panel from "./Panel/PanelApp";

import store from './store'
import Chartkick from 'vue-chartkick'
import VueApexCharts from 'vue-apexcharts'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import 'leaflet/dist/leaflet.css';
import AudioRecorder from 'vue-audio-recorder'
import VueQuagga from 'vue-quaggajs';
import * as VueMapbox from 'vue-mapbox';
import Mapbox from 'mapbox-gl';

Vue.use(VueApexCharts)
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.component('apexchart', VueApexCharts);
Vue.use(Chartkick);
Vue.use(VueQuagga);
Vue.use(AudioRecorder);
Vue.use(require('vue-jalali-moment'));
Vue.use(VueMapbox, {mapboxgl: Mapbox});

import '@mdi/font/css/materialdesignicons.min.css'


const moment = require('moment');
require('moment/locale/es');
moment.locale('en');
window.Apex.chart = {fontFamily: "IRANSans"};

// Vue.use(Vuetify, {rtl: true})
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');

// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('example')
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import {Icon} from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


var Promise = require('es6-promise').Promise;

import vuetify from './plugins/vuetify'


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
window.onload = function () {
    var app3;
    var app4;
    var app5;
    const isDeveloperAuthenticated = localStorage.getItem('isDeveloperAuthenticated');
    if (isDeveloperAuthenticated) {
        store.commit('setDeveloperAuthenticated', true);
        // Perform any other initialization for authenticated users
    }
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated) {
        store.commit('setAuthenticated', true);
        // Perform any other initialization for authenticated users
    }
    const isCustomerAuthenticated = localStorage.getItem('isCustomerAuthenticated');
    if (isCustomerAuthenticated) {
        store.commit('setCustomerAuthenticated', true);
        // Perform any other initialization for authenticated users
    }

    // const initVueInstance = (elementId, component, router) => {
    //     if (document.getElementById(elementId)) {
    //         new Vue({
    //             vuetify,
    //             store,
    //             el: `#${elementId}`,
    //             components: { component },
    //             router
    //         });
    //     }
    // };
    //
    // // Initialize specific Vue instances
    // initVueInstance('appLanding2', Landing, routerLanding);
    // initVueInstance('appLanding', Landing, routerLanding);
    // initVueInstance('appPanel', Panel, routerPanel);
    // initVueInstance('app', App, router);
    // initVueInstance('app2', Customer, routerCustomer);
    if (document.getElementById('appLanding2')) {
        app4 = new Vue({
            vuetify,
            store,
            el: '#appLanding2',

            components: {
                Landing
            },
            router: routerLanding

        });
    }
    let app26 = document.getElementById('appLanding');
    if (app26) {
        app4 = new Vue({
            vuetify,
            store,
            el: '#appLanding',
            components: {
                Landing
            },
            router: routerLanding

        });
    }

    let app25 = document.getElementById('appPanel');
    if (app25) {
        app3 = new Vue({
            vuetify,
            store,
            el: '#appPanel',
            components: {
                Panel
            },
            router: routerPanel

        });
    }
    var app24 = document.getElementById('app');
    if (app24) {
        var app = new Vue({
            vuetify,
            store,
            el: '#app',
            components: {
                App
            },
            router
        });
    }
    var app23 = document.getElementById('app2');
    if (app23) {
        var app2 = new Vue({
            vuetify,
            store,

            el: '#app2',
            components: {
                Customer
            },
            router: routerCustomer
        });
    }
}
