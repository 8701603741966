<template>
    <div class="pt-16">

        <v-card max-width="480px" class="mx-auto rounded-form" outlined >
            <v-card-title class="layout justify-center">
                 ثبت درخواست ارائه خدمات توسط شرکت ساماندهی
            </v-card-title>
            <v-card-subtitle class="layout justify-center">
                لطفا خدماتی که شرکت ساماندهی می تواند به شما ارائه نماید را انتخاب نمایید
            </v-card-subtitle>
            <v-img :src="require('../../../../assets/iamges/Vet.png')" contain max-height="120px">

            </v-img>


            <!--':'', '':'', '':'', '':'', '':'', '':'', '':'',
                                    '':'', '':'-->
            <v-card-text>
                <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                <v-text-field  label="شماره ی تماس" :error-messages="errors" placeholder="لطفا شماره ی تماس خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                <!--            <v-text-field  label="Latitude of first catch" :error-messages="errors" placeholder="Please enter Latitude" v-model="form.catched_lat " ></v-text-field>-->
                <!--            <v-text-field  label="Longitude of first catch" :error-messages="errors" placeholder="Please enter Longitude" v-model="form.catched_lon " ></v-text-field>-->
                <div id="filepond">
                    <file-pond

                        name="test"
                        ref="pond"
                        class-name="my-pond"
                        label-idle="...لطفا عکس حیوان خانگی را ارسال نمایید"
                        allow-multiple="false"
                        accepted-file-types="image/jpeg, image/png"
                        server= "../owner/upload"
                        v-on:processfile="handleProcessFile">
                    </file-pond>
                </div>
                <v-select :items="[ 'واگذاری','واکسن 2','واکسن 1']" label="نوع خدمت درخواستی" :error-messages="errors" placeholder="نوع خدمت" v-model="form.sex " ></v-select>
                <v-text-field  label="نژاد" :error-messages="errors" placeholder="لطفا نژاد سگ خود را وارد نمایید." v-model="form.age "></v-text-field>
                <v-text-field  label="سن سگ" placeholder="سن سگ خود را وارد نمایید." v-model="form.job "></v-text-field>
                <v-select :items="['نر','ماده']" label="جنسیت حیوان" :error-messages="errors" placeholder="لطفا جنسیت حیوان را وارد نمایید." v-model="form.date_of_adoption "></v-select>
                <!--                <v-text-field  label="ای دی سگ" :error-messages="errors" placeholder="لطفا ای دی سگ مد نظر را وارد نمایید" v-model="form.dog_ID "></v-text-field>-->
<!--                <v-select :items="['ID100','ID101','ID102','ID103','ID104','ID105','ID106','ID107' ]" label="ای دی سگ" :error-messages="errors" placeholder="لطفا ای دی سگ مد نظر را وارد نمایید" v-model="form.dog_ID " ></v-select>-->

                <v-text-field  label="آدرس" :error-messages="errors" placeholder="آدرس خود را وارد نمایید." v-model="form.address "></v-text-field>
                <v-text-field  label="توضیحات"  placeholder="در صورت لزوم توضیحات را وارد نمایید." v-model="form.comments "></v-text-field>


            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="m-1" :to="{name:'profiles'}">لغو کردن </v-btn>
                <v-btn depressed color="success" class="m-1" href="https://idpay.ir/niomatic-kit" :loading="busy">ثبت کردن</v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
    // import InputField from "../components/InputField";
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

    export default {
        name: "OwnerCreate",
        components: {
            FilePond
        },

        data:function () {
            return{
                //myFiles: ['index.html'],

                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name':'', 'age':'', 'sex':'', 'family':'', 'job':'', 'date_of_adoption':'', 'address':'',
                        'comments':'', 'dog_ID':'', 'fig':'',
                    },
                errors: null,
                responses:null,
                busy:false,

            }
        },
        mounted() {
            // this.video = this.$refs.video;
            // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            //     navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //         video.srcObject = stream;
            //         video.play();
            //         video.onplay = function () {
            //         };
            //         this.video.play();
            //     });
            // }
        },

        methods:{
            handleProcessFile: function(error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            },
            addNewDog() {
                /*axios.post('../dogdata/', {
                    race: 'Finn',
                    age: 'Williams',
                    sex: 'Williams',
                    catched_lat:'',
                    catched_lon: '',
                    vaccinated: '',
                    sterilized:''
                }).then(response => {

                            })
                         .catch(errors =>{
                             console.log(errors);
                         });*/

                this.busy=true;

                axios.post('../owner', this.form)
                    .then( response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name:'owner'});
                    })
                    .catch(errors =>{
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(()=>{
                    this.busy=false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }
    #video {
        background-color: #000000;
    }
    #canvas {
        display: none;
    }
    li {
        display: inline;
        padding: 5px;
    }
    .rounded-form{
        border-radius: 28px !important;

    }
</style>

