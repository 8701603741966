<template>
    <div class="pt-16">
        <v-snackbar v-model="snackkbar" color="#f2c4d3" :timeout="3000" top>
            <span style="color: #333333;">
                The {{form.family}} has been successfully added.
            </span>
        </v-snackbar>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-card  outlined>
                <v-card-title class="layout justify-center">
                    Add modules to the building
                </v-card-title>
                <v-card-subtitle class="layout justify-center">
                    Please add your desired module to your building.
                </v-card-subtitle>
                <v-img :src="require('../../../assets/iamges/sensor.png')" contain max-height="120px">

                </v-img>
                <v-card-text >
                    <v-flex row wrap class="box mx-auto" xs9 >
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                class="justify-center"
                            >
                                <v-select
                                    :rules="[v => !!v || 'Model selection is mandatory']"
                                    :items="brands" label="Hardware brand" :error-messages="errors" placeholder="model"
                                    v-model="form.type "></v-select>
                            </v-col>

                            <v-col
                                class="justify-center"
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    label="Declaration module ID"
                                    placeholder="e.g. ID000012491"
                                    :rules="cellnumRules"
                                    v-model="unique_id"></v-text-field>
                                <!--                                <v-text-field-->
                                <!--                                    label="Declaration module ID"-->
                                <!--                                    placeholder="e.g. ID000012491"-->
                                <!--                                    v-model="unique_id"></v-text-field>-->
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                                class="justify-center"

                            >
                                <v-select
                                    v-model="form.building_id"
                                    :rules="[v => !!v || 'Choosing a home is a must']"
                                    :items="BuildingInformation"
                                    item-text='address'
                                    item-value='id'
                                    label="The home address" data-vv-name="country" prepend-icon="mdi-flag"
                                    required></v-select>
                                <!--                                <v-select :items="['ساختمان 1','ساختمان 2']" label="نام ساختمان" :error-messages="errors" placeholder="ساختمان" v-model="form.building_id " ></v-select>-->
                            </v-col>
                        </v-row>

                    </v-flex>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="m-1" :to="{name:'dashboard'}">Cancel</v-btn>
                        <v-btn
                            :disabled="!valid"
                            depressed color="success"
                            class="m-1"
                            @click="validate()"
                            :loading="busy">Register
                        </v-btn>
                    </v-card-actions>
                    <template>
                        <v-dialog
                            v-model="dialogSupporter"
                            width="500"
                        >
                            <template v-if="form.type == 'Insurmatics'">
                                <v-card >
                                    <v-card-title class="headline grey lighten-2">
                                        Self-declaration form
                                    </v-card-title>

                                    <v-card-text>
                                        I confess that all the information entered is correct and the Company for
                                        Organizing Industries and Jobs will not be held responsible for any possible
                                        violations.
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="green"
                                            text
                                            @click="attachNewModule"
                                        >
                                            Yes, I agree
                                        </v-btn>

                                        <v-btn
                                            color="red"
                                            text
                                            @click="dialogSupporter= false"
                                        >
                                            Cancel
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                            <template v-else>
                                <v-card>
                                    <v-card-title class="headline grey lighten-2">
                                        Add as MQTT client
                                    </v-card-title>

                                    <v-card-text>
                                        I confess that all the information entered is correct and the company will not
                                        be held responsible for any possible violations.
                                    </v-card-text>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            class="justify-center"
                                        >
                                            <v-text-field
                                                readonly
                                                label="Username"
                                                v-model="username"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="justify-center"
                                        >
                                            <v-text-field
                                                readonly
                                                label="Password"
                                                v-model="password"
                                            ></v-text-field>

                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="justify-center"
                                        >
                                            <v-text-field
                                                readonly="readonly"
                                                label="Client ID"
                                                v-model="clientID"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="justify-center"
                                        >
                                            <v-text-field
                                                readonly="readonly"
                                                label="Topic"
                                                v-model="topic"
                                            ></v-text-field>
                                        </v-col>

                                    </v-row>
                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="green"
                                            text
                                            @click="dialogSupporter= false"
                                        >
                                            Yes, I agree
                                        </v-btn>

                                        <v-btn
                                            color="red"
                                            text
                                            @click="dialogSupporter= false"
                                        >
                                            Cancel
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>

                            </template>

                        </v-dialog>
                    </template>

                </v-card-text>
            </v-card>
        </v-form>

    </div>
</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "OwnerCreate",
        components: {
            FilePond
        },

        data: function () {
            return {
                username:'SjDWxBNw',
                password:'whN6SGqmbTq0',
                clientID:'Bc8B6pLLFKTBGcAi',
                topic:'topic-7994xdfr',
                valid: false,
                //myFiles: ['index.html'],
                cellnumRules: [
                    v => !!v || 'Number is required',
                    // v => (v && v.length === 11) || 'Incorrect format: Enter the ID with 9 numbers.',
                ],
                dialogSupporter: false,
                BuildingInformation: null,
                snackkbar: false,
                unique_id: '',
                form:
                    {
                        'building_id': '', 'type': ''
                    },
                // 'name', 'family', 'sex', 'number', 'fig', 'age', 'degree',  'number_of_nezam', 'address', 'comments', 'verified'

                formVet:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '',
                        'age': '',
                        'sex': '',
                        'family': '',
                        'degree': '',
                        'number_of_nezam': '',
                        'address': '',
                        'comments': '',
                        'fig': '',
                        'number': '',
                        'verified': '0'
                    },
                errors: null,
                responses: null,
                busy: false,
                service: '',
                brands: ['Insurmatics', 'Honeywell Home', 'Bosch Smart Home', 'Philips Hue', 'tado°', 'Legrand (Netatmo)', 'Gigaset', 'Schneider Electric', 'Trust International'],
                hardwareTypes: {
                    'Honeywell Home': ['Door and window sensors', 'Motion detectors', 'Water leak sensors'],
                    'Bosch Smart Home': ['Door and window sensors', 'Motion detectors', 'Smoke detectors'],
                    'Philips Hue': ['Motion detectors', 'Water leak sensors'],
                    'tado°': ['Water leak sensors'],
                    'Legrand (Netatmo)': ['Door and window sensors', 'Smoke detectors', 'Water leak sensors'],
                    'Gigaset': ['Door and window sensors', 'Motion detectors', 'Smoke detectors'],
                    'Schneider Electric': ['Door and window sensors', 'Motion detectors', 'Water leak sensors'],
                    'Trust International': ['Door and window sensors', 'Motion detectors', 'Water leak sensors'],
                },
            }
        },
        mounted() {
            this.responses = this.$store.state.customers.id;
            axios.get('citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.BuildingInformation = response.data;
                })
                .catch(errors => {
                    // console.log(this.form);
                    this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
        },

        methods: {
            validate() {
                if (this.form.type ==='Insurmatics')
                {
                    this.dialogSupporter = this.$refs.form.validate();
                }
                else {
                    this.dialogSupporter = this.$refs.form.validate();
                }
            },
            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            },
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            attachNewModule() {
                this.dialogSupporter = false;
                this.busy = true;
                //form

                axios.put('hardware/' + this.unique_id, this.form)
                    .then(response => {
                        this.responses = response;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.snackkbar = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            attachNewHardware() {
                this.dialogSupporter = false;
                this.busy = true;
                //form

                axios.put('hardware/' + this.unique_id, this.form)
                    .then(response => {
                        this.responses = response;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.snackkbar = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>

