<template>
    <svg
        enable-background="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
        version="1.1"
        id="svg26"
        sodipodi:docname="app_registration_black_24dp.svg"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <defs
            id="defs30" />
        <sodipodi:namedview
            id="namedview28"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            showgrid="false"
            inkscape:zoom="8.7291667"
            inkscape:cx="-19.417661"
            inkscape:cy="18.844869"
            inkscape:window-width="1920"
            inkscape:window-height="1001"
            inkscape:window-x="-9"
            inkscape:window-y="-9"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg26" />
        <g
            id="g4">
            <rect
                fill="none"
                height="24"
                width="24"
                id="rect2" />
        </g>
        <g
            id="g24"
            transform="translate(-0.11455847,-0.22911694)"
            style="fill:#757575;fill-opacity:1">
            <g
                id="g22"
                style="fill:#757575;fill-opacity:1">
                <rect
                    height="4"
                    width="4"
                    x="10"
                    y="4"
                    id="rect6"
                    style="fill:#757575;fill-opacity:1" />
                <rect
                    height="4"
                    width="4"
                    x="4"
                    y="16"
                    id="rect8"
                    style="fill:#757575;fill-opacity:1" />
                <rect
                    height="4"
                    width="4"
                    x="4"
                    y="10"
                    id="rect10"
                    style="fill:#757575;fill-opacity:1" />
                <rect
                    height="4"
                    width="4"
                    x="4"
                    y="4"
                    id="rect12"
                    style="fill:#757575;fill-opacity:1" />
                <polygon
                    points="10,10 10,14 12.42,14 14,12.42 14,10 "
                    id="polygon14"
                    style="fill:#757575;fill-opacity:1" />
                <path
                    d="m 20.88,11.29 -1.17,-1.17 c -0.16,-0.16 -0.42,-0.16 -0.58,0 L 18.25,11 20,12.75 20.88,11.87 c 0.16,-0.16 0.16,-0.42 0,-0.58 z"
                    id="path16"
                    style="fill:#757575;fill-opacity:1" />
                <polygon
                    points="12.75,20 19.42,13.33 17.67,11.58 11,18.25 11,20 "
                    id="polygon18"
                    style="fill:#757575;fill-opacity:1" />
                <rect
                    height="4"
                    width="4"
                    x="16"
                    y="4"
                    id="rect20"
                    style="fill:#757575;fill-opacity:1" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "Appliance"
    }
</script>

<style scoped>

</style>
