<template>

    <div class="test">
        <template>
            <v-card
                class="mx-auto my-6 rounded-form"
                width="90%"
                max-width="1200"
                outlined
                elevation="10"
            >
                <v-card-title primary-title class="justify-center">Explore Homeowner Plans</v-card-title>
                <v-divider class="mx-4 my-3"></v-divider>
                <v-container
                    class="grey lighten-5 mb-6 mx-auto" align="center"
                >
                    <v-layout align-center row wrap>
                        <v-flex>

                            <v-row
                                no-gutters class="px-3 py-1"
                                align="center" justify="space-around"
                            >
                                <v-card
                                    v-if="!unit_set.length"
                                >
                                    <v-card-text>
                                        No registered home
                                    </v-card-text>
                                </v-card>
                                <v-col
                                    lg="4"
                                    md="6"
                                    cols="12"
                                    v-for="item in unit_set"
                                >
                                    <v-card
                                        v-if="unit_set.length > 0"
                                        class="mx-auto my-4"
                                        width="90%"
                                        rounded="lg"
                                        elevation="3"
                                        :style="{ backgroundColor: '#fff', borderColor: '#e0e0e0' }"
                                    >
                                        <v-img
                                            src="../../../../assets/icons/home.png"

                                            class="white--text align-end"
                                            height="200px"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                        >

                                            <v-btn
                                                fab
                                                small
                                                dark
                                                color="#7b5765ff"
                                                class="mt-4"
                                                @click="navigateToDetails(item.id)"
                                            >
                                                <v-icon>mdi-chevron-right</v-icon>
                                            </v-btn>
                                        </v-img>
                                        <v-divider class="my-4"></v-divider>
                                        <v-row
                                            no-gutters class="px-3 py-1"
                                        >
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="Unit number"
                                                    outlined
                                                    v-model="item.unit_Nom"
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="Hardware ID"
                                                    outlined
                                                    class="text-field mx-2"
                                                    v-model="item.hardware_id"
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            no-gutters class="px-3 py-1"
                                        >
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    label="area"
                                                    outlined
                                                    class="text-field mx-2"
                                                    v-model="item.area"
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="6"
                                            >
                                                <v-text-field
                                                    outlined

                                                    class="text-field mx-2"
                                                    value="0"
                                                    dense
                                                    readonly
                                                >
                                                    <template v-slot:label>
                                                        <span class="wrap-label">Errors in Last 30 Days</span>
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            no-gutters class="px-3 py-1"
                                        >
                                            <v-col
                                                cols="12"
                                            >
                                                <v-text-field
                                                    label="Building ID"
                                                    outlined
                                                    class="text-field2 text-field mx-2 "
                                                    v-model="item.building_id"
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>

                            </v-row>
                        </v-flex>
                    </v-layout>
                </v-container>

            </v-card>
        </template>

    </div>
</template>

<script>

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';

    import VueApexCharts from 'vue-apexcharts'
    import VueMoment from 'vue-jalali-moment';

    export default {

        components: {

            VueFunnelGraph,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'user',
        ],
        data: () => ({
            building_set: '',
            unit_set: '',
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },

            //Sparkline
            fill: false,
            //
            gradients,
        }),

        created() {
            console.log(this.$store.state.customers.id);
            axios.get('../citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.building_set = response.data;
                    // this.building_set =[];

                })
                .catch(errors => {
                    console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });

            axios.get('../citizenUnits/' + this.$store.state.customers.id)
                .then(response => {
                    this.unit_set = response.data;
                    // this.unit_set = [];
                })
                .catch(errors => {
                    console.log(this.form);
                    // this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });

        },

        async mounted() {

            this.loaded = false;
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
        },
        methods: {
            navigateToDetails(id) {
                this.$router.push({name: 'homeDataPanel', params: {id: id}})
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            }
        },
        computed: {}
    }
</script>

<style scoped>
    .custom-top-margin {
        margin-top: 15px;
    }

    .rounded-card {
        border-radius: 20px !important;
        overflow: hidden;
    }

    .v-btn {
        text-transform: capitalize
    }

    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    >>> .text-field {
        border-radius: 20px !important;
    }


    >>> .text-field2 {
        font-size: 12px;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }

    .wrap-label {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
