var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dogs,"items-per-page":10,"sort-by":"id","sort-desc":true,"loading":_vm.busy,"footer-props":{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'items per page',
      }},on:{"click:row":function (item){ return _vm.$router.push(''); },"page-count":function($event){10}},scopedSlots:_vm._u([{key:"item.track",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.ShowLocation(item.id)}}},[_c('v-icon',[_vm._v("gps_not_fixed")])],1)]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.EditData(item.id)}}},[_c('v-icon',[_vm._v("edit")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }