<template>
    <div class="test">
        <v-card
            width="95%"
            class="custom-top-margin mx-auto rounded-card"
            elevation="20"
        >
            <template>
                <v-app-bar color="#7B5D6A" window style="height: 95px;">
                    <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'MyWallets'}" icon color="white" large>
                            <v-icon large>account_balance_wallet</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt;">My Wallets</div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'TransactionHistory'}" icon color="white" large>
                            <v-icon large>history</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt;">Transaction History</div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'TransferFunds'}" icon color="white" large>
                            <v-icon large>swap_horiz</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt;">Transfer Funds</div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'Reports'}" icon color="white" large>
                            <v-icon large>bar_chart</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt;">Reports and Analytics</div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card flat color="transparent" class="d-flex flex-column align-center justify-center mx-2 my-auto">
                        <v-btn :to="{name:'SupportFAQ'}" icon color="white" large>
                            <v-icon large>help_outline</v-icon>
                        </v-btn>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <div style="color: whitesmoke; font-size: 8pt;">Support and FAQs</div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-app-bar>

                <v-main class="" style="background-color: #f5f5f5;">
                    <router-view></router-view>
                </v-main>
            </template>
        </v-card>
    </div>
</template>

<script>
    export default {
        props: ['user'],
    };
</script>

<style scoped>
    .v-btn {
        text-transform: capitalize;
    }

    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    >>> .text-field {
        border-radius: 20px !important;
    }

    >>> .text-field2 {
        font-size: 12px;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center;
    }

    .rounded-card {
        border-radius: 20px !important;
        overflow: hidden;
    }

    .custom-top-margin {
        margin-top: 15px;
    }
</style>
