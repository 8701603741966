<template>

    <div class="test">
        <v-snackbar v-model="snackbarSetHoliday" color="#f2c4d3" :timeout="3000" centered>
            <span style="color: #333333;">
                Registration successful
            </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>

        <v-container fluid>
            <v-text class="text-h5 ml-10 text-bold black--text mt-20 mb-15 ">
                Security
            </v-text>
            <v-row class="mt-10">
                <v-card
                    color="grey lighten-5"
                    class="mx-auto my-4 rounded-form"
                    width="80%"
                    elevation="3"
                >
                    <v-card-title class="d-flex justify-center title-wrap">Personal Secured data</v-card-title>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 mb-5 wrap_text">
                                Password
                            </v-list-item-title>
                            <v-list-item-subtitle class="wrap_text ">
                                Write your new password and retype it for confirmation!
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-row>
                        <v-col
                            cols="11"
                            sm="6"
                        >
                            <v-text-field
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type=" 'password'"
                                name="input-10-1"
                                label="New password"
                                hint="At least contain 8 characters"
                                counter
                                @click:append="show1 = !show1"
                                class="mx-auto"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="11"
                            sm="6"
                        >
                            <v-text-field

                                v-model="rePassword"
                                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                                :type="'password'"
                                name="input-10-2"
                                label="Retype password"
                                hint="Retype password"
                                class="input-group--focused mx-auto"
                                @click:append="show2 = !show2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-row class="mx-10">
                            <v-btn
                                rounded
                                outlined

                                color="success"
                                @click.stop="dialogVocation2=true"
                                class="text-capitalize"
                            >
                                Save password
                            </v-btn>
                        </v-row>
                        <v-dialog
                            v-model="dialogVocation2"
                            max-width="290"
                        >
                            <v-card>


                                <v-card-text>
                                    Are you sure you want to change your password?
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="red darken-1"
                                        text
                                        @click.prevent="dialogVocation2=false"
                                    >
                                        No
                                    </v-btn>

                                    <v-btn
                                        color="green darken-1"
                                        text
                                        @click.prevent="EditPassword"
                                    >
                                        Yes
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card-actions>
                    <v-divider class="mx-4"></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 mb-5 wrap_text">
                                2-Step Verification
                            </v-list-item-title>
                            <v-list-item-subtitle class="wrap_text ">In addition to your username and password, 2-step
                                verification adds an extra layer of security to your Hubbcasts Account.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-card-actions>
                        <v-btn
                            outlined
                            rounded
                            color="success"
                            class="text-capitalize mx-10"
                        >
                            Activate
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card
                    color="grey lighten-5"
                    class="mx-auto my-4 rounded-form"
                    width="80%"
                    elevation="10"
                >
                    <v-card-title class="d-flex justify-center title-wrap text-center" >Social accounts authentication</v-card-title>
                    <v-card-subtitle>
                        You can login with well-known social accounts into Hubbcast developer panel.
                    </v-card-subtitle>
                    <v-card
                        class="mx-auto align-center justify-center"
                        width="90%"
                        rounded="3"
                        flat
                    >
                        <v-row align="center">
                            <v-col cols="3" class="d-flex justify-center">
                                <v-avatar size="30px">
                                    <img
                                        alt="Avatar"
                                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col cols="5" class="text-center text-md-left">
                                <strong>Google</strong>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn text color="success">Active</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row align="center">
                            <v-col cols="3" class="d-flex justify-center">
                                <v-avatar size="30px">
                                    <img
                                        alt="Avatar"
                                        src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col cols="5" class="text-center text-md-left">
                                <strong>Linkedin</strong>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn text color="orange">Inactive</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row align="center">
                            <v-col cols="3" class="d-flex justify-center">
                                <v-avatar size="30px">
                                    <img
                                        alt="Avatar"
                                        src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col cols="5" class="text-center text-md-left">
                                <strong>Facebook</strong>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn text color="orange">Inactive</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row align="center">
                            <v-col cols="3" class="d-flex justify-center">
                                <v-avatar size="30px">
                                    <img
                                        alt="Avatar"
                                        src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col cols="5" class="text-center text-md-left">
                                <strong>Github</strong>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn text color="orange">Inactive</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>

                <v-card
                    color="grey lighten-5"
                    class="mx-auto my-4 rounded-form"
                    width="80%"
                    elevation="10"
                >
                    <v-card-title>My login info</v-card-title>
                    <v-card-subtitle>
                        You can see the list of devices that have been logged in with your account.
                    </v-card-subtitle>
                    <v-card
                        class="mx-auto align-center justify-center"
                        width="90%"
                        rounded="3"
                        flat
                    >
                        <v-row align="center">
                            <v-col cols="2" class="d-flex justify-center">
                                <v-avatar size="30px">
                                    <img
                                        alt="Avatar"
                                        src="https://upload.wikimedia.org/wikipedia/commons/e/e1/Google_Chrome_icon_%28February_2022%29.svg"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col cols="6" class="text-center text-md-left text-bold">
                                <v-card-text>IP: 66.249.70.131</v-card-text>
                                <v-card-text>30 minutes ago</v-card-text>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn text color="success">Current</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row align="center">
                            <v-col cols="2" class="d-flex justify-center">
                                <v-avatar size="30px">
                                    <img
                                        alt="Avatar"
                                        src="https://upload.wikimedia.org/wikipedia/commons/5/52/Safari_browser_logo.svg"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col cols="6" class="text-center text-md-left text-bold">
                                <v-card-text>IP: 156.146.59.13</v-card-text>
                                <v-card-text>1 day and 10 hours ago</v-card-text>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-btn text class="text-capitalize" color="orange">Log out</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                    </v-card>
                </v-card>

            </v-row>
        </v-container>
        <v-card-actions>
            <v-btn
                color="primary"
                class="text-capitalize"
            >
                Restore
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="text-capitalize"
                color="success"
                type="submit"
            >
                Save
            </v-btn>
        </v-card-actions>


    </div>
</template>

<script>

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import VueMoment from 'vue-jalali-moment';

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props: [
            'user',
        ],
        data: () => ({
            show1: true,
            show2: true,
            password: '',
            rePassword: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'At least 8 characters',
            },
            snackbarSetHoliday: false,
            dialogVocation: false,
            dialogVocation2: false,
            dialogAllVocation: false,
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation': '',
                    'car_number': '',
                    'pic': ''
                },
            dateTommorow: '',
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1

            //pi chart
            seriesPi: [14, 23, 21, 17],
            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],

            //
            access_level: '',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number: [4, 2, 1, 1, 1, 1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        // data() {
        //     return {
        //         labels: ['Impressions', 'Add To Cart', 'Buy'],
        //         subLabels: ['Direct', 'Social Media', 'Ads'],
        //         values: [
        //             // with the given Labels and SubLabels here's what the values represent:
        //             //
        //             // Direct, Social, Ads
        //             //    |      |     |
        //             //    v      v     v
        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
        //             [3000, 1700, 1000], // Segments of "Add To Cart"
        //             [600,  200,  130]   // Segments of "Buy"
        //         ],
        //         colors: [
        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        //         ],
        //         direction: 'horizontal',
        //         gradientDirection: 'horizontal',
        //         height: 300,
        //         width: 800
        //     };
        // },
        created() {
            // console.log(this.$refs.funnel);
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;
        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            ;
            this.access_level = this.getAccessLevel();
        },
        methods: {
            increase() {
                this.height += 10
            },
            EditPassword: function () {
                this.dialogVocation = false;
                axios.put('../user/' + this.$store.state.users.id, {'password': this.password})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            EditVacation: function (num) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                axios.put('../user/' + this.$store.state.users.id, {'tomorrow_vacation': num})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            EditAllVacation: function (num) {
                this.dialogAllVocation = false;

                axios.put('../users/all', {'holiday': num})
                    .then(response => {
                        this.snackbarSetHoliday = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'Management'
                else if (this.$store.state.users.access_level == "2") return 'Operator'
                else if (this.$store.state.users.access_level == "3") return 'Installation expert'
                else if (this.$store.state.users.access_level == "4") return 'Damage expert'
                else if (this.$store.state.users.access_level == "5") return 'Support specialist'
                else if (this.$store.state.users.access_level == "7") return 'Responsible technician'
                else if (this.$store.state.users.access_level == "8") return 'Security and inspection'
                else return 'Unapproved user'
            },
        },
        computed: {
            passwordConfirmationRule() {
                return () => (this.password === this.rePassword) || 'The selected password and its repetition are not the same'
            },
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text {
        font-family: IRANSans !important;
    }

    .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .test {
        font-size: 0.875rem;
    }

    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }

    /deep/ .v-text-field {
        width: 90%;
    }

    .v-select {
        width: 90%;
        align-content: center;
    }

    .v-btn {
        text-transform: capitalize !important;
    }

    .title-wrap {
        overflow-wrap: break-word; /* This helps to break long words */
        word-wrap: break-word;     /* This is an alias for overflow-wrap */
        white-space: normal;       /* This ensures that text wraps normally */
        word-break: keep-all; /* Prevents breaking words */
    }
</style>
