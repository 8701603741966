<template>
    <v-container>
        <!-- Card for Welcome Message -->
        <v-card class="mx-auto card-gradient" dark max-width="100%" shaped>
            <v-card-title class="rounded-card">
                <span class="headline mr-2">Welcome to Your Home Data Details</span>
                <v-icon left>mdi-hand-wave</v-icon>
            </v-card-title>
            <v-card-text>
                <p>
                    You've reached this panel where all information related to your home is monitored, and you can get some insight related to your home promptly.
                </p>
                <p>Happy to assist you.</p>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn color="#6a5067ff" fab dark small>
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- Card for Smoke Detectors and Fire Alarms -->
        <v-row>
            <v-col cols="12" md="4" lg="4">
                <v-card class="card-gradient mx-auto my-4 elevation-10" outlined shaped>
                    <v-card-title class="headline px-4 py-2">
                        <v-icon left color="white">mdi-home-circle</v-icon>
                        Overall Overview of Home
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list three-line>
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6">Risk Profile</v-list-item-title>
                                    <v-list-item-subtitle>Analysis of current risk factors and potential threats</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon color="green">mdi-account-circle</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item-action class="d-flex justify-center">
                                <apexchart type="radialBar" :options="chartOptions" :series="series" class="radial-bar-chart" />
                            </v-list-item-action>
                            <v-divider></v-divider>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6">Recent Risk Signals</v-list-item-title>
                                    <v-list-item-subtitle>Quick glance at signals detected within the last 24 hours.</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon color="orange">mdi-bell-ring</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-row>
                                <v-col cols="6">
                                    <v-card flat class="mx-auto my-card" max-width="200">
                                        <v-card-text class="d-flex align-center justify-space-between">
                                            <v-avatar size="36">
                                                <v-icon color="red" large>mdi-alert</v-icon>
                                            </v-avatar>
                                            <div>
                                                <div class="text-subtitle-1 font-weight-medium">Severe warning</div>
                                                <div class="text-caption">49%</div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="6">
                                    <v-card flat class="mx-auto my-card" max-width="200">
                                        <v-card-text class="d-flex align-center justify-space-between">
                                            <v-avatar size="36">
                                                <v-icon color="orange" large>mdi-alert-circle</v-icon>
                                            </v-avatar>
                                            <div>
                                                <div class="text-subtitle-1 font-weight-medium">Medium warning</div>
                                                <div class="text-caption">49%</div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6">Maintenance Notifications</v-list-item-title>
                                    <v-list-item-subtitle>Scheduled maintenance and service alerts.</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon color="blue">mdi-wrench</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-card flat tile class="pa-3 mx-auto my-4" max-width="90%">
                                <v-list two-line subheader>
                                    <v-subheader>What to do for better performance:</v-subheader>
                                    <v-list-item-group color="primary">
                                        <v-list-item v-for="(tip, index) in maintenanceTips" :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ tip.title }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ tip.description }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6">Sensor Overview</v-list-item-title>
                                    <v-list-item-subtitle>Status report on all connected sensors and devices.</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon color="green">mdi-eye-outline</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-row>
                                <v-col class="mx-auto" cols="3" v-for="(sensor, index) in sensors" :key="index">
                                    <v-card flat class="my-card-sensor">
                                        <v-card-text class="sensor-card-content">{{ sensor.name }}</v-card-text>
                                        <template class="justify-center mb-10">
                                            <img :src="sensor.icon" width="20px" class="sensor-icon">
                                        </template>
                                        <v-card-text class="sensor-card-content">{{ sensor.count }}</v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>

            <v-col cols="12" md="8" lg="8">
                <v-row>
                    <v-divider></v-divider>

                    <v-col cols="12">
                        <v-card class="dashboard-card mx-auto elevation-10" outlined shaped>
                            <v-card-title class="card-text-gradient headline px-4 py-2 d-flex align-center v-card-title-font">
                                <v-avatar size="32" class="mr-3">
                                    <img src="../../../../assets/icons/Customer/SmokeSensorWhite.svg" alt="Sensor Icon">
                                </v-avatar>
                                <span class="text-truncate v-card-title-font">Smoke Detectors and Fire Alarms</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="card-content d-flex align-center justify-space-between">
                                <div>Honeywell, Smoke detector, model T9, Last updated: 1 hour ago</div>
                                <v-btn-toggle v-model="timeRangeSmoke" @change="setTimeRangeSmoke" class="ml-4" mandatory>
                                    <v-btn small value="24h">Last 24 Hours</v-btn>
                                    <v-btn small value="week">Last Week</v-btn>
                                </v-btn-toggle>
                                <v-spacer></v-spacer>
                                <div class="d-flex align-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                :color="getBatteryIconAndColor(getLatestBatteryLevel(dataSmoke)).color"
                                                v-bind="attrs"
                                                v-on="on">
                                                {{ getBatteryIconAndColor(getLatestBatteryLevel(dataSmoke)).icon }}
                                            </v-icon>
                                        </template>
                                        <span>Battery Level: {{ getLatestBatteryLevel(dataSmoke) }}%</span>
                                    </v-tooltip>
                                    <span class="mr-5" :style="{ color: getBatteryColor(getLatestBatteryLevel(dataSmoke)) }">{{ getLatestBatteryLevel(dataSmoke) }}%</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                :color="dataSmoke[dataSmoke.length - 1].device_status === true ? 'green' : 'orange'"
                                                class="mr-2"
                                                v-bind="attrs" v-on="on">
                                                {{ dataSmoke[dataSmoke.length - 1].device_status === true ? 'mdi-wifi' : 'mdi-wifi-off' }}
                                            </v-icon>
                                        </template>
                                        <span>Sensor Status: {{ dataSmoke[dataSmoke.length - 1].device_status === true ? 'Online' : 'Offline' }}</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                :color="dataSmoke[dataSmoke.length - 1].test_status === true ? 'green' : 'orange'"
                                                class="mr-2"
                                                v-bind="attrs" v-on="on">
                                                {{ dataSmoke[dataSmoke.length - 1].test_status === true ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle' }}
                                            </v-icon>
                                        </template>
<!--                                        <span>-->
                                        <span>Test Status: {{ dataSmoke[dataSmoke.length - 1].test_status === true ? 'Ok' : 'No' }}</span>
                                    </v-tooltip>
                                </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <template class="mt-20">
                                <apexchart type="line" height="350" :options="chartOptionsSmoke" :series="seriesSmoke"></apexchart>
                                <v-divider></v-divider>
                                <apexchart type="line" height="350" :options="fireAlarmChartOptions" :series="fireAlarmSeries"></apexchart>
                            </template>
                            <v-card-actions class="justify-end">
                                <v-btn color="#6a5067ff" fab dark small>
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card class="dashboard-card mx-auto elevation-10" outlined shaped>
                            <v-card-title class="card-text-gradient headline px-4 py-2 d-flex align-center v-card-title-font">
                                <v-avatar size="32" class="mr-3">
                                    <img src="../../../../assets/icons/Customer/DoorSensorWhite.svg" alt="Sensor Icon">
                                </v-avatar>
                                <span class="text-truncate v-card-title-font">Smart Doors and Windows</span>
                                <v-spacer></v-spacer>
                                <div class="d-flex align-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="white" v-bind="attrs" v-on="on">mdi-battery</v-icon>
                                        </template>
                                        <span>Battery Level: 50%</span>
                                    </v-tooltip>
                                    <span class="mr-5" style="font-size: .7em;">50%</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="#edd7a4" class="mr-2" v-bind="attrs" v-on="on">mdi-wifi-off</v-icon>
                                        </template>
                                        <span>Sensor Status: Offline</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="#9ee8c4" class="mr-2" v-bind="attrs" v-on="on">mdi-checkbox-marked-circle</v-icon>
                                        </template>
                                        <span>Tamper Status: Ok</span>
                                    </v-tooltip>
                                </div>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="card-content">
                                Honeywell 5816 Wireless Door/Window Sensor
                            </v-card-text>
                            <v-divider></v-divider>
                            <p>Periodic and Event-Driven Changes - Last 24 Hours</p>
                            <apexchart type="area" height="350" :options="eventDrivenOptions" :series="eventDrivenSeries"></apexchart>
                            <v-divider></v-divider>
                            <p>Door State Changes Over Time</p>
                            <apexchart type="line" height="350" :options="stateChangeOptions" :series="stateChangeSeries"></apexchart>
                            <v-divider></v-divider>
                            <p>Door Open vs. Closed - Last 24 Hours</p>
                            <apexchart type="pie" height="350" :options="pieChartOptions" :series="pieChartSeries"></apexchart>
                            <v-card-actions class="justify-end">
                                <v-btn color="#6a5067ff" fab dark small>
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card class="dashboard-card mx-auto elevation-10" outlined shaped>
                            <v-card-title class="card-text-gradient headline px-4 py-2 d-flex align-center v-card-title-font">
                                <v-avatar size="32" class="mr-3">
                                    <img src="../../../../assets/icons/Customer/WaterLeakageSensorWhite.svg" alt="Sensor Icon">
                                </v-avatar>
                                <span class="text-truncate v-card-title-font">Smart Water Leakage Sensors</span>
                                <v-spacer></v-spacer>
                                <div class="d-flex align-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="white" v-bind="attrs" v-on="on">mdi-battery</v-icon>
                                        </template>
                                        <span>Battery Level: 50%</span>
                                    </v-tooltip>
                                    <span class="mr-5" style="font-size: .7em;">50%</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="#9ee8c4" class="mr-2" v-bind="attrs" v-on="on">mdi-wifi</v-icon>
                                        </template>
                                        <span>Sensor Status: Online</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="#9ee8c4" class="mr-2" v-bind="attrs" v-on="on">mdi-checkbox-marked-circle</v-icon>
                                        </template>
                                        <span>Test Status: Ok</span>
                                    </v-tooltip>
                                </div>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="card-content">
                                Honeywell Lyric Wi-Fi Water Leak
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="card-content">
                                <apexchart type="area" :options="waterLeakageOptions" :series="waterLeakageSeries" height="350"></apexchart>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn color="#6a5067ff" fab dark small>
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-card class="dashboard-card mx-auto elevation-10" outlined shaped>
                            <v-card-title class="card-text-gradient headline px-4 py-2 d-flex align-center v-card-title-font">
                                <v-avatar size="32" class="mr-3">
                                    <img src="../../../../assets/icons/Customer/MotionWhite.svg" alt="Sensor Icon">
                                </v-avatar>
                                <span class="text-truncate v-card-title-font">Motion Detectors</span>
                                <v-spacer></v-spacer>
                                <div class="d-flex align-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="white" v-bind="attrs" v-on="on">mdi-battery</v-icon>
                                        </template>
                                        <span>Battery Level: 50%</span>
                                    </v-tooltip>
                                    <span class="mr-5" style="font-size: .7em;">50%</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="#9ee8c4" class="mr-2" v-bind="attrs" v-on="on">mdi-wifi</v-icon>
                                        </template>
                                        <span>Sensor Status: Online</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="#9ee8c4" class="mr-2" v-bind="attrs" v-on="on">mdi-checkbox-marked-circle</v-icon>
                                        </template>
                                        <span>Test Status: Ok</span>
                                    </v-tooltip>
                                </div>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="card-content">
                                Honeywell 5800PIR-RES
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="card-content">
                                <apexchart type="bar" :options="chartOptionsMotion" :series="seriesMotion"></apexchart>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn color="#6a5067ff" fab dark small>
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            apexchart: VueApexCharts,
        },
        data: () => ({
                dataSmoke: [],
                timeRangeSmoke: '24h', // default time range
                seriesMotion: [
                    {
                        name: 'Motion Events',
                        data: [30, 40, 45, 50, 49, 60, 70]
                    }
                ],
                chartOptionsMotion: {
                        chart: {
                                type: 'bar',
                            height: 350,
                        },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: false,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    }
                },
                waterLeakageOptions: {
                    chart: {
                        type: 'area',
                        stacked: false,
                        height: 350,
                        zoom: {
                            type: 'x',
                            enabled: true,
                            autoScaleYaxis: true
                        },
                        toolbar: {
                            autoSelected: 'zoom'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0,
                    },
                    title: {
                        text: 'Water Leakage Over Time',
                        align: 'left'
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.5,
                            opacityTo: 0,
                            stops: [0, 90, 100]
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                        },
                        title: {
                            text: 'Water Detected (in Liters)'
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                    },
                    tooltip: {
                        shared: false,
                        y: {
                            formatter: function (val) {
                                return val.toFixed(0) + " Liters";
                            }
                        }
                    }
                },
                waterLeakageSeries: [
                    {
                        name: 'Water Detected',
                        data: [
                            {x: new Date('2024-01-01').getTime(), y: 10},
                            {x: new Date('2024-01-02').getTime(), y: 30},
                            {x: new Date('2024-01-03').getTime(), y: 25},
                            {x: new Date('2024-01-04').getTime(), y: 18},
                            {x: new Date('2024-01-05').getTime(), y: 22},
                        ]
                    }
                ],
                stateChangeOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: false
                        }
                    },
                    stroke: {
                        width: 3,
                        curve: 'straight'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: ['2024-01-01', '2024-01-02', '2024-01-03', '2024-01-04', '2024-01-05']
                    },
                    tooltip: {
                        x: {
                            format: 'dd MMM yyyy'
                        }
                    },
                    colors: ['#00E396', '#FF4560'],
                },
                stateChangeSeries: [
                    {
                        name: 'Door Opened',
                        data: [5, 6, 3, 3, 4]
                    },
                    {
                        name: 'Door Closed',
                        data: [6, 5, 2, 3, 5]
                    }
                ],
                pieChartOptions: {
                    labels: ['Open', 'Closed'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                pieChartSeries: [44, 55],
                eventDrivenOptions: {
                    chart: {
                        height: 350,
                        type: 'area',
                        toolbar: {
                            show: true,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true
                            },
                            autoSelected: 'zoom'
                        },
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            speed: 800
                        },
                        markers: {
                            size: 5,
                            hover: {
                                size: 7
                            }
                        }
                    },
                    stroke: {
                        width: 3,
                        curve: 'straight'
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            format: 'HH:mm'
                        }
                    },
                    tooltip: {
                        x: {
                            format: 'HH:mm'
                        }
                    },
                    colors: ['#00E396', '#FF4560'],
                    annotations: {
                        points: [
                            {
                                x: new Date('2024-01-01T14:30:00').getTime(),
                                y: 1,
                                marker: {
                                    size: 8,
                                    fillColor: '#fff',
                                    strokeColor: '#e33845',
                                    radius: 1
                                },
                                label: {
                                    text: 'Door Opened'
                                }
                            }
                        ]
                    }
                },
                eventDrivenSeries: [
                    {
                        name: 'Periodic Data',
                        data: [
                            {x: '2024-01-01T00:00:00', y: 0},
                            {x: '2024-01-01T04:00:00', y: 0},
                            {x: '2024-01-01T08:00:00', y: 0},
                            {x: '2024-01-01T12:00:00', y: 0},
                            {x: '2024-01-01T16:00:00', y: 1},
                            {x: '2024-01-01T20:00:00', y: 1}
                        ]
                    }
                ],
                fireAlarmChartOptions: {
                    chart: {
                        toolbar: {
                            show: true,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true
                            },
                            autoSelected: "zoom"
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        categories: []
                    },
                    stroke: {
                        curve: "smooth"
                    },
                    dataLabels: {
                        enabled: false
                    },
                    title: {
                        text: "Fire Alarm Frequency",
                        align: "left"
                    },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"],
                            opacity: 0.5
                        }
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0, 90, 100]
                        }
                    },
                    colors: ["#FFA500"]
                },
                fireAlarmSeries: [
                    {
                        name: "Number of Alarms",
                        data: []
                    }
                ],
                chartOptionsSmoke: {
                    chart: {
                        toolbar: {
                            show: true,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true
                            },
                            autoSelected: "zoom"
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        labels: {
                            format: "dd MMM HH:mm"
                        }
                    },
                    stroke: {
                        curve: "smooth"
                    },
                    dataLabels: {
                        enabled: false
                    },
                    title: {
                        text: "Smoke Detector Data",
                        align: "left"
                    },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"],
                            opacity: 0.5
                        }
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0, 90, 100]
                        }
                    },
                    yaxis: [
                        {
                            title: {
                                text: "Alerts"
                            }
                        },
                        {
                            opposite: true,
                            title: {
                                text: "Temperature (°C)"
                            }
                        },
                        {
                            opposite: true,
                            title: {
                                text: "Humidity (%)"
                            }
                        }
                    ],
                    colors: ["#FF4560", "#00E396", "#008FFB"],
                    tooltip: {
                        x: {
                            format: 'dd MMM yyyy HH:mm'
                        },
                        y: {
                            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
                                let seriesName = w.globals.seriesNames[seriesIndex];
                                let formattedValue;
                                if (seriesName === 'Number of Alerts') {
                                    formattedValue = value + " alerts";
                                } else if (seriesName === 'Temperature') {
                                    formattedValue = value + " °C";
                                } else if (seriesName === 'Humidity') {
                                    formattedValue = value + " %";
                                }
                                return formattedValue;
                            }
                        }
                    },
                },
                seriesSmoke: [
                    {
                        name: "Number of Alerts",
                        type: "line",
                        data: []
                    },
                    {
                        name: "Temperature",
                        type: "line",
                        yaxis: 1,
                        data: []
                    },
                    {
                        name: "Humidity",
                        type: "line",
                        yaxis: 2,
                        data: []
                    }
                ],
            maintenanceTips: [
                {
                    title: "Smart Smoke Detector and Fire Alarm",
                    description: "1- Test Regularly, 2- Clean the Detectors, 3- Battery Check and Replacement."
                },
                {
                    title: "Smart Door Sensors",
                    description: "1- Battery Maintenance, 2- Alignment and Sensitivity, 3- Firmware Updates."
                },
                {
                    title: "Smart Motion Detectors",
                    description: "1- Field of View Check, 2- Sensitivity Adjustment, 3- Cleaning the Sensor."
                },
                {
                    title: "Smart Water Leakage Sensors",
                    description: "1- Regular Testing, 2- Placement Check, 3- Dry and Clean."
                },
            ],
            series: [10], // This value is just an example, set it according to your data
            chartOptions: {
                chart: {
                    type: 'radialBar',
                    offsetY: -10
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            name: {
                                fontSize: '12px',
                                color: '#444',
                                offsetY: 50
                            },
                            value: {
                                offsetY: 20,
                                fontSize: '20px',
                                color: '#444',
                                formatter: function (val) {
                                    return val + "%";
                                }
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        shadeIntensity: 0.15,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 65, 91]
                    },
                },
                stroke: {
                    dashArray: 4
                },
                labels: ['Low Risk'], // Customize based on your need
            },
            sensors: [
                {
                    name: "Smoke and fire",
                    icon: "../../../../assets/icons/Customer/SmokeSensor.svg",
                    count: 1
                },
                {
                    name: "Motion",
                    icon: "../../../../assets/icons/Customer/Motion.svg",
                    count: 1
                },
                {
                    name: "Door and Window",
                    icon: "../../../../assets/icons/Customer/DoorSensor.svg",
                    count: 1
                },
                {
                    name: "Water leakage",
                    icon: "../../../../assets/icons/Customer/WaterLeakageSensor.svg",
                    count: 1
                }
            ]
        }),
        methods: {
            fetchData() {
                console.log('Start fetching');
                this.dataSmoke = [
                    {
                        "time": "2024-06-28T18:00:29.985854Z",
                        "alarm_status": true,
                        "brand_name": "honeywell",
                        "device_status": true,
                        "humidity": 45.6,
                        "maintenance_required": false,
                        "sensor_type": "smoke",
                        "smoke_level": 3.5,
                        "temperature": 24.3,
                        "test_status": true,
                        "topic_id": "topic-7994xdfr",
                        "battery_level": 75
                    },
                    {
                        "time": "2024-06-28T18:21:52.965754Z",
                        "alarm_status": true,
                        "brand_name": "honeywell",
                        "device_status": true,
                        "humidity": 45.6,
                        "maintenance_required": false,
                        "sensor_type": "smoke",
                        "smoke_level": 3.5,
                        "temperature": 24.3,
                        "test_status": true,
                        "topic_id": "topic-7994xdfr",
                        "battery_level": 72
                    },
                    {
                        "time": "2024-06-28T12:28:10.198899Z",
                        "alarm_status": true,
                        "brand_name": "honeywell",
                        "device_status": true,
                        "humidity": 45.6,
                        "maintenance_required": false,
                        "sensor_type": "smoke",
                        "smoke_level": 3.5,
                        "temperature": 24.3,
                        "test_status": true,
                        "topic_id": "topic-7994xdfr",
                        "battery_level": 35
                    }
                ];
            },
            getBatteryIconAndColor(level) {
                if (level >= 80) {
                    return {icon: 'mdi-battery', color: 'green'};
                } else if (level >= 60) {
                    return {icon: 'mdi-battery-80', color: 'green'};
                } else if (level >= 40) {
                    return {icon: 'mdi-battery-60', color: 'blue'};
                } else if (level >= 20) {
                    return {icon: 'mdi-battery-40', color: 'orange'};
                } else {
                    return {icon: 'mdi-battery-20', color: 'red'};
                }
            },
            getBatteryColor(level) {
                if (level >= 80) {
                    return 'green';
                } else if (level >= 60) {
                    return 'green';
                } else if (level >= 40) {
                    return 'blue';
                } else if (level >= 20) {
                    return 'orange';
                } else {
                    return 'red';
                }
            },
            getLatestBatteryLevel(data) {
                if (data.length === 0) return null;
                const latestEntry = data[data.length - 1];
                return latestEntry.battery_level || 'Battery level not available';
            },
            setTimeRangeSmoke(range) {
                this.timeRangeSmoke = range;
                const now = new Date();
                let filteredData = this.dataSmoke;

                if (range === '24h') {
                    filteredData = this.dataSmoke.filter(entry => new Date(entry.time) > new Date(now - 24 * 60 * 60 * 1000));
                } else if (range === 'week') {
                    filteredData = this.dataSmoke.filter(entry => new Date(entry.time) > new Date(now - 7 * 24 * 60 * 60 * 1000));
                }

                this.seriesSmoke = [
                    {
                        name: 'Number of Alerts',
                        type: 'line',
                        data: filteredData.map(entry => {
                            return {x: new Date(entry.time).getTime(), y: entry.smoke_level};
                        })
                    },
                    {
                        name: 'Temperature',
                        type: 'line',
                        yaxis: 1,
                        data: filteredData.map(entry => {
                            return {x: new Date(entry.time).getTime(), y: entry.temperature};
                        })
                    },
                    {
                        name: 'Humidity',
                        type: 'line',
                        yaxis: 2,
                        data: filteredData.map(entry => {
                            return {x: new Date(entry.time).getTime(), y: entry.humidity};
                        })
                    }
                ];

                this.fireAlarmSeries = [
                    {
                        name: "Number of Alarms",
                        data: filteredData.map(entry => {
                            return {x: new Date(entry.time).getTime(), y: entry.alarm_status ? 1 : 0};
                        })
                    }
                ];
            }
        },
        created() {
            this.fetchData();
        }
    }
</script>

<style scoped>
    .v-card-title-font {
        font-size: calc(7px + 1vmin);
        white-space: nowrap;
    }

    @media (max-width: 600px) {
        .v-card-title-font {
            font-size: 4vw;
        }
    }

    .my-card-sensor {
        width: 95%;
    }

    .sensor-card-content {
        font-size: calc(5px + 1vmin);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 57pt;
    }

    .sensor-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: auto;
    }

    .v-icon.x-large {
        font-size: 40px;
    }

    .mode-card {
        background-color: #424242;
        color: white;
        border-radius: 5px;
    }

    .highlighted {
        background-color: #555;
    }

    .my-card:hover {
        background-color: inherit !important;
    }

    .radial-bar-chart .apexcharts-radial-series .apexcharts-radialbar-area {
        fill: purple;
        transition: fill 0.2s ease-in-out;
    }

    .radial-bar-chart:hover .apexcharts-radial-series .apexcharts-radialbar-area {
        fill: purple;
    }

    .my-card {
        width: 100%;
        background-color: inherit;
        box-shadow: none;
    }

    .my-list-item:hover .my-card {
        background-color: inherit !important;
    }

    .radial-bar-chart {
        max-width: 150px;
    }

    .headline {
        color: white;
    }

    .card-gradient {
        border-radius: 16px;
        background: linear-gradient(135deg, #7b5d78ff 30%, #9e768f 100%);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    }
    .card-text-gradient {
        background: linear-gradient(135deg, #7b5d78ff 30%, #9e768f 100%);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    }

    .rounded-card {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .v-card-text p {
        color: #ECEFF1;
    }

    .v-row {
        align-items: stretch;
    }

    .dashboard-card {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .card-content {
        flex: 1;
    }

    .dashboard-card:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }

    .v-list-item-action .mdi-alert-circle {
        color: #e57373;
    }

    .v-list-item-action .mdi-bell-ring {
        color: #ffb74d;
    }

    .v-list-item-action .mdi-wrench {
        color: #64b5f6;
    }

    .v-list-item-action .mdi-eye-outline {
        color: #68b36b;
    }

    .v-btn .mdi-plus-circle {
        font-size: 24px;
    }

    .v-btn-toggle .v-btn {
        min-width: 100px;
        font-weight: bold;
    }
    </style>










