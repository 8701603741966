<template>
    <v-container class="grey lighten-5 mb-6 mx-auto" align="center" v-if="$store.state.users.access_level< 5">
        <v-card v-if="images2" max-width="550px" class="mx-auto" elevation="10" outlined
                shaped>
            <v-img :src="images2" contain max-height="500px">
                <!--                <v-img :src="require('../../../../storage/app/public/images/publics/Public_100420200728185f7979927beb2.jpg')" contain max-height="200px">-->

                <!--                -->
            </v-img>
            <v-card-title class="layout justify-center" style="font-size: smaller">
                Image taken in the place
            </v-card-title>
        </v-card>
        <v-card-title class="layout justify-center">
            Detail information of house
        </v-card-title>
        <!--    </v-container>-->
        <!--    <v-container-->
        <!--            class="grey lighten-5 mb-6 mx-auto" align="center"-->
        <!--        >-->
        <v-card-actions class="justify-center mx-auto">

            <v-flex class="justify-center">
                <v-card-actions class="justify-center mx-auto">

                    <v-layout align-center row wrap>
                        <v-card
                            class="mx-4 my-2"
                            outlined
                            shaped
                        >

                            <v-row class="mx-auto">
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="chip_id"
                                        label="ای دی چیپ*"
                                        persistent-hint
                                        required
                                        :disabled="!(doginfoshow.chip_id==='')"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="doginfoshow.race"
                                        label="نژاد"
                                        persistent-hint
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="doginfoshow.age"
                                        label="سن"
                                        persistent-hint
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="doginfoshow.sex"
                                        label="جنس"
                                        persistent-hint
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-card>
                        <v-card
                            class="mx-4 my-2"
                            outlined
                            shaped
                        >
                            <v-row class="mx-auto">
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions class="justify-center">
                                        <v-switch
                                            v-model="vaccine_a"
                                            label="واکس الف"
                                            color="success"
                                            hide-details
                                            id="styled-input"
                                            :readonly="(doginfoshow.vaccine_a === 'true')"
                                        >

                                        </v-switch>
                                    </v-card-actions>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"

                                >
                                    <v-card-actions flat class="layout justify-center my-3"
                                                    :readonly="(doginfoshow.vaccine_a=== 'true')">
                                        <span class="text-sm-justify grey--text"> آخرین زمان واکسن الف </span>
                                        <v-date-picker :disabled="(!vaccine_a)||(doginfoshow.vaccine_a === 'true')"
                                                     v-model="doginfoshow.vaccine_a_Last"
                                        ></v-date-picker>
                                    </v-card-actions>

                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions flat class="layout justify-center my-3"
                                    >
                                        <span class="text-sm-justify grey--text">زمان بعدی واکسن الف </span>
                                        <date-picker :disabled="(!vaccine_a)||(doginfoshow.vaccine_a=== 'true')"
                                                     v-model="doginfoshow.vaccine_a_Next"
                                                     :readonly="(doginfoshow.vaccine_a === 'true')"
                                        ></date-picker>
                                    </v-card-actions>

                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select :items="[doginfoshow.vaccine_a_vet_name , $store.state.users.name]"
                                              label="نام دکتر تزریق کننده ی واکسن الف"
                                              v-model="doginfoshow.vaccine_a_vet_name "
                                              :disabled="!vaccine_a"
                                              :readonly="(doginfoshow.vaccine_a=== 'true')"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <v-row class="mx-auto">

                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions class="justify-center">
                                        <v-switch
                                            v-model="vaccine_b"
                                            label="واکس ب"
                                            color="success"
                                            hide-details
                                            id="styled-input"
                                            :readonly="(doginfoshow.vaccine_b=== 'true' )"
                                        >
                                        </v-switch>
                                    </v-card-actions>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions flat class="justify-center my-3">
                                        <span class="text-sm-justify grey--text">اخرین زمان واکسن ب</span>
                                        <v-date-picker
                                            :disabled="(!vaccine_b) || (doginfoshow.vaccine_b === 'true')"
                                            v-model="doginfoshow.vaccine_b_Last"></v-date-picker>
                                    </v-card-actions>

                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions flat class="justify-center my-3">
                                        <span class="text-sm-justify grey--text">زمان بعدی واکسن ب</span>
                                        <v-date-picker
                                            :disabled="(!vaccine_b) || (doginfoshow.vaccine_b === 'true')"
                                            v-model="doginfoshow.vaccine_b_Next"></v-date-picker>
                                    </v-card-actions>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select :items="[doginfoshow.vaccine_b_vet_name, $store.state.users.name]"
                                              label="نام دکتر تزریق کننده ی واکسن الف"
                                              v-model="doginfoshow.vaccine_b_vet_name "
                                              :readonly="(doginfoshow.vaccine_b === 'true')"
                                              :disabled="!vaccine_b"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <v-row class="mx-auto">
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions class="justify-center">
                                        <v-switch
                                            v-model="vaccine_c"
                                            label="واکس ج"
                                            color="success"
                                            hide-details
                                            id="styled-input"
                                            :readonly="(doginfoshow.vaccine_c === 'true')"
                                        >
                                        </v-switch>
                                    </v-card-actions>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions flat class="justify-center my-3">
                                        <span class="text-sm-justify grey--text"> اخرین زمان واکسن ج</span>
                                        <v-date-picker
                                            :disabled="(!vaccine_c)||(doginfoshow.vaccine_c === 'true')"
                                            v-model="doginfoshow.vaccine_c_Last"></v-date-picker>
                                    </v-card-actions>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions flat class="justify-center my-3">
                                        <span class="text-sm-justify grey--text"> زمان بعدی واکسن ج</span>
                                        <v-date-picker
                                            :disabled="(!vaccine_c)||(doginfoshow.vaccine_c === 'true')"
                                            v-model="doginfoshow.vaccine_c_Next"></v-date-picker>
                                    </v-card-actions>

                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select :items="[doginfoshow.vaccine_c_vet_name, $store.state.users.name]"
                                              label="نام دکتر تزریق کننده ی واکسن ج"
                                              v-model="doginfoshow.vaccine_c_vet_name"
                                              :disabled="!vaccine_c"
                                              :readonly="(doginfoshow.vaccine_c === 'true' )"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <v-row class="mx-auto">
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-card-actions class="justify-center">
                                        <v-switch
                                            v-model="sterilized"
                                            label="عقیم سازی"
                                            color="success"
                                            hide-details
                                            id="styled-input"
                                            :readonly="(doginfoshow.sterilized === 'true')"
                                        >
                                        </v-switch>
                                    </v-card-actions>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    class="my-0"
                                >

                                    <v-card-actions flat class="justify-center my-3">
                                        <span class="text-sm-justify grey--text"> زمان عقیم سازی</span>
                                        <v-date-picker
                                            :disabled="(!sterilized)|| (doginfoshow.sterilized=== 'true')"
                                            v-model="doginfoshow.sterilized_date"></v-date-picker>
                                    </v-card-actions>

                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select :items="[doginfoshow.sterilized_vet_name , $store.state.users.name]"
                                              label="نام دکتر عقیم کننده"
                                              v-model="doginfoshow.sterilized_vet_name"
                                              :disabled="!sterilized"
                                              :readonly="(doginfoshow.sterilized === 'true')"

                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card>

                    </v-layout>
                </v-card-actions>
            </v-flex>
        </v-card-actions>
        <v-card flat>
            <v-flex class="justify-center" v-if="$store.state.users.access_level==3 || $store.state.users.access_level==1">
                <v-card-actions class="justify-center">
                    <v-spacer></v-spacer>
                    <v-btn outlined text class="m-1" max-width="200" min-width="120" :to="'../profiles'">لغو</v-btn>
                    <v-btn depressed color="green" class="m-2" @click="editDofInfo" max-width="200" min-width="120" >ارسال</v-btn>
                </v-card-actions>
            </v-flex>
        </v-card>
    </v-container>


    <!--                <tr>            <td>عقیم سازی</td><td>{{doginfoshow.sterilized}}</td></tr>-->
    <!--                <tr>            <td>زمان عقیم سازی</td><td>{{doginfoshow.sterilized_date}}</td></tr>-->
    <!--                <tr>            <td>نام دکتر عقیم کننده</td><td>{{doginfoshow.sterilized_vet_name}}</td></tr>-->

    <!--                </tbody>-->
    <!--            </template>-->
    <!--        </v-simple-table>-->

    <!--<div>
    <div class="flex justify-between">
        <div href="#" class="text-blue-400" @click="$router.back()">
            < Back
        </div>
        <div class="relative">
    &lt;!&ndash;        <router-link  class="px-4 py-2 rounded textsm text-green-500 border border-green-500 font-bold mr-2" @click="edit">Edit</router-link>&ndash;&gt;
            <a href="#" class="px-4 py-2 rounded textsm text-green-500 border border-green-500 font-bold mr-2" @click="edit">Edit</a>
            <a href="#" class="px-4 py-2 rounded textsm text-red-500 border border-red-500 font-bold mr-2" @click="modal = ! modal">Delete</a>
            <div v-if="modal" class="absolute bg-blue-900 text-white rounded-lg z-20 p-8 w-64 right-0 mt-2 mr-6">
                <p>Are you sure want to delete this record?</p>
                <div class="flex items-center mt-6 justify-end">
                    <button class="text-white pr-4" @click="modal=!modal">Cancel</button>
                    <button class="px-4 py-2 bg-red-500 text-sm font-bold rounded text-white" @click="destroy">Delete</button>
                </div>
            </div>
        </div>
    </div>

        <div class="flex flex-col flex-1 h-screen overflow-y-hidden">
            <div class="h-16 px-6 border-b border-gray-400 flex items-center justify-between">
                <div class="flex items-center pt-6">
                    <PhotoCircle></PhotoCircle>

                    <p class="pl-5 text-xl">{{doginfoshow.race }}</p>
                </div>

                <p class="pt-6 text-gray-600 font-bold Uppercase text-sm">Age</p>
                <p class="pt-2 text-blue-400">{{doginfoshow.age }}</p>
                <p class="pt-6 text-gray-600 font-bold Uppercase text-sm">Sex</p>
                <p class="pt-2 text-blue-400">{{doginfoshow.sex }}</p>
                <p class="pt-6 text-gray-600 font-bold Uppercase text-sm">User</p>
                <p class="pt-2 text-blue-400">({{doginfoshow.catched_lat }},{{doginfoshow.catched_lon }})</p>
                <p class="pt-6 text-gray-600 font-bold Uppercase text-sm">Vaccinated</p>
                <p class="pt-2 text-blue-400">{{doginfoshow.vaccinated }}</p>
                <p class="pt-6 text-gray-600 font-bold Uppercase text-sm">Sterilized</p>
                <p class="pt-2 text-blue-400">{{doginfoshow.sterilized }}</p>
            </div>
        </div>
    &lt;!&ndash;    <LocationMap :doginfos="[doginfoshow, doginfoshow]"></LocationMap>&ndash;&gt;


    </div>-->
</template>

<script>
    import PhotoCircle from "../components/PhotoCircle";
    import LocationMap from "../components/LocationMap";
    import VuePersianDatetimePicker from 'vue-persian-datetime-picker'


    export default {
        name: "DogInfoShow",

        mounted() {
            axios.get('../homedata/' + this.$route.params.id)
                .then(response => {
                    this.doginfoshow = response.data[0].data;
                    this.vaccine_a = (this.doginfoshow.vaccine_a === 'true');
                    this.vaccine_b = (this.doginfoshow.vaccine_b === 'true');
                    this.vaccine_c = (this.doginfoshow.vaccine_c=== 'true');
                    this.sterilized = (this.doginfoshow.sterilized=== 'true');
                    this.chip_id = this.doginfoshow.chip_id;
                    this.images2 = '/storage/images/dogs/' + this.doginfoshow.first_fig;
                    //this.temp = response.data[0].data.race;
                    //this.doginfoshow= response.data[0].data;
                })
                .catch(error => {

                });
        },
        computed: {},
        components: {
            PhotoCircle,
            LocationMap,
            datePicker: VuePersianDatetimePicker

        },
        data: function () {
            return {
                doginfoshow: '',
                modal: false,
                temp: '',
                images2: '',
                date: '1399/11/2',
                notbool: true,
                vaccine_a: false,
                vaccine_b: false,
                vaccine_c: false,
                sterilized: false,
                chip_id: ''
            }
        },
        methods: {

            editDofInfo: function () {
                this.doginfoshow.vaccine_a = this.vaccine_a.toString();
                this.doginfoshow.vaccine_b = this.vaccine_b.toString();
                this.doginfoshow.vaccine_c = this.vaccine_c.toString();
                this.doginfoshow.sterilized = this.sterilized.toString();
                this.doginfoshow.chip_id = this.chip_id;
                axios.put('../homedata/' + this.doginfoshow.id , this.doginfoshow)
                    .then( response => {
                        console.log(response);
                        this.$router.push({name:'profiles'});
                    })
                    .catch(errors =>{
                        console.log(errors);
                    }).finally(()=>{

                });
            },
            notBoolean: function (state) {
                this.notbool = state;
                return !state;
            },
            destroy: function () {
                axios.delete('/homedata' + this.$route.id)
                    .then()
                    .catch(error => {
                        alert('Cannot delete dog info')
                    })
            },
            edit: function () {
                this.$router.push('/profile/create')
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })
            },

        }
    }
</script>

<style scoped>
    table.v-table thead th {
        font-size: 40px !important;


    }

    #styled-input {
        height: 40px;
        font-size: 20pt;
    }
</style>
