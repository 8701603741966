<template>
    <div class="dashboard">

        <v-container class="grey lighten-5 mb-6 mx-auto" align="center">
            <!-- Card for Welcome Message -->
            <v-card class="mx-auto card-gradient" dark max-width="100%" shaped>
                <v-card-title class="rounded-card">
                    <span
                        class="headline mr-2">Welcome to Building Data Details in your role as Building Manager.</span>
                    <v-icon left>mdi-hand-wave</v-icon>
                </v-card-title>
                <v-card-text>
                    <p>
                        You’ve entered this panel, where you can monitor all building-related information. As a manager,
                        you can quickly access insights about your building.
                    </p>
                </v-card-text>
                <v-spacer></v-spacer>
            </v-card>
            <v-row>
                <v-col cols="12" md="4" lg="4">
                    <v-card class="card-gradient mx-auto my-4 elevation-10" outlined shaped>
                        <v-card-title class="headline px-4 py-2">
                            <v-icon left color="white">mdi-home-circle</v-icon>
                            Overall Overview of Building
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-list three-line>
                            <v-list-item-group color="primary">
                                <!-- Building Image and Info -->
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">Building Information</v-list-item-title>
                                        <v-list-item-subtitle>Building-Related General Information
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon color="green">mdi-building</v-icon>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-list-item-action class="d-flex justify-center">
                                    <v-col cols="12">
                                        <v-row justify="space-around">
                                            <v-img max-width="100%"
                                                   :src="'/storage/images/buildings/' + building.pic"></v-img>
                                        </v-row>
                                        <v-divider class="mx-4 my-3"></v-divider>
                                        <v-row justify="center" align="center">
                                            <v-col cols="12" v-for="field in fields" :key="field.label"
                                                   class="my-0 d-flex justify-center">
                                                <v-text-field
                                                    :label="field.label"
                                                    outlined
                                                    class="text-field"
                                                    :value="field.value"
                                                    :v-model="field.model"
                                                    readonly
                                                    dense
                                                    style="max-width: 350px;"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" class="my-0">
                                                <v-text-field
                                                    label="Address"
                                                    outlined
                                                    class="text-field mx-2"
                                                    v-model="building.address"
                                                    readonly
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-list-item-action>
                                <v-divider></v-divider>
                                <v-row class="mx-auto" no-gutters align="center" justify="space-around">
                                    <v-col cols="12" class="justify-center my-3 mb-10">
                                        <v-card class="mx-auto mb-10 rounded-form" outlined width="90%" height="300px">
                                            <v-app-bar dark color="#7b5d78ff" class="text-sm-center">
                                                <v-toolbar-title class="text-font">Location of the insured building
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                            </v-app-bar>
                                            <v-card height="90%">
                                                <BuildingLocation
                                                    :doglocations="[building.longitude, building.latitude]"
                                                    :userlocations="[building.longitude, building.latitude]"/>
                                            </v-card>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="12" md="8" lg="8">
                    <v-row>
                        <v-divider></v-divider>
                        <v-col cols="12">
                            <v-card class="dashboard-card mx-auto elevation-10" outlined shaped>

                                <v-card-title
                                    class="card-text-gradient headline px-4 py-2 d-flex align-center v-card-title-font">
<!--                                    <v-avatar size="32" class="mr-3">-->
<!--                                        <img src="../../../assets/icons/Customer/BuildingStatusOverviewWhite.svg" alt="Sensor Icon">-->
<!--                                    </v-avatar>-->
                                    <span class="text-truncate v-card-title-font">Building Status Overview</span>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="card-content d-flex align-center justify-space-between">
                                    <div>Honeywell, Smoke detector, model T9, Last updated: 1 hour ago</div>
                                    <v-spacer></v-spacer>
                                </v-card-text>
                                <v-divider></v-divider>
                                <template class="mt-20">
                                </template>
                                <v-card-actions class="justify-end">
                                    <v-btn color="#6a5067ff" fab dark small>
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card class="dashboard-card mx-auto elevation-10" outlined shaped>
                                <!-- System Health, Active Alerts, Energy Usage, Recent Activities -->
                                <v-row class="mt-4" justify="space-around">
                                    <v-col cols="12" sm="6" lg="3">
                                        <v-card outlined class="system-health-card">
                                            <v-card-title class="subtitle-1 font-weight-bold">System Health
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-list dense>
                                                    <v-list-item v-for="status in systemHealth" :key="status.system">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ status.system }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon
                                                            :color="status.health === 'Healthy' ? 'green' : 'red'">
                                                            <v-icon>{{ status.health === 'Healthy' ? 'mdi-check-circle'
                                                                :
                                                                'mdi-alert-circle'
                                                                }}
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="6" lg="3">
                                        <v-card outlined class="active-alerts-card">
                                            <v-card-title class="subtitle-1 font-weight-bold">Active Alerts
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-list dense>
                                                    <v-list-item v-for="alert in activeAlerts" :key="alert.id">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ alert.message }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ alert.time }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-icon color="red">
                                                            <v-icon>mdi-alert</v-icon>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="6" lg="3">
                                        <v-card outlined class="energy-usage-card">
                                            <v-card-title class="subtitle-1 font-weight-bold">Energy Usage
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <apexchart type="line" height="150" :options="energyChartOptions"
                                                           :series="energySeries"></apexchart>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="6" lg="3">
                                        <v-card outlined class="recent-activities-card">
                                            <v-card-title class="subtitle-1 font-weight-bold">Recent Activities
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-list dense>
                                                    <v-list-item v-for="activity in recentActivities"
                                                                 :key="activity.id">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ activity.description }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>{{ activity.time }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <!-- Risk Signals and Sensor Management -->


                                <v-row class="mx-auto" no-gutters align="center" justify="space-around">
                                    <v-card class="mx-2 my-4" width="90%" rounded="3" elevation="3">
                                        <v-card-title>Risk signal</v-card-title>
                                        <v-card max-width="300pt" class="mx-auto my-6">
                                            <v-radio-group v-model="row" row>
                                                <v-radio label="Daily" value="daily"></v-radio>
                                                <v-radio label="Interval" value="interval"></v-radio>
                                            </v-radio-group>
                                        </v-card>

                                        <template v-if="row === 'daily'">
                                            <v-card-text>
                                                <div>View the performance report by selecting the date through the
                                                    calendar
                                                    below.
                                                </div>
                                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                                        :return-value.sync="date"
                                                        transition="scale-transition" offset-y max-width="80%">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="date" label="Pick date"
                                                                      prepend-icon="event_note" readonly
                                                                      v-bind="attrs" v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="date" no-title scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-card-text>
                                            <v-divider class="mx-4"></v-divider>
                                            <v-card-actions>
                                                <v-btn color="deep-purple lighten-2" @click="fetchRiskSignal">Display
                                                </v-btn>
                                            </v-card-actions>
                                        </template>

                                        <template v-if="row === 'interval'">
                                            <v-card height="90%">
                                                <v-container>
                                                    <v-row align="center" justify="center">
                                                        <v-col cols="4">
                                                            <v-card-text>Starting day</v-card-text>
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-menu ref="menu2" v-model="menu2"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="dateStartCaptured"
                                                                    transition="scale-transition"
                                                                    offset-y max-width="80%">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="dateStartCaptured"
                                                                                  label="Pick date"
                                                                                  prepend-icon="event_note" readonly
                                                                                  v-bind="attrs"
                                                                                  v-on="on"></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="dateStartCaptured" no-title
                                                                               scrollable>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn text color="primary" @click="menu2 = false">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn text color="primary"
                                                                           @click="$refs.menu2.save(dateStartCaptured)">
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row align="center" justify="center">
                                                        <v-col cols="4">
                                                            <v-card-text>End day</v-card-text>
                                                        </v-col>
                                                        <v-col cols="8">
                                                            <v-menu ref="menu3" v-model="menu3"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="dateEndCaptured"
                                                                    transition="scale-transition"
                                                                    offset-y max-width="80%">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="dateEndCaptured"
                                                                                  label="Pick date"
                                                                                  prepend-icon="event_note" readonly
                                                                                  v-bind="attrs"
                                                                                  v-on="on"></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="dateEndCaptured" no-title
                                                                               scrollable>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn text color="primary" @click="menu3 = false">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn text color="primary"
                                                                           @click="$refs.menu3.save(dateEndCaptured)">
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-card class="mx-auto">
                                                            <v-alert v-model="alertStartEndCaptured" color="orange"
                                                                     close-text="Close Alert"
                                                                     class="white--text" dismissible>
                                                                The start day must be before the end day!
                                                            </v-alert>
                                                            <v-btn color="green" elevation="20" shaped dark
                                                                   class="white--text"
                                                                   @click="fetchRiskSignalInterval">
                                                                Display
                                                            </v-btn>
                                                        </v-card>
                                                    </v-row>
                                                </v-container>
                                                <template>
                                                    <apexchart ref="realtimeChart" type="area" height="250"
                                                               :options="chartOptionsChart3"
                                                               :series="seriesChart3"></apexchart>
                                                </template>
                                            </v-card>
                                        </template>
                                    </v-card>
                                </v-row>

                                <!-- Sensor Management -->
                                <v-row class="mt-4" justify="space-around">
                                    <v-col cols="12">
                                        <v-card outlined class="sensor-management-card">
                                            <v-card-title class="subtitle-1 font-weight-bold">Sensor Management
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-btn color="primary" @click="addSensor">Add New Sensor</v-btn>
                                                <v-data-table :headers="sensorHeaders" :items="sensors"
                                                              class="elevation-1 mt-4"></v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <!-- User Management -->
                                <v-row class="mt-4" justify="space-around">
                                    <v-col cols="12">
                                        <v-card outlined class="user-management-card">
                                            <v-card-title class="subtitle-1 font-weight-bold">User Management
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-btn color="primary" @click="addUser">Add New User</v-btn>
                                                <v-data-table :headers="userHeaders" :items="users"
                                                              class="elevation-1 mt-4"></v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import BuildingLocation from "./BuildingLocation";

    export default {
        components: {
            BuildingLocation,
            apexchart: VueApexCharts,
        },
        props: ['user', 'building'],
        data() {
            return {
                riskSignals: [],
                headerSignal: [
                    {text: 'Date', value: 'date'},
                    {text: 'Time', value: 'time'},
                    {text: 'Type', value: 'status'},
                ],
                dateStartCaptured: '',
                dateEndCaptured: '',
                alertStartEndCaptured: false,
                date: new Date().toISOString().substr(0, 10),
                menu: false,
                menu2: false,
                menu3: false,
                row: 'daily',
                count: '',
                search: '',
                showImage: false,
                busy: false,
                seriesChart3: [{
                    name: 'Number of risk',
                    data: Array(14).fill({y: 0}),
                }],
                chartOptionsChart3: {
                    chart: {
                        type: 'area',
                        toolbar: false,
                        animations: {enabled: false},
                        zoom: {enabled: false},
                    },
                    colors: ['#546E7A'],
                    dataLabels: {enabled: false},
                    stroke: {curve: 'straight'},
                    fill: {
                        opacity: 0.8,
                        type: 'pattern',
                        pattern: {style: ['verticalLines', 'horizontalLines'], width: 6, height: 6},
                    },
                    markers: {
                        size: 5,
                        hover: {size: 9},
                    },
                    tooltip: {
                        intersect: false,
                        shared: false,
                    },
                    xaxis: {type: 'category'},
                },
                fields: [
                    {label: 'Plan', model: 'building.plan'},
                    {label: 'Access Level', value: 'management'},
                    {label: 'No. of Units', model: 'building.unit_num'},
                    {label: 'Number of insured supplies', value: '1'},
                    {label: 'Building type', model: 'building.infrastructure'},
                    {label: 'Remaining time', value: '365 days'},
                    {label: 'Number of devices', value: '0'},
                    {label: 'Number of risk signals in 30 days ago', model: 'building.risk_count'},
                ],
                systemHealth: [
                    {system: 'BMS System 1', health: 'Healthy'},
                    {system: 'BMS System 2', health: 'Issue Detected'},
                    {system: 'BMS System 3', health: 'Healthy'},
                ],
                activeAlerts: [
                    {id: 1, message: 'Temperature threshold exceeded', time: '10:30 AM'},
                    {id: 2, message: 'Water leakage detected', time: '11:00 AM'},
                ],
                energySeries: [{name: 'Energy Usage', data: [30, 40, 35, 50, 49, 60, 70, 91]}],
                energyChartOptions: {
                    chart: {id: 'energy-usage'},
                    xaxis: {categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']},
                },
                recentActivities: [
                    {id: 1, description: 'User logged in', time: '9:00 AM'},
                    {id: 2, description: 'BMS System updated', time: '10:00 AM'},
                ],
                sensors: [
                    {id: 1, name: 'Temperature Sensor', status: 'Active', lastChecked: '10:00 AM'},
                    {id: 2, name: 'Humidity Sensor', status: 'Active', lastChecked: '11:00 AM'},
                ],
                sensorHeaders: [
                    {text: 'Sensor Name', value: 'name'},
                    {text: 'Status', value: 'status'},
                    {text: 'Last Checked', value: 'lastChecked'},
                ],
                users: [
                    {id: 1, name: 'John Doe', role: 'Admin', lastLogin: 'Today'},
                    {id: 2, name: 'Jane Smith', role: 'Manager', lastLogin: 'Yesterday'},
                ],
                userHeaders: [
                    {text: 'User Name', value: 'name'},
                    {text: 'Role', value: 'role'},
                    {text: 'Last Login', value: 'lastLogin'},
                ],
            };
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchRiskSignalInterval() {
                if (this.dateStartCaptured && this.dateEndCaptured) {
                    if (new Date(this.dateStartCaptured) > new Date(this.dateEndCaptured)) {
                        this.alertStartEndCaptured = true;
                    } else {
                        axios.get(`../hardwares/getBuildingAlarm/${this.building.id}/${this.dateStartCaptured}/${this.dateEndCaptured}`)
                            .then(response => {
                                let dateAxis = this.dateStartCaptured;
                                this.seriesChart3[0].data = response.data.map((value, index) => {
                                    let x = dateAxis;
                                    dateAxis = new Date(new Date(dateAxis).setDate(new Date(dateAxis).getDate() + 1)).toISOString().substr(0, 10);
                                    return {x, y: value};
                                });
                                this.updateSeriesLine();
                            });
                    }
                }
            },
            fetchRiskSignal() {
                this.busy = true;
                axios.get(`../hardwares/getBuildingAlarm/${this.building.id}/${this.date}`)
                    .then(response => {
                        this.riskSignals = response.data;
                        this.count = this.riskSignals.length;
                        this.showImage = true;
                    })
                    .catch(() => {
                        alert('Please select the date');
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            },
            updateSeriesLine() {
                this.$refs.realtimeChart.updateSeries([{data: this.seriesChart3[0].data}], false, true);
            },
            fetchData() {
                this.busy = true;
                axios.get('/users/technicion')
                    .then(response => {
                        this.users = response.data;
                    })
                    .catch(() => {
                        alert('Unable to fetch data');
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            },
            addSensor() {
                // Implement sensor addition logic
            },
            addUser() {
                // Implement user addition logic
            },
        },
    };
</script>

<style scoped>

    .v-card-title-font {
        font-size: calc(7px + 1vmin);
        white-space: nowrap;
    }

    @media (max-width: 600px) {
        .v-card-title-font {
            font-size: 4vw;
        }
    }
    .card-gradient {
        border-radius: 16px;
        background: linear-gradient(135deg, #7b5d78ff 30%, #9e768f 100%);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    }

    .rounded-card {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .headline {
        color: white;
    }

    .dashboard {
        font-family: IRANSans !important;
        color: #3490dc;
    }

    .v-card-text, .v-container {
        font-family: IRANSans !important;
    }

    .rounded-form {
        border-radius: 20px !important;
    }

    .system-health-card,
    .active-alerts-card,
    .energy-usage-card,
    .recent-activities-card,
    .sensor-management-card,
    .user-management-card {
        margin-top: 16px;
        padding: 16px;
    }

    .v-card-title {
        font-weight: bold;
        font-size: 16px;
    }

    .text-field {
        margin-bottom: 8px;
    }

    .text-font {
        font-size: 16px;
    }

    .text-field2 {
        font-size: 12px;
    }

    .v-chip {
        font-weight: bold;
    }

    .v-btn {
        text-transform: capitalize;
    }

    .v-data-table {
        margin-top: 16px;
    }
    .dashboard-card {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .card-text-gradient {
        background: linear-gradient(135deg, #7b5d78ff 30%, #9e768f 100%);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    }
</style>
