<template>
    <div class="pt-16">

        <v-card max-width="480px" class="mx-auto rounded-form" outlined>
            <div id="filepond">
                <file-pond

                    name="test"
                    ref="pond"
                    class-name="my-pond"
                    label-idle="Kindly take a picture..."
                    allow-multiple="false"
                    accepted-file-types="image/jpeg, image/png"
                    server="../owner/upload"
                    v-on:processfile="handleProcessFile">
                </file-pond>
            </div>

            <v-card-title class="text-uppercase">
                Add homeowner
            </v-card-title>
            <v-img :src="require('./../../assets/iamges/LandLord.png')" contain max-height="200px">

            </v-img>
            <v-card-text>
                <v-text-field label="Name" :error-messages="errors" placeholder="Enter the name"
                              v-model="form.name "></v-text-field>
                <v-text-field label="Surname" :error-messages="errors"
                              placeholder="Enter Surname" v-model="form.family "></v-text-field>
                <v-select :items="['Woman','Man','Other']" label="Sex" :error-messages="errors" placeholder="Sex"
                          v-model="form.sex "></v-select>
                <!--            <v-text-field  label="Latitude of first catch" :error-messages="errors" placeholder="Please enter Latitude" v-model="form.catched_lat " ></v-text-field>-->
                <!--            <v-text-field  label="Longitude of first catch" :error-messages="errors" placeholder="Please enter Longitude" v-model="form.catched_lon " ></v-text-field>-->
                <v-text-field label="Age" :error-messages="errors" placeholder="Please, enter your age"
                              v-model="form.age "></v-text-field>
<!--                <v-text-field label="Job" placeholder="شغل خود را وارد نمایید." v-model="form.job "></v-text-field>-->
<!--                <v-text-field label="تاریخ پذیرش" :error-messages="errors"-->
<!--                              placeholder="لطفا تاریخ پذیرش را وارد نمایید."-->
<!--                              v-model="form.date_of_adoption "></v-text-field>-->
                <!--                <v-text-field  label="ای دی سگ" :error-messages="errors" placeholder="لطفا ای دی سگ مد نظر را وارد نمایید" v-model="form.dog_ID "></v-text-field>-->
<!--                <v-select :items="['ID100','ID101','ID102','ID103','ID104','ID105','ID106','ID107' ]" label="ای دی سگ"-->
<!--                          :error-messages="errors" placeholder="لطفا ای دی سگ مد نظر را وارد نمایید"-->
<!--                          v-model="form.dog_ID "></v-select>-->

<!--                <v-text-field label="آدرس" :error-messages="errors" placeholder="آدرس خود را وارد نمایید."-->
<!--                              v-model="form.address "></v-text-field>-->
                <v-text-field label="Comment" placeholder="You're welcome to leave a comment if needed."
                              v-model="form.comments "></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="m-1" :to="{name:'profiles'}">Cancel</v-btn>
                <v-btn depressed color="success" class="m-1" @click="addNewDog" :loading="busy">Register Data</v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
    import InputField from "../components/InputField";
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "OwnerCreate",
        components: {
            InputField,
            FilePond
        },

        data: function () {
            return {
                //myFiles: ['index.html'],

                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '', 'age': '', 'sex': '', 'family': '', 'job': '', 'date_of_adoption': '', 'address': '',
                        'comments': '', 'dog_ID': '', 'fig': '',
                    },
                errors: null,
                responses: null,
                busy: false,

            }
        },
        mounted() {
            // this.video = this.$refs.video;
            // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            //     navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //         video.srcObject = stream;
            //         video.play();
            //         video.onplay = function () {
            //         };
            //         this.video.play();
            //     });
            // }
        },

        methods: {
            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            },
            addNewDog() {
                /*axios.post('../dogdata/', {
                    race: 'Finn',
                    age: 'Williams',
                    sex: 'Williams',
                    catched_lat:'',
                    catched_lon: '',
                    vaccinated: '',
                    sterilized:''
                }).then(response => {

                            })
                         .catch(errors =>{
                             console.log(errors);
                         });*/

                this.busy = true;

                axios.post('../owner', this.form)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>

