<template>
    <div class="error-container">
        <h1>403 - Forbidden</h1>
        <p>The custom domain is not recognized.</p>
        <a href="https://hubbcast.com">Go back to the homepage</a>
    </div>
</template>

<script>
    export default {
        name: 'ForbiddenError'
    }
</script>

<style scoped>
    .error-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        font-family: 'Arial', sans-serif;
    }

    h1 {
        font-size: 3rem;
        color: #e74c3c;
    }

    p {
        margin-top: 1rem;
        font-size: 1.5rem;
    }

    a {
        margin-top: 2rem;
        color: #3498db;
        text-decoration: none;
    }
</style>
