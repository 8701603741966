<template>
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m29.88 15.52-6-11a1 1 0 0 0 -.88-.52h-14a1 1 0 0 0 -.88.52l-6 11a1 1 0 0 0 0 1l6 11a1 1 0 0 0 .88.48h14a1 1 0 0 0 .88-.52l6-11a1 1 0 0 0 0-.96zm-6.95-8.52 4.39 8h-9.5zm-6.93 7.14-5.18-8.14h10.36zm-6.93-7.14 5.11 8h-9.5zm-4.39 10h9.5l-5.11 8zm11.32.86 5.18 8.14h-10.36zm6.93 7.14-5.11-8h9.5z"/>
        <path d="m0 0h32v32h-32z" fill="none"/>
    </svg>
</template>

<script>
    export default {
        name: "integrationLCIcon"
    }
</script>

<style scoped>

</style>
