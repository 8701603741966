import { render, staticRenderFns } from "./BecomePartner.vue?vue&type=template&id=39b5d751&scoped=true&"
import script from "./BecomePartner.vue?vue&type=script&lang=js&"
export * from "./BecomePartner.vue?vue&type=script&lang=js&"
import style0 from "./BecomePartner.vue?vue&type=style&index=0&id=39b5d751&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b5d751",
  null
  
)

export default component.exports