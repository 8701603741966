<template>

    <div class="test">
        <v-snackbar v-model="snackbarSetHoliday" color="#f2c4d3" :timeout="3000" centered>
            <span style="color: #333333;">
                Registration completed successfully.
            </span>
            <!--            <v-btn text @click="snackkbar = false">بستن</v-btn>-->
        </v-snackbar>

        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-card-actions class="justify-center mx-auto">
                                <v-layout column align-center>
                                    <v-avatar  size='150'  class="mx-auto">
                                        <!--                                    //                    this.images= '/storage/images/publics/'+this.dogs.fig;-->
                                        <v-img
                                            :src="'/storage/images/user/'+ this.$store.state.users.pic"
                                        >
                                        </v-img>
                                    </v-avatar>
                                </v-layout>

                            </v-card-actions>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center" class="text-sm-center">
                                <v-text-field
                                    label="Name and Family"
                                    v-model="$store.state.users.name"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-1"
                                >

                                    <template v-slot:label>
                                        Name and Family
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            account_box
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center" >
                                <v-text-field
                                    label="Management level"
                                    v-model="access_level"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-1"
                                >

                                    <template v-slot:label>
                                        Management level
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            supervised_user_circle
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                            class="justify-center"
                        >
                            <v-flex align-self-center align="center" >
                                <v-text-field
                                    label="cell number"
                                    v-model="$store.state.users.email"
                                    readonly
                                    filled
                                    color="blue"
                                    rounded
                                    height="90px"
                                    dense
                                    class="pa-2"
                                >

                                    <template v-slot:label>
                                        Cell number
                                        <v-icon style="vertical-align: middle" color="green" dark medium>
                                            phone
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>


        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-card

                            class="mx-2 my-4"
                            width="80%"
                            rounded="3"
                            elevation="3"
                        >


                            <v-card-title >Change User Profile</v-card-title>

                            <v-row>
                                <v-col
                                    cols="11"
                                    sm="5"
                                >
                                    <v-text-field
                                        v-model="password"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type=" 'password'"
                                        name="input-10-1"
                                        label="New password"
                                        hint="At least contain 8 characters"
                                        counter
                                        @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="11"
                                    sm="5"
                                >
                                    <v-text-field
                                        v-model="rePassword"
                                        :rules="[rules.required, rules.min, passwordConfirmationRule]"
                                        :type="'password'"
                                        name="input-10-2"
                                        label="Retype password"
                                        hint="Retype password"
                                        class="input-group--focused"
                                        @click:append="show2 = !show2"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions>
                                <v-row class="mx-4">
                                    <v-btn
                                        color="deep-green lighten-2"
                                        @click.stop="dialogVocation2=true"
                                    >
                                        Save changes
                                    </v-btn>
                                </v-row>
                                <v-dialog
                                    v-model="dialogVocation2"
                                    max-width="290"
                                >
                                    <v-card>


                                        <v-card-text>
                                            Are you sure you want to change your password?
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn
                                                color="red darken-1"
                                                text
                                                @click.prevent="dialogVocation2=false"
                                            >
                                                No
                                            </v-btn>

                                            <v-btn
                                                color="green darken-1"
                                                text
                                                @click.prevent="EditPassword"
                                            >
                                                Yes
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-card-actions>
                        </v-card>
                        <!--related to manager-->
                        <v-card

                            class="mx-2 my-4"
                            max-width="100%"
                            rounded="3"
                            elevation="3"
                            v-if="$store.state.users.access_level == 1"
                        >


                            <v-card-title class="text-sm-justify ">Vacation registration</v-card-title>

                            <v-card-text>
                                <div>
                                    If there is a holiday for all technicians on a day other than Friday, please register the day before. For example, you must report it on Friday if it is closed on Wednesday.
                                </div>
                            </v-card-text>

                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions>

                                <v-btn
                                    color="red lighten-6"
                                    class="text--white"
                                    @click.stop="dialogAllVocation=true"
                                >
                                    Register
                                </v-btn>
                                <v-dialog
                                    v-model="dialogAllVocation"
                                    max-width="290"
                                >
                                    <v-card>


                                        <v-card-text>
                                            Do you want to {{dateTommorow  }} registered as holiday?
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn
                                                color="red darken-1"
                                                text
                                                @click.prevent="EditAllVacation(0)"
                                            >
                                                No
                                            </v-btn>

                                            <v-btn
                                                color="green darken-1"
                                                text
                                                @click.prevent="EditAllVacation(1)"
                                            >
                                                Yes
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-card-actions>
                        </v-card>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>



        <v-container
            class="grey lighten-5 mb-6 mx-auto" align="center"
        >
            <v-layout align-center row wrap>
                <v-flex>
                    <v-row
                        class="mx-auto "
                        no-gutters
                        align="center" justify="space-around"
                    >
                        <v-card

                            class="mx-2 my-4"
                            max-width="100%"
                            rounded="3"
                            elevation="3"
                            v-if="$store.state.users.access_level == 5 || $store.state.users.access_level == 7"
                        >


                            <v-card-title> Leave request </v-card-title>

                            <v-card-text>
                                <div> Due to the registration of the performance software, if you are on leave, this request must be submitted one day in advance. Otherwise, you will incur a performance defect. For example, if you apply for leave on Wednesday, you must register on Tuesday. </div>
                            </v-card-text>

                            <v-divider class="mx-4"></v-divider>
                            <v-card-actions>

                                <v-btn
                                    color="deep-purple lighten-2"
                                    @click.stop="dialogVocation=true"
                                >
                                    Submit a request
                                </v-btn>
                                <v-dialog
                                    v-model="dialogVocation"
                                    max-width="290"
                                >
                                    <v-card>


                                        <v-card-text>
                                            Are you on leave at {{dateTommorow}}
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn
                                                color="red darken-1"
                                                text
                                                @click.prevent="EditVacation(0)"
                                            >
                                                No
                                            </v-btn>

                                            <v-btn
                                                color="green darken-1"
                                                text
                                                @click.prevent="EditVacation(1)"
                                            >
                                                Yes
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-card-actions>
                        </v-card>
                        <!--related to manager-->
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>


    </div>
</template>

<script>

    const gradients = [
        ['#222'],
        ['#42b3f4'],
        ['red', 'orange', 'yellow'],
        ['purple', 'violet'],
        ['#00c6ff', '#F0F', '#FF0'],
        ['#f72047', '#ffd200', '#1feaea'],
    ]
    import {VueFunnelGraph} from 'vue-funnel-graph-js';
    // import LineChart from './LineChart.js'

    import VueApexCharts from 'vue-apexcharts'
    import VueMoment from 'vue-jalali-moment';

    // Vue.use(VueApexCharts);
    // window.Apex.chart = { fontFamily: IRANSans !important;};

    // Vue.component('apexchart', VueApexCharts);

    export default {

        components: {
            VueFunnelGraph,
            VueMoment,
            apexchart: VueApexCharts,
        },
        props:[
            'user',
        ],
        data: () => ({
            show1: true,
            show2: true,
            password: '',
            rePassword:'',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'At least 8 characters',
            },
            snackbarSetHoliday: false,
            dialogVocation:false,
            dialogVocation2:false,
            dialogAllVocation:false,
            form:
                {
                    'name': '',
                    'access_level': '',
                    'national_ID': '',
                    'shenasname': '',
                    'tomorrow_vacation':'',
                    'car_number' : '',
                    'pic' : ''
                },
            dateTommorow:'',
            //Sparkline
            fill: false,
            selectedGradient: gradients[4],
            padding: 8,
            radius: 10,
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            width: 2,
            //Area Chart 1

            //pi chart
            seriesPi: [14, 23, 21, 17],
            //radar

            seriesRa: [{
                data: [80, 50, 30, 40],
            }],

            //
            access_level:'',
            chartData: [["Jan", 4], ["Feb", 2], ["Mar", 10], ["Apr", 5], ["May", 3]],
            loaded: false,
            chartdata: null,
            // height: ,
            lineCap: 'round',
            gradient: gradients[5],
            number:[4,2,1,1,1,1],
            gradientDirection: 'top',
            gradients,
            type: 'trend',
            autoLineWidth: false,

            labels: ['Impressions', 'Add To Cart', 'Buy'],
            // subLabels: ['Direct', 'Social Media', 'Ads'],
            values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                [3000], // Segments of "Impressions" from top to bottom
                [1700], // Segments of "Add To Cart"
                [600]   // Segments of "Buy"
            ],
            colors: [
                ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
                ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
                ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
            ],
            direction: 'vertical',
            gradientDirection2: 'horizontal',
            height: 100,
            width2: 200,

            chartOptions: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 35, 50, 49, 60, 70, 91]
            }]


        }),

        // data() {
        //     return {
        //         labels: ['Impressions', 'Add To Cart', 'Buy'],
        //         subLabels: ['Direct', 'Social Media', 'Ads'],
        //         values: [
        //             // with the given Labels and SubLabels here's what the values represent:
        //             //
        //             // Direct, Social, Ads
        //             //    |      |     |
        //             //    v      v     v
        //             [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
        //             [3000, 1700, 1000], // Segments of "Add To Cart"
        //             [600,  200,  130]   // Segments of "Buy"
        //         ],
        //         colors: [
        //             ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        //             ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        //             ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        //         ],
        //         direction: 'horizontal',
        //         gradientDirection: 'horizontal',
        //         height: 300,
        //         width: 800
        //     };
        // },
        created(){
            // console.log(this.$refs.funnel);
            this.dateTommorow = new Date();
            this.dateTommorow.setDate(this.dateTommorow.getDate() + 1);
            this.form.name = this.$store.state.users.name;
            this.form.access_level = this.$store.state.users.access_level;
            this.form.national_ID = this.$store.state.users.national_ID;
            this.form.shenasname = this.$store.state.users.shenasname;
            this.form.car_number = this.$store.state.users.car_number;
            this.form.pic = this.$store.state.users.pic;
        },
        async mounted() {
            // console.log(this.$refs.funnel.clientWidth);
            // this.width = this.$refs.funnel.clientWidth;
            this.loaded = false
            try {
                const {userlist} = await fetch('/api/userlist')
                this.chartdata = userlist
                this.loaded = true
            } catch (e) {
                console.error(e)
            };
            this.access_level = this.getAccessLevel();
        },
        methods: {
            increase() {
                this.height += 10
            },
            EditPassword: function ( ) {
                this.dialogVocation = false;
                axios.put('../user/'+ this.$store.state.users.id , {'password': this.password})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            EditVacation: function ( num ) {
                this.dialogVocation = false;
                this.form.tomorrow_vacation = num;
                axios.put('../user/'+ this.$store.state.users.id , {'tomorrow_vacation': num})
                    .then(response => {
                        this.$router.push({name: 'dashboard'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            EditAllVacation: function ( num ) {
                this.dialogAllVocation = false;

                axios.put('../users/all' , {'holiday': num})
                    .then(response => {
                        this.snackbarSetHoliday = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        // this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },

            getAccessLevel() {
                if (this.$store.state.users.access_level == "1") return 'Management'
                else if (this.$store.state.users.access_level == "2") return 'Operator'
                else if (this.$store.state.users.access_level == "3") return 'Installation expert'
                else if (this.$store.state.users.access_level == "4") return 'Damage expert'
                else if (this.$store.state.users.access_level == "5") return 'Support specialist'
                else if (this.$store.state.users.access_level == "7") return 'Responsible technician'
                else if (this.$store.state.users.access_level == "8") return 'Security and inspection'
                else return 'Unapproved user'
            },
        },
        computed: {
            passwordConfirmationRule() {
                return () => (this.password === this.rePassword) || 'The selected password and its repetition are not the same'
            },
            graphWidth() {
                return this.$refs.funnel.clientWidth;
            },
            myStyles() {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<style scoped>
    .test {
        font-family: IRANSans !important;
        color: #3490dc;
    }
    .v-card-text {
        font-family: IRANSans !important;
    }
    .v-container {
        font-family: IRANSans !important;
    }
    .rounded-form {
        border-radius: 20px !important;
    }
    .test{
        font-size: 0.875rem;
    }
    .toolbar__title,
    .tabs__container {
        background-color: rgba(255, 255, 255, 0.2);
        justify-content: flex-start !important;
        max-width: 600px;
        width: 100%;
    }

    .centered-input >>> input {
        text-align: center
    }
</style>
