<template>
    <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        enable-background="new 0 0 1000 1000"
        xml:space="preserve"
        id="svg24"
        sodipodi:docname="806f14439a71fa25420bf9271752b7fe.svg"
        inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"><defs
   id="defs28" /><sodipodi:namedview
        id="namedview26"
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1.0"
        inkscape:pageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        showgrid="false"
        inkscape:zoom="0.838"
        inkscape:cx="499.40334"
        inkscape:cy="499.40334"
        inkscape:window-width="1920"
        inkscape:window-height="1001"
        inkscape:window-x="-9"
        inkscape:window-y="-9"
        inkscape:window-maximized="1"
        inkscape:current-layer="svg24" />
        <metadata
            id="metadata2"> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g
            id="g22"
            style="fill:#ffffff"><g
     transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"
     id="g20"
     style="fill:#ffffff"><path
       d="M483.2,607.6v-4402.3h3672.4c3475.9,0,3672.4,2.5,3682.7,45.9c5.1,23,7.6,479.8,5.1,1013.2l-2.6,969.8L7639.3-1582C7527-1480,7430-1395.7,7419.8-1395.7c-12.8,0-20.4-441.5-20.4-982.6v-982.5H4158.2H917.1V607.6v3968.5h3241.1h3241.1V2312.4l-2.5-2266.3l211.8-196.5c117.4-107.2,216.9-191.4,222-183.8c7.7,5.1,12.8,1209.7,12.8,2677.1L7846,5010H4165.9H483.2V607.6z"
       id="path4"
       style="fill:#ffffff" /><path
            d="M4528.3,4124.4c-288.4-35.7-676.3-168.4-923.9-319c-1342.4-809-1541.5-2669.5-400.7-3749C3920.9-619.9,5000.4-773,5888.5-326.4C6756.2,110,7264.1,1041.5,7167.1,2019c-30.6,303.7-84.2,484.9-229.7,783.5c-339.4,704.4-952,1166.3-1725.2,1301.6C5010.6,4139.7,4714.6,4147.4,4528.3,4124.4z M5242.8,3409.8c84.2-20.4,239.9-79.1,344.5-130.2c257.8-125.1,587-451.7,722.2-712c449.2-867.7,99.5-1896.2-786-2319.8c-191.4-91.9-497.7-163.3-701.8-163.3c-408.3,0-877.9,191.4-1168.8,479.8C3331.3,880.7,3170.5,1238,3145,1682.1c-28.1,505.3,130.2,918.8,487.4,1276c114.8,112.3,270.5,239.9,347.1,283.3C4362.4,3458.3,4806.4,3517,5242.8,3409.8z"
            id="path6"
            style="fill:#ffffff" /><path
            d="M5013.1,2483.4c-132.7-53.6-211.8-222-165.9-347.1c25.5-63.8,7.7-99.5-214.4-433.9c-219.5-326.7-250.1-364.9-313.9-362.4c-40.8,2.5-97-5.1-130.1-15.3c-43.4-15.3-89.3,7.7-206.7,99.5c-125.1,97-150.6,132.7-150.6,191.4c5.1,178.7-120,306.3-298.6,306.3c-74,0-114.8-20.4-181.2-86.8c-71.5-71.5-86.8-104.6-86.8-199.1c0-61.3,17.9-137.8,40.8-171c58.7-81.7,199.1-135.3,301.1-117.4c74,15.3,102.1,2.6,237.4-112.3c122.5-99.5,158.2-148,171-222c10.2-51,51-127.6,91.9-168.4c58.7-58.7,94.4-71.5,191.4-71.5c102.1,0,132.7,12.8,209.3,89.3c79.1,79.1,86.8,97,79.1,222c-5.1,132.7,0,142.9,229.7,487.4c181.2,268,247.6,349.6,290.9,349.6c30.6,0,91.9,12.8,137.8,30.6c76.6,25.5,94.4,20.4,262.9-79.1c99.5-58.7,181.2-125.1,181.2-145.5c0-127.6,153.1-265.4,293.5-265.4c303.7,0,403.2,436.4,127.6,551.3c-109.7,45.9-160.8,45.9-250.1-2.6c-66.4-33.2-79.1-30.6-250.1,68.9c-99.6,56.1-186.3,122.5-194,145.5c-43.4,140.4-76.6,191.4-145.5,234.8C5186.7,2511.5,5097.4,2519.2,5013.1,2483.4z"
            id="path8"
            style="fill:#ffffff" /><path
            d="M8165,3797.8v-216.9h204.2h204.2V1284v-2296.9l109.7-97c58.7-53.6,155.7-142.9,216.9-196.5l107.2-94.4v2707.8v2707.8h-421.1H8165V3797.8z"
            id="path10"
            style="fill:#ffffff" /><path
            d="M6695-53.3L6485.7-260l1020.8-918.8c1743.1-1569.5,1559.3-1416.4,1699.7-1416.4c137.8,0,216.9,45.9,273.1,153.1c56.1,107.2,48.5,234.8-17.9,326.7C9392.5-2021,6970.6,145.7,6929.7,150.8C6914.5,153.4,6807.3,61.5,6695-53.3z"
            id="path12"
            style="fill:#ffffff" /><path
            d="M1708.2-1038.4v-229.7H4222h2513.8v229.7v229.7H4222H1708.2V-1038.4z"
            id="path14"
            style="fill:#ffffff" /><path
            d="M1708.2-2365.5v-229.7H4222h2513.8v229.7v229.7H4222H1708.2V-2365.5z"
            id="path16"
            style="fill:#ffffff" /><path
            d="M8573.3-3396.6v-959.6H5332.1H2091v153.1v153.1h-229.7h-229.7v-370v-370h3687.8h3687.8v1005.5V-2779l-84.2,53.6c-45.9,28-112.3,81.6-145.5,117.4c-35.7,38.3-94.4,91.9-132.7,119.9l-71.4,48.5V-3396.6z"
            id="path18"
            style="fill:#ffffff" /></g></g>
</svg>
</template>

<script>
    export default {
        name: "Appliance"
    }
</script>

<style scoped>

</style>
