import { render, staticRenderFns } from "./emailLCIcon.vue?vue&type=template&id=651129b3&scoped=true&"
import script from "./emailLCIcon.vue?vue&type=script&lang=js&"
export * from "./emailLCIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651129b3",
  null
  
)

export default component.exports