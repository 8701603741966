<template>
    <div style="width: 100%; height: 100%;">
        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <MglMarker
                :coordinates.sync="markerCoordinates"
                color="Red"
            />
            <MglMarker v-for="pos in markerCoordinates1" :key="pos"
                :coordinates.sync="pos"
                :color="colors"
            />
        </MglMap>
    </div>
</template>

<script>
    import {MglMap, MglMarker} from "vue-mapbox";


    export default {
        name: "mapirMarker",
        components: {
            MglMap,
            MglMarker
        },
        data() {
            return {
                colors: "Green",
                coordinates: [51.420296, 35.732379],
                centers: [51.420296, 35.732379],
                markerCoordinates: [51.420296, 35.732379],
                markerCoordinates1: [[52.420296, 35.732379],[53.420296, 35.732379]],
                markerCoordinates2: [53.420296, 35.732379],
                // mapirToken: "<Your API Key>"
                mapirToken: "pk.eyJ1IjoiaHViYmNhc3QiLCJhIjoiY2xoZHF4NTlnMTk2OTNlcGM3Zm52dDcyYiJ9.pHFSMavRdJea__8bv6XWVQ",

            };
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get('/getuserlocation')
                    .then(response => {
                        console.log(response);
                        //this.dogs = response.data[0].data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            }
        }
    };

</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>

