<template>
    <div class="pt-16">

        <v-card max-width="600px" class="mx-auto rounded-form" outlined >
            <v-card-actions class="justify-center">
            <v-card width="90%" align="center">

            <div id="filepond" >
                <file-pond
                    allowImageResize="true"
                    imageResizeTargetWidth="400"
                    imageResizeTargetHeight="400"
                    imageResizeUpscale="false"
                    allowImageTransform="true"
                    imageTransformOutputMimeType="image/png"
                    name="public"
                    ref="pond"
                    class-name="my-pond"
                    label-idle="... Please take a photo horizontally"
                    allow-multiple="false"
                    accepted-file-types="image/png, image/jpeg, image/jpg, image/*"
                    server= "report/upload"
                    allowFileRename="true"
                    captureMethod="environment"
                    v-on:init="handleFilePondInit"
                    v-on:processfile="handleProcessFile">
                </file-pond>
<!--                captureMethod="camera"-->
<!--                    accepted-file-types="image/jpeg;capture=camera, image/png;capture=camera"-->
            </div>
            </v-card>
            </v-card-actions>
            <v-card-title class="layout justify-center">
                Claim for building damage
            </v-card-title>
            <v-img :src="require('../../../assets/iamges/RequestDamage.png')" contain max-height="200px">

            </v-img>
            <v-card-text>
                <v-autocomplete
                    :items="building_set"
                    item-text="address"
                    item-value="id"
                    v-model="building_selected_id"
                    @change="updateUnit"
                    :error-messages="errors" label="Building selection"
                    placeholder="Please enter your building.">
                </v-autocomplete>

                <v-autocomplete
                    :disabled="unitEnable"
                    :items="unit_set"
                    item-text="unit_Nom"
                    item-value="id"
                    v-model="unit_selected_id"
                    label="Select the desired unit?"
                ></v-autocomplete>
                <v-text-field  label="Date" v-model="damageDate" :error-messages="errors" placeholder="Please enter your date." ></v-text-field>
                <v-textarea  label="Description"  placeholder="Please enter more details." v-model="damageComments"></v-textarea>
            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn text class="m-1" >Cancel </v-btn>
                <v-btn depressed color="blue" class="m-2" @click="dialogReportDamage='true'" :loading="busy">Send </v-btn>
                <v-snackbar
                    v-model="snackbar"
                    :top=true
                    timeout=10000
                >
                    Damage information was recorded. Wait for the experts to call.

                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="pink"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>


            </v-card-actions>
            <template>
                <v-dialog
                    v-model="dialogReportDamage"
                    width="500"
                >
                    <v-card>
                        <v-card-title class="headline grey lighten-2">
                            Claim for building damage
                        </v-card-title>

                        <v-card-text>
                            I confess that all the information entered is correct and  Insurance will not be held responsible for any possible violations.
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="reportDamage"
                                color="green"
                                text
                            >
                                Yes, I agree.
                            </v-btn>
                            <v-btn
                                @click="dialogReportDamage=false"
                                color="red"
                                text
                            >
                                No.
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-card>

    </div>
</template>

<script>

    import vueFilePond , { setOptions } from 'vue-filepond';
    let currentDate = Date.now()
    // setOptions({
    //     fileRenameFunction: (file) => {
    //         return currentDate + `${file.extension}`;
    //     }
    // })
    // setOptions({
    //     chunkUploads: true,
    //     chunkForce: true,
    //     server: {
    //         url: "/uploads",
    //         headers: {
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         process: {
    //             method: 'POST',
    //         },
    //     }
    // });
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {
        created(){

        },
        name: "PublicCreate",
        components: {
            // InputField,
            FilePond
        },
        props:{
            savingSuccessful: false
        },
        data:function () {
            return{
                dialogReportDamage: false,
                damageDate:'',
                damageComments:'',
                unitEnable:true,
                building_selected_id:'',
                unit_selected_id:'',
                video: {},
                canvas: {},
                captures: [],
                building_set:[],
                unit_set:[],
                form: {
                        'name':'','comments':'', 'cell_number':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                    },
                errors: null,
                responses:null,
                busy:false,
                snackbar: false,
                text: '',
                sampleFig:'',
                picID2:''

            }
        },

        mounted: function(){
            // this.Bol = true;
             this.unit_set=[];
            axios.get('citizens/' + this.$store.state.customers.id)
                .then(response => {
                    this.building_set = response.data;
                    // this.snackkbar = true;
                })
                .catch(errors => {
                    console.log(this.form);
                    this.errors = errors.response.data.errors;
                    console.log(this.errors);
                }).finally(() => {
                this.busy = false;
            });
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(position => {
                    this.form.catched_lon = position.coords.longitude;
                    this.form.catched_lat = position.coords.latitude;
                })
            };
        },
        computed:{
            // handleProcessFile: function(error, file) {
            //     this.form.fig = file.serverId;
            //     this.picID2 = file;
            //     alert(file.serverId);
            //     // this.$store.commit("updatePublicFig", file.serverId);
            //
            //     // this.setFigValue;
            //     // let response = JSON.parse(file.serverId);
            //     // this.$emit("response", response);
            //     // this.setFigValue(file.serverId);
            //     // console.log(this.form.fig);
            // },
            picID:function (file) {
                this.picID2 = file;
            }
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods:{
            updateUnit:function(){
                axios.get('buildings/' + this.building_selected_id)
                    .then(response => {
                        this.unit_set = response.data ;
                        this.unitEnable = false;
                    })

            },
            updateSetOption: function(){
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },
            getCurrentLocation: function(){
                if(navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(position => {
                        this.form.catched_lon = position.coords.longitude;
                        this.form.catched_lat = position.coords.latitude;
                    })
                }
            },

            callback (msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function(error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            reportDamage() {
                let currentTime = new Date();
                let msg = {
                    'type': 'ReportDamage',
                    'status': 'submitted',
                    'issue_tracking':currentTime.getTime(),
                    'message': [{'userType':'citizen',
                        'id': '',
                        'date':this.damageDate,
                        'msg': this.damageComments,
                    }],
                };
                axios.post('msg', msg)
                    .then(response => {
                        this.dialogReportDamage = false;
                        this.snackbar = true;
                    })
                    .catch(errors => {})
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }
    #video {
        background-color: #000000;
    }
    #canvas {
        display: none;
    }
    li {
        display: inline;
        padding: 5px;
    }
    .rounded-form{
        border-radius: 28px !important;

    }
    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/
</style>
