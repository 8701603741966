<template>
    <div>

        <v-data-table
            show-expand
            :headers="headers"
            :items="hardware_list"
            :items-per-page="10"
            :loading="busy"
            :footer-props="{
        showFirstLastPage: true,
        'disable-pagination': false,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'arrow_back',
        nextIcon: 'arrow_forward',
           'items-per-page-text':'per page',


      }"
        >
            <template #item.remove="{item}">
                <v-btn color="red"
                       rounded
                       small>delete
                </v-btn>
            </template>

            <template #item.assign="{item}">
                <v-btn color="blue"
                       rounded
                       small>assign
                </v-btn>
            </template>
            <template #item.edit="{item}">
                <v-btn color="green"
                       rounded
                       @click="updateRemoveID(item.id)"
                       small>edit
                </v-btn>
            </template>
        </v-data-table>


    </div>
</template>

<script>

    export default {
        name: "OwnersIndex",


        created() {

        },
        mounted() {
            this.fetchData();
        },


        data: function () {
            return {
                busy: false,
                hardware_list: [],

                headers: [
                    {text: 'ID', value: 'id'},
                    // 'type', 'implementation_date','unique_id', 'cell_number', 'building_id', 'pic', 'wifi_ssid', 'wifi_password','remained_charge', 'restful_address', 'signal'

                    {
                        text: 'Type',
                        align: 'start',
                        value: 'type',
                    },
                    {text: 'Unique ID', value: 'unique_id'},
                    {text: 'Building ID', value: 'building_id'},

                    {text: 'Wifi SSID', value: 'wifi_ssid', sortable: false},
                    {text: 'Wifi Password', value: 'wifi_password', sortable: false},
                    {text: 'Rest Address', value: 'restful_address', sortable: false},
                    {text: 'Alarm signals', value: 'signal', sortable: false},
                    {text: 'assign home', value: 'assign', sortable: false},
                    {text: 'delete', value: 'remove', sortable: false},
                    // { text: 'Dog ID', value: 'family' },
                ],


            }
        },
        methods: {
            updateRemoveID(id) {
                axios.put('../owner/' + id, {'verified': 1})
                    .then(response => {
                        console.log(response);
                        this.$router.go(0);
                    })
                    .catch(errors => {
                        //alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },
            getTextState(state) {
                if (state == "0") return 'تایید نشده'
                else return 'تایید شده';
            },
            getColor(state) {
                if (state == "0") return 'orange'
                else return 'green';
            },
            fetchData() {
                this.busy = true;

                axios.get('hardware')
                    .then(response => {
                        this.hardware_list = response.data[0].data;
                    })
                    .catch(errors => {
                        // alert('unable to fetch');
                    }).finally(() => {
                    this.busy = false;

                })
            },

            ShowLocation: function (id) {
                this.$router.push('../doglocation/' + id)
                    .then()
                    .catch(error => {
                        alert('Cannot edit dog info')
                    })

            },


        }
    }

</script>

<style scoped>

</style>
