<template>

</template>

<script>
    export default {
        name: "Logout",
        created() {
            localStorage.removeItem('isAuthenticated');
            // localStorage.clear();
            axios.get('../logout-manual',{})
                .finally(err=>{
                    let baseRoute = '../login';
                    if (this.$route.params.business) {
                        baseRoute = `login`;
                    }
                    window.location = baseRoute;
                })
        }
    }
</script>

<style scoped>

</style>
