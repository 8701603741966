<template>
        <v-app id="landing">
            <v-app-bar
                dark
                app
                clipped-left
                color="white"
                dense
                v-if=isHeaderMedium
            >
                <v-list-item-action class="mx-20">
                    <v-img
                        class="ma-0"
                        contain
                        v-if="isHeaderMedium"
                        :src="require('../../assets/icons/HubbcastLogo.svg')"
                        max-height="40px"
                        width="150px"
                        @click="$router.push('/')"
                    ></v-img>
                    <!--                <img height="50px"  src="../../assets/icons/insurmatics.png">-->
                </v-list-item-action>
                <v-spacer></v-spacer>

                <v-btn
                    rounded
                    width="100px"
                    color="#a36789ff"
                    :to="{name:'demo'}"

                >
                    Get demo
                </v-btn>
                <v-app-bar-nav-icon @click.stop="drawerMedium = !drawerMedium" color="black"></v-app-bar-nav-icon>

            </v-app-bar>
            <v-navigation-drawer
                v-model="drawerMedium"
                right
                fixed
            >

                <template class="justify-center mb-10">

                    <img width="150px" src="../../assets/icons/HubbcastLogo.svg">


                </template>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group
                        active-class="  deep-purple--text text--accent-4 "
                    >
                        <template class="text-center">
                            <v-list-item class="justify-center">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    small
                                    @click.stop="drawerMedium = !drawerMedium"
                                >
                                    <v-icon dark>
                                        close
                                    </v-icon>
                                </v-btn>

                            </v-list-item>

                            <v-list-item class="text-center" :to="{name:'whyinsurmatics'}"
                            >
                                <v-list-item-title
                                >

                                    Why Hubbcast?
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="text-center" :to="{name:'faq'}">
                                <v-list-item-title>FAQ</v-list-item-title>
                            </v-list-item>

                            <!--                        <v-list-item class="text-center">-->
                            <!--                            <v-list-item-title>Support</v-list-item-title>-->
                            <!--                        </v-list-item>-->
                            <v-list-item class="text-center" :to="{name:'partner'}">
                                <v-list-item-title>Partnership</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="text-center" :to="{name:'journey'}">
                                <v-list-item-title>Be in our journey</v-list-item-title>
                            </v-list-item>

                        </template>

                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <!--        V-app-nar Small-->
            <v-app-bar
                dark
                app
                clipped-left
                color="white"
                dense
                v-if=isHeaderSmall
                height="50px"
            >
                <v-list-item-action class="mx-20">
                    <v-img
                        class="ma-0"
                        contain
                        v-if="isHeaderSmall"
                        :src="require('../../assets/icons/HubbcastLogo.svg')"
                        max-height="40px"
                        width="150px"
                        @click="$router.push('/')"
                    ></v-img>
                    <!--                <img height="100%" src="../../assets/icons/insurmatics.png">-->
                </v-list-item-action>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click.stop="drawerSmall = !drawerSmall" color="black"></v-app-bar-nav-icon>
            </v-app-bar>
            <v-navigation-drawer
                v-model="drawerSmall"
                right
                fixed
                :width="$vuetify.breakpoint.width"
            >
                <template class="justify-center mb-10">

                    <img width="150px" src="../../assets/icons/HubbcastLogo.svg">

                </template>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group
                        active-class="  deep-purple--text text--accent-4 "
                    >
                        <template class="text-center">
                            <v-list-item class="justify-center">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    small
                                    @click.stop="drawerSmall = !drawerSmall"
                                >
                                    <v-icon dark>
                                        close
                                    </v-icon>
                                </v-btn>

                            </v-list-item>
                            <v-list-item class="text-center" :to="{name:'whyinsurmatics'}">
                                <v-list-item-title>Why Hubbcast?</v-list-item-title>
                            </v-list-item>

                            <v-list-item class="text-center" :to="{name:'faq'}">
                                <v-list-item-title>FAQ</v-list-item-title>
                            </v-list-item>

                            <!--                        <v-list-item class="text-center">-->
                            <!--                            <v-list-item-title>Support</v-list-item-title>-->
                            <!--                        </v-list-item>-->
                            <v-list-item class="text-center" :to="{name:'partner'}">
                                <v-list-item-title>Partnership</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="text-center" :to="{name:'journey'}">
                                <v-list-item-title>Be in our journey</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="justify-center">
                                <v-btn
                                    rounded
                                    width="100px"
                                    color="#a36789ff"
                                    class="white--text"
                                    :to="{name:'demo'}"

                                >
                                    Get demo
                                </v-btn>
                            </v-list-item>
                        </template>

                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <!--        V-app-nar Large-->
            <v-app-bar
                dark
                app
                clipped-left
                color="white"
                dense
                v-if=isHeaderLarge
            >
                <v-list-item-action class="mx-20">
                    <a class=" testToolbar">
                        <v-img
                            class="ma-0"
                            contain
                            v-if="isHeaderLarge"
                            :src="require('../../assets/icons/HubbcastLogo.svg')"
                            max-height="40px"
                            width="150px"
                            @click="$router.push('/')"
                        ></v-img>
                    </a>
                    <!--                <img width="150px" src="../../assets/icons/insurmatics.png">-->
                </v-list-item-action>
                <v-spacer></v-spacer>
                <v-toolbar-title class=" testToolbar mx-8 align-center mb-2" @click="$router.push('/whyinsurmatics')">
                    <a class=" testToolbar">
                        Why Hubbcast?
                    </a>
                </v-toolbar-title>

                <v-toolbar-title class=" testToolbar mx-8 align-center mb-2" @click="$router.push('/faq')">
                    <a class=" testToolbar">
                        FAQ
                    </a>
                </v-toolbar-title>
                <!--            <v-toolbar-title class=" testToolbar mx-8 align-center mb-2">-->
                <!--                Support-->
                <!--            </v-toolbar-title>-->
                <v-hover
                    v-slot="{ hover }"
                    open-delay="200"
                >
                    <v-toolbar-title class="testToolbar mx-8 align-center  mb-2" @click="$router.push('/partner')">
                        <a class=" testToolbar">
                            Partnership
                        </a>
                    </v-toolbar-title>
                </v-hover>
                <v-toolbar-title class="testToolbar mx-8 align-center  mb-2" @click="$router.push('/journey')">
                    <a class=" testToolbar">
                        Be in our journey
                    </a>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    width="100px"
                    color="#a36789ff"
                    class="white--text"
                    :to="{name:'demo'}"
                >
                    Get demo
                </v-btn>
            </v-app-bar>

            <v-main class="mb-5">
                <router-view></router-view>
            </v-main>

            <v-footer
                padless
            >

                <v-card
                    flat
                    tile
                    width="100%"
                    class="justify-center text-center"
                >
                    <v-card-text>
                        <v-btn
                            class="mx-4 "
                            icon
                            href="https://www.linkedin.com/company/hubbcast"
                        >
                            <v-img
                                :src="require('../../assets/iamges/Footer/Linkedin.svg')"
                                size="10px"
                            ></v-img>
                        </v-btn>
                        <v-btn
                            class="mx-4 "
                            icon
                            href="https://youtube.com/@hubbcast"
                        >
                            <v-img
                                :src="require('../../assets/iamges/Footer/facebook.svg')"
                                size="10px"
                            ></v-img>
                        </v-btn>
                        <v-btn
                            class="mx-4 "
                            icon
                            href="https://X.com/hubbcast"
                        >
                            <v-img
                                :src="require('../../assets/iamges/Footer/Twitter.svg')"
                                size="10px"

                            ></v-img>
                        </v-btn>
                        <v-btn
                            class="mx-4 "
                            icon
                            href="https://www.instagram.com/hubbcast/"
                        >
                            <v-img
                                :src="require('../../assets/iamges/Footer/Instagram.svg')"
                                size="10px"
                            ></v-img>
                        </v-btn>
                        <!--                    <v-btn-->
                        <!--                        v-for="icon in icons"-->
                        <!--                        :key="icon"-->
                        <!--                        class="mx-4 white--text"-->
                        <!--                        icon-->
                        <!--                    >-->
                        <!--                        <v-icon size="24px">-->
                        <!--                            {{ icon }}-->
                        <!--                        </v-icon>-->
                        <!--                    </v-btn>-->
                    </v-card-text>

                    <v-row class="justify-center text-center">
                        <v-col class="mx-auto my-4" xs="12" sm="4">
                            <v-row justify="center" class="mb-3">
                                <v-btn text
                                       class="text-none"
                                       :to="{name:'privacy'}"
                                >
                                    Privacy Policy
                                </v-btn>
                            </v-row>
                            <v-row justify="center">
                                <v-btn text
                                       class="text-none"
                                       :to="{name:'terms'}">
                                    Terms of Use
                                </v-btn>
                            </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col class="mx-auto my-4" xs="12" sm="4">
                            <v-card-text class="pt-0">
                                <div class="mb-2">
                                    <v-icon small color="gray">mdi-map-marker</v-icon>
                                    <span>Address: Laisvės pr. 60, Vilnius 05120</span>
                                </div>
                                <div class="mb-2">
                                    <v-icon small color="gray">mdi-phone</v-icon>
                                    <span>Phone: +37066978994</span>
                                </div>
                                <div>
                                    <v-icon small color="gray">mdi-email</v-icon>
                                    <span>Email: info@hubbcast.com</span>
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-card-text class="justify-center mx-auto">
                        {{ new Date().getFullYear() }} — <strong>Hubbcast Inc.</strong>
                    </v-card-text>
                </v-card>
            </v-footer>


        </v-app>

</template>




<script>

    import vueFilePond, {setOptions} from 'vue-filepond';

    let currentDate = Date.now()
    // setOptions({
    //     fileRenameFunction: (file) => {
    //         return currentDate + `${file.extension}`;
    //     }
    // })
    // setOptions({
    //     chunkUploads: true,
    //     chunkForce: true,
    //     server: {
    //         url: "/uploads",
    //         headers: {
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         process: {
    //             method: 'POST',
    //         },
    //     }
    // });
    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import FilepondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
    import FilePondPluginFileValidateSize
        from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';

    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';


    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilepondPluginFileRename, FilePondPluginImageResize, FilePondPluginFileValidateSize, FilePondPluginImageTransform);

    export default {

        created() {
            // this.$store.commit("updateCustomer", this.citizen);
        },
        name: "Landing",
        components: {
            // InputField,
            FilePond,


        },
        props: [
            'citizen',
            // 'locationLatLong',
        ],
        data() {
            return {
                drawerMedium: false,
                drawerSmall: false,
                selectComponent: 1,
                video: {},
                canvas: {},
                captures: [],
                snackbar: false,
                text: 'Hello, I\'m a snackbar',
                sampleFig: '',
                picID2: '',


            }
        },
        data: function () {
            return {
                drawerMedium: false,
                drawerSmall: false,
                drawer: false,
                selectComponent: 1,

                //myFiles: ['index.html'],
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'dateofadoption', 'address', 'comments'
                        'name': '', 'comments': '', 'cellnumber': '', 'fig': '', 'catched_lon': '', 'catched_lat': ''
                    },
                errors: null,
                responses: null,
                busy: false,
                snackbar: false,
                text: '',
                sampleFig: '',
                picID2: ''

            }
        },

        mounted: function () {
            // this.$store.commit("updateCustomer", this.citizen);
            // this.Bol = true;

        },
        computed: {
            isHeaderSmall() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return true
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderMedium() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return true
                    case 'md':
                        return true
                    case 'lg':
                        return false
                    case 'xl':
                        return false
                }
            },
            isHeaderLarge() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return false
                    case 'sm':
                        return false
                    case 'md':
                        return false
                    case 'lg':
                        return true
                    case 'xl':
                        return true
                }
            },
            // handleProcessFile: function(error, file) {
            //     this.form.fig = file.serverId;
            //     this.picID2 = file;
            //     alert(file.serverId);
            //     // this.$store.commit("updatePublicFig", file.serverId);
            //
            //     // this.setFigValue;
            //     // let response = JSON.parse(file.serverId);
            //     // this.$emit("response", response);
            //     // this.setFigValue(file.serverId);
            //     // console.log(this.form.fig);
            // },
            picID: function (file) {
                this.picID2 = file;
            }
        },
        watch: {
            // a computed getter
            // picID: function () {
            //     // `this` points to the vm instance
            //     this.form.fig = this.picID;
            // },
        },
        methods: {
            updateSetOption: function () {
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
            },
            handleFilePondInit: function () {
                console.log('FilePond has initializeddddddd');
                let currentDate = Date.now();
                setOptions({
                    fileRenameFunction: (file) => {
                        this.picID2 = 'Public_' + currentDate + Math.random().toString() + '.png';
                        return this.picID2;
                    }
                })
                // example of instance method call on pond reference
                this.$refs.pond.getFiles();
            },


            callback(msg) {
                console.debug('Event: ', msg)
            },

            handleProcessFile: function (error, file) {
                this.form.fig = this.picID2;
                // this.picID2 = file;
                //  alert(this.picID2);
            },
            addNewDog() {
                this.updateSetOption();
                this.busy = true;
                this.getCurrentLocation();
                if (this.form.catched_lon === '') {
                    this.snackbar = true;
                    this.text = 'برای ثبت موقعیت مکانی، لطفا مکانیاب خود را فعال نمایید.';
                    this.busy = false;
                } else {
                    axios.post('../report/message', this.form)
                        .then(response => {
                            console.log(response);
                            this.snackbar = true;
                            this.text = 'ممنون از همکاری شما. گزارش کار به محضر شما خواهد رسید.';
                            // this.text = this.form.fig;
                        })
                        .catch(errors => {
                            this.text = errors.response.data.errors;

                            //'name':'','comments':'', 'cellnumber':'', 'fig':'', 'catched_lon':'', 'catched_lat':''
                            this.snackbar = true;
                            // this.text = 'لطفا فیلدها را کامل پرکنید';                            this.text = 'لطفا فیلدها را کامل پرکنید';

                            //this.text = this.form.fig;
                            // console.log(this.form);
                            // this.errors = errors.response.data.errors;
                            console.log(errors);
                        }).finally(() => {
                        this.busy = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>
    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;
    }

    .test {
        font-size: 1em;
    }

    .testHeader {
        font-size: 1.5em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }

    .testToolbar {
        font-size: 1em;
        text-space: 10;
        line-height: 30px;
        color: #000000;
    }

    .titleText {
        font-size: 1.5em;
        line-height: 30px;
        color: #000000;
    }

    .subtitleText {
        font-size: 1em;
        line-height: 30px;
        color: #000000;
    }

    .textHeaderDefinition {
        font-size: .9em;
        color: #999999;
    }

    .lightgray {
        background-color: whitesmoke !important;
    }

    .lightPurple {
        color: #e5d5ffff;
    }

    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/
    .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../assets/icons/Header.png") no-repeat center center;
        background-size: cover;
        transform: scale(1.1);
    }


    .smallbg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../assets/icons/sample.png") no-repeat center center;
        background-size: cover;
        background-color: red;
        transform: scale(1.1);

    }

    .v-list-item--link::before {
        background-color: mediumpurple;
    }

    .theme--light.v-list-item:hover::before {
        opacity: 0.64;
    }

    .v-toolbar-title--link::before {
        background-color: mediumpurple;
    }

    .theme--light.v-toolbar-title:hover::before {
        opacity: 0.64;
    }


    /*.v-card.on-hover.theme--dark*/
    /*background-color: rgba(#FFF, 0.8)*/
    /*>.v-card__text*/
    /*color: #000*/

    /*div{*/
    /*    position: relative;*/
    /*    z-index: 9999;*/
    /*}*/

</style>
