<template>
    <div class="pt-16">
        <!--        <v-snackbar v-model="snackkbar" color="success" :timeout="3000" top>-->
        <!--            <span>-->
        <!--                سلام، {{form.family}} عزیز، اطلاعات شما به عنوان حامی ثبت شد.-->
        <!--            </span>-->
        <!--            &lt;!&ndash;            <v-btn text @click="snackkbar = false">بستن</v-btn>&ndash;&gt;-->
        <!--        </v-snackbar>-->

        <v-card max-width="700px" class="mx-auto rounded-form" outlined>
            <v-card-title class="layout justify-center">
                Add unit holder
            </v-card-title>
            <v-card-subtitle class="layout justify-center">
                Enter the cell number.
            </v-card-subtitle>
            <v-card-text>
                <v-flex row wrap class="box mx-auto" xs9>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-select :items="['Building 1', 'Building 2', 'Building 3']" label="Building name"
                                      :error-messages="errors" placeholder="Building"
                                      v-model="form.building "></v-select>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-select :items="['Unit 1', 'Unit 2', 'Unit 3']" label="Unit Name" :error-messages="errors"
                                      placeholder="Unit" v-model="form.building2 "></v-select>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            class="justify-center"
                        >
                            <v-text-field label="cell number" :error-messages="errors"
                                          placeholder="Enter the caller ID number."
                                          v-model="form.chip_id "></v-text-field>
                        </v-col>
                    </v-row>

                </v-flex>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="m-1" :to="{name:'profiles'}">Cancel</v-btn>
                    <v-btn depressed color="success" class="m-1" @click="dialogSupporter='true'" :loading="busy">
                        Register
                    </v-btn>
                </v-card-actions>
                <template>
                    <v-dialog
                        v-model="dialogSupporter"
                        width="500"
                    >

                        <v-card>
                            <v-card-title class="headline grey lighten-2">
                                Self-declaration form
                            </v-card-title>

                            <v-card-text>
                                I confess that all the information entered is correct and  Insurance Company will
                                not be held responsible for any possible violations.

                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="green"
                                    text
                                    @click="addNewDog"
                                >
                                    Yes, I agrre
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    import vueFilePond from 'vue-filepond';

    import FilePondPluginFileValidateType
        from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

    export default {
        name: "OwnerCreate",
        components: {
            FilePond
        },

        data: function () {
            return {
                //myFiles: ['index.html'],
                dialogSupporter: false,
                snackkbar: false,
                form:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '',
                        'age': '',
                        'sex': '',
                        'family': '',
                        'job': '',
                        'date_of_adoption': '1399',
                        'address': '',
                        'comments': '',
                        'dog_ID': '0',
                        'fig': '',
                        'number': '',
                        'verified': '0'
                    },
                // 'name', 'family', 'sex', 'number', 'fig', 'age', 'degree',  'number_of_nezam', 'address', 'comments', 'verified'

                formVet:
                    {//'name', 'family', 'age', 'job', 'sex', 'date_of_adoption', 'address', 'comments'
                        'name': '',
                        'age': '',
                        'sex': '',
                        'family': '',
                        'degree': '',
                        'number_of_nezam': '',
                        'address': '',
                        'comments': '',
                        'fig': '',
                        'number': '',
                        'verified': '0'
                    },
                errors: null,
                responses: null,
                busy: false,
                service: '',
            }
        },
        mounted() {
            // this.video = this.$refs.video;
            // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            //     navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //         video.srcObject = stream;
            //         video.play();
            //         video.onplay = function () {
            //         };
            //         this.video.play();
            //     });
            // }
        },

        methods: {
            handleProcessFile: function (error, file) {
                // let response = JSON.parse(file.serverId);
                // this.$emit("response", response);
                this.form.fig = file.serverId;
                console.log(this.form.fig);
            },
            addNewVet() {

                this.busy = true;
                // <v-text-field  label="نام" :error-messages="errors" placeholder="لطفا نام خود را وارد نمایید" v-model="form.name "></v-text-field>
                //         <v-text-field  label="نام خانوادگی" :error-messages="errors" placeholder="لطفا نام خانوادگی خود را وارد نمایید."   v-model="form.family " ></v-text-field>
                //         <v-select :items="['مرد','زن']" label="جنس" :error-messages="errors" placeholder="جنسیت" v-model="form.sex " ></v-select>
                //         <v-text-field  label="شماره تماس" placeholder="شماره تماس خود را وارد نمایید." v-model="form.number"></v-text-field>
                this.formVet.name = this.form.name;
                this.formVet.family = this.form.family;
                this.formVet.sex = this.form.sex;
                this.formVet.number = this.form.number;
                this.formVet.fig = this.form.fig;

                axios.post('../vet', this.formVet)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.$router.push({name: 'owner'});
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            },
            addNewDog() {
                this.dialogSupporter = false;
                this.busy = true;
                axios.post('../owner', this.form)
                    .then(response => {
                        this.responses = response.data[0].data.id;
                        //this.$router.push(toString(response.data[0].data.id));
                        this.snackkbar = true;
                    })
                    .catch(errors => {
                        console.log(this.form);
                        this.errors = errors.response.data.errors;
                        console.log(this.errors);
                    }).finally(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>

    #camera {
        text-align: center;
        color: #2c3e50;
    }

    #video {
        background-color: #000000;
    }

    #canvas {
        display: none;
    }

    li {
        display: inline;
        padding: 5px;
    }

    .rounded-form {
        border-radius: 28px !important;

    }
</style>

