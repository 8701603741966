<template>
    <div style="width: 100%; height: 100%;">
        <MglMap :accessToken="mapirToken"
                :center.sync="centers"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                zoom="9">
            <MglAttributionControl />
            <MglNavigationControl position="top-right" />
            <MglGeolocateControl position="top-right" />
<!--            <mapGeojsonLayer-->
<!--                sourceId="mySource"-->
<!--                :source="geoJsonSource"-->
<!--                layerId="myLayer"-->
<!--                :layer="geoJsonlayer"-->
<!--            />-->
            <mapMarker
                :coordinates.sync="markerCoordinates"
                color="blue"
                :draggable="true"
                @dragend="emitBtn = true"
            />

        </MglMap>
<!--        <v-btn-->
<!--            v-click-outside="emitBtn=false"-->
<!--            @click="emitLocation"-->
<!--        >-->
<!--            Set Location!-->
<!--        </v-btn>-->

    </div>
</template>

<script>
    import { MglMap, MglMarker , MglPopup , MglGeojsonLayer, MglGeolocateControl, MglNavigationControl, MglAttributionControl} from "vue-mapbox";

    export default {
        computed:{
            // coordinates(){
            //     return this.userslocation[0]["LatLong"];
            // },

            geoJsonSource() {

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                    coordinates:this.userlocations
                                    //     [
                                    //     [-122.48369693756104, 37.83381888486939],
                                    //     [-122.48348236083984, 37.83317489144141],
                                    //     [-122.48339653015138, 37.83270036637107],
                                    //     [-122.48356819152832, 37.832056363179625],
                                    //     [-122.48404026031496, 37.83114119107971],
                                    //     [-122.48404026031496, 37.83049717427869],
                                    //     [-122.48348236083984, 37.829920943955045],
                                    //     [-122.48356819152832, 37.82954808664175],
                                    //     [-122.48507022857666, 37.82944639795659],
                                    //     [-122.48610019683838, 37.82880236636284],
                                    //     [-122.48695850372314, 37.82931081282506],
                                    //     [-122.48700141906738, 37.83080223556934],
                                    //     [-122.48751640319824, 37.83168351665737],
                                    //     [-122.48803138732912, 37.832158048267786],
                                    //     [-122.48888969421387, 37.83297152392784],
                                    //     [-122.48987674713133, 37.83263257682617],
                                    //     [-122.49043464660643, 37.832937629287755],
                                    //     [-122.49125003814696, 37.832429207817725],
                                    //     [-122.49163627624512, 37.832564787218985],
                                    //     [-122.49223709106445, 37.83337825839438],
                                    //     [-122.49378204345702, 37.83368330777276]
                                    // ]
                                }
                            }
                        ]
                    }
                };
            }
        },
        mounted()
        {
                // axios.post('../dogdatas/date' ,{'date': this.date, 'user': this.userselected})
                //     .then(response =>{
                //         // this.dogs = response.data[0].data;
                //         console.log(response);
                //         let index;
                //         this.count = response.data.length;
                //         for (index = 0; index < response.data.length; ++index) {
                //             this.locations[index] = [response.data[index].catched_lon , response.data[index].catched_lat ];
                //             this.times[index] = moment(response.data[index].created_at).locale('fa').format('YYYY/M/D HH:mm:ss');
                //         }
                //
                //     })
                //     .catch(errors =>{
                //         console.log(errors);
                //
                //         alert('unable to fetch map');
                //     }).finally(()=>{
                //     this.busy = false;
                //
                // })

        },
        name: "AddrLocation",
        components: {
            MglMap,
            MglMarker,
            MglPopup,
            MglGeojsonLayer,
            MglGeolocateControl,
            MglNavigationControl,
            MglAttributionControl,
        },
        // props: ['doglocations', 'userlocations'],//
        methods:{
            emitLocation(){
                this.emitBtn = true;
            }
        },
        data() {
            return {
                emitBtn:false,
                geoJsonlayer: {
                    id: "myLayer",
                    type: "line",
                    source: "mySource",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    paint: {
                        "line-color": "#088",
                        "line-width": 4
                    }
                },
                count:'',
                colors: "Green",
                locations:[],
                times:[],
                coordinates: [51.420296, 35.732379],
                centers: [51.420296, 35.732379],
                markerCoordinates: [51.420296, 35.732379],
                markerCoordinates1: [[51.425296, 35.734379],[51.440296, 35.792379]],
                markerCoordinates2: [53.420296, 35.732379],
                // mapirToken: "<Your API Key>"
                mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjI5ZDk5YjY3YWM5ODMzMDg4YzViZWY3OTFlNzgyNzUzZDk3NDYxNzUxMzQ0NmZlMDdkZjQ4NmZkYmYyNDEyYzk2NDg4MjE5MDY1NDU2NTIxIn0.eyJhdWQiOiIxMTAyNSIsImp0aSI6IjI5ZDk5YjY3YWM5ODMzMDg4YzViZWY3OTFlNzgyNzUzZDk3NDYxNzUxMzQ0NmZlMDdkZjQ4NmZkYmYyNDEyYzk2NDg4MjE5MDY1NDU2NTIxIiwiaWF0IjoxNjAxMzc5ODU3LCJuYmYiOjE2MDEzNzk4NTcsImV4cCI6MTYwMzg4NTQ1Nywic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.tyDRemEvf2jL-t4OXadIpGWoq3OpLQF8hMU_LDSfYMvb00TEyo0njISeSLydHTYDGTMkNVd-3y__w3GNYi24MmT5ZNkeiYH-lvijsneDXe4JDBLG2R1agwFviWHcYX_E3RiBb5Lqm_vI-my48CjB4-Z8uve1n3NuMcHp8mi1VXeosR0LXy65Km1WOIS1-PJw1amwiSL5FycOqHTqeM7cA8uxuwRZvMz5vBJrT6bZ_0tjCrzz519v9lAmK1vlWFpsN4eymO5w2ss0b3WkTYse52cbQGIGgvlAbNwPtnDYxbYAZalB5xCMf3LqxU2O1WbnN7Jpaw6YiSXWBrTmgt305A"

            };
        }
    };

</script>

<style scoped>
    .map-view{
        height: calc(100vh - 48px);
    }
</style>

