<template>
    <svg viewBox="0 0 1024 1176.463" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m509.36 757.41c-64.754 0-117.248 52.493-117.248 117.247s52.494 117.248 117.248 117.248 117.248-52.494 117.248-117.248c0-32.384-13.13-61.702-34.356-82.92-21.216-21.21-50.522-34.327-82.89-34.327zm-132.12-639.977-309.91 306.95c-21.674 21.69-35.078 51.648-35.078 84.736s13.404 63.045 35.08 84.737h-.002l307.91 309.91c-1.986-8.753-3.125-18.805-3.125-29.123 0-75.79 61.44-137.23 137.23-137.23 11.734 0 23.126 1.474 33.998 4.245l-.95-.206-220.856-232.332 174.788-174.87-83.176-83.175c-25.005-25.227-40.455-59.96-40.455-98.3 0-12.563 1.66-24.738 4.77-36.32zm134.88-85.295c-66.244.014-119.94 53.72-119.94 119.965 0 33.12 13.423 63.108 35.125 84.816l274.52 274.52-177.795 181.589 69.78 73.42c10.767 8.468 20.01 18.207 27.702 29.117l.29.433.48.44-.04.08c15.24 21.803 24.353 48.868 24.353 78.06 0 11.077-1.312 21.847-3.788 32.164l.19-.936 309.91-305.792c1.24-1.16 2.68-2 3.92-3.2.92-.92 1.518-2.08 2.398-3.04 20.155-21.376 32.543-50.27 32.543-82.057 0-33-13.35-62.88-34.944-84.537l-359.888-359.893c-21.65-21.715-51.593-35.15-84.675-35.15h-.146.008z"/>
    </svg>
</template>

<script>
    export default {
        name: "googleTagManager"
    }
</script>

<style scoped>

</style>
